import React, { Component } from "react";
import { ComboboxOption, Combobox } from "../Combobox/Combobox";
import { MultiCombobox } from "../Combobox/MultiCombobox";
import { observable, action, runInAction } from "mobx";
import { observer } from "mobx-react";
import { gql } from "apollo-boost";
import { store } from "Models/Store";
import alert from "Util/ToastifyUtils";
import Spinner from "../Spinner/Spinner";
import { Button, Colors, Display } from "Views/Components/Button/Button";
import CheckInRatings from "./CheckInRatings";
import Axios from "axios";
import _ from "lodash";
import { ButtonGroup } from "../Button/ButtonGroup";
import DashboardFilterDatalayout from "../Dashboard/DashboardFilterDatalayout";
import CheckinRaitingsTable from "../Dashboard/CheckinRatingsTable";
import { PatientEntity, TagEntity } from '../../../Models/Entities';
import { QuestionType } from '../../../Forms/Schema/Question';

function queryFields(formId: string) {
	return gql`
        query forms {
            ratingtemplateEntitys(id: "${formId}") {
                id
                name
                frequency
                publishedVersion {
                    formData
                }
			}
			ratingpersonalEntitys(id: "${formId}") {
                id
                name
                frequency
                publishedVersion {
                    formData
                }
            }
        }
    `;
}

function queryResponses(
	patientId: string,
	formId: string,
	fieldId: string,
	connectionType: string,
	ratingType: string
) {
	return gql`
        query response {
            patientEntity(id: "${patientId}") {
                id
                forename
                surname
                ${connectionType}(
                    where: {
                        path: "${ratingType}Id"
                        comparison: equal
                        value: "${formId}"
                    }
                ) {
                    ${ratingType}Id
                    ${ratingType} {
                        publishedVersion {
                            formSubmissions(
                                where: [{
                                    path: "submissionData"
                                    comparison: contains
                                    value: "${fieldId}"
                                }, {
                                    path: "owner"
                                    comparison: equal
                                    value: "${patientId}"
                                }]                           
                                orderBy: { path: "submissionDate", descending: true }
                                take: 7
                            ) {
                                submissionData
                                submissionDate
                            }
                        }
                    }
                }
            }
        }
    `;
}

interface checkInProps {
	forms: ComboboxOption<string>[];

	tags: TagEntity[];
	patients: PatientEntity[];
}

interface model {
	tag: string;
	users: string[];
	form: string;
	formField: string;
}

export interface response {
	userId: string;
	userName: string;
	surname: string;
	firstName: string;
	hasRating: boolean;
	values?: any[];
	dates?: any[];
}

@observer
export default class CheckIn extends Component<checkInProps> {
	@observable
	private model: model = {
		tag: "",
		users: [],
		form: "",
		formField: "",
	};

	@observable
	private ratings: JSX.Element;

	@observable
	private ratingType: "RatingtemplateEntity" | "RatingpersonalEntity";

	@observable
	private users: ComboboxOption<string>[] = [];

	@observable
	private formFields: ComboboxOption<string>[] = [];

	@observable
	private placeHolder = {
		users: "Select Users",
		formField: "Select Form Field",
	};

	@observable
	private disabled = {
		users: true,
		formField: true,
	};

	@observable
	private tagOptions: ComboboxOption<string>[] = [];

	private allQuestions: any = [];

	private tempSelectedQuestion: any;
	private selectedQuestion: any;


	// Question types on forms that are allowed to be displayed on the checkin graph
	private allowedQuestions = [
		'smileyrating',
		'slider',
		'pictorial-slider'
	] as QuestionType[]

	private responses: response[] = [];

	private frequency: 'DAILY' | 'WEEKLY' | 'FORTNIGHTLY';

	private currentTag: string;

	@observable
	private isRunReportLoading = false;

	componentDidMount() {
		this.setTags(this.props.tags);
	}

	// Find all of the fields for a certain form ID
	async findFields(formId: string) {
		await store.apolloClient.query({ query: queryFields(formId) }).then((d) => {
			if (d.data.ratingtemplateEntitys.length > 0) {
				if (d.data.ratingtemplateEntitys[0].publishedVersion?.formData.length > 0) {
					this.setRatingType("RatingtemplateEntity");
					this.setFormFields(d.data.ratingtemplateEntitys[0].publishedVersion.formData);
				}
			} else if (d.data.ratingpersonalEntitys.length > 0) {
				if (d.data.ratingpersonalEntitys[0].publishedVersion.formData.length > 0) {
					this.setRatingType("RatingpersonalEntity");
					this.setFormFields(d.data.ratingpersonalEntitys[0].publishedVersion.formData);
				}
			}
		});
	}

	@action
	private setRatingType(ratingType: "RatingtemplateEntity" | "RatingpersonalEntity") {
		this.ratingType = ratingType;
	}

	// Set all of the form fields for a certain form ID
	@action
	private setFormFields(fields: any) {
		// Reset all question options
		this.allQuestions = [];
		let slide = JSON.parse(fields);
		if (slide[0]) {
			this.formFields = slide[0].contents
				.filter((question: any) => this.allowedQuestions.includes(question.questionType))
				.map((question: any) => {
					this.allQuestions.push(question);
					return { display: question.title ?? "", value: question.id };
				});

			if (this.users.length > 0) {
				this.placeHolder.formField = "Select Form Field";
				this.disabled.formField = false;
			} else {
				this.placeHolder.formField = `None`;
			}
		} else {
			this.formFields = [];
		}
	}

	@action
	private setTags(tags: TagEntity[]) {
		this.tagOptions = tags.map(attr => {
			return { display: attr.name, value: attr.id };
		})
		this.tagOptions.unshift({ display: "All", value: "All" });
	}

	// Filter all of the users to only show those matching the selected tag
	@action
	private filterUsers = (e: any) => {
		if (this.model.tag === 'All') {
			this.currentTag = 'Number of Values';
			this.users = this.props.patients.map((user: PatientEntity) => {
				return { display: user.fullname, value: `${user.id}` };
			});
		} else {
			this.currentTag = e.currentTarget.textContent;
			this.users = this.props.patients
				.filter((user: PatientEntity) => {
					return (
						user.tagss.find((tag: any) => {
							return tag.tagsId === this.model.tag;
						}) !== undefined
					);
				})
				.map((user: PatientEntity) => {
					return { display: user.fullname, value: user.id };
				});
		}

		if (this.users.length > 0) {
			this.users.unshift({ display: "All", value: "All" });
			this.placeHolder.users = "Select Users";
			this.disabled.users = false;
		} else {
			this.placeHolder.users = `None`;
		}
	};

	// Gets the username representation for a user (If they do not have a first/last name)
	private getUserName(fistName?: string, lastName?: string) {
		let firstname = fistName ? fistName : "User";
		let lastname = lastName ? lastName : "Name";
		return `${firstname} ${lastname}`;
	}

	// Find the form fields matching the selected form ID
	@action
	private filterFormFields = () => {
		this.model.formField = "";
		this.allQuestions = [];
		this.findFields(this.model.form);
	};

	// Check if the value range slider should be rendered
	@action
	private checkValueRange = () => {
		let question = this.allQuestions.find((question: any) => {
			return question.id === this.model.formField;
		});

		if (question) {
			this.tempSelectedQuestion = question;
		}
	};

	private handleOnClickRunReport = () => {
		if (!this.validFilterSelections()) {
			return;
		}

		const { tag, users, form, formField } = this.model;
		let userProps: any;
		if (users[0] === "All") {
			userProps = this.users.slice(1).map((user: any) => {
				return user.value;
			});
		} else {
			userProps = Object.values(users);
		}

		this.fetchRatingResponses(userProps, form, formField);
		this.selectedQuestion = this.tempSelectedQuestion;
	}

	// Validates the selections to ensure each item has been chosen
	@action
	private validFilterSelections = () => {
		const { tag, users, form, formField } = this.model;
		let validFilters = true;

		if (tag === "") {
			validFilters = false;
			alert("Please select a tag");
		}
		if (users.length == 0) {
			validFilters = false;
			alert("Please select user(s)");
		}
		if (form === "") {
			validFilters = false;
			alert("Please select a form");
		}
		if (formField === "") {
			validFilters = false;
			alert("Please select a form field");
		}

		return validFilters;
	};

	// Get the responses for the selected users
	@action
	async fetchRatingResponses(users: string[], form: string, formField: string) {
		try {
			const connectionType = this.ratingType === "RatingtemplateEntity" ? "ratingtemplatess" : "ratingss";
			const ratingType = this.ratingType === "RatingtemplateEntity" ? "ratingtemplates" : "ratings";

			this.isRunReportLoading = true;
			this.responses = [];

			await Axios.get(`/api/entity/${this.ratingType}/${form}`)
				.then((d) => (this.frequency = d.data.frequency))
				.then(async () => {
					await Promise.all(
						users.map(async (user: any) => {
							await store.apolloClient
								.query({
									query: queryResponses(
										user,
										form,
										formField,
										connectionType,
										ratingType
									),
								})
								.then((d) => {
									this.setResponse(
										d.data.patientEntity,
										connectionType,
										ratingType
									);
								});
						})
					).then(() => {
						this.renderResponses();
					});
				});
		} catch (error) {
			console.log(error);
			alert(`Error fetching responses`, 'error')
		} finally {
			runInAction(() => {
				this.isRunReportLoading = false;
			})
		}
	}

	// Sets the responses for each user
	private setResponse(response: any, connectionType: string, ratingType: string) {
		let userName = this.getUserName(response.forename, response.surname);
		if (response[connectionType].length > 0) {
			const connections = response[connectionType];
			const firstConnection = connections[0];
			const rating = firstConnection[ratingType];
			this.responses.push({
				firstName: response.forename,
				surname: response.surname,
				userId: response.id,
				userName: userName,
				hasRating: true,
				values: rating.publishedVersion.formSubmissions
			});
		} else {
			this.responses.push({
				firstName: response.forename,
				surname: response.surname,
				userId: response.id,
				userName: userName,
				hasRating: false
			});
		}
	}

	// Renders the responses using the check-in ratings component
	@action
	private renderResponses() {
		this.ratings = (
			<CheckInRatings
				responses={this.responses}
				frequency={this.frequency}
				question={this.selectedQuestion}
				formField={this.model.formField}
				tagName={this.currentTag}
			/>
		);
	}

	private renderCheckinGraph() {
		return (
			this.ratings ||
			<CheckInRatings
				responses={[]}
				frequency="DAILY"
				question={[]}
				formField={""}
				tagName={""}
			/>
		);
	}

	private renderCheckinTableFilterOptions() {
		return (
			<DashboardFilterDatalayout>
				<>
					<div className='dashboard-patient-table-filter__row'>
						<Combobox
							className="tag-select dashboard-patient-table-filter__multicombobox"
							placeholder="Select Group"
							model={this.model}
							modelProperty="tag"
							label="Tag"
							options={this.tagOptions}
							onAfterChange={this.filterUsers}
						/>
						<MultiCombobox
							className="user-select dashboard-patient-table-filter__multicombobox"
							placeholder={this.placeHolder.users}
							model={this.model}
							modelProperty="users"
							label="Patients"
							options={this.users}
							isDisabled={this.disabled.users}
							isClearable={true}
						/>
					</div>
					<div className='dashboard-patient-table-filter__row'>
						<Combobox
							className="form-select dashboard-patient-table-filter__multicombobox"
							placeholder="Select Form"
							model={this.model}
							modelProperty="form"
							label="Check In"
							options={this.props.forms}
							onAfterChange={this.filterFormFields}
						/>
						<Combobox
							className="form-field-select dashboard-patient-table-filter__multicombobox"
							placeholder={this.placeHolder.formField}
							model={this.model}
							modelProperty="formField"
							label="Questions"
							options={this.formFields}
							isDisabled={this.disabled.formField}
							onAfterChange={this.checkValueRange}
						/>
					</div>
					<div className='dashboard-patient-table-filter__row'>
						<ButtonGroup>
							<Button
								display={Display.Solid}
								onClick={this.handleOnClickRunReport}
								colors={Colors.Black}
								className="dashboard-button__run-report">
								Run Report
							</Button>
						</ButtonGroup>
					</div>
				</>
			</DashboardFilterDatalayout>
		);
	}

	render() {
		if (!this.props.forms) {
			return <Spinner />;
		}

		return (
			<div>
				<div className="dashboard-patient-row">
					{this.renderCheckinGraph()}
					{this.renderCheckinTableFilterOptions()}
				</div>
				{this.responses.length > 0 && (
					<CheckinRaitingsTable
						responses={this.responses}
						frequency={this.frequency}
						question={this.selectedQuestion}
						formField={this.model.formField}
						tagName={this.currentTag}
					/>
				)}
				{this.isRunReportLoading && <Spinner />}
			</div>
		);
	}
}
