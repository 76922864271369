import * as React from 'react';
import { observer } from 'mobx-react';
import { RouteComponentProps } from 'react-router';
import SecuredPage from 'Views/Components/Security/SecuredPage';
import Navigation, { Orientation } from 'Views/Components/Navigation/Navigation';
import { getFrontendNavLinks } from 'Views/FrontendNavLinks';
import Header from 'Views/Components/Header/Header';
import { Button } from 'Views/Components/Button/Button';
import { action, observable } from 'mobx';
import { displayName } from 'Symbols';
import * as Models from 'Models/Entities';
import Spinner from 'Views/Components/Spinner/Spinner';
import { store } from 'Models/Store';
import CSVClinicianImport from "../Components/CSVUserImport/CSVClinicianImport";
import Popup from "reactjs-popup";
import EntityCRUD from '../Components/CRUD/EntityCRUD';
import smartlookClient from "smartlook-client";

@observer
class CustomClinicianPage extends React.Component<RouteComponentProps> {
	@observable
	private popupOpen: boolean = false;

	@action
	public onPopupOpen = () => {
		smartlookClient.track('Patients_Import_CSV', {});
		this.popupOpen = true;
	}

	@action
	public onPopupClose = () => {
		this.popupOpen = false;
	}

	public render() {
		if(store.displayNameStatus === 'loading'){
			return <Spinner/>
		}

		return (
			<SecuredPage groups={['Super Administrators', 'Admin']}>
				<Navigation
					linkGroups={getFrontendNavLinks(this.props)}
					orientation={Orientation.VERTICAL}
					match={this.props.match}
					location={this.props.location}
					history={this.props.history}
					staticContext={this.props.staticContext}
				/>

				<div className="body-content">
					<Header title={Models.ClinicianEntity[displayName]} subtitle="User Management" />
					<Popup
						trigger={<Button className="btn btn--outline import">Import Custom CSV</Button>}
						position="right center"
						modal
						closeOnDocumentClick
						open={this.popupOpen}
						onOpen={this.onPopupOpen}
					>
						<CSVClinicianImport closePopup={this.onPopupClose} entity="Clinician" />
					</Popup>
					<EntityCRUD {...this.props} modelType={Models.ClinicianEntity} URLExtension={'Clinician'} />
				</div>
			</SecuredPage>
		);
	}
}

export default CustomClinicianPage;
