/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import * as React from 'react';
import * as uuid from 'uuid';
import moment from 'moment';
import classNames from 'classnames';
import { FormSlideBuilder } from 'Forms/Designer/FormSlideBuilder';
import { FormEntityData } from 'Forms/FormEntityData';
import { action, computed, observable, runInAction } from 'mobx';
import { observer } from 'mobx-react';
import { ButtonGroup } from 'Views/Components/Button/ButtonGroup';
import { Button, Display, Sizes } from 'Views/Components/Button/Button';
import If from 'Views/Components/If/If';
import { FormVersion } from 'Forms/FormVersion';
import { FormTile } from 'Forms/FormTile';
// % protected region % [Add any further imports here] on begin
import { EntityFormMode, AttributeFormMode } from "Views/Components/Helpers/Common";
import { RouteComponentProps } from "react-router";
import * as Models from "Models/Entities";
import { IModelType, Model } from "Models/Model";
import { EntityFormLayout } from "Views/Components/EntityFormLayout/EntityFormLayout";
import GetModel from "Views/Components/GetModel/GetModel";
import { store } from "Models/Store";
import { FormAdminPreview } from "Views/Components/FormAdminPreview/FormAdminPreview";

// % protected region % [Add any further imports here] end

export interface GenericFormDesignerProps {
	form: FormEntityData;
	initialSelectedVersion?: string;
	className?: string;
	// % protected region % [Add any additional props here] on begin
	modelType: IModelType;
	formMode?: EntityFormMode;
	history: any;
	match: any;

	// % protected region % [Add any additional props here] end
}

export interface FormDesignerProps extends GenericFormDesignerProps {
	onCancel?: () => void;
	onSaveDraft?: (version: FormVersion) => void;
	onSavePublish?: (version: FormVersion) => void;
	// % protected region % [Add any additional generic props  here] off begin
	// % protected region % [Add any additional generic props  here] end
}

// % protected region % [Modify tab types here] on begin
type tabTypes = "prepare" | "build" | "preview";

// % protected region % [Modify tab types here] end

@observer
// % protected region % [Modify class declaration here] off begin
export class FormDesigner extends React.Component<FormDesignerProps> {
// % protected region % [Modify class declaration here] end

	// % protected region % [override default values here] on begin
	@observable
	private selectedVersionId = this.props.initialSelectedVersion || this.props.form.publishedVersionId;

	@observable
	private selectedTab: tabTypes = "prepare";

	@observable
	private previewData = this.props.form;
	// % protected region % [override default values here] end

	// % protected region % [Add any additional variables here] on begin
	@observable
	private model: Model;
	// % protected region % [Add any additional variables here] end

	// % protected region % [Override isPublishedVersion here] off begin
	@computed
	private get isPublishedVersion() {
		return !!(this.selectedVersion && this.selectedVersion.id === this.props.form.publishedVersionId);
	}
	// % protected region % [Override isPublishedVersion here] end

	// % protected region % [Override selectedVersion here] off begin
	@computed
	private get selectedVersion() {
		const versions = this.props.form.formVersions;
		if (versions && this.selectedVersionId) {
			return versions.find(v => v.id === this.selectedVersionId);
		}
		return undefined;
	}
	// % protected region % [Override selectedVersion here] end

	// % protected region % [Override selectVersion here] off begin
	@action
	public selectVersion = (id: string) => {
		this.selectedVersionId = id;
	}
	// % protected region % [Override selectVersion here] end

	// % protected region % [Override addEmptyVersion here] off begin
	@action
	private addEmptyVersion = () => {
		if (this.props.form.formVersions) {
			this.props.form.formVersions.push({
				formData: [],
				id: uuid.v4(),
				version: 1,
			});
		}
	}
	// % protected region % [Override addEmptyVersion here] end

	// % protected region % [Override change tab functionality here] on begin
	private changeTab = (tabType: tabTypes) => (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
		event.preventDefault();
		runInAction(() => (this.selectedTab = tabType));
	};
	// % protected region % [Override change tab functionality here] end

	// % protected region % [Override getOrAddLatestVersion here] off begin
	public getOrAddLatestVersion = async () => {
		await this.props.form.getAllVersions();

		if (!this.props.form.formVersions) {
			this.props.form.formVersions = [];
		}

		if (this.props.form.formVersions.length === 0) {
			this.addEmptyVersion();
		}

		return this.props.form.formVersions.slice().sort((a, b) => b.version - a.version)[0];
	}
	// % protected region % [Override getOrAddLatestVersion here] end

	// % protected region % [Override onSaveDraft here] off begin
	private onSaveDraft = () => {
		if (this.props.onSaveDraft && this.selectedVersion) {
			this.props.onSaveDraft(this.selectedVersion);
		}
	}
	// % protected region % [Override onSaveDraft here] end

	// % protected region % [override on save publish functionality here] on begin
	@action
	private onSavePublish = () => {
		this.model.saveFromCrud(this.props.formMode)
			.then(() => {
				if (this.props.onSavePublish && this.selectedVersion) {
					this.props.onSavePublish(this.selectedVersion);
				}
			})
	};
	// % protected region % [override on save publish functionality here] end

	// % protected region % [Add any additional functions here] on begin
	// Sets layout of prepare tab to attributes of current/new RatingTemplate Entity
	private getRatingTemplateFormLayout() {
		let params: any = this.props.match.params;

		// Uses GetModel to get and edit the current model from its id
		return (
			<GetModel modelType={Models.RatingtemplateEntity} id={params.id}>
				{(model: Models.RatingtemplateEntity) => (
					(this.model = model),
						(
							<section className={"prepare-activity"}>
								<div className={"rating-template-form"}>
									<EntityFormLayout
										model={model}
										formMode={this.props.formMode}
										attributeBehaviours={[
											{ name: "created", behaviour: AttributeFormMode.HIDE },
											{ name: "modified", behaviour: AttributeFormMode.HIDE },
										]}
									/>
								</div>
							</section>
						)
				)}
			</GetModel>
		);
	}

	// Sets layout of prepare tab to attributes of current/new RatingPersonal Entity
	private getRatingPersonalFormLayout() {
		let params: any = this.props.match.params;

		// Uses GetModel to get and edit the current model from its id
		return (
			<GetModel modelType={Models.RatingpersonalEntity} id={params.id}>
				{(model: Models.RatingpersonalEntity) => (
					(this.model = model),
						(
							<section className={"prepare-activity"}>
								<div className={"rating-personal-form"}>
									<EntityFormLayout
										model={model}
										formMode={this.props.formMode}
										attributeBehaviours={[]}
									/>
								</div>
							</section>
						)
				)}
			</GetModel>
		);
	}

	// % protected region % [Add any additional functions here] end

	public componentDidMount() {
		this.getOrAddLatestVersion().then(version => this.selectVersion(version.id));

		// % protected region % [Add any additional componentDidMount logic here] off begin
		// % protected region % [Add any additional componentDidMount logic here] end
	}

	public render() {
		// % protected region % [override render function here] on begin
		if (this.selectedVersion) {
			return (
				<section className={classNames("forms-behaviour", this.props.className)}>
					<section className="header-bar">
						<div className="version-details" style={{ padding: "0.5em" }}>
							<h2>Create a new {this.getFormTitle()}</h2>
						</div>
						<div className="tabs">
							<ul>
								<li className={this.selectedTab === "prepare" ? "active" : ""}>
									<a onClick={this.changeTab("prepare")}>Prepare</a>
								</li>
								<li className={this.selectedTab === "build" ? "active" : ""}>
									<a onClick={this.changeTab("build")}>Build</a>
								</li>
								<li className={this.selectedTab === "preview" ? "active" : ""}>
									<a onClick={this.changeTab("preview")}>Preview</a>
								</li>
							</ul>
						</div>
					</section>

					{this.renderCenter(this.selectedVersion)}

					<section aria-label="action-bar" className="action-bar">
						<div className="form-information">
							{/*<p> Form status <span className={classNames('form-status', this.isPublishedVersion ? 'published' : 'draft')}>{this.isPublishedVersion ? 'Published ' : 'Draft '}</span> </p>*/}
							<If condition={this.selectedVersion.modified !== undefined}>
								<p>
									Last updated{" "}
									<span
										className="italics">{moment(this.selectedVersion.modified).format("YYYY-MM-DD HH:mm:ss")}</span>{" "}
								</p>
							</If>
						</div>
						<ButtonGroup>
							<If condition={this.props.onCancel !== undefined}>
								<Button onClick={this.onCancel} className="btn--outline">
									Cancel
								</Button>
							</If>
							<If condition={this.props.onSaveDraft !== undefined}>
								<Button onClick={this.onSaveDraft} className="btn--outline">
									Save Draft
								</Button>
							</If>
							<If condition={this.props.onSavePublish !== undefined}>
								<Button onClick={this.onSavePublish} className="btn--solid">
									Save and Publish
								</Button>
							</If>
						</ButtonGroup>
					</section>
				</section>
			);
		}

		return "No Version";

		// % protected region % [override render function here] end
	}

	// % protected region % [Modify renderCenter here] on begin
	private renderCenter = (version: FormVersion) => {
		switch (this.selectedTab) {
			case "prepare":
				return this.prepareTile();
			case "build":
				return <FormSlideBuilder formVersion={version} />;
			case "preview":
				return <FormAdminPreview model={this.previewData} schema={version.formData}
				                         className="forms-preview" />;
		}
	};

	@action
	private onCancel = () => {
		// If model has no versions then it is a new model and has not been saved before, so cancelling will delete the model
		if (!this.selectedVersion?.modified) {
			this.model.delete();
		}

		// Redirect back to forms page
		store.routerHistory.push("/admin/forms");
	};

	// Gets current model type to be displayed in the page header
	@action
	private getFormTitle() {
		if (this.props.modelType.toString() == "ActivitiesEntity") {
			return "activity";
		} else if (this.props.modelType.toString() == "RatingpersonalEntity") {
			return "personal rating";
		} else if (this.props.modelType.toString() == "RatingtemplateEntity") {
			return "rating template";
		}
		return;
	}

	// Determines what the prepare tab will look like depending on the current form
	@action
	private prepareTile() {
		if (this.props.modelType.toString() == "ActivitiesEntity") {
			return this.getActivityFormLayout();
		} else if (this.props.modelType.toString() == "RatingpersonalEntity") {
			return this.getRatingPersonalFormLayout();
		} else if (this.props.modelType.toString() == "RatingtemplateEntity") {
			return this.getRatingTemplateFormLayout();
		}
		return;
	}

	// Sets layout of prepare tab to attributes of current/new Activity Entity
	private getActivityFormLayout() {
		let params: any = this.props.match.params;

		// Uses GetModel to get and edit the current model from its id
		return (
			<GetModel modelType={Models.ActivitiesEntity} id={params.id}>
				{(model: Models.ActivitiesEntity) => (
					(this.model = model),
						(
							<section className={"prepare-activity"}>
								<div className={"activity-form"}>
									<EntityFormLayout
										model={model}
										formMode={this.props.formMode}
										attributeBehaviours={[
											// Tags is deprecated and replaced by library tags. Hide it so its not mutated
											{ name: "tagss", behaviour: AttributeFormMode.HIDE },

											// Description is not shown. No time to chase up the answer.
											{ name: "description", behaviour: AttributeFormMode.HIDE },

											// These were modelled incorrectly according to build 1.
											// The user roles that can view activities should be based on the same fields but on the book that it is associated with
											{ name: "patient", behaviour: AttributeFormMode.HIDE },
											{ name: "clinician", behaviour: AttributeFormMode.HIDE },
											{ name: "carer", behaviour: AttributeFormMode.HIDE },
											{ name: "favouritedbypatientss", behaviour: AttributeFormMode.HIDE },

											{ name: "created", behaviour: AttributeFormMode.HIDE },
											{ name: "modified", behaviour: AttributeFormMode.HIDE },
										]}
									/>
								</div>
							</section>
						)
				)}
			</GetModel>
		);
	}

	// % protected region % [Modify renderCenter here] end
}
