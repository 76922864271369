import React, { Component } from 'react';
import ArticleButton from './ArticleButton';
import If from '../If/If';
import { Button, Sizes } from '../Button/Button';
import { Icon } from 'semantic-ui-react';
import { observer } from 'mobx-react';
import { observable, action } from 'mobx';
import { store } from 'Models/Store';

export interface BooksProps {
	title: string;
	articles: any[];
	filter?: string;
}

@observer
export default class BookContainerIndex extends Component<BooksProps> {
	@observable
	private collapsed: 'chevron-up' | 'chevron-down' = 'chevron-up';

	@observable
	private articles: JSX.Element;

	@action
	private handleExpand = () => {
		this.collapsed = this.collapsed == 'chevron-up' ? 'chevron-down' : 'chevron-up';
		this.renderArticles();
	};

	@action
	renderArticles = () => {
		let articles;
		if (this.collapsed === 'chevron-down') {
			articles = this.props.articles.map((article: any) => {
				return (
					<div
						className="icon-heart-large icon-left index-article"
						onClick={() => store.routerHistory.push(`/article/${article.id}`)}>
						{article.title}
					</div>
				);
			});
		}

		this.articles = <div className="article-container">{articles}</div>;
	};

	render() {
		let articles: any = [];
		// if (this.props.articles) {
		// 	this.props.articles.map((article: any, index: any) => {
		// 		// Only render filtered books
		// 		if (!this.props.filter) {
		// 			articles.push(<ArticleButton key={article.id} article={article} />);
		// 		} else {
		// 			if (article.title.toLowerCase().includes(this.props.filter.toLowerCase())) {
		// 				articles.push(<ArticleButton key={article.id} article={article} />);
		// 			}
		// 		}
		// 	});
		// }

		// let title = articles.length > 0 ? <h4>{this.props.title}</h4> : <></>;

		return (
			<>
				<div className="index-title" onClick={() => this.handleExpand()}>
					<h3>{this.props.title}</h3>
					<Button sizes={Sizes.Large} icon={{ icon: this.collapsed, iconPos: 'icon-left' }}></Button>
				</div>

				<hr className="dotted-hr" />

				{this.articles}

				<div className="all-articles-container">{articles}</div>
			</>
		);
	}
}
