import Axios, { AxiosResponse } from 'axios';
import { ActivitiesEntity } from '../Models/Entities';

export default class ActivityService {
	private static BASE_ACTIVITIES_URL = '/api/entity/ActivitiesEntity';

	public static readPersonalisedActivities(userId: string): Promise<AxiosResponse<ActivitiesEntity[]>> {
		const URL = `${this.BASE_ACTIVITIES_URL}/personalised/${userId}`;
		return Axios.get<ActivitiesEntity[]>(URL);
	}
}
