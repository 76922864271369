import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { QuestionType } from 'Forms/Schema/Question';
import { IQuestionTile, QuestionTileOptionsProps } from '../QuestionTile';
import { QuestionComponent, QuestionComponentProps } from 'Forms/Questions/QuestionComponent';
import TileOptions from '../TileOptions';
import { action } from 'mobx';
import { createMuiTheme, ThemeProvider, Slider, Switch, FormControlLabel, withStyles, Mark } from '@material-ui/core';
import { TextField } from 'Views/Components/TextBox/TextBox';
import { Combobox } from 'Views/Components/Combobox/Combobox';
import CompareNumber from 'Forms/Conditions/CompareNumber';

//import svgs
import alco_5_1 from '../../../Images/Pictorials/5 item Alcohol Scale/Rating Scale Graphics_Graphic 9_Alcohol-01.svg';
import alco_5_2 from '../../../Images/Pictorials/5 item Alcohol Scale/Rating Scale Graphics_Graphic 9_Alcohol-02.svg';
import alco_5_3 from '../../../Images/Pictorials/5 item Alcohol Scale/Rating Scale Graphics_Graphic 9_Alcohol-03.svg';
import alco_5_4 from '../../../Images/Pictorials/5 item Alcohol Scale/Rating Scale Graphics_Graphic 9_Alcohol-04.svg';
import alco_5_5 from '../../../Images/Pictorials/5 item Alcohol Scale/Rating Scale Graphics_Graphic 9_Alcohol-05.svg';
import mood_5_1 from '../../../Images/Pictorials/5 item Mood Scale/Rating Scale Graphics 6_Mood Scale-01.svg';
import mood_5_2 from '../../../Images/Pictorials/5 item Mood Scale/Rating Scale Graphics 6_Mood Scale-02.svg';
import mood_5_3 from '../../../Images/Pictorials/5 item Mood Scale/Rating Scale Graphics 6_Mood Scale-03.svg';
import mood_5_4 from '../../../Images/Pictorials/5 item Mood Scale/Rating Scale Graphics 6_Mood Scale-04.svg';
import mood_5_5 from '../../../Images/Pictorials/5 item Mood Scale/Rating Scale Graphics 6_Mood Scale-05.svg';
import pain_5_1 from '../../../Images/Pictorials/5 item Pain Scale/Rating Scale Graphics 4_Pain Scale-01.svg';
import pain_5_2 from '../../../Images/Pictorials/5 item Pain Scale/Rating Scale Graphics 4_Pain Scale-02.svg';
import pain_5_3 from '../../../Images/Pictorials/5 item Pain Scale/Rating Scale Graphics 4_Pain Scale-03.svg';
import pain_5_4 from '../../../Images/Pictorials/5 item Pain Scale/Rating Scale Graphics 4_Pain Scale-04.svg';
import pain_5_5 from '../../../Images/Pictorials/5 item Pain Scale/Rating Scale Graphics 4_Pain Scale-05.svg';
import sleep_5_5 from '../../../Images/Pictorials/5 item Sleep Scale/B Rating Scale Graphics_Sleep Time Scale-05.svg';
import sleep_5_4 from '../../../Images/Pictorials/5 item Sleep Scale/B Rating Scale Graphics_Sleep Time Scale-04.svg';
import sleep_5_3 from '../../../Images/Pictorials/5 item Sleep Scale/B Rating Scale Graphics_Sleep Time Scale-03.svg';
import sleep_5_2 from '../../../Images/Pictorials/5 item Sleep Scale/B Rating Scale Graphics_Sleep Time Scale-02.svg';
import sleep_5_1 from '../../../Images/Pictorials/5 item Sleep Scale/B Rating Scale Graphics_Sleep Time Scale-01.svg';
import wthr_5_1 from '../../../Images/Pictorials/5 item Weather Scale/Rating Scale Graphics 1_Weather Scale-01.svg';
import wthr_5_2 from '../../../Images/Pictorials/5 item Weather Scale/Rating Scale Graphics 1_Weather Scale-02.svg';
import wthr_5_3 from '../../../Images/Pictorials/5 item Weather Scale/Rating Scale Graphics 1_Weather Scale-03.svg';
import wthr_5_4 from '../../../Images/Pictorials/5 item Weather Scale/Rating Scale Graphics 1_Weather Scale-04.svg';
import wthr_5_5 from '../../../Images/Pictorials/5 item Weather Scale/Rating Scale Graphics 1_Weather Scale-05.svg';
import alco_10_1 from '../../../Images/Pictorials/10 item Alcohol Scale/Rating Scale Graphics_Graphic 8_Alcohol-01.svg';
import alco_10_2 from '../../../Images/Pictorials/10 item Alcohol Scale/Rating Scale Graphics_Graphic 8_Alcohol-02.svg';
import alco_10_3 from '../../../Images/Pictorials/10 item Alcohol Scale/Rating Scale Graphics_Graphic 8_Alcohol-03.svg';
import alco_10_4 from '../../../Images/Pictorials/10 item Alcohol Scale/Rating Scale Graphics_Graphic 8_Alcohol-04.svg';
import alco_10_5 from '../../../Images/Pictorials/10 item Alcohol Scale/Rating Scale Graphics_Graphic 8_Alcohol-05.svg';
import alco_10_6 from '../../../Images/Pictorials/10 item Alcohol Scale/Rating Scale Graphics_Graphic 8_Alcohol-06.svg';
import alco_10_7 from '../../../Images/Pictorials/10 item Alcohol Scale/Rating Scale Graphics_Graphic 8_Alcohol-07.svg';
import alco_10_8 from '../../../Images/Pictorials/10 item Alcohol Scale/Rating Scale Graphics_Graphic 8_Alcohol-08.svg';
import alco_10_9 from '../../../Images/Pictorials/10 item Alcohol Scale/Rating Scale Graphics_Graphic 8_Alcohol-09.svg';
import alco_10_10 from '../../../Images/Pictorials/10 item Alcohol Scale/Rating Scale Graphics_Graphic 8_Alcohol-10.svg';
import mood_10_1 from '../../../Images/Pictorials/10 item Mood Scale/Rating Scale Graphics 7_Mood Scale-01.svg';
import mood_10_2 from '../../../Images/Pictorials/10 item Mood Scale/Rating Scale Graphics 7_Mood Scale-02.svg';
import mood_10_3 from '../../../Images/Pictorials/10 item Mood Scale/Rating Scale Graphics 7_Mood Scale-03.svg';
import mood_10_4 from '../../../Images/Pictorials/10 item Mood Scale/Rating Scale Graphics 7_Mood Scale-04.svg';
import mood_10_5 from '../../../Images/Pictorials/10 item Mood Scale/Rating Scale Graphics 7_Mood Scale-05.svg';
import mood_10_6 from '../../../Images/Pictorials/10 item Mood Scale/Rating Scale Graphics 7_Mood Scale-06.svg';
import mood_10_7 from '../../../Images/Pictorials/10 item Mood Scale/Rating Scale Graphics 7_Mood Scale-07.svg';
import mood_10_8 from '../../../Images/Pictorials/10 item Mood Scale/Rating Scale Graphics 7_Mood Scale-08.svg';
import mood_10_9 from '../../../Images/Pictorials/10 item Mood Scale/Rating Scale Graphics 7_Mood Scale-09.svg';
import mood_10_10 from '../../../Images/Pictorials/10 item Mood Scale/Rating Scale Graphics 7_Mood Scale-10.svg';
import pain_10_1 from '../../../Images/Pictorials/10 item Pain Scale/Rating Scale Graphics 5_Pain Scale-01.svg';
import pain_10_2 from '../../../Images/Pictorials/10 item Pain Scale/Rating Scale Graphics 5_Pain Scale-02.svg';
import pain_10_3 from '../../../Images/Pictorials/10 item Pain Scale/Rating Scale Graphics 5_Pain Scale-03.svg';
import pain_10_4 from '../../../Images/Pictorials/10 item Pain Scale/Rating Scale Graphics 5_Pain Scale-04.svg';
import pain_10_5 from '../../../Images/Pictorials/10 item Pain Scale/Rating Scale Graphics 5_Pain Scale-05.svg';
import pain_10_6 from '../../../Images/Pictorials/10 item Pain Scale/Rating Scale Graphics 5_Pain Scale-06.svg';
import pain_10_7 from '../../../Images/Pictorials/10 item Pain Scale/Rating Scale Graphics 5_Pain Scale-07.svg';
import pain_10_8 from '../../../Images/Pictorials/10 item Pain Scale/Rating Scale Graphics 5_Pain Scale-08.svg';
import pain_10_9 from '../../../Images/Pictorials/10 item Pain Scale/Rating Scale Graphics 5_Pain Scale-09.svg';
import pain_10_10 from '../../../Images/Pictorials/10 item Pain Scale/Rating Scale Graphics 5_Pain Scale-10.svg';
import wthr_10_1 from '../../../Images/Pictorials/10 item Weather Scale/Rating Scale Graphics 2_Weather Scale-01.svg';
import wthr_10_2 from '../../../Images/Pictorials/10 item Weather Scale/Rating Scale Graphics 2_Weather Scale-02.svg';
import wthr_10_3 from '../../../Images/Pictorials/10 item Weather Scale/Rating Scale Graphics 2_Weather Scale-03.svg';
import wthr_10_4 from '../../../Images/Pictorials/10 item Weather Scale/Rating Scale Graphics 2_Weather Scale-04.svg';
import wthr_10_5 from '../../../Images/Pictorials/10 item Weather Scale/Rating Scale Graphics 2_Weather Scale-05.svg';
import wthr_10_6 from '../../../Images/Pictorials/10 item Weather Scale/Rating Scale Graphics 2_Weather Scale-06.svg';
import wthr_10_7 from '../../../Images/Pictorials/10 item Weather Scale/Rating Scale Graphics 2_Weather Scale-07.svg';
import wthr_10_8 from '../../../Images/Pictorials/10 item Weather Scale/Rating Scale Graphics 2_Weather Scale-08.svg';
import wthr_10_9 from '../../../Images/Pictorials/10 item Weather Scale/Rating Scale Graphics 2_Weather Scale-09.svg';
import wthr_10_10 from '../../../Images/Pictorials/10 item Weather Scale/Rating Scale Graphics 2_Weather Scale-10.svg';
import star_on from '../../../Images/Pictorials/Rating\ Scale\ Graphics\ 3-1_Stars.svg';
import star_off from '../../../Images/Pictorials/Rating\ Scale\ Graphics\ 3-1_Stars-outline.svg';

import If from 'Views/Components/If/If';
import { GRADIENT_COLORS } from '../../../Constants';
import { prettyLog } from '../../../Util/StringUtils';

@observer
export class PictorialSliderQuestionTileOptions extends Component<QuestionTileOptionsProps> {
	@action
	public componentDidMount() {
		if (!this.props.question.shape) {
			this.props.question['shape'] = 'mood';
		}

		if (this.props.question['max'] == null) {
			this.props.question['max'] = 5;
		}
	}

	public render() {
		const countOptions = [
			{ display: '5', value: 5 },
			{ display: '10', value: 10 },
		];

		const shapeOptions = [
			{ display: 'Alcohol', value: 'alco' },
			{ display: 'Mood', value: 'mood' },
			{ display: 'Pain', value: 'pain' },
			{ display: 'Weather', value: 'wthr' },
			{ display: 'Star', value: 'star' },
			{ display: 'Sleep', value: 'sleep' },
		];

		const { question, schema } = this.props;

		//switch change handler
		const toggleChecked = action(() => {
			this.props.question.sliderMode = !this.props.question.sliderMode;
		});

		//custom YHP colors for switch
		const BlueSwitch = withStyles({
			switchBase: {
				color: 'rgb(55, 121, 149)',
				'&$checked': {
					color: 'rgb(65, 151, 182)',
				},
				'&$checked + $track': {
					backgroundColor: 'rgb(65, 151, 182)',
				},
			},
			checked: {},
			track: {},
		})(Switch);

		//display options to change shape, number of ratings and slider options
		return (
			<>
				<TileOptions question={question} schema={schema} />

				<Combobox
					model={this.props.question}
					label="Shape"
					modelProperty="shape"
					options={shapeOptions}
					placeholder={'Select a shape'}
					isRequired={true}
				/>

				<Combobox
					model={this.props.question}
					label="Count"
					modelProperty="max"
					options={countOptions}
					placeholder={'Choose rating scale'}
					isRequired={true}
				/>

				<FormControlLabel
					style={{ width: '100%', paddingBottom: 'inherit' }}
					control={<BlueSwitch size="medium" checked={this.props.question.sliderMode} onChange={toggleChecked} />}
					label="Toggle Slider"
				/>

				<If condition={this.props.question.sliderMode}>
					<TextField model={this.props.question} modelProperty="leftmark" label="Left Mark" placeholder="Poor" />
					<TextField model={this.props.question} modelProperty="centermark" label="Center Mark" placeholder="Average" />
					<TextField model={this.props.question} modelProperty="rightmark" label="Right Mark" placeholder="Great" />
				</If>
			</>
		);
	}
}

export interface IPictorialSliderQuestionTileProps<T> extends QuestionComponentProps<T> {}

@observer
export class PictorialSliderQuestionTile<T> extends QuestionComponent<T, IPictorialSliderQuestionTileProps<T>> implements IQuestionTile {
	static displayName = 'Rating';

	static questionType: QuestionType = 'pictorial-slider';

	static optionsMenu = PictorialSliderQuestionTileOptions;

	static conditionOptions = [
		{ display: 'Equal', value: 'equal' },
		{ display: 'Greater than', value: 'greaterThan' },
		{ display: 'Less than', value: 'lessThan' },
		{ display: 'Not equal', value: 'notEqual' },
		{ display: 'Greater than or equal', value: 'greaterThanOrEqual' },
		{ display: 'Less than or equal', value: 'lessThanOrEqual' },
		{ display: 'Contains', value: 'contains' },
	];

	static compareFunction = CompareNumber;

	static imports = [
		{ key: 'star_on', value: star_on },
		{ key: 'star_off', value: star_off },
		{ key: 'alco_5_1', value: alco_5_1 },
		{ key: 'alco_5_2', value: alco_5_2 },
		{ key: 'alco_5_3', value: alco_5_3 },
		{ key: 'alco_5_4', value: alco_5_4 },
		{ key: 'alco_5_5', value: alco_5_5 },
		{ key: 'wthr_5_5', value: wthr_5_1 },
		{ key: 'wthr_5_4', value: wthr_5_2 },
		{ key: 'wthr_5_3', value: wthr_5_3 },
		{ key: 'wthr_5_2', value: wthr_5_4 },
		{ key: 'wthr_5_1', value: wthr_5_5 },
		{ key: 'mood_5_1', value: mood_5_1 },
		{ key: 'mood_5_2', value: mood_5_2 },
		{ key: 'mood_5_3', value: mood_5_3 },
		{ key: 'mood_5_4', value: mood_5_4 },
		{ key: 'mood_5_5', value: mood_5_5 },
		{ key: 'pain_5_5', value: pain_5_1 },
		{ key: 'pain_5_4', value: pain_5_2 },
		{ key: 'pain_5_3', value: pain_5_3 },
		{ key: 'pain_5_2', value: pain_5_4 },
		{ key: 'pain_5_1', value: pain_5_5 },
		{ key: 'sleep_5_1', value: sleep_5_1 },
		{ key: 'sleep_5_2', value: sleep_5_2 },
		{ key: 'sleep_5_3', value: sleep_5_3 },
		{ key: 'sleep_5_4', value: sleep_5_4 },
		{ key: 'sleep_5_5', value: sleep_5_5 },
		{ key: 'alco_10_1', value: alco_10_1 },
		{ key: 'alco_10_2', value: alco_10_2 },
		{ key: 'alco_10_3', value: alco_10_3 },
		{ key: 'alco_10_4', value: alco_10_4 },
		{ key: 'alco_10_5', value: alco_10_5 },
		{ key: 'alco_10_6', value: alco_10_6 },
		{ key: 'alco_10_7', value: alco_10_7 },
		{ key: 'alco_10_8', value: alco_10_8 },
		{ key: 'alco_10_9', value: alco_10_9 },
		{ key: 'alco_10_10', value: alco_10_10 },
		{ key: 'wthr_10_10', value: wthr_10_1 },
		{ key: 'wthr_10_9', value: wthr_10_2 },
		{ key: 'wthr_10_8', value: wthr_10_3 },
		{ key: 'wthr_10_7', value: wthr_10_4 },
		{ key: 'wthr_10_6', value: wthr_10_5 },
		{ key: 'wthr_10_5', value: wthr_10_6 },
		{ key: 'wthr_10_4', value: wthr_10_7 },
		{ key: 'wthr_10_3', value: wthr_10_8 },
		{ key: 'wthr_10_2', value: wthr_10_9 },
		{ key: 'wthr_10_1', value: wthr_10_10 },
		{ key: 'mood_10_1', value: mood_10_1 },
		{ key: 'mood_10_2', value: mood_10_2 },
		{ key: 'mood_10_3', value: mood_10_3 },
		{ key: 'mood_10_4', value: mood_10_4 },
		{ key: 'mood_10_5', value: mood_10_5 },
		{ key: 'mood_10_6', value: mood_10_6 },
		{ key: 'mood_10_7', value: mood_10_7 },
		{ key: 'mood_10_8', value: mood_10_8 },
		{ key: 'mood_10_9', value: mood_10_9 },
		{ key: 'mood_10_10', value: mood_10_10 },
		{ key: 'pain_10_10', value: pain_10_1 },
		{ key: 'pain_10_9', value: pain_10_2 },
		{ key: 'pain_10_8', value: pain_10_3 },
		{ key: 'pain_10_7', value: pain_10_4 },
		{ key: 'pain_10_6', value: pain_10_5 },
		{ key: 'pain_10_5', value: pain_10_6 },
		{ key: 'pain_10_4', value: pain_10_7 },
		{ key: 'pain_10_3', value: pain_10_8 },
		{ key: 'pain_10_2', value: pain_10_9 },
		{ key: 'pain_10_1', value: pain_10_10 },
	];

	@action
	private setRating = (val: any) => {
		if (!this.props.isReadOnly) {
			this.props.model[this.props.id] = val;
		}
	};

	//gets single image used for slider option
	@action
	private getSingleImg(shape: any, max: number, currentRating: number) {
		let imageName = `${shape}_${max}_${currentRating}`;
		let image: any;

		//default image used
		if (imageName.match('undefined')) {
			if (!shape) {
				return;
			} else {
				if (shape === 'alco') return alco_5_1;
				if (shape === 'mood') return mood_5_1;
				if (shape === 'wthr') return wthr_5_1;
				if (shape === 'pain') return pain_5_1;
				if (shape === 'sleep') return sleep_5_1;
			}
		}
		console.log(imageName)

		//looks for image in imports object
		PictorialSliderQuestionTile.imports.forEach((i) => {
			if (i.key == imageName) {
				image = i.value;
			}
		});

		return image;
	}

	//gets multiple images used for normal option
	@action
	private getMultipleImgs(shape: any, max: number) {
		let imgs: any = [];

		//star option, push the max rating amount of stars to array
		if (shape === 'star' && max) {
			for (let i = 0; i < max; i++) {
				imgs.push(star_off);
			}
		} else {
			//looks for image in imports object
			PictorialSliderQuestionTile.imports.forEach((i) => {
				if (i.key.startsWith(`${shape}_${max}`)) {
					imgs.push(i.value);
				}
			});
		}

		return imgs;
	}

	@action
	private setSliderDisplay(theme: any, shape: any, max: number, currentRating: number, marks: any) {
		let imgs: any = [];

		if (shape === 'star') {
			// We add a star for each one as they do not have progressive states, unlike the other icons
			for (let i = 0; i < currentRating; i++) {
				imgs.push(star_on);
			}
		} else {
			imgs.push(this.getSingleImg(shape, max, currentRating));
		}

		return (
			<>
				<div className="rating-slider-container">
					<p>{this.props.title}</p>
					<div className="slider-imgs">
						{imgs.map((image: any) => {
							return <img src={image} width={150} height={150} />;
						})}
					</div>


					<ThemeProvider theme={theme}>
						<Slider
							defaultValue={this.props.isReadOnly && this.props.model[this.props.id] ? this.props.model[this.props.id] : Math.round(max / 2)}
							aria-labelledby="discrete-slider"
							step={1}
							min={1}
							max={max}
							marks={marks}
							onChange={(e, val) => this.setRating(val)}
							disabled={this.props.isReadOnly}
						/>
					</ThemeProvider>
				</div>
			</>
		);
	}

	@action
	private setNormalDisplay(shape: any, max: number, currentRating: number) {
		let imgs = this.getMultipleImgs(shape, max);

		return (
			<>
				<div className="rating-normal-slider-container">
					<p>{this.props.title}</p>
					<div className="rating-imgs">
						{imgs.map((image: any, index: number) => {
							if (image === star_off || image === star_on) {
								return (
									<img
										className={'rating-img'}
										style={{ maxWidth: '100px', maxHeight: '100px', width: '10%', height: '10%' }}
										src={index <= this.props.model[this.props.id] - 1 ? star_on : star_off}
										onClick={() => this.setRating(index + 1)}
									/>
								);
							}
							return (
								<img
									className={'rating-img'}
									src={image}
									onClick={() => this.setRating(index + 1)}
									style={{
										boxShadow: index == this.props.model[this.props.id] - 1 ? '0rem 0rem 0rem 3px rgb(55, 121, 149)' : '',
										maxWidth: '100px',
										maxHeight: '100px',
										width: '10%',
										height: '10%',
									}}
								/>
							);
						})}
					</div>
				</div>
			</>
		);
	}

	public render() {
		const theme = createMuiTheme({
			typography: {
				fontFamily: ['Lato'].join(','),
			},
			palette: {
				primary: { main: GRADIENT_COLORS.BLUE },
			},
		});

		const shape = this.props.shape ? this.props.shape : 'mood';
		const max = this.props.max ? this.props.max : 5;
		const currentRating = this.props.model[this.props.id] ? this.props.model[this.props.id] : Math.round(max / 2);
		const marks = [
			{ value: 1, label: this.props.leftmark },
			{ value: (max + 1) / 2, label: this.props.centermark },
			{ value: max, label: this.props.rightmark },
		] as Mark[];

		return (
			<div>
				<If condition={this.props.sliderMode}>{this.setSliderDisplay(theme, shape, max, currentRating, marks)}</If>
				<If condition={!this.props.sliderMode}>{this.setNormalDisplay(shape, max, currentRating)}</If>
			</div>
		);
	}
}
