/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import { action, observable } from 'mobx';
import { Model, IModelAttributes, attribute, entity } from 'Models/Model';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from "Util/AttributeUtils";
import { IAcl } from 'Models/Security/IAcl';
import {
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import { VisitorsFeaturedArticleEntity } from 'Models/Security/Acl/VisitorsFeaturedArticleEntity';
import { PatientFeaturedArticleEntity } from 'Models/Security/Acl/PatientFeaturedArticleEntity';
import { ClinicianFeaturedArticleEntity } from 'Models/Security/Acl/ClinicianFeaturedArticleEntity';
import { CarerFeaturedArticleEntity } from 'Models/Security/Acl/CarerFeaturedArticleEntity';
import { AdminFeaturedArticleEntity } from 'Models/Security/Acl/AdminFeaturedArticleEntity';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import {SuperAdministratorScheme} from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface IFeaturedArticleEntityAttributes extends IModelAttributes {
	title: string;

	articlesId?: string;
	articles?: Models.ArticlesEntity | Models.IArticlesEntityAttributes;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('FeaturedArticleEntity', 'Featured Article')
// % protected region % [Customise your entity metadata here] end
export default class FeaturedArticleEntity extends Model implements IFeaturedArticleEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new VisitorsFeaturedArticleEntity(),
		new PatientFeaturedArticleEntity(),
		new ClinicianFeaturedArticleEntity(),
		new CarerFeaturedArticleEntity(),
		new AdminFeaturedArticleEntity(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	// % protected region % [Modify props to the crud options here for attribute 'Title'] off begin
	/**
	 * Title of the featured article.
	 */
	@observable
	@attribute()
	@CRUD({
		name: 'Title',
		displayType: 'textfield',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public title: string;
	// % protected region % [Modify props to the crud options here for attribute 'Title'] end

	/**
	 * Article stored in the Feature Article entity.
	 */
	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Articles'] off begin
		name: 'Articles',
		displayType: 'reference-combobox',
		order: 20,
		referenceTypeFunc: () => Models.ArticlesEntity,
		// % protected region % [Modify props to the crud options here for reference 'Articles'] end
	})
	public articlesId?: string;
	@observable
	@attribute({isReference: true})
	public articles: Models.ArticlesEntity;

	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<IFeaturedArticleEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<IFeaturedArticleEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.title !== undefined) {
				this.title = attributes.title;
			}
			if (attributes.articles !== undefined) {
				if (attributes.articles === null) {
					this.articles = attributes.articles;
				} else {
					if (attributes.articles instanceof Models.ArticlesEntity) {
						this.articles = attributes.articles;
						this.articlesId = attributes.articles.id;
					} else {
						this.articles = new Models.ArticlesEntity(attributes.articles);
						this.articlesId = this.articles.id;
					}
				}
			} else if (attributes.articlesId !== undefined) {
				this.articlesId = attributes.articlesId;
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] off begin
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] off begin
	public defaultExpands = `
		articles {
			${Models.ArticlesEntity.getAttributes().join('\n')}
			${Models.ArticlesEntity.getFiles().map(f => f.name).join('\n')}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] off begin
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath = {
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
							'articles',
						]
					},
				],
			}
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] on begin
		return this.title;
		// % protected region % [Customise the display name for this entity] end
	}


	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [ createdAttr, modifiedAttr ] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(FeaturedArticleEntity.prototype, 'created');
CRUD(modifiedAttr)(FeaturedArticleEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
