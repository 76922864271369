import React from "react";
import { IForm } from "./CheckInFormTile";

export default function FormBanner(props: { form: IForm }) {
	if (props.form) {
		const { bannerId } = props.form;
		const banner = bannerId ? `/api/files/${bannerId}` : `${process.env.PUBLIC_URL}/Checkin/Banner.png`;

		return (
			<div className="form-banner">
				<div className="banner">
					<img src={banner} />
				</div>
			</div>
		);
	}
	return <></>;
}
