import Axios from 'axios';
import moment from 'moment';
import {
	BasePatientGraphData, DASHBOARD_GRAPH_COMMON_CHART_OPTIONS,
	DashboardGraphDateRange,
	IBasePatientGraphDataAttributes
} from '../Views/Components/PatientGraphs/PatientCommonGraph';
import { SeriesOptionsType } from 'highcharts';
import { GRADIENT_COLORS } from '../Constants';

export default class PatientTimelineEventsService {

	private static BASE_URL = '/api/entity/PatientTimelineEventsEntity';

	public static getPatientEngagementGraphData(dateRange: DashboardGraphDateRange) {
		const URL = `${PatientTimelineEventsService.BASE_URL}/patient-engagement-graph-data`;
		return Axios.post<PatientEngagementGraphData>(URL, dateRange);
	}
}

interface IPatientEngagementGraphDataAttributes extends IBasePatientGraphDataAttributes<PatientEngagementDay> {

}

export class PatientEngagementGraphData extends BasePatientGraphData<PatientEngagementDay> {

	constructor(attributes?: Partial<IPatientEngagementGraphDataAttributes>) {
		super(attributes);
		if (attributes) {
			if (attributes.data) {
				for (const model of attributes.data) {
					if (model instanceof PatientEngagementDay) {
						this.data.push(model);
					} else {
						this.data.push(new PatientEngagementDay(model));
					}
				}
			}
		}
	}

	// Return a list of date strings for the x axis of the graph
	getXCategories = () => {
		return this.data.map(day => moment(day.date).format('D MMM'));
	}

	// Return the active patient time series data
	getActiveSeriesData = (): SeriesOptionsType => {
		var seriesData = {
			name: 'Active',
			type: DASHBOARD_GRAPH_COMMON_CHART_OPTIONS.seriesType,
			data: this.data.map(data => data.numberActive),
			color: GRADIENT_COLORS.PURPLE,

		} as SeriesOptionsType;

		return seriesData;
	}

	// Return the inactive patient time series data
	getInactiveSeriesData = (): SeriesOptionsType => {
		var seriesData = {
			name: 'Inactive',
			type: DASHBOARD_GRAPH_COMMON_CHART_OPTIONS.seriesType,
			data: this.data.map(data => data.numberInactive),
			color: GRADIENT_COLORS.BLUE,
		} as SeriesOptionsType;

		return seriesData;
	}
	getActivatedByThisDateSeriesData = (): SeriesOptionsType => {
		var seriesData = {
			name: 'Activated By This Date',
			type: DASHBOARD_GRAPH_COMMON_CHART_OPTIONS.seriesType,
			data: this.data.map(data => data.numberActivated),
			color: '#F2B2FF',
		} as SeriesOptionsType;

		return seriesData;
	}
}

interface IPatientEngagementDayAttributes {
	date: Date;
	numberActivated: number;
	numberActive: number;
	numberInactive: number;
}

class PatientEngagementDay {
	public date: Date;
	public numberActive: number = 0;
	public numberInactive: number = 0;
	public numberActivated: number = 0;
	constructor(attributes?: Partial<IPatientEngagementDayAttributes>) {
		if (attributes) {
			if (attributes.date) {
				this.date = moment(attributes.date).toDate();
			}
			if (attributes.numberActivated) {
				this.numberActivated = attributes.numberActivated;
			}
			if (attributes.numberActive) {
				this.numberActive = attributes.numberActive;
			}
			if (attributes.numberInactive) {
				this.numberInactive = attributes.numberInactive;
			}
		}
	}
}
