import React from 'react';
import { ModelCollection } from '../ModelCollection/ModelCollection';
import {
	ActivitiesSubmissionEntity,
	PatientEntity, TagEntity
} from '../../../Models/Entities';
import { ICollectionHeaderProps } from '../Collection/CollectionHeaders';
import { ICollectionItemActionProps } from '../Collection/Collection';
import { Link } from 'react-router-dom';
import { SERVER_URL } from '../../../Constants';
import { Button, Colors, Display } from '../Button/Button';
import { action, observable } from 'mobx';
import { MultiCombobox } from '../Combobox/MultiCombobox';
import { ApiQueryParams, IOrderByCondition } from '../ModelCollection/ModelAPIQuery';
import { observer } from 'mobx-react';
import { IFilter } from '../Collection/CollectionFilterPanel';
import { DatePicker } from '../DatePicker/DatePicker';
import { ButtonGroup } from '../Button/ButtonGroup';
import {
	DASHBOARD_COMMON_TEXT,
	DashboardTableFilterOptions,
	DashboardTableOptions,
	IDashboardProps
} from './DashboardCommon';
import PatientActivitiesGraph from '../PatientGraphs/PatientActivitiesGraph';
import { utcDateToLocalDateString } from '../../../Util/TimelineUtils';
import axios from 'axios';
import alert from '../../../Util/ToastifyUtils';
import moment from 'moment';

/**
 * Filter options for the table.
 */
class ActivitiesSubmissionTableOptions extends DashboardTableOptions {
	@observable
	orderBy: IOrderByCondition<ActivitiesSubmissionEntity> = {
		path: 'created', descending: true
	}

	@observable
	public fromSubmissionDate: Date | null = null;

	@observable
	public toSubmissionDate: Date | null = null;

	@action
	setFilterOptions = (
		selectedTagIds: string[],
		selecetedPatientIds: string[],
		selectedFromSubmissionDate: Date | null,
		selectedToSubmissionDate: Date | null
	) => {
		let parsedTagIds: string[] = [];
		selectedTagIds?.forEach(id => parsedTagIds.push(id));
		this.tagIds = parsedTagIds;

		let parsedPatientIds: string[] = [];
		selecetedPatientIds?.forEach(id => parsedPatientIds.push(id));
		this.patientIds = parsedPatientIds;

		const MS_PER_MINUTE = 60000;

		if (selectedFromSubmissionDate != null) {
			// serverside stopped casting timezones after the bot upgrade so this is no longer needed - leaving this here cos timezones has been a cluster of a mess. .
			// selectedFromSubmissionDate = new Date(selectedFromSubmissionDate.getTime() + (selectedFromSubmissionDate.getTimezoneOffset() * MS_PER_MINUTE))
		}
		if (selectedToSubmissionDate != null) {
			selectedToSubmissionDate.setHours(23);
			selectedToSubmissionDate.setMinutes(59);
			selectedToSubmissionDate.setSeconds(59);
			// serverside stopped casting timezones after the bot upgrade so this is no longer needed - leaving this here cos timezones has been a cluster of a mess. .
			// selectedToSubmissionDate = new Date(selectedToSubmissionDate.getTime() + (selectedToSubmissionDate.getTimezoneOffset() * MS_PER_MINUTE))
			// selectedToSubmissionDate = moment(selectedToSubmissionDate).add(24, 'hours').toDate();
		}

		this.fromSubmissionDate = selectedFromSubmissionDate;
		this.toSubmissionDate = selectedToSubmissionDate;
	}
}

/**
 * Class for storing the selected data from the filter data table.
 * These are not passed into the table until we click `run report`.
 */
class PatientActivitiesTableFilterOptions extends DashboardTableFilterOptions {
	@observable
	public selectedFromSubmissionDate: Date | null;

	@observable
	public selectedToSubmissionDate: Date | null;
}

@observer
export default class DashboardActivities extends React.Component<IDashboardProps> {
	@observable
	public filterOptions: PatientActivitiesTableFilterOptions = new PatientActivitiesTableFilterOptions();

	@observable
	public tableOptions: ActivitiesSubmissionTableOptions = new ActivitiesSubmissionTableOptions();

	@action
	setFilterOptionTagOptions() {
		this.filterOptions.setTagOptionsAndIncludeAll(this.props.tags);
		this.filterOptions.setPatientOptionsAndIncludeAll(this.props.patients);
	}

	componentDidMount() {
		this.setFilterOptionTagOptions();
	}

	render() {
		return (
			<div>
				<div className="dashboard-patient-row">
					{this.renderActivitiesGraph()}
					{this.renderActivitiesTableFilterOptions()}
				</div>
				{this.renderActivitiesTable()}
			</div>
		);
	}

	renderActivitiesGraph = () => {
		return (
			<div className="dashboard-grid-item dashboard-patient-graph">
				<h1>Activity engagement</h1>
				<p>This graph displays the number of activities completed each day.</p>
				<PatientActivitiesGraph />
			</div>
		)
	}

	renderActivitiesTableFilterOptions = () => {
		return (
			<div className="dashboard-grid-item dashboard-patient-table-filter">
				<h1>{DASHBOARD_COMMON_TEXT.filterTable.title}</h1>
				<p>{DASHBOARD_COMMON_TEXT.filterTable.description}</p>
				<div className='dashboard-patient-table-filter__row'>
					<MultiCombobox
						className='dashboard-patient-table-filter__multicombobox'
						placeholder="Select tags"
						model={this.filterOptions}
						modelProperty="selectedTagIds"
						label="Tag"
						options={this.filterOptions.tagOptions || []}
						isClearable
						onAfterChange={(event, data) => {
							this.filterOptions.setPatientOptionsFilteredByTags(data.value as string[], this.props.patients);
						}}
					/>
					<MultiCombobox
						className='dashboard-patient-table-filter__multicombobox'
						placeholder={"Select patients"}
						model={this.filterOptions}
						modelProperty="selectedPatientIds"
						label="Patients"
						options={this.filterOptions.patientOptions || []}
						isClearable
					/>
				</div>
				<div className='dashboard-patient-table-filter__row'>
					<DatePicker
						className='dashboard-patient-table-filter__datepicker'
						label="From Submission Date"
						model={this.filterOptions}
						modelProperty="selectedFromSubmissionDate"
						flatpickrOptions={{
							dateFormat: 'd/m/Y',
						}}
					/>
					<DatePicker
						className='dashboard-patient-table-filter__datepicker'
						label="To Submission Date"
						model={this.filterOptions}
						modelProperty="selectedToSubmissionDate"
						flatpickrOptions={{
							dateFormat: 'd/m/Y',
						}}
					/>
				</div>

				<div className='dashboard-patient-table-filter__row'>
					<ButtonGroup>
						<Button
							className="dashboard-button__run-report"
							display={Display.Solid}
							colors={Colors.Black}
							onClick={() => this.handleOnClickRunReport()}
						>
							Run Report
						</Button>
						<Button
							className="dashboard-button__run-report"
							display={Display.Solid}
							colors={Colors.Black}
							onClick={() => this.handleOnClickExportReport()}
						>
							Export
						</Button>
					</ButtonGroup>
				</div>
			</div>
		)
	}

	// Update the selected values when we click run report.
	// Otherwise, everytime we select new options in the dropdown, the table refreshes.
	handleOnClickRunReport = () => {
		let parsedSelectedTagIds = this.filterOptions.getParsedSelectedTagIds();
		let parsedSelectedPatientIds = this.filterOptions.getParsedSelectedPatientIds();

		this.tableOptions.setFilterOptions(
			parsedSelectedTagIds,
			parsedSelectedPatientIds,
			this.filterOptions.selectedFromSubmissionDate,
			this.filterOptions.selectedToSubmissionDate,
		);
	}

	handleOnClickExportReport = () => {
		axios.get(
			"/api/entity/ActivitiesSubmissionEntity/dashboard_export_activitySubmissions",
			{
				params: this.getMoreParams(),
			}
		).then((result) => {
			const blob = new Blob([result.data], { type: "text/csv;charset=utf-8" });
			saveAs(blob, `export-dashoboard-activitySubmissions.csv`);
		}).catch(() => {
			alert('Unsuccessfully exported dashboard activities', 'error');
		})
	}
	renderActivitiesTable = () => {

		return (
			<div className="dashboard-grid-item dashboard-table">
				<ModelCollection
					url="/api/entity/ActivitiesSubmissionEntity/dashboard_patient_submissions"
					isApiQuery
					model={ActivitiesSubmissionEntity}
					orderBy={this.tableOptions.orderBy}
					perPage={this.tableOptions.paginationQueryOptions.perPage}
					headers={this.getHeaders()}
					actions={this.getActions()}
					getMoreParams={this.getMoreParams}
				/>
			</div>
		)
	}

	getMoreParams = (filters?: Array<IFilter<ActivitiesSubmissionEntity>>, filterApplied?: boolean): ApiQueryParams => {
		const filterParams = {};

		// Check what filters have been selected and is ready to be passed to the table.
		if (!!this.tableOptions.tagIds) {
			filterParams['tagIds'] = JSON.stringify(this.tableOptions.tagIds.map(tag => tag));
		}

		if (!!this.tableOptions.patientIds) {
			filterParams['patientIds'] = JSON.stringify(this.tableOptions.patientIds.map(tag => tag));
		}

		if (!!this.tableOptions.fromSubmissionDate) {
			filterParams['fromSubmissionDate'] = this.tableOptions.fromSubmissionDate;
		}

		if (!!this.tableOptions.toSubmissionDate) {
			filterParams['toSubmissionDate'] = this.tableOptions.toSubmissionDate;
		}

		return filterParams;
	};

	getHeaders = (): Array<ICollectionHeaderProps<ActivitiesSubmissionEntity>> => {

		var headers = [
			{
				name: 'name',
				displayName: 'Activity Name'
			},
			{
				name: 'surname',
				displayName: 'Surname',
			},
			{
				name: 'forename',
				displayName: 'First Name',
			},
			{
				name: 'userRole',
				displayName: 'User Role',
			},
			{
				name: 'created',
				displayName: 'Submission date',
				transformItem: (item, name) => item[name] ? utcDateToLocalDateString(item[name]) : 'N/A'
			},
			{
				name: 'libraryTagNames',
				displayName: 'Area of Life',
				transformItem: (item, name) => {
					return item.libraryTagNames.length > 0 ? item.libraryTagNames.join(', ') : 'N/A';
				}
			}
		] as Array<ICollectionHeaderProps<ActivitiesSubmissionEntity>>

		headers.forEach(header => {
			header.sortable = true;
		})

		return headers;
	}

	/**
	 * Create the array of actions that we want to display on the patient table for each row
	 */
	getActions = () => {
		const tableActions: Array<ICollectionItemActionProps<ActivitiesSubmissionEntity>> = [];

		tableActions.push({
			action: item => {

			},
			label: 'View Profile',
			customButton: (item) => (

				<Link to={`${SERVER_URL}/profile/patient/${item.ownerId}`}>

					<Button display={Display.Text}>View Profile</Button>
				</Link>

			)
		});
		return tableActions;
	};
}
