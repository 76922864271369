import * as React from 'react';
import { observer } from 'mobx-react';
import { RouteComponentProps } from 'react-router';
// % protected region % [Add any extra imports here] on begin
import { observable, action } from "mobx";
import alert from "Util/ToastifyUtils";
import { gql } from "apollo-boost";
import { store } from "Models/Store";
import ActivityBookContainer from "Views/Components/Activities/ActivityBookContainer";
import { SERVER_URL } from "Constants";
import { TextField } from "Views/Components/TextBox/TextBox";
import { Button } from "Views/Components/Button/Button";

import ActivityService from '../../Services/ActivityService';
import { ActivitiesEntity } from '../../Models/Entities';
import { runInAction } from "mobx";

interface ISearch {
	searchTerm: string;
}

@observer
export default class ActivitiesTile extends React.Component<RouteComponentProps> {
	@observable
	private books: JSX.Element;
	@observable
	private search: ISearch = { searchTerm: "" };

	@observable
	private recommendedActivities: ActivitiesEntity[] = [];

	public render() {
		let contents = null;

		// % protected region % [Override contents here] on begin
		let focus = this.props.location.state == true ? true : false;

		contents = (
			<>
				<div className="library-header">
					<div>
						<h6>Home /</h6>
						<h2>Activities</h2>
					</div>
					<div className="library-all-back">
						{/* Search Bar */}
						<div className="search-box">
							<Button icon={{ icon: "search", iconPos: "icon-left" }} />
						</div>
						<TextField
							model={this.search}
							modelProperty="searchTerm"
							className="article-search"
							placeholder="Search..."
							onAfterChange={this.handleSearch}
							autoFocus={focus}
						/>
					</div>
				</div>
				<div className="activities-dashboard">
					<div className="books-container">{this.books}</div>
					<div className="recommended-activities">
						<h4>Recommended Activities</h4>
						<>
							{this.recommendedActivities.map(activity => {
								return (
									<div
										key={activity.id}
										className="activity-banner"
										onClick={() => {
											store.routerHistory.push("/activity?id=" + activity.id);
										}}>
										<div className="activity-image">
											<img src={`${SERVER_URL}/api/files/${activity.featureId}`} />
										</div>
										<b>{activity.name}</b>
									</div>
								);
							})}
							<div className="recommended-view-all"
							     onClick={() => store.routerHistory.push("/activities/all")}>
								<span>View All</span>
							</div>
						</>
					</div>
				</div>
			</>
		);

		// % protected region % [Override contents here] end

		return contents;
	}

	// % protected region % [Add class methods here] on begin
	componentDidMount() {
		this.getActivities();
		this.getRecommendedActivitiesUser();
	}

	@action
	private handleSearch = () => {
		let state = { search: this.search.searchTerm, path: "/activities", focus: true };
		store.routerHistory.push("/activities/all", state);
	};

	async getActivities() {
		await store.apolloClient
			.query({
				query: queryBooksWithActivities(),
			})
			.then((d) => {
				this.renderBooks(d.data.booksEntitys);
			})
			.catch(() => alert("Could not retrieve content"));
	}

	async getRecommendedActivitiesUser() {

		if (store.userId) {
			ActivityService.readPersonalisedActivities(store.userId)
				.then(res => {
					runInAction(() => {
						this.recommendedActivities = res.data.slice(0, 4).map(d => new ActivitiesEntity(d));
					});
				})
				.catch(err => {
					alert('Error fetching recommended activities');
				})
		}
	}

	@action
	private renderBooks(books: any) {
		let bookContainers = books.map((book: any) => {
			if (book.activitiess.length > 0) {
				// Super admins can see all books
				if (store.userGroups.find((group) => group.name === "Admin")) {
					return <ActivityBookContainer key={book.id} id={book.id} title={book.name}
					                              activities={book.activitiess} max={true} />;
				}
				// Check if the user have access to the book
				if (store.userGroups.find((group) => group.name === "Patient") && book.patient == true) {
					return <ActivityBookContainer key={book.id} id={book.id} title={book.name}
					                              activities={book.activitiess} max={true} />;
				} else if (store.userGroups.find((group) => group.name === "Clinician") && book.clinician == true) {
					return <ActivityBookContainer key={book.id} id={book.id} title={book.name}
					                              activities={book.activitiess} max={true} />;
				} else if (store.userGroups.find((group) => group.name === "Carer") && book.carer == true) {
					return <ActivityBookContainer key={book.id} id={book.id} title={book.name}
					                              activities={book.activitiess} max={true} />;
				}
				return;
			}
			return;
		});

		this.books = bookContainers;
	}
}

function queryBooksWithActivities() {
	return gql`
		query books {
			booksEntitys {
				id
				name
				patient
				carer
				clinician
				activitiess(take: 3) {
					id
					name
					featureId
				}
			}
		}
	`;
}
