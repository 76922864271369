import * as React from 'react';
import { computed, action } from 'mobx';
import { observer } from 'mobx-react';
import { IQuestionTile, IQuestionTileProps, QuestionTileOptionsProps } from '../QuestionTile';
import { TextField } from 'Views/Components/TextBox/TextBox';
import { QuestionType } from 'Forms/Schema/Question';
import Slider from '@material-ui/core/Slider';
import { ThemeProvider, createMuiTheme } from '@material-ui/core';
import { blue } from '@material-ui/core/colors';
import CheckDisplayConditions from 'Forms/Conditions/ConditionUtils';
import { GRADIENT_COLORS } from '../../../Constants';

// The options sidebar for this question
@observer
export class SliderQuestionTileOptions extends React.Component<QuestionTileOptionsProps> {
	public render() {
		return (
			<>
				<TextField model={this.props.question} modelProperty="title" label="Question Name" />
				<TextField model={this.props.question} modelProperty="leftmark" label="Left Mark" placeholder="Poor" />
				<TextField model={this.props.question} modelProperty="centermark" label="Center Mark" placeholder="Average" />
				<TextField model={this.props.question} modelProperty="rightmark" label="Right Mark" placeholder="Great" />
			</>
		);
	}
}

// Any custom props to be passed to the component
export interface ITextAreaQuestionTileProps<T> extends IQuestionTileProps<T> {}

@observer
export class SliderQuestionTile<T> extends React.Component<ITextAreaQuestionTileProps<T>> implements IQuestionTile {
	// The display name for the UI of the question tile
	static displayName = 'Slider';
	// The type of the question that is stored in the form data
	static questionType: QuestionType = 'slider';
	// The options menu to use when displayed in the sidebar
	static optionsMenu = SliderQuestionTileOptions;

	// Used for checking if any custom show logic
	// Currently there is no way to set show logic in the UI
	@computed
	public get isConditionSatisfied() {
		if (this.props.showConditions !== undefined && !this.props.disableShowConditions) {
			return this.props.showConditions.every((condition) => CheckDisplayConditions(condition, this.props.model, this.props.schema));
		}
		return true;
	}

	public render() {
		if (this.isConditionSatisfied) {
			// TODO: Render the question here
			const marks = [
				{ value: 0, label: this.props.leftmark },
				{ value: 5, label: this.props.centermark },
				{ value: 10, label: this.props.rightmark },
			];
			const theme = createMuiTheme({
				typography: {
					fontFamily: ['Lato'].join(','),
				},
				palette: {
					primary: { main: GRADIENT_COLORS.BLUE },
				},
			});
			const displayLabel = this.props.isReadOnly ? 'on' : 'auto';

			let contents = (
				<>
					<p className="question__content">{this.props.title}</p>
					<br />
					<div className="slider-container">
						<ThemeProvider theme={theme}>
							<Slider
								defaultValue={this.props.model[this.props.id] ? this.props.model[this.props.id] : 5}
								aria-labelledby="discrete-slider"
								valueLabelDisplay={displayLabel}
								step={1}
								min={0}
								max={10}
								marks={marks}
								onChangeCommitted={(e, val) => this.valueChanged(val)}
								disabled={this.props.isReadOnly}
							/>
						</ThemeProvider>
					</div>
				</>
			);
			return contents;
		} else {
			return null;
		}
	}

	@action
	public valueChanged = (val: any) => {
		if (!this.props.isReadOnly) {
			this.props.model[this.props.id] = val;
		}
	};
}
