import React, { Component } from 'react';
import SecuredPage from 'Views/Components/Security/SecuredPage';
import Navigation, { Orientation } from 'Views/Components/Navigation/Navigation';
import { getFrontendNavLinks } from 'Views/FrontendNavLinks';
import GetModel from '../../Components/GetModel/GetModel';
import * as Models from '../../../Models/Entities';
import { EntityFormLayout } from '../../Components/EntityFormLayout/EntityFormLayout';
import { AttributeFormMode, EntityFormMode } from '../../Components/Helpers/Common';
import { observable } from 'mobx';
import { RouteComponentProps } from 'react-router';
import { Button } from 'Views/Components/Button/Button';
import { store } from 'Models/Store';
import Header from 'Views/Components/Header/Header';
import { observer } from 'mobx-react';

interface PageProps {
	id?: string;
}

@observer
export default class FormSubmissionPage extends Component<RouteComponentProps<PageProps>> {

	@observable
	private model: Models.RatingtemplateSubmissionEntity;

	render() {
		const { history, location, match, staticContext } = this.props;

		const navigationLinks = getFrontendNavLinks(this.props);

		const contents = (
			<SecuredPage groups={['Admin', 'Patient', 'Clinician', 'Carer']}>
				<Navigation
					linkGroups={navigationLinks}
					orientation={Orientation.VERTICAL}
					match={match}
					location={location}
					history={history}
					staticContext={staticContext}
				/>

				<div className="body-content">
					<Header title="Submission" subtitle="Home / Submission" />
					<div style={{ width: '100%', padding: '2em' }}>
						<GetModel modelType={Models.RatingtemplateSubmissionEntity} id={this.props.match.params.id}>
							{(model: Models.RatingtemplateSubmissionEntity, modelName: string) => (
								(this.model = model),
								(
									<EntityFormLayout
										{...this.props}
										model={model}
										getErrorsForAttribute={this.model.getErrorsForAttribute}
										formMode={EntityFormMode.VIEW}
										attributeBehaviours={[{ name: 'modified', behaviour: AttributeFormMode.HIDE }]}
									/>
								)
							)}
						</GetModel>
						<Button
							className="btn--solid"
							onClick={() => {
								store.routerHistory.goBack();
							}}>
							Back
						</Button>
					</div>
				</div>
			</SecuredPage>
		);
		// % protected region % [Customise the page here] end

		return contents;
	}
}
