import React from 'react';
import { ModelCollection } from '../ModelCollection/ModelCollection';
import { CustomProfileFieldsEntity, PatientEntity } from '../../../Models/Entities';
import { ICollectionHeaderProps } from '../Collection/CollectionHeaders';
import { ICollectionItemActionProps } from '../Collection/Collection';
import { Link } from 'react-router-dom';
import { SERVER_URL } from '../../../Constants';
import { Button, Colors, Display } from '../Button/Button';
import { action, observable, runInAction } from 'mobx';
import { MultiCombobox } from '../Combobox/MultiCombobox';
import { ApiQueryParams, IOrderByCondition } from '../ModelCollection/ModelAPIQuery';
import { observer } from 'mobx-react';
import { IFilter } from '../Collection/CollectionFilterPanel';
import { DatePicker } from '../DatePicker/DatePicker';
import { ButtonGroup } from '../Button/ButtonGroup';
import PatientEngagementGraph from '../PatientGraphs/PatientEngagementGraph';
import {
	DASHBOARD_COMMON_TEXT,
	DashboardTableFilterOptions,
	DashboardTableOptions,
	IDashboardProps
} from './DashboardCommon';
import { utcDateToLocalDateString } from '../../../Util/TimelineUtils';
import axios from 'axios';
import alert from '../../../Util/ToastifyUtils';
import moment from 'moment';

/**
 * Filter options for the table.
 */
class PatientTableOptions extends DashboardTableOptions {
	@observable
	orderBy: IOrderByCondition<PatientEntity> = {
		path: 'lastLogin', descending: true
	}

	@observable
	public fromCreatedDate: Date | null = null;

	@observable
	public toCreatedDate: Date | null = null;

	@action
	setFilterOptions = (
		selectedTagIds: string[], selecetedPatientIds: string[],
		selectedFromDate: Date | null, selectedToDate: Date | null
	) => {

		let parsedTagIds: string[] = [];
		selectedTagIds?.forEach(id => parsedTagIds.push(id));
		this.tagIds = parsedTagIds;

		let parsedPatientIds: string[] = [];
		selecetedPatientIds?.forEach(id => parsedPatientIds.push(id));
		this.patientIds = parsedPatientIds;

		const MS_PER_MINUTE = 60000;

		if (selectedFromDate != null){
			// serverside stopped casting timezones after the bot upgrade so this is no longer needed - leaving this here cos timezones has been a cluster of a mess. .
			// selectedFromDate = new Date(selectedFromDate.getTime() + (selectedFromDate.getTimezoneOffset() * MS_PER_MINUTE))
		}

		if (selectedToDate != null){
			selectedToDate.setHours(23);
			selectedToDate.setMinutes(59);
			selectedToDate.setSeconds(59);

			// Adjust for UTC.
			// serverside stopped casting timezones after the bot upgrade so this is no longer needed - leaving this here cos timezones has been a cluster of a mess. .
			// selectedToDate = new Date(selectedToDate.getTime() + (selectedToDate.getTimezoneOffset() * MS_PER_MINUTE))
		}

		this.fromCreatedDate = selectedFromDate;
		this.toCreatedDate = selectedToDate;
	}
}

/**
 * Class for storing the selected data from the filter data table.
 * These are not passed into the table until we click `run report`.
 */
export class PatientTableFilterOptions extends DashboardTableFilterOptions {
	@observable
	public selectedFromCreatedDate: Date | null;

	@observable
	public selectedToCreatedDate: Date | null;
}

@observer
export default class DashboardPatients extends React.Component<IDashboardProps> {

	/**
	 * Oberservable to store the filter options. We dont update the table until we click 'run report'.
	 */
	@observable
	public filterOptions: PatientTableFilterOptions = new PatientTableFilterOptions();

	@observable
	public tableOptions: PatientTableOptions = new PatientTableOptions();

	@observable
	public customPatientProfilefields: CustomProfileFieldsEntity[] = [];

	componentDidMount() {
		this.setFilterOptions();

		CustomProfileFieldsEntity.fetch<CustomProfileFieldsEntity>({
			args: [[{ path: "patient", comparison: "equal", value: "true" }]],
		}).then(res => {
			runInAction(() => {
				this.customPatientProfilefields = res.map(attr => new CustomProfileFieldsEntity(attr))
			})
		});
	}

	@action
	setFilterOptions() {
		this.filterOptions.setTagOptionsAndIncludeAll(this.props.tags);
		this.filterOptions.setPatientOptionsAndIncludeAll(this.props.patients);
	}

	render() {
		return (
			<div>
				<div className="dashboard-patient-row">
					{this.renderPatientGraph()}
					{this.renderPatientTableFilterOptions()}
				</div>
				{this.renderPatientTable()}
			</div>
		);
	}

	renderPatientGraph = () => {
		return (
			<div className="dashboard-grid-item dashboard-patient-graph">
				<h1>User engagement</h1>
				<p>Of the activated users, this graph displays the number of users engaged each day.</p>
				<PatientEngagementGraph />
			</div>
		)
	}

	renderPatientTableFilterOptions = () => {
		return (
			<div className="dashboard-grid-item dashboard-patient-table-filter">
				<h1>{DASHBOARD_COMMON_TEXT.filterTable.title}</h1>
				<p>{DASHBOARD_COMMON_TEXT.filterTable.description}</p>
				<div className='dashboard-patient-table-filter__row'>
					<MultiCombobox
						className='dashboard-patient-table-filter__multicombobox'
						placeholder="Select tags"
						model={this.filterOptions}
						modelProperty="selectedTagIds"
						label="Tag"
						options={this.filterOptions.tagOptions || []}
						isClearable
						onAfterChange={(event, data) => {
							this.filterOptions.setPatientOptionsFilteredByTags(data.value as string[], this.props.patients);
						}}
					/>
					<MultiCombobox
						className='dashboard-patient-table-filter__multicombobox'
						placeholder={"Select patients"}
						model={this.filterOptions}
						modelProperty="selectedPatientIds"
						label="Patients"
						options={this.filterOptions.patientOptions || []}
						isClearable
					/>
				</div>
				<div className='dashboard-patient-table-filter__row'>
					<DatePicker
						className='dashboard-patient-table-filter__datepicker'
						label="From Login Date"
						model={this.filterOptions}
						modelProperty="selectedFromCreatedDate"
						flatpickrOptions={{
							dateFormat: 'd/m/Y',
						}}
					/>
					<DatePicker
						className='dashboard-patient-table-filter__datepicker'
						label="To Login Date"
						model={this.filterOptions}
						modelProperty="selectedToCreatedDate"
						flatpickrOptions={{
							dateFormat: 'd/m/Y',
						}}
					/>
				</div>

				<div className='dashboard-patient-table-filter__row'>
					<ButtonGroup>
						<Button
							className="dashboard-button__run-report"
							display={Display.Solid}
							colors={Colors.Black}
							onClick={() => this.handleOnClickRunReport()}
						>
							Run Report
						</Button>
						<Button
							className="dashboard-button__run-report"
							display={Display.Solid}
							colors={Colors.Black}
							onClick={() => this.handleOnClickExportReport()}
						>
							Export
						</Button>
					</ButtonGroup>
				</div>
			</div>
		)
	}

	handleOnClickRunReport = () => {
		// Update the selected values when we click run report.
		// Otherwise, everytime we select new options in the dropdown, the table refreshes.

		let parsedSelectedTagIds = this.filterOptions.getParsedSelectedTagIds();
		let parsedSelectedPatientIds = this.filterOptions.getParsedSelectedPatientIds();

		this.tableOptions.setFilterOptions(
			parsedSelectedTagIds,
			parsedSelectedPatientIds,
			this.filterOptions.selectedFromCreatedDate,
			this.filterOptions.selectedToCreatedDate
		);
	}


	handleOnClickExportReport = () => {
		axios.get(
			"/api/entity/PatientEntity/dashboard_export_patient",
			{
				params: this.getMoreParams(),
			}
		).then((result) => {
			const blob = new Blob([result.data], { type: "text/csv;charset=utf-8" });
			saveAs(blob, `export-dashoboard-patient.csv`);
		}).catch(() => {
			alert('Unsuccessfully exported dashboard users', 'error');
		})
	}

	renderPatientTable = () => {

		return (
			<div className="dashboard-grid-item dashboard-table">
				<ModelCollection
					url="/api/entity/PatientEntity/patient_list"
					isApiQuery
					model={PatientEntity}
					orderBy={this.tableOptions.orderBy}
					perPage={this.tableOptions.paginationQueryOptions.perPage}
					headers={this.getHeaders()}
					actions={this.getActions()}
					getMoreParams={this.getMoreParams}
				/>
			</div>
		)
	}

	getMoreParams = (filters?: Array<IFilter<PatientEntity>>, filterApplied?: boolean): ApiQueryParams => {
		const filterParams = {};

		// Check what filters have been selected and is ready to be passed to the table.
		if (!!this.tableOptions.tagIds) {
			filterParams['tagIds'] = JSON.stringify(this.tableOptions.tagIds.map(tag => tag));
		}

		if (!!this.tableOptions.patientIds) {
			filterParams['patientIds'] = JSON.stringify(this.tableOptions.patientIds.map(tag => tag));
		}

		if (!!this.tableOptions.fromCreatedDate) {
			filterParams['fromCreatedDate'] = this.tableOptions.fromCreatedDate;
		}

		if (!!this.tableOptions.toCreatedDate) {
			filterParams['toCreatedDate'] = this.tableOptions.toCreatedDate;
		}

		return filterParams;
	};

	getHeaders = (): Array<ICollectionHeaderProps<PatientEntity>> => {
		var headers = [
			{
				name: 'surname',
				displayName: 'Surname',
			},
			{
				name: 'forename',
				displayName: 'First Name',
			},
			{
				name: 'email',
				displayName: 'Email',
			},
			{
				name: 'created',
				displayName: 'Invited Date',
				transformItem: (item, name) => item[name] ? utcDateToLocalDateString(item[name]) : 'N/A'
			},
			{
				name: 'activatedDate',
				displayName: 'Activated Date',
				transformItem: (item, name) => item[name] ? utcDateToLocalDateString(item[name]) : 'N/A'
			},
			{
				// TODO: Change to login date
				name: 'lastLogin',
				displayName: 'Last Login',
				transformItem: (item, name) => item[name] ? utcDateToLocalDateString(item[name]) : 'N/A'
			}
		] as Array<ICollectionHeaderProps<PatientEntity>>

		headers.forEach(header => {
			header.sortable = true;
		})

		this.customPatientProfilefields.forEach(customField => {
			headers.push({
				sortable: false,
				name: customField.name,
				displayName: customField.name,
				transformItem: (item, name) => {
					return CustomProfileFieldsEntity.FieldDisplayName(item.customFields, customField);
				}
			})
		})

		return headers;
	}

	/**
	 * Create the array of actions that we want to display on the patient table for each row
	 */
	getActions = () => {
		const tableActions: Array<ICollectionItemActionProps<PatientEntity>> = [];

		tableActions.push({
			action: item => { },
			label: 'View Profile',
			customButton: (item) => (
				<Link to={`${SERVER_URL}/profile/patient/${item.id}`}>
					<Button display={Display.Text}>View Profile</Button>
				</Link>
			)
		});
		return tableActions;
	};
}

