/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import { Model, IModelAttributes, attribute, entity } from 'Models/Model';
import * as Models from 'Models/Entities';
import { IAcl } from '../Security/IAcl';
import { observable } from 'mobx';
import { VisitorsLibraryTagsReferenceManyToMany } from '../Security/Acl/VisitorsLibraryTagsReferenceManyToMany';
import { PatientLibraryTagsReferenceManyToMany } from '../Security/Acl/PatientLibraryTagsReferenceManyToMany';
import { ClinicianLibraryTagsReferenceManyToMany } from '../Security/Acl/ClinicianLibraryTagsReferenceManyToMany';
import { CarerLibraryTagsReferenceManyToMany } from '../Security/Acl/CarerLibraryTagsReferenceManyToMany';
import { AdminLibraryTagsReferenceManyToMany } from '../Security/Acl/AdminLibraryTagsReferenceManyToMany';

// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface IGoalsLibraryTagsAttributes extends IModelAttributes {
	goalsId: string;
	libraryTagsId: string;

	goals: Models.GoalsEntity | Models.IGoalsEntityAttributes;
	libraryTags: Models.LibraryTagEntity | Models.ILibraryTagEntityAttributes;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

@entity('GoalsLibraryTags')
export default class GoalsLibraryTags extends Model implements IGoalsLibraryTagsAttributes {
	public static acls: IAcl[] = [
		new VisitorsLibraryTagsReferenceManyToMany(),
		new PatientLibraryTagsReferenceManyToMany(),
		new ClinicianLibraryTagsReferenceManyToMany(),
		new CarerLibraryTagsReferenceManyToMany(),
		new AdminLibraryTagsReferenceManyToMany(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	@observable
	@attribute()
	public goalsId: string;

	@observable
	@attribute()
	public libraryTagsId: string;

	@observable
	@attribute({isReference: true})
	public goals: Models.GoalsEntity;

	@observable
	@attribute({isReference: true})
	public libraryTags: Models.LibraryTagEntity;
	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	constructor(attributes?: Partial<IGoalsLibraryTagsAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		if (attributes) {
			if (attributes.goalsId) {
				this.goalsId = attributes.goalsId;
			}
			if (attributes.libraryTagsId) {
				this.libraryTagsId = attributes.libraryTagsId;
			}

			if (attributes.goals) {
				if (attributes.goals instanceof Models.GoalsEntity) {
					this.goals = attributes.goals;
					this.goalsId = attributes.goals.id;
				} else {
					this.goals = new Models.GoalsEntity(attributes.goals);
					this.goalsId = this.goals.id;
				}
			} else if (attributes.goalsId !== undefined) {
				this.goalsId = attributes.goalsId;
			}

			if (attributes.libraryTags) {
				if (attributes.libraryTags instanceof Models.LibraryTagEntity) {
					this.libraryTags = attributes.libraryTags;
					this.libraryTagsId = attributes.libraryTags.id;
				} else {
					this.libraryTags = new Models.LibraryTagEntity(attributes.libraryTags);
					this.libraryTagsId = this.libraryTags.id;
				}
			} else if (attributes.libraryTagsId !== undefined) {
				this.libraryTagsId = attributes.libraryTagsId;
			}
		}

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}