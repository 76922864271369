import React, { Component } from "react";
import { RouteComponentProps } from "react-router";
import { observer } from "mobx-react";
import CheckInTile from '../../Components/PatientCheckIn/CheckInTile';

@observer
export default class PatientDashboard extends Component<RouteComponentProps> {

    render() {

        return (
            <div>
                <CheckInTile />
            </div>
        );
    }
}
