import { action, observable, runInAction } from 'mobx';
import { observer } from "mobx-react";
import { AreaOfLifeRatingsEntity, GoalsEntity, PatientEntity } from 'Models/Entities';
import { store } from 'Models/Store';
import React, { Component } from 'react'
import GoalSlideOverview from './GoalSlideOverview';
import GoalSlideSelectAreaOfLife from './GoalSlideSelectAreaOfLife'
import GoalSlideSelectGoal from './GoalSlideSelectGoal';
import GoalWizardHeader from './GoalsWizardHeader';
import smartlookClient from "smartlook-client";
import PatientService from '../../../../Services/PatientService/PatientService';
import { PatientExpands } from '../../../../Services/PatientService/PatientEntityExpands';

interface IGoalFormWizard {
	patient?: PatientEntity
	hideForProfile?: boolean;
	onDone: () => void;
}
@observer
export default class GoalFormWizard extends Component<IGoalFormWizard> {

	@observable
	public goal: GoalsEntity = new GoalsEntity();

	@observable
	public patient: PatientEntity;

	@observable
	public areaOfLifeRating: AreaOfLifeRatingsEntity;

	@observable
	private requestState: 'pending' | 'error' | 'success' = 'pending';

	componentDidMount() {

		this.getPatient();
	}

	private async getPatient() {
		let userId = this.props.patient ? this.props.patient.id : store.userId;
		if (userId != null) {
			try {
				PatientService
					.getPatient(userId, PatientExpands.patientOnboarding)
					.then((res) => {
						runInAction(() => {
							this.setupInitialGoalFormData(res[0]);
							this.requestState = 'success';
						})
					})
			} catch (error) {
				this.requestState = 'error';
				console.log(error);
			}
		}
	}

	@action
	setupInitialGoalFormData = (patient: PatientEntity) => {
		this.patient = new PatientEntity(patient);

		if (this.patient.hasAFullAreaOfLifeRating()) {
			this.areaOfLifeRating = new AreaOfLifeRatingsEntity(this.patient.getLatestAreaofLifeValues());
			this.areaOfLifeRating.patientId = undefined;
			this.areaOfLifeRating.setModelId(null);
		} else {
			this.areaOfLifeRating = new AreaOfLifeRatingsEntity({
				patientId: undefined,
				fullRating: true
			});
		}
	}

	@observable
	public currentSlideIndex = 0;

	@action
	public incrementStep(): void {
		this.currentSlideIndex += 1;
	}

	@action
	public decrementStep(): void {
		if (this.currentSlideIndex > 0) {
			this.currentSlideIndex -= 1;
		}
	}

	@action
	private onChangeGoal(goal: any) {
		this.goal = goal;
	}

	@action
	private onClickDone() {
		const clonedGoal = new GoalsEntity(this.goal);
		clonedGoal.patientId = this.props.patient ? this.props.patient.id : store.userId;
		clonedGoal.save({
			goalStepss: {},
			libraryTagss: {}
		}).then(res => {
			this.props.onDone();
		}).catch(err => {
			console.log("error saving goal");
		});
	}

	@action
	private onClickRedirect() {
		store.routerHistory.push("/goals");
	}

	render() {

		let contents = (
			<>
				<div className="goal-wizard-container">
					<GoalWizardHeader
						displayBack={this.props.hideForProfile === undefined} currentSlideIndex={this.currentSlideIndex} totalNumberOfSlides={3}
						onClickBack={() => store.routerHistory.push('/goals')}
					/>
					<div>
						{this.currentSlideIndex === 0 && (
							<GoalSlideSelectAreaOfLife
								goal={this.goal}
								lifeArea={this.goal.lifeArea}
								user={this.patient}
								areaOfLifeRating={this.areaOfLifeRating}
								decrementStep={() => this.decrementStep()}
								incrementStep={() => this.incrementStep()}
								onClickBack={() => {
									store.routerHistory.push("/goals");
								}}
								displayBack={this.props.hideForProfile}
							/>
						)}
						{this.currentSlideIndex === 1 && (
							<GoalSlideSelectGoal
								goal={this.goal}
								lifeArea={this.goal.lifeArea}
								onChangeGoal={(goal: GoalsEntity) => {
									this.onChangeGoal(goal)
								}}
								decrementStep={() => this.decrementStep()}
								incrementStep={() => this.incrementStep()}
								onClickRedirect={() => {
									this.onClickRedirect();
								}}
								displayRedirect={this.props.hideForProfile}
							/>
						)}
						{this.currentSlideIndex === 2 && (
							<GoalSlideOverview
								goal={this.goal}
								lifeArea={this.goal.lifeArea}
								initialDisplayState={this.goal.name == null || this.goal.name.trim().length === 0 ? 'GOAL-NAME' : 'GOAL-OVERVIEW'}
								decrementStep={() => this.decrementStep()}
								incrementStep={() => this.incrementStep()}
								onClickDone={() => {
									smartlookClient.track('Goals_Save_Goal', {});
									this.onClickDone();
								}}
							/>
						)}
					</div>
				</div>
			</>
		);
		if (this.requestState === 'success') {
			return contents;
		}

		return <></>
	}
}




