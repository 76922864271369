import { observer } from 'mobx-react';
import React from 'react';
import { AreaProgress, AreaProgressButton, IAreaProgressButton } from '../Goals/GoalsProgess';
import { AreaOfLifeRatingsEntity, IAreaOfLifeRatingsEntityAttributes, PatientEntity } from '../../../Models/Entities';
import { lifeAreaOptions } from '../../../Models/Enums';
import _ from 'lodash';
import { observable, runInAction } from 'mobx';
import Axios from 'axios';

/**
 * Component that handles fetching and displaying summary of the areas of life ratings.
 */
@observer
export class PatientAreaOfLifeGraph extends React.Component {

	@observable
	areaOfLiferating = new AreaOfLifeRatingsEntity();

	@observable
	public allRatings: AreaOfLifeRatingsEntity[] = [];

	@observable
	isLoading: boolean = true;

	async componentDidMount() {
		try {
			const res = await Axios.get('/api/entity/AreaOfLifeRatingsEntity/patient-aol-graph-data');
			runInAction(() => {
				this.allRatings = res.data.map((attr: IAreaOfLifeRatingsEntityAttributes) => new AreaOfLifeRatingsEntity(attr));
				this.mapAllRatingsToSummaryRating();
			})
		} catch (e) {
			console.warn(`Error fetching area of lifes`);
		} finally {
			runInAction(() => {
				this.isLoading = false;
			})
		}
	}

	mapAllRatingsToSummaryRating = () => {
		// Calculate sum for each area
		Object.keys(lifeAreaOptions).forEach(key => {
			const areaValues = this.allRatings
				.filter(rating => {
					const value = rating[key.toLowerCase()];
					return value != null && value !== -1 && value >= 0 && value <= 10;
				}).map(rating => rating[key.toLowerCase()]);

			const sumOfRatings = _.sum(areaValues);
			const average = sumOfRatings / areaValues.length;
			this.areaOfLiferating[key.toLowerCase()] = (average === 0 || areaValues.length === 0) ? 0 : average.toFixed(2);
		})
	}

	render() {
		if (this.isLoading) {
			return <></>
		}
		return (
			<div className="area-progress patient-area-of-life-graph">
				{this.lifeAreaSummaryArray().map((area, index) => {
					return (
						<AreaProgressButton
							key={index}
							value={area.value}
							area={area.area}
							color={area.color}
							content={area.content}
						/>
					)
				})}
			</div>
		)
	}

	lifeAreaSummaryArray = () => {

		let data: IAreaProgressButton[] = Object.keys(lifeAreaOptions).map(key => {
			const value = this.areaOfLiferating[key.toLowerCase()];
			return {
				area: key.toLowerCase(),
				value: value,
				color: this.getColor(value),
				content: this.getProgressNode(value)
			}
		})

		data.sort((a, b) => a.value - b.value)

		return data;
	}

	getColor = (value: number | null) => {
		if (value == null || value === -1) {
			return '#f4f4f4';
		}
		if (value >= 0 && value <= 4) {
			return '#FF8676';
		}
		if (value > 4 && value <= 7) {
			return '#FCD33E';
		}
		if (value > 7 && value <= 10) {
			return '#97C9AA';
		}
		return '#f4f4f4';
	}

	getProgressNode = (value: number | null) => {

		if (value == null || value === -1) {
			return <></>
		}

		return (
			<div className="area-name add-area progress-value">
				{`${(value / 10 * 100).toFixed(0)}%`}
			</div>
		);
	}
}
