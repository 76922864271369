import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router';
import { gql } from 'apollo-boost';
import { observer } from 'mobx-react';
import { store } from 'Models/Store';
import { observable, action } from 'mobx';
import { Button, Display } from 'Views/Components/Button/Button';
import { Link } from 'react-router-dom';
import BookContainerIndex from 'Views/Components/Articles/BookContainerIndex.';
import { TextField } from 'Views/Components/TextBox/TextBox';

function getBooksWithArticles() {
	return gql`
		query bookArticles {
			booksEntitys {
				id
				name
				patient
				carer
				clinician
				learningss {
					id
					title
					featureId
					description
					content
				}
			}
		}
	`;
}

interface ISearch {
	searchTerm: string;
}

@observer
export default class LibraryTileIndex extends Component<RouteComponentProps> {
	@observable
	private index: JSX.Element;

	@observable
	private search: ISearch = { searchTerm: '' };

	public componentDidMount(): void {
		this.getLibraryArticles();
	}

	private async getLibraryArticles() {
		await store.apolloClient
			.query({
				query: getBooksWithArticles(),
			})
			.then((d) => {
				this.renderIndex(d.data.booksEntitys);
			})
			.catch(() => alert('Could not retrieve content'));
	}

	@action
	private renderIndex(books: any) {
		let bookContainers = books.map((book: any) => {
			// Super admins can see all books
			if (store.userGroups.find((group) => group.name === 'Admin')) {
				return <BookContainerIndex key={book.id} title={book.name} articles={book.learningss} />;
			}
			// Check if the user have access to the book
			if (store.userGroups.find((group) => group.name === 'Patient') && book.patient == true) {
				return <BookContainerIndex key={book.id} title={book.name} articles={book.learningss} />;
			} else if (store.userGroups.find((group) => group.name === 'Clinician') && book.clinician == true) {
				return <BookContainerIndex key={book.id} title={book.name} articles={book.learningss} />;
			} else if (store.userGroups.find((group) => group.name === 'Carer') && book.carer == true) {
				return <BookContainerIndex key={book.id} title={book.name} articles={book.learningss} />;
			} else {
				return <></>;
			}
		});

		this.index = bookContainers;
	}

	@action
	private handleSearch = () => {
		let state = { search: this.search.searchTerm, path: '/library/index', focus: true };
		store.routerHistory.push('/library/all', state);
	};

	render() {
		let focus = this.props.location.state == true ? true : false;

		let contents = (
			<>
				<div className="library-header">
					<div>
						<h6>Home / Library /</h6>
						<h2>Library Index</h2>
					</div>
					<div className="library-all-back">
						{/* Search Bar */}
						<div className="search-box">
							<Button icon={{ icon: 'search', iconPos: 'icon-left' }} />
						</div>
						<TextField
							model={this.search}
							modelProperty="searchTerm"
							className="article-search"
							placeholder="Search..."
							onAfterChange={this.handleSearch}
							autoFocus={focus}
						/>
					</div>
				</div>

				<div className="back-fav">
					<Link to="/library" className="icon-arrow-left icon-left">
						Back
					</Link>
				</div>

				<div className="index-container">{this.index}</div>
			</>
		);
		return contents;
	}
}
