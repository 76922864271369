import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Animated } from 'react-animated-css';

interface IOnboardingGoalsSavedProps {
	incrementStep: () => void;
}
export class OnboardingGoalsSaved extends Component<IOnboardingGoalsSavedProps> {
	render() {
		const { incrementStep } = this.props;
		return (
			<Animated animationIn="fadeInUp" isVisible={true} animationOut="fadeInDown">
				<div className="onboarding-framework-set">

					<h3 className="onboarding-goals-saved-text">We are preparing some content tailored just for you.</h3>
					<div className="onboarding-plus-new">
						<img className="plus-image" src={process.env.PUBLIC_URL + "/Goals/Plus2x.png"} />
						<div className="onboarding-loader" />
					</div>
				</div>
				<div style={{ display: "none" }}>{setTimeout(() => incrementStep(), 6000)}</div>
			</Animated>
		)
	}
}

export default OnboardingGoalsSaved
