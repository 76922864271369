import * as React from 'react';
import { observer } from 'mobx-react';
import MarkdownIt from 'markdown-it';
import MdEditor from 'react-markdown-editor-lite';
import 'react-markdown-editor-lite/lib/index.css';
import { observable, action } from 'mobx';
import If from '../If/If';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Display } from '../Button/Button';
import { store } from 'Models/Store';

export interface ArticleActivityContentProps<T> extends RouteComponentProps {
	article: any;
}

@observer
export class ArticleActivityContent<T> extends React.Component<ArticleActivityContentProps<T>, any> {
	mdParser: MarkdownIt;

	constructor(props: any) {
		super(props);

		this.mdParser = new MarkdownIt();
		this.mdParser.set({ html: true, linkify: true, typographer: true });

		var emoji = require('markdown-it-emoji');
		var underline = require('markdown-it-plugin-underline');
		this.mdParser.use(emoji);
		this.mdParser.use(underline);

		this.state = { content: this.props.article.content };
	}

	public render() {
		return (
			<>
				<MdEditor
					value={this.props.article.content}
					renderHTML={(text: string) => this.mdParser.render(text)}
					config={{
						view: {
							menu: false,
							md: false,
							html: true,
						},
						canView: {
							hideMenu: false,
							menu: false,
							md: false,
							html: false,
						},
					}}
				/>
			</>
		);
	}
}
