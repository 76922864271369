/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import { SERVER_URL } from 'Constants';
import * as React from 'react';
import { action, observable, runInAction } from 'mobx';
import { Model, IModelAttributes, attribute, entity } from 'Models/Model';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from "Util/AttributeUtils";
import { IAcl } from 'Models/Security/IAcl';
import {
	makeFetchManyToManyFunc,
	makeJoinEqualsFunc,
	makeFetchOneToManyFunc,
	makeEnumFetchFunction,
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import { VisitorsRatingpersonalEntity } from 'Models/Security/Acl/VisitorsRatingpersonalEntity';
import { PatientRatingpersonalEntity } from 'Models/Security/Acl/PatientRatingpersonalEntity';
import { ClinicianRatingpersonalEntity } from 'Models/Security/Acl/ClinicianRatingpersonalEntity';
import { CarerRatingpersonalEntity } from 'Models/Security/Acl/CarerRatingpersonalEntity';
import { AdminRatingpersonalEntity } from 'Models/Security/Acl/AdminRatingpersonalEntity';
import * as Enums from '../Enums';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import { FormEntityData, FormEntityDataAttributes, getAllVersionsFn, getPublishedVersionFn } from 'Forms/FormEntityData';
import { FormVersion } from 'Forms/FormVersion';
import { fetchFormVersions, fetchPublishedVersion } from 'Forms/Forms';
import {SuperAdministratorScheme} from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] on begin
import { v4 as uuidv4 } from "uuid";
// % protected region % [Add any further imports here] end

export interface IRatingpersonalEntityAttributes extends IModelAttributes, FormEntityDataAttributes {
	name: string;
	frequency: Enums.frequency;
	description: string;
	featureImageId: string;
	featureImage: Blob;
	bannerId: string;
	banner: Blob;

	formPages: Array<Models.RatingpersonalEntityFormTileEntity | Models.IRatingpersonalEntityFormTileEntityAttributes>;
	userss: Array<Models.UsersRatings | Models.IUsersRatingsAttributes>;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('RatingpersonalEntity', 'RatingPersonal')
// % protected region % [Customise your entity metadata here] end
export default class RatingpersonalEntity extends Model implements IRatingpersonalEntityAttributes, FormEntityData  {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new VisitorsRatingpersonalEntity(),
		new PatientRatingpersonalEntity(),
		new ClinicianRatingpersonalEntity(),
		new CarerRatingpersonalEntity(),
		new AdminRatingpersonalEntity(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	// % protected region % [Modify props to the crud options here for attribute 'Name'] on begin
	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		name: "Name",
		displayType: "textfield",
		headerColumn: true,
		searchable: true,
		searchFunction: "like",
		searchTransform: AttrUtils.standardiseString,
		order: 1,
	})
	public name: string;
	// % protected region % [Modify props to the crud options here for attribute 'Name'] end

	// % protected region % [Modify props to the crud options here for attribute 'Frequency'] on begin
	/* Frequency */
	@observable
	@attribute()
	@CRUD({
		name: "Frequency",
		displayType: "enum-combobox",
		headerColumn: true,
		searchable: true,
		searchFunction: "equal",
		searchTransform: (attr: string) => {
			return AttrUtils.standardiseEnum(attr, Enums.frequencyOptions);
		},
		enumResolveFunction: makeEnumFetchFunction(Enums.frequencyOptions),
		displayFunction: (attribute: Enums.frequency) => Enums.frequencyOptions[attribute],
		order: 2,
	})
	public frequency: Enums.frequency;
	// % protected region % [Modify props to the crud options here for attribute 'Frequency'] end

	// % protected region % [Modify props to the crud options here for attribute 'Description'] off begin
	/**
	 * Description for the rating
	 */
	@observable
	@attribute()
	@CRUD({
		name: 'Description',
		displayType: 'textfield',
		order: 30,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public description: string;
	// % protected region % [Modify props to the crud options here for attribute 'Description'] end

	// % protected region % [Modify props to the crud options here for attribute 'Feature Image'] off begin
	/**
	 * Image for the form button
	 */
	@observable
	@attribute({file: 'featureImage'})
	@CRUD({
		name: 'Feature Image',
		displayType: 'file',
		order: 40,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseUuid,
		inputProps: {
			imageOnly: true,
		},
		fileAttribute: 'featureImage',
		displayFunction: attr => attr 
			? <img src={`${SERVER_URL}/api/files/${attr}`} alt='Image for the form button' style={{maxWidth: '300px'}} />
			: 'No File Attached',
	})
	public featureImageId: string;
	@observable
	public featureImage: Blob;
	// % protected region % [Modify props to the crud options here for attribute 'Feature Image'] end

	// % protected region % [Modify props to the crud options here for attribute 'Banner'] off begin
	/**
	 * Banner for the form
	 */
	@observable
	@attribute({file: 'banner'})
	@CRUD({
		name: 'Banner',
		displayType: 'file',
		order: 50,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseUuid,
		inputProps: {
			imageOnly: true,
		},
		fileAttribute: 'banner',
		displayFunction: attr => attr 
			? <img src={`${SERVER_URL}/api/files/${attr}`} alt='Banner for the form' style={{maxWidth: '300px'}} />
			: 'No File Attached',
	})
	public bannerId: string;
	@observable
	public banner: Blob;
	// % protected region % [Modify props to the crud options here for attribute 'Banner'] end

	@observable
	@attribute({isReference: true})
	public formVersions: FormVersion[] = [];

	@observable
	@attribute()
	public publishedVersionId?: string;

	@observable
	@attribute({isReference: true})
	public publishedVersion?: FormVersion;

	@observable
	@attribute({isReference: true})
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Form Page'] on begin
		name: "Form Pages",
		displayType: "hidden",
		referenceTypeFunc: () => Models.RatingpersonalEntityFormTileEntity,
		disableDefaultOptionRemoval: true,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: "formPages",
			oppositeEntity: () => Models.RatingpersonalEntityFormTileEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Form Page'] end
	})
	public formPages: Models.RatingpersonalEntityFormTileEntity[] = [];

	/**
	 * Ratings the a user will complete
	 */
	@observable
	@attribute({isReference: true})
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Users'] on begin
		name: "Users",
		displayType: "reference-multicombobox",
		isJoinEntity: true,
		referenceTypeFunc: () => Models.UsersRatings,
		optionEqualFunc: makeJoinEqualsFunc("usersId"),
		order: 3,
		referenceResolveFunction: makeFetchManyToManyFunc({
			entityName: "ratingpersonalEntity",
			oppositeEntityName: "patientEntity",
			relationName: "ratings",
			relationOppositeName: "users",
			entity: () => Models.RatingpersonalEntity,
			joinEntity: () => Models.UsersRatings,
			oppositeEntity: () => Models.PatientEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Users'] end
	})
	public userss: Models.UsersRatings[] = [];

	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<IRatingpersonalEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<IRatingpersonalEntityAttributes>) {
		// % protected region % [Override assign attributes here] on begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.frequency) {
				this.frequency = attributes.frequency;
			}
			if (attributes.description !== undefined) {
				this.description = attributes.description;
			}
			if (attributes.featureImage !== undefined) {
				this.featureImage = attributes.featureImage;
			}
			if (attributes.featureImageId !== undefined) {
				this.featureImageId = attributes.featureImageId;
			}
			if (attributes.banner !== undefined) {
				this.banner = attributes.banner;
			}
			if (attributes.bannerId !== undefined) {
				this.bannerId = attributes.bannerId;
			}
			if (attributes.publishedVersion) {
				if (typeof attributes.publishedVersion.formData === "string") {
					attributes.publishedVersion.formData = JSON.parse(attributes.publishedVersion.formData);
				}
				this.publishedVersion = attributes.publishedVersion;
				this.publishedVersionId = attributes.publishedVersion.id;
			} else if (attributes.publishedVersionId !== undefined) {
				this.publishedVersionId = attributes.publishedVersionId;
			}
			if (attributes.formVersions) {
				this.formVersions.push(...attributes.formVersions);
			}
			if (attributes.name) {
				this.name = attributes.name;
			}
			if (attributes.formPages) {
				for (const model of attributes.formPages) {
					if (model instanceof Models.RatingpersonalEntityFormTileEntity) {
						this.formPages.push(model);
					} else {
						this.formPages.push(new Models.RatingpersonalEntityFormTileEntity(model));
					}
				}
			}
			if (attributes.userss) {
				for (const model of attributes.userss) {
					if (model instanceof Models.UsersRatings) {
						this.userss.push(model);
					} else {
						this.userss.push(new Models.UsersRatings(model));
					}
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] on begin
			if (attributes.id) {
				this.id = attributes.id;
			}
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] off begin
	public defaultExpands = `
		publishedVersion {
			id
			created
			modified
			formData
		}
		userss {
			${Models.UsersRatings.getAttributes().join('\n')}
			users {
				${Models.PatientEntity.getAttributes().join('\n')}
			}
		}
		formPages {
			${Models.RatingpersonalEntityFormTileEntity.getAttributes().join('\n')}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] off begin
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath = {
			userss: {},
			formPages: {},
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
							'userss',
						]
					},
				],
				contentType: 'multipart/form-data',
			}
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] off begin
		return this.name;
		// % protected region % [Customise the display name for this entity] end
	}

	/**
	 * Gets all the versions for this form.
	 */
	public getAllVersions: getAllVersionsFn = (includeSubmissions?, conditions?) => {
		// % protected region % [Modify the getAllVersionsFn here] off begin
		return fetchFormVersions(this, includeSubmissions, conditions)
			.then(d => {
				runInAction(() => this.formVersions = d);
				return d.map(x => x.formData)
			});
		// % protected region % [Modify the getAllVersionsFn here] end
	};

	/**
	 * Gets the published version for this form.
	 */
	public getPublishedVersion: getPublishedVersionFn = includeSubmissions => {
		// % protected region % [Modify the getPublishedVersionFn here] off begin
		return fetchPublishedVersion(this, includeSubmissions)
			.then(d => {
				runInAction(() => this.publishedVersion = d);
				return d ? d.formData : undefined;
			});
		// % protected region % [Modify the getPublishedVersionFn here] end
	};

	/**
	 * Gets the submission entity type for this form.
	 */
	public getSubmissionEntity = () => {
		// % protected region % [Modify the getSubmissionEntity here] off begin
		return Models.RatingpersonalSubmissionEntity;
		// % protected region % [Modify the getSubmissionEntity here] end
	}


	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [ createdAttr, modifiedAttr ] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(RatingpersonalEntity.prototype, 'created');
CRUD(modifiedAttr)(RatingpersonalEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
