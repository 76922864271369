import React from "react";
import useRainbow from '../../../Hooks/UseGradient';

interface IYHPGradientProps {
	className?: string;
	children?: React.ReactNode;
	onClick?: () => void;
}

const YHPGradient = (props: IYHPGradientProps) => {
	const intervalDelay = 2000;
	const colors = useRainbow({ intervalDelay });
	const colorKeys = Object.keys(colors);
	const transitionDelay = intervalDelay * 1.25;

	return (
		<div
			onClick={props.onClick}
			className={props.className}
			style={{
				...colors,
				transition: `
					${colorKeys[0]} ${transitionDelay}ms linear,
					${colorKeys[1]} ${transitionDelay}ms linear,
					${colorKeys[2]} ${transitionDelay}ms linear
					`,
				background: `
					radial-gradient(
					circle at top right,
					var(${colorKeys[2]}),
					var(${colorKeys[1]}),
					var(${colorKeys[0]})
					)
					`,
			}}>
			{props.children}
		</div>
	);
};

export default YHPGradient;
