import { range } from 'lodash';
import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import useIncrementingNumber from './useIncrementingNumber';

const gradientColors = [
	'#377995', // Deep blue
	'#46AFDB', // Blue
	'#975FB0', // Purple
	'#DD3B83', // Pink
	'#FB6470', // Salmon
	'#DD3B83', // Pink
	'#975FB0', // Purple
	'#46AFDB', // Blue
];

const WINDOW_SIZE = 3;

// During compile-time build, we have to assume no browser support.
// On mount, we'll check if `CSS.registerProperty` exists
const hasBrowserSupport =
	// @ts-ignore
	typeof window !== 'undefined' && typeof window.CSS != 'undefined' ? typeof window.CSS.registerProperty === 'function' : false;

const getColorPropName = (id: string, index: number) =>
	`--magic-rainbow-color-${id}-${index}`;

const useRainbow = ({ intervalDelay = 2000 }) => {
	const prefersReducedMotion =
		typeof window === 'undefined'
			? true
			: window.matchMedia('(prefers-reduced-motion: no-preference)');

	// @ts-ignore
	const isEnabled = hasBrowserSupport && prefersReducedMotion.matches;

	const { current: uniqueId } = React.useRef(uuidv4());

	// Register all custom properties
	React.useEffect(() => {
		if (!isEnabled) {
			return;
		}

		range(0, WINDOW_SIZE).map((index) => {
			const name = getColorPropName(uniqueId, index);
			const initialValue = gradientColors[index];
			// @ts-ignore
			CSS.registerProperty({
				name,
				initialValue,
				syntax: '<color>',
				inherits: false,
			});
		});
	}, [WINDOW_SIZE, isEnabled]);

	const intervalCount = useIncrementingNumber(intervalDelay);

	return range(0, WINDOW_SIZE).reduce((acc, index) => {
		const effectiveIntervalCount = isEnabled ? intervalCount : 0;

		const name = getColorPropName(uniqueId, index);
		const value =
			gradientColors[(effectiveIntervalCount + index) % gradientColors.length];

		return {
			...acc,
			[name]: value,
		};
	}, {});
};

export default useRainbow;
