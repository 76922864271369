import { action } from 'mobx';
import { store } from 'Models/Store';
import React, { Component } from 'react'
import { Button, Colors, Display } from '../Button/Button';

export default class OnboardingFinish extends Component {
	render() {
		return (
			<>
				<div >
					<div className="goals-hands-up">
						<img src={process.env.PUBLIC_URL + "/Goals/hands-up.png"} />
					</div>
					<h3>Done.</h3>
					<h5>Each day, we're going to provide you with check-ins, activities and articles that will assist you on your wellbeing journey.</h5>

					<div className="onboarding-wizard-footer">
						<Button
							display={Display.Round}
							colors={Colors.Black}
							className="btn--icon icon-arrow-right icon-right"
							onClick={() => {
								store.routerHistory.push('/');
							}}
						>Show me what's on today</Button>
					</div>
				</div>
			</>

		)
	}
}
