/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import { Model, IModelAttributes, attribute, entity } from 'Models/Model';
import * as Models from 'Models/Entities';
import { IAcl } from '../Security/IAcl';
import { observable } from 'mobx';
import { VisitorsActivitiesReferenceManyToMany } from '../Security/Acl/VisitorsActivitiesReferenceManyToMany';
import { PatientActivitiesReferenceManyToMany } from '../Security/Acl/PatientActivitiesReferenceManyToMany';
import { ClinicianActivitiesReferenceManyToMany } from '../Security/Acl/ClinicianActivitiesReferenceManyToMany';
import { CarerActivitiesReferenceManyToMany } from '../Security/Acl/CarerActivitiesReferenceManyToMany';
import { AdminActivitiesReferenceManyToMany } from '../Security/Acl/AdminActivitiesReferenceManyToMany';

// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface ITagsActivitiesAttributes extends IModelAttributes {
	tagsId: string;
	activitiesId: string;

	tags: Models.TagEntity | Models.ITagEntityAttributes;
	activities: Models.ActivitiesEntity | Models.IActivitiesEntityAttributes;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

@entity('TagsActivities')
export default class TagsActivities extends Model implements ITagsActivitiesAttributes {
	public static acls: IAcl[] = [
		new VisitorsActivitiesReferenceManyToMany(),
		new PatientActivitiesReferenceManyToMany(),
		new ClinicianActivitiesReferenceManyToMany(),
		new CarerActivitiesReferenceManyToMany(),
		new AdminActivitiesReferenceManyToMany(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	@observable
	@attribute()
	public tagsId: string;

	@observable
	@attribute()
	public activitiesId: string;

	@observable
	@attribute({isReference: true})
	public tags: Models.TagEntity;

	@observable
	@attribute({isReference: true})
	public activities: Models.ActivitiesEntity;
	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	constructor(attributes?: Partial<ITagsActivitiesAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		if (attributes) {
			if (attributes.tagsId) {
				this.tagsId = attributes.tagsId;
			}
			if (attributes.activitiesId) {
				this.activitiesId = attributes.activitiesId;
			}

			if (attributes.tags) {
				if (attributes.tags instanceof Models.TagEntity) {
					this.tags = attributes.tags;
					this.tagsId = attributes.tags.id;
				} else {
					this.tags = new Models.TagEntity(attributes.tags);
					this.tagsId = this.tags.id;
				}
			} else if (attributes.tagsId !== undefined) {
				this.tagsId = attributes.tagsId;
			}

			if (attributes.activities) {
				if (attributes.activities instanceof Models.ActivitiesEntity) {
					this.activities = attributes.activities;
					this.activitiesId = attributes.activities.id;
				} else {
					this.activities = new Models.ActivitiesEntity(attributes.activities);
					this.activitiesId = this.activities.id;
				}
			} else if (attributes.activitiesId !== undefined) {
				this.activitiesId = attributes.activitiesId;
			}
		}

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}