import { store } from 'Models/Store';
import React  from 'react'
import { RouteComponentProps } from 'react-router';

import GoalFormWizard from 'Views/Components/Goals/GoalWizard/GoalFormWizard';
import Navigation, { Orientation } from 'Views/Components/Navigation/Navigation';
import SecuredPage from 'Views/Components/Security/SecuredPage';
import { getFrontendNavLinks } from 'Views/FrontendNavLinks';

export default class GoalsCreatePage extends React.Component<RouteComponentProps> {
    render() {
        const { history, location, match, staticContext } = this.props;
        const navigationLinks = getFrontendNavLinks(this.props);

        const contents = (
            <SecuredPage groups={["Clinician", "Patient"]}>
                <Navigation
                    linkGroups={navigationLinks}
                    orientation={Orientation.VERTICAL}
                    match={match}
                    location={location}
                    history={history}
                    staticContext={staticContext}
                />

                <div className="goals-create body-content">
					<GoalFormWizard onDone={() => { store.routerHistory.push("/goals") }} />
                </div>
            </SecuredPage>
        );

        return contents;
    }
}
