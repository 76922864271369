import * as React from "react";
import { observer } from "mobx-react";
import MarkdownIt from "markdown-it";
import MdEditor from "react-markdown-editor-lite";
import "react-markdown-editor-lite/lib/index.css";
import { observable, action, runInAction } from "mobx";
import If from "../If/If";
import { Link, RouteComponentProps } from "react-router-dom";
import { PatientEntity, ArticlesEntity, FavouritedbypatientsFavouritearticles } from "Models/Entities";
import { Button, Display } from "../Button/Button";
import { store } from "Models/Store";
import alert from "Util/ToastifyUtils";
import ArticleService from 'Services/ArticleService';
import ArticleReadService from "Services/ArticleReadService";
import BooksEntity from '../../../Models/Entities/BooksEntity';
import BooksService from '../../../Services/BooksService';
import smartlookClient from "smartlook-client";
import PatientService from "Services/PatientService/PatientService";
import { PatientExpands } from "Services/PatientService/PatientEntityExpands";

const MD_EDITOR_CONFIG = {
	view: {
		menu: false,
		md: false,
		html: true,
	},
	canView: {
		hideMenu: false,
		menu: false,
		md: false,
		html: false,
	},
}

export interface ArticleContentProps<T> extends RouteComponentProps {
	articleId: string;
}

@observer
export class ArticleContent<T> extends React.Component<ArticleContentProps<T>, any> {
	@observable
	private heart: "heart-large" | "heart-fill" = "heart-large";

	@observable
	private previousArticle: any;

	@observable
	private nextArticle: any;

	@observable
	private recommendedArticles: ArticlesEntity[] = [];

	@observable
	private patientEntity: PatientEntity = new PatientEntity();

	@observable
	private currentArticle: ArticlesEntity = new ArticlesEntity();

	mdParser: MarkdownIt;

	// Observable that assumes whether the user has finished reading the article or not. Based on 10 seconds.
	@observable
	userHasReadArticle: boolean;

	constructor(props: any) {
		super(props);

		this.mdParser = new MarkdownIt();
		this.mdParser.set({ html: true });

		var emoji = require("markdown-it-emoji");
		var underline = require("markdown-it-plugin-underline");
		this.mdParser.use(emoji);
		this.mdParser.use(underline);
	}

	componentDidMount() {
		this.fetchData()
	}

	fetchData() {
		this.getPatient();
		this.getArticle();

		setTimeout(() => {
			this.userHasReadArticle = true;
		}, 15_000)

	}

	componentDidUpdate(prevProps: any) {
		if (this.props.articleId !== prevProps.articleId) {
			this.fetchData();
		}
	}

	componentWillUnmount() {
		this.checkAndUpdateArticleReadCount(this.props.articleId);
	}

	private async getPatient() {
		if (store.userId == null) {
			return;
		}
		try {
			PatientService.getPatient(store.userId, PatientExpands.patientArticlesPage)
				.then
				((res) => {
					runInAction(() => {
						this.setPatient(res)
					})
				});
		} catch (error) {
			console.log(error);
		}
	}

	@action
	private setPatient = (results: PatientEntity[]) => {
		this.patientEntity = results[0];
		if (store.userGroups[0].name !== "Patient") return;
		if (this.patientEntity.favouritearticless.some((fav: any) => fav.favouritearticlesId == this.currentArticle.id)) {
			this.heart = "heart-fill";
		} else {
			this.heart = "heart-large";
		}
	};

	private async getArticle() {
		try {
			ArticleService.getArticle(this.props.articleId).then((res) => {
				runInAction(() => {
					this.currentArticle = new ArticlesEntity(res[0]);
					if (this.currentArticle.booksId) {
						this.getBooks(this.currentArticle.booksId)
					}
				})
			})
		} catch (error) {
			console.log(error);
		}
	}


	private async checkAndUpdateArticleReadCount(id: any) {
		if (!this.userHasReadArticle || id === "") {
			return;
		}

		this.userHasReadArticle = false;

		ArticleReadService.saveArticleReadEvent(id)
			.then(() => console.log("Successfully logged article read event"))
			.catch(() => console.warn(`Unsuccessfully logged article read event`))
	}

	@action
	async getBooks(booksId: string) {
		BooksService.read(booksId)
			.then((books) => {
				runInAction(() => {
					this.previousArticle = null
					this.nextArticle = null;
					this.recommendedArticles = [];
				})

				var book = new BooksEntity(books[0]);
				let articles = book.learningss;
				this.recommendedArticles = articles.slice(0, 3);

				articles.forEach((article: ArticlesEntity, index: any) => {
					// Get previous and next articles
					if (article.id === this.props.articleId) {
						if (articles[index - 1]) {
							this.setPreviousArticle(articles[index - 1]);
						}
						if (articles[index + 1]) {
							this.setNextArticle(articles[index + 1]);
						}
					}
				});

			})
			.catch(() => {
			})
	}

	@action
	setPreviousArticle = (article: ArticlesEntity) => {
		this.previousArticle = article;
	};

	@action
	setNextArticle = (article: ArticlesEntity) => {
		this.nextArticle = article;
	};

	@action
	renderRecommendedReadings = () => {
		return (
			<div className="recommended-reading">
				<h4>Recommended Reading</h4>
				<div className="recommended-articles-container">
					{this.recommendedArticles.map((article: any, index: any) => (
						<div key={index} className="recommended-article" onClick={() => this.goToArticle(article.id)}>
							{article.title}
						</div>
					))}
				</div>
			</div>
		);
	};

	goToArticle = (id: string) => {
		this.checkAndUpdateArticleReadCount(this.currentArticle.id);
		if (id === "") {
			store.routerHistory.push("/library");
		} else {
			store.routerHistory.push(`/article/${id}`);
		}
	};

	@action
	private handleFavourite = (article: ArticlesEntity) => {
		smartlookClient.track('Library_Add_Favourite', {});
		if (store.userGroups[0].name !== "Patient") return;
		let added: boolean = true;
		if (this.patientEntity.favouritearticless.some((fav: any) => fav.favouritearticlesId == article.id)) {
			this.patientEntity.favouritearticless = this.patientEntity.favouritearticless.filter(
				(fav: any) => fav.favouritearticlesId !== article.id
			);
			added = false;
		} else {
			// Creating entity for FavouritedbypatientsFavouritearticles
			let favouritedbypatientsFavouritearticles = new FavouritedbypatientsFavouritearticles({
				favouritearticles: this.currentArticle,
				favouritedbypatientsId: this.patientEntity.id,
				favouritearticlesId: this.props.articleId,
				favouritedbypatients: this.patientEntity,
			});

			this.patientEntity.favouritearticless.push(favouritedbypatientsFavouritearticles);
		}
		this.heart = this.heart == "heart-fill" ? "heart-large" : "heart-fill";
		const relationPath = {
			favouritearticless: {},
		};

		this.patientEntity
			.save(relationPath, {
				options: [
					{
						key: "mergeReferences",
						graphQlType: "[String]",
						value: ["favouritearticless"],
					},
				],
				contentType: "multipart/form-data",
			})
			.then(() => {
				alert(added ? "Saved to favourites!" : "Removed from favourites");
			});
	};

	public render() {
		return (
			<>
				<div className="library-header">
					<div>
						<h6>Library</h6>
						<h2>{this.currentArticle.title}</h2>
					</div>
				</div>

				{this.renderArticleTopbar()}

				<MdEditor
					key={this.currentArticle.id}
					value={this.currentArticle.content}
					renderHTML={(text: string) => this.mdParser.render(text)}
					config={MD_EDITOR_CONFIG}
				/>

				<hr className="dotted-hr" />

				<div className="previous-next">
					<div className="previous-article"
					     onClick={() => {
						     this.goToArticle(this.previousArticle ? this.previousArticle.id : "")
					     }}>
						<Button display={Display.Solid} icon={{ icon: "arrow-left", iconPos: "icon-left" }} />
						<div className="previous-text">
							<p>Previous</p>
							<b>{this.previousArticle ? this.previousArticle.title : "Return to Library"}</b>
						</div>
					</div>

					<div className="next-article"
					     onClick={() => {
						     this.goToArticle(this.nextArticle ? this.nextArticle.id : "")
					     }}>
						<div className="next-text">
							<p>Up Next</p>
							<b>{this.nextArticle ? this.nextArticle.title : "Return to Library"}</b>
						</div>
						<Button display={Display.Solid} icon={{ icon: "arrow-right", iconPos: "icon-left" }} />
					</div>
				</div>

				<hr className="dotted-hr" />

				{this.renderRecommendedReadings()}
			</>
		);
	}

	renderArticleTopbar = () => {
		let backLink: any = this.props.location.state ? this.props.location.state : "/library";
		return (
			<div className="back-fav">
				<Link to={backLink} className="icon-arrow-left icon-left">
					Back
				</Link>

				<If condition={store.userGroups[0].name === "Patient"}>
					<Link
						to={this.props.location}
						className={"icon-" + this.heart + " icon-left"}
						onClick={() => this.handleFavourite(this.currentArticle)}>
						Mark as favourite
					</Link>
				</If>
			</div>
		)
	}
}
