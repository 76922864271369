/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import * as React from 'react';
import { action } from 'mobx';
import { store } from "Models/Store";
import { Route, RouteComponentProps, Switch, Redirect } from 'react-router';
import * as Pages from './Pages';
import Logout from "./Components/Logout/Logout";
import Auth from "./Components/Auth/Auth";
import PageNotFound from './Pages/PageNotFound';
import PageAccessDenied from './Pages/PageAccessDenied';
import Topbar from "./Components/Topbar/Topbar";
// % protected region % [Add any extra imports here] on begin
import ElementsPage from './Pages/ElementsPage'
import ImpersonateTopBar from "./Components/Topbar/ImpersonateTopBar";
import { navigateToAppIfMobile } from 'Util/MobileUtils';
// % protected region % [Add any extra imports here] end

export default class Frontend extends React.Component<RouteComponentProps> {
	@action
	private setAppLocation = () => {
		store.appLocation = 'frontend';
	}

	public componentDidMount() {
		this.setAppLocation();
	}

	public render() {
		const path = this.props.match.path === '/' ? '' : this.props.match.path;

		// % protected region % [Add any extra render logic here] on begin
		let homePath = "";
		if (store.userGroups.find((group)=> group.name === "Admin")) {
			homePath = "admin-dashboard"
		}
		else if(store.userGroups.find((group)=> group.name === "Clinician") && store.userGroups.find((group)=> group.name !== "Admin")) {
			homePath = "clinician-dashboard"
		}
		else {
			homePath = "dashboard"
		}

		navigateToAppIfMobile(this.props.location.pathname, this.props.location.search);

		// % protected region % [Add any extra render logic here] end

		return (
			<>
				<div className="body-container">
					{
					// % protected region % [Modify Topbar] on begin
					}
					<ImpersonateTopBar currentLocation="frontend" />
					{
					// % protected region % [Modify Topbar] end
					}
					{
					// % protected region % [Modify Frontend] off begin
					}
					<div className="frontend">
					{
					// % protected region % [Modify Frontend] end
					}
						{
						// % protected region % [Add any header content here] off begin
						}
						{
						// % protected region % [Add any header content here] end
						}
						<>
							<Switch>
								{/* Public routes */}
								{
								// % protected region % [customize the universal public routes] on begin
								}
								<Route path="/login" component={Pages.LoginPage} />
								<Route path="/register" component={Pages.RegistrationPage} />
								<Route path="/confirm-email" component={Pages.RegistrationConfirmPage} />
								<Route path="/reset-password-request" component={Pages.ResetPasswordRequestPage} />
								<Route path="/reset-password" component={Pages.ResetPasswordPage} />
								<Route path="/logout" component={Logout} />
								<Route path="/onboarding" component={Pages.NewUserOnboardingPage} />
								<Route exact path={`${path}/profile-creation-page`} component={Pages.ProfileCreationPage} />
								{
									// % protected region % [customize the universal public routes] end
								}


								<Route path={`${path}/404`} component={PageNotFound} />
								<Route path={`${path}/403`} component={PageAccessDenied} />

								{
								// % protected region % [Add additional routes here] on begin
								}
								{
									process.env.NODE_ENV === 'development' && (
										<Route path="/elements" component={ElementsPage} />)
								}
								<Route path="/check-your-email" component={Pages.CheckEmailPage} />
								{
									// % protected region % [Add additional routes here] end
								}

								<Auth {...this.props}>
									<Switch>
										{/* These routes require a login to view */}
										{/* Pages from the ui model */}
										{
										// % protected region % [customize the page routes] on begin
										}
										<Redirect exact={true} from={`/`} to={`${path}/${homePath}`} />
										<Route path={`${path}/dashboard`} component={Pages.DashboardPage} />
										<Route path={`${path}/admin-dashboard`} component={Pages.DashboardPage} />
										<Route path={`${path}/clinician-dashboard`} component={Pages.DashboardPage} />
										<Route path={`${path}/carer`} component={Pages.CustomCarerPage} />
										<Route exact path={`${path}/patient`} component={Pages.CustomPatientPage} />
										<Route path={`${path}/clinician`} component={Pages.CustomClinicianPage} />
										<Route exact path={`${path}/library/all`} component={Pages.LibraryPageAll} />
										<Route path={`${path}/library`} component={Pages.LibraryPage} />
										<Route path={`${path}/activities`} component={Pages.ActivitiesPage} />
										<Route path={`${path}/activity`} component={Pages.ActivityPage} />
										{
											// % protected region % [customize the page routes] end
										}
										{
										// % protected region % [Add any extra page routes here] on begin
										}
										<Route exact path={`${path}/patient/create`} component={Pages.PatientCreatePage} />
										<Route exact path={`${path}/goals/create`} component={Pages.GoalsCreatePage} />
										<Route exact path={`${path}/goals`} component={Pages.GoalsPage} />
										<Route exact path={`${path}/article/:id`} component={Pages.ArticlePage} />
										<Route exact path={`${path}/profile/:entity/:id`} component={Pages.ProfilePage} />
										<Route exact path={`${path}/profile/:id`} component={Pages.ProfilePage} />

										<Route path={`${path}/check-in/overview/:id/:mode/:submission`} component={Pages.CheckInOverviewPage} />
										<Route path={`${path}/check-in/overview/:id`} component={Pages.CheckInOverviewPage} />
										<Route path={`${path}/check-in/:date/:id`} component={Pages.CheckInFormPage} />
										<Route path={`${path}/check-in`} component={Pages.CheckInPage} />
										<Route path={`${path}/submission/:id`} component={Pages.FormSubmissionPage} />
										<Route path={`${path}/to-do`} component={Pages.ToDoPage} />
										<Route path={`${path}/failedcsv`} component={Pages.FailedCSVPage} />
										<Route path={`${path}/failedCliniciancsv`} component={Pages.FailedClinicianCSVPage} />
										<Route exact path={`${path}/profile`} component={Pages.MyProfile} />
										<Route exact path={`${path}/goals/:id`} component={Pages.GoalsViewPage} />
										<Route path={`${path}/rateareasoflife`} component={Pages.RateAreasOfLife} />

										{
											// % protected region % [Add any extra page routes here] end
										}

										<Route component={PageNotFound} />
									</Switch>
								</Auth>
							</Switch>
						</>
						{
						// % protected region % [Add any footer content here] off begin
						}
						{
						// % protected region % [Add any footer content here] end
						}
					</div>
				</div>
			</>
		);
	}
}
