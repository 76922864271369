import * as Models from '../../Models/Entities';

export const PatientExpands = {
	/**
	 * Expands for the patients Profile
	 */
	patientProfile: `
		patientSocialMediaLinkss {
			${Models.SocialMediaLinkEntity.getAttributes().join('\n')}
		}
		carerss {
			${Models.CarersUsers.getAttributes().join('\n')}
			carers {
				${Models.CarerEntity.getAttributes().join('\n')}
			}
		}
		clinicianss {
			${Models.CliniciansUsers.getAttributes().join('\n')}
			clinicians {
				${Models.ClinicianEntity.getAttributes().join('\n')}
			}
		}
	`,
	/**
	 * Expands for clinician dashboard
	 */
	clinicianDashboard:`
		tagss {
			${Models.TagsUsertags.getAttributes().join('\n')}
			tags {
				${Models.TagEntity.getAttributes().join('\n')}
			}
		}
		demoAdminss {
			${Models.AdminEntity.getAttributes().join('\n')}
		}
		demoClinicianss {
			${Models.ClinicianEntity.getAttributes().join('\n')}
		}
	`,

	/**
	 * Expands for patient onboarding
	 */
	patientOnboarding: `
		goalss {
			${Models.GoalsEntity.getAttributes().join('\n')}
		}
		areaOfLifeRatingss {
			${Models.AreaOfLifeRatingsEntity.getAttributes().join('\n')}
		}
	`,
	/**
	 * patientGoalsPage
	 */
	patientGoalsPage: `
		goalss {
			${Models.GoalsEntity.getAttributes().join('\n')}
		}
		areaOfLifeRatingss {
			${Models.AreaOfLifeRatingsEntity.getAttributes().join('\n')}
		}
	`,
	/**
	 * patientArticlesPage
	 */
	patientArticlesPage: `
		favouritearticless {
			${Models.FavouritedbypatientsFavouritearticles.getAttributes().join('\n')}
			favouritearticles {
				${Models.ArticlesEntity.getAttributes().join('\n')}
				${Models.ArticlesEntity.getFiles().map(f => f.name).join('\n')}
			}
		}
	 `,
	/**
	 * Expands for patient re-rating all their areas of life.
	 */
	patientRateAllAreaOfLife: `
		areaOfLifeRatingss {
			${Models.AreaOfLifeRatingsEntity.getAttributes().join('\n')}
		}
	`,
	/**
	 * Expands for patient onboarding
	 */
	patientProfileGoals: `
		goalss {
			${Models.GoalsEntity.getAttributes().join('\n')}
			goalStepss {
				${Models.GoalStepEntity.getAttributes().join('\n')}
			}
		}
		areaOfLifeRatingss {
			${Models.AreaOfLifeRatingsEntity.getAttributes().join('\n')}
		}
	`,
	/**
	 * Expands for patient create
	 */
	patientCreate: `
		ratingss {
			${Models.UsersRatings.getAttributes().join('\n')}
			ratings {
				${Models.RatingpersonalEntity.getAttributes().join('\n')}
				${Models.RatingpersonalEntity.getFiles().map(f => f.name).join('\n')}
			}
		}
		ratingtemplatess {
			${Models.RatingtemplatesPatients.getAttributes().join('\n')}
			ratingtemplates {
				${Models.RatingtemplateEntity.getAttributes().join('\n')}
				${Models.RatingtemplateEntity.getFiles().map(f => f.name).join('\n')}
			}
		}
	`,
}
