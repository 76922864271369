/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import { SERVER_URL } from 'Constants';
import * as React from 'react';
import { action, observable } from 'mobx';
import { Model, IModelAttributes, attribute, entity } from 'Models/Model';
import {IOrderByCondition} from 'Views/Components/ModelCollection/ModelQuery';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from "Util/AttributeUtils";
import { IAcl } from 'Models/Security/IAcl';
import {
	makeFetchManyToManyFunc,
	makeJoinEqualsFunc,
	makeFetchOneToManyFunc,
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import { VisitorsArticlesEntity } from 'Models/Security/Acl/VisitorsArticlesEntity';
import { PatientArticlesEntity } from 'Models/Security/Acl/PatientArticlesEntity';
import { ClinicianArticlesEntity } from 'Models/Security/Acl/ClinicianArticlesEntity';
import { CarerArticlesEntity } from 'Models/Security/Acl/CarerArticlesEntity';
import { AdminArticlesEntity } from 'Models/Security/Acl/AdminArticlesEntity';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import {SuperAdministratorScheme} from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface IArticlesEntityAttributes extends IModelAttributes {
	featureId: string;
	feature: Blob;
	title: string;
	description: string;
	content: string;
	htmlcontent: string;
	reads: number;

	articleReadEventss: Array<Models.ArticleReadEventEntity | Models.IArticleReadEventEntityAttributes>;
	booksId?: string;
	books?: Models.BooksEntity | Models.IBooksEntityAttributes;
	article?: Models.FeaturedArticleEntity | Models.IFeaturedArticleEntityAttributes;
	activitiesId?: string;
	activities?: Models.ActivitiesEntity | Models.IActivitiesEntityAttributes;
	tagss: Array<Models.TagsArticles | Models.ITagsArticlesAttributes>;
	favouritedbypatientss: Array<Models.FavouritedbypatientsFavouritearticles | Models.IFavouritedbypatientsFavouritearticlesAttributes>;
	libraryTagss: Array<Models.ArticlesLibraryTags | Models.IArticlesLibraryTagsAttributes>;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('ArticlesEntity', 'Articles')
// % protected region % [Customise your entity metadata here] end
export default class ArticlesEntity extends Model implements IArticlesEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new VisitorsArticlesEntity(),
		new PatientArticlesEntity(),
		new ClinicianArticlesEntity(),
		new CarerArticlesEntity(),
		new AdminArticlesEntity(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	/**
	 * The default order by field when the collection is loaded .
	 */
	public get orderByField(): IOrderByCondition<Model> | undefined {
		// % protected region % [Modify the order by field here] off begin
		return {
			path: 'title',
			descending: false,
		};
		// % protected region % [Modify the order by field here] end
	}

	// % protected region % [Modify props to the crud options here for attribute 'Feature'] on begin
	/* Feature image to be displayed in the library */
	@observable
	@attribute({ file: "feature" })
	@CRUD({
		name: "Feature Image",
		displayType: "file",
		headerColumn: true,
		searchable: true,
		searchFunction: "equal",
		searchTransform: AttrUtils.standardiseUuid,
		inputProps: {
			imageOnly: true,
		},
		fileAttribute: "feature",
		displayFunction: (attr) =>
			attr ? (
				<img src={`${SERVER_URL}/api/files/${attr}`} style={{ maxWidth: "300px" }} />
			) : (
					"No File Attached"
				),
		order: 1,
	})
	public featureId: string;
	@observable
	public feature: Blob;
	// % protected region % [Modify props to the crud options here for attribute 'Feature'] end

	// % protected region % [Modify props to the crud options here for attribute 'Title'] on begin
	/* Title of the library article */
	@observable
	@attribute()
	@CRUD({
		name: "Title",
		displayType: "textfield",
		headerColumn: true,
		searchable: true,
		searchFunction: "like",
		searchTransform: AttrUtils.standardiseString,
		order: 3,
	})
	public title: string;
	// % protected region % [Modify props to the crud options here for attribute 'Title'] end

	// % protected region % [Modify props to the crud options here for attribute 'Description'] on begin
	/* Description for the library article */
	@observable
	@attribute()
	@CRUD({
		name: "Description",
		displayType: "textarea",
		headerColumn: false,
		searchable: true,
		searchFunction: "like",
		searchTransform: AttrUtils.standardiseString,
		order: 4,
	})
	public description: string;
	// % protected region % [Modify props to the crud options here for attribute 'Description'] end

	// % protected region % [Modify props to the crud options here for attribute 'Content'] on begin
	/* Content for the library article */
	@observable
	@attribute()
	@CRUD({
		name: "Content",
		displayType: "textarea",
		headerColumn: false,
		searchable: true,
		searchFunction: "like",
		searchTransform: AttrUtils.standardiseString,
		order: 5,
	})
	public content: string;
	// % protected region % [Modify props to the crud options here for attribute 'Content'] end

	// % protected region % [Modify props to the crud options here for attribute 'HtmlContent'] off begin
	/**
	 * HTML version of the content for the library article
	 */
	@observable
	@attribute()
	@CRUD({
		name: 'HtmlContent',
		displayType: 'textfield',
		order: 50,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public htmlcontent: string;
	// % protected region % [Modify props to the crud options here for attribute 'HtmlContent'] end

	// % protected region % [Modify props to the crud options here for attribute 'Reads'] on begin
	/**
	 * The number of time the article had been read.
	 */
	@Validators.Integer()
	@observable
	@attribute()
	@CRUD({
		name: 'Reads',
		displayType: 'textfield',
		order: 50,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseInteger,
	})
	public reads: number;
	// % protected region % [Modify props to the crud options here for attribute 'Reads'] end

	@observable
	@attribute({isReference: true})
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Article Read Events'] on begin
		name: "Article Read Eventss",
		displayType: 'hidden',
		order: 60,
		referenceTypeFunc: () => Models.ArticleReadEventEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'articleReadEventss',
			oppositeEntity: () => Models.ArticleReadEventEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Article Read Events'] end
	})
	public articleReadEventss: Models.ArticleReadEventEntity[] = [];

	/**
	 * Books contain many articles
	 */
	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Books'] on begin
		name: 'Book',
		displayType: 'reference-combobox',
		order: 2,
		referenceTypeFunc: () => Models.BooksEntity,
		// % protected region % [Modify props to the crud options here for reference 'Books'] end
	})
	public booksId?: string;
	@observable
	@attribute({isReference: true})
	public books: Models.BooksEntity;

	/**
	 * Article stored in the Feature Article entity.
	 */
	@observable
	@attribute({isReference: true})
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Article'] on begin
		name: 'Is Feature Article',
		displayType: 'reference-combobox',
		referenceTypeFunc: () => Models.FeaturedArticleEntity,
		optionEqualFunc: (model, option) => model.id === option,
		order: 6,
		inputProps: {
			fetchReferenceEntity: true,
		},
		// % protected region % [Modify props to the crud options here for reference 'Article'] end
	})
	public article?: Models.FeaturedArticleEntity;

	/**
	 * Articles for an activity
	 */
	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Activities'] on begin
		name: 'Activities',
		displayType: 'reference-combobox',
		referenceTypeFunc: () => Models.ActivitiesEntity,
		order: 8,
		// % protected region % [Modify props to the crud options here for reference 'Activities'] end
	})
	public activitiesId?: string;
	@observable
	@attribute({isReference: true})
	public activities: Models.ActivitiesEntity;

	@observable
	@attribute({isReference: true})
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Tags'] on begin
		name: 'Tags',
		displayType: 'reference-multicombobox',
		isJoinEntity: true,
		referenceTypeFunc: () => Models.TagsArticles,
		optionEqualFunc: makeJoinEqualsFunc('tagsId'),
		referenceResolveFunction: makeFetchManyToManyFunc({
			entityName: 'articlesEntity',
			oppositeEntityName: 'tagEntity',
			relationName: 'articles',
			relationOppositeName: 'tags',
			entity: () => Models.ArticlesEntity,
			joinEntity: () => Models.TagsArticles,
			oppositeEntity: () => Models.TagEntity,
		}),
		order: 7,
		// % protected region % [Modify props to the crud options here for reference 'Tags'] end
	})
	public tagss: Models.TagsArticles[] = [];

	/**
	 * Articles favourited by patients
	 */
	@observable
	@attribute({isReference: true})
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'FavouritedByPatients'] on begin
		name: 'FavouritedByPatients',
		displayType: 'hidden', //reference-multicombobox',
		order: 110,
		isJoinEntity: true,
		referenceTypeFunc: () => Models.FavouritedbypatientsFavouritearticles,
		optionEqualFunc: makeJoinEqualsFunc('favouritedbypatientsId'),
		referenceResolveFunction: makeFetchManyToManyFunc({
			entityName: 'articlesEntity',
			oppositeEntityName: 'patientEntity',
			relationName: 'favouritearticles',
			relationOppositeName: 'favouritedbypatients',
			entity: () => Models.ArticlesEntity,
			joinEntity: () => Models.FavouritedbypatientsFavouritearticles,
			oppositeEntity: () => Models.PatientEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'FavouritedByPatients'] end
	})
	public favouritedbypatientss: Models.FavouritedbypatientsFavouritearticles[] = [];

	/**
	 * List of library tags that is associated with the article. These are used to personalise content.
	 */
	@observable
	@attribute({isReference: true})
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Library Tags'] off begin
		name: 'Library Tags',
		displayType: 'reference-multicombobox',
		order: 130,
		isJoinEntity: true,
		referenceTypeFunc: () => Models.ArticlesLibraryTags,
		optionEqualFunc: makeJoinEqualsFunc('libraryTagsId'),
		referenceResolveFunction: makeFetchManyToManyFunc({
			entityName: 'articlesEntity',
			oppositeEntityName: 'libraryTagEntity',
			relationName: 'articles',
			relationOppositeName: 'libraryTags',
			entity: () => Models.ArticlesEntity,
			joinEntity: () => Models.ArticlesLibraryTags,
			oppositeEntity: () => Models.LibraryTagEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Library Tags'] end
	})
	public libraryTagss: Models.ArticlesLibraryTags[] = [];

	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<IArticlesEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<IArticlesEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.feature !== undefined) {
				this.feature = attributes.feature;
			}
			if (attributes.featureId !== undefined) {
				this.featureId = attributes.featureId;
			}
			if (attributes.title !== undefined) {
				this.title = attributes.title;
			}
			if (attributes.description !== undefined) {
				this.description = attributes.description;
			}
			if (attributes.content !== undefined) {
				this.content = attributes.content;
			}
			if (attributes.htmlcontent !== undefined) {
				this.htmlcontent = attributes.htmlcontent;
			}
			if (attributes.reads !== undefined) {
				this.reads = attributes.reads;
			}
			if (attributes.articleReadEventss !== undefined && Array.isArray(attributes.articleReadEventss)) {
				for (const model of attributes.articleReadEventss) {
					if (model instanceof Models.ArticleReadEventEntity) {
						this.articleReadEventss.push(model);
					} else {
						this.articleReadEventss.push(new Models.ArticleReadEventEntity(model));
					}
				}
			}
			if (attributes.books !== undefined) {
				if (attributes.books === null) {
					this.books = attributes.books;
				} else {
					if (attributes.books instanceof Models.BooksEntity) {
						this.books = attributes.books;
						this.booksId = attributes.books.id;
					} else {
						this.books = new Models.BooksEntity(attributes.books);
						this.booksId = this.books.id;
					}
				}
			} else if (attributes.booksId !== undefined) {
				this.booksId = attributes.booksId;
			}
			if (attributes.article !== undefined) {
				if (attributes.article === null) {
					this.article = attributes.article;
				} else {
					if (attributes.article instanceof Models.FeaturedArticleEntity) {
						this.article = attributes.article;
					} else {
						this.article = new Models.FeaturedArticleEntity(attributes.article);
					}
				}
			}
			if (attributes.activities !== undefined) {
				if (attributes.activities === null) {
					this.activities = attributes.activities;
				} else {
					if (attributes.activities instanceof Models.ActivitiesEntity) {
						this.activities = attributes.activities;
						this.activitiesId = attributes.activities.id;
					} else {
						this.activities = new Models.ActivitiesEntity(attributes.activities);
						this.activitiesId = this.activities.id;
					}
				}
			} else if (attributes.activitiesId !== undefined) {
				this.activitiesId = attributes.activitiesId;
			}
			if (attributes.tagss !== undefined && Array.isArray(attributes.tagss)) {
				for (const model of attributes.tagss) {
					if (model instanceof Models.TagsArticles) {
						this.tagss.push(model);
					} else {
						this.tagss.push(new Models.TagsArticles(model));
					}
				}
			}
			if (attributes.favouritedbypatientss !== undefined && Array.isArray(attributes.favouritedbypatientss)) {
				for (const model of attributes.favouritedbypatientss) {
					if (model instanceof Models.FavouritedbypatientsFavouritearticles) {
						this.favouritedbypatientss.push(model);
					} else {
						this.favouritedbypatientss.push(new Models.FavouritedbypatientsFavouritearticles(model));
					}
				}
			}
			if (attributes.libraryTagss !== undefined && Array.isArray(attributes.libraryTagss)) {
				for (const model of attributes.libraryTagss) {
					if (model instanceof Models.ArticlesLibraryTags) {
						this.libraryTagss.push(model);
					} else {
						this.libraryTagss.push(new Models.ArticlesLibraryTags(model));
					}
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] off begin
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] off begin
	public defaultExpands = `
		libraryTagss {
			${Models.ArticlesLibraryTags.getAttributes().join('\n')}
			libraryTags {
				${Models.LibraryTagEntity.getAttributes().join('\n')}
			}
		}
		tagss {
			${Models.TagsArticles.getAttributes().join('\n')}
			tags {
				${Models.TagEntity.getAttributes().join('\n')}
			}
		}
		favouritedbypatientss {
			${Models.FavouritedbypatientsFavouritearticles.getAttributes().join('\n')}
			favouritedbypatients {
				${Models.PatientEntity.getAttributes().join('\n')}
			}
		}
		articleReadEventss {
			${Models.ArticleReadEventEntity.getAttributes().join('\n')}
		}
		books {
			${Models.BooksEntity.getAttributes().join('\n')}
		}
		article {
			${Models.FeaturedArticleEntity.getAttributes().join('\n')}
		}
		activities {
			${Models.ActivitiesEntity.getAttributes().join('\n')}
			${Models.ActivitiesEntity.getFiles().map(f => f.name).join('\n')}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] on begin
	//new referances will need to manually be added as we needed to move the manny to manny off as its a masive save request ~3s
	//make sure you never add back fav by patient and read events
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath = {
			libraryTagss: {},
			tagss: {},
			article: {},
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
							'article',
							'activities',
							'tagss',
							'libraryTagss',
						]
					},
				],
				contentType: 'multipart/form-data',
			}
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] on begin
		return this.title;
		// % protected region % [Customise the display name for this entity] end
	}


	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [ createdAttr, modifiedAttr ] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(ArticlesEntity.prototype, 'created');
CRUD(modifiedAttr)(ArticlesEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
