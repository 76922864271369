/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import moment from 'moment';
import { action, observable } from 'mobx';
import { Model, IModelAttributes, attribute, entity } from 'Models/Model';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from "Util/AttributeUtils";
import { IAcl } from 'Models/Security/IAcl';
import {
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import { VisitorsGoalStepEntity } from 'Models/Security/Acl/VisitorsGoalStepEntity';
import { PatientGoalStepEntity } from 'Models/Security/Acl/PatientGoalStepEntity';
import { ClinicianGoalStepEntity } from 'Models/Security/Acl/ClinicianGoalStepEntity';
import { CarerGoalStepEntity } from 'Models/Security/Acl/CarerGoalStepEntity';
import { AdminGoalStepEntity } from 'Models/Security/Acl/AdminGoalStepEntity';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import {SuperAdministratorScheme} from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface IGoalStepEntityAttributes extends IModelAttributes {
	name: string;
	completed: boolean;
	dueDate: Date;
	completedDate: Date;

	goalsId?: string;
	goals?: Models.GoalsEntity | Models.IGoalsEntityAttributes;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('GoalStepEntity', 'Goal Step')
// % protected region % [Customise your entity metadata here] end
export default class GoalStepEntity extends Model implements IGoalStepEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new VisitorsGoalStepEntity(),
		new PatientGoalStepEntity(),
		new ClinicianGoalStepEntity(),
		new CarerGoalStepEntity(),
		new AdminGoalStepEntity(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	// % protected region % [Modify props to the crud options here for attribute 'Name'] off begin
	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		name: 'Name',
		displayType: 'textfield',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public name: string;
	// % protected region % [Modify props to the crud options here for attribute 'Name'] end

	// % protected region % [Modify props to the crud options here for attribute 'Completed'] off begin
	/**
	 * Whether the goal step is completed.
	 */
	@observable
	@attribute()
	@CRUD({
		name: 'Completed',
		displayType: 'checkbox',
		order: 20,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public completed: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'Completed'] end

	// % protected region % [Modify props to the crud options here for attribute 'Due Date'] off begin
	/**
	 * The date that this step is due.
	 */
	@observable
	@attribute()
	@CRUD({
		name: 'Due Date',
		displayType: 'datepicker',
		order: 30,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseDate,
	})
	public dueDate: Date;
	// % protected region % [Modify props to the crud options here for attribute 'Due Date'] end

	// % protected region % [Modify props to the crud options here for attribute 'Completed Date'] off begin
	/**
	 * The date that this step was completed.
	 */
	@observable
	@attribute()
	@CRUD({
		name: 'Completed Date',
		displayType: 'datepicker',
		order: 40,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseDate,
	})
	public completedDate: Date;
	// % protected region % [Modify props to the crud options here for attribute 'Completed Date'] end

	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Goals'] off begin
		name: 'Goals',
		displayType: 'reference-combobox',
		order: 50,
		referenceTypeFunc: () => Models.GoalsEntity,
		// % protected region % [Modify props to the crud options here for reference 'Goals'] end
	})
	public goalsId?: string;
	@observable
	@attribute({isReference: true})
	public goals: Models.GoalsEntity;

	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<IGoalStepEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<IGoalStepEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.name !== undefined) {
				this.name = attributes.name;
			}
			if (attributes.completed !== undefined) {
				this.completed = attributes.completed;
			}
			if (attributes.dueDate !== undefined) {
				if (attributes.dueDate === null) {
					this.dueDate = attributes.dueDate;
				} else {
					this.dueDate = moment(attributes.dueDate).toDate();
				}
			}
			if (attributes.completedDate !== undefined) {
				if (attributes.completedDate === null) {
					this.completedDate = attributes.completedDate;
				} else {
					this.completedDate = moment(attributes.completedDate).toDate();
				}
			}
			if (attributes.goals !== undefined) {
				if (attributes.goals === null) {
					this.goals = attributes.goals;
				} else {
					if (attributes.goals instanceof Models.GoalsEntity) {
						this.goals = attributes.goals;
						this.goalsId = attributes.goals.id;
					} else {
						this.goals = new Models.GoalsEntity(attributes.goals);
						this.goalsId = this.goals.id;
					}
				}
			} else if (attributes.goalsId !== undefined) {
				this.goalsId = attributes.goalsId;
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] off begin
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] off begin
	public defaultExpands = `
		goals {
			${Models.GoalsEntity.getAttributes().join('\n')}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] off begin
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath = {
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
						]
					},
				],
			}
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] off begin
		return this.id;
		// % protected region % [Customise the display name for this entity] end
	}


	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [ createdAttr, modifiedAttr ] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(GoalStepEntity.prototype, 'created');
CRUD(modifiedAttr)(GoalStepEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
