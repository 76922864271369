import { action, observable, runInAction } from 'mobx';
import { observer } from 'mobx-react';
import { GoalsEntity, GoalStepEntity } from 'Models/Entities'
import { lifeArea } from 'Models/Enums'
import { store } from 'Models/Store';
import React, { Component } from 'react'
import { Button, Colors, Display, QuickTypes, Sizes } from 'Views/Components/Button/Button';
import { DisplayType } from 'Views/Components/Models/Enums';
import { TextField } from 'Views/Components/TextBox/TextBox';
import GoalStepRow from '../GoalStepRow';


type GoalSlideOverviewDisplay = 'GOAL-NAME' | 'GOAL-OVERVIEW'

interface IGoalSlideOverview {
	goal: GoalsEntity
	lifeArea: lifeArea;
	initialDisplayState?: GoalSlideOverviewDisplay
	incrementStep: () => void;
	decrementStep: () => void;
	onClickDone: () => void;
}

@observer
export default class GoalSlideOverview extends Component<IGoalSlideOverview> {
	@observable
	public goal: GoalsEntity;

	@observable
	private displayState?: GoalSlideOverviewDisplay = 'GOAL-OVERVIEW';

	constructor(props: IGoalSlideOverview) {
		super(props);
		this.goal = this.props.goal;
		this.displayState = this.props.initialDisplayState;
	}

	render() {
		const { decrementStep, onClickDone } = this.props;

		let areaOfLifeTitle = "";
		switch (this.props.lifeArea.toLowerCase()) {
			case "spiritual":
				areaOfLifeTitle = "values";
				break;
			case "psychological":
				areaOfLifeTitle = "mindset"
				break;
			default:
				areaOfLifeTitle = this.props.lifeArea.toLowerCase();
		}

		return (
			<>
				{
					this.displayState === 'GOAL-NAME' && (
						<>
							<h4 className="goal-select-text">My <span
								className="goals-life-area-text">{areaOfLifeTitle}</span> Goal is to:
							</h4>
							<TextField
								model={this.goal}
								modelProperty="name"
								name="GoalName"
								className="input-group--text-goal-field"
								displayType={DisplayType.INLINE}
								label=""
							/>

							<div className="goal-wizard__footer">
								<div>
									<Button
										display={Display.Text}
										colors={Colors.White}
										onClick={() => {
											decrementStep()
										}}>Back</Button>
								</div>

								<Button
									display={Display.Round}
									colors={Colors.Black}
									className="btn-goals-next icon-arrow-right icon-right"
									disabled={this.goal.name == null || this.goal.name.length === 0}
									onClick={() => {
										runInAction(() => {
											this.displayState = "GOAL-OVERVIEW";
										});
									}}>Next</Button>
							</div>
						</>
					)
				}

				{this.displayState === 'GOAL-OVERVIEW' && (
					<div className="goal-overview__summary">
						<h5>My <span>{areaOfLifeTitle}</span> goal is to <span>{this.props.goal.name}</span>.</h5>
						<h4 className="goal-select-text">List the steps involved in achieving this goal:</h4>
						<div>
							<div className="goals-step-list">
								{this.goal.goalStepss.map((step, key) => (
									<div key={key}>
										<GoalStepRow
											step={step}
											displayCheckbox={false}
											onChangeStep={() => {
											}}
											onRemoveStep={(removedStep) => {
												runInAction(() => {
													const index = this.goal.goalStepss.findIndex((step) => step.id === removedStep.id);
													this.goal.goalStepss.splice(index, 1);
												});
											}}
										/>
										<hr className="goals-step__step-divider"/>
									</div>
								))}
								<Button
									display={Display.Text}
									colors={Colors.White}
									className="goals-planner__add icon-plus-circle icon-left"
									onClick={() => {
										runInAction(() => this.goal.goalStepss.push(new GoalStepEntity({
											goalsId: this.goal.id,
										})));
									}}
								>
									Add Step
								</Button>

							</div>
						</div>

						<div className="goal-wizard__footer">
							<div>
								<Button
									display={Display.Text}
									colors={Colors.White}
									onClick={() => {
										decrementStep()
									}}>Back</Button>
							</div>

							<Button
								display={Display.Round}
								colors={Colors.Black}
								className={"btn-goals-done icon-arrow-right icon-right"}
								onClick={() => {
									onClickDone();
								}}>Done</Button>
						</div>
					</div>
				)
				}
			</>)
	}
}
