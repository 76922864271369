/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import React, { Component } from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import { IQuestionTile, QuestionTileOptionsProps } from 'Forms/Questions/QuestionTile';
import { QuestionType } from 'Forms/Schema/Question';
import TileOptions from '../TileOptions';
import { TextArea } from 'Views/Components/TextArea/TextArea';
import { QuestionComponent, QuestionComponentProps } from 'Forms/Questions/QuestionComponent';
// % protected region % [Add any further imports here] on begin
import MdEditor from 'react-markdown-editor-lite';
import MarkdownIt from 'markdown-it';
import { observable, action } from 'mobx';
import Collapsible from '../../../Views/Components/Articles/Plugins/Collapsible';
import ColourPicker from '../../../Views/Components/Articles/Plugins/ColourPicker';
import * as Models from 'Models/Entities';
import 'react-markdown-editor-lite/lib/index.css';
import { getQuestionType, questions } from '../QuestionUtils';
import { SERVER_URL } from 'Constants';

const MD_EDITOR_CONFIG = {
	view: {
		menu: false,
		md: false,
		html: true,
	},
	canView: {
		hideMenu: false,
		menu: false,
		md: false,
		html: false,
	},
}

// % protected region % [Add any further imports here] end

@observer
export class FormStatementTileOptions extends Component<QuestionTileOptionsProps> {
	// % protected region % [Add extra options class properties here] on begin
	mdParser: MarkdownIt;

	// Setup the markdown parser
	constructor(props: any) {
		super(props);

		this.mdParser = new MarkdownIt();
		this.mdParser.set({ html: true });

		var emoji = require('markdown-it-emoji');
		var underline = require('markdown-it-plugin-underline');
		this.mdParser.use(emoji);
		this.mdParser.use(underline);
	}

	// Used for storing images and presenting them in the markdown editor
	@observable
	private imageEntity = new Models.LibraryimagesEntity();

	private imageUpload = (file: File): Promise<string> => {
		// Create an image entity and set it's file to be the file retrieved from the MDE
		this.imageEntity = new Models.LibraryimagesEntity();
		this.imageEntity.libraryimages = file;

		return new Promise((resolve) => {
			var imageID = this.imageEntity.save(undefined, { contentType: 'multipart/form-data' }).then((r) => {
				return `${SERVER_URL}/api/files/${this.imageEntity.libraryimagesId}`;
			});
			resolve(imageID);
		});
	}

	@action
	handleEditorChange = (it: { text: string; html: string }, event: any) => {
		const { question } = this.props;
		question.content = it.text;
		question.htmlcontent = it.html;
	};
	// % protected region % [Add extra options class properties here] end

	public render() {
		// % protected region % [Customize options render here] on begin
		interface ComboValues {
			display: string; 
			value: string;
		}
		MdEditor.use(Collapsible);
		MdEditor.use(ColourPicker);
		const { question, schema } = this.props;

		return (
			<TileOptions question={question} schema={schema} hasShowConditions hasStylingOptions>
				<MdEditor
						value={question.content}
						// I know inline styles = bad but this is ONLY for the MDEditor because the class
						// names are confusing to those who will try and debug in the future
						style={{ height: '50%', borderRadius: '0px' }}
						renderHTML={(text: string) => this.mdParser.render(text)}
						config={{
							view: {md: true, html: false,  menu: true},
							imageAccept: '.jpg,.png,.svg,.jpeg',
						}}
						onImageUpload={this.imageUpload}
						onChange={this.handleEditorChange}
					/>
				{/* <TextArea model={question} modelProperty="content" /> */}
			</TileOptions>
		);
		// % protected region % [Customize options render here] end
	}
}

export interface IFormStatementTileProps<T> extends QuestionComponentProps<T> {
	// % protected region % [Add extra props here] off begin
	// % protected region % [Add extra props here] end
}

@observer
export class FormStatementTile<T> extends QuestionComponent<T, IFormStatementTileProps<T>> implements IQuestionTile {
	// % protected region % [Customize static form vars here] on begin
	static displayName = "Statement";

	static questionType: QuestionType = "statement";

	static optionsMenu = FormStatementTileOptions;

	static compareFunction = undefined;

	static validateFunction = undefined;

	static conditionOptions = undefined;

	static validatorOptions = undefined;

	static stylingOptions = [
		{ display: "Regular", value: "form-statement--regular" },
		{ display: "Light", value: "form-statement--light" },
		{ display: "Important", value: "form-statement--important" },
	];
	
	mdParser: MarkdownIt;

	// Setup the markdown parser
	constructor(props: any) {
		super(props);

		this.mdParser = new MarkdownIt();
		this.mdParser.set({ html: true });

		var emoji = require('markdown-it-emoji');
		var underline = require('markdown-it-plugin-underline');
		this.mdParser.use(emoji);
		this.mdParser.use(underline);
	}

	// % protected region % [Customize static form vars here] end

	// % protected region % [Add extra class properties here] off begin
	// % protected region % [Add extra class properties here] end

	public render() {
		// % protected region % [Customize render here] on begin
		const { title, content, className } = this.props;
		const statementClass = className ? className : "form-statement--regular";

		return (
			<div className={classNames(statementClass)}>
				<MdEditor
					style={{ width: '100%', border: '0px', backgroundColor: 'transparent', margin: '0px' }}
					value={content}
					renderHTML={(text: string) => this.mdParser.render(text)}
					config={MD_EDITOR_CONFIG}
				/>
			</div>
			
		);
		// % protected region % [Customize render here] end
	}
}

// % protected region % [Add extra methods here] off begin
// % protected region % [Add extra methods here] end