import { size } from 'lodash'
import React, { Component } from 'react'
import { Button, Colors, Display, Sizes } from '../Button/Button'
import { store } from '../../../Models/Store';
import { Grid } from 'semantic-ui-react';


interface IOnboardingIntroductionProps {
	incrementStep: () => void;
}
export default class OnboardingIntroduction extends Component<IOnboardingIntroductionProps> {

	render() {
		const { incrementStep } = this.props;
		return (
			<>
				<div className="intro-text">
					<h2>Welcome to YourHealth+ {store.getUser()?.forename}</h2>
					<h3 className="onboarding-intro-text">
						Mental health underpins your overall health and wellbeing and your mental health is linked to specific areas of your life.
                    </h3>
					<h3 className="onboarding-intro-text" >
						Let's take a moment to rate your satisfaction with each area.
                	</h3>
				</div>
				<div style={{
					marginTop: 50,
				}}>
					<Grid container justify="flex-end">
						<Button
							display={Display.Round}
							colors={Colors.Black}
							className="btn--icon icon-arrow-right icon-right"
							onClick={() => {
								incrementStep();

							}}
						>
							Rate areas of my life
						</Button>
					</Grid>
				</div>
			</>
		)
	}
}
