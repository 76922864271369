/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import moment from 'moment';
import { action, observable } from 'mobx';
import { Model, IModelAttributes, attribute, entity } from 'Models/Model';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from "Util/AttributeUtils";
import { IAcl } from 'Models/Security/IAcl';
import {
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import { VisitorsNotificationsSettingsEntity } from 'Models/Security/Acl/VisitorsNotificationsSettingsEntity';
import { PatientNotificationsSettingsEntity } from 'Models/Security/Acl/PatientNotificationsSettingsEntity';
import { ClinicianNotificationsSettingsEntity } from 'Models/Security/Acl/ClinicianNotificationsSettingsEntity';
import { CarerNotificationsSettingsEntity } from 'Models/Security/Acl/CarerNotificationsSettingsEntity';
import { AdminNotificationsSettingsEntity } from 'Models/Security/Acl/AdminNotificationsSettingsEntity';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import {SuperAdministratorScheme} from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface INotificationsSettingsEntityAttributes extends IModelAttributes {
	checkInEnabled: boolean;
	activitiesEnabled: boolean;
	meetingsEnabled: boolean;
	checkInTime: Date;
	activitiesTime: Date;
	meetingsTime: Date;
	notificationsDeviceToken: string;

	patientNotifications?: Models.PatientEntity | Models.IPatientEntityAttributes;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('NotificationsSettingsEntity', 'Notifications Settings')
// % protected region % [Customise your entity metadata here] end
export default class NotificationsSettingsEntity extends Model implements INotificationsSettingsEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new VisitorsNotificationsSettingsEntity(),
		new PatientNotificationsSettingsEntity(),
		new ClinicianNotificationsSettingsEntity(),
		new CarerNotificationsSettingsEntity(),
		new AdminNotificationsSettingsEntity(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	// % protected region % [Modify props to the crud options here for attribute 'Check In Enabled'] off begin
	/**
	 * Are Check-in notifications enabled?
	 */
	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		name: 'Check In Enabled',
		displayType: 'checkbox',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public checkInEnabled: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'Check In Enabled'] end

	// % protected region % [Modify props to the crud options here for attribute 'Activities Enabled'] off begin
	/**
	 * Are the notifications for Activities enabled
	 */
	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		name: 'Activities Enabled',
		displayType: 'checkbox',
		order: 20,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public activitiesEnabled: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'Activities Enabled'] end

	// % protected region % [Modify props to the crud options here for attribute 'Meetings Enabled'] off begin
	/**
	 * Are the notifications for Meetings enabled.
	 */
	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		name: 'Meetings Enabled',
		displayType: 'checkbox',
		order: 30,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public meetingsEnabled: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'Meetings Enabled'] end

	// % protected region % [Modify props to the crud options here for attribute 'Check In Time'] off begin
	/**
	 * Time of Check In notification
	 */
	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		name: 'Check In Time',
		displayType: 'datetimepicker',
		order: 40,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseDate,
	})
	public checkInTime: Date;
	// % protected region % [Modify props to the crud options here for attribute 'Check In Time'] end

	// % protected region % [Modify props to the crud options here for attribute 'Activities Time'] off begin
	/**
	 * Time of Activities notification
	 */
	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		name: 'Activities Time',
		displayType: 'datetimepicker',
		order: 50,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseDate,
	})
	public activitiesTime: Date;
	// % protected region % [Modify props to the crud options here for attribute 'Activities Time'] end

	// % protected region % [Modify props to the crud options here for attribute 'Meetings Time'] off begin
	/**
	 * Time of Meetings notification
	 */
	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		name: 'Meetings Time',
		displayType: 'datetimepicker',
		order: 60,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseDate,
	})
	public meetingsTime: Date;
	// % protected region % [Modify props to the crud options here for attribute 'Meetings Time'] end

	// % protected region % [Modify props to the crud options here for attribute 'Notifications Device Token'] off begin
	/**
	 * Token for notifications within the app
	 */
	@observable
	@attribute()
	@CRUD({
		name: 'Notifications Device Token',
		displayType: 'textfield',
		order: 70,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public notificationsDeviceToken: string;
	// % protected region % [Modify props to the crud options here for attribute 'Notifications Device Token'] end

	/**
	 * A Patient's notification configuration settings
	 */
	@observable
	@attribute({isReference: true})
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Patient Notifications'] off begin
		name: 'Patient Notifications',
		displayType: 'reference-combobox',
		order: 80,
		referenceTypeFunc: () => Models.PatientEntity,
		optionEqualFunc: (model, option) => model.id === option,
		inputProps: {
			fetchReferenceEntity: true,
		},
		// % protected region % [Modify props to the crud options here for reference 'Patient Notifications'] end
	})
	public patientNotifications?: Models.PatientEntity;

	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<INotificationsSettingsEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<INotificationsSettingsEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.checkInEnabled !== undefined) {
				this.checkInEnabled = attributes.checkInEnabled;
			}
			if (attributes.activitiesEnabled !== undefined) {
				this.activitiesEnabled = attributes.activitiesEnabled;
			}
			if (attributes.meetingsEnabled !== undefined) {
				this.meetingsEnabled = attributes.meetingsEnabled;
			}
			if (attributes.checkInTime !== undefined) {
				if (attributes.checkInTime === null) {
					this.checkInTime = attributes.checkInTime;
				} else {
					this.checkInTime = moment(attributes.checkInTime).toDate();
				}
			}
			if (attributes.activitiesTime !== undefined) {
				if (attributes.activitiesTime === null) {
					this.activitiesTime = attributes.activitiesTime;
				} else {
					this.activitiesTime = moment(attributes.activitiesTime).toDate();
				}
			}
			if (attributes.meetingsTime !== undefined) {
				if (attributes.meetingsTime === null) {
					this.meetingsTime = attributes.meetingsTime;
				} else {
					this.meetingsTime = moment(attributes.meetingsTime).toDate();
				}
			}
			if (attributes.notificationsDeviceToken !== undefined) {
				this.notificationsDeviceToken = attributes.notificationsDeviceToken;
			}
			if (attributes.patientNotifications !== undefined) {
				if (attributes.patientNotifications === null) {
					this.patientNotifications = attributes.patientNotifications;
				} else {
					if (attributes.patientNotifications instanceof Models.PatientEntity) {
						this.patientNotifications = attributes.patientNotifications;
					} else {
						this.patientNotifications = new Models.PatientEntity(attributes.patientNotifications);
					}
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] off begin
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] off begin
	public defaultExpands = `
		patientNotifications {
			${Models.PatientEntity.getAttributes().join('\n')}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] off begin
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath = {
			patientNotifications: {},
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
							'patientNotifications',
						]
					},
				],
			}
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] off begin
		return this.id;
		// % protected region % [Customise the display name for this entity] end
	}


	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [ createdAttr, modifiedAttr ] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(NotificationsSettingsEntity.prototype, 'created');
CRUD(modifiedAttr)(NotificationsSettingsEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
