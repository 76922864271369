import React, { Component } from "react";
import { RouteComponentProps } from "react-router";
import { observer } from "mobx-react";
import { observable, action, toJS, runInAction } from "mobx";
import Axios from "axios";
import { store } from "Models/Store";
import { ComboboxOption } from "Views/Components/Combobox/Combobox";
import ClinicianCheckIn from "Views/Components/CheckInClinician/ClinicianCheckIn";
import DashboardHeaderButton, { IDashboardHeaderButtonProps } from '../../Components/Dashboard/DashboardHeaderButton';
import DashboardPatients from '../../Components/Dashboard/DashboardPatients';
import DashboardGoals from '../../Components/Dashboard/DashboardGoals';
import TagService from "Services/TagService";
import { PatientEntity, TagEntity } from '../../../Models/Entities';
import DashboardSummaryMetrics from '../../Components/Dashboard/DashboardSummaryMetrics';
import alert from '../../../Util/ToastifyUtils';
import DashboardActivities from '../../Components/Dashboard/DashboardActivities';
import Spinner from '../../Components/Spinner/Spinner';
import DashboardToDo from "Views/Components/Dashboard/DashboardToDo";
import DashboardAreasOfLife from 'Views/Components/Dashboard/DashboardAreasOfLife';
import DashboardArticles from '../../Components/Dashboard/DashboardArticles';
import PatientService from '../../../Services/PatientService/PatientService';
import { PatientExpands } from '../../../Services/PatientService/PatientEntityExpands';

enum DashboardDisplayEnum {
	USERS = 'Users',
	CHECK_IN = 'Check ins',
	GOALS = 'Goals',
	TODO = 'To-Dos',
	ACTIVITIES = 'Activities',
	AREAS_OF_LIFE = "Areas of Life",
	ARTICLES = "Articles"
}

@observer
export default class ClinicianDashboard extends Component<RouteComponentProps> {

	private dashboardButtons: IDashboardHeaderButtonProps[] = [
		{
			title: "Users",
			description: "Activated",
			onClick: () => { runInAction(() => { this.display = DashboardDisplayEnum.USERS }) }
		},
		{
			title: "Check ins",
			description: "Completed Today",
			onClick: () => { runInAction(() => { this.display = DashboardDisplayEnum.CHECK_IN }) }
		},
		{
			title: "Activities",
			description: "Submissions Today",
			onClick: () => { runInAction(() => { this.display = DashboardDisplayEnum.ACTIVITIES }) }
		},
		{
			title: "Articles",
			description: "Read Today",
			onClick: () => { runInAction(() => { this.display = DashboardDisplayEnum.ARTICLES }) }
		},
		{
			title: "Areas of Life",
			onClick: () => { runInAction(() => { this.display = DashboardDisplayEnum.AREAS_OF_LIFE})}
		},
		{
			title: "Goals",
			description: "Goals set",
			value: 0,
			onClick: () => { runInAction(() => { this.display = DashboardDisplayEnum.GOALS }) }
		},
		{
			title: "To-Dos",
			description: "To-Dos set Today",
			value: 0,
			onClick: () => { runInAction(() => { this.display = DashboardDisplayEnum.TODO }) }
		}
	]

	@observable
	private display: DashboardDisplayEnum = DashboardDisplayEnum.USERS;

	@observable
	private forms: ComboboxOption<string>[] = [];

	@observable
	private dashboardSummaryMetrics = new DashboardSummaryMetrics();

	@observable
	private tagEntitys: TagEntity[] = [];

	@observable
	private patientEntitys: PatientEntity[] = [];

	@observable
	private isTagsLoaded = false;

	@observable
	private isPatientsLoaded = false;

	componentDidMount() {
		this.getForms();

		// Fetch common dashboard data
		this.fetchTags();
		this.fetchPatients();

		// Fetch dashboard summary data
		this.fetchDashboardSummaryMetrics();
	}

	@action
	fetchPatients = () => {
		PatientService.getAllPatient(PatientExpands.clinicianDashboard)
			.then((res) => {
				runInAction(() => {
					this.patientEntitys = res
						.map(attr => new PatientEntity(attr))
						.filter(p => !p.isDemoAccount());
				})
			})
			.catch(err => {
				console.log(`Error fetching patient options`);
			})
			.finally(() => {
				runInAction(() => {
					this.isPatientsLoaded = true;
				})
			});
	}

	@action
	fetchTags = () => {
		TagService.getAllTags()
			.then((res) => {
				runInAction(() => {
					this.tagEntitys = res.data.data.map(attr => new TagEntity(attr));
					this.isTagsLoaded = true;
				})
			})
			.catch(err => {
				console.log(`Error fetching tag options`, err);
			});
	}

	fetchDashboardSummaryMetrics = () => {
		Axios.get('/api/entity/PatientEntity/dashboard_summary_metrics',
			{
				params: {
					offset: new Date().getTimezoneOffset()
				}
			})
			.then(res => {
				runInAction(() => {
					this.dashboardSummaryMetrics = new DashboardSummaryMetrics(res.data);
				})
			})
			.catch(err => {
				alert('Failed to fetch dashboard summary metrics', 'error');
			})
	}

	async getForms() {
		this.resetForms();
		await Axios.get(`/api/entity/RatingtemplateEntity`).then((d) => this.setForms(d.data.data));
		await Axios.get(`/api/entity/RatingpersonalEntity`).then((d) => this.setForms(d.data.data));
	}

	@action resetForms() {
		this.forms = [];
	}

	@action setForms(forms: any) {
		forms
			.filter((form: any) => form.frequency === "DAILY" || form.frequency === "WEEKLY")
			.map((form: any) => {
				this.forms.push({ display: form.name, value: form.id });
			});
	}

	getDashboardContent = () => {

		switch (this.display) {
			case DashboardDisplayEnum.AREAS_OF_LIFE:
				if (!this.isTagsLoaded || !this.isPatientsLoaded) {
					return <Spinner />
				}
				return <DashboardAreasOfLife tags={this.tagEntitys} patients={this.patientEntitys} />
			case DashboardDisplayEnum.ARTICLES:
				if (!this.isTagsLoaded || !this.isPatientsLoaded) {
					return <Spinner />
				}
				return <DashboardArticles tags={this.tagEntitys} patients={this.patientEntitys} />
			case DashboardDisplayEnum.ACTIVITIES:
				if (!this.isTagsLoaded || !this.isPatientsLoaded) {
					return <Spinner />
				}
				return <DashboardActivities tags={this.tagEntitys} patients={this.patientEntitys} />
			case DashboardDisplayEnum.USERS:
				if (!this.isTagsLoaded || !this.isPatientsLoaded) {
					return <Spinner />
				}
				return <DashboardPatients tags={this.tagEntitys} patients={this.patientEntitys} />;
			case DashboardDisplayEnum.GOALS:
				if (!this.isTagsLoaded || !this.isPatientsLoaded) {
					return <Spinner />
				}
				return <DashboardGoals tags={this.tagEntitys} patients={this.patientEntitys} />;
			case DashboardDisplayEnum.TODO:
				if (!this.isTagsLoaded || !this.isPatientsLoaded) {
					return <Spinner />
				}
				return <DashboardToDo tags={this.tagEntitys} patients={this.patientEntitys} />;
			case DashboardDisplayEnum.CHECK_IN:
				if (!this.isTagsLoaded || !this.isPatientsLoaded) {
					return <Spinner />
				}
				return <ClinicianCheckIn tags={this.tagEntitys} forms={this.forms}  patients={this.patientEntitys} />;
		}
	}
	render() {
/* This needs to be refactored so the button fields are bound to the data in the dashboardsummary metrics as to not
*  require this really inefficient checking method
* */
		var userButton = this.dashboardButtons.find(x => x.title === "Users");
		var activitiesButton = this.dashboardButtons.find(x => x.title === "Activities");
		var articlesButton = this.dashboardButtons.find(x => x.title === "Articles");
		var goalsButton = this.dashboardButtons.find(x => x.title === "Goals");
		var toDosButton = this.dashboardButtons.find(x => x.title === "To-Dos");
		var checkinsButton = this.dashboardButtons.find(x => x.title === "Check ins");

		if (userButton != null) {
			userButton.value = this.dashboardSummaryMetrics.totalPatientsActivated;
		}
		if (activitiesButton != null) {
			activitiesButton.value = this.dashboardSummaryMetrics.totalActivitiesSubmittedToday;
		}
		if (articlesButton != null) {
			articlesButton.value = this.dashboardSummaryMetrics.totalArticlesReadToday;
		}
		if (goalsButton != null) {
			goalsButton.value = this.dashboardSummaryMetrics.totalGoalsCreated;
		}
		if (toDosButton != null) {
			toDosButton.value = this.dashboardSummaryMetrics.totalTodosCreated;
		}

		const welcomeMessage = store.getUser()?.discriminator === 'AdminEntity' || store.getUser()?.surname == null
			? `Here's a snapshot of your portfolio`
			: `${store.getUser()?.forename} ${store.getUser()?.surname} here's a snapshot of your portfolio`;

		if (checkinsButton != null) {
			checkinsButton.value = this.dashboardSummaryMetrics.totalPatientsCheckedInToday;
		}

		return (
			<div>
				<div className="dashboard-title">
					<h1>{welcomeMessage}</h1>
				</div>
				<div className="dashboard-header">
					{this.dashboardButtons.map((button) => {
						return (
							<DashboardHeaderButton
								active={this.display === button.title}
								key={button.title.replace(' ', '')}
								title={button.title}
								onClick={button.onClick}
								value={button.value}
								description={button.description}
							/>
						)
					})}
				</div>

				{this.getDashboardContent()}
			</div>
		);
	}
}
