/**
 * Common graph configuration for the patient graphs used on the dashboard.
 *
 * These should not be changed unless they are expected to be updated on all the graphs shown on the dashboards.
 */
import { LegendOptions } from 'highcharts';
import moment from 'moment';
import { DateRange } from '../../../Util/TimelineUtils';
import { adjustDateWithLocalTimezone } from '../../../Util/DateUtils';
import { prettyLog } from '../../../Util/StringUtils';

export const DASHBOARD_GRAPH_COMMON_CHART_OPTIONS = {

	// Config and styling for the chart title
	title: {
		text: '',
		style: {
			display: 'none'
		}
	},

	// Config and styling for the legend
	legend: {
		align: "center",
		layout: "horizontal",
	} as LegendOptions,

	// Styling for y axis
	yAxis: {
		tickInterval: 1,
		title: {
			text: 'COUNT'
        },
        gridLineColor: 'transparent',
        lineWidth: 1
	},

	// Number of days shown on the x Axis. Should be 14 days wide.
	xAxisRange: 14 - 1,
	// xAxisRange: 3 - 1,

	// Config and styling for the data
	seriesType: 'line',
}

export class DashboardGraphDateRange {
	public timezoneOffset: number;
	public startDate: Date;
	public endDate: Date;

	constructor(startDate: Date, endDate: Date) {
		this.startDate = startDate;
		this.endDate = endDate;
		this.timezoneOffset = new Date().getTimezoneOffset();
	}

	updateRangeBackward = (numDays: number) => {
		const prevStartDate = this.startDate;
		this.endDate = moment(prevStartDate).subtract(1, 'days').toDate();
		this.startDate = moment(this.endDate).subtract(numDays, 'days').toDate();
	}

	updateRangeForward = (numDays: number) => {
		const prevEndDate = this.endDate;
		this.startDate = moment(prevEndDate).add(1, 'days').toDate();
		this.endDate = moment(this.startDate).add(numDays, 'days').toDate();
	}
}

export interface IBasePatientGraphDataAttributes<T> extends DateRange {
	data: T[];
}

/**
 * Abstract class for managing dashboard graph data.
 */
export abstract class BasePatientGraphData<T>  implements IBasePatientGraphDataAttributes<T>{
	public data: T[] = [];
	public startDate: Date;
	public endDate: Date;

	constructor(attributes?: Partial<IBasePatientGraphDataAttributes<T>>) {
		if (attributes) {
			if (attributes.startDate) {
				this.startDate = moment(attributes.startDate).local().toDate();
			}

			if (attributes.endDate) {
				this.endDate = moment(attributes.endDate).local().toDate();
			}
		}
	}

	// Return a list of date strings for the x axis of the graph
	abstract getXCategories(): string[];
}
