import * as React from 'react';
import { PluginComponent, PluginProps } from 'react-markdown-editor-lite';
import { SketchPicker } from 'react-color';
import { observable, action } from 'mobx';
import { observer } from 'mobx-react';
import { Button } from 'Views/Components/Button/Button';

@observer
export default class ColourPicker extends PluginComponent {
	@observable
	displayColorPicker: boolean = false;

	@observable
	colour: any = '#377995';

	constructor(props: PluginProps) {
		super(props);
		this.handleClick = this.handleClick.bind(this);
	}

	@action
	handleClick = () => {
		this.displayColorPicker = !this.displayColorPicker;
	};

	@action
	handleClose = () => {
		this.displayColorPicker = false;
		const text = '<text style="color: ' + this.colour + '"> Insert text here! </text>';
		this.editor.insertText(text);
	};

	@action
	handleChange = (color: any) => {
		this.colour = color.hex;
	};

	@action
	handleChangeComplete = (color: any) => {
		this.colour = color.hex;
	};

	render() {
		const presetColors = ['#377995', '#4197B6', '#8623DF', '#E84D38', '#F27600', '#FBC200', '#38CC7D', '#009FDD'];
		return (
			<div>
				<span className="button button-type-colour-picker" title="Colour Picker" onClick={this.handleClick}>
					<i className="icon-eye-dropper icon-top" style={{ fontStyle: 'normal' }} />
				</span>
				{this.displayColorPicker ? (
					<div style={{ position: 'absolute', zIndex: 2 }}>
						<div style={{ position: 'fixed', top: '0px', right: '0px', bottom: '0px', left: '0px' }} onClick={this.handleClose} />
						<SketchPicker
							color={this.colour}
							onChangeComplete={this.handleChangeComplete}
							onChange={this.handleChange}
							disableAlpha={true}
							presetColors={presetColors}
						/>
						<Button>Test</Button>
					</div>
				) : null}
			</div>
		);
	}
}
