import * as React from 'react';
import { observer } from 'mobx-react';
import { RouteComponentProps } from 'react-router';
import { PatientEntity, ActivitiesSubmissionEntity, RatingtemplateSubmissionEntity } from "../../Models/Entities";
import { getModelDisplayName } from "../../Util/EntityUtils";
import { action, observable } from "mobx";
import ToDoTile from "./ToDoTile";
import { gql } from "apollo-boost";
import { store } from "Models/Store";
import ProfileCheckIn from "Views/Components/Profile/ProfileCheckIn";
import ProfileActivities from "Views/Components/Profile/ProfileActivities";
import queryString from "query-string";
import ScrollMenu from "react-horizontal-scrolling-menu";
import ProfileGoals from "Views/Components/Profile/ProfileGoals";
import { Badge, createMuiTheme, ThemeProvider } from "@material-ui/core";
import If from "Views/Components/If/If";
import moment, { Moment } from "moment";
import { Bar } from "react-chartjs-2";
import alert from "Util/ToastifyUtils";
import { status, statusOptions } from "Models/Enums";
import MyProfileTile from "Views/Components/MyProfile/MyProfileTile";

const BAR_OPTIONS = {
	legend: {
		display: false,
	},
	scales: {
		yAxes: [
			{
				ticks: {
					display: false,
					beginAtZero: true,
				},
			},
		],
		xAxes: [
			{
				barPercentage: 1.3,
				gridLines: {
					drawOnChartArea: false,
				},
			},
		],
	},
	maintainAspectRatio: false,
}

interface IProfileTileRouteParams {
	id?: string;
	entity?: string;
	active?: string;
}

@observer
export default class ProfileTile extends React.Component<RouteComponentProps<IProfileTileRouteParams>> {
	@observable
	private activeSection: string = this.getSearchTerm();

	@observable
	private userName: string = "...";

	@observable
	private status: status = "ACTIVE";

	@observable
	private lastlogin: string = "";

	@observable
	private selectStatus = false;

	@observable
	private badgeContent: number[] = [0, 0, 0];

	@observable
	private moodTrackerResponses: { values: any[]; dates: Moment[] } = { values: [], dates: [] };

	@observable
	private moodLoading = true;

	constructor(props: any) {
		super(props);
		this.getUsername();
	}

	componentDidMount() {
		this.getDates();
		this.getUsername();
		this.getBadgeContent();
		this.getSubmissionData();
	}

	@action
	private getDates() {
		for (let day = 13; day >= 0; day--) {
			const today = moment.utc();
			this.moodTrackerResponses.dates.push(today.subtract(day, "day"));
			this.moodTrackerResponses.values.push(0);
		}
	}

	private async getSubmissionData() {
		await store.apolloClient
			.query({ query: queryMoodTracker(this.props.match.params.id!) })
			.then(
				action((submissions) => {
					this.moodTrackerResponses.dates.forEach((date, index) => {
						submissions.data.ratingtemplateEntity.publishedVersion.formSubmissions.forEach((submission: any) => {
							const submissionDay = moment.utc(submission.submissionDate).local();

							if (submissionDay.format("YYYY-MM-DD") === date.format("YYYY-MM-DD")) {
								let value;
								let data = JSON.parse(submission.submissionData);
								if (data) {
									console.log(data);
									value = Object.values(data)[0];
								}
								this.moodTrackerResponses.values[index] = value;
							}
						});
					});
				})
			)
			.catch(() => alert(`Please create your "Mood tracker" form in order to track a users Daily Mood Rating`));
		this.moodLoadingDone();
	}

	@action
	private moodLoadingDone() {
		this.moodLoading = false;
	}

	public getSearchTerm(): string {
		if (this.props.location.search) {
			const search: any = queryString.parse(this.props.location.search);
			return search.active;
		} else {
			return "to-do";
		}
	}

	private getMenuItems() {
		const items = [
			{ index: 0, path: "to-do", name: "To-do List" },
			{ index: 1, path: "check-in", name: "Check In" },
			{ index: 2, path: "activities", name: "Activities" },
			{ index: 3, path: "goals", name: "Goals" },
			{ index: 4, path: "profile", name: "Profile" },
		];

		const theme = createMuiTheme({
			palette: {
				primary: { main: "#4197B6" },
				secondary: { main: "#DC1D46" },
			},
		});

		return items.map((item) => {
			const className = item.path == "to-do" ? "todo-badge" : "badge";
			const color = item.path == "to-do" ? "primary" : "secondary";
			const badgeContent = this.badgeContent[item.index];

			const itemBadge = (
				<ThemeProvider theme={theme}>
					<Badge className={className} color={color} badgeContent={badgeContent} />
				</ThemeProvider>
			);

			return (
				<div
					className={this.activeSection == item.path ? "active-section-header section-header" : "section-header"}
					onClick={() => this.switchSection(item.path)}>
					<div className="menu-item">
						<div className={"menu-title"}>{item.name}</div>

						<If condition={badgeContent > 0}>{itemBadge}</If>
					</div>
				</div>
			);
		});
	}

	@action
	private async getBadgeContent() {
		const owned = await store.apolloClient.query({
			query: queryPatientOwnedToDos(this.props.match.params.id!, "notEqual", false),
			fetchPolicy: "network-only",
		});

		const assigned = await store.apolloClient.query({
			query: queryPatientAssignedToDos(this.props.match.params.id!, "notEqual"),
			fetchPolicy: "network-only",
		});

		Promise.all([owned, assigned]).then((d) => {
			this.setTodoBadges(d);
		});

		// get and set activities badge
		if (store.userGroups[0].name === "Clinician") {
			await store.apolloClient
				.query({
					query: queryActivitySubmissionsUnseen(this.props.match.params.id!),
					fetchPolicy: "network-only",
				})
				.then(
					action((activities) => {
						this.badgeContent[2] = 0;

						activities.data.activitiesSubmissionEntitys.forEach((submission: ActivitiesSubmissionEntity) => {
							let seen: boolean = false;

							// check if clinician has already seen submission
							submission.clinicianss.forEach((clinician: any) => {
								if (clinician.cliniciansId == store.userId) {
									seen = true;
								}
							});

							if (!seen) {
								this.badgeContent[2]++;
							}
						});
					})
				);
		}

		// get and set checkin badge
		if (store.userGroups[0].name === "Clinician") {
			await store.apolloClient
				.query({
					query: queryCheckinSubmissionsUnseen(this.props.match.params.id!),
					fetchPolicy: "network-only",
				})
				.then(
					action((checkins) => {
						this.badgeContent[1] = 0;

						checkins.data.ratingtemplateSubmissionEntitys.forEach((submission: RatingtemplateSubmissionEntity) => {
							let seen: boolean = false;

							// check if clinician has already seen submission
							submission.clinicianss.forEach((clinician: any) => {
								if (clinician.cliniciansId == store.userId) {
									seen = true;
								}
							});

							if (!seen) {
								this.badgeContent[1]++;
							}
						});
					})
				);
		}
	}

	@action
	private setTodoBadges(d: any) {
		this.badgeContent[0] = d[0].data.todoEntitys.length + d[1].data.todoEntitys.length;
	}

	// % protected region % [Add class properties here] end

	public render() {
		let contents = null;

		// % protected region % [Override contents here] on begin
		const menu = this.getMenuItems();
		const arrowLeft = <div key={'left'} className="scrollbar-arrow icon-right icon-chevron-left" />;
		const arrowRight = <div key={'right'} className="scrollbar-arrow icon-left icon-chevron-right" />;

		contents = (
			<div className="profile-page">
				<div className="patient-profile-header">
					<div className="user-info">
						<h2>{this.userName}</h2>
						<div className="profile-status">
							<h5 className="patient-status">
								{getModelDisplayName(PatientEntity)} status:
								<span className="status-selector-container"
								      onClick={() => this.setSelectStatus(!this.selectStatus)}>
									<span className="status-selector-button">{this.formatStatus(this.status)}</span>
									<span className="icon-right icon-chevron-down" />
								</span>
							</h5>
							<div className="last-login">
								{"Last login :"} {this.lastlogin}
							</div>
							{this.renderStatusSelector()}
						</div>
					</div>
					<div className="user-graph">{this.renderGraph()}</div>
				</div>
				<div className="profile-switch">
					<ScrollMenu
						data={menu}
						arrowLeft={arrowLeft}
						arrowRight={arrowRight}
						hideArrows={true}
						alignCenter={false} />
				</div>

				<div className="profile-content">{this.renderSection()}</div>
			</div>
		);
		// % protected region % [Override contents here] end

		return contents;
	}

	// % protected region % [Add class methods here] on begin
	private async getUsername() {
		store.apolloClient
			.query({ query: queryUserName(this.props.match.params.id!) })
			.then((d) => {
				this.setUsername(d.data.patientEntity);
				this.setStatus(d.data.patientEntity.status);
				this.setLastlogin(d.data.patientEntity);
			})
			.catch((e) => {
				console.log(e);
			});
	}

	@action
	private setUsername(data: any) {
		this.userName = this.displayUsername(data.forename, data.surname);
	}

	@action
	private setLastlogin(data: any) {
		this.lastlogin = moment.utc(data.lastLogin).local().format("LT Do MMMM YYYY");
	}

	// Gets the username representation for a user (If they do not have a first/last name)
	private displayUsername(fistName?: string, lastName?: string) {
		let firstname = fistName ? fistName : getModelDisplayName(PatientEntity);
		let lastname = lastName ? lastName : "";
		return `${firstname} ${lastname}`;
	}

	@action
	private switchSection(section: string) {
		store.routerHistory.push(this.props.location.pathname + "?active=" + section);
		this.activeSection = section;
	}

	private renderSection() {
		const { history, location, match } = this.props;
		switch (this.activeSection) {
			case "to-do":
				return (
					<ToDoTile
						history={history}
						location={location}
						match={match}
						displayHeader={false}
						userId={match.params.id!}
						userGroup={"Patient"}
						renderProfileTile={() => this.getBadgeContent()}
					/>
				);
			case "check-in":
				return <ProfileCheckIn userId={match.params.id!} renderProfileTile={() => this.getBadgeContent()} />;
			case "activities":
				return <ProfileActivities userId={match.params.id!} renderProfileTile={() => this.getBadgeContent()} />;
			case "goals":
				return <ProfileGoals userId={match.params.id!} history={history}
				                     location={location}
				                     match={match} />;
			case "profile":
				return <MyProfileTile displayHeader={false} userId={match.params.id!} userGroup={"Patient"}
				                      onSave={() => this.getUsername()} />;
			case "meetings":
				return <>Meetings</>;
			default:
				return;
		}
	}

	private renderGraph() {
		if (!this.moodLoading) {
			const colourOptions = [
				"d1d1d1",
				"#be3a49",
				"#d36352",
				"#de824b",
				"#e29c56",
				"#f1d343",
				"#efe545",
				"#d9e158",
				"#bbe058",
				"#9dcf50",
				"#92c3a1",
			];
			const data = this.moodTrackerResponses.values;
			const labels = this.moodTrackerResponses.dates.map((date) => {
				return date.format("D/M");
			});
			const colours = this.moodTrackerResponses.values.map((value) => {
				return colourOptions[value];
			});

			return (
				<div className="chart-container">
					<div className="chart">
						<Bar
							data={{
								labels: labels,
								datasets: [
									{
										label: "",
										data: data,
										backgroundColor: colours,
									},
								],
							}}
							options={BAR_OPTIONS}
						/>
					</div>
					<b>Mood trail</b>
				</div>
			);
		}
		return <></>;
	}

	@action
	private setSelectStatus(value: boolean) {
		this.selectStatus = value;
	}

	private renderStatusSelector() {
		if (this.selectStatus) {
			return (
				<div className="status-selector">
					{Object.keys(statusOptions).map((option: any) => {
						return (
							<div
								className={option == this.status ? `active-option status-option` : `status-option`}
								onClick={() => this.updatePatientStatus(option)}>
								{this.formatStatus(option)}
							</div>
						);
					})}
				</div>
			);
		}
		return;
	}

	private updatePatientStatus(status: any) {
		PatientEntity.fetch<PatientEntity>(
			{ args: [[{ path: "id", comparison: "equal", value: this.props.match.params.id }]] },
			new PatientEntity().defaultExpands
		).then((d) => {
			d[0].status = status;
			d[0].save().then(
				action(() => {
					this.setStatus(status);
					this.setSelectStatus(false);
				})
			);
		});
	}

	@action
	private setStatus(status: status) {
		this.status = status;
	}

	private formatStatus(status: status) {
		return status.charAt(0).toUpperCase() + status.slice(1).toLowerCase();
	}
}

function queryUserName(id: string) {
	return gql`
		query user {
			patientEntity(id: "${id}") {
				id
				forename
				surname
				status
				lastLogin
			}
		}
	`;
}

// Gets a list of todo items owned by the user
function queryPatientOwnedToDos(owner: string, equality: string, complete: boolean, take?: number) {
	if (equality === 'notEqual') {
		equality = 'equal, negate: true';
	}
	return gql`
        query todo {
            todoEntitys(
                where: [
                    { path: "owner", comparison: equal, value: "${owner}" }
                    { path: "complete", comparison: ${equality}, value: "true" }
                    { path: "complete", comparison: ${equality}, value: "true" }
                ]
				${complete ? `take: ${take} ` : ``}
            ) {
                id
                owner
                complete
                patientId
            }
        }
    `;
}

// Gets a list of todo items for a given patient
function queryPatientAssignedToDos(patient: string, equality: string) {
	if (equality === 'notEqual') {
		equality = 'equal, negate: true';
	}
	return gql`
	query todo {
		todoEntitys(
			where: [
				{ path: "patientId", comparison: equal, value: "${patient}" }
				{ path: "complete", comparison: ${equality}, value: "true" }
			]
		) {
			id
			owner
			complete
            patientId
		}
	}
`;
}

function queryActivitySubmissionsUnseen(patient: string) {
	return gql`
        query activities {
            activitiesSubmissionEntitys(where:{path:"owner", comparison:equal, value:"${patient}"}) {
                id
                owner
                formVersion{
                    id
                    form {
                        id
                        name
                    }
                  }
                clinicianss{
                    cliniciansId 
                }
            }
        }
    `;
}

function queryCheckinSubmissionsUnseen(patient: string) {
	return gql`
        query checkins {           
            ratingtemplateSubmissionEntitys(where:{path:"owner", comparison:equal, value:"${patient}"}) {     
                id      
                owner
                formVersion{
                    id
                    form {
                        id
                        name
                    }
                  }
                clinicianss{
                    cliniciansId 
                }
            }
        }
    `;
}

function queryMoodTracker(patient: string) {
	// NOTE - "value" HAS to be 'Mood tracker' verbatim. These forms are created by admin on childsites.
	return gql`
		query moodTracker {
			ratingtemplateEntity(where: { path: "name", comparison: equal, value: "Mood tracker" }) {
				name
				publishedVersion {
					formSubmissions(
						where: { path: "owner", comparison: equal, value: "${patient}" }
						orderBy: { path: "submissionDate", descending: true }
						take: 14
					) {
						owner
						submissionData
						submissionDate
					}
				}
			}
		}
	`;
}
