import Axios from "axios";
import { TagEntity } from '../Models/Entities';
import { ModelApiQueryResponse } from './CommonService';

export default class TagService {

	public static getAllTags() {
		return Axios.get<ModelApiQueryResponse<TagEntity>>(`/api/entity/TagEntity`);
	}
}
