interface IDashboardSummaryMetricsAttributes {
	totalPatientsActivated: number;
	totalGoalsCreated: number;
	totalTodosCreated: number;
	totalArticlesReadToday: number;
	totalActivitiesSubmittedToday: number;
	totalPatientsCheckedInToday: number;
}

export default class DashboardSummaryMetrics {

	totalPatientsActivated: number = 0;
	totalGoalsCreated: number = 0;
	totalTodosCreated: number = 0;
	totalArticlesReadToday: number = 0;
	totalActivitiesSubmittedToday: number = 0;
	totalPatientsCheckedInToday: number = 0;

	constructor(attributes?: Partial<IDashboardSummaryMetricsAttributes>) {

		if (attributes) {
			if (attributes.totalPatientsActivated) {
				this.totalPatientsActivated = attributes.totalPatientsActivated
			}
			if (attributes.totalGoalsCreated) {
				this.totalGoalsCreated = attributes.totalGoalsCreated
			}
			if (attributes.totalTodosCreated) {
				this.totalTodosCreated = attributes.totalTodosCreated
			}
			if (attributes.totalArticlesReadToday) {
				this.totalArticlesReadToday = attributes.totalArticlesReadToday
			}
			if (attributes.totalPatientsActivated) {
				this.totalPatientsActivated = attributes.totalPatientsActivated
			}
			if (attributes.totalActivitiesSubmittedToday) {
				this.totalActivitiesSubmittedToday = attributes.totalActivitiesSubmittedToday
			}
			if (attributes.totalPatientsCheckedInToday) {
				this.totalPatientsCheckedInToday = attributes.totalPatientsCheckedInToday
			}
		}
	}
}
