import BooksEntity from '../Models/Entities/BooksEntity';

export default class BooksService {

	public static read(id: string) {
		return BooksEntity.fetch<BooksEntity>(
			{
				args: [
					[
						{ path: 'id', comparison: 'equal', value: id }
					]
				]
			}, new BooksEntity().defaultExpands
		)
	}
}
