import { observable, runInAction } from 'mobx';
import { observer } from 'mobx-react';
import { PatientEntity } from 'Models/Entities';
import { store } from 'Models/Store';
import React, { Component } from 'react'
import YHPGradient from 'Views/Components/Gradient/YHPGradient';
import SecuredPage from 'Views/Components/Security/SecuredPage';
import ProfileDetails from '../Components/Profile/ProfileDetails';
import PatientService from '../../Services/PatientService/PatientService';
import { PatientExpands } from '../../Services/PatientService/PatientEntityExpands';
import { gender } from '../../Models/Enums';

@observer
export default class ProfileCreationPage extends Component {

	@observable
	private user: PatientEntity = new PatientEntity({
		// Adding default values for required fields.
		// Request for patients are taking some time so if patient hit's continue before the
		// fetch finishes, the request fails because the form does not show all required fields.
		// These fields are not shown because they should already be filled elsewhere
		forename: "",
		surname: "",
		dateOfBirth: new Date(),
		gender: "OTHER" as gender
	});

	async componentDidMount() {
		if (store.userId != null) {
			try {
				const res = await PatientService.getPatient(store.userId, '')
				runInAction(() => {
					this.user = new PatientEntity(res[0]);
				})
			} catch (err) {
				console.log(err);
			}
		}
	}

	render() {

		let contents = (
			<SecuredPage groups={['Patient']}>
				<div className="profile-body-content">
					<div className="profile-creation">
						<ProfileDetails user={this.user} />
					</div>
					<div className="profile-gradient">
						<YHPGradient />
					</div>
				</div>
			</SecuredPage>
		);
		return contents;
	}
}
