
import React, { Component } from 'react';

interface IGoalsWizardProgress {
	numberSlides: number;
	currentSlideIndex: number,
}

export function GoalsWizardProgress(props: IGoalsWizardProgress) {
	const { numberSlides, currentSlideIndex } = props;
	return (
		<div>
			<div className="goal-wizard-progress-box">
				{Array.from(Array(numberSlides).keys()).map((slideNumber: number) => (
					<div className={(currentSlideIndex === slideNumber) ? 'goal-wizard-progress-dot is-current' : (currentSlideIndex >= slideNumber) ? 'goal-wizard-progress-dot is-filled' : 'goal-wizard-progress-dot'} key={slideNumber}>
					</div>
				))}
			</div>

		</div>
	);
}
