/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import { SERVER_URL } from 'Constants';
import * as React from 'react';
import { action, observable, runInAction } from 'mobx';
import { Model, IModelAttributes, attribute, entity } from 'Models/Model';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from "Util/AttributeUtils";
import { IAcl } from 'Models/Security/IAcl';
import {
	makeFetchManyToManyFunc,
	makeJoinEqualsFunc,
	makeFetchOneToManyFunc,
	makeEnumFetchFunction,
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import { VisitorsRatingtemplateEntity } from 'Models/Security/Acl/VisitorsRatingtemplateEntity';
import { PatientRatingtemplateEntity } from 'Models/Security/Acl/PatientRatingtemplateEntity';
import { ClinicianRatingtemplateEntity } from 'Models/Security/Acl/ClinicianRatingtemplateEntity';
import { CarerRatingtemplateEntity } from 'Models/Security/Acl/CarerRatingtemplateEntity';
import { AdminRatingtemplateEntity } from 'Models/Security/Acl/AdminRatingtemplateEntity';
import * as Enums from '../Enums';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import { FormEntityData, FormEntityDataAttributes, getAllVersionsFn, getPublishedVersionFn } from 'Forms/FormEntityData';
import { FormVersion } from 'Forms/FormVersion';
import { fetchFormVersions, fetchPublishedVersion } from 'Forms/Forms';
import {SuperAdministratorScheme} from '../Security/Acl/SuperAdministratorScheme';
import { TagsRatingtemplates } from '.';
// % protected region % [Add any further imports here] on begin
import { v4 as uuidv4 } from "uuid";
import { store } from "Models/Store";
// % protected region % [Add any further imports here] end

export interface IRatingtemplateEntityAttributes extends IModelAttributes, FormEntityDataAttributes {
	name: string;
	frequency: Enums.frequency;
	description: string;
	featureImageId: string;
	featureImage: Blob;
	bannerId: string;
	banner: Blob;

	formPages: Array<Models.RatingtemplateEntityFormTileEntity | Models.IRatingtemplateEntityFormTileEntityAttributes>;
	patientss: Array<Models.RatingtemplatesPatients | Models.IRatingtemplatesPatientsAttributes>;
	tagss: Array<Models.TagsRatingtemplates | Models.ITagsRatingtemplatesAttributes>;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('RatingtemplateEntity', 'RatingTemplate')
// % protected region % [Customise your entity metadata here] end
export default class RatingtemplateEntity extends Model implements IRatingtemplateEntityAttributes, FormEntityData  {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new VisitorsRatingtemplateEntity(),
		new PatientRatingtemplateEntity(),
		new ClinicianRatingtemplateEntity(),
		new CarerRatingtemplateEntity(),
		new AdminRatingtemplateEntity(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	// % protected region % [Modify props to the crud options here for attribute 'Name'] off begin
	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		name: 'Name',
		displayType: 'textfield',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public name: string;
	// % protected region % [Modify props to the crud options here for attribute 'Name'] end

	// % protected region % [Modify props to the crud options here for attribute 'Frequency'] off begin
	/**
	 * Frequency
	 */
	@observable
	@attribute()
	@CRUD({
		name: 'Frequency',
		displayType: 'enum-combobox',
		order: 20,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: (attr: string) => {
			return AttrUtils.standardiseEnum(attr, Enums.frequencyOptions);
		},
		enumResolveFunction: makeEnumFetchFunction(Enums.frequencyOptions),
		displayFunction: (attribute: Enums.frequency) => Enums.frequencyOptions[attribute],
	})
	public frequency: Enums.frequency;
	// % protected region % [Modify props to the crud options here for attribute 'Frequency'] end

	// % protected region % [Modify props to the crud options here for attribute 'Description'] off begin
	/**
	 * Description for the rating
	 */
	@observable
	@attribute()
	@CRUD({
		name: 'Description',
		displayType: 'textfield',
		order: 30,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public description: string;
	// % protected region % [Modify props to the crud options here for attribute 'Description'] end

	// % protected region % [Modify props to the crud options here for attribute 'Feature Image'] off begin
	/**
	 * Image for the form button
	 */
	@observable
	@attribute({file: 'featureImage'})
	@CRUD({
		name: 'Feature Image',
		displayType: 'file',
		order: 40,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseUuid,
		inputProps: {
			imageOnly: true,
		},
		fileAttribute: 'featureImage',
		displayFunction: attr => attr 
			? <img src={`${SERVER_URL}/api/files/${attr}`} alt='Image for the form button' style={{maxWidth: '300px'}} />
			: 'No File Attached',
	})
	public featureImageId: string;
	@observable
	public featureImage: Blob;
	// % protected region % [Modify props to the crud options here for attribute 'Feature Image'] end

	// % protected region % [Modify props to the crud options here for attribute 'Banner'] off begin
	/**
	 * Banner for the form
	 */
	@observable
	@attribute({file: 'banner'})
	@CRUD({
		name: 'Banner',
		displayType: 'file',
		order: 50,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseUuid,
		inputProps: {
			imageOnly: true,
		},
		fileAttribute: 'banner',
		displayFunction: attr => attr 
			? <img src={`${SERVER_URL}/api/files/${attr}`} alt='Banner for the form' style={{maxWidth: '300px'}} />
			: 'No File Attached',
	})
	public bannerId: string;
	@observable
	public banner: Blob;
	// % protected region % [Modify props to the crud options here for attribute 'Banner'] end

	@observable
	@attribute({isReference: true})
	public formVersions: FormVersion[] = [];

	@observable
	@attribute()
	public publishedVersionId?: string;

	@observable
	@attribute({isReference: true})
	public publishedVersion?: FormVersion;

	@observable
	@attribute({isReference: true})
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Form Page'] off begin
		name: "Form Pages",
		displayType: 'hidden',
		order: 60,
		referenceTypeFunc: () => Models.RatingtemplateEntityFormTileEntity,
		disableDefaultOptionRemoval: true,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'formPages',
			oppositeEntity: () => Models.RatingtemplateEntityFormTileEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Form Page'] end
	})
	public formPages: Models.RatingtemplateEntityFormTileEntity[] = [];

	@observable
	@attribute({isReference: true})
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Patients'] off begin
		name: 'Patients',
		displayType: 'reference-multicombobox',
		order: 70,
		isJoinEntity: true,
		referenceTypeFunc: () => Models.RatingtemplatesPatients,
		optionEqualFunc: makeJoinEqualsFunc('patientsId'),
		referenceResolveFunction: makeFetchManyToManyFunc({
			entityName: 'ratingtemplateEntity',
			oppositeEntityName: 'patientEntity',
			relationName: 'ratingtemplates',
			relationOppositeName: 'patients',
			entity: () => Models.RatingtemplateEntity,
			joinEntity: () => Models.RatingtemplatesPatients,
			oppositeEntity: () => Models.PatientEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Patients'] end
	})
	public patientss: Models.RatingtemplatesPatients[] = [];

	@observable
	@attribute({isReference: true})
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Tags'] off begin
		name: 'Tags',
		displayType: 'reference-multicombobox',
		order: 80,
		isJoinEntity: true,
		referenceTypeFunc: () => Models.TagsRatingtemplates,
		optionEqualFunc: makeJoinEqualsFunc('tagsId'),
		referenceResolveFunction: makeFetchManyToManyFunc({
			entityName: 'ratingtemplateEntity',
			oppositeEntityName: 'tagEntity',
			relationName: 'ratingtemplates',
			relationOppositeName: 'tags',
			entity: () => Models.RatingtemplateEntity,
			joinEntity: () => Models.TagsRatingtemplates,
			oppositeEntity: () => Models.TagEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Tags'] end
	})
	public tagss: Models.TagsRatingtemplates[] = [];

	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<IRatingtemplateEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<IRatingtemplateEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.frequency !== undefined) {
				this.frequency = attributes.frequency;
			}
			if (attributes.description !== undefined) {
				this.description = attributes.description;
			}
			if (attributes.featureImage !== undefined) {
				this.featureImage = attributes.featureImage;
			}
			if (attributes.featureImageId !== undefined) {
				this.featureImageId = attributes.featureImageId;
			}
			if (attributes.banner !== undefined) {
				this.banner = attributes.banner;
			}
			if (attributes.bannerId !== undefined) {
				this.bannerId = attributes.bannerId;
			}
			if (attributes.publishedVersion !== undefined) {
				if (attributes.publishedVersion === null) {
					this.publishedVersion = attributes.publishedVersion;
				} else {
					if (typeof attributes.publishedVersion.formData === 'string') {
						attributes.publishedVersion.formData = JSON.parse(attributes.publishedVersion.formData);
					}
					this.publishedVersion = attributes.publishedVersion;
					this.publishedVersionId = attributes.publishedVersion.id;
				}
			} else if (attributes.publishedVersionId !== undefined) {
				this.publishedVersionId = attributes.publishedVersionId;
			}
			if (attributes.formVersions !== undefined) {
				this.formVersions.push(...attributes.formVersions);
			}
			if (attributes.name !== undefined) {
				this.name = attributes.name;
			}
			if (attributes.formPages !== undefined && Array.isArray(attributes.formPages)) {
				for (const model of attributes.formPages) {
					if (model instanceof Models.RatingtemplateEntityFormTileEntity) {
						this.formPages.push(model);
					} else {
						this.formPages.push(new Models.RatingtemplateEntityFormTileEntity(model));
					}
				}
			}
			if (attributes.patientss !== undefined && Array.isArray(attributes.patientss)) {
				for (const model of attributes.patientss) {
					if (model instanceof Models.RatingtemplatesPatients) {
						this.patientss.push(model);
					} else {
						this.patientss.push(new Models.RatingtemplatesPatients(model));
					}
				}
			}
			if (attributes.tagss !== undefined && Array.isArray(attributes.tagss)) {
				for (const model of attributes.tagss) {
					if (model instanceof Models.TagsRatingtemplates) {
						this.tagss.push(model);
					} else {
						this.tagss.push(new Models.TagsRatingtemplates(model));
					}
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] on begin
			if (attributes.id) {
				this.id = attributes.id;
			}
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] off begin
	public defaultExpands = `
		publishedVersion {
			id
			created
			modified
			formData
		}
		patientss {
			${Models.RatingtemplatesPatients.getAttributes().join('\n')}
			patients {
				${Models.PatientEntity.getAttributes().join('\n')}
			}
		}
		tagss {
			${Models.TagsRatingtemplates.getAttributes().join('\n')}
			tags {
				${Models.TagEntity.getAttributes().join('\n')}
			}
		}
		formPages {
			${Models.RatingtemplateEntityFormTileEntity.getAttributes().join('\n')}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] on begin
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath = {
			patientss: {},
			tagss: {},
			formPages: {},
		};

        await this.getUsersFromTags();
		
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
							'patientss',
							'tagss',
						]
					},
				],
				contentType: 'multipart/form-data',
			}
		);
				
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] off begin
		return this.name;
		// % protected region % [Customise the display name for this entity] end
	}

	/**
	 * Gets all the versions for this form.
	 */
	public getAllVersions: getAllVersionsFn = (includeSubmissions?, conditions?) => {
		// % protected region % [Modify the getAllVersionsFn here] off begin
		return fetchFormVersions(this, includeSubmissions, conditions)
			.then(d => {
				runInAction(() => this.formVersions = d);
				return d.map(x => x.formData)
			});
		// % protected region % [Modify the getAllVersionsFn here] end
	};

	/**
	 * Gets the published version for this form.
	 */
	public getPublishedVersion: getPublishedVersionFn = includeSubmissions => {
		// % protected region % [Modify the getPublishedVersionFn here] off begin
		return fetchPublishedVersion(this, includeSubmissions)
			.then(d => {
				runInAction(() => this.publishedVersion = d);
				return d ? d.formData : undefined;
			});
		// % protected region % [Modify the getPublishedVersionFn here] end
	};

	/**
	 * Gets the submission entity type for this form.
	 */
	public getSubmissionEntity = () => {
		// % protected region % [Modify the getSubmissionEntity here] off begin
		return Models.RatingtemplateSubmissionEntity;
		// % protected region % [Modify the getSubmissionEntity here] end
	}


	// % protected region % [Add any further custom model features here] on begin

	// Loads users into 
	private async getUsersFromTags(){

		for(const tag of this.tagss){

			const usertags = await Models.TagsUsertags.fetch<Models.TagsUsertags>(
				{
					args: [
						[
							{ path: 'tagsId', comparison: 'equal', value: tag.tagsId }
						]
					]
				}
			);

			for(const usertag of usertags){
				const patients = await Models.PatientEntity.fetch<Models.PatientEntity>(
					{
						args: [
							[
								{ path: 'id', comparison: 'equal', value: usertag.usertagsId }
							]
						]
					}
				);
					
				patients.forEach(patient => {
					let patientExists = false;
					this.patientss.forEach(existingPatient => {
						if(existingPatient.patientsId == patient.id){
							patientExists = true;
						}
					})
					if(!patientExists){
						const rtp = new Models.RatingtemplatesPatients({
							patients: patient,
							created: new Date(),
							modified: new Date()
						});
		
						runInAction(() => this.patientss.push(rtp));
					}
				})
			}
		}
	}
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [ createdAttr, modifiedAttr ] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(RatingtemplateEntity.prototype, 'created');
CRUD(modifiedAttr)(RatingtemplateEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
