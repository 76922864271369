import { SERVER_URL } from 'Constants';
import { action, observable } from 'mobx';
import { observer } from 'mobx-react';
import { ArticleReadEventEntity, PatientEntity, TagEntity } from 'Models/Entities';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Button, Colors, Display, Sizes } from '../Button/Button';
import { ButtonGroup } from '../Button/ButtonGroup';
import { ICollectionItemActionProps } from '../Collection/Collection';
import { IFilter } from '../Collection/CollectionFilterPanel';
import { ICollectionHeaderProps } from '../Collection/CollectionHeaders';
import { MultiCombobox } from '../Combobox/MultiCombobox';
import { DatePicker } from '../DatePicker/DatePicker';
import { ApiQueryParams, IOrderByCondition } from '../ModelCollection/ModelAPIQuery';
import { ModelCollection } from '../ModelCollection/ModelCollection';
import PatientArticlesGraph from '../PatientGraphs/PatientArticlesGraph';
import { DashboardTableFilterOptions, DashboardTableOptions, DASHBOARD_COMMON_TEXT } from './DashboardCommon';
import { utcDateToLocalDateString } from '../../../Util/TimelineUtils';
import axios from 'axios';
import alert from '../../../Util/ToastifyUtils';
import moment from 'moment';

class ArticlesReadTableOptions extends DashboardTableOptions {
	@observable
	orderBy: IOrderByCondition<ArticleReadEventEntity> = {
		path: 'readDate', descending: true
	}

	@observable
	public fromArticleReadDate: Date | null = null;

	@observable
	public toArticleReadDate: Date | null = null;

	@action
	setFilterOptions = (
		selectedTagIds: string[],
		selecetedPatientIds: string[],
		selectedFromArticleReadDate: Date | null,
		selectedToArticleReadDate: Date | null
	) => {
		let parsedTagIds: string[] = [];
		selectedTagIds?.forEach(id => parsedTagIds.push(id));
		this.tagIds = parsedTagIds;

		let parsedPatientIds: string[] = [];
		selecetedPatientIds?.forEach(id => parsedPatientIds.push(id));
		this.patientIds = parsedPatientIds;

		const MS_PER_MINUTE = 60000;

		if (selectedFromArticleReadDate != null) {
			// serverside stopped casting timezones after the bot upgrade so this is no longer needed - leaving this here cos timezones has been a cluster of a mess. .
			// selectedFromArticleReadDate = new Date(selectedFromArticleReadDate.getTime() + (selectedFromArticleReadDate.getTimezoneOffset() * MS_PER_MINUTE))
		}

		if (selectedToArticleReadDate != null) {
			selectedToArticleReadDate.setHours(23);
			selectedToArticleReadDate.setMinutes(59);
			selectedToArticleReadDate.setSeconds(59);

			// serverside stopped casting timezones after the bot upgrade so this is no longer needed - leaving this here cos timezones has been a cluster of a mess. .
			// selectedToArticleReadDate = new Date(selectedToArticleReadDate.getTime() + (selectedToArticleReadDate.getTimezoneOffset() * MS_PER_MINUTE))
			// selectedToArticleReadDate = moment(selectedToArticleReadDate).add(24, 'hours').toDate();
		}

		this.fromArticleReadDate = selectedFromArticleReadDate;
		this.toArticleReadDate = selectedToArticleReadDate;
	}
}

/**
 * Class for storing the selected data from the filter data table.
 * These are not passed into the table until we click `run report`.
 */
class ArticleReadTableFilterOptions extends DashboardTableFilterOptions {
	@observable
	public selectedFromArticleReadDate: Date | null;

	@observable
	public selectedToArticleReadDate: Date | null;
}

interface IDashboardArticlesProps {
	tags: TagEntity[];
	patients: PatientEntity[];
}

@observer
export default class DashboardArticles extends Component<IDashboardArticlesProps> {

	@observable
	public filterOptions: ArticleReadTableFilterOptions = new ArticleReadTableFilterOptions();

	@observable
	public tableOptions: ArticlesReadTableOptions = new ArticlesReadTableOptions();

	constructor(props: IDashboardArticlesProps) {
		super(props);
	}

	@action
	setFilterOptionTagOptions() {
		this.filterOptions.tagOptions = this.props.tags.map(attr => {
			return { display: attr.name, value: attr.id };
		})
		this.filterOptions.tagOptions.unshift({ display: "All", value: "All" });

		this.filterOptions.patientOptions = this.props.patients.map(attr => {
			return { display: attr.fullname, value: attr.id }
		});
		this.filterOptions.patientOptions.unshift({ display: "All", value: "All" });
	}

	componentDidMount() {
		this.setFilterOptionTagOptions();
	}
	render() {
		return (
			<div>
				<div className="dashboard-patient-row">
					{this.renderArticlesGraph()}
					{this.renderArticlesTableFilterOptions()}
				</div>
				{this.renderArticlesTable()}
			</div>
		);
	}

	renderArticlesGraph = () => {
		return (
			<div className="dashboard-grid-item dashboard-patient-graph">
				<h1>Library article engagement</h1>
				<p>This graph displays the number of articles read each day.</p>
				<PatientArticlesGraph />
			</div>
		)
	}

	renderArticlesTableFilterOptions = () => {
		return (
			<div className="dashboard-grid-item dashboard-patient-table-filter">
				<h1>{DASHBOARD_COMMON_TEXT.filterTable.title}</h1>
				<p>{DASHBOARD_COMMON_TEXT.filterTable.description}</p>
				<div className='dashboard-patient-table-filter__row'>
					<MultiCombobox
						className='dashboard-patient-table-filter__multicombobox'
						placeholder="Select tags"
						model={this.filterOptions}
						modelProperty="selectedTagIds"
						label="Tag"
						options={this.filterOptions.tagOptions || []}
						isClearable
						onAfterChange={(event, data) => {
							this.filterOptions.setPatientOptionsFilteredByTags(data.value as string[], this.props.patients);
						}}
					/>
					<MultiCombobox
						className='dashboard-patient-table-filter__multicombobox'
						placeholder={"Select patients"}
						model={this.filterOptions}
						modelProperty="selectedPatientIds"
						label="Patients"
						options={this.filterOptions.patientOptions || []}
						isClearable
					/>
				</div>
				<div className='dashboard-patient-table-filter__row'>
					<DatePicker
						className='dashboard-patient-table-filter__datepicker'
						label="From Article Read Date"
						model={this.filterOptions}
						modelProperty="selectedFromArticleReadDate"
						flatpickrOptions={{
							dateFormat: 'd/m/Y',
						}}
					/>
					<DatePicker
						className='dashboard-patient-table-filter__datepicker'
						label="To Article Read Date"
						model={this.filterOptions}
						modelProperty="selectedToArticleReadDate"
						flatpickrOptions={{
							dateFormat: 'd/m/Y',
						}}
					/>
				</div>

				<div className='dashboard-patient-table-filter__row'>
					<ButtonGroup>
						<Button
							className="dashboard-button__run-report"
							display={Display.Solid}
							colors={Colors.Black}
							onClick={() => this.handleOnClickRunReport()}
						>
							Run Report
						</Button>
						<Button
							className="dashboard-button__run-report"
							display={Display.Solid}
							colors={Colors.Black}
							onClick={() => this.handleOnClickExportReport()}
						>
							Export
						</Button>
					</ButtonGroup>
				</div>
			</div>
		)
	}

	handleOnClickRunReport = () => {
		// Update the selected values when we click run report.
		// Otherwise, everytime we select new options in the dropdown, the table refreshes.
		let parsedSelectedTagIds = this.filterOptions.getParsedSelectedTagIds();
		let parsedSelectedPatientIds = this.filterOptions.getParsedSelectedPatientIds();

		this.tableOptions.setFilterOptions(
			parsedSelectedTagIds,
			parsedSelectedPatientIds,
			this.filterOptions.selectedFromArticleReadDate,
			this.filterOptions.selectedToArticleReadDate
		);
	}

	handleOnClickExportReport = () => {
		axios.get(
			"/api/entity/ArticleReadEventEntity/dashboard_export_articleReads",
			{
				params: this.getMoreParams(),
			}
		).then((result) => {
			const blob = new Blob([result.data], { type: "text/csv;charset=utf-8" });
			saveAs(blob, `export-dashoboard-articleReads.csv`);
		}).catch(() => {
			alert('Unsuccessfully exported dashboard articles', 'error');
		})
	}

	renderArticlesTable = () => {

		return (
			<div className="dashboard-grid-item dashboard-table">
				<ModelCollection
					url="/api/entity/ArticleReadEventEntity/articles-read-list"
					isApiQuery
					model={ArticleReadEventEntity}
					orderBy={this.tableOptions.orderBy}
					perPage={this.tableOptions.paginationQueryOptions.perPage}
					headers={this.getHeaders()}
					actions={this.getActions()}
					getMoreParams={this.getMoreParams}
				/>
			</div>
		)
	}

	getMoreParams = (filters?: Array<IFilter<ArticleReadEventEntity>>, filterApplied?: boolean): ApiQueryParams => {
		const filterParams = {};

		if (!!this.tableOptions.tagIds) {
			filterParams['tagIds'] = JSON.stringify(this.tableOptions.tagIds.map(tag => tag));
		}

		if (!!this.tableOptions.patientIds) {
			filterParams['patientIds'] = JSON.stringify(this.tableOptions.patientIds.map(tag => tag));
		}

		if (!!this.tableOptions.fromArticleReadDate) {
			filterParams['fromArticleReadDate'] = this.tableOptions.fromArticleReadDate;
		}

		if (!!this.tableOptions.toArticleReadDate) {
			filterParams['toArticleReadDate'] = this.tableOptions.toArticleReadDate;
		}
		return filterParams;
	};

	getHeaders = (): Array<ICollectionHeaderProps<ArticleReadEventEntity>> => {
		let headers = [
			{
				name: 'name',
				displayName: 'Article Name',
			},
			{
				name: 'surname',
				displayName: 'Surname',
			},
			{
				name: 'forename',
				displayName: 'First Name',
			},
			{
				name: 'userRole',
				displayName: 'User Role',
			},
			{
				name: 'created',
				displayName: 'Read date',
				transformItem: (item, name) => item[name] ? utcDateToLocalDateString(item[name]) : 'N/A'
			},
			{
				name: 'libraryTagNames',
				displayName: 'Area of Life',
				transformItem: (item, name) => {
					return item.libraryTagNames.length > 0 ? item.libraryTagNames.join(', ') : 'N/A';
				}
			}
		] as Array<ICollectionHeaderProps<ArticleReadEventEntity>>

		headers.forEach(header => {
			header.sortable = true;
		})

		return headers;
	};

	getActions = () => {
		const tableActions: Array<ICollectionItemActionProps<ArticleReadEventEntity>> = [];

		tableActions.push({
			action: item => {
			},
			label: 'View Profile',
			customButton: (item) => (
				<Link to={`${SERVER_URL}/profile/patient/${item.ownerId}`}>
					<Button colors={Colors.Primary} display={Display.Text} sizes={Sizes.Small}>View Profile</Button>
				</Link>
			)
		});
		return tableActions;
	};
}

