import classNames from "classnames";
import React from "react";
import styles from "./../../../scss/components/button.module.scss";
import { Button, ICbButtonProps } from "./Button";

interface IYHPButtonProps extends ICbButtonProps {
	children?: React.ReactNode;
	active?: boolean;
}

export const YHPButtonDropdown = (props: IYHPButtonProps) => {
	return (
		<Button
			{...props}
			className={classNames([
				styles.YHPButtonDropdown,
				props.active ? styles.YHPButtonDropdownActive : "",
				props.className,
			])}>
			{props.children}
		</Button>
	);
};
