/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import { ILink } from 'Views/Components/Navigation/Navigation';
import { RouteComponentProps } from 'react-router';
import { store } from 'Models/Store';
// % protected region % [Add any extra imports here] on begin
import { displayName } from "Symbols";
import * as Models from "Models/Entities";
import { getModelDisplayName } from "Util/EntityUtils";
// % protected region % [Add any extra imports here] end

export function getFrontendNavLinks(pageProps: RouteComponentProps): ILink[][] {
	// % protected region % [Add any logic before displaying page links here] on begin
	// % protected region % [Add any logic before displaying page links here] end
	return [
		[
			// % protected region % [Customise top nav section here] on begin
			{
				label: "Home",
				path: "/",
				icon: "home",
				iconPos: "icon-left",
			},
			// % protected region % [Customise top nav section here] end
		],
		[
			// % protected region % [Customise middle nav section here] on begin
			{
				label: "To-Do",
				path: "/to-do",
				icon: "todo",
				iconPos: "icon-left",
			},
			{
				label: "Goals",
				path: "/goals",
				icon: "target",
				iconPos: "icon-left",
				shouldDisplay: () => {
					if (
						store.userGroups.find((group) => group.name === "Patient") &&
						!store.userGroups.find((group) => group.name === "Admin")
					) {
						return true;
					}
					return false;
				},
			},

			{
				label: "Library",
				path: "/library",
				icon: "yhp-library",
				iconPos: "icon-left",
			},
			{
				label: "Activities",
				path: "/activities",
				icon: "quiz",
				iconPos: "icon-left",
			},
		],
		[
			{
				label: getModelDisplayName(Models.PatientEntity) + "s",
				path: "/patient",
				icon: "person-group",
				iconPos: "icon-left",
				shouldDisplay: () =>
					store.userGroups.find(
						(group) =>
							group.name === "Admin" ||
							group.name === "Clinician" ||
							group.name === "Carer"
					) !== undefined,
			},
			{
				label: getModelDisplayName(Models.ClinicianEntity) + "s",
				path: "/clinician",
				icon: "person-group",
				iconPos: "icon-left",
				shouldDisplay: () =>
					store.userGroups.find((group) => group.name === "Admin") !== undefined,
			},
			{
				label: getModelDisplayName(Models.CarerEntity) + "s",
				path: "/carer",
				icon: "person-group",
				iconPos: "icon-left",
				shouldDisplay: () =>
					store.userGroups.find((group) => group.name === "Admin") !== undefined,
			},

			// % protected region % [Customise middle nav section here] end
		],
		[
			// % protected region % [Customise bottom nav section here] on begin
			{
				label: "My Profile",
				path: "/profile",
				icon: "person",
				iconPos: "icon-left",
				shouldDisplay: () => {
					if (store.userGroups.find((group) => group.name === "Admin")) {
						return false;
					}
					return true;
				},
			},
			{
				label: "Login",
				path: "/login",
				icon: "login",
				iconPos: "icon-left",
				shouldDisplay: () => !store.loggedIn,
			},
			{
				label: "Logout",
				path: "/logout",
				icon: "logout",
				iconPos: "icon-left",
				shouldDisplay: () => store.loggedIn,
			},
			// % protected region % [Customise bottom nav section here] end
		],
	];
}
