import React, { useEffect, useState } from "react";
import { PatientEntity, SocialMediaLinkEntity } from "Models/Entities";
import { Button, Colors, Display } from "../Button/Button";
import { TextField } from "../TextBox/TextBox";
import alert from "Util/ToastifyUtils";
import { getModelName } from "Util/EntityUtils";

interface ISocialLinks {
	user: PatientEntity | any;
	refetch: () => void;
}

interface ISummaryState {
	user: PatientEntity;
	renderButtons: boolean;
	edit: boolean;
}

export default function SocialMediaLinks(props: ISocialLinks) {
	const [state, setState] = useState<ISummaryState>({
		user: props.user,
		renderButtons: false,
		edit: false,
	});

	useEffect(() => {
		setState({ ...state, user: props.user });
	}, [props]);

	const handleCancel = () => {
		props.refetch();
		setState({ ...state, renderButtons: false, edit: false });
	};

	const handleChange = () => {
		setState({ ...state, renderButtons: true });
	};

	const handleDelete = (index: number) => {
		let _user = state.user;
		_user.patientSocialMediaLinkss.splice(index, 1);
		setState({ ...state, renderButtons: true, user: _user, edit: true });
	};

	const handleSave = () => {
		props.user
			.save(
				{ patientSocialMediaLinkss: {} },
				{
					graphQlInputType: `[${getModelName(PatientEntity)}Input]`,
					options: [{ key: "mergeReferences", graphQlType: "[String]", value: ["patientSocialMediaLinkss"] }],
				}
			)
			.then(() => {
				alert("Information saved!");
				setState({ ...state, renderButtons: false, edit: false });
			})
			.catch(() => {
				alert("Please enter a name and URL for each social media link");
			});
	};

	const handleAdd = () => {
		let link = new SocialMediaLinkEntity();
		let _user = state.user;

		_user.patientSocialMediaLinkss.push(link);
		setState({ ...state, renderButtons: true, user: _user, edit: true });
	};

	return (
		<div className="profile-card">
			<div className="profile-header">
				SOCIAL MEDIA LINKS <div className="icon-left icon-edit-box" onClick={() => setState({ ...state, edit: !state.edit })} />
			</div>
			<div className="profile-body">
				{renderSocialMediaLinks()}
				<div className="add-item icon-left icon-plus" onClick={handleAdd}>
					Add Social Media Link
				</div>

				{state.renderButtons ? (
					<div className="profile-field">
						<div className="profile-label"/>
						<div className="profile-save-cancel">
							<div className="cancel" onClick={handleCancel}>
								Cancel
							</div>
							<Button className="save" display={Display.Solid} colors={Colors.Primary} onClick={handleSave}>
								Save
							</Button>
						</div>
					</div>
				) : (
					<></>
				)}
			</div>
		</div>
	);

	function renderSocialMediaLinks() {
		//
		if (state.user.patientSocialMediaLinkss) {
			return state.user.patientSocialMediaLinkss.map((link: SocialMediaLinkEntity, index: number) => {
				if (state.edit) {
					return (
						<div className="profile-field" key={index}>
							<TextField
								className="profile-input profile-input-social"
								label="Name"
								model={state.user.patientSocialMediaLinkss[index]}
								modelProperty={`name`}
								placeholder="Name"
								onAfterChange={handleChange}
							/>
							<TextField
								className="profile-input profile-input-social"
								label="URL"
								model={state.user.patientSocialMediaLinkss[index]}
								modelProperty={`url`}
								placeholder="URL"
								onAfterChange={handleChange}
							/>
							<div className="icon-left icon-bin-delete" onClick={() => handleDelete(index)} />
						</div>
					);
				}
				return (
					<div
						className="social-field"
						key={index}
						onClick={() => window.open(`https://${state.user.patientSocialMediaLinkss[index].url}`)}>
						<div className="icon-left icon-link-1 name">{state.user.patientSocialMediaLinkss[index].name}</div>
						<div className="url">{state.user.patientSocialMediaLinkss[index].url}</div>
					</div>
				);
			});
		}
		return;
	}
}
