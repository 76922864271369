/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import { action, observable } from 'mobx';
import { Model, IModelAttributes, attribute, entity } from 'Models/Model';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from "Util/AttributeUtils";
import { IAcl } from 'Models/Security/IAcl';
import {
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import { VisitorsAreaOfLifeRatingsEntity } from 'Models/Security/Acl/VisitorsAreaOfLifeRatingsEntity';
import { PatientAreaOfLifeRatingsEntity } from 'Models/Security/Acl/PatientAreaOfLifeRatingsEntity';
import { ClinicianAreaOfLifeRatingsEntity } from 'Models/Security/Acl/ClinicianAreaOfLifeRatingsEntity';
import { CarerAreaOfLifeRatingsEntity } from 'Models/Security/Acl/CarerAreaOfLifeRatingsEntity';
import { AdminAreaOfLifeRatingsEntity } from 'Models/Security/Acl/AdminAreaOfLifeRatingsEntity';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import {SuperAdministratorScheme} from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] on begin
import * as Enums from 'Models/Enums';
export const AreaOfLifeRatingRange = {
	// Max rating value
	MAX: 10,

	// Min rating value (also supports -1 which is not applicable)
	MIN: 0,
};
// % protected region % [Add any further imports here] end

export interface IAreaOfLifeRatingsEntityAttributes extends IModelAttributes {
	fullRating: boolean;
	work: number;
	financial: number;
	social: number;
	relationships: number;
	spiritual: number;
	family: number;
	psychological: number;
	physical: number;

	patientId?: string;
	patient?: Models.PatientEntity | Models.IPatientEntityAttributes;
	// % protected region % [Add any custom attributes to the interface here] on begin
	surname: string;
	forename: string;
	ownerId: string;
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('AreaOfLifeRatingsEntity', 'Area Of Life Ratings')
// % protected region % [Customise your entity metadata here] end
export default class AreaOfLifeRatingsEntity extends Model implements IAreaOfLifeRatingsEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new VisitorsAreaOfLifeRatingsEntity(),
		new PatientAreaOfLifeRatingsEntity(),
		new ClinicianAreaOfLifeRatingsEntity(),
		new CarerAreaOfLifeRatingsEntity(),
		new AdminAreaOfLifeRatingsEntity(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	// % protected region % [Modify props to the crud options here for attribute 'Full Rating'] off begin
	/**
	 * Whether this instance is a rating for the full areas of life or for a single.
	 */
	@observable
	@attribute()
	@CRUD({
		name: 'Full Rating',
		displayType: 'checkbox',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public fullRating: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'Full Rating'] end

	// % protected region % [Modify props to the crud options here for attribute 'Work'] off begin
	@Validators.Integer()
	@observable
	@attribute()
	@CRUD({
		name: 'Work',
		displayType: 'textfield',
		order: 20,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseInteger,
	})
	public work: number;
	// % protected region % [Modify props to the crud options here for attribute 'Work'] end

	// % protected region % [Modify props to the crud options here for attribute 'Financial'] off begin
	@Validators.Integer()
	@observable
	@attribute()
	@CRUD({
		name: 'Financial',
		displayType: 'textfield',
		order: 30,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseInteger,
	})
	public financial: number;
	// % protected region % [Modify props to the crud options here for attribute 'Financial'] end

	// % protected region % [Modify props to the crud options here for attribute 'Social'] off begin
	@Validators.Integer()
	@observable
	@attribute()
	@CRUD({
		name: 'Social',
		displayType: 'textfield',
		order: 40,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseInteger,
	})
	public social: number;
	// % protected region % [Modify props to the crud options here for attribute 'Social'] end

	// % protected region % [Modify props to the crud options here for attribute 'Relationships'] off begin
	@Validators.Integer()
	@observable
	@attribute()
	@CRUD({
		name: 'Relationships',
		displayType: 'textfield',
		order: 50,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseInteger,
	})
	public relationships: number;
	// % protected region % [Modify props to the crud options here for attribute 'Relationships'] end

	// % protected region % [Modify props to the crud options here for attribute 'Spiritual'] off begin
	@Validators.Integer()
	@observable
	@attribute()
	@CRUD({
		name: 'Spiritual',
		displayType: 'textfield',
		order: 60,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseInteger,
	})
	public spiritual: number;
	// % protected region % [Modify props to the crud options here for attribute 'Spiritual'] end

	// % protected region % [Modify props to the crud options here for attribute 'Family'] off begin
	@Validators.Integer()
	@observable
	@attribute()
	@CRUD({
		name: 'Family',
		displayType: 'textfield',
		order: 70,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseInteger,
	})
	public family: number;
	// % protected region % [Modify props to the crud options here for attribute 'Family'] end

	// % protected region % [Modify props to the crud options here for attribute 'Psychological'] off begin
	@Validators.Integer()
	@observable
	@attribute()
	@CRUD({
		name: 'Psychological',
		displayType: 'textfield',
		order: 80,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseInteger,
	})
	public psychological: number;
	// % protected region % [Modify props to the crud options here for attribute 'Psychological'] end

	// % protected region % [Modify props to the crud options here for attribute 'Physical'] off begin
	@Validators.Integer()
	@observable
	@attribute()
	@CRUD({
		name: 'Physical',
		displayType: 'textfield',
		order: 90,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseInteger,
	})
	public physical: number;
	// % protected region % [Modify props to the crud options here for attribute 'Physical'] end

	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Patient'] off begin
		name: 'Patient',
		displayType: 'reference-combobox',
		order: 100,
		referenceTypeFunc: () => Models.PatientEntity,
		// % protected region % [Modify props to the crud options here for reference 'Patient'] end
	})
	public patientId?: string;
	@observable
	@attribute({isReference: true})
	public patient: Models.PatientEntity;

	// % protected region % [Add any custom attributes to the model here] on begin
	@observable
	public surname: string;

	@observable
	public forename: string;

	@observable
	public ownerId: string;
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<IAreaOfLifeRatingsEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<IAreaOfLifeRatingsEntityAttributes>) {
		// % protected region % [Override assign attributes here] on begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.fullRating != null) {
				this.fullRating = attributes.fullRating;
			}
			if (attributes.work != null) {
				this.work = attributes.work;
			}
			if (attributes.financial != null) {
				this.financial = attributes.financial;
			}
			if (attributes.social != null) {
				this.social = attributes.social;
			}
			if (attributes.relationships != null) {
				this.relationships = attributes.relationships;
			}
			if (attributes.spiritual != null) {
				this.spiritual = attributes.spiritual;
			}
			if (attributes.family != null) {
				this.family = attributes.family;
			}
			if (attributes.psychological != null) {
				this.psychological = attributes.psychological;
			}
			if (attributes.physical != null) {
				this.physical = attributes.physical;
			}
			if (attributes.patient) {
				if (attributes.patient instanceof Models.PatientEntity) {
					this.patient = attributes.patient;
					this.patientId = attributes.patient.id;
				} else {
					this.patient = new Models.PatientEntity(attributes.patient);
					this.patientId = this.patient.id;
				}
			} else if (attributes.patientId !== undefined) {
				this.patientId = attributes.patientId;
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] on begin
			if (attributes.surname) {
				this.surname = attributes.surname;
			}
			if (attributes.forename) {
				this.forename = attributes.forename;
			}
			if (attributes.ownerId) {
				this.ownerId = attributes.ownerId;
			}
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] off begin
	public defaultExpands = `
		patient {
			${Models.PatientEntity.getAttributes().join('\n')}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] off begin
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath = {
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
						]
					},
				],
			}
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] off begin
		return this.id;
		// % protected region % [Customise the display name for this entity] end
	}


	// % protected region % [Add any further custom model features here] on begin
	/**
	 * Method for returning a readable string of a rating on an area of life. Method is required as the initial
	 * implementation has implicit interpretations depending on the value.
	 * - 0 to 10    == a rating value
	 * - null       == unset
	 * - -1         == not applicable (set by user)
	 */
	public ratingToDisplayString = (value: number | null) => {
		if (value == null) {
			return 'None';
		} else if (value === 0) {
			return '0';
		} else if (value === -1) {
			return 'N/A';
		} else {
			return value.toString();
		}
	}

	/**
	 * @tech-debt
	 * Ratings for area of life are stored on a patient.
	 * To get the rating for a patient, we need t omap the area of life enum literals to
	 * the fields the devs have specified on the patient.
	 *
	 * @param lifeArea
	 */
	public getLifeAreaRating(lifeArea: Enums.lifeArea) {
		return this[lifeArea.toLowerCase()];
	}

	public setLifeAreaRating(lifeArea: Enums.lifeArea, value: number) {
		this[lifeArea.toLowerCase()] = value;
	}

	public isAreaDisabled(lifeArea: Enums.lifeArea) {
		return this[lifeArea.toLowerCase()] == -1;
	}

	/**
	 * Given a new area of life rating and an initial area of life rating, set the area of lifes on this instance,
	 * where a change has actually happened between the old and new one values.
	 *
	 * We only set the changed values and leave the rest as null for proper average calculations.
	 */
	public setValuesForChangedAreas(initialRating: AreaOfLifeRatingsEntity, newRating: AreaOfLifeRatingsEntity) {
		Object.keys(Enums.lifeAreaOptions).forEach(type => {
			const area = type as Enums.lifeArea;

			// Get value for the specific life area on the old and new AreaOfLife instance.
			const initialValue = initialRating.getLifeAreaRating(area);
			const newValue = newRating.getLifeAreaRating(area);

			// Set the value if there is a change.
			if (initialValue !== newValue) {
				this.setLifeAreaRating(area, newValue);
			}
		})
	}
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [ createdAttr, modifiedAttr ] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(AreaOfLifeRatingsEntity.prototype, 'created');
CRUD(modifiedAttr)(AreaOfLifeRatingsEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
