/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import { Model, IModelAttributes, attribute, entity } from 'Models/Model';
import * as Models from 'Models/Entities';
import { IAcl } from '../Security/IAcl';
import { observable } from 'mobx';
import { VisitorsViewedratingtemplatesubmissionsReferenceManyToMany } from '../Security/Acl/VisitorsViewedratingtemplatesubmissionsReferenceManyToMany';
import { PatientViewedratingtemplatesubmissionsReferenceManyToMany } from '../Security/Acl/PatientViewedratingtemplatesubmissionsReferenceManyToMany';
import { ClinicianViewedratingtemplatesubmissionsReferenceManyToMany } from '../Security/Acl/ClinicianViewedratingtemplatesubmissionsReferenceManyToMany';
import { CarerViewedratingtemplatesubmissionsReferenceManyToMany } from '../Security/Acl/CarerViewedratingtemplatesubmissionsReferenceManyToMany';
import { AdminViewedratingtemplatesubmissionsReferenceManyToMany } from '../Security/Acl/AdminViewedratingtemplatesubmissionsReferenceManyToMany';

// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface ICliniciansViewedratingtemplatesubmissionsAttributes extends IModelAttributes {
	cliniciansId: string;
	viewedratingtemplatesubmissionsId: string;

	clinicians: Models.ClinicianEntity | Models.IClinicianEntityAttributes;
	viewedratingtemplatesubmissions: Models.RatingtemplateSubmissionEntity | Models.IRatingtemplateSubmissionEntityAttributes;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

@entity('CliniciansViewedratingtemplatesubmissions')
export default class CliniciansViewedratingtemplatesubmissions extends Model implements ICliniciansViewedratingtemplatesubmissionsAttributes {
	public static acls: IAcl[] = [
		new VisitorsViewedratingtemplatesubmissionsReferenceManyToMany(),
		new PatientViewedratingtemplatesubmissionsReferenceManyToMany(),
		new ClinicianViewedratingtemplatesubmissionsReferenceManyToMany(),
		new CarerViewedratingtemplatesubmissionsReferenceManyToMany(),
		new AdminViewedratingtemplatesubmissionsReferenceManyToMany(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	@observable
	@attribute()
	public cliniciansId: string;

	@observable
	@attribute()
	public viewedratingtemplatesubmissionsId: string;

	@observable
	@attribute({isReference: true})
	public clinicians: Models.ClinicianEntity;

	@observable
	@attribute({isReference: true})
	public viewedratingtemplatesubmissions: Models.RatingtemplateSubmissionEntity;
	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	constructor(attributes?: Partial<ICliniciansViewedratingtemplatesubmissionsAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		if (attributes) {
			if (attributes.cliniciansId) {
				this.cliniciansId = attributes.cliniciansId;
			}
			if (attributes.viewedratingtemplatesubmissionsId) {
				this.viewedratingtemplatesubmissionsId = attributes.viewedratingtemplatesubmissionsId;
			}

			if (attributes.clinicians) {
				if (attributes.clinicians instanceof Models.ClinicianEntity) {
					this.clinicians = attributes.clinicians;
					this.cliniciansId = attributes.clinicians.id;
				} else {
					this.clinicians = new Models.ClinicianEntity(attributes.clinicians);
					this.cliniciansId = this.clinicians.id;
				}
			} else if (attributes.cliniciansId !== undefined) {
				this.cliniciansId = attributes.cliniciansId;
			}

			if (attributes.viewedratingtemplatesubmissions) {
				if (attributes.viewedratingtemplatesubmissions instanceof Models.RatingtemplateSubmissionEntity) {
					this.viewedratingtemplatesubmissions = attributes.viewedratingtemplatesubmissions;
					this.viewedratingtemplatesubmissionsId = attributes.viewedratingtemplatesubmissions.id;
				} else {
					this.viewedratingtemplatesubmissions = new Models.RatingtemplateSubmissionEntity(attributes.viewedratingtemplatesubmissions);
					this.viewedratingtemplatesubmissionsId = this.viewedratingtemplatesubmissions.id;
				}
			} else if (attributes.viewedratingtemplatesubmissionsId !== undefined) {
				this.viewedratingtemplatesubmissionsId = attributes.viewedratingtemplatesubmissionsId;
			}
		}

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}