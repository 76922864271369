/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import { Model, IModelAttributes, attribute, entity } from 'Models/Model';
import * as Models from 'Models/Entities';
import { IAcl } from '../Security/IAcl';
import { observable } from 'mobx';
import { VisitorsUsersReferenceManyToMany } from '../Security/Acl/VisitorsUsersReferenceManyToMany';
import { PatientUsersReferenceManyToMany } from '../Security/Acl/PatientUsersReferenceManyToMany';
import { ClinicianUsersReferenceManyToMany } from '../Security/Acl/ClinicianUsersReferenceManyToMany';
import { CarerUsersReferenceManyToMany } from '../Security/Acl/CarerUsersReferenceManyToMany';
import { AdminUsersReferenceManyToMany } from '../Security/Acl/AdminUsersReferenceManyToMany';

// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface ICarersUsersAttributes extends IModelAttributes {
	carersId: string;
	usersId: string;

	carers: Models.CarerEntity | Models.ICarerEntityAttributes;
	users: Models.PatientEntity | Models.IPatientEntityAttributes;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

@entity('CarersUsers')
export default class CarersUsers extends Model implements ICarersUsersAttributes {
	public static acls: IAcl[] = [
		new VisitorsUsersReferenceManyToMany(),
		new PatientUsersReferenceManyToMany(),
		new ClinicianUsersReferenceManyToMany(),
		new CarerUsersReferenceManyToMany(),
		new AdminUsersReferenceManyToMany(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	@observable
	@attribute()
	public carersId: string;

	@observable
	@attribute()
	public usersId: string;

	@observable
	@attribute({isReference: true})
	public carers: Models.CarerEntity;

	@observable
	@attribute({isReference: true})
	public users: Models.PatientEntity;
	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	constructor(attributes?: Partial<ICarersUsersAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		if (attributes) {
			if (attributes.carersId) {
				this.carersId = attributes.carersId;
			}
			if (attributes.usersId) {
				this.usersId = attributes.usersId;
			}

			if (attributes.carers) {
				if (attributes.carers instanceof Models.CarerEntity) {
					this.carers = attributes.carers;
					this.carersId = attributes.carers.id;
				} else {
					this.carers = new Models.CarerEntity(attributes.carers);
					this.carersId = this.carers.id;
				}
			} else if (attributes.carersId !== undefined) {
				this.carersId = attributes.carersId;
			}

			if (attributes.users) {
				if (attributes.users instanceof Models.PatientEntity) {
					this.users = attributes.users;
					this.usersId = attributes.users.id;
				} else {
					this.users = new Models.PatientEntity(attributes.users);
					this.usersId = this.users.id;
				}
			} else if (attributes.usersId !== undefined) {
				this.usersId = attributes.usersId;
			}
		}

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}