import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router';
import { gql } from 'apollo-boost';
import { store } from 'Models/Store';
import Header from 'Views/Components/Header/Header';
import { observer } from 'mobx-react';
import { observable, action } from 'mobx';
import alert from 'Util/ToastifyUtils';
import { PREFIX, SERVER_URL } from 'Constants';
import ArticleBanner from 'Views/Components/Articles/ArticleBanner';
import { Button, Display } from 'Views/Components/Button/Button';
import { TextField } from 'Views/Components/TextBox/TextBox';
import If from 'Views/Components/If/If';
import axios from 'axios';
import ArticleService from 'Services/ArticleService';
import smartlookClient from "smartlook-client";

function queryFeatureArticles() {
	return gql`
        query featureArticle {
            featuredArticleEntitys(take: 1) {
                id
                articles {
                    id
                    title
                    content
                    description
                    featureId
                    booksId
                    books {
                        clinician
                        patient
                        carer
                    }
                }
            }
        }
    `;
}

function queryLatestArticles() {
	return gql`
        query article {
            articlesEntitys(take: 4, orderBy: { path: "modified", descending: true }) {
                id
                title
                content
                featureId
                booksId
            }
        }
    `;
}

function queryLatestArticlesUserGroup(path: string) {
	return gql`
		query article {
			articlesEntitys(take: 4, orderBy: { path: "modified", descending: true }, where: { path: "books.${path}", comparison: equal, value: "true" }) {
				id
				title
				content
				featureId
				booksId
				books {
					patient
					carer
					clinician
				}
			}
		}
	`;
}

interface ISearch {
	searchTerm: string;
}

@observer
export default class LibraryTile extends Component<RouteComponentProps> {
	@observable
	private featureArticle: JSX.Element;

	@observable
	private recommendedArticles: JSX.Element;

	@observable
	private latestArticles: JSX.Element;

	@observable
	private search: ISearch = { searchTerm: '' };

	componentDidMount() {
		// Get the featured article (this will be visible to all)
		this.getFeatureArticle();
		this.getRecommendedArticles();
		// Admins can view all articles
		if (store.userGroups.find((group) => group.name === 'Admin')) {
			this.getLatestArticles();
		}
		// Separate latest and recommended articles via usergroup
		else {
			if (store.userGroups.find((group) => group.name === 'Patient')) {
				this.getLatestArticlesUserGroup('patient');
			} else if (store.userGroups.find((group) => group.name === 'Clinician')) {
				this.getLatestArticlesUserGroup('clinician');
			} else if (store.userGroups.find((group) => group.name === 'Carer')) {
				this.getLatestArticlesUserGroup('carer');
			}
		}

	}

	async getFeatureArticle() {
		await store.apolloClient
			.query({ query: queryFeatureArticles() })
			.then((d) => this.renderFeatureArticle(d.data))
			.catch(() => alert('Feature article is not set'));
	}

	async getLatestArticles() {
		await store.apolloClient
			.query({ query: queryLatestArticles() })
			.then((d) => this.renderLatestArticles(d.data))
			.catch(() => alert('Could not load latest articles'));
	}

	async getLatestArticlesUserGroup(path: string) {
		await store.apolloClient
			.query({ query: queryLatestArticlesUserGroup(path) })
			.then((d) => this.renderLatestArticles(d.data))
			.catch(() => alert('Could not load latest articles'));
	}

	// This currently just gets the next 4 articles
	// In the future, articles will have tags and we can get the first 4 articles
	// that match the tag of the given user (or something?)
	private getRecommendedArticles() {
		ArticleService.getArticles()
			.then((d) =>
				this.renderRecommendedArticles(d.data.slice(0, 4))
			)
			.catch((err) => {
				alert('Could not load recommended articles')
			});
	}


	@action
	private renderFeatureArticle = (featureArticle: any) => {
		const { id, title, featureId, description, books } = featureArticle.featuredArticleEntitys[0].articles;

		let renderFeatureArticle = false;

		if (store.userGroups.find((group) => group.name === 'Admin')) {
			renderFeatureArticle = true;
		} else if (store.userGroups.find((group) => group.name === 'Patient') && books.patient == true) {
			renderFeatureArticle = true;
		} else if (store.userGroups.find((group) => group.name === 'Clinician') && books.clinician == true) {
			renderFeatureArticle = true;
		} else if (store.userGroups.find((group) => group.name === 'Carer') && books.carer == true) {
			renderFeatureArticle = true;
		}

		if (renderFeatureArticle) {
			this.featureArticle = (
				<>
					<h4>Featured Article</h4>
					<div className="featured-container">
						<div
							className="featured-article"
							onClick={() => {
								store.routerHistory.push(`/article/${id}`);
							}}
						>
							<div className="feature-image">
								<img src={`${SERVER_URL}/api/files/${featureId}`} />
							</div>

							<div className="feature-description">
								<h3>{title}</h3>
								<h5>{description}</h5>
							</div>
						</div>
					</div>
				</>
			);
		}
	};

	@action
	private renderLatestArticles = (articles: any) => {
		const { history, location, match, staticContext } = this.props;
		let articleButtons = articles.articlesEntitys.map((article: any) => (
			<ArticleBanner key={article.id} article={article} history={history} location={location} match={match} staticContext={staticContext} />
		));

		let state = { focus: false };
		this.latestArticles = (
			<div className="latest-article-container">
				<h4>Latest Articles</h4>
				{articleButtons}
				<Button display={Display.Outline} onClick={() => {
					store.routerHistory.push('/library/all', state); 
				}}>
					View All
                </Button>
			</div>
		);
	};

	@action
	private renderRecommendedArticles = (articles: any) => {
		const { history, location, match, staticContext } = this.props;
		let articleButtons = articles.map((article: any) => (
			<ArticleBanner key={article.id} article={article} history={history} location={location} match={match} staticContext={staticContext} />
		));

		let state = { focus: false };
		this.recommendedArticles = (
			<div className="recommended-article-container">
				<h4>Recommended Articles</h4>
				{articleButtons}
				<Button display={Display.Outline} onClick={() => {
					store.routerHistory.push('/library/all', state);
				}}>
					View All
                </Button>
			</div>
		);
	};

	@action
	private handleSearch = () => {
		let state = {
			search: this.search.searchTerm,
			path: '/library',
			focus: true,
		};
		store.routerHistory.push('/library/all', state);
	};

	private renderFavourites = () => {
		if (!!store) {
			if (!!store.userGroups[0]) {
				if (store.userGroups[0].name === 'Patient') {
					return true
				}
			}

		}
		return false;
	}

	render() {
		let focus = this.props.location.state == true ? true : false;
		
		let contents = (
			<>
				{/* <Header subtitle="Dashboard" title="Library" /> */}

				<div className="library-header">
					<div>
						<h6>Home / </h6>
						<h2>Library</h2>
					</div>
					<div className="library-all-back">
						<If condition={this.renderFavourites()}>
							<Button
								className=" library-favourites-button"
								icon={{ icon: 'heart-large', iconPos: 'icon-left' }}
								onClick={() => {
									smartlookClient.track('Library_View_Favourites', {});
									store.routerHistory.push('/library/favourites')
								}}
							>
								Favourites
                            </Button>
						</If>
						<Button className="library-index-button" onClick={() => store.routerHistory.push('/library/index')}>
							View Index
                        </Button>
						<div className="search-box">
							<Button icon={{ icon: 'search', iconPos: 'icon-left' }} />
						</div>
						<TextField
							model={this.search}
							modelProperty="searchTerm"
							className="article-search"
							placeholder="Search..."
							onAfterChange={this.handleSearch}
							autoFocus={focus}
						/>
					</div>
				</div>

				{/* Rendering feature/latest/recommended articles*/}
				<div className="articles-container">
					{this.featureArticle}

					<div className="latest-recommended-container">
						{this.latestArticles}
						{this.recommendedArticles}
					</div>
				</div>
			</>
		);
		return contents;
	}
}
