import React from "react";
import YHPGradient from "Views/Components/Gradient/YHPGradient";
import styles from "./../scss/pages/loginPage.module.scss";

interface ILoginContainerProps {
	children?: React.ReactNode;
}

export const LoginWrapper = (props: ILoginContainerProps) => {
	// console.log(colorKeys);

	return (
		<div className={styles.container}>
			<div className={styles.children}>{props.children}</div>
			<YHPGradient className={styles.gradient} />
		</div>
	);
};
