/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import { Model, IModelAttributes, attribute, entity } from 'Models/Model';
import * as Models from 'Models/Entities';
import { IAcl } from '../Security/IAcl';
import { observable } from 'mobx';
import { VisitorsViewedratingpersonalsubmissionsReferenceManyToMany } from '../Security/Acl/VisitorsViewedratingpersonalsubmissionsReferenceManyToMany';
import { PatientViewedratingpersonalsubmissionsReferenceManyToMany } from '../Security/Acl/PatientViewedratingpersonalsubmissionsReferenceManyToMany';
import { ClinicianViewedratingpersonalsubmissionsReferenceManyToMany } from '../Security/Acl/ClinicianViewedratingpersonalsubmissionsReferenceManyToMany';
import { CarerViewedratingpersonalsubmissionsReferenceManyToMany } from '../Security/Acl/CarerViewedratingpersonalsubmissionsReferenceManyToMany';
import { AdminViewedratingpersonalsubmissionsReferenceManyToMany } from '../Security/Acl/AdminViewedratingpersonalsubmissionsReferenceManyToMany';

// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface ICliniciansViewedratingpersonalsubmissionsAttributes extends IModelAttributes {
	cliniciansId: string;
	viewedratingpersonalsubmissionsId: string;

	clinicians: Models.ClinicianEntity | Models.IClinicianEntityAttributes;
	viewedratingpersonalsubmissions: Models.RatingpersonalSubmissionEntity | Models.IRatingpersonalSubmissionEntityAttributes;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

@entity('CliniciansViewedratingpersonalsubmissions')
export default class CliniciansViewedratingpersonalsubmissions extends Model implements ICliniciansViewedratingpersonalsubmissionsAttributes {
	public static acls: IAcl[] = [
		new VisitorsViewedratingpersonalsubmissionsReferenceManyToMany(),
		new PatientViewedratingpersonalsubmissionsReferenceManyToMany(),
		new ClinicianViewedratingpersonalsubmissionsReferenceManyToMany(),
		new CarerViewedratingpersonalsubmissionsReferenceManyToMany(),
		new AdminViewedratingpersonalsubmissionsReferenceManyToMany(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	@observable
	@attribute()
	public cliniciansId: string;

	@observable
	@attribute()
	public viewedratingpersonalsubmissionsId: string;

	@observable
	@attribute({isReference: true})
	public clinicians: Models.ClinicianEntity;

	@observable
	@attribute({isReference: true})
	public viewedratingpersonalsubmissions: Models.RatingpersonalSubmissionEntity;
	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	constructor(attributes?: Partial<ICliniciansViewedratingpersonalsubmissionsAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		if (attributes) {
			if (attributes.cliniciansId) {
				this.cliniciansId = attributes.cliniciansId;
			}
			if (attributes.viewedratingpersonalsubmissionsId) {
				this.viewedratingpersonalsubmissionsId = attributes.viewedratingpersonalsubmissionsId;
			}

			if (attributes.clinicians) {
				if (attributes.clinicians instanceof Models.ClinicianEntity) {
					this.clinicians = attributes.clinicians;
					this.cliniciansId = attributes.clinicians.id;
				} else {
					this.clinicians = new Models.ClinicianEntity(attributes.clinicians);
					this.cliniciansId = this.clinicians.id;
				}
			} else if (attributes.cliniciansId !== undefined) {
				this.cliniciansId = attributes.cliniciansId;
			}

			if (attributes.viewedratingpersonalsubmissions) {
				if (attributes.viewedratingpersonalsubmissions instanceof Models.RatingpersonalSubmissionEntity) {
					this.viewedratingpersonalsubmissions = attributes.viewedratingpersonalsubmissions;
					this.viewedratingpersonalsubmissionsId = attributes.viewedratingpersonalsubmissions.id;
				} else {
					this.viewedratingpersonalsubmissions = new Models.RatingpersonalSubmissionEntity(attributes.viewedratingpersonalsubmissions);
					this.viewedratingpersonalsubmissionsId = this.viewedratingpersonalsubmissions.id;
				}
			} else if (attributes.viewedratingpersonalsubmissionsId !== undefined) {
				this.viewedratingpersonalsubmissionsId = attributes.viewedratingpersonalsubmissionsId;
			}
		}

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}