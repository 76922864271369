/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { RouteComponentProps } from 'react-router';
import { PageWrapper } from 'Views/Components/PageWrapper/PageWrapper';
import Card from '../../Components/Card/Card';
import CardGroup from '../../Components/Card/CardGroup';
// % protected region % [Add any extra imports here] on begin
import { Button, Sizes, Display } from 'Views/Components/Button/Button';
import { ButtonGroup } from 'Views/Components/Button/ButtonGroup';
import NavigationLink from 'Views/Components/Navigation/NavigationLink';
import { Link } from 'react-router-dom';
import { VideoChatLinkEntity } from '../../../Models/Entities';
// % protected region % [Add any extra imports here] end

@observer
export default class AdminPage extends React.Component<RouteComponentProps> {

	public render() {
		// % protected region % [Override contents here] on begin
		return (
            <PageWrapper {...this.props}>
                <div className="admin-dashboard">
                    <h2>Administration</h2>
                    <p>Welcome to the Administration Dashboard. Use this section to manage all data within the YourHealth+ Application.</p>
                    <h4>Quick Links:</h4>

                    <ButtonGroup className="quick-links">
                        <Link className="btn-link" to="admin/user">
                            <Button icon={{ icon: "person-group", iconPos: "icon-left" }} sizes={Sizes.Large} display={Display.Outline}>
                                User Management
                            </Button>
                        </Link>
                        <Link className="btn-link" to="admin/tagentity">
                            <Button icon={{ icon: "tag", iconPos: "icon-left" }} sizes={Sizes.Large} display={Display.Outline}>
                                User Tags
                            </Button>
                        </Link>
                        <Link className="btn-link" to="admin/forms">
                            <Button icon={{ icon: "file-text", iconPos: "icon-left" }} sizes={Sizes.Large} display={Display.Outline}>
                                Form Templates
                            </Button>
                        </Link>
                        <Link className="btn-link" to="admin/libraryadmin">
                            <Button icon={{ icon: "library", iconPos: "icon-left" }} sizes={Sizes.Large} display={Display.Outline}>
                                Library Articles
                            </Button>
                        </Link>
                        <Link className="btn-link" to="admin/booksentity">
                            <Button icon={{ icon: "book", iconPos: "icon-left" }} sizes={Sizes.Large} display={Display.Outline}>
                                Library Books
                            </Button>
                        </Link>
                        <Link className="btn-link" to="admin/onboardingslideentity/create">
                            <Button icon={{ icon: "slide", iconPos: "icon-left" }} sizes={Sizes.Large} display={Display.Outline}>
                                Joyride Slides
                            </Button>
                        </Link>
						<Link className="btn-link" to="admin/customprofilefieldsentity">
                            <Button icon={{ icon: "person", iconPos: "icon-left" }} sizes={Sizes.Large} display={Display.Outline}>
                                Custom Fields
                            </Button>
                        </Link>
                    </ButtonGroup>
                </div>
            </PageWrapper>
        );
		// % protected region % [Override contents here] end
	}
}
