import * as React from 'react';
import { observer } from 'mobx-react';
import { RouteComponentProps } from 'react-router';
import SecuredPage from 'Views/Components/Security/SecuredPage';

import Navigation, { Orientation } from 'Views/Components/Navigation/Navigation';
import { getFrontendNavLinks } from 'Views/FrontendNavLinks';

import Spinner from 'Views/Components/Spinner/Spinner';
import { store } from 'Models/Store';

import { FailedCSVTable } from 'Views/Components/FailedCSVTable/FailedCSVTable';
import Header from 'Views/Components/Header/Header';

@observer
export default class FailedCSVPage extends React.Component<RouteComponentProps> {

	private headers: any[];
	private data: object[];
	private entity: any;

	public render() {
		const {
			history, location, match, staticContext,
		} = this.props;
		
		let _location: any = location;

		if (store.displayNameStatus === 'loading' || this.props.location === null) {
			return <Spinner />
		}

		if (location != undefined && location.state != undefined) {
			this.headers = Object.keys(_location.state["data"][0]);
			this.data = [_location.state["data"]];
			this.entity = [_location.state["entity"]];
		} else {
			store.routerHistory.goBack();
		}

		const navigationLinks = getFrontendNavLinks(this.props);

		const contents = (
			<SecuredPage groups={["Admin", "Carer", "Clinician"]}>
				<Navigation
					linkGroups={navigationLinks}
					orientation={Orientation.VERTICAL}
					match={match}
					location={location}
					history={history}
					staticContext={staticContext}
				/>

				<div className="body-content">
					<Header title="Import Patients" subtitle="User Management" />

					<FailedCSVTable headers={this.headers} data={this.data} entity={this.entity} />
				</div>
			</SecuredPage>
		);

		return contents;
	}
}

