/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import * as React from "react";
import {Link} from "react-router-dom";
import {lowerCaseNoSpaces} from "Util/StringUtils";
// % protected region % [Add any further imports here] on begin
import { EntityFormMode } from "Views/Components/Helpers/Common";
import { ActivitiesEntity, RatingpersonalEntity, RatingtemplateEntity } from "Models/Entities";
import { action } from "mobx";
import { store } from "Models/Store";
// % protected region % [Add any further imports here] end

export interface INewFormVersionTileProps {
	formName: string;
	formDisplayName: string;
	// % protected region % [Add any extra props here] off begin
	// % protected region % [Add any extra props here] end
}

export class NewFormVersionTile extends React.Component<INewFormVersionTileProps>{

	// % protected region % [Add class properties here] on begin
	@action
	private async onClick() {
		let newForm: any;

		// Creates new entity and redirects to form designer of that entity
		if (this.props.formDisplayName == "Activities") {
			newForm = new ActivitiesEntity({ name: "New Activty" });
		} else if (this.props.formDisplayName == "RatingPersonal") {
			newForm = new RatingpersonalEntity({ name: "New Personal Rating" });
		} else if (this.props.formDisplayName == "RatingTemplate") {
			newForm = new RatingtemplateEntity({ name: "New Template Rating" });
		}

		await newForm.saveFromCrud(EntityFormMode.CREATE);
		store.routerHistory.push(`/admin/forms/build/${this.props.formName}/${newForm.id}`);
	}
	// % protected region % [Add class properties here] end

	public render(){
		// % protected region % [Modify render function here] on begin
		return (
			<div className="form-item__new icon-plus icon-bottom" onClick={() => this.onClick()} style={{ cursor: "pointer" }}>
				<h3>New {this.props.formDisplayName}</h3>
			</div>
		);
		// % protected region % [Modify render function here] end
	}
}