import React, { useEffect, useState } from 'react';
import { frequency } from 'Models/Enums';
import FormCard from './FormCard';
import { RatingtemplateSubmissionEntity, VideoChatLinkEntity } from 'Models/Entities';
import styles from './../../../scss/pages/checkInPage.module.scss';
import { CheckInBanner } from '../CheckIn/CheckInBanner';
import { YHPButtonDropdown } from '../Button/YHPButtonDropdown';
import Spinner from '../Spinner/Spinner';
import { useForms } from '../../../Hooks/useForms';
import CheckInVideoBanner from '../CheckIn/CheckInVideoBanner';

export interface IForm {
	id: string;
	name: string;
	frequency: frequency;
	description: string;
	featureImageId: string;
	missingDates?: string[];
}

export default function CheckInTile() {
	const [viewComplete, setViewComplete] = useState(false);
	const [banners, setBanners] = useState<VideoChatLinkEntity[]>([]);
	const { loading, forms } = useForms();

	useEffect(() => {
		VideoChatLinkEntity.fetch<VideoChatLinkEntity>().then((d) => setBanners(d));
	}, []);

	if (loading) {
		return <Spinner />
	}

	return (
		<div className={styles.container}>
			<CheckInBanner />
			<h5 className={styles.title}>
				<b>Your Activities</b>
			</h5>
			<div className={styles.formContainer}>
				{renderIncompleteForms()}
				{forms && forms.complete.length > 0 && (
					<div className={styles.completedText}>
						<YHPButtonDropdown
							active={viewComplete}
							onClick={() => setViewComplete(!viewComplete)}
							icon={{ icon: 'chevron-right', iconPos: 'icon-left' }}
						>
							<h5><b>Completed Activities</b></h5>
						</YHPButtonDropdown>
					</div>
				)}

				{renderCompleteForms()}
			</div>
			{renderBanners()}
		</div>
	);

	function renderBanners() {
		return (
			<div className={styles.videoChatContainer}>
				{banners.length > 0 && (
					<p>
						<b>Support</b>
					</p>
				)}
				<div className={styles.videoChatBannerContainer}>
					{banners.map((chatLink: VideoChatLinkEntity) => (
						<CheckInVideoBanner
							title={chatLink.title}
							description={chatLink.description}
							link={chatLink.link}
						/>
					))}
				</div>
			</div>
		);
	}

	function renderIncompleteForms() {
		if (forms === undefined) {
			return <h6>Loading...</h6>;
		}
		if (forms.incomplete.length === 0) {
			return (
				<h6>Congratulations! You have completed all of your activities.</h6>
			);
		}
		return forms.incomplete.map((form: IForm, index: number) => {
			return (
				<FormCard
					key={form.id}
					id={form.id}
					title={form.name}
					description={form.description}
					featureImage={form.featureImageId}
					completed={false}
					missingDates={form.missingDates}
					incompleteForms={forms.incomplete}
					incompleteFormIndex={index}
				/>
			);
		});
	}

	function renderCompleteForms() {
		return (
			forms &&
			viewComplete &&
			forms.complete.map((form: IForm) => (
				<FormCard
					key={form.id}
					id={form.id}
					title={form.name}
					description={form.description}
					featureImage={form.featureImageId}
					completed={true}
				/>
			))
		);
	}
}
