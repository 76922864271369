import React, { Component } from 'react';
import ArticleButton from './ArticleButton';
import If from '../If/If';

export interface BooksProps {
	title: string;
	articles: any[];
	filter?: string;
}

export default class BookContainer extends Component<BooksProps> {
	render() {
		let articles: any = [];
		if (this.props.articles) {
			this.props.articles.map((article: any, index: any) => {
				// Only render filtered books
				if (!this.props.filter) {
					articles.push(<ArticleButton key={article.id} article={article} />);
				} else {
					if (article.title.toLowerCase().includes(this.props.filter.toLowerCase())) {
						articles.push(<ArticleButton key={article.id} article={article} />);
					}
				}
				// Will eventually implement a means to only display a certain number of books and a view all button
			});
		}

		let title = articles.length > 0 ? <h4>{this.props.title}</h4> : <></>;

		return (
			<>
				{title}

				<div className="all-articles-container">{articles}</div>
			</>
		);
	}
}
