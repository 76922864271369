import { action, observable, runInAction } from 'mobx';
import { observer } from 'mobx-react';
import { AreaOfLifeRatingsEntity, GoalsEntity, PatientEntity } from 'Models/Entities';
import { store } from 'Models/Store';
import React, { Component } from 'react'
import GoalSlideOverview from '../Goals/GoalWizard/GoalSlideOverview';
import GoalSlideSelectAreaOfLife from '../Goals/GoalWizard/GoalSlideSelectAreaOfLife';
import GoalSlideSelectGoal from '../Goals/GoalWizard/GoalSlideSelectGoal';
import { GoalsWizardProgress } from '../Goals/GoalWizard/GoalsWizardProgress'
import If from '../If/If';
import OnboardingAreadOfLifeSaved from './OnboardingAreadOfLifeSaved';
import OnboardingAreaofLife from './OnboardingAreaofLife';
import OnboardingFinish from './OnboardingFinish';
import OnboardingGoalsSaved from './OnboardingGoalsSaved';
import OnboardingIntroduction from './OnboardingIntroduction';
import { prettyLog } from '../../../Util/StringUtils';
import moment from 'moment';
import smartlookClient from "smartlook-client";
import PatientService from '../../../Services/PatientService/PatientService';
import { PatientExpands } from '../../../Services/PatientService/PatientEntityExpands';

const NUMBER_ONBOARDING_SLIDES = 6;

@observer
export default class OnboardingFormWizard extends Component {

	@observable
	public currentSlideIndex = 0;

	@observable
	private patient: PatientEntity = new PatientEntity();

	@observable
	areaOfLifeRating: AreaOfLifeRatingsEntity;

	private initialLifeRating = new AreaOfLifeRatingsEntity();

	@observable
	public goal: GoalsEntity = new GoalsEntity();

	componentDidMount() {
		this.getPatient();
	}

	private async getPatient() {
		if (store.userId == null) {
			return;
		}
		try {
			PatientService.getPatient(store.userId, PatientExpands.patientOnboarding)
				.then((res) => {
					runInAction(() => {
						this.setupInitialOnboardingData(res[0]);
					});
				})
		} catch (error) {
			console.log(error);
		}
	}

	@action
	private onChangeGoal(goal: GoalsEntity) {
		this.goal = new GoalsEntity(goal);
	}

	@action
	private handleOnClickFinish() {
		this.setPatientAttributesForFinishedOnboarding();
		this.patient.goalss.push(this.goal);

		// Create empty area of life rating and only copy over values that the user actually changed.
		const areaOfLifeRating = new AreaOfLifeRatingsEntity({
			id: '00000000-0000-0000-0000-000000000000',
			patientId: this.patient.id,
			fullRating: true
		});

		areaOfLifeRating.setValuesForChangedAreas(this.initialLifeRating, this.areaOfLifeRating);
		this.patient.areaOfLifeRatingss.push(areaOfLifeRating);

		const relationPath = {
			areaOfLifeRatingss: {},
			goalss: {
				goalStepss: {},
				libraryTagss: {},
			}
		};

		const updateOptions = {
			options: [
				{
					key: 'mergeReferences',
					graphQlType: '[String]',
					value: [
						'goalss',
						'areaOfLifeRatingss',
					]
				},
			],
		};

		this.patient.save(relationPath, updateOptions)
			.then(res => {
				smartlookClient.track('Goals_Save_Goal', {});
				this.incrementStep();
			})
			.catch(err => {
				console.log(`error finishing onboard`)
			});
	}

	@action
	public incrementStep(): void {
		this.currentSlideIndex += 1;
	}

	@action
	public decrementStep(): void {
		if (this.currentSlideIndex > 0) {
			this.currentSlideIndex -= 1;
		}
	}

	@action
	setupInitialOnboardingData = (patient: PatientEntity) => {
		this.patient = new PatientEntity(patient);

		this.areaOfLifeRating = new AreaOfLifeRatingsEntity(this.patient.getLatestAreaofLifeValues());
		// Store for later comparison;
		this.initialLifeRating = new AreaOfLifeRatingsEntity(this.patient.getLatestAreaofLifeValues());
	}

	render() {
		return (
			<div className={`onboarding-wizard-container`}>
				<div className="onboardig-wizard__header">
					<If condition={this.currentSlideIndex <= NUMBER_ONBOARDING_SLIDES - 1}>
						<GoalsWizardProgress
							currentSlideIndex={this.currentSlideIndex}
							numberSlides={NUMBER_ONBOARDING_SLIDES} />
					</If>
				</div>
				<div className="onboarding-wizard__content">
					{this.currentSlideIndex === 0 && (
						<OnboardingIntroduction incrementStep={() => this.incrementStep()} />
					)}
					{this.currentSlideIndex === 1 && (
						<OnboardingAreaofLife
							user={this.patient}
							areaOfLifeRating={this.areaOfLifeRating}
							setAreaOfLifeRating={(area) => {
								this.areaOfLifeRating = area;
							}}
							decrementStep={() => this.decrementStep()}
							incrementStep={() => this.incrementStep()}
						/>
					)}
					{this.currentSlideIndex === 2 && (
						<OnboardingAreadOfLifeSaved incrementStep={() => this.incrementStep()} />
					)}
					{this.currentSlideIndex === 3 && (
						<GoalSlideSelectAreaOfLife
							goal={this.goal}
							lifeArea={this.goal.lifeArea}
							areaOfLifeRating={this.areaOfLifeRating}
							decrementStep={() => this.decrementStep()}
							incrementStep={() => this.incrementStep()}
							user={this.patient}
							onClickBack={() => this.decrementStep()}
						/>
					)}
					{this.currentSlideIndex === 4 && (
						<GoalSlideSelectGoal
							goal={this.goal}
							lifeArea={this.goal.lifeArea}
							onChangeGoal={(goal: GoalsEntity) => {
								this.onChangeGoal(goal)
							}}
							decrementStep={() => this.decrementStep()}
							incrementStep={() => this.incrementStep()}
							onClickRedirect={this.handleOnClickSetGoalLater}
						/>
					)}
					{this.currentSlideIndex === 5 && (
						<GoalSlideOverview
							goal={this.goal}
							lifeArea={this.goal.lifeArea}
							initialDisplayState={this.goal.name == null || this.goal.name.trim().length === 0 ? 'GOAL-NAME' : 'GOAL-OVERVIEW'}
							decrementStep={() => {
								this.decrementStep();
							}}
							incrementStep={() => {
								this.incrementStep();
							}}
							onClickDone={() => {
								this.handleOnClickFinish();
							}}
						/>
					)}
					{this.currentSlideIndex === 6 && (
						<OnboardingGoalsSaved
							incrementStep={() => {
								this.incrementStep();
							}} />
					)}

					{this.currentSlideIndex === 7 && (
						<OnboardingFinish />
					)}
				</div>
			</div>
		)
	}

	/**
	 * Update all the fields that are required when patient finished onboarding.
	 *
	 * Only set activated date if its empty. This date is set when onboarding is finished and they can onboard
	 * multiple times if the admin switches the flag in the backend
	 */
	setPatientAttributesForFinishedOnboarding = () => {
		this.patient.onboarded = true;
		this.patient.lastLogin = moment(Date.now()).subtract(moment().utcOffset(), "minutes").toDate();

		if (this.patient.activatedDate == null) {
			this.patient.activatedDate = moment(Date.now()).subtract(moment().utcOffset(), "minutes").toDate();
		}
	}

	handleOnClickSetGoalLater = () => {
		this.setPatientAttributesForFinishedOnboarding();
		// Create empty area of life rating and only copy over values that the user actually changed.
		const areaOfLifeRating = new AreaOfLifeRatingsEntity({
			id: '00000000-0000-0000-0000-000000000000',
			patientId: this.patient.id,
			fullRating: true
		});

		areaOfLifeRating.setValuesForChangedAreas(this.initialLifeRating, this.areaOfLifeRating);
		this.patient.areaOfLifeRatingss.push(areaOfLifeRating);

		const relationPath = {
			areaOfLifeRatingss: {}
		};

		const updateOptions = {
			options: [
				{
					key: 'mergeReferences',
					graphQlType: '[String]',
					value: [
						'areaOfLifeRatingss',
					]
				},
			],
		};

		this.patient
			.save(relationPath, updateOptions)
			.then(res => {
				store.routerHistory.push("/");
			});
	}
}
