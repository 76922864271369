/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */

// % protected region % [Override contents here] on begin
export const SERVER_URL = '';

export const AreaColor = {
	WORK: '#84DFFF',
	FINANCIAL: '#53BAE6',
	SOCIAL: '#40C9AF',
	RELATIONSHIPS: '#FF72A5',
	VALUES: '#FDF372',
	FAMILY: '#FFA7C2',
	PSYCHOLOGICAL: '#DB89EB',
	PHYSICAL: '#F2B2FF',
};

// Refer to colors.scss under scss/frontend
export const GRADIENT_COLORS = {
	PURPLE: '#9f59ad',
	BLUE: '#4197B6',
}

export const PREFIX = "yhp:///"

// % protected region % [Override contents here] end
