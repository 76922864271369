import React from 'react'
import HighchartsReact from 'highcharts-react-official'
import Highcharts from 'highcharts'
import { observer } from 'mobx-react';
import { action, observable, runInAction } from 'mobx';
import PatientTimelineEventsService, { PatientEngagementGraphData } from '../../../Services/PatientTimelineEventsService';
import { Button } from '../Button/Button';
import { ButtonGroup } from '../Button/ButtonGroup';
import moment from 'moment';
import alert from '../../../Util/ToastifyUtils';
import { DASHBOARD_GRAPH_COMMON_CHART_OPTIONS, DashboardGraphDateRange } from './PatientCommonGraph';

/**
 * Graph for displaying the active and inactive patients acrosos 2 week period with navigation.
 * https://github.com/highcharts/highcharts-react
 */
@observer
export default class PatientEngagementGraph extends React.Component {

	@observable
	private highchartOptions: Highcharts.Options;

	@observable
	private data: PatientEngagementGraphData;

	@observable
	private dateRange: DashboardGraphDateRange;

	@action
	updateChartData = (graphData: PatientEngagementGraphData) => {

		this.highchartOptions = {
			title: DASHBOARD_GRAPH_COMMON_CHART_OPTIONS.title,
			legend: DASHBOARD_GRAPH_COMMON_CHART_OPTIONS.legend,
			yAxis: DASHBOARD_GRAPH_COMMON_CHART_OPTIONS.yAxis,
			xAxis: {
				categories: graphData.getXCategories() || []
			},
			series: [
				graphData.getActiveSeriesData(),
				graphData.getInactiveSeriesData(),
				// TODO: ONLY FOR DEV PURPOSE - KEEP IT COMMENTED OUT
				// graphData.getActivatedByThisDateSeriesData()
			]
		}
	}

	constructor(props: any) {
		super(props);
		const startingDate = moment().subtract(DASHBOARD_GRAPH_COMMON_CHART_OPTIONS.xAxisRange, 'days').toDate();
		startingDate.setHours(0);
		startingDate.setMinutes(0);
		startingDate.setSeconds(0);
		const endingDate = moment().toDate();
		endingDate.setHours(23);
		endingDate.setMinutes(59);
		endingDate.setSeconds(59);
		this.dateRange = new DashboardGraphDateRange(startingDate, endingDate);
	}

	componentDidMount() {
		this.fetchChartDataAndUpdateChart();
	}

	@action
	fetchChartDataAndUpdateChart = () => {
		PatientTimelineEventsService.getPatientEngagementGraphData(this.dateRange)
			.then(res => {
				runInAction(() => {
					this.data = new PatientEngagementGraphData(res.data);
					this.updateChartData(this.data);
				});
			})
			.catch(err => {
				console.error(err);
				alert('Unsuccessfully fetched patient graph data', 'error')
			});
	}

	@action
	onPanLeft = () => {
		this.dateRange.updateRangeBackward(DASHBOARD_GRAPH_COMMON_CHART_OPTIONS.xAxisRange);
		this.fetchChartDataAndUpdateChart();
	}

	@action
	onPanRight = () => {
		this.dateRange.updateRangeForward(DASHBOARD_GRAPH_COMMON_CHART_OPTIONS.xAxisRange);
		this.fetchChartDataAndUpdateChart();
	}

	render() {

		return (
			<div>
				<ButtonGroup>
					<Button className="push-right" onClick={this.onPanLeft}>
						View left
					</Button>
					<Button
						disabled={moment(this.dateRange.endDate).isSameOrAfter(moment())}
						onClick={this.onPanRight}
					>
						View right
					</Button>
				</ButtonGroup>
				<HighchartsReact
					highcharts={Highcharts}
					options={this.highchartOptions}
				/>
			</div>
		)
	}
}
