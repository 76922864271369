import React from 'react'
import { RouteComponentProps } from 'react-router';
import OnboardingFormWizard from 'Views/Components/NewuserOnboarding/OnboardingFormWizard';
import SecuredPage from 'Views/Components/Security/SecuredPage';
import YHPGradient from '../Components/Gradient/YHPGradient';
import styles from "./../../scss/pages/loginPage.module.scss";

export default class NewUserOnboardingPage extends React.Component<RouteComponentProps> {
	render() {

		const contents = (
			<SecuredPage groups={["Patient"]}>
				<div className="body-content">
					<YHPGradient className={styles.gradientOnboarding}>
						<OnboardingFormWizard />
					</YHPGradient>
				</div>
			</SecuredPage>
		);

		return contents;
	}
}
