import React from 'react';
import classNames from 'classnames';

export interface IDashboardHeaderButtonProps {
	// Represents a statistic that is shown on the button. Optional as not all buttons have one.
	value?: number;
	// Represents the description of the statistic that is shown on the button.
	// Optional as not all buttons have one. Description should exists if value exists
	description?: string;
	// Title of the dashboard button that changes the content shown on the dashboard.
	title: string;
	// Callback for handling pressing the card
	onClick: () => void;
	active?: boolean;
}

export default class DashboardHeaderButton extends React.Component<IDashboardHeaderButtonProps> {

	render() {

		const clazz = classNames(['dashboard-header-button', (this.props.active ? 'active' : '')]);

		return (
			<div
				className="dashboard-header-button-container"
				onClick={() => this.props.onClick()}
			>
				<div className={clazz}>
					<p className="dashboard-header-button__value">{this.props.value}</p>
					<p className="dashboard-header-button__description">{this.props.description}</p>
					<p className="dashboard-header-button__title">{this.props.title}</p>
				</div>
				{this.props.active && (
					<div className="arrow-container">
						<div className="arrow-down" />
					</div>
				)}
			</div>
		);
	}
}
