/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import { Model, IModelAttributes, attribute, entity } from 'Models/Model';
import * as Models from 'Models/Entities';
import { IAcl } from '../Security/IAcl';
import { observable } from 'mobx';
import { VisitorsViewedactivitiesSubmissionsReferenceManyToMany } from '../Security/Acl/VisitorsViewedactivitiesSubmissionsReferenceManyToMany';
import { PatientViewedactivitiesSubmissionsReferenceManyToMany } from '../Security/Acl/PatientViewedactivitiesSubmissionsReferenceManyToMany';
import { ClinicianViewedactivitiesSubmissionsReferenceManyToMany } from '../Security/Acl/ClinicianViewedactivitiesSubmissionsReferenceManyToMany';
import { CarerViewedactivitiesSubmissionsReferenceManyToMany } from '../Security/Acl/CarerViewedactivitiesSubmissionsReferenceManyToMany';
import { AdminViewedactivitiesSubmissionsReferenceManyToMany } from '../Security/Acl/AdminViewedactivitiesSubmissionsReferenceManyToMany';

// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface ICliniciansViewedactivitiesSubmissionsAttributes extends IModelAttributes {
	cliniciansId: string;
	viewedactivitiesSubmissionsId: string;

	clinicians: Models.ClinicianEntity | Models.IClinicianEntityAttributes;
	viewedactivitiesSubmissions: Models.ActivitiesSubmissionEntity | Models.IActivitiesSubmissionEntityAttributes;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

@entity('CliniciansViewedactivitiesSubmissions')
export default class CliniciansViewedactivitiesSubmissions extends Model implements ICliniciansViewedactivitiesSubmissionsAttributes {
	public static acls: IAcl[] = [
		new VisitorsViewedactivitiesSubmissionsReferenceManyToMany(),
		new PatientViewedactivitiesSubmissionsReferenceManyToMany(),
		new ClinicianViewedactivitiesSubmissionsReferenceManyToMany(),
		new CarerViewedactivitiesSubmissionsReferenceManyToMany(),
		new AdminViewedactivitiesSubmissionsReferenceManyToMany(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	@observable
	@attribute()
	public cliniciansId: string;

	@observable
	@attribute()
	public viewedactivitiesSubmissionsId: string;

	@observable
	@attribute({isReference: true})
	public clinicians: Models.ClinicianEntity;

	@observable
	@attribute({isReference: true})
	public viewedactivitiesSubmissions: Models.ActivitiesSubmissionEntity;
	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	constructor(attributes?: Partial<ICliniciansViewedactivitiesSubmissionsAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		if (attributes) {
			if (attributes.cliniciansId) {
				this.cliniciansId = attributes.cliniciansId;
			}
			if (attributes.viewedactivitiesSubmissionsId) {
				this.viewedactivitiesSubmissionsId = attributes.viewedactivitiesSubmissionsId;
			}

			if (attributes.clinicians) {
				if (attributes.clinicians instanceof Models.ClinicianEntity) {
					this.clinicians = attributes.clinicians;
					this.cliniciansId = attributes.clinicians.id;
				} else {
					this.clinicians = new Models.ClinicianEntity(attributes.clinicians);
					this.cliniciansId = this.clinicians.id;
				}
			} else if (attributes.cliniciansId !== undefined) {
				this.cliniciansId = attributes.cliniciansId;
			}

			if (attributes.viewedactivitiesSubmissions) {
				if (attributes.viewedactivitiesSubmissions instanceof Models.ActivitiesSubmissionEntity) {
					this.viewedactivitiesSubmissions = attributes.viewedactivitiesSubmissions;
					this.viewedactivitiesSubmissionsId = attributes.viewedactivitiesSubmissions.id;
				} else {
					this.viewedactivitiesSubmissions = new Models.ActivitiesSubmissionEntity(attributes.viewedactivitiesSubmissions);
					this.viewedactivitiesSubmissionsId = this.viewedactivitiesSubmissions.id;
				}
			} else if (attributes.viewedactivitiesSubmissionsId !== undefined) {
				this.viewedactivitiesSubmissionsId = attributes.viewedactivitiesSubmissionsId;
			}
		}

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}