import React, { Component } from "react";
import { gql } from "apollo-boost";
import { store } from "Models/Store";
import { Button, Display } from "Views/Components/Button/Button";
import { Link, RouteComponentProps } from "react-router-dom";
import { observer } from "mobx-react";
import { observable, action } from "mobx";
import { ButtonGroup } from "Views/Components/Button/ButtonGroup";
import Popup from "reactjs-popup";
import { TextField } from "Views/Components/TextBox/TextBox";
import alert from "Util/ToastifyUtils";
import * as Models from "Models/Entities";
import AdminBookContainer from "Views/Components/Articles/AdminBookContainer";

function getBooksWithArticles() {
    return gql`
        query bookArticles {
            booksEntitys {
                id
                name
                learningss {
                    id
                    title
                    featureId
                    description
                    content
                    htmlcontent
                    activitiesId
                }
            }
        }
    `;
}

interface Books {
    id: string;
    name: string;
    articles: [];
}

@observer
export default class LibraryAdmin extends Component<RouteComponentProps> {
    @observable
    private books: JSX.Element[] = [];

    @observable
    private book = {
        title: "",
    };

    public componentDidMount(): void {
        this.getLibraryArticles();
    }

    private async getLibraryArticles() {
        let books: Books[] = [];
        await store.apolloClient
            .query({
                query: getBooksWithArticles(),
            })
            .then((d) => {
                books = d.data.booksEntitys.map((book: any) => ({
                    id: book.id,
                    name: book.name,
                    articles: book.learningss,
                }));
            })
            .catch(() => alert("Could not retrieve content"))
            .finally(() => this.renderBooks(books));
    }

    @action
    private renderBooks(books: Books[]) {
        this.books = books.map((book: any) => {
            return (
                <AdminBookContainer
                    key={book.id}
                    book={book.id}
                    title={book.name}
                    articles={book.articles}
                    editing={true}
                />
            );
        });
    }

    @action
    private handleTitle = (e: any) => {
        this.book.title = e.target.value;
        console.log(this.book.title);
    };

    private createBook = () => {
        if (!this.book.title) {
            alert("Please enter a book title");
        } else {
            const book = new Models.BooksEntity({ name: this.book.title });
            book.save()
                .then(() => window.open("/admin/libraryadmin", "_self"))
                .catch(() => alert("Could not save article"));
        }
    };

    render() {
        let contents = (
            <>
                <div className="library-header">
                    <div>
                        <h6>Admin</h6>
                        <h2>Library</h2>
                    </div>
                    <div className="crud-btn-container">
                        <ButtonGroup className="article-crud-buttons">
                            <Link
                                to={{
                                    pathname: "/admin/libraryeditor",
                                    state: {
                                        article: {
                                            content: "",
                                            description: "",
                                            featureId: "",
                                            title: "",
                                        },
                                        book: "",
                                    },
                                }}
                            >
                                <Button
                                    display={Display.Solid}
                                    icon={{
                                        icon: "plus",
                                        iconPos: "icon-right",
                                    }}
                                >
                                    Create an article
                                </Button>
                            </Link>

                            <Popup
                                trigger={
                                    <Button
                                        display={Display.Solid}
                                        icon={{
                                            icon: "book",
                                            iconPos: "icon-right",
                                        }}
                                    >
                                        Create a book
                                    </Button>
                                }
                                modal
                            >
                                {(close) => (
                                    <>
                                        <TextField
                                            model={this.book}
                                            modelProperty="title"
                                            label="Book Title"
                                            placeholder="Enter the title of the book"
                                            isRequired={true}
                                            onChangeAndBlur={this.handleTitle}
                                        />
                                        <div className="btn-delete-container">
                                            <Button
                                                onClick={() =>
                                                    this.createBook()
                                                }
                                                className="btn-create-yes"
                                                display={Display.Solid}
                                                icon={{
                                                    icon: "plus",
                                                    iconPos: "icon-left",
                                                }}
                                            >
                                                Create Book
                                            </Button>

                                            <Button
                                                onClick={() => close()}
                                                className="btn-create-no"
                                                display={Display.Solid}
                                                icon={{
                                                    icon: "cross",
                                                    iconPos: "icon-left",
                                                }}
                                            >
                                                Cancel
                                            </Button>
                                        </div>
                                    </>
                                )}
                            </Popup>
                        </ButtonGroup>
                    </div>
                </div>
                {this.books}
            </>
        );

        return contents;
    }
}
