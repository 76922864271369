import React from 'react';
import {DASHBOARD_COMMON_TEXT} from "./DashboardCommon";

type props = {
	children: JSX.Element
}

export default function DashboardFilterDatalayout({children}: props) {
	return (
		<div className="dashboard-grid-item dashboard-patient-table-filter">
			<h1>{DASHBOARD_COMMON_TEXT.filterTable.title}</h1>
			<p>{DASHBOARD_COMMON_TEXT.filterTable.description}</p>
			{children}
		</div>
	);
}
