import * as React from 'react';
import { observer } from 'mobx-react';
import { RouteComponentProps } from 'react-router';
import SecuredPage from 'Views/Components/Security/SecuredPage';
import Navigation, { Orientation } from 'Views/Components/Navigation/Navigation';
import { getFrontendNavLinks } from 'Views/FrontendNavLinks';
import { Button } from "Views/Components/Button/Button";
import { action, observable } from "mobx";
import Header from "Views/Components/Header/Header";
import { store } from "Models/Store";
import { displayName } from "Symbols";
import * as Models from "Models/Entities";
import Spinner from "Views/Components/Spinner/Spinner";
import Popup from "reactjs-popup";
import CSVUserImport from "Views/Components/CSVUserImport/CSVUserImport";
import PatientWrappingTileTile from '../Tiles/PatientWrappingTile';
import smartlookClient from "smartlook-client";

@observer
class CustomPatientPage extends React.Component<RouteComponentProps> {

	@observable
	private popupOpen: boolean = false;

	@action
	public onPopupOpen = () => {
		smartlookClient.track('Patients_Import_CSV', {});
		this.popupOpen = true;
	}

	@action
	public onPopupClose = () => {
		this.popupOpen = false;
	}

	public render() {
		const { history, location, match, staticContext } = this.props;

		const navigationLinks = getFrontendNavLinks(this.props);
		if (store.displayNameStatus === "loading") {
			return <Spinner />;
		}

		return (
			<SecuredPage groups={['Super Administrators', 'Clinician', 'Admin']}>
				<Navigation
					linkGroups={navigationLinks}
					orientation={Orientation.VERTICAL}
					match={match}
					location={location}
					history={history}
					staticContext={staticContext}
				/>

				<div className="body-content">
					<Header title={Models.PatientEntity[displayName]} subtitle="User Management" />
					
					<div className="import-button">{this.renderImportCustomCSVButton()}</div>
						
					<PatientWrappingTileTile
						history={history}
						location={location}
						match={match}
						staticContext={staticContext}
					/>
				</div>
			</SecuredPage>
		);
	}


	private renderImportCustomCSVButton = () => {
		//import custom CSV Button is not visible to clinicians
    	if (store.userGroups.find((group) => group.name === 'Clinician')) {
			return 
		}  
		return (
			<Popup
			trigger={<Button className="btn btn--outline import">Import Custom CSV</Button>}
			position="right center"
			modal
			closeOnDocumentClick
			open={this.popupOpen}
			onOpen={this.onPopupOpen}
		>
			<CSVUserImport closePopup={this.onPopupClose} entity="Patient" />
		</Popup>
		)
	}
		
	
}

export default CustomPatientPage;
