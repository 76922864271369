import React, { Component } from "react";
import { observer } from "mobx-react";
import { RouteComponentProps } from "react-router";
import { observable, action, toJS, runInAction } from "mobx";
import moment from "moment";
import PreviewPane from "./PreviewPane";
import { PatientEntity, GoalsEntity, AreaOfLifeRatingsEntity } from "Models/Entities";
import { AreaProgress } from '../Goals/GoalsProgess';
import { getModelDisplayName } from '../../../Util/EntityUtils';
import { GetAreaOfLifeBackgroundPath, GoalButton } from "../Goals/GoalsButtons";
import classNames from "classnames";
import { Button } from "../Button/Button";
import GoalViewTile from "Views/Tiles/GoalViewTile";
import GoalFormWizard from "../Goals/GoalWizard/GoalFormWizard";
import PatientService from '../../../Services/PatientService/PatientService';
import { store } from '../../../Models/Store';
import { PatientExpands } from '../../../Services/PatientService/PatientEntityExpands';


interface IProfileGoals extends RouteComponentProps {
	userId: string;
}

@observer
export default class ProfileGoals extends Component<IProfileGoals> {

	@observable
	patientsAreaOfLifeRating: AreaOfLifeRatingsEntity = new AreaOfLifeRatingsEntity();

	@observable
	private viewPreview: boolean = false;

	@observable
	private currentGoal: GoalsEntity | undefined;


	@observable
	private patientEntity: PatientEntity = new PatientEntity();

	@observable
	private completedGoalsExpanded: boolean = false;

	@observable
	private containerStyle = {};

	render() {
		return (
			<div className="profile-goals-page">
				{this.renderContent()}
				{this.renderPreview()}
			</div>
		);
	}

	async componentDidMount() {
		await this.getPatient();
	}

	private async getPatient() {
		try {
			const res = await PatientService.getPatient(this.props.userId, PatientExpands.patientProfileGoals)
			this.setPatient(res)
		} catch (err) {
			console.log(err);
		}
	}

	@action
	public onClick(goal: GoalsEntity): void {
		this.setCurrentGoal(goal)
	}


	@action
	private setPatient = (results: PatientEntity[]) => {
		this.patientEntity = results[0];
		if (this.patientEntity.hasAFullAreaOfLifeRating()) {
			this.patientsAreaOfLifeRating = this.patientEntity.getLatestAreaofLifeValues();
		}
	};

	private renderGoals() {
		const pendingGoals = this.patientEntity.goalss.filter((goal) => !goal.isComplete && !goal.archived);
		const completedGoals = this.patientEntity.goalss.filter((goal) => goal.isComplete && !goal.archived);
		return (
			<div className="goals-container">
				<div className="goals-header">
					<div className="goals-header-item profile-goals-title">GOALS</div>
					<div className="goals-header-item add-goal" onClick={() => this.createGoal()}>
						Add a goal
					</div>
				</div>
				{pendingGoals.length > 0 ? (pendingGoals.map((goal) => {
					return (
						<div
							key={goal.id}
							className={classNames('goals-card')}
							style={{
								backgroundImage: `url(${GetAreaOfLifeBackgroundPath(
									goal.lifeArea
								)})`,
							}}
						>
							<GoalButton goal={goal} onGoalClick={() => this.onClick(goal)} />
						</div>
					);
				})) : <h5>This patient does not have any goals, add one using the button above</h5>}

				{completedGoals.length > 0 && (
					<Button
						className={this.completedGoalsExpanded ? " completed-task-button icon-left icon-chevron-down" : " completed-task-button icon-left icon-chevron-right"}
						onClick={() => {
							runInAction(() => {
								this.completedGoalsExpanded = !this
									.completedGoalsExpanded;
							});
						}}
					>
						Completed
					</Button>
				)}

				{this.completedGoalsExpanded &&
					completedGoals.length > 0 &&
					completedGoals.map((goal) => {
						return (
							<div
								className='goals-card'
								style={{
									backgroundImage: `url(${GetAreaOfLifeBackgroundPath(
										goal.lifeArea
									)})`,
								}}
								onClick={() =>

									this.setCurrentGoal(goal)}
							>

								<GoalButton goal={goal} onGoalClick={() => this.onClick(goal)} />
							</div>
						);
					})}
			</div>
		);
	}

	@action
	private createGoal() {
		this.currentGoal = undefined;
		this.viewPreview = true;
	}

	@action
	private saveAndCelebrate() {
		this.closePreview();
		this.getPatient();
	}

	@action
	setCurrentGoal(goal: GoalsEntity) {
		GoalsEntity.fetch<GoalsEntity>(
			{
				args: [
					[{ path: 'id', comparison: 'equal', value: goal.id }],
				],
			},
			new GoalsEntity().defaultExpands
		).then((d) => {
			runInAction(() => {
				this.currentGoal = d[0];
			})
		});

		this.viewPreview = true;
		if (window.innerWidth < 800) {
			this.containerStyle = { display: "none" };
		}
	}

	private renderContent() {
		return (
			<div className="goals-left" style={toJS(this.containerStyle)} onClickCapture={() => this.closePreview()}>
				{this.renderAreaOfLifeRating()}
				{this.renderGoals()}
			</div>
		);
	}

	private renderAreaOfLifeRating = () => {

		return (
			<div className="profile-goals-aol-container">
				<div className="profile-goals-title">Areas of Life</div>
				<div className="area-progress">

					{this.patientEntity.hasAFullAreaOfLifeRating()
						? <AreaProgress
							user={this.patientEntity}
							areaOfLifeRating={this.patientsAreaOfLifeRating}
							displayFooter={true}
							onClick={(area) => { }}
						/>
						: (
							<div>
								{`This ${getModelDisplayName(PatientEntity)} has not completed their goal setting activity`}
							</div>
						)
					}
				</div>
			</div>
		);
	}

	private renderPreview() {
		if (this.viewPreview) {
			if (this.currentGoal) {
				const { id, created } = this.currentGoal;
				return (
					<div className="goal-preview-pane">
						<GoalViewTile
							goal={this.currentGoal}
							user={this.patientEntity}
							onClickBack={() => { this.closePreview() }}
							onClickDelete={() => { this.closePreview(); this.getPatient(); }}
							onPopUpSaveAndCelebrate={() => this.saveAndCelebrate()}
							onAfterSave={() => {
								this.getPatient();
							}}
						/>
					</div>

				);
			} else {
				return (
					<PreviewPane
						name="Add A Goal"
						createdDate={new Date()}
						submittedDate={new Date()}
						created={moment().format("Do MMMM YYYY")}
						submitted={moment().format("Do MMMM YYYY")}
						countForms={0}
						goal={true}
						currentFormIndex={0}
						previous={() => { }}
						next={() => { }}
						close={() => this.closePreview()}>
						<div className="goals-create">
							<GoalFormWizard hideForProfile={true} onDone={() => { this.closePreview(); this.getPatient(); }} patient={this.patientEntity} />
						</div>
					</PreviewPane >
				);
			}
		}
		return (
			<div className="goals-image">
				<img src={process.env.PUBLIC_URL + "/Goals/goals-bg2x.png"} />
			</div>
		);
	}

	@action
	private closePreview() {
		this.viewPreview = false;
		this.containerStyle = {};
	}

}



