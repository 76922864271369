import moment from "moment";
import React, { Component } from "react";
import If from "../If/If";

interface IFormButton {
	title: string;
	submissions: any;
	countSubmissions: number;
	frequency?: string;
	lastCreated: string;
	lastSubmission?: Date;
	onClick?: () => void;
	showBadge?: boolean;
}

export default class FormButton extends Component<IFormButton> {
	render() {
		const { title, submissions, countSubmissions, frequency, lastCreated, lastSubmission, onClick } = this.props;

		var submissionString = 'n/a'

		if(lastSubmission != undefined) {
			submissionString = moment.utc(lastSubmission).local().format('Do MMMM YYYY');
		}


		return (
			<div className="form-button" onClick={onClick}>
				<If condition={this.props.showBadge}>
					<div className="badge"></div>
				</If>
				<div className="top-container">
					<div className="form-name">{title}</div>
					<div className="count-type-container">
						<div>{countSubmissions} submissions</div>
						<div className="form-type">{frequency}</div>
					</div>
				</div>

				<div className="bottom-container">
					Last submission: <div>{submissionString}</div> {/* //FIX  */}
				</div>
			</div>
		);
	}
}
