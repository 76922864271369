
import * as React from 'react';
import { observer } from 'mobx-react';
import { RouteComponentProps } from 'react-router';
import OnboardingSlideTile from './OnboadingSlideTile';

@observer
export default class OnboardingSlideWrappingTileTile extends React.Component<RouteComponentProps> {

	public render() {
		let contents = (
			<>
				<OnboardingSlideTile {...this.props} viewOnly={false} />
			</>
		);
		return contents;
	}

}
