import { SERVER_URL } from "Constants";
import { store } from "Models/Store";
import React from "react";
import styles from "../../../scss/pages/loginPage.module.scss";

const YHPLogo = () => {

	return (
		<div className={styles.logoContainer}>
			<img src={`${process.env.PUBLIC_URL}/Logo/bhg_logo_vert.png`} alt='YHP Logo'/>

			{store.customLogo && (
				<img src={`${SERVER_URL}/api/files/${store.customLogo}`} alt='Custom Logo' />
			)}
		</div>
	);
};

export default YHPLogo;
