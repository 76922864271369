import { store } from "Models/Store";
import React from "react";
import styles from "./../../../scss/components/checkInBanner.module.scss";

export const CheckInBanner = () => {

	return (

		<div
			className={styles.banner}
			id='checkInBanner'
			style={{ backgroundImage: `url('${process.env.PUBLIC_URL}/Checkin/CheckInBanner.jpg')` }}
		>

			<div className={styles.bannerGradient} />
			<div className={styles.bannerText}>
				<h4>Welcome {store.getUser()?.forename ?? ''}.</h4>
				<h2>Let's get started!</h2>
			</div>
		</div>
	);
};
