import classNames from "classnames";
import React from "react";
import styles from "./../../../scss/components/button.module.scss";
import { Button, ICbButtonProps } from "./Button";

interface IYHPButtonProps extends ICbButtonProps {
	children?: React.ReactNode;
}

export const YHPButton = (props: IYHPButtonProps) => {
	return (
		<Button {...props} className={classNames([styles.YHPButton, props.className])}>
			{props.children}
		</Button>
	);
};
