import * as React from "react";
import _ from "lodash";
import { observer } from "mobx-react";
import { action, computed, observable } from "mobx";
import { jsonReplacerFn, Model } from "Models/Model";
import { FormTile, IFormProps } from "./FormTile";
import { FormEntityData, SubmissionEntityData } from "Forms/FormEntityData";
import moment, { Moment } from "moment";

export interface IFormEntityTileProps<T extends FormEntityData> extends IFormProps<T> {
	model: T;
	submissionDate?: Moment;
	onAfterSubmit?: (entity: Model & SubmissionEntityData) => void;
	onAfterFinish?: (entity: Model & SubmissionEntityData) => void;
}

/**
 * Managed form that handles submit getting published forms and submit logic automatically
 */
@observer
export class RatingEntityTile<T extends FormEntityData> extends React.Component<IFormEntityTileProps<T>> {
	@observable
	private submissionEntity: Model & SubmissionEntityData = new (this.props.model.getSubmissionEntity())();

	private submissionTransform: jsonReplacerFn = (input) => {
		let submissionData = input["submissionData"];
		if (submissionData) {
			input["submissionData"] = JSON.stringify(submissionData);
		}
		return input;
	};

	@action
	private onSubmit = () => {
		if (this.formVersion) {
			this.submissionEntity.formVersionId = this.formVersion.id;
			const questions = _.flatMap(this.formVersion.formData, (s) => s.contents).filter((q) => q.property !== undefined);
			const excludedAttributes = ["id", "created", "modified", "submissionData", "formVersionId", "submissionDate"];
			const submissionAttributes = _.without(this.submissionEntity.attributes, ...excludedAttributes);
			for (const question of questions) {
				const data = this.submissionEntity.submissionData[question.id];
				const property = question.property;
				if (data !== undefined && data !== null && property && submissionAttributes.indexOf(property) > -1) {
					this.submissionEntity.assignAttributes({ [property]: data });
				}
			}
		}

		this.submissionTransform(this.submissionEntity);
		if (this.props.submissionDate) {
			this.submissionEntity["submissionDate"] = this.props.submissionDate.utc().toDate(); //FIX
		}
		this.submissionEntity.save().then(() => {
			this.afterSubmit();
		});
	};

	@action
	private onFinish = () => {
		if (this.formVersion) {
			this.submissionEntity.formVersionId = this.formVersion.id;
			const questions = _.flatMap(this.formVersion.formData, (s) => s.contents).filter((q) => q.property !== undefined);
			const excludedAttributes = ["id", "created", "modified", "submissionData", "formVersionId", "submissionDate"];
			const submissionAttributes = _.without(this.submissionEntity.attributes, ...excludedAttributes);
			for (const question of questions) {
				const data = this.submissionEntity.submissionData[question.id];
				const property = question.property;
				if (data !== undefined && data !== null && property && submissionAttributes.indexOf(property) > -1) {
					this.submissionEntity.assignAttributes({ [property]: data });
				}
			}
		}
		this.submissionTransform(this.submissionEntity);
		if (this.props.submissionDate) {
			this.submissionEntity["submissionDate"] =  moment.utc(this.props.submissionDate).local().format("YYYY-MM-DD");
        }

		this.submissionEntity.save().then(() => {
			this.afterFinish();
		});
	};

	@action
	private afterSubmit = () => {
		this.submissionEntity = new (this.props.model.getSubmissionEntity())();
		if (this.props.onAfterSubmit) {
			return this.props.onAfterSubmit(this.submissionEntity);
		}
	};

	@action
	private afterFinish = () => {
		this.submissionEntity = new (this.props.model.getSubmissionEntity())();
		if (this.props.onAfterFinish) {
			return this.props.onAfterFinish(this.submissionEntity);
		}
	};

	@computed
	public get formVersion() {
		return this.props.model.publishedVersion;
	}

	public render() {
		const onSubmit = this.props.onAfterSubmit ? () => this.onSubmit() : undefined;
		if (this.formVersion) {
			return (
				<FormTile
					{...this.props}
					model={this.submissionEntity.submissionData}
					schema={this.formVersion.formData}
					onSubmit={onSubmit}
					onFinish={() => this.onFinish()}
					finishText="Finish"
				/>
			);
		}
		return <></>;
	}
}
