import React, { useEffect, useState } from "react";
import { PatientEntity, ClinicianEntity, CarerEntity } from "Models/Entities";
import { MultiCombobox } from "../Combobox/MultiCombobox";
import { getModelDisplayName } from "Util/EntityUtils";
import If from "../If/If";
import Spinner from "../Spinner/Spinner";

interface IAssociations {
    user: PatientEntity | ClinicianEntity | CarerEntity | any;
    userGroup: "Patient" | "Clinician" | "Carer" | string;
}

interface IAssociationsState {
    associations: any;
    clinicians: any;
    patients: any;
    carers: any;
}

export default function Associations(props: IAssociations) {
    const [state, setState] = useState<IAssociationsState>({
        associations: undefined,
        clinicians: undefined,
        patients: undefined,
        carers: undefined,
    });

    useEffect(() => {
        getAssociations();
    }, [props]);

    const getAssociations = () => {
        let clinicianOptions;
        let clinicianModel: any[] = [];

        let carerOptions;
        let carerModel: any[] = [];

        let patientOptions;
        let patientModel: any[] = [];

        if (props.user) {
            if (props.user.clinicianss) {
                clinicianOptions = props.user.clinicianss.map((clinician: any) => {
                    clinicianModel.push(clinician.clinicians.id);
                    return { display: clinician.clinicians.email, value: clinician.clinicians.id };
                });
            }
            if (props.user.carerss) {
                carerOptions = props.user.carerss.map((carer: any) => {
                    carerModel.push(carer.carers.id);
                    return { display: carer.carers.email, value: carer.carers.id };
                });
            }
            if (props.user.userss) {
                patientOptions = props.user.userss.map((patient: any) => {
                    patientModel.push(patient.users.id);
                    return { display: patient.users.email, value: patient.users.id };
                });
            }
        }

        setState({
            ...state,
            associations: { clinicians: clinicianModel, patients: patientModel, carers: carerModel },
            clinicians: clinicianOptions,
            patients: patientOptions,
            carers: carerOptions,
        });
    };

    if (!props.user) {
        return <Spinner />;
    }

    return (
        <div className="profile-card">
            <div className="profile-header">ASSOCIATIONS</div>
            <div className="profile-body">
                <If condition={props.userGroup === "Patient"}>
                    <div className="profile-field">
                        <div className="profile-label">{getModelDisplayName(ClinicianEntity) + "s"}</div>
                        <MultiCombobox
                            label=""
                            labelVisible={false}
                            className="profile-input profile-multi-input"
                            model={state.associations ? state.associations : []}
                            modelProperty="clinicians"
                            options={state.clinicians ? state.clinicians : []}
                            placeholder={getModelDisplayName(ClinicianEntity) + "s"}
                            isDisabled={true}
                        />
                    </div>
                    <div className="profile-divider">
                        <hr />
                    </div>
                    <div className="profile-field">
                        <div className="profile-label">{getModelDisplayName(CarerEntity) + "s"}</div>
                        <MultiCombobox
                            label=""
                            labelVisible={false}
                            className="profile-input profile-multi-input"
                            model={state.associations ? state.associations : []}
                            modelProperty="carers"
                            options={state.carers ? state.carers : []}
                            placeholder={getModelDisplayName(CarerEntity) + "s"}
                            isDisabled={true}
                        />
                    </div>
                </If>

                <If condition={props.userGroup === "Carer" || props.userGroup === "Clinician"}>
                    <div className="profile-field">
                        <div className="profile-label">{getModelDisplayName(PatientEntity) + "s"}</div>
                        <MultiCombobox
                            label=""
                            labelVisible={false}
                            className="profile-input profile-multi-input"
                            model={state.associations ? state.associations : []}
                            modelProperty="patients"
                            options={state.patients ? state.patients : []}
                            placeholder="Patients"
                            isDisabled={true}
                        />
                    </div>
                </If>
            </div>
        </div>
    );
}
