import React from "react";
import YHPGradient from "../Gradient/YHPGradient";
import styles from "./../../../scss/components/checkInVideoBanner.module.scss";
import smartlookClient from "smartlook-client";

interface ICheckInVideoBannerProps {
	title: string;
	description: string;
	link: string;
}

function handelsupportLinkClick(props: ICheckInVideoBannerProps) {
	smartlookClient.track('Home_Support',{});
	window.open(props.link)
}

export default function CheckInVideoBanner(props: ICheckInVideoBannerProps) {
	return (
		<YHPGradient className={styles.videoBanner} onClick={()=> handelsupportLinkClick(props)}>
			<div className={styles.videoDescription}>
				<div className={styles.videoDescriptionTitle}>
					<div className={styles.videoDescriptionText}>{props.title}</div>
					<div className='icon-link-external icon-right' />
				</div>
				<div>{props.description}</div>
			</div>

			<img
				className={styles.videoImage}
				src={`${process.env.PUBLIC_URL}/Checkin/Clinician.svg`}
				alt=''
			/>
		</YHPGradient>
	);
}
