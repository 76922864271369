import React, { useState, useEffect } from "react";
import { PatientEntity, ClinicianEntity, CarerEntity } from "Models/Entities";
import { TextField } from "../TextBox/TextBox";
import Spinner from "../Spinner/Spinner";
import { DatePicker } from "../DatePicker/DatePicker";
import { Combobox } from "../Combobox/Combobox";
import PhoneInput, { formatPhoneNumberIntl, isValidPhoneNumber } from "react-phone-number-input";
import { Button, Colors, Display } from "../Button/Button";
import alert from "Util/ToastifyUtils";
import "react-phone-number-input/style.css";

interface ISummary {
	user: PatientEntity | ClinicianEntity | CarerEntity | undefined;
    userGroup: "Patient" | "Clinician" | "Carer" | string;
    refetch: () => void;
	onSave?: () => void;

}

interface ISummaryState {
    user: any | PatientEntity | ClinicianEntity | CarerEntity;
    phone?: string;
    renderButtons: boolean;
}

export default function Summary(props: ISummary) {
    const [state, setState] = useState<ISummaryState>({ user: props.user, phone: props.user?.phone, renderButtons: false });

    useEffect(() => {
        setState({ ...state, user: props.user, phone: props.user?.phone });
    }, [props]);

    const handleChange = () => {
        setState({ ...state, renderButtons: true });
    };

    const handlePhoneChange = (e: string) => {
        setState({ ...state, phone: e, renderButtons: true });
    };

    const handleCancel = () => {
        props.refetch();
		setState({ ...state, renderButtons: false });
    };

    const handleSave = () => {
        if (validateForm()) {
            switch (props.userGroup) {
                case "Patient":
                    let patient = new PatientEntity(state.user);
                    patient.phone = state.phone!;

                    if (patient.gender === undefined)
                        patient.gender = null;

                    patient
                        .save()
                        .then(() => handleSaveSuccess())
                        .catch(() => alert("Could not save information"));
                    break;
                case "Clinician":
                    let clinician = new ClinicianEntity(state.user);
                    clinician.phone = state.phone!;
                    clinician
                        .save()
                        .then(() => handleSaveSuccess())
                        .catch(() => alert("Could not save information"));
                    break;
                case "Clinician":
                    let carer = new CarerEntity(state.user);
                    carer.phone = state.phone!;
                    carer
                        .save()
                        .then(() => handleSaveSuccess())
                        .catch(() => alert("Could not save information"));
                    break;
            }
        }
    };

    const handleSaveSuccess = () => {
        alert("Information updated!", "success");
        setState({ ...state, renderButtons: false });
        if (props.onSave) {
            props.onSave();
        }
    };

    const validateForm = (): boolean => {
        let validate = true;
        if (state.user) {
            if (!state.user.forename) {
                validate = false;
                alert("Please enter a valid first name");
            }
            if (!state.user.surname) {
                validate = false;
                alert("Please enter a valid last name");
            }
            if (state.phone) {
                if (!isValidPhoneNumber(state.phone)) {
                    validate = false;
                    alert("Please enter a valid phone number");
                }
            }

            return validate;
        }
        return false;
    };

    if (!props.user) {
        return <Spinner />;
    }

    return (
        <div className="profile-card">
            <div className="profile-header">SUMMARY</div>
            <div className="profile-body">
                <div className="profile-field">
                    <div className="profile-label">First name</div>
                    <TextField
                        className="profile-input"
                        model={props.user}
                        modelProperty="forename"
                        placeholder="First name"
                        onAfterChange={handleChange}
                    />
                </div>

                <div className="profile-field">
                    <div className="profile-label">Middle name (Optional)</div>
                    <TextField
                        className="profile-input"
                        model={props.user}
                        modelProperty="middlename"
                        placeholder="Middle name"
                        onAfterChange={handleChange}
                    />
                </div>

                <div className="profile-field">
                    <div className="profile-label">Last name</div>
                    <TextField
                        className="profile-input"
                        model={props.user}
                        modelProperty="surname"
                        placeholder="Last name"
                        onAfterChange={handleChange}
                    />
                </div>

                <div className="profile-field">
                    <div className="profile-label">Date of birth (Optional)</div>
                    <DatePicker
                        className="profile-input-date"
                        model={props.user}
                        modelProperty="dateOfBirth"
                        placeholder="Date of birth"
                        onAfterChange={handleChange}
                    />
                </div>

                <div className="profile-field">
                    <div className="profile-label">Gender</div>
                    <Combobox
                        label=""
                        labelVisible={false}
                        className="profile-input"
                        model={props.user}
                        modelProperty="gender"
                        options={genderOptions}
                        placeholder="Gender"
                        isClearable
                        onAfterChange={handleChange}
                    />
                </div>

                <div className="profile-field">
                    <div className="profile-label">Email</div>
                    <TextField
                        className="profile-input"
                        model={props.user}
                        modelProperty="email"
                        placeholder="Email"
                        isReadOnly={true}
                        onAfterChange={handleChange}
                    />
                </div>

                <div className="profile-field">
                    <div className="profile-label">Phone</div>
                    <PhoneInput
                        className="profile-input-phone"
                        value={state.phone ? state.phone : ""}
                        onChange={(e) => handlePhoneChange(e)}
                        international
                    />
                </div>

                <div className="profile-field">
                    <div className="profile-label">Password</div>
                    <TextField className="profile-input" model={password} modelProperty="password" placeholder="Password" isReadOnly={true} />
                </div>
                <div className="profile-field">
                    <div className="profile-label"/>
                    <div className="profile-reset-password" onClick={() => window.open("/reset-password-request", "_self")}>
                        Reset Password
                    </div>
                </div>

                {state.renderButtons && (
                    <div className="profile-field">
                        <div className="profile-label"></div>
                        <div className="profile-save-cancel">
                            <div className="cancel" onClick={handleCancel}>
                                Cancel
                            </div>
                            <Button className="save" display={Display.Solid} colors={Colors.Primary} onClick={handleSave}>
                                Save
                            </Button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

const password = {
    password: "**************",
};

const genderOptions = [
    { display: "Male", value: "MALE" },
    { display: "Female", value: "FEMALE" },
    { display: "Other", value: "OTHER" },
];
