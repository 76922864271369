import { RatingpersonalEntity, RatingtemplateEntity } from "Models/Entities";
import { store } from "Models/Store";
import React, { useEffect, useState } from "react";
import HeaderUpdate from "../Header/HeaderUpdate";
import GetModel from "../../../Views/Components/GetModel/GetModel";
import { RatingEntityTile } from "Forms/RatingEntityTile";
import moment from "moment";
import { useLocation } from "react-router";
import { gql } from "apollo-boost";
import { v4 } from "uuid";
import FormBanner from "./FormBanner";
import smartlookClient from "smartlook-client";

interface ICheckInFormProps {
	formId: string;
	dueDate: string;
}

export default function CheckInFormTile(props: ICheckInFormProps) {
	const [type, form] = useForm(props.formId);

	return (
		<div className="checkin-form-page">
			<HeaderUpdate title="Form" displayBack={true} backClick={() => store.routerHistory.push("/check-in")} />
			<FormBanner form={form} />
			<FormContent formId={props.formId} form={form} dueDate={props.dueDate} type={type} />
		</div>
	);
}

interface IFormContentProps {
	formId: string;
	form: IForm;
	dueDate: string;
	type: "RatingtemplateEntity" | "RatingpersonalEntity";
}

export interface IForm {
	name: string;
	description: string;
	bannerId: string;
	frequency: string;
}

function FormContent(props: IFormContentProps) {
	const location: any = useLocation();
	if (props.form && props.type) {
		// Check if we should render the next form button
		let forms: any[] = [];
		let formIndex: number = 0;
		if (location.state) {
			if (location.state.forms.length > 1) {
				forms = location.state.forms;
				formIndex = location.state.incompleteFormIndex;
			}
		}

		const { name, description } = props.form;

		return (
			<div className="container">
				<div className="content">
					<div className="information">
						<div className="title">{name}</div>
						<div className="description">{description}</div>
						<div className="date">{getDueString()}</div>
					</div>
					<FormQuestionaire formId={props.formId} dueDate={props.dueDate} type={props.type} forms={forms} formIndex={formIndex} />
				</div>
			</div>
		);
	}
	return <></>;

	function getDueString() {
		const { frequency } = props.form;
		const { dueDate } = props;


		if (frequency == "ONEOFF") {
			return;
		} else if (frequency == "DAILY" || frequency == "WEEKLY" || frequency == "FORTNIGHTLY") {
			return "Submitting for " +moment.utc(dueDate).local().format("ddd Do MMMM");
		} else {
			return "Submitting for " + moment.utc(dueDate).local().format("MMMM");
		}
	}
}

interface IQuestionaireProps {
	formId: string;
	dueDate: string;
	forms?: any[];
	formIndex?: number;
	type?: "RatingtemplateEntity" | "RatingpersonalEntity";
}

function handleOnAfterFinish(){
	smartlookClient.track('Home_Checkin_Submit', {});
	store.routerHistory.push("/check-in", { reFetch: true })
}

function FormQuestionaire(props: IQuestionaireProps) {
	const { type, formId } = props;
	const submissionDate = getSubmissionDate(props.dueDate);
	const formIndex = props.formIndex ? props.formIndex : 0;
	const forms = props.forms ? props.forms : [];

	let onNext: (() => void) | undefined = undefined;
	if (forms.length > 0) {
		smartlookClient.track('Home_Checkin_Submit', {});
		onNext = () => nextForm();
	}

	if (type == "RatingtemplateEntity") {
		return (
			<GetModel key={v4()} modelType={RatingtemplateEntity} id={formId}>
				{(model: RatingtemplateEntity) => (
					<RatingEntityTile
						key={model.id}
						model={model}
						submissionDate={submissionDate}
						submitText="Submit & next form"
						onAfterFinish={() => handleOnAfterFinish()}
						onAfterSubmit={onNext}
					/>
				)}
			</GetModel>
		);
	} else if (type == "RatingpersonalEntity") {
		return (
			<GetModel key={v4()} modelType={RatingpersonalEntity} id={formId}>
				{(model: RatingpersonalEntity) => (
					<RatingEntityTile
						key={model.id}
						model={model}
						submissionDate={submissionDate}
						submitText="Submit & next form"
						onAfterFinish={() => handleOnAfterFinish()}
						onAfterSubmit={onNext}
					/>
				)}
			</GetModel>
		);
	}
	return <></>;

	function nextForm() {
		if (forms.length > 1) {
			forms.splice(formIndex, 1);

			let route = `/check-in/${getSubmissionDate(forms[0].missingDates[0]).format("YYYY-MM-DD")}/${forms[0].id}`;
			store.routerHistory.push(route, {
				forms: forms,
				incompleteFormIndex: 0,
			});
		} else {
			store.routerHistory.push("/check-in", { reFetch: true });
		}
	}

	function getSubmissionDate(dueDate: string) {
		return  moment.utc(dueDate);
	}
}

export function useForm(formId: string) {
	const [form, setForm] = useState<any>(undefined);
	const [type, setType] = useState<"RatingtemplateEntity" | "RatingpersonalEntity" | undefined>(undefined);
	const location: any = useLocation();

	useEffect(() => {
		getForm();
	}, [getForm, location]);

	async function getForm() {
		let response: any = await store.apolloClient.query({ query: queryForm(formId) });
		if (response.data.ratingtemplateEntitys.length > 0) {
			setForm(response.data.ratingtemplateEntitys[0]);
			setType("RatingtemplateEntity");
		} else if (response.data.ratingpersonalEntitys.length > 0) {
			setForm(response.data.ratingpersonalEntitys[0]);
			setType("RatingpersonalEntity");
		}
	}

	return [type, form];
}

function queryForm(formId: string) {
	return gql`
		query rating {
			ratingtemplateEntitys(id: "${formId}") {
				id
				name
				description
				bannerId
				frequency
			}
			ratingpersonalEntitys(id: "${formId}") {
				id
				name
				description
				bannerId
				frequency
			}
		}
	`;
}
