import React, { Component } from "react";
import { observer } from "mobx-react";
import { GoalsWizardProgress } from "./GoalsWizardProgress";
import If from "Views/Components/If/If";

interface IGoalWizardHeader {
    displayBack: boolean;
    onClickBack?: () => void;
    currentSlideIndex: number;
    totalNumberOfSlides: number;

}
@observer
export default class GoalWizardHeader extends Component<IGoalWizardHeader> {


    render() {
        const onClickBack = this.props.onClickBack ? this.props.onClickBack : () => { };
        return (
            <div className="goal-header">
                <If condition={this.props.displayBack}>
                    <div className="icon-left icon-chevron-left" onClick={() => onClickBack()} />
                </If>

                <GoalsWizardProgress currentSlideIndex={this.props.currentSlideIndex} numberSlides={this.props.totalNumberOfSlides} ></GoalsWizardProgress>

            </div>
        );
    }
}

