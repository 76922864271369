import React, { Component, ReactNode } from "react";
import { observable, runInAction } from "mobx";
import { observer } from "mobx-react";
import _ from "lodash";
import classNames from "classnames";
import { Form, Question } from "./../../../Forms/Schema/Question";
import { SlideTile } from "./../../../Forms/SlideTile";
import { IFormProps } from "Forms/FormTile";

interface IFormTileProps<T> extends IFormProps<T> {
	schema: Form;
	model: T;
}

@observer
export class FormAdminPreview<T> extends Component<IFormTileProps<T>> {
	public render(): ReactNode {
		const { className, schema, isReadOnly, disableShowConditions, model } = this.props;

		return (
			<div className="form-admin-preview">
				<div className="banner">
					<img src={`${process.env.PUBLIC_URL}/Checkin/Banner.png`} />
					<div className="information">
						<div className="title">{model["name"]}</div>
						<div className="description">{model["description"]}</div>
					</div>
				</div>
				<div className="information information-padder">
					<div className="title">{model["name"]}</div>
					<div className="description">{model["description"]}</div>
				</div>
				<div className={classNames("forms-tile", className)}>
					{schema.map(
						(slide, i): ReactNode => {
							const key = `${slide.name}-${i}`;

							return (
								<SlideTile
									key={key}
									model={model}
									schema={schema}
									isReadOnly={isReadOnly}
									disableShowConditions={disableShowConditions}
									contents={slide.contents}
									name={slide.name}
								/>
							);
						}
					)}
				</div>
			</div>
		);
	}
}
