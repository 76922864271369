import * as React from 'react';
import { observer } from 'mobx-react';
import { RouteComponentProps } from 'react-router';
import Header from 'Views/Components/Header/Header';
import { observable, action } from 'mobx';
import queryString from 'query-string';
import { Link } from 'react-router-dom';
import { PREFIX, SERVER_URL } from 'Constants';
import { Button, Display } from 'Views/Components/Button/Button';
import { gql } from 'apollo-boost';
import { store } from 'Models/Store';
import If from 'Views/Components/If/If';
import * as Models from 'Models/Entities';
import { FormEntityTile } from 'Forms/FormEntityTile';
import alert from 'Util/ToastifyUtils';
import { ArticleActivityContent } from 'Views/Components/Articles/ArticleActivityContent';
import Collection from 'Views/Components/Collection/Collection';
import { EntityFormLayout } from 'Views/Components/EntityFormLayout/EntityFormLayout';
import { AttributeFormMode, EntityFormMode } from 'Views/Components/Helpers/Common';
import moment from "moment";
import smartlookClient from "smartlook-client";

interface Article {
	id: string;
	title: string;
	content: string;
	htmlcontent: string;
}

interface Activity {
	id: string;
	name: string;
	description: string;
	featureId: string;
	articles?: any;
}

interface PreviousSubmission {
	id: string;
	date: Date;
	created: any;
	modified: any;
	submissionData: any;
	formVersionId: string;
	formVersion: any;
}

// % protected region % [Add any custom interface here] end

@observer
// % protected region % [Add any customisations to default class definition here] off begin
export default class ActivityTile extends React.Component<RouteComponentProps> {
// % protected region % [Add any customisations to default class definition here] end
	// % protected region % [Add class properties here] on begin
	@observable
	private content: JSX.Element;

	@observable
	private previouSubmissions: JSX.Element;

	@observable
	private previousEntries: PreviousSubmission[];

	@observable
	private previousEntry: PreviousSubmission;

	@observable
	private article: Article = {
		id: '',
		title: '',
		content: '',
		htmlcontent: '',
	};

	@observable
	private activity: Activity = {
		id: '',
		name: '',
		description: '',
		featureId: '',
	};

	// % protected region % [Add class properties here] end

	public render() {
		let contents = null;

		// % protected region % [Override contents here] on begin
		contents = <>{this.content}</>;

		// % protected region % [Override contents here] end

		return contents;
	}

	// % protected region % [Add class methods here] on begin
	componentDidMount() {
		this.getPreviousEntries();
	}

	async getPreviousEntries() {
		let query: any = queryString.parse(this.props.location.search);
		let formId: any = query.id;
		let userId: any = store.userId;


		await store.apolloClient
			.query({
				query: queryPreviousEntries(userId, formId),
			})
			.then((d) => {
				this.renderPreviousEntries(d.data)
			})
			.then(() => this.getActivity())
			.catch(() => alert('Could not load activity'));
	}

	@action
	renderPreviousEntries(entries: any) {
		if (entries.activitiesEntity.formVersions[0].formSubmissions.length > 0) {
			this.previousEntries = entries.activitiesEntity.formVersions[0].formSubmissions.map((entry: any) => {
				return {
					id: entry.id,
					date: moment.utc(entry.created).local().format("ddd Do MMMM YYYY"),
					submissionData: entry.submissionData,
					created: entry.created,
					modified: entry.modified,
					formVersionId: entry.formVersionId,
					formVersion: entry.formVersion,
				};
			});
			this.previouSubmissions = (
				<div className="previous-entries">
					<h4>Previous Entries</h4>
					<Collection
						collection={this.previousEntries}
						headers={[{ displayName: 'Date Completed', name: 'date' }]}
						actions={[
							{
								action: (model) => {
									this.previousEntry = model;
									this.renderPreviousSubmission();
									store.routerHistory.push(`/activity?id=${this.activity.id}&previd=${model.id}`);
								},
								label: 'View',
								showIcon: true,
								icon: 'look',
								iconPos: 'icon-top',
							},
						]}
					/>
				</div>
			);
		}
	}

	// Get the activity and it's article
	async getActivity() {
		let query: any = queryString.parse(this.props.location.search);

		await store.apolloClient
			.query({
				query: getActivityExpands(),
				variables: {
					args: [{ path: 'id', comparison: 'equal', value: query.id }],
				},
			})
			.then((d) => {
				this.setActivity(d.data.activitiesEntity);
			})
			.catch(() => alert('Could Not Load Activity'));
	}

	@action
	setActivity(activity: any) {
		this.activity = activity;

		if (activity.articles) {
			this.article = activity.articles;
		}

		let query: any = queryString.parse(this.props.location.search);
		if (query.article) {
			this.renderArticle();
		} else if (query.previd) {
			this.renderPreviousSubmission();
		} else {
			this.renderActivity();
		}
	}

	@action
	renderActivity() {
		const { id, name, description, featureId } = this.activity;
		const { pathname, search } = this.props.location;
		const returnPath: any = this.props.location.state ? this.props.location.state : "/activities";

		this.content = (
			<>
				<Header title={name} subtitle="Home / Activities /" />

				<div className="activity-landing">
					<Link to={returnPath} className="icon-arrow-left icon-left">
						Back
					</Link>
					<div className="activity-landing-container">
						<div className="activity-landing-text">
							<If condition={this.article.id != ''}>
								<p>
									We recommend you read the{' '}
									<span onClick={() => this.renderArticle()}>
										<Link to={pathname + search + '&article=true'}>{this.article.title}</Link>
									</span>{' '}
									library article before continuing on in this activity
								</p>
							</If>

							<p>{description}</p>
							<br />

							<Button
								className="activity-btn-start"
								onClick={() => this.startActivity(id, name)}
								display={Display.Solid}
								icon={{ icon: 'plus', iconPos: 'icon-right' }}>
								Start New
							</Button>
						</div>

						<div className="activity-image-cropper">
							<img src={`${SERVER_URL}/api/files/${featureId}`} />
						</div>
					</div>

					{this.previouSubmissions}
				</div>
			</>
		);
	}

	@action
	renderArticle() {
		const { history, location, match, staticContext } = this.props;
		const { id, name } = this.activity;
		
		this.content = (
			<>
				<div className="library-header">
					<div>
						<h6>Library</h6>
						<h2>{this.article.title}</h2>
					</div>
				</div>

				<div className="back-fav">
					<Link to={'/activity?id=' + id} className="icon-arrow-left icon-left"
					      onClick={() => this.renderActivity()}>
						Back
					</Link>
				</div>

				<ArticleActivityContent article={this.article} match={match} location={location} history={history}
				                        staticContext={staticContext} />

				<hr className="dotted-hr" />

				<div className="previous-next">
					<div
						className="previous-article"
						onClick={() => {
							store.routerHistory.push('/activity?id=' + id);
							this.renderActivity();
						}}>
						<Button display={Display.Solid} icon={{ icon: 'arrow-left', iconPos: 'icon-left' }} />
						<div className="previous-text">
							<p>Back</p>
							<b>To Activities Dashboard</b>
						</div>
					</div>

					<div className="next-article" onClick={() => this.startActivity(id, name)}>
						<div className="next-text">
							<p>Start</p>
							<b>Begin Activity</b>
						</div>
						<Button display={Display.Solid} icon={{ icon: 'arrow-right', iconPos: 'icon-left' }} />
					</div>
				</div>

				<hr className="dotted-hr" />

			</>
		);
	}

	@action
	startActivity(id: string, name: string) {
		smartlookClient.track('Activities_Start_Activity', {});
		store.routerHistory.push('/activity?id=' + id);

		let form = new Models.ActivitiesEntity({ id: id });
		form.getPublishedVersion();
		let content = (
			<>
				<Header title={name} subtitle="Home / Activities /"/>

				<div className="activity-form">
					<FormEntityTile model={form} submitText={'Finish'}
									onAfterSubmit={() => {
										smartlookClient.track('Activities_Complete_Activity', {})
										window.location.reload()
									}}/>
				</div>
			</>
		);

		this.content = content;
	}

	@action
	renderPreviousSubmission() {
		let entry = new Models.ActivitiesSubmissionEntity(this.previousEntry);

		this.content = (
			<>
				<Header title={this.activity.name} subtitle="Home / Activities / Previous Submissions" />
				<div className="back-fav">
					<Link to={`/activity?id=${this.activity.id}`} className="icon-arrow-left icon-left"
					      onClick={() => this.renderActivity()}>
						Back to activities
					</Link>
				</div>
				<div className="previous-activity-submission">
					<EntityFormLayout
						model={entry}
						formMode={EntityFormMode.VIEW}
						attributeBehaviours={[
							{ name: "created", behaviour: AttributeFormMode.HIDE },
							{ name: "modified", behaviour: AttributeFormMode.HIDE },
						]}
					/>
				</div>
			</>
		);
	}
}

function getActivityExpands() {
	return gql`
		query activity($args: [WhereExpressionGraph]) {
			activitiesEntity(where: $args) {
				id
				name
				description
				featureId
				articles {
					id
					title
					content
				}
			}
		}
	`;
}

function queryPreviousEntries(userId: string, formId: string) {
	return gql`
		query previousEntries {
			activitiesEntity(where: { path: "id", comparison: equal, value: "${formId}" }) {
				id
				name
				formVersions(orderBy: {path: "version", descending: true}){
					version
					formSubmissions(where: { path: "owner", comparison: equal, value: "${userId}" }, orderBy: {path: "created", descending: true}, take: 6) {
						id
						owner
						formVersionId
						submissionData   
						created
						modified
						formVersion {
							form {
								id
								name
							}
							formData
						}
					}
				}
			}
		}
	`;
}
