/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import { Model, IModelAttributes, attribute, entity } from 'Models/Model';
import * as Models from 'Models/Entities';
import { IAcl } from '../Security/IAcl';
import { observable } from 'mobx';
import { VisitorsPatientsReferenceManyToMany } from '../Security/Acl/VisitorsPatientsReferenceManyToMany';
import { PatientPatientsReferenceManyToMany } from '../Security/Acl/PatientPatientsReferenceManyToMany';
import { ClinicianPatientsReferenceManyToMany } from '../Security/Acl/ClinicianPatientsReferenceManyToMany';
import { CarerPatientsReferenceManyToMany } from '../Security/Acl/CarerPatientsReferenceManyToMany';
import { AdminPatientsReferenceManyToMany } from '../Security/Acl/AdminPatientsReferenceManyToMany';

// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface IRatingtemplatesPatientsAttributes extends IModelAttributes {
	ratingtemplatesId: string;
	patientsId: string;

	ratingtemplates: Models.RatingtemplateEntity | Models.IRatingtemplateEntityAttributes;
	patients: Models.PatientEntity | Models.IPatientEntityAttributes;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

@entity('RatingtemplatesPatients')
export default class RatingtemplatesPatients extends Model implements IRatingtemplatesPatientsAttributes {
	public static acls: IAcl[] = [
		new VisitorsPatientsReferenceManyToMany(),
		new PatientPatientsReferenceManyToMany(),
		new ClinicianPatientsReferenceManyToMany(),
		new CarerPatientsReferenceManyToMany(),
		new AdminPatientsReferenceManyToMany(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	@observable
	@attribute()
	public ratingtemplatesId: string;

	@observable
	@attribute()
	public patientsId: string;

	@observable
	@attribute({isReference: true})
	public ratingtemplates: Models.RatingtemplateEntity;

	@observable
	@attribute({isReference: true})
	public patients: Models.PatientEntity;
	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	constructor(attributes?: Partial<IRatingtemplatesPatientsAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		if (attributes) {
			if (attributes.ratingtemplatesId) {
				this.ratingtemplatesId = attributes.ratingtemplatesId;
			}
			if (attributes.patientsId) {
				this.patientsId = attributes.patientsId;
			}

			if (attributes.ratingtemplates) {
				if (attributes.ratingtemplates instanceof Models.RatingtemplateEntity) {
					this.ratingtemplates = attributes.ratingtemplates;
					this.ratingtemplatesId = attributes.ratingtemplates.id;
				} else {
					this.ratingtemplates = new Models.RatingtemplateEntity(attributes.ratingtemplates);
					this.ratingtemplatesId = this.ratingtemplates.id;
				}
			} else if (attributes.ratingtemplatesId !== undefined) {
				this.ratingtemplatesId = attributes.ratingtemplatesId;
			}

			if (attributes.patients) {
				if (attributes.patients instanceof Models.PatientEntity) {
					this.patients = attributes.patients;
					this.patientsId = attributes.patients.id;
				} else {
					this.patients = new Models.PatientEntity(attributes.patients);
					this.patientsId = this.patients.id;
				}
			} else if (attributes.patientsId !== undefined) {
				this.patientsId = attributes.patientsId;
			}
		}

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}