/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { RouteComponentProps } from 'react-router';
import * as Models from 'Models/Entities';
import EntityCRUD from 'Views/Components/CRUD/EntityCRUD';
import { PageWrapper } from 'Views/Components/PageWrapper/PageWrapper';
import SecuredPage from 'Views/Components/Security/SecuredPage';
// % protected region % [Add any extra imports here] on begin
import { Switch, Route } from "react-router";
import OnboardingSlideWrappingTileTile from "Views/Tiles/OnboardingSlideWrappingTileTile";
// % protected region % [Add any extra imports here] end

@observer
export default class OnboardingSlideEntityPage extends React.Component<RouteComponentProps> {
	// % protected region % [Add any extra attributes here] on begin
	// % protected region % [Add any extra attributes here] end

	public render() {
		let contents = null;

		// % protected region % [Override contents here] on begin
		const { history, location, match, staticContext } = this.props;

		const onboardingBuildPage = (pageProps: RouteComponentProps) => {
			return (
				<div className="body-content">
					<OnboardingSlideWrappingTileTile history={history} location={location} match={match} staticContext={staticContext} />
				</div>
			);
		};

		const onboardingCrud = (pageProps: RouteComponentProps) => {
			return (
				<PageWrapper {...this.props}>
					<EntityCRUD modelType={Models.OnboardingSlideEntity} {...this.props} />
				</PageWrapper>
			);
		};

		console.log(match.url);

		return (
			<SecuredPage groups={["Admin", "Carer", "Clinician", "Patient"]}>
				<div>
					<Switch>
						<Route path={`${match.url}/create`} render={onboardingBuildPage} />
						<Route path={`${match.url}`} render={onboardingCrud} />
					</Switch>
				</div>
			</SecuredPage>
		);
		// % protected region % [Override contents here] end

		return (
			<SecuredPage groups={["Super Administrators", "Patient","Clinician","Carer","Admin"]}>
				{contents}
			</SecuredPage>
		);
	}

	// % protected region % [Add any extra functions here] on begin
	// % protected region % [Add any extra functions here] end
}
