import { IconButton, Popper, TextField } from "@material-ui/core";
import { InfoOutlined } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import { 
	CarerEntity, 
	ClinicianEntity, 
	PatientEntity, 
	RatingpersonalEntity, 
	RatingtemplateEntity, 
	RatingtemplatesPatients, 
	UsersRatings 
} from "Models/Entities";
import { store } from "Models/Store";
import moment from "moment";
import React, { useEffect } from "react";
import { useState } from "react";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import Popup from "reactjs-popup";
import { getFetchAllQuery, getModelDisplayName } from "Util/EntityUtils";
import { DatePicker } from "Views/Components/DatePicker/DatePicker";
import HeaderUpdate from "Views/Components/Header/HeaderUpdate";
import "react-phone-number-input/style.css";
import Spinner from "Views/Components/Spinner/Spinner";
import { Button, Display } from "Views/Components/Button/Button";
import alert from "Util/ToastifyUtils";
import { EntityFormMode } from "Views/Components/Helpers/Common";
import { v4 } from "uuid";
import { gql } from "apollo-boost";
import { PatientExpands } from '../../Services/PatientService/PatientEntityExpands';
import PatientService from '../../Services/PatientService/PatientService';

interface IPatientState {
	firstname: string;
	lastname: string;
	dob?: Date;
	email: string;
	phone: string;
	clinicians: any;
	carers: any;
	ratingtemplates: any;
	ratingpersonals: any;
}

interface IPatientErrorState {
	firstname: boolean;
	lastname: boolean;
	dob: boolean;
	email: boolean;
	phone: boolean;
}

export default function PatientCreateTile() {
	const [state, setState] = useState<IPatientState>({
		firstname: "",
		lastname: "",
		dob: undefined,
		email: "",
		phone: "",
		clinicians: undefined,
		carers: undefined,
		ratingtemplates: undefined,
		ratingpersonals: undefined,
	});

	const [error, setError] = useState<IPatientErrorState>({ firstname: false, lastname: false, dob: false, email: false, phone: false });

	const [clinicianOptions, setClinicianOptions] = useState<any[]>([]);
	const [carerOptions, setCarerOptions] = useState<any[]>([]);
	const [ratingTemplateOptions, setRatingTemplateOptions] = useState<any[]>([]);
	const [ratingPersonalOptions, setRatingPersonalOptions] = useState<any[]>([]);

	let defaultValue = undefined;

	useEffect(() => {
		getClinicianOptions();
		getCarerOptions();
		getRatingTemplateOptions();
		getRatingPersonalOptions();
	}, []);

	const getClinicianOptions = () => {
		store.apolloClient.query({ query: queryClinicians() }).then((d) => {
			d.data.clinicianEntitys.find((clinician: any) => {
				if (clinician.id == store.userId) {
					setState({ ...state, clinicians: [clinician] });
				}
			});
			setClinicianOptions(d.data.clinicianEntitys);
		});
	};

	const getCarerOptions = () => {
		store.apolloClient.query({ query: queryCarers() }).then((d) => setCarerOptions(d.data.carerEntitys));
	};

	const getRatingTemplateOptions = () => {
		store.apolloClient.query({ query: queryRatingTemp() }).then((d) => setRatingTemplateOptions(d.data.ratingtemplateEntitys));
	};

	const getRatingPersonalOptions = () => {
		store.apolloClient.query({ query: queryRatingPers() }).then((d) => setRatingPersonalOptions(d.data.ratingpersonalEntitys));
	};

	if (clinicianOptions.length == 0) {
		return <Spinner />;
	}

	clinicianOptions.find((clinician, index) => {
		if (clinician.id == store.userId) {
			defaultValue = index;
		}
	});

	return (
		<div className="patient-create-page">
			<HeaderUpdate
				title={`Create a new ${getModelDisplayName(PatientEntity)}`}
				displayBack={true}
				backClick={() => store.routerHistory.push("/patient")}
			/>
			<div className="content">
				<div className="left">
					<div className="summary">
						<div className="title-container">
							<span className="title">Summary</span>
						</div>
						<div className="input-container">
							<div className="label">First name <span className="required">*</span></div>
							<TextField
								className="input-field"
								placeholder="First name"
								variant="outlined"
								fullWidth
								onChange={(e) => {
									setState({ ...state, firstname: e.target.value });
									setError({ ...error, firstname: false });
								}}
								error={error.firstname}
								helperText={error.firstname ? "Please enter a valid first name" : ""}
							/>
						</div>
						<div className="input-container">
							<div className="label">Last name <span className="required">*</span></div>
							<TextField
								className="input-field"
								placeholder="Last name"
								variant="outlined"
								fullWidth
								onChange={(e) => {
									setState({ ...state, lastname: e.target.value });
									setError({ ...error, lastname: false });
								}}
								error={error.lastname}
								helperText={error.lastname ? "Please enter a valid last name" : ""}
							/>
						</div>
						<div className="input-container">
							<div className="label">Date of Birth</div>
							<DatePicker
								className="date-field"
								model={state}
								modelProperty="dob"
								onAfterChange={(dates, currentDate) => {
									setError({ ...error, dob: false });
									setState({ ...state, dob: moment(currentDate).toDate() });
								}}
								placeholder="Date of birth"
							/>
							{error.dob ? <div className="error-text">Please enter a valid date of birth</div> : <></>}
						</div>
						<div className="input-container">
							<div className="label">Email <span className="required">*</span></div>
							<TextField
								className="input-field"
								placeholder="Email"
								variant="outlined"
								fullWidth
								onChange={(e) => {
									setError({ ...error, email: false });
									setState({ ...state, email: e.target.value });
								}}
								error={error.email}
								helperText={error.email ? "Please enter a valid email" : ""}
							/>
						</div>

						<div className="input-container">
							<div className="label">Phone</div>
							<Popup
								className="tooltip-popup"
								position="left top"
								trigger={
									<IconButton className="tooltip">
										<InfoOutlined style={{ color: "#989898" }} />
									</IconButton>
								}
								contentStyle={{ padding: "1rem", background: "#dddddd" }}>
								<div className="tooltip-content">This is optional</div>
							</Popup>
							<PhoneInput
								className="profile-input-phone"
								value={state.phone}
								onChange={(e) => {
									setError({ ...error, phone: false });
									setState({ ...state, phone: e });
								}}
								international
							/>
							{error.phone ? <div className="error-text">Invalid phone number entered</div> : <></>}
						</div>
					</div>
				</div>
				<div className="right">
					<div className="associations">
						<div className="title-container">
							<span className="title">Associations</span>
							<span className="optional"> (Optional)</span>
						</div>

						<div className="input-container">
							<div className="label">{getModelDisplayName(ClinicianEntity)}</div>
							<Popup
								className="tooltip-popup"
								position="left top"
								trigger={
									<IconButton className="tooltip">
										<InfoOutlined style={{ color: "#989898" }} />
									</IconButton>
								}
								contentStyle={{ padding: "1rem", background: "#dddddd" }}>
								<div className="tooltip-content">
									By default, the {getModelDisplayName(ClinicianEntity)} who creates the new user will be assigned.
								</div>
							</Popup>

							<Autocomplete
								className="multi-select"
								multiple
								disableCloseOnSelect
								options={clinicianOptions}
								defaultValue={defaultValue !== undefined ? [clinicianOptions[defaultValue]] : undefined}
								getOptionLabel={(option) => option.email}
								onChange={(event: any, newValue: any) => {
									setState({ ...state, clinicians: newValue });
								}}
								renderInput={(params) => (
									<TextField {...params} placeholder={getModelDisplayName(ClinicianEntity)} variant="outlined" fullWidth />
								)}
							/>
						</div>
						<div className="input-container">
							<div className="label">{getModelDisplayName(CarerEntity)}</div>
							<Autocomplete
								className="multi-select"
								multiple
								disableCloseOnSelect
								options={carerOptions}
								getOptionLabel={(option) => option.email}
								onChange={(event: any, newValue: any) => {
									setState({ ...state, carers: newValue });
								}}
								renderInput={(params) => (
									<TextField {...params} placeholder={getModelDisplayName(CarerEntity)} variant="outlined" fullWidth />
								)}
							/>
						</div>
					</div>
					<div className="checkin">
						<div className="title-container">
							<span className="title">Check In</span>
							<span className="optional"> (Optional)</span>
						</div>
						<div className="input-container">
							<div className="label">Assign template ratings</div>
							<Popup
								className="tooltip-popup"
								position="left top"
								trigger={
									<IconButton className="tooltip">
										<InfoOutlined style={{ color: "#989898" }} />
									</IconButton>
								}
								contentStyle={{ padding: "1rem", background: "#dddddd" }}>
								<div className="tooltip-content">Template ratings are used by the entire organisation.</div>
							</Popup>

							<Autocomplete
								className="multi-select"
								multiple
								disableCloseOnSelect
								options={ratingTemplateOptions}
								getOptionLabel={(option) => option.name}
								onChange={(event: any, newValue: any) => {
									setState({ ...state, ratingtemplates: newValue });
								}}
								renderInput={(params) => <TextField {...params} placeholder="Select templates" variant="outlined" fullWidth />}
							/>
						</div>
						<div className="input-container">
							<div className="label">Assign personal ratings</div>
							<Popup
								className="tooltip-popup"
								position="left top"
								trigger={
									<IconButton className="tooltip">
										<InfoOutlined style={{ color: "#989898" }} />
									</IconButton>
								}
								contentStyle={{ padding: "1rem", background: "#dddddd" }}>
								<div className="tooltip-content">Personal ratings can be used for specific use-cases across users.</div>
							</Popup>

							<Autocomplete
								className="multi-select"
								multiple
								disableCloseOnSelect
								options={ratingPersonalOptions}
								getOptionLabel={(option) => option.name}
								onChange={(event: any, newValue: any) => {
									setState({ ...state, ratingpersonals: newValue });
								}}
								renderInput={(params) => <TextField {...params} placeholder="Select templates" variant="outlined" fullWidth />}
							/>
						</div>
					</div>
					<div className="buttons">
						<div className="cancel" onClick={() => store.routerHistory.push("/patient")}>
							Cancel
						</div>
						<Button className="save" display={Display.Solid} onClick={() => createPatient()}>
							Save
						</Button>
					</div>
				</div>
			</div>
		</div>
	);

	function createPatient() {
		if (validatePatient()) {
			const password = v4();
			
			const clinicianss = state.clinicians?.map((clinician: ClinicianEntity) => {
				return { cliniciansId: clinician.id };
			});
			
			const carerss = state.carers?.map((carer: CarerEntity) => {
				return { carersId: carer.id };
			});

			let patient = new PatientEntity({
				forename: state.firstname,
				surname: state.lastname,
				email: state.email,
				phone: state.phone,
				dateOfBirth: state.dob,
				clinicianss,
				carerss
			});
			
			patient.password = password;
			patient._confirmPassword = password;

			// Creates new patient. The Aftersave method of patient will send emails and assign the correct forms
			patient.saveFromCrud(EntityFormMode.CREATE).then(() => {
				// Refetch patient for updated joins
				PatientService.getPatient(patient.id, PatientExpands.patientCreate).then((res) => {
					patient = new PatientEntity(res[0]);
					
					// Add rating templates
					state.ratingtemplates?.map((rating: RatingtemplateEntity) => {
						// Check if already exists
						const check = (element: RatingtemplatesPatients) => element.ratingtemplatesId === rating.id;
						
						if (!patient.ratingtemplatess.some(check)) {
							const newRatingTemplate = new RatingtemplatesPatients({
								patientsId: patient.id,
								ratingtemplatesId: rating.id,
							})

							newRatingTemplate.save();	
						}
					});

					state.ratingpersonals?.map((rating: RatingpersonalEntity) => {
						// Check if already exists
						const check = (element: UsersRatings) => element.ratingsId === rating.id;

						if (!patient.ratingss.some(check)) {
							let newPersonalRatingTemplate = new UsersRatings({
								usersId: patient.id,
								ratingsId: rating.id,
							})

							newPersonalRatingTemplate.save();
						}
					});
				});
			}).then(() => {
				alert("Patient created!", "success");
				store.routerHistory.push("/patient");
			}).catch((err) => {
				console.log(err);
				alert("Error creating patient", "error");
			});
		}
	}

	function validatePatient() {
		let _error = { firstname: false, lastname: false, dob: false, email: false, phone: false };

		let valid = true;

		if (state.firstname.length == 0) {
			valid = false;
			_error.firstname = true;
		}
		if (state.lastname.length == 0) {
			valid = false;
			_error.lastname = true;
		}
		if (state.email.length == 0 || !/[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?/
			.test(state.email) || /[ +]/.test(state.email)
			){
				valid = false;
				_error.email = true;
		}

		if (state.phone.length > 0) {
			console.log(isValidPhoneNumber(state.phone));
			if (!isValidPhoneNumber(state.phone)) {
				valid = false;
				_error.phone = true;
			}
		}

		setError(_error);
		return valid;
	}

	function queryClinicians() {
		return gql`
			query clinicians {
				clinicianEntitys {
					id
					email
				}
			}
		`;
	}

	function queryCarers() {
		return gql`
			query carers {
				carerEntitys {
					id
					email
				}
			}
		`;
	}

	function queryRatingTemp() {
		return gql`
			query ratingTemp {
				ratingtemplateEntitys {
					id
					name
				}
			}
		`;
	}

	function queryRatingPers() {
		return gql`
			query ratingPers {
				ratingpersonalEntitys {
					id
					name
				}
			}
		`;
	}
}
