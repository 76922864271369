import * as React from 'react';
import { observer } from 'mobx-react';
import { RouteComponentProps } from 'react-router';
import * as Models from 'Models/Entities';
import { action, observable } from 'mobx';
import Spinner from 'Views/Components/Spinner/Spinner';
import { ICollectionFilterPanelProps, IFilter } from 'Views/Components/Collection/CollectionFilterPanel';
import moment from 'moment';
import { Button, Display } from 'Views/Components/Button/Button';
import classNames from 'classnames';
import { getModelDisplayName } from 'Util/EntityUtils';
import { store } from 'Models/Store';
import Collection from 'Views/Components/Collection/Collection';
import { gql } from 'apollo-boost';
import _ from 'lodash';

interface collectionData {
	id: string;
	email: string;
	forename: any;
	surname: any;
	gender: any;
	dateofbirth: any;
	carers: associationContent[];
	clinicians: associationContent[];
}

interface associationFilters {
	clinicians: Array<{ display: string; value: string }>;
	carers: Array<{ display: string; value: string }>;
}

interface associationContent {
	id: string;
	name: string;
}

@observer
export default class PatientWrappingTileTile extends React.Component<RouteComponentProps> {
	constructor(props: RouteComponentProps) {
		super(props);
		this.filterConfig = {
			filters: this.getFilters(),
			onClearFilter: this.onClearFilter,
			onApplyFilter: this.onApplyFilter,
			onFilterChanged: this.onFilterChanged,
		};
	}

	@observable
	private collectionSearch = '';

	@observable
	private filterApplied: boolean = false;

	@observable
	private collectionData: collectionData[] = [];

	@observable
	private filterConfig: ICollectionFilterPanelProps<any>;

	@observable
	private associationFilters: associationFilters = {
		clinicians: [{ display: '', value: '' }],
		carers: [{ display: '', value: '' }],
	};

	public render() {
		const { result } = this.getSearchAndResult();
		let additionalActions: React.ReactNode[] = [];
		additionalActions.push(this.renderCreateButton());

		return this.collectionData ? (
			<Collection
				className="collection-patient-table"
				collection={this[result]}
				selectableItems={false}
				idColumn="email"
				headers={this.getHeaders()}
				actions={[
					{
						label: 'View',
						showIcon: true,
						icon: 'look',
						iconPos: 'icon-top',
						action: (model) => store.routerHistory.push(`/profile/${getModelDisplayName(Models.PatientEntity).toLowerCase()}/${model.id}`),
					},
				]}
				onSearchTriggered={(searchTerm) => this.onSearchTriggered(searchTerm)}
				menuFilterConfig={this.filterConfig}
				additionalActions={additionalActions}
			/>
		) : (
			<Spinner />
		);
	}

	// % protected region % [Add class methods here] on begin
	componentDidMount() {
		let userId = store.userId!;
		let userGroup = store.userGroups[0].name;
		this.getPatients(userGroup, userId);
		this.getAssociations();
	}

	getHeaders = () => {
		return [
			{ displayName: 'First Name', name: 'forename' },
			{ displayName: 'Surname', name: 'surname' },
			{ displayName: 'Email', name: 'email' },
			{ displayName: 'Gender', name: 'gender' },
			{ displayName: 'Date of Birth', name: 'dateofbirth' },
		]
	}

	// Get patients associated with current user logged in
	private async getPatients(userGroup: string, userId: string) {
		if (userGroup == 'Super Administrators' || userGroup === 'Admin') {
			store.apolloClient.query({ query: queryAllUsers(), fetchPolicy: 'network-only' }).then((d) => {
				this.renderPatientTable(d.data.patientEntitys, true);
			});
		} else {
			let entity = userGroup == 'Clinician' ? 'clinicianEntity' : 'carerEntity';

			store.apolloClient
				.query({ query: queryUsers(entity, userId), fetchPolicy: 'network-only' }).then((res) => {
				if (userGroup == 'Clinician') {
					this.renderPatientTable(res.data.clinicianEntity.userss, false);
				} else if (userGroup == 'Carer') {
					this.renderPatientTable(res.data.carerEntity.userss, false);
				}
			});
		}
	}

	// Gets the associations for the collection filters
	private async getAssociations() {
		store.apolloClient.query({ query: queryAssociations() }).then((d) => {
			this.setAssociationFilters(d.data);
		});
	}

	private getUserName(fistName?: string, lastName?: string) {
		let firstname = fistName ? fistName : 'No Name';
		let lastname = lastName ? lastName : '';
		return `${firstname} ${lastname}`;
	}

	@action
	setAssociationFilters(data: any) {
		this.associationFilters.clinicians = data.clinicianEntitys.map((clinician: any) => {
			return { display: this.getUserName(clinician.forename, clinician.surname), value: clinician.id };
		});

		this.associationFilters.carers = data.carerEntitys.map((carer: any) => {
			return { display: this.getUserName(carer.forename, carer.surname), value: carer.id };
		});

		this.filterConfig.filters = this.getFilters();
	}

	// Renders patient table given data returned by getPatients method
	@action
	private renderPatientTable = (data: any, admin: boolean) => {
		let collectionData: collectionData[] = [];

		// Populates collectionData variable
		data.forEach((entry: any) => {
			let carers: any[] = [];
			let clinicians: any[] = [];

			if (admin) {
				// Populate carer array with carer first name and last name
				entry.carerss.forEach((carer: any) => {
					carers.push({
						id: carer.carers.id,
						name: this.getUserName(carer.carers.forename, carer.carers.surname),
					});
				});

				// Populate clinician array with clinician first name and last name
				entry.clinicianss.forEach((clinician: any) => {
					clinicians.push({
						id: clinician.clinicians.id,
						name: this.getUserName(clinician.clinicians.forename, clinician.clinicians.surname),
					});
				});

				collectionData.push({
					id: entry.id,
					email: entry.email,
					forename: entry.forename,
					surname: entry.surname,
					gender: entry.gender,
					dateofbirth: entry.dateOfBirth ? moment(entry.dateOfBirth).format('D MMMM YYYY') : undefined,
					carers: carers,
					clinicians: clinicians,
				});
			} else {
				// Populate carer array with carer first name and last name
				entry.users.carerss.forEach((carer: any) => {
					carers.push({
						id: carer.carers.id,
						name: this.getUserName(carer.carers.forename, carer.carers.surname),
					});
				});

				// Populate clinician array with clinician first name and last name
				entry.users.clinicianss.forEach((clinician: any) => {
					clinicians.push({
						id: clinician.clinicians.id,
						name: this.getUserName(clinician.clinicians.forename, clinician.clinicians.surname),
					});
				});

				collectionData.push({
					id: entry.users.id,
					email: entry.users.email,
					forename: entry.users.forename,
					surname: entry.users.surname,
					gender: entry.users.gender,
					dateofbirth: entry.dateOfBirth ? moment(entry.dateOfBirth).format('D MMMM YYYY') : undefined,
					carers: carers,
					clinicians: clinicians,
				});
			}
		});
		// The actual bit that changed is this
		this.collectionData = _.sortBy(collectionData, 'surname', 'forename');
	};

	getSearchAndResult() {
		// Filter applied and search triggered
		if (this.filterApplied && this.collectionSearch !== '') {
			return { search: 'filteredCollectionData', result: 'filteredSearchedCollectionData' };
		}
		// Filter applied
		else if (this.filterApplied && this.collectionSearch === '') {
			return { search: 'collectionData', result: 'filteredCollectionData' };
		}
		// Search triggered
		else if (!this.filterApplied && this.collectionSearch !== '') {
			return { search: 'collectionData', result: 'filteredCollectionData' };
		}
		// Raw collection data
		else {
			return { search: 'collectionData', result: 'collectionData' };
		}
	}

	@action
	protected onSearchTriggered = (searchTerm: string) => {
		this.collectionSearch = searchTerm;
		searchTerm = searchTerm.trim().toLowerCase();

		const { search, result } = this.getSearchAndResult();

		this[result] = this[search].filter((model: any) => {
			// Returns true for each item in the collection that includes the search string
			return (
				Object.values(model).filter((value: any) => {
					if (value) {
						// If the item is an array (clinicians, carers), we have to search one level deeper
						if (Array.isArray(value)) {
							if (value.length > 0) {
								return (
									value.filter((association: any) => {
										return association.name.toLowerCase().includes(searchTerm);
									}).length > 0
								);
							}
						} else {
							return value.toLowerCase().includes(searchTerm);
						}
					}
				}).length > 0
			);
		});
	};

	// Gets the available filters for the collection
	protected getFilters = (): Array<IFilter<any>> => {
		let filters = new Array<IFilter<any>>();

		filters.push({
			path: 'clinicians',
			comparison: 'contains',
			active: false,
			value1: [],
			value2: undefined,
			displayType: 'enum-combobox',
			displayName: 'Clinicians',
			enumResolveFunction: this.associationFilters.clinicians,
		});

		filters.push({
			path: 'carers',
			comparison: 'contains',
			active: false,
			value1: [],
			value2: undefined,
			displayType: 'enum-combobox',
			displayName: 'Carers',
			enumResolveFunction: this.associationFilters.carers,
		});

		filters.push({
			path: 'gender',
			comparison: 'contains',
			active: false,
			value1: [],
			value2: undefined,
			displayType: 'enum-combobox',
			displayName: 'Gender',
			enumResolveFunction: [
				{ display: 'Male', value: 'MALE' },
				{ display: 'Female', value: 'FEMALE' },
				{ display: 'Other', value: 'OTHER' },
			],
		});

		filters.push({
			path: 'dob',
			comparison: 'range',
			value1: undefined,
			value2: undefined,
			active: false,
			displayType: 'datepicker',
			displayName: 'Date of Birth Range',
		} as IFilter<any>);

		return filters;
	};

	@action
	protected onClearFilter = () => {
		this.filterConfig.filters = this.getFilters();
		this.filterApplied = false;
		this.collectionSearch = '';
	};

	@action
	protected onApplyFilter = () => {
		this.filterApplied = true;

		const { search, result } = this.getSearchAndResult();

		this[result] = this[search].filter((model: any) => {
			return this.filterConfig.filters
				.map((filter: any) => {
					if (filter.active) {
						switch (filter.path) {
							case 'clinicians':
								return (
									model.clinicians.find((clinician: any) => {
										return (
											filter.value1.find((id: string) => {
												return clinician.id === id;
											}) !== undefined
										);
									}) !== undefined
								);
							case 'carers':
								return (
									model.carers.find((carer: any) => {
										return (
											filter.value1.find((id: string) => {
												return carer.id === id;
											}) !== undefined
										);
									}) !== undefined
								);
							case 'gender':
								return (
									filter.value1.find((value: string) => {
										return value === model.gender;
									}) !== undefined
								);
							case 'dob':
								if (model.dateofbirth) {
									return moment(model.dateofbirth).isBetween(filter.value1, filter.value2);
								} else {
									return false;
								}
						}
					}
					return;
				})
				.every((result: any) => {
					return result !== false;
				});
		});
	};

	@action
	protected onFilterChanged = () => {
	};

	private renderCreateButton() {
		return (
			<Button
				key="create"
				className={classNames(Display.Solid)}
				icon={{ icon: 'create', iconPos: 'icon-right' }}
				buttonProps={{
					onClick: () => {
						this.props.history.push(`${this.props.location.pathname}/create`);
					},
				}}
			>
				Create {getModelDisplayName(Models.PatientEntity)}
			</Button>
		);
	}

	// % protected region % [Add class methods here] end
}

// % protected region % [Add extra features here] on begin
function queryUsers(entity: string, userId: string) {
	return gql`
	query getPatients {
		${entity}(id: "${userId}"){
		id
		userss {
			users{
					id
					email
					forename
					surname
					gender
					dateOfBirth
					clinicianss {
						clinicians {
							id
							email
							forename
							surname
						}
					}
					carerss{
						carers {
							id
							email
							forename
							surname
						}
					}
				}
			}
		}
	}
`;
}

function queryAllUsers() {
	return gql`
        query patients {
            patientEntitys {
                id
                email
                forename
                surname
                gender
                dateOfBirth
                clinicianss {
                    clinicians {
                        id
                        email
                        forename
                        surname
                    }
                }
                carerss {
                    carers {
                        id
                        email
                        forename
                        surname
                    }
                }
            }
        }
    `;
}

function queryAssociations() {
	return gql`
        query Associations {
            clinicianEntitys {
                id
                forename
                surname
            }
            carerEntitys {
                id
                forename
                surname
            }
        }
    `;
}
