import React, { useEffect } from "react";

function useIncrementingNumber(delay: number | null) {
	const [count, setCount] = React.useState(0);

	const savedCallback = React.useRef(() => setCount((c) => c + 1));

	// Set up the interval.
	useEffect(() => {
		function tick() {
			savedCallback.current();
		}
		if (delay !== null) {
			let id = setInterval(tick, delay);
			return () => clearInterval(id);
		}
		return;
	}, [delay]);

	return count;
}

export default useIncrementingNumber;
