/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { RouteComponentProps } from 'react-router';
import { Redirect } from 'react-router';
import { Button, Display, Colors, Sizes } from '../Components/Button/Button';
import { action, observable } from 'mobx';
import { TextField } from '../Components/TextBox/TextBox';
import { IUserResult, store } from 'Models/Store';
import { SERVER_URL } from 'Constants';
import axios from 'axios';
import * as queryString from 'querystring';
import { ButtonGroup, Alignment } from 'Views/Components/Button/ButtonGroup';
import { Password } from 'Views/Components/Password/Password';
import { isEmail } from 'Validators/Functions/Email';
import alert from '../../Util/ToastifyUtils';
import { getErrorMessages } from 'Util/GraphQLUtils';
// % protected region % [Add any extra imports here] on begin
import { IconButton } from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { LoginWrapper } from "Wrappers/LoginWrapper";
import styles from "./../../scss/pages/loginPage.module.scss";
import { YHPButton } from "Views/Components/Button/YHPButton";
import { YHPButtonLink } from "Views/Components/Button/YHPButtonLink";
import YHPLogo from "Views/Components/Logo/YHPLogo";
import PatientService from '../../Services/PatientService/PatientService';
// % protected region % [Add any extra imports here] end

// % protected region % [Customise ILoginState here] off begin
interface ILoginState {
	username: string;
	password: string;
	errors: { [attr: string]: string };
}
// % protected region % [Customise ILoginState here] end

// % protected region % [Customise defaultLoginState here] off begin
const defaultLoginState: ILoginState = {
	username: '',
	password: '',
	errors: {},
};
// % protected region % [Customise defaultLoginState here] end

// % protected region % [Add any extra constants here] off begin
// % protected region % [Add any extra constants here] end

@observer
// % protected region % [Override class signature here] off begin
export default class LoginPage extends React.Component<RouteComponentProps> {
// % protected region % [Override class signature here] end
	@observable
	private loginState: ILoginState = defaultLoginState;

	// % protected region % [Add any extra fields here] on begin
	@observable
	private showPassword: boolean = false;
	// % protected region % [Add any extra fields here] end

	// % protected region % [Override render here] on begin
	public render() {
		if (store.loggedIn) {
			return this.handleAlreadyLoggedIn();
		}

		return <LoginWrapper>{this.renderLoginForm()}</LoginWrapper>;
	}

	// % protected region % [Override render here] end

	// % protected region % [Override onLoginClicked here] on begin
	@action
	public onLoginClicked = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		this.loginState.errors = {};

		if (!this.loginState.username) {
			this.loginState.errors["username"] = "Email Address is required";
		} else if (!isEmail(this.loginState.username)) {
			this.loginState.errors["username"] = "This is not a valid email address";
		}
		if (!this.loginState.password) {
			this.loginState.errors["password"] = "Password is required";
		}

		if (Object.keys(this.loginState.errors).length > 0) {
			return;
		} else {
			axios
				.post(`${SERVER_URL}/api/authorization/login`, {
					username: this.loginState.username,
					password: this.loginState.password,
				})
				.then(({ data }) => {
					this.onLoginSuccess(data);
				})
				.catch((response) => {
					const errorMessages = getErrorMessages(response).map((error: any) => {
						const message =
							typeof error.message === "object" ? JSON.stringify(error.message) : error.message;
						return <p>{message}</p>;
					});
					alert(
						<div>
							<h6>Login failed</h6>
							{errorMessages}
						</div>,
						"error"
					);
				});
		}
	};
	// % protected region % [Override onLoginClicked here] end

	// % protected region % [Override onStartRegisterClicked here] off begin
	@action
	private onStartRegisterClicked = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		const { redirect } = queryString.parse(this.props.location.search.substring(1));
		store.routerHistory.push(`/register?${!!redirect ? `redirect=${redirect}` : ''}`);
	};
	// % protected region % [Override onStartRegisterClicked here] end

	// % protected region % [Override login success logic here] on begin
	@action
	private onLoginSuccess = (userResult: IUserResult) => {
		store.setLoggedInUser(userResult);
		const { redirect } = queryString.parse(this.props.location.search.substring(1));
		if (userResult.groups[0].name === "Patient") {
			PatientService.getPatient(userResult.id)
				.then((res) => {
					if (res[0] != null && (res[0].acceptedPrivacyPolicy == false || res[0].acceptedPrivacyPolicy == null)) {
						store.routerHistory.push('/profile-creation-page');
					} else if (res[0] != null && (res[0].onboarded == false || res[0].onboarded == null)) {
						store.routerHistory.push('/onboarding');
					} else if (redirect && !Array.isArray(redirect)) {
						store.routerHistory.push(redirect);
					} else {
						store.routerHistory.push('/');
					}
				});
		} else {
			if (redirect && !Array.isArray(redirect)) {
				store.routerHistory.push(redirect);
			} else {
				store.routerHistory.push('/');
			}
		}
	};
	// % protected region % [Override login success logic here] end

	// % protected region % [Override onForgottenPasswordClick here] on begin
	@action
	private onForgottenPasswordClick = (
		e: React.MouseEvent<HTMLButtonElement, MouseEvent> | React.KeyboardEvent<HTMLAnchorElement>
	) => {
		store.routerHistory.push(`/reset-password-request`);
	};
	// % protected region % [Override onForgottenPasswordClick here] end

	// % protected region % [Add class methods here] on begin
	public renderLoginForm() {
		return (
			<form className={styles.formContainer} onSubmit={(e) => this.onLoginClicked(e)}>
				<YHPLogo />
				<h2 className={styles.formHeader}>Login</h2>
				<TextField
					id='login_username'
					className='login-username'
					model={this.loginState}
					modelProperty='username'
					label='Email'
					inputProps={{ autoComplete: "username", type: "email" }}
					isRequired={true}
					errors={this.loginState.errors["username"]}
				/>
				<div className={styles.passwordContainer}>
					<Password
						id='login_password'
						className='login-password'
						model={this.loginState}
						modelProperty='password'
						label='Password'
						inputProps={{ type: this.showPassword ? "text" : "password" }}
						isRequired={true}
						errors={this.loginState.errors["password"]}
					/>
					<IconButton
						className={styles.hidePassword}
						onClick={(e) => this.togglePassword(e)}>
						{this.showPassword ? <VisibilityOff /> : <Visibility />}
					</IconButton>
				</div>

				<ButtonGroup className={styles.formFooter}>
					<YHPButtonLink
						className={styles.YHPButtonLink}
						onClick={this.onForgottenPasswordClick}>
						Reset Password
					</YHPButtonLink>
					<YHPButton
						className={styles.YHPButton}
						type='submit'
						icon={{ icon: "arrow-right", iconPos: "icon-right" }}>
						Login
					</YHPButton>
				</ButtonGroup>
			</form>
		);
	}

	@action
	public togglePassword(e: any) {
		e.preventDefault();
		this.showPassword = !this.showPassword;
	}

	public handleAlreadyLoggedIn() {
		return <Redirect to='/' />;
	}

	// % protected region % [Add class methods here] end
}

// % protected region % [Add additional exports here] off begin
// % protected region % [Add additional exports here] end
