import * as React from 'react';
import { observer } from 'mobx-react';
import MarkdownIt from 'markdown-it';
import MdEditor from 'react-markdown-editor-lite';
import 'react-markdown-editor-lite/lib/index.css';
import * as Models from 'Models/Entities';
import { observable, action } from 'mobx';
import { SERVER_URL } from 'Constants';
import { TextField } from '../TextBox/TextBox';
import { store } from 'Models/Store';
import { Combobox } from '../Combobox/Combobox';
import Axios from 'axios';
import { TextArea } from '../TextArea/TextArea';
import FileUpload from '../FileUpload/FileUpload';
import { Button, Display, Colors } from '../Button/Button';
import { ButtonGroup } from 'semantic-ui-react';
import If from '../If/If';
import alert from 'Util/ToastifyUtils';
import * as uuid from 'uuid';
import Popup from 'reactjs-popup';
import Collapsible from './Plugins/Collapsible';
import ColourPicker from './Plugins/ColourPicker';
import { gql } from 'apollo-boost';
import Spinner from '../Spinner/Spinner';

function queryActivity(id: string) {
	return gql`
	query activity{
		activitiesEntity(id: "${id}"){
		  articles {
			id
			title
			description
			content
			htmlcontent
			booksId
			featureId
		  }
		}
	  }
	`;
}

export interface ArticleEditorProps<T> {
	article?: any;
	book?: any;
}

interface ComboValues {
	display: string;
	value: string;
}

MdEditor.use(Collapsible);
MdEditor.use(ColourPicker);

@observer
export class ArticleEditor<T> extends React.Component<ArticleEditorProps<T>, any> {
	mdParser: MarkdownIt;

	// Setup the markdown parser
	constructor(props: any) {
		super(props);

		this.mdParser = new MarkdownIt();
		this.mdParser.set({ html: true });

		var emoji = require('markdown-it-emoji');
		var underline = require('markdown-it-plugin-underline');
		this.mdParser.use(emoji);
		this.mdParser.use(underline);
	}

	// Used for storing images and presenting them in the markdown editor
	@observable
	private imageEntity = new Models.LibraryimagesEntity();

	// Used to populate the books dropdown
	@observable
	private books: ComboValues[] = [];

	// Used to populate the books dropdown
	@observable
	private activities: ComboValues[] = [];

	// Used to represent the article entity
	@observable
	private model = {
		title: this.props.article.title,
		book: this.props.book,
		description: this.props.article.description,
		content: this.props.article.content,
		htmlcontent: this.props.article.htmlcontent,
		image: new Models.LibraryimagesEntity({ libraryimagesId: this.props.article.featureId }),
		activity: this.props.article.activitiesId,
	};

	@action
	handleEditorChange = (it: { text: string; html: string }, event: any) => {
		this.model.content = it.text;
		this.model.htmlcontent = it.html;
	};

	@action
	private handleComboboxBook = (e: any) => {
		this.model.book = e.target.dataset.id;
	};

	@action
	private handleComboboxActivity = (e: any) => {
		this.model.activity = e.target.dataset.id;
	};

	@action
	private handleTitle = (e: any) => {
		this.model.title = e.target.value;
	};

	@action
	private handleDescription = (e: any) => {
		this.model.description = e.target.value;
	};

	@action
	private handleImage = (e: any) => {
		this.model.image = new Models.LibraryimagesEntity();
		this.model.image.libraryimages = e;
		this.props.article.featureId = e;
		this.model.image.save(undefined, { contentType: 'multipart/form-data' });
	};

	// Saves an article
	// Param finish: if the user has finished editing and will return to the admin library page
	private async saveArticle(finish: boolean) {
		const { title, book, description, content, htmlcontent, activity } = this.model;
		const featureImage = this.model.image.libraryimagesId;

		if (!title) {
			alert('Please enter a title');
		}
		if (!book) {
			alert('Please choose a valid book');
		}
		if (!description) {
			alert('Please enter a description');
		}
		if (!featureImage) {
			alert('Please select a feature image');
		}
		if (title && book && description && featureImage) {
			this.props.article.content = content;
			this.props.article.htmlcontent = htmlcontent;
			this.props.article.booksId = book;
			this.props.article.description = description;
			this.props.article.featureId = featureImage;
			this.props.article.title = title;
			this.props.article.activitiesId = activity;

			const saveArticle = new Models.ArticlesEntity(this.props.article);

			// If an activity was selected
			if (this.props.article.activitiesId) {
				// Remove the existing relationship between an activity and an article if it exists
				store.apolloClient.query({ query: queryActivity(this.props.article.activitiesId) }).then((d) => {
					if (d.data.activitiesEntity.articles) {
						const article = new Models.ArticlesEntity(d.data.activitiesEntity.articles);
						article.save().then(() => {
							if (finish) {
								saveArticle
									.save()
									// Have to use window.open because the API call in the LibraryAdminPage does not load the book change
									.then(() => window.open('/admin/libraryadmin', '_self'))
									.then(() => alert('Successfully saved article'))
									.catch(() => alert('Could not save article'));
							} else {
								saveArticle._clientId = uuid.v4();
								saveArticle
									.save()
									.then(() => alert('Successfully saved article'))
									.then(() => (this.props.article.id = saveArticle.id))
									.catch(() => alert('Could not save article'));
							}
						});
					}
					// Relationship doesn't exist, continue saving article
					else {
						saveArticle.save().then(() => {
							if (finish) {
								saveArticle
									.save()
									.then(() => window.open('/admin/libraryadmin', '_self'))
									.then(() => alert('Successfully saved article'))
									.catch(() => alert('Could not save article'));
							} else {
								saveArticle._clientId = uuid.v4();
								saveArticle
									.save()
									.then(() => alert('Successfully saved article'))
									.then(() => (this.props.article.id = saveArticle.id))
									.catch(() => alert('Could not save article'));
							}
						});
					}
				});
				// If an activity was not selected
			} else {
				if (finish) {
					saveArticle
						.save()
						// Have to use window.open because the API call in the LibraryAdminPage does not load the book change
						.then(() => window.open('/admin/libraryadmin', '_self'))
						.catch(() => alert('Could not save article'));
				} else {
					saveArticle._clientId = uuid.v4();
					saveArticle
						.save()
						.then(() => alert('Successfully saved article'))
						.then(() => (this.props.article.id = saveArticle.id))
						.catch(() => alert('Could not save article'));
				}
			}
		}
	}

	// Deletes an article
	private deleteArticle = () => {
		if (!this.props.article.id) {
			alert('Can not delete article because it does not yet exist');
		} else {
			const article = new Models.ArticlesEntity(this.props.article);
			article
				.delete()
				// Have to use window.open because the API call in the LibraryAdminPage loads the deleted book
				.then(() => window.open('/admin/libraryadmin', '_self'))
				.catch(() => alert('Could not delete article'));
		}
	};

	private imageUpload = (file: File): Promise<string> => {
		// Create an image entity and set it's file to be the file retrieved from the MDE
		this.imageEntity = new Models.LibraryimagesEntity();
		this.imageEntity.libraryimages = file;

		return new Promise((resolve) => {
			var imageID = this.imageEntity.save(undefined, { contentType: 'multipart/form-data' }).then((r) => {
				return `${SERVER_URL}/api/files/${this.imageEntity.libraryimagesId}`;
			});
			resolve(imageID);
		});
	};

	public componentDidMount(): void {
		this.getBooks();
		this.getActivities();
	}

	public async getBooks() {
		let books: ComboValues[];
		await Axios.get(`${SERVER_URL}/api/entity/BooksEntity`)
			.then((d) => {
				books = d.data.data.map((book: any) => ({ display: book.name, value: book.id }));
			})
			.catch(() => alert('Could not load books'))
			.finally(() => {
				this.setBooks(books);
			});
	}

	public async getActivities() {
		let activities: ComboValues[];
		await Axios.get('/api/entity/ActivitiesEntity')
			.then((d) => (activities = d.data.data.map((activity: any) => ({ display: activity.name, value: activity.id }))))
			.catch(() => alert('Could not load activities'))
			.finally(() => this.setActivities(activities));
	}

	@action
	setBooks(books: ComboValues[]) {
		this.books = books;
	}

	@action
	setActivities(activities: ComboValues[]) {
		this.activities = activities;
	}

	public render() {
		if (!this.books || !this.activities) {
			return <Spinner />;
		}

		return (
			<>
				<div className="library-header">
					<div>
						<h6>Library</h6>
						<If condition={this.model.title}>
							<h2>{this.model.title}</h2>
						</If>
						<If condition={!this.model.title}>
							<h2>Create an article</h2>
						</If>
					</div>
					<div className="crud-btn-container">
						<ButtonGroup className="article-crud-buttons">
							<Popup
								trigger={
									<Button display={Display.Solid} icon={{ icon: 'bin-empty', iconPos: 'icon-left' }}>
										Delete Article
									</Button>
								}
								modal>
								{(close) => (
									<>
										<h4>Are you sure?</h4>
										<p>Do you really wish to delete this article?</p>
										<div className="btn-delete-container">
											<Button
												onClick={() => this.deleteArticle()}
												className="btn-delete-yes"
												display={Display.Solid}
												icon={{ icon: 'check', iconPos: 'icon-left' }}>
												Yes
											</Button>

											<Button
												onClick={() => close()}
												className="btn-delete-no"
												display={Display.Solid}
												icon={{ icon: 'cross', iconPos: 'icon-left' }}>
												No
											</Button>
										</div>
									</>
								)}
							</Popup>

							<Button
								onClick={() => store.routerHistory.push('/admin/libraryadmin')}
								display={Display.Solid}
								icon={{ icon: 'cross', iconPos: 'icon-left' }}>
								Cancel
							</Button>
							<Button onClick={() => this.saveArticle(false)} display={Display.Solid} icon={{ icon: 'save', iconPos: 'icon-left' }}>
								Save
							</Button>
							<Button onClick={() => this.saveArticle(true)} display={Display.Solid} icon={{ icon: 'save', iconPos: 'icon-left' }}>
								Save & Finish
							</Button>
						</ButtonGroup>
					</div>
				</div>
				<div className="create-article">
					<div className="create-article-left-container">
						<TextField
							model={this.model}
							modelProperty="title"
							label="Article Title"
							placeholder="Article title"
							isRequired={true}
							// onChangeAndBlur={this.handleTitle}
						/>
						<Combobox
							model={this.model}
							modelProperty="book"
							label={'Book'}
							placeholder="Search or select book"
							options={this.books}
							isRequired={true}
							// onChange={this.handleComboboxBook}
						/>
						<Combobox
							model={this.model}
							modelProperty="activity"
							label={'Activity'}
							placeholder="Activities"
							options={this.activities}
							// isRequired={false}
							// onChange={this.handleComboboxActivity}
						/>
						<TextArea
							className="article-description"
							model={this.model}
							modelProperty="description"
							label="Description"
							placeholder="Enter a short summary of what the article is about"
							isRequired={true}
							// onChangeAndBlur={this.handleDescription}
						/>
					</div>

					<div className="create-article-right-container">
						<p>Feature Image</p>
						<FileUpload
							isRequired={true}
							model={this.model.image}
							modelProperty="libraryimages"
							onAfterChange={this.handleImage}
							imageUpload
							preview
						/>
						{this.props.article.featureId ? (
							<>
								<div className="preview">
									<div className="image">
										<p>Image Preview:</p>
										<img src={`/api/files/${this.props.article.featureId}`} />
									</div>
								</div>
								<p>Recommended file dimensions w: 480px h: 320px</p>
							</>
						) : (
							<p>Recommended file dimensions w: 480px h: 320px</p>
						)}
					</div>
				</div>
				<div className="article-editor-container">
					<MdEditor
						value={this.model.content}
						// I know inline styles = bad but this is ONLY for the MDEditor because the class
						// names are confusing to those who will try and debug in the future
						style={{ height: '100%', borderRadius: '4px' }}
						renderHTML={(text: string) => this.mdParser.render(text)}
						config={{
							imageAccept: '.jpg,.png,.svg,.jpeg',
						}}
						onImageUpload={this.imageUpload}
						onChange={this.handleEditorChange}
					/>
				</div>
			</>
		);
	}
}
