import React from 'react';
import { observer } from 'mobx-react';
import { AreaOfLifeRatingsEntity, PatientEntity } from 'Models/Entities';
import { action, observable } from 'mobx';
import If from '../If/If';
import _ from 'lodash';
import { AreaColor } from 'Constants';
import { AreaOfLifeRatingRange } from '../../../Models/Entities/AreaOfLifeRatingsEntity';

interface IAreaButton {
	area: string;
	color: string;
	value: number;
}

interface IAreaProgress {
	user: PatientEntity;
	areaOfLifeRating: AreaOfLifeRatingsEntity;
	onClick: (area: string) => void;
	displayFooter: boolean;
}

@observer
export class AreaProgress extends React.Component<IAreaProgress> {
	@observable
	private areaButtons: IAreaButton[] = [];

	@action
	componentDidUpdate(prevProps: any) {
		if (prevProps.user !== this.props.user) {
			this.setAreaButtons();
		}
	}

	render() {
		return (
			<div className="area-progress-content">
				{this.areaButtons.map((button: IAreaButton, index: number) => {
					const { area, color, value } = button;
					return (
						<AreaProgressButton
							key={index}
							onClickButton={() => this.props.onClick(area)}
							color={color}
							value={value}
							area={area}
							content={this.getEndOfBarContentNode(value)}
						/>
					);
				})}

				<If condition={this.props.displayFooter}>
					<div className="area-scale">
						<div>0%</div>
						<div>100%</div>
					</div>
				</If>
			</div>
		);
	}
	private getEndOfBarContentNode = (value: number): JSX.Element => {
		const className = value === AreaOfLifeRatingRange.MAX
			? 'add-area icon-check icon-left completed-area'
			: 'add-area icon-plus icon-left';

		return <div className={className} />;
	}

	@action
	componentDidMount() {
		this.setAreaButtons();
	}

	@action
	private setAreaButtons() {
		const {
			family,
			financial,
			physical,
			psychological,
			relationships,
			spiritual,
			social,
			work,
		} = this.props.areaOfLifeRating;

		this.areaButtons = [
			{
				area: 'Family',
				color: AreaColor.FAMILY,
				value: family,
			},
			{
				area: 'Financial',
				color: AreaColor.FINANCIAL,
				value: financial,
			},
			{
				area: 'Physical',
				color: AreaColor.PHYSICAL,
				value: physical,
			},
			{
				area: 'Psychological',
				color: AreaColor.PSYCHOLOGICAL,
				value: psychological,
			},
			{
				area: 'Spiritual',
				color: AreaColor.VALUES,
				value: spiritual,
			},
			{
				area: 'Social',
				color: AreaColor.SOCIAL,
				value: social,
			},
			{
				area: 'Relationships',
				color: AreaColor.RELATIONSHIPS,
				value: relationships,
			},
			{
				area: 'Work',
				color: AreaColor.WORK,
				value: work,
			},
		];

		this.areaButtons = _.sortBy(this.areaButtons, (button) => {
			if (button.value == undefined) {
				return 0;
			}
			if (button.value !== -1) {
				return button.value;
			}
			return;
		});
	}
}

export interface IAreaProgressButton {
	onClickButton?: (area: string) => void;
	color: string;
	area: string;
	value: number;
	content?: JSX.Element
}

@observer
export class AreaProgressButton extends React.Component<IAreaProgressButton> {
	render() {
		const { area, color, value } = this.props;
		// this is adding more bad ontop of the bad. however with the impending production release a refactor of the entire codebase that is using the enums inappropriately
		var areaName = "";
		switch (area.toLowerCase()){
			case "spiritual":
				areaName = "Values";
				break;
			case "psychological":
				areaName = "Mindset"
				break;
			default:
				areaName = _.upperFirst(area.toLowerCase());
		}

		return (
			<div
				className={`area-button-container`}
				onClick={() => {
					if (this.props.onClickButton == null || value === -1) {
						return;
					}
					this.props.onClickButton(area);
				}}
			>
				<div
					className="area-button"
					style={{
						background: value === -1 ? '#f4f4f4' : color,
						width: `${value ? value * 10 : 0}%`,
						opacity: value === -1 ? 0.5 : 1,
					}}
				>
					<div className="area-name">
						{areaName} {value === -1 ? '(N/A)' : ''}
					</div>
				</div>
				{this.props.content}
			</div>
		);
	}
}

