import { observer } from "mobx-react";
import * as React from "react";
import { action, observable, toJS } from "mobx";
import alert from "../../../Util/ToastifyUtils";
import axios from "axios";
import { Link } from "react-router-dom";
import { Button } from "../Button/Button";
import FileUploadCustom from "../FileUpload/FileUploadCustom";
import { store } from 'Models/Store';
import moment from 'moment';

// Interface for importing a file. Contains File object and fileName string.
interface UserImportModel {
    file?: File;
    fileName?: string
}

// CSV Template interface. Used for safe creating of objects that contain the User
// data. All values are strings for ease of parsing.
export interface CSVClinicianTemplate {
	email: string;
	forename: string;
	surname: string;
	gender: string;
	dateofbirth: string | Date;
	pos: number;
}

interface CSVImportProps {
    closePopup: Function;
    entity: string;
}

@observer
export default class CSVImport extends React.Component<CSVImportProps, any> {
    dragRef = React.createRef<HTMLDivElement>();

    @observable
    private buttonName: string = "Save";

    @observable
    private dragging: boolean = false;

    @observable
    private dragCount: number = 0;

    @observable
    private model: UserImportModel = {
        file: undefined,
        fileName: ""
    };

    // Create instance of the CSVClinicianTemplate interface.
    private csvRow: CSVClinicianTemplate = {
        email: "",
        forename: "",
        surname: "",
        gender: "",
        dateofbirth: "",
        pos: 0
    }

    // Create array of CSVClinicianTemplate to store each line of the CSV file.
    // This will be posted to the server as an array of CSVClinicianTemplate objects
    // for parsing.
    @observable
    private failedRows: CSVClinicianTemplate[] = [];

    @observable
    private csvRows: CSVClinicianTemplate[] = [];

    // Action performed when a new file is uploaded.
    @action
    private fileChange(): boolean {
        try {
            this.model.fileName = this.model.file?.name;
        }
        catch (e) {
            console.log(e);
            return false;
        }

        // Check if file is a .csv file. Else, remove the file and display an error.
        if (this.model.fileName?.split('.').slice(-1)[0] != 'csv') {
            alert("File must be a .csv file.");
            this.model.file = undefined;
            this.model.fileName = "";
            return false;
        }

        return true;
    }


    @action
    private importUsers() {
        // Check if .csv file exists
        if(this.model.file && this.model.fileName) {
            alert("Importing Users...");
            this.buttonName = "Importing";

            // Assigns column headers as keys
            const keys: Array<Object> = Object.keys(this.csvRow).map(key => {
                // Change firstname header name back to forename
                if(key === "firstname") {
                    return {value:"forename"};
                } else {
                    return ({ value: key });
                }
            })

            // Extract the text from the csv, then split it per row, then split it per entry.
            this.model.file?.text().then((csv) => {
                
                // Loop through rows
                csv.split('\n').forEach(async (row, i) => {
                    let rowFailed = false;

                    // Disregard header row and blank rows
                    if(i > 0 && row.toString() != "") {
                        this.csvRow['pos'] = i;

                        // Loop through entries and
                        row.split(',').forEach((entry, j) => {

                            // Validate gender and date of birth columns
                            if (keys[j]['value'] == "gender") {
                                entry = entry.toUpperCase();

                                if (entry !== "" && !entry.match("MALE") && !entry.match("FEMALE")) {
                                    rowFailed = true;
                                }
                            }
                            else if (keys[j]['value'] == 'dateofbirth') {

                                if (entry !== "")
                                {
                                    let parsedEntry = entry.replace("\r", "");
                                    let entryDate = moment(parsedEntry, "DD/MM/YYYY", true);
    
                                    if (!entryDate.isValid() || entryDate.isAfter(new Date())) {
                                        rowFailed = true;
                                    }
                                    else{
                                        entry = parsedEntry;
                                    }
                                }
                            }
                            else if (keys[j]['value'] == 'email') {
                                if(entry == "") {
                                    rowFailed = true;
                                } else {
                                    entry = entry.toLowerCase();
                                }
                            }

                            // Assign entry value to corresponding key of csvRow
                            this.csvRow[keys[j]['value']] = entry;
                        })

                        // Push row to failedRows or csvRows object
                        if(rowFailed) {
                            this.failedRows.push(this.csvRow);
                        } else {
                            this.csvRows.push(this.csvRow);
                        }
                    }
                })

                let counter = 0;

                // If all rows have failed, redirect straight to CSV editor page
                if(this.csvRows.length < 1 && this.failedRows.length >= 1) {
                    alert("Failed to import following users. Please validate information.", 'error')

                    let temp = {data:toJS(this.failedRows), entity:this.props.entity};
                    store.routerHistory.push({pathname: '/failedCliniciancsv', state: temp});
                }

                // Post each row
                this.csvRows.forEach((row) => {
					axios
						.post("/api/entity/ClinicianEntity/import-users", row)
						.then((res: any) => {
							alert(`Successfully created ${row.email}.`);
						})
						.catch((e: any) => {
							this.failedRows.unshift(row);
						})
						.finally(() => {
							counter++;

							// Redirect to CSV editor page if any lines fail
							if (counter == this.csvRows.length) {
								setTimeout(() => {
									if (this.failedRows.length >= 1) {
										alert("Failed to import following users. Please validate information.", "error");
										this.failedRows = this.failedRows.sort((a, b) => (a.pos > b.pos ? 1 : b.pos > a.pos ? -1 : 0)); // sort table by line position in csv
										let temp = { data: toJS(this.failedRows), entity: this.props.entity };
										store.routerHistory.push({ pathname: "/failedCliniciancsv", state: temp });
									} else {
										alert("All imports succeeded! Reloading page...", "success");
										setTimeout(() => window.location.reload(), 1000);
									}
									this.failedRows = [];
									this.props.closePopup();
								}, 1000);
							}
						});
				})
            })
        } else {
            // Alert if no file exists.
            alert("Please import a CSV file.");
        }
    }

    constructor(props: Readonly<CSVImportProps>) {
        super(props);
        //binding context
        this.importUsers = this.importUsers.bind(this);
        this.fileChange = this.fileChange.bind(this);
        this.handleDragEnter = this.handleDragEnter.bind(this);
        this.handleDragLeave = this.handleDragLeave.bind(this);
        this.handleDrop = this.handleDrop.bind(this);
    }

    componentDidMount() {
        //add listeners
        let dragArea: HTMLDivElement | null = this.dragRef.current;
        if (dragArea) {
            dragArea.addEventListener('dragenter', this.handleDragEnter);
            dragArea.addEventListener('dragleave', this.handleDragLeave);
            //important required for drop event to be allowed
            dragArea.addEventListener('dragover', this.stopEvent);
            dragArea.addEventListener('drop', this.handleDrop);
        }
    }

    componentWillUnmount() {
        //kill listeners
        let dragArea: HTMLDivElement | null = this.dragRef.current;
        if (dragArea) {
            dragArea.removeEventListener('dragenter', this.handleDragEnter);
            dragArea.removeEventListener('dragleave', this.handleDragLeave);
            dragArea.removeEventListener('dragover', this.stopEvent);
            dragArea.removeEventListener('drop', this.handleDrop);
        }
    }

    @action
    handleDragEnter(e: DragEvent) {
        e.preventDefault();
        e.stopPropagation();
        if (e.dataTransfer?.items && e.dataTransfer.items.length > 0) {
            this.dragCount += 1;
            if (!this.dragging) {
                this.dragging = true;
            }
        }
    }

    @action
    handleDragLeave(e: DragEvent) {
        e.preventDefault();
        e.stopPropagation();
        this.dragCount -= 1;
        if (this.dragCount === 0) {
            this.dragging = false;
        }
    }

    stopEvent(e: Event) {
        e.stopPropagation();
        e.preventDefault();
    }

    @action
    handleDrop(e: DragEvent) {
        e.preventDefault();
        e.stopPropagation();
        if (e.dataTransfer?.files && e.dataTransfer.files.length > 0) {
            this.dragCount = 0;
            this.dragging = false;
            this.model.file = e.dataTransfer?.files[0];
            this.fileChange();
        }
    }

    render() {
        return (
			<>
				<div className="import-popup-main">
					<h2 className="import-popup-header">Import from .csv</h2>
					<span className="text-sm">The fastest way to import multiple users is by downloading and using our template.</span>
					<Link to={"/CSV/" + this.props.entity + "Template.csv"} target="_blank" download>
						{<Button className="btn--solid">Download Template</Button>}
					</Link>
					<span className="text-sm">Then upload that file here.</span>
					<div className={"import-popup-dragarea" + (this.dragging ? " dragging" : "")} ref={this.dragRef} id="import-popup-dragarea">
						<span className="text-bold">Drag or upload file here</span>
						<FileUploadCustom model={this.model} modelProperty="file" onAfterChange={this.fileChange} />
					</div>
					<span className={this.model.fileName ? "selected" : ""}>File Selected: {this.model.fileName}</span>
				</div>
				<div className="import-popup-controls">
					<Button className="btn--outline" onClick={() => this.props.closePopup()}>
						Cancel
					</Button>
					<Button className="btn--solid" onClick={this.importUsers}>
						{this.buttonName}
					</Button>
				</div>
			</>
		);
    }
}
