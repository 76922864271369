import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Popup from "reactjs-popup";
import moment from "moment";
import { response } from '../CheckInClinician/ClinicianCheckIn';
import { QuestionType } from '../../../Forms/Schema/Question';
import _ from 'lodash';

interface ratingProps {
	responses: response[];
	frequency: 'DAILY' | 'WEEKLY' | 'FORTNIGHTLY';
	formField: string;
	tagName: string;
	question: any;
}

//really needs reworking once everyth ing is typed properly
function sortResponsesByDay(responses: response[], filterDate: string, formField: string, direction: 'asc' | 'desc', questionType: QuestionType) {

	const getDateValue = (response: response) => {
		if (response.values == null || response.values.length === 0) {
			return null;
		}
		let foundValue = null;
		response.values.forEach(value => {
			let date = moment(value.submissionDate).format('D/M');
			if (date === filterDate) {
				let data = JSON.parse(value.submissionData);
				if (data && data[formField]) {
					foundValue = data[formField];
				}
			}
		})
		return foundValue;
	}


	// Handle sorting for numerical questions
	const numericalQuestions = ['number', 'slider', 'smileyrating', 'pictorial-slider'] as QuestionType[];
	if (numericalQuestions.includes(questionType)) {
		return responses.sort((a, b) => {
			const aValue = getDateValue(a);
			const bValue = getDateValue(b);
			// Sort ascending and descending. In both cases, null values go to the bottom of the list.
			if (direction === 'asc') {
				return (aValue != null ? aValue : Infinity) - (bValue != null ? bValue : Infinity);
			} else {
				return (bValue != null ? bValue : -Infinity) - (aValue != null ? aValue : -Infinity);
			}
		});
	}

	// Handle sorting for text questions
	const textQuestions = ['text', 'textarea'] as QuestionType[];
	if (textQuestions.includes(questionType)) {
		return responses.sort((a, b) => {
			const aValue = getDateValue(a);
			const bValue = getDateValue(b);
			// Sort ascending and descending. In both cases, null values go to the bottom of the list.
			if (direction === 'asc') {
				return (aValue != null ? aValue : "zzzz").localeCompare(bValue != null ? bValue : "zzzz");
			} else {
				return (bValue != null ? bValue : "").localeCompare(aValue != null ? aValue : "");
			}
		});
	}

	const dateQuestions = ['date', 'datetime'] as QuestionType[];
	if (dateQuestions.includes(questionType)) {
		return responses.sort((a, b) => {
			const aValue = getDateValue(a);
			const bValue = getDateValue(b);
			const smallDate = moment(0).toDate();
			const bigDate = moment().add(100, 'years').toDate();

			// Sort ascending and descending. In both cases, null values go to the bottom of the list.
			if (direction === 'asc') {
				const parsedAValue = (aValue != null ? moment(aValue) : bigDate)
				const parsedBValue = (bValue != null ? moment(bValue) : bigDate);
				if (parsedAValue === parsedBValue) return 0;
				return parsedAValue > parsedBValue ? 1 : -1;
			} else {
				const parsedAValue = (aValue != null ? moment(aValue) : smallDate)
				const parsedBValue = (bValue != null ? moment(bValue) : smallDate)
				if (parsedAValue === parsedBValue) return 0;
				return parsedBValue > parsedAValue ? 1 : - 1;
			}
		});
	}
	// Handle Comparable Questions
	const booleanQuestions = ['checkbox', 'radiobutton'] as QuestionType[];
	if (booleanQuestions.includes(questionType)) {
		return responses.sort((a, b) => {
			const aValue = getDateValue(a);
			const bValue = getDateValue(b);

			// Sort ascending and descending. In both cases, null values go to the bottom of the list.
			if (direction === 'asc') {
				return (aValue != null ? aValue : true) > (bValue != null ? bValue : true) ? 1 : -1;
			} else {
				return (bValue != null ? bValue : false) > (aValue != null ? aValue : false) ? 1 : -1;
			}
		});
	}
	return responses;
}

function getDates(frequency: string) {
	let columns = [];
	if (frequency === 'DAILY') {
		for (let prevDays = 6; prevDays >= 0; prevDays--) {
			let nowDay = moment();
			columns.push(nowDay.subtract(prevDays, 'd').format('D/M'));
		}
	}
	if (frequency === 'WEEKLY') {
		for (let prevWeek = 6; prevWeek >= 0; prevWeek--) {
			let nowDay = moment().startOf('w');
			columns.push(nowDay.subtract(prevWeek, 'w').format('D/M'));
		}
	}
	if (frequency === 'FORTNIGHTLY') {
		for (let prevFortnight = 6; prevFortnight >= 0; prevFortnight--) {
			let nowDay = moment().startOf('w');
			columns.push(nowDay.subtract(prevFortnight * 2, 'w').format('D/M'));
		}
	}
	return columns;
}

// Gets the values to go into the columns for each user
function getTableRows(columns: string[], frequency: string, responses: response[], formField: string) {
	return responses.map((response: response) => {
		if (response.hasRating) {
			let values = columns.map((day: string) => {
				let submission = null;
				if (frequency === 'DAILY') {
					response.values?.forEach((value: any) => {
						let submissionDate = moment(value.submissionDate).format('D/M');
						if (submissionDate === day) {
							let data = JSON.parse(value.submissionData);
							if (data !== undefined) {
								submission = data[formField];
							}
						}
					});
					return submission;
				}
				if (frequency === 'WEEKLY') {
					response.values?.forEach((value: any) => {
						let submissionDate = moment(value.submissionDate).startOf('w').format('D/M');
						if (submissionDate === day) {
							let data = JSON.parse(value.submissionData);

							if (data !== undefined) {
								submission = data[formField];
							}
						}
					});
					return submission;
				}
				return;
			});

			return {
				id: response.userId,
				user: response.userName,
				firstname: response.firstName,
				surname: response.surname,
				values: values
			};
		}
		return {
			id: response.userId,
			user: response.userName,
			firstname: response.firstName,
			surname: response.surname,
			values: []
		};
	});
}

interface IRows {
	id: string;
	user: string;
	firstname: string;
	surname: string;
	values: any[];
}

// Renders the value item appropriately depending on the question type
function renderValues(value: any, question: any) {
	const { questionType } = question;

	if (value != null) {
		if (questionType === 'pictorial-slider') {
			const { shape, max } = question;
			if (shape) {
				// This is good to bad (bad = highest)
				if (shape === 'alco') {
					if (max === 5) {
						let renderValue = 11 - value * 2;
						return <div className={`value_${renderValue}`}>{value}</div>;
					} else {
						let renderValue = 11 - value;
						return <div className={`value_${renderValue}`}>{value}</div>;
					}
					// This is bad to good (good = highest)
				} else {
					if (max === 5) {
						let renderValue = value * 2;
						return <div className={`value_${renderValue}`}>{value}</div>;
					} else {
						return <div className={`value_${value}`}>{value}</div>;
					}
				}
			}
		}

		if (questionType === 'smileyrating' || questionType === 'slider') {
			return <div className={`value_${value}`}>{value}</div>;
		}
		if (questionType === 'textarea' || questionType === 'text') {
			return (
				<Popup trigger={<div className="value_text icon-left icon-forms" />} position="top center"
				       closeOnDocumentClick>
					<span>{value}</span>
				</Popup>
			);
		}
		if (questionType === 'date' || questionType === 'datetime') {
			return (
				<Popup trigger={<div className="value_text icon-left icon-calender" />} position="top center"
				       closeOnDocumentClick>
					<span>{moment(value).format('dddd, MMMM Do YYYY hh:mm a')}</span>
				</Popup>
			);
		}
		if (questionType === 'radiobutton') {
			return (
				<Popup trigger={<div className="value_text icon-left icon-forms" />} position="top center"
				       closeOnDocumentClick>
					<span>{value}</span>
				</Popup>
			);
		}
		if (questionType === 'number') {
			return <div className="value_number">{value}</div>;
		}
		if (questionType === 'checkbox') {
			const className = value === true ? `value_check_true icon-left icon-check` : `value_check_false icon-left icon-cross`
			return <div className={className} />;
		}
		return <div>{value}</div>
	}

	return <div>-</div>;
}
function sortResponsesByAnswered(responses: response[]) {
	return _.orderBy(responses, (o: response) => o.values?.length, ["desc"]);
}

export default function CheckinRaitingsTable({ frequency, responses, question, formField }: ratingProps) {
	const [responsesSorted, setResponses] = useState<response[]>(sortResponsesByAnswered(responses));
	const [rows, setRows] = useState<IRows[]>([]);

	const [sortFieldName, setSortFieldName] = useState('firstName');
	const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');

	let columns = getDates(frequency);

	// Compute values on mounting component;
	useEffect(() => {
		let values = getTableRows(columns, frequency, responsesSorted, formField);
		setRows(values);
	}, [])

	// On Click Handler for setting the current sort direction and sort field when clicking header
	const onClickHeader = (headerName: string) => {
		if (sortFieldName !== headerName) {
			setSortFieldName(headerName);
			setSortDirection('desc');
		} else {
			if (sortDirection === 'asc') {
				setSortDirection('desc');
			} else if (sortDirection === 'desc') {
				setSortDirection('asc');
			}
		}
	}

	// Fetching the class name for sorting
	const sortClassName = (headerName: string) => {
		if (sortFieldName !== headerName) {
			return 'sortable';
		}
		if (sortDirection === 'desc') {
			return 'sortable--des';
		} else if (sortDirection === 'asc') {
			return 'sortable--asc'
		} else {
			return 'sortable'
		}
	}

	//
	useEffect(() => {
		if (sortFieldName === 'firstName' || sortFieldName === 'surname') {
			let sortedResponse = responses.sort((a, b) => {
				const aString = (a && a[sortFieldName]) ?? "";
				const bString = (b && b[sortFieldName]) ?? "";

				if (sortDirection === 'asc') {
					return aString.localeCompare(bString);
				} else {
					return bString.localeCompare(aString);
				}
			});

			setResponses(sortedResponse);
			let values = getTableRows(columns, frequency, responsesSorted, formField);
			setRows(values);
		} else {
			const sortedResponse = sortResponsesByDay(responsesSorted, sortFieldName, formField, sortDirection, question.questionType);
			setResponses(sortedResponse);
			let values = getTableRows(columns, frequency, responsesSorted, formField);
			setRows(values);
		}

	}, [sortFieldName, sortDirection])

	return (
		<div className="dashboard-grid-item dashboard-table check-in-ratings">
			<section className="collection-component">
				<section aria-label="collection menu" className="collection__menu" />
				<section className="collection__list">
					<table>
						<thead className="header list__header">
						<tr>
							<th scope="col" className={sortClassName('surname')}
							    onClick={() => onClickHeader('surname')}>Surname
							</th>
							<th scope="col" className={sortClassName('firstName')}
							    onClick={() => onClickHeader('firstName')}>First Name
							</th>
							{columns.map((day: string, index: number) => {
								return (
									<th
										key={day}
										scope="col"
										className={`value ${sortClassName(day)}`}
										onClick={() => onClickHeader(day)}
									>
										{day}
									</th>
								);
							})}
							<th scope="col" className="profile list__header--actions" />
						</tr>
						</thead>
						<tbody className="users">
						{rows.map((response: any) => {
							return (
								<tr className="user">
									<td className="name">{response.surname ?? 'N/A'}</td>
									<td className="name">{response.firstname ?? 'N/A'}</td>
									{response.values.map((value: any, index: number) => {
										return <td className="value" key={index}>{renderValues(value, question)}</td>;
									})}
									{response.values.length === 0 && (
										<>
											<td />
											<td />
											<td />
											<td />
											<td />
											<td />
											<td />
										</>
									)}
									<td>
										<Link to={`profile/patient/${response.id}`} className="profile">
											View Profile
										</Link>
									</td>
								</tr>
							);
						})}
						</tbody>
					</table>
				</section>
			</section>
		</div>
	);
}
