import React, { useEffect, useState } from "react";
import { PatientEntity, ClinicianEntity, CarerEntity, CustomProfileFieldsEntity } from "Models/Entities";
import { profileFieldType } from "Models/Enums";
import { TextField } from "../TextBox/TextBox";
import alert from "Util/ToastifyUtils";
import { DatePicker } from "../DatePicker/DatePicker";
import { Button, Colors, Display } from "../Button/Button";

interface ICustomFieldsProps {
    user: PatientEntity | ClinicianEntity | CarerEntity | any;
    userGroup: "patient" | "clinician" | "carer" | string;
}

interface IFields {
    name: string;
    type: profileFieldType;
}

interface ICustomFieldsState {
    user: PatientEntity | ClinicianEntity | CarerEntity | any;
    fields: IFields[];
    values: any[];
    renderButtons: boolean;
}

export default function CustomFields(props: ICustomFieldsProps) {
    const [state, setState] = useState<ICustomFieldsState>({ user: props.user, fields: [], values: [], renderButtons: false });

    useEffect(() => {
        getFields();
    }, [props]);

    const getFields = () => {
        if (props.user) {
            CustomProfileFieldsEntity.fetch<CustomProfileFieldsEntity>({
                args: [[{ path: `${props.userGroup}`, comparison: "equal", value: "true" }]],
            }).then(setFields);
        }
    };

    const setFields = (data: CustomProfileFieldsEntity[]) => {
        let fields: { name: any; type: any }[] = [];
        let values: any[] = [];
        data.map((field) => {
            fields.push({ name: field.name, type: field.types });
            values.push(props.user.customFields ? getValue(field) : undefined);
        });

        setState({ ...state, fields: fields, values: values, renderButtons: false, user: props.user });
    };

    const getValue = (field: any) => {
        if (field.type === "DATE_TYPE") {
            return new Date(JSON.parse(props.user.customFields)[field.name]);
        } else {
            return JSON.parse(props.user.customFields)[field.name];
        }
    };

    const handleTextChange = (e: any, index: number) => {
        let _values = state.values;
        _values[index] = e.currentTarget.value;
        setState({ ...state, values: _values });
    };

    const handleDateChange = (e: any, index: number) => {
        let _values = state.values;
        try {
            _values[index] = new Date(e);
        } catch (error) {
            console.log(error);
        }
        setState({ ...state, values: _values, renderButtons: true });
    };

    const handleChange = () => {
        setState({ ...state, renderButtons: true });
    };

    const handleCancel = () => {
        setState({ ...state, user: props.user, renderButtons: false });
        getFields();
    };

    const handleSave = () => {
        let customFields = {};

        state.fields.map((field: any, index: number) => {
            customFields[field.name] = state.values[index];
        });

        state.user.customFields = JSON.stringify(customFields);
        state.user.save().then(() => {
            setState({ ...state, renderButtons: false });
            alert("Information updated!");
        });
    };

    const renderFields = () => {
        return state.fields.map((field, index) => {
            switch (field.type) {
                case "STRING_TYPE":
                    return (
                        <div className="profile-field" key={index}>
                            <div className="profile-label">{field.name}</div>
                            <TextField
                                className="profile-input"
                                model={state.values}
                                modelProperty={`${index}`}
                                placeholder={field.name}
                                inputProps={{ onChangeCapture: (e) => handleTextChange(e, index) }}
                                onAfterChange={handleChange}
                            />
                        </div>
                    );
                case "NUMBER_TYPE":
                    return (
                        <div className="profile-field" key={index}>
                            <div className="profile-label">{field.name}</div>
                            <TextField
                                className="profile-input"
                                model={state.values}
                                modelProperty={`${index}`}
                                placeholder={field.name}
                                inputProps={{ onChangeCapture: (e) => handleTextChange(e, index) }}
                                onAfterChange={handleChange}
                            />
                        </div>
                    );
                case "DATE_TYPE":
                    return (
                        <div className="profile-field" key={index}>
                            <div className="profile-label">{field.name}</div>
                            <DatePicker
                                className="profile-input-date"
                                model={state.values}
                                modelProperty={`${index}`}
                                placeholder={field.name}
                                isReadOnly={false}
                                flatpickrProps={{ onChange: (e) => handleDateChange(e, index) }}
                            />
                        </div>
                    );
                default:
                    return;
            }
        });
    };

    return state.fields.length > 0 ? (
        <div className="profile-card">
            <div className="profile-header">CUSTOM FIELDS</div>
            <div className="profile-body">
                {renderFields()}
                {state.renderButtons ? (
                    <div className="profile-field">
                        <div className="profile-label"></div>
                        <div className="profile-save-cancel">
                            <div className="cancel" onClick={handleCancel}>
                                Cancel
                            </div>
                            <Button className="save" display={Display.Solid} colors={Colors.Primary} onClick={handleSave}>
                                Save
                            </Button>
                        </div>
                    </div>
                ) : (
                    <></>
                )}
            </div>
        </div>
    ) : (
        <></>
    );
}
