import React from "react";

interface IPaginationButtons {
	pages: number[];
	page: number;
	previousPage: () => void;
	nextPage: () => void;
	setActivePage: (page: number) => void;
}

export default function PaginationButtons(props: IPaginationButtons) {
	return (
		<div className="page-buttons">
			<div
				className={"navigate-page icon-left icon-chevron-left " + (props.page == 1 ? "navigate-page-disabled" : "")}
				onClick={() => previousPage()}
			/>
			<div
				className={props.page == 1 ? `active-page-button page-button` : `page-button`}
				onClick={() => {
					props.setActivePage(1);
				}}>
				{1}
			</div>

			{getMiddlePages(props.pages)}

			{props.pages.length !== 1 ? (
				<div
					className={props.page == props.pages.length ? `active-page-button page-button` : `page-button`}
					onClick={() => {
						props.setActivePage(props.pages.length);
					}}>
					{props.pages.length}
				</div>
			) : (
				<></>
			)}

			<div
				className={"navigate-page icon-right icon-chevron-right " + (props.page == props.pages.length ? "navigate-page-disabled" : "")}
				onClick={() => nextPage()}
			/>
		</div>
	);

	function previousPage() {
		if (props.page > 1) {
			props.previousPage();
		}
	}

	function nextPage() {
		if (props.page < props.pages.length) {
			props.nextPage();
		}
	}

	function getMiddlePages(pages: number[]) {
		if (pages.length < 4) {
			return pages.map((page) => {
				if (page !== 1 && page !== pages.length) {
					return (
						<div
							key={page}
							className={props.page == page ? `active-page-button page-button` : `page-button`}
							onClick={() => {
								props.setActivePage(page);
							}}>
							{page}
						</div>
					);
				}
				return;
			});
		} else {
			if (props.page !== 1 && props.page !== pages.length) {
				return (
					<>
						{props.page !== 2 ? <div className="page-dots">...</div> : <></>}
						<div
							className="active-page-button page-button"
							onClick={() => {
								props.setActivePage(props.page);
							}}>
							{props.page}
						</div>
						{props.page !== pages.length - 1 ? <div className="page-dots">...</div> : <></>}
					</>
				);
			} else {
				return <div className="page-dots">...</div>;
			}
		}
	}
}
