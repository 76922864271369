/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import moment from 'moment';
import { action, observable } from 'mobx';
import { Model, IModelAttributes, attribute, entity } from 'Models/Model';
import {IOrderByCondition} from 'Views/Components/ModelCollection/ModelQuery';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from "Util/AttributeUtils";
import { IAcl } from 'Models/Security/IAcl';
import {
	makeFetchManyToManyFunc,
	makeJoinEqualsFunc,
	makeFetchOneToManyFunc,
	makeEnumFetchFunction,
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import { VisitorsPatientEntity } from 'Models/Security/Acl/VisitorsPatientEntity';
import { PatientPatientEntity } from 'Models/Security/Acl/PatientPatientEntity';
import { ClinicianPatientEntity } from 'Models/Security/Acl/ClinicianPatientEntity';
import { CarerPatientEntity } from 'Models/Security/Acl/CarerPatientEntity';
import { AdminPatientEntity } from 'Models/Security/Acl/AdminPatientEntity';
import * as Enums from '../Enums';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import { TimelineModel } from 'Timelines/TimelineModel';
import {SuperAdministratorScheme} from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] on begin
import { computed} from 'mobx';
import AreaOfLifeRatingEntity from '../Entities/AreaOfLifeRatingsEntity';
import { lifeArea, lifeAreaOptions } from '../Enums';
import { lowerCaseFirst } from 'Util/StringUtils';
import { store } from 'Models/Store';
import { gql } from 'apollo-boost';
import { getTheNetworkError } from 'Util/GraphQLUtils';
import { ErrorResponse } from 'apollo-link-error';
import { runInAction } from 'mobx';
import axios from 'axios';
import { SERVER_URL } from 'Constants';
// % protected region % [Add any further imports here] end

export interface IPatientEntityAttributes extends IModelAttributes {
	email: string;
	forename: string;
	middlename: string;
	surname: string;
	// YHPSD-213 - Gender needs to be nullable
	gender: Enums.gender | null;
	dateOfBirth: Date;
	acceptedPrivacyPolicy: boolean;
	phone: string;
	customFields: string;
	status: Enums.status;
	lastLogin: Date;
	onboarded: boolean;
	activatedDate: Date;

	areaOfLifeRatingss: Array<Models.AreaOfLifeRatingsEntity | Models.IAreaOfLifeRatingsEntityAttributes>;
	demoAdminss: Array<Models.AdminEntity | Models.IAdminEntityAttributes>;
	demoClinicianss: Array<Models.ClinicianEntity | Models.IClinicianEntityAttributes>;
	goalss: Array<Models.GoalsEntity | Models.IGoalsEntityAttributes>;
	patientSocialMediaLinkss: Array<Models.SocialMediaLinkEntity | Models.ISocialMediaLinkEntityAttributes>;
	todoss: Array<Models.TodoEntity | Models.ITodoEntityAttributes>;
	loggedEvents: Array<Models.PatientTimelineEventsEntity | Models.IPatientTimelineEventsEntityAttributes>;
	notificationsSettingsId?: string;
	notificationsSettings?: Models.NotificationsSettingsEntity | Models.INotificationsSettingsEntityAttributes;
	favouriteactivitiess: Array<Models.FavouritedbypatientsFavouriteactivities | Models.IFavouritedbypatientsFavouriteactivitiesAttributes>;
	favouritearticless: Array<Models.FavouritedbypatientsFavouritearticles | Models.IFavouritedbypatientsFavouritearticlesAttributes>;
	ratingtemplatess: Array<Models.RatingtemplatesPatients | Models.IRatingtemplatesPatientsAttributes>;
	ratingss: Array<Models.UsersRatings | Models.IUsersRatingsAttributes>;
	carerss: Array<Models.CarersUsers | Models.ICarersUsersAttributes>;
	clinicianss: Array<Models.CliniciansUsers | Models.ICliniciansUsersAttributes>;
	tagss: Array<Models.TagsUsertags | Models.ITagsUsertagsAttributes>;
	// % protected region % [Add any custom attributes to the interface here] on begin
	goalsCreated: number;
	goalsCompleted: number;
	goalsNotComplete: number;
	goalsCompletedPercentage: number;
	todosAssignedToUser: number;
	todosCreatedByUser: number;
	totalTodos: number;
	todosNotCompleted: number;
	todosCompletedPercentage: number;
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('PatientEntity', 'Patient')
// % protected region % [Customise your entity metadata here] end
export default class PatientEntity extends Model implements IPatientEntityAttributes, TimelineModel  {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new VisitorsPatientEntity(),
		new PatientPatientEntity(),
		new ClinicianPatientEntity(),
		new CarerPatientEntity(),
		new AdminPatientEntity(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		'email',
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	/**
	 * The default order by field when the collection is loaded .
	 */
	public get orderByField(): IOrderByCondition<Model> | undefined {
		// % protected region % [Modify the order by field here] off begin
		return {
			path: 'forename',
			descending: false,
		};
		// % protected region % [Modify the order by field here] end
	}

	// % protected region % [Modify props to the crud options here for attribute 'Email'] on begin
	@Validators.Email()
	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		name: "Email",
		displayType: "displayfield",
		createFieldType: "textfield",
		headerColumn: true,
		searchable: true,
		searchFunction: "like",
		searchTransform: AttrUtils.standardiseString,
		order: 1,
	})
	public email: string;
	// % protected region % [Modify props to the crud options here for attribute 'Email'] end

	// % protected region % [Modify props to the crud options here for attribute 'Password'] on begin
	@Validators.Length(6)
	@observable
	@CRUD({
		name: "Password",
		displayType: "hidden",
		createFieldType: "password",
		order: 2,
	})
	public password: string;
	// % protected region % [Modify props to the crud options here for attribute 'Password'] end

	// % protected region % [Modify props to the crud options here for attribute 'Confirm Password'] on begin
	@Validators.Custom("Password Match", (e: string, target: PatientEntity) => {
		return new Promise((resolve) => resolve(target.password !== e ? "Password fields do not match" : null));
	})
	@observable
	@CRUD({
		name: "Confirm Password",
		displayType: "hidden",
		createFieldType: "password",
		order: 3,
	})
	public _confirmPassword: string;
	// % protected region % [Modify props to the crud options here for attribute 'Confirm Password'] end

	// % protected region % [Modify props to the crud options here for attribute 'Forename'] on begin
	/* Firstname */
	@observable
	@attribute()
	@CRUD({
		name: "First Name",
		displayType: "textfield",
		headerColumn: true,
		searchable: true,
		searchFunction: "like",
		searchTransform: AttrUtils.standardiseString,
		order: 4,
	})
	public forename: string;
	// % protected region % [Modify props to the crud options here for attribute 'Forename'] end

	// % protected region % [Modify props to the crud options here for attribute 'Middlename'] on begin
	/**
	 * Middlename
	 */
	@observable
	@attribute()
	@CRUD({
		name: "Middle Name",
		displayType: "textfield",
		order: 5,
		headerColumn: true,
		searchable: true,
		searchFunction: "like",
		searchTransform: AttrUtils.standardiseString,
	})
	public middlename: string;
	// % protected region % [Modify props to the crud options here for attribute 'Middlename'] end

	// % protected region % [Modify props to the crud options here for attribute 'Surname'] on begin
	/* Lastname */
	@observable
	@attribute()
	@CRUD({
		name: "Last Name",
		displayType: "textfield",
		headerColumn: true,
		searchable: true,
		searchFunction: "like",
		searchTransform: AttrUtils.standardiseString,
		order: 6,
	})
	public surname: string;
	// % protected region % [Modify props to the crud options here for attribute 'Surname'] end

	// % protected region % [Modify props to the crud options here for attribute 'Gender'] on begin
	/* Gender */
	@observable
	@attribute()
	@CRUD({
		name: "Gender",
		displayType: "enum-combobox",
		headerColumn: true,
		searchable: true,
		searchFunction: "equal",
		searchTransform: (attr: string) => {
			return AttrUtils.standardiseEnum(attr, Enums.genderOptions);
		},
		enumResolveFunction: makeEnumFetchFunction(Enums.genderOptions),
		displayFunction: (attribute: Enums.gender) => Enums.genderOptions[attribute],
		order: 7,
	})
	public gender: Enums.gender | null;
	// % protected region % [Modify props to the crud options here for attribute 'Gender'] end

	// % protected region % [Modify props to the crud options here for attribute 'Date of Birth'] on begin
	/* DoB */
	@observable
	@attribute()
	@CRUD({
		name: "Date of Birth",
		displayType: "datepicker",
		headerColumn: true,
		searchable: true,
		searchFunction: "equal",
		searchTransform: AttrUtils.standardiseDate,
		order: 8,
	})
	public dateOfBirth: Date;
	// % protected region % [Modify props to the crud options here for attribute 'Date of Birth'] end

	// % protected region % [Modify props to the crud options here for attribute 'Accepted Privacy Policy'] off begin
	/**
	 * Has the user accepted the provacy policy yet or not
	 */
	@observable
	@attribute()
	@CRUD({
		name: 'Accepted Privacy Policy',
		displayType: 'checkbox',
		order: 90,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public acceptedPrivacyPolicy: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'Accepted Privacy Policy'] end

	// % protected region % [Modify props to the crud options here for attribute 'Phone'] off begin
	/**
	 * Phone Number
	 */
	@observable
	@attribute()
	@CRUD({
		name: 'Phone',
		displayType: 'textfield',
		order: 100,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public phone: string;
	// % protected region % [Modify props to the crud options here for attribute 'Phone'] end

	// % protected region % [Modify props to the crud options here for attribute 'Custom Fields'] off begin
	/**
	 * Custom fields for the patient as a stringified object 
	 */
	@observable
	@attribute()
	@CRUD({
		name: 'Custom Fields',
		displayType: 'textfield',
		order: 110,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public customFields: string;
	// % protected region % [Modify props to the crud options here for attribute 'Custom Fields'] end

	// % protected region % [Modify props to the crud options here for attribute 'Status'] off begin
	/**
	 * Status for the user
	 */
	@observable
	@attribute()
	@CRUD({
		name: 'Status',
		displayType: 'enum-combobox',
		order: 120,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: (attr: string) => {
			return AttrUtils.standardiseEnum(attr, Enums.statusOptions);
		},
		enumResolveFunction: makeEnumFetchFunction(Enums.statusOptions),
		displayFunction: (attribute: Enums.status) => Enums.statusOptions[attribute],
	})
	public status: Enums.status;
	// % protected region % [Modify props to the crud options here for attribute 'Status'] end

	// % protected region % [Modify props to the crud options here for attribute 'Last Login'] off begin
	/**
	 * Last login details
	 */
	@observable
	@attribute()
	@CRUD({
		name: 'Last Login',
		displayType: 'datetimepicker',
		order: 130,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseDate,
	})
	public lastLogin: Date;
	// % protected region % [Modify props to the crud options here for attribute 'Last Login'] end

	// % protected region % [Modify props to the crud options here for attribute 'Onboarded'] off begin
	/**
	 * Whether the patient has finished the onboarding process that demonstrates core application features,
	 */
	@observable
	@attribute()
	@CRUD({
		name: 'Onboarded',
		displayType: 'checkbox',
		order: 140,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public onboarded: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'Onboarded'] end

	// % protected region % [Modify props to the crud options here for attribute 'Activated Date'] off begin
	/**
	 * The date that the patient first logged into the mobile or web application.
	 */
	@observable
	@attribute()
	@CRUD({
		name: 'Activated Date',
		displayType: 'datepicker',
		order: 150,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseDate,
	})
	public activatedDate: Date;
	// % protected region % [Modify props to the crud options here for attribute 'Activated Date'] end

	@observable
	@attribute({isReference: true})
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Area Of Life Ratings'] on begin
		name: "Area Of Life Ratingss",
		displayType: 'hidden', //reference-multicombobox',
		order: 160,
		referenceTypeFunc: () => Models.AreaOfLifeRatingsEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'areaOfLifeRatingss',
			oppositeEntity: () => Models.AreaOfLifeRatingsEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Area Of Life Ratings'] end
	})
	public areaOfLifeRatingss: Models.AreaOfLifeRatingsEntity[] = [];

	@observable
	@attribute({isReference: true})
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Demo Admins'] on begin
		name: "Demo Adminss",
		displayType: 'hidden', // 'reference-multicombobox',
		order: 250,
		referenceTypeFunc: () => Models.AdminEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'demoAdminss',
			oppositeEntity: () => Models.AdminEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Demo Admins'] end
	})
	public demoAdminss: Models.AdminEntity[] = [];

	@observable
	@attribute({isReference: true})
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Demo Clinicians'] on begin
		name: "Demo Clinicianss",
		displayType: 'hidden',
		order: 260,
		referenceTypeFunc: () => Models.ClinicianEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'demoClinicianss',
			oppositeEntity: () => Models.ClinicianEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Demo Clinicians'] end
	})
	public demoClinicianss: Models.ClinicianEntity[] = [];

	/**
	 * Goals for the patients
	 */
	@observable
	@attribute({isReference: true})
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Goals'] on begin
		name: "Goals",
		displayType: "reference-multicombobox",
		referenceTypeFunc: () => Models.GoalsEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: "goalss",
			oppositeEntity: () => Models.GoalsEntity,
		}),
		order: 70,
		// % protected region % [Modify props to the crud options here for reference 'Goals'] end
	})
	public goalss: Models.GoalsEntity[] = [];

	/**
	 * Social media links for a patient
	 */
	@observable
	@attribute({isReference: true})
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Patient Social Media Links'] off begin
		name: "Patient Social Media Linkss",
		displayType: 'reference-multicombobox',
		order: 200,
		referenceTypeFunc: () => Models.SocialMediaLinkEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'patientSocialMediaLinkss',
			oppositeEntity: () => Models.SocialMediaLinkEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Patient Social Media Links'] end
	})
	public patientSocialMediaLinkss: Models.SocialMediaLinkEntity[] = [];

	/**
	 * ToDo
	 */
	@observable
	@attribute({isReference: true})
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'ToDos'] off begin
		name: "ToDoss",
		displayType: 'reference-multicombobox',
		order: 210,
		referenceTypeFunc: () => Models.TodoEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'todoss',
			oppositeEntity: () => Models.TodoEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'ToDos'] end
	})
	public todoss: Models.TodoEntity[] = [];

	@observable
	@attribute({isReference: true})
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Logged Event'] off begin
		name: "Logged Events",
		displayType: 'hidden',
		order: 220,
		referenceTypeFunc: () => Models.PatientTimelineEventsEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'loggedEvents',
			oppositeEntity: () => Models.PatientTimelineEventsEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Logged Event'] end
	})
	public loggedEvents: Models.PatientTimelineEventsEntity[] = [];

	/**
	 * A Patient's notification configuration settings
	 */
	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Notifications Settings'] on begin
		name: 'Notifications Settings',
		// Dont show notification association in CRUD. Admin should not be setting the notification token.
		displayType: 'hidden', //reference-combobox',
		order: 230,
		referenceTypeFunc: () => Models.NotificationsSettingsEntity,
		// % protected region % [Modify props to the crud options here for reference 'Notifications Settings'] end
	})
	public notificationsSettingsId?: string;
	@observable
	@attribute({isReference: true})
	public notificationsSettings: Models.NotificationsSettingsEntity;

	/**
	 * Activities favourited by patients
	 */
	@observable
	@attribute({isReference: true})
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'FavouriteActivities'] on begin
		name: 'FavouriteActivities',
		displayType: 'hidden', //reference-multicombobox',
		order: 240,
		isJoinEntity: true,
		referenceTypeFunc: () => Models.FavouritedbypatientsFavouriteactivities,
		optionEqualFunc: makeJoinEqualsFunc('favouriteactivitiesId'),
		referenceResolveFunction: makeFetchManyToManyFunc({
			entityName: 'patientEntity',
			oppositeEntityName: 'activitiesEntity',
			relationName: 'favouritedbypatients',
			relationOppositeName: 'favouriteactivities',
			entity: () => Models.PatientEntity,
			joinEntity: () => Models.FavouritedbypatientsFavouriteactivities,
			oppositeEntity: () => Models.ActivitiesEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'FavouriteActivities'] end
	})
	public favouriteactivitiess: Models.FavouritedbypatientsFavouriteactivities[] = [];

	/**
	 * Articles favourited by patients
	 */
	@observable
	@attribute({isReference: true})
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'FavouriteArticles'] on begin
		name: 'FavouriteArticles',
		displayType: 'hidden',
		order: 250,
		isJoinEntity: true,
		referenceTypeFunc: () => Models.FavouritedbypatientsFavouritearticles,
		optionEqualFunc: makeJoinEqualsFunc('favouritearticlesId'),
		referenceResolveFunction: makeFetchManyToManyFunc({
			entityName: 'patientEntity',
			oppositeEntityName: 'articlesEntity',
			relationName: 'favouritedbypatients',
			relationOppositeName: 'favouritearticles',
			entity: () => Models.PatientEntity,
			joinEntity: () => Models.FavouritedbypatientsFavouritearticles,
			oppositeEntity: () => Models.ArticlesEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'FavouriteArticles'] end
	})
	public favouritearticless: Models.FavouritedbypatientsFavouritearticles[] = [];

	@observable
	@attribute({isReference: true})
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'RatingTemplates'] off begin
		name: 'RatingTemplates',
		displayType: 'reference-multicombobox',
		order: 260,
		isJoinEntity: true,
		referenceTypeFunc: () => Models.RatingtemplatesPatients,
		optionEqualFunc: makeJoinEqualsFunc('ratingtemplatesId'),
		referenceResolveFunction: makeFetchManyToManyFunc({
			entityName: 'patientEntity',
			oppositeEntityName: 'ratingtemplateEntity',
			relationName: 'patients',
			relationOppositeName: 'ratingtemplates',
			entity: () => Models.PatientEntity,
			joinEntity: () => Models.RatingtemplatesPatients,
			oppositeEntity: () => Models.RatingtemplateEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'RatingTemplates'] end
	})
	public ratingtemplatess: Models.RatingtemplatesPatients[] = [];

	/**
	 * Ratings the a user will complete
	 */
	@observable
	@attribute({isReference: true})
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Ratings'] on begin
		name: "Ratings",
		displayType: "reference-multicombobox",
		isJoinEntity: true,
		referenceTypeFunc: () => Models.UsersRatings,
		optionEqualFunc: makeJoinEqualsFunc("ratingsId"),
		referenceResolveFunction: makeFetchManyToManyFunc({
			entityName: "patientEntity",
			oppositeEntityName: "ratingpersonalEntity",
			relationName: "users",
			relationOppositeName: "ratings",
			entity: () => Models.PatientEntity,
			joinEntity: () => Models.UsersRatings,
			oppositeEntity: () => Models.RatingpersonalEntity,
		}),
		order: 120,
		// % protected region % [Modify props to the crud options here for reference 'Ratings'] end
	})
	public ratingss: Models.UsersRatings[] = [];

	/**
	 * Users the carer takes care of
	 */
	@observable
	@attribute({isReference: true})
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Carers'] on begin
		name: "Carers",
		displayType: 'hidden', //reference-multicombobox',
		isJoinEntity: true,
		referenceTypeFunc: () => Models.CarersUsers,
		optionEqualFunc: makeJoinEqualsFunc("carersId"),
		referenceResolveFunction: makeFetchManyToManyFunc({
			entityName: "patientEntity",
			oppositeEntityName: "carerEntity",
			relationName: "users",
			relationOppositeName: "carers",
			entity: () => Models.PatientEntity,
			joinEntity: () => Models.CarersUsers,
			oppositeEntity: () => Models.CarerEntity,
		}),
		order: 100,
		// % protected region % [Modify props to the crud options here for reference 'Carers'] end
	})
	public carerss: Models.CarersUsers[] = [];

	@observable
	@attribute({isReference: true})
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Clinicians'] on begin
		name: "Clinicians",
		displayType: "reference-multicombobox",
		isJoinEntity: true,
		referenceTypeFunc: () => Models.CliniciansUsers,
		optionEqualFunc: makeJoinEqualsFunc("cliniciansId"),
		referenceResolveFunction: makeFetchManyToManyFunc({
			entityName: "patientEntity",
			oppositeEntityName: "clinicianEntity",
			relationName: "users",
			relationOppositeName: "clinicians",
			entity: () => Models.PatientEntity,
			joinEntity: () => Models.CliniciansUsers,
			oppositeEntity: () => Models.ClinicianEntity,
		}),
		order: 105,
		// % protected region % [Modify props to the crud options here for reference 'Clinicians'] end
	})
	public clinicianss: Models.CliniciansUsers[] = [];

	/**
	 * UserTags
	 */
	@observable
	@attribute({isReference: true})
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Tags'] on begin
		name: "Tags",
		displayType: "reference-multicombobox",
		isJoinEntity: true,
		referenceTypeFunc: () => Models.TagsUsertags,
		optionEqualFunc: makeJoinEqualsFunc("tagsId"),
		referenceResolveFunction: makeFetchManyToManyFunc({
			entityName: "patientEntity",
			oppositeEntityName: "tagEntity",
			relationName: "usertags",
			relationOppositeName: "tags",
			entity: () => Models.PatientEntity,
			joinEntity: () => Models.TagsUsertags,
			oppositeEntity: () => Models.TagEntity,
		}),
		order: 110,
		// % protected region % [Modify props to the crud options here for reference 'Tags'] end
	})
	public tagss: Models.TagsUsertags[] = [];

	// % protected region % [Add any custom attributes to the model here] on begin
	@observable
	public goalsCreated: number = 0;

	@observable
	public goalsCompleted: number = 0;

	@observable
	public goalsNotComplete: number = 0;

	@observable
	public goalsCompletedPercentage: number = 0;

	@observable
	public todosAssignedToUser: number = 0;

	@observable
	public todosCreatedByUser: number = 0;

	@observable
	public totalTodos: number = 0;

	@observable
	public todosCompletedPercentage: number = 0;

	@observable
	public todosNotCompleted: number = 0;
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<IPatientEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<IPatientEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.email !== undefined) {
				this.email = attributes.email;
			}
			if (attributes.forename !== undefined) {
				this.forename = attributes.forename;
			}
			if (attributes.middlename !== undefined) {
				this.middlename = attributes.middlename;
			}
			if (attributes.surname !== undefined) {
				this.surname = attributes.surname;
			}
			if (attributes.gender !== undefined) {
				this.gender = attributes.gender;
			}
			if (attributes.dateOfBirth !== undefined) {
				if (attributes.dateOfBirth === null) {
					this.dateOfBirth = attributes.dateOfBirth;
				} else {
					this.dateOfBirth = moment(attributes.dateOfBirth).toDate();
				}
			}
			if (attributes.acceptedPrivacyPolicy !== undefined) {
				this.acceptedPrivacyPolicy = attributes.acceptedPrivacyPolicy;
			}
			if (attributes.phone !== undefined) {
				this.phone = attributes.phone;
			}
			if (attributes.customFields !== undefined) {
				this.customFields = attributes.customFields;
			}
			if (attributes.status !== undefined) {
				this.status = attributes.status;
			}
			if (attributes.lastLogin !== undefined) {
				if (attributes.lastLogin === null) {
					this.lastLogin = attributes.lastLogin;
				} else {
					this.lastLogin = moment(attributes.lastLogin).toDate();
				}
			}
			if (attributes.onboarded !== undefined) {
				this.onboarded = attributes.onboarded;
			}
			if (attributes.activatedDate !== undefined) {
				if (attributes.activatedDate === null) {
					this.activatedDate = attributes.activatedDate;
				} else {
					this.activatedDate = moment(attributes.activatedDate).toDate();
				}
			}
			if (attributes.areaOfLifeRatingss !== undefined && Array.isArray(attributes.areaOfLifeRatingss)) {
				for (const model of attributes.areaOfLifeRatingss) {
					if (model instanceof Models.AreaOfLifeRatingsEntity) {
						this.areaOfLifeRatingss.push(model);
					} else {
						this.areaOfLifeRatingss.push(new Models.AreaOfLifeRatingsEntity(model));
					}
				}
			}
			if (attributes.demoAdminss !== undefined && Array.isArray(attributes.demoAdminss)) {
				for (const model of attributes.demoAdminss) {
					if (model instanceof Models.AdminEntity) {
						this.demoAdminss.push(model);
					} else {
						this.demoAdminss.push(new Models.AdminEntity(model));
					}
				}
			}
			if (attributes.demoClinicianss !== undefined && Array.isArray(attributes.demoClinicianss)) {
				for (const model of attributes.demoClinicianss) {
					if (model instanceof Models.ClinicianEntity) {
						this.demoClinicianss.push(model);
					} else {
						this.demoClinicianss.push(new Models.ClinicianEntity(model));
					}
				}
			}
			if (attributes.goalss !== undefined && Array.isArray(attributes.goalss)) {
				for (const model of attributes.goalss) {
					if (model instanceof Models.GoalsEntity) {
						this.goalss.push(model);
					} else {
						this.goalss.push(new Models.GoalsEntity(model));
					}
				}
			}
			if (attributes.patientSocialMediaLinkss !== undefined && Array.isArray(attributes.patientSocialMediaLinkss)) {
				for (const model of attributes.patientSocialMediaLinkss) {
					if (model instanceof Models.SocialMediaLinkEntity) {
						this.patientSocialMediaLinkss.push(model);
					} else {
						this.patientSocialMediaLinkss.push(new Models.SocialMediaLinkEntity(model));
					}
				}
			}
			if (attributes.todoss !== undefined && Array.isArray(attributes.todoss)) {
				for (const model of attributes.todoss) {
					if (model instanceof Models.TodoEntity) {
						this.todoss.push(model);
					} else {
						this.todoss.push(new Models.TodoEntity(model));
					}
				}
			}
			if (attributes.loggedEvents !== undefined && Array.isArray(attributes.loggedEvents)) {
				for (const model of attributes.loggedEvents) {
					if (model instanceof Models.PatientTimelineEventsEntity) {
						this.loggedEvents.push(model);
					} else {
						this.loggedEvents.push(new Models.PatientTimelineEventsEntity(model));
					}
				}
			}
			if (attributes.notificationsSettings !== undefined) {
				if (attributes.notificationsSettings === null) {
					this.notificationsSettings = attributes.notificationsSettings;
				} else {
					if (attributes.notificationsSettings instanceof Models.NotificationsSettingsEntity) {
						this.notificationsSettings = attributes.notificationsSettings;
						this.notificationsSettingsId = attributes.notificationsSettings.id;
					} else {
						this.notificationsSettings = new Models.NotificationsSettingsEntity(attributes.notificationsSettings);
						this.notificationsSettingsId = this.notificationsSettings.id;
					}
				}
			} else if (attributes.notificationsSettingsId !== undefined) {
				this.notificationsSettingsId = attributes.notificationsSettingsId;
			}
			if (attributes.favouriteactivitiess !== undefined && Array.isArray(attributes.favouriteactivitiess)) {
				for (const model of attributes.favouriteactivitiess) {
					if (model instanceof Models.FavouritedbypatientsFavouriteactivities) {
						this.favouriteactivitiess.push(model);
					} else {
						this.favouriteactivitiess.push(new Models.FavouritedbypatientsFavouriteactivities(model));
					}
				}
			}
			if (attributes.favouritearticless !== undefined && Array.isArray(attributes.favouritearticless)) {
				for (const model of attributes.favouritearticless) {
					if (model instanceof Models.FavouritedbypatientsFavouritearticles) {
						this.favouritearticless.push(model);
					} else {
						this.favouritearticless.push(new Models.FavouritedbypatientsFavouritearticles(model));
					}
				}
			}
			if (attributes.ratingtemplatess !== undefined && Array.isArray(attributes.ratingtemplatess)) {
				for (const model of attributes.ratingtemplatess) {
					if (model instanceof Models.RatingtemplatesPatients) {
						this.ratingtemplatess.push(model);
					} else {
						this.ratingtemplatess.push(new Models.RatingtemplatesPatients(model));
					}
				}
			}
			if (attributes.ratingss !== undefined && Array.isArray(attributes.ratingss)) {
				for (const model of attributes.ratingss) {
					if (model instanceof Models.UsersRatings) {
						this.ratingss.push(model);
					} else {
						this.ratingss.push(new Models.UsersRatings(model));
					}
				}
			}
			if (attributes.carerss !== undefined && Array.isArray(attributes.carerss)) {
				for (const model of attributes.carerss) {
					if (model instanceof Models.CarersUsers) {
						this.carerss.push(model);
					} else {
						this.carerss.push(new Models.CarersUsers(model));
					}
				}
			}
			if (attributes.clinicianss !== undefined && Array.isArray(attributes.clinicianss)) {
				for (const model of attributes.clinicianss) {
					if (model instanceof Models.CliniciansUsers) {
						this.clinicianss.push(model);
					} else {
						this.clinicianss.push(new Models.CliniciansUsers(model));
					}
				}
			}
			if (attributes.tagss !== undefined && Array.isArray(attributes.tagss)) {
				for (const model of attributes.tagss) {
					if (model instanceof Models.TagsUsertags) {
						this.tagss.push(model);
					} else {
						this.tagss.push(new Models.TagsUsertags(model));
					}
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] on begin
			if (attributes.goalsCompleted) {
				this.goalsCompleted = attributes.goalsCompleted;
			}
			if (attributes.goalsCreated) {
				this.goalsCreated = attributes.goalsCreated;
			}
			if (attributes.goalsNotComplete) {
				this.goalsNotComplete = attributes.goalsNotComplete;
			}
			if (attributes.goalsCompletedPercentage) {
				this.goalsCompletedPercentage = attributes.goalsCompletedPercentage;
			}
			if (attributes.todosAssignedToUser) {
				this.todosAssignedToUser = attributes.todosAssignedToUser;
			}
			if (attributes.todosCreatedByUser) {
				this.todosCreatedByUser = attributes.todosCreatedByUser;
			}
			if (attributes.totalTodos) {
				this.totalTodos = attributes.totalTodos;
			}
			if (attributes.todosNotCompleted) {
				this.todosNotCompleted = attributes.todosNotCompleted;
			}
			if (attributes.todosCompletedPercentage) {
				this.todosCompletedPercentage = attributes.todosCompletedPercentage;
			}
			// NOTE - Override  assigning lastLogin in assignAttributes as it is being created as a local time because
			// attributes.lastLogin does not have the 'Z' on the string that identifies it as a UTC date string.
			if (attributes.lastLogin !== undefined) {
				if (attributes.lastLogin === null) {
					this.lastLogin = attributes.lastLogin;
				} else {
					this.lastLogin = moment.utc(attributes.lastLogin).toDate();
				}
			}
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] off begin
	public defaultExpands = `
		favouriteactivitiess {
			${Models.FavouritedbypatientsFavouriteactivities.getAttributes().join('\n')}
			favouriteactivities {
				${Models.ActivitiesEntity.getAttributes().join('\n')}
				${Models.ActivitiesEntity.getFiles().map(f => f.name).join('\n')}
			}
		}
		favouritearticless {
			${Models.FavouritedbypatientsFavouritearticles.getAttributes().join('\n')}
			favouritearticles {
				${Models.ArticlesEntity.getAttributes().join('\n')}
				${Models.ArticlesEntity.getFiles().map(f => f.name).join('\n')}
			}
		}
		ratingss {
			${Models.UsersRatings.getAttributes().join('\n')}
			ratings {
				${Models.RatingpersonalEntity.getAttributes().join('\n')}
				${Models.RatingpersonalEntity.getFiles().map(f => f.name).join('\n')}
			}
		}
		ratingtemplatess {
			${Models.RatingtemplatesPatients.getAttributes().join('\n')}
			ratingtemplates {
				${Models.RatingtemplateEntity.getAttributes().join('\n')}
				${Models.RatingtemplateEntity.getFiles().map(f => f.name).join('\n')}
			}
		}
		carerss {
			${Models.CarersUsers.getAttributes().join('\n')}
			carers {
				${Models.CarerEntity.getAttributes().join('\n')}
			}
		}
		clinicianss {
			${Models.CliniciansUsers.getAttributes().join('\n')}
			clinicians {
				${Models.ClinicianEntity.getAttributes().join('\n')}
			}
		}
		tagss {
			${Models.TagsUsertags.getAttributes().join('\n')}
			tags {
				${Models.TagEntity.getAttributes().join('\n')}
			}
		}
		areaOfLifeRatingss {
			${Models.AreaOfLifeRatingsEntity.getAttributes().join('\n')}
		}
		demoAdminss {
			${Models.AdminEntity.getAttributes().join('\n')}
		}
		demoClinicianss {
			${Models.ClinicianEntity.getAttributes().join('\n')}
		}
		goalss {
			${Models.GoalsEntity.getAttributes().join('\n')}
		}
		patientSocialMediaLinkss {
			${Models.SocialMediaLinkEntity.getAttributes().join('\n')}
		}
		todoss {
			${Models.TodoEntity.getAttributes().join('\n')}
		}
		loggedEvents {
			${Models.PatientTimelineEventsEntity.getAttributes().join('\n')}
		}
		notificationsSettings {
			${Models.NotificationsSettingsEntity.getAttributes().join('\n')}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] on begin
	//dont add backl the favourited etc to big m2m
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath = {
			ratingss: {},
			ratingtemplatess: {},
			tagss: {},
			carerss: {},
			clinicianss: {},
			areaOfLifeRatingss: {},
			demoAdminss: {},
			demoClinicianss: {},
			goalss: {},
			patientSocialMediaLinkss: {},
			todoss: {},
		};

		if (formMode === 'create') {
			relationPath['password'] = {};

			if (this.password !== this._confirmPassword) {
				throw "Password fields do not match";
			}
		}
		return this.save(
			relationPath,
			{
				graphQlInputType: formMode === 'create'
					? `[${this.getModelName()}CreateInput]`
					: `[${this.getModelName()}Input]`,
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
							'areaOfLifeRatingss',
							'demoAdminss',
							'demoClinicianss',
							'goalss',
							'patientSocialMediaLinkss',
							'todoss',
							'notificationsSettings',
							'ratingtemplatess',
							'ratingss',
							'tagss',
							'carerss',
							'clinicianss',
						]
					},
				],
			}
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] off begin
		return this.email;
		// % protected region % [Customise the display name for this entity] end
	}

	/**
	 * Gets the timeline event entity type for this form.
	 */
	public getTimelineEventEntity = () => {
		// % protected region % [Modify the getTimelineEventEntity here] off begin
		return Models.PatientTimelineEventsEntity;
		// % protected region % [Modify the getTimelineEventEntity here] end
	}


	// % protected region % [Add any further custom model features here] on begin
	/**
	 * Deletes the model from the server
	 */
	public async delete() {
		let ratingpersonalSubmissionIds: String[] = []; 
		await store.apolloClient.query({
			query: gql`
				query ratingpersonalSubmissionEntity {
					ratingpersonalSubmissionEntitys {
						id
						owner
					}
				}
			`
		}).then((d) => {
			d.data.ratingpersonalSubmissionEntitys.forEach((submission : any)=> {
				if (submission.owner == this.id) {
					ratingpersonalSubmissionIds.push(submission.id); 
				}
			});
		}).catch(() => alert("Could not retrieve content"));
		// delete ratingpersonalsubmission ids
		
		await store.apolloClient
			.mutate({
				mutation: gql`
					mutation delete($${'ratingpersonalSubmissionEntityIds'}:[ID]) {
						${'deleteRatingpersonalSubmissionEntity'}(${'ratingpersonalSubmissionEntityIds'}: $${'ratingpersonalSubmissionEntityIds'}) {
							id
						}
					}`,
				variables: {'ratingpersonalSubmissionEntityIds': ratingpersonalSubmissionIds}
			})
			.catch((response: ErrorResponse) => {
				const errorMessage = getTheNetworkError(response);
				throw errorMessage;
			});

		let ratingtemplateSubmissionIds: String[] = []; 
		await store.apolloClient.query({
			query: gql`
				query ratingtemplateSubmissionEntity {
					ratingtemplateSubmissionEntitys {
						id
						owner
					}
				}
			`
		}).then((d) => {
			d.data.ratingtemplateSubmissionEntitys.forEach((submission : any)=> {
				if (submission.owner == this.id) {
					ratingtemplateSubmissionIds.push(submission.id); 
				}
			});
		}).catch(() => alert("Could not retrieve content"));
		// delete ratingtemplatesubmission ids
		await store.apolloClient
			.mutate({
				mutation: gql`
					mutation delete($${'ratingtemplateSubmissionEntityIds'}:[ID]) {
						${'deleteRatingtemplateSubmissionEntity'}(${'ratingtemplateSubmissionEntityIds'}: $${'ratingtemplateSubmissionEntityIds'}) {
							id
						}
					}`,
				variables: {'ratingtemplateSubmissionEntityIds': ratingtemplateSubmissionIds}
			})
			.catch((response: ErrorResponse) => {
				const errorMessage = getTheNetworkError(response);
				throw errorMessage;
			});

		let activitiesSubmissionIds: String[] = []; 
		await store.apolloClient.query({
			query: gql`
				query activitiesSubmissionEntity {
					activitiesSubmissionEntitys {
						id
						owner
					}
				}
			`
		}).then((d) => {
			d.data.activitiesSubmissionEntitys.forEach((submission : any)=> {
				if (submission.owner == this.id) {
					activitiesSubmissionIds.push(submission.id); 
				}
			});
		}).catch(() => alert("Could not retrieve content"));
		// delete activity submission
		await store.apolloClient
			.mutate({
				mutation: gql`
					mutation delete($${'activitiesSubmissionEntityIds'}:[ID]) {
						${'deleteActivitiesSubmissionEntity'}(${'activitiesSubmissionEntityIds'}: $${'activitiesSubmissionEntityIds'}) {
							id
						}
					}`,
				variables: {'activitiesSubmissionEntityIds': activitiesSubmissionIds}
			})
			.catch((response: ErrorResponse) => {
				const errorMessage = getTheNetworkError(response);
				throw errorMessage;
			});

		await super.delete();
	}
	
	@computed
	get fullname() {
		return `${this.forename} ${this.surname}`;
	}

	public hasAFullAreaOfLifeRating = (): boolean => {
		return this.areaOfLifeRatingss.filter(a => a.fullRating).length > 0;
	}

	/**
	 * Instance of Area Of Life Rating with all the most recent values for each area.
	 * Need to loop through both full and non full ratings.
	 */
	public getLatestAreaofLifeValues() {
		const areaOfLifeRatings = new AreaOfLifeRatingEntity({
			id: '00000000-0000-0000-0000-000000000000',
			patientId: this.id,
			fullRating: true,
		});

		// Sorted area of life ratings with most recent rating indexed first.
		const sortedAreaOfLifeRatings = this.areaOfLifeRatingss.sort((a, b) => {
			const bValue = b.created ? b.created.getTime() : 0;
			const aValue = a.created ? a.created.getTime() : 0;
			return bValue - aValue;
		});

		// Loop through each area and find most recent rating value OR its N/A
		Object.keys(lifeAreaOptions).map((keyAol) => {
			const area = keyAol as lifeArea;

			// Get first value that is a number;
			const firstAreaOfLifeRatingWithValidValue = sortedAreaOfLifeRatings.find(aol => {
				const areaValue = aol.getLifeAreaRating(keyAol as Enums.lifeArea);
				return !isNaN(areaValue);
			});

			// Set value if the patient has actually set a value for that area before.
			if (firstAreaOfLifeRatingWithValidValue) {
				const value = firstAreaOfLifeRatingWithValidValue.getLifeAreaRating(area);
				areaOfLifeRatings.setLifeAreaRating(area, value);
			}
		});

		return areaOfLifeRatings;
	}

	/**
	 * Return whether this patient is a demo patient account that belongs to an admin.
	 */
	public isDemoPatientOfAdmin () {
		return this.demoAdminss != null && this.demoAdminss.length > 0;
	}

	/**
	 * Return whether this patient is a demo patient account that belongs to a clinician.
	 */
	public isDemoPatientOfClinician () {
		return this.demoClinicianss != null && this.demoClinicianss.length > 0;
	}

	/**
	 * Return whether this patient is a demo patient account of an admin or a clinician
	 */
	public isDemoAccount = () => {
		return this.isDemoPatientOfAdmin() || this.isDemoPatientOfClinician();
	}
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [ createdAttr, modifiedAttr ] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(PatientEntity.prototype, 'created');
CRUD(modifiedAttr)(PatientEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
