import React from 'react';
import { ModelCollection } from '../ModelCollection/ModelCollection';
import { PatientEntity, TagEntity } from '../../../Models/Entities';
import { ICollectionHeaderProps } from '../Collection/CollectionHeaders';
import { ICollectionItemActionProps } from '../Collection/Collection';
import { Link } from 'react-router-dom';
import { SERVER_URL } from '../../../Constants';
import { Button, Colors, Display, Sizes } from '../Button/Button';
import { action, observable, runInAction } from 'mobx';
import { MultiCombobox } from '../Combobox/MultiCombobox';
import { ApiQueryParams, IOrderByCondition } from '../ModelCollection/ModelAPIQuery';
import { observer } from 'mobx-react';
import { IFilter } from '../Collection/CollectionFilterPanel';
import { DatePicker } from '../DatePicker/DatePicker';
import { ButtonGroup } from '../Button/ButtonGroup';
import { Checkbox } from '../Checkbox/Checkbox';
import { DashboardTableFilterOptions, DashboardTableOptions, IDashboardProps } from './DashboardCommon';
import axios from 'axios';
import alert from '../../../Util/ToastifyUtils';
import moment from 'moment';

export class PatientGoalsTableOptions extends DashboardTableOptions {

	@observable
	orderBy: IOrderByCondition<PatientEntity> = {
		path: 'forename', descending: true
	}

	@observable
	public fromGoalsCreatedDate: Date | null = null;

	@observable
	public toGoalsCreatedDate: Date | null = null;

	@observable
	public noGoalsUsers: boolean | false;

	@action
	setFilterOptions = (
		selectedTagIds: string[], selecetedPatientIds: string[],
		selectedGoalsFromCreatedDate: Date | null,
		selectedGoalsToCreatedDate: Date | null,
		selectedNoGoalsUsers: boolean | false
	) => {

		let parsedTagIds: string[] = [];
		selectedTagIds?.forEach(id => parsedTagIds.push(id));
		this.tagIds = parsedTagIds;

		let parsedPatientIds: string[] = [];
		selecetedPatientIds?.forEach(id => parsedPatientIds.push(id));
		this.patientIds = parsedPatientIds;

		const MS_PER_MINUTE = 60000;

		if (selectedGoalsFromCreatedDate != null){
			// serverside stopped casting timezones after the bot upgrade so this is no longer needed - leaving this here cos timezones has been a cluster of a mess. .
			// selectedGoalsFromCreatedDate = new Date(selectedGoalsFromCreatedDate.getTime() + (selectedGoalsFromCreatedDate.getTimezoneOffset() * MS_PER_MINUTE))
		}
		if (selectedGoalsToCreatedDate != null){
			selectedGoalsToCreatedDate.setHours(23);
			selectedGoalsToCreatedDate.setMinutes(59);
			selectedGoalsToCreatedDate.setSeconds(59);

			// serverside stopped casting timezones after the bot upgrade so this is no longer needed - leaving this here cos timezones has been a cluster of a mess. .
			// selectedGoalsToCreatedDate = new Date(selectedGoalsToCreatedDate.getTime() + (selectedGoalsToCreatedDate.getTimezoneOffset() * MS_PER_MINUTE))
			// selectedGoalsToCreatedDate = moment(selectedGoalsToCreatedDate).add(24, 'hours').toDate();
		}


		this.fromGoalsCreatedDate = selectedGoalsFromCreatedDate;
		this.toGoalsCreatedDate = selectedGoalsToCreatedDate;
		this.noGoalsUsers = selectedNoGoalsUsers;
	}
}

/**
 * Class for storing the selected data from the filter data table.
 * These are not passed into the table until we click `run report`.
 */
export class PatientGoalsTableFilterOptions extends DashboardTableFilterOptions {
	@observable
	public selectedGoalsFromCreatedDate: Date | null;

	@observable
	public selectedGoalsToCreatedDate: Date | null;

	@observable
	public selectedNoGoalsUsers: boolean | false;

	constructor() {
		super();
	}
}

@observer
export default class DashboardGoals extends React.Component<IDashboardProps> {

	@observable
	public filterOptions: PatientGoalsTableFilterOptions = new PatientGoalsTableFilterOptions();

	@observable
	public tableOptions: PatientGoalsTableOptions = new PatientGoalsTableOptions();


	constructor(props: IDashboardProps) {
		super(props);
	}

	@action
	setFilterOptionTagOptions() {
		this.filterOptions.setTagOptionsAndIncludeAll(this.props.tags);
		this.filterOptions.setPatientOptionsAndIncludeAll(this.props.patients);

	}

	componentDidMount() {
		this.setFilterOptionTagOptions();
	}
	render() {
		return (
			<div>
				<div className="dashboard-goal-todo-row">
					{this.renderPatientGoalsTableFilterOptions()}
				</div>
				{this.renderPatientGoalsTable()}
			</div>
		);
	}
	renderPatientGoalsTableFilterOptions = () => {
		return (
			<div className="dashboard-grid-item">
				<h1>Filter data</h1>
				<div className="dashboard-goal-todo-filter">
					<p>Filter, view, and export the full table of data below.</p>
					<div className='dashboard-report'>
						<ButtonGroup>
							<Button
								className="dashboard-button__run-report"
								display={Display.Solid}
								colors={Colors.Black}
								onClick={() => this.handleOnClickRunReport()}
							>
								Run Report
						</Button>
							<Button
								className="dashboard-button__run-report"
								display={Display.Solid}
								colors={Colors.Black}
								onClick={() => this.handleOnClickExportReport()}
							>
								Export
						</Button>
						</ButtonGroup>
					</div>
				</div>
				<div className='dashboard-goal-todo-table-filter__row'>
					<MultiCombobox
						className='dashboard-goal-todo-table-filter__multicombobox'
						placeholder="Select tags"
						model={this.filterOptions}
						modelProperty="selectedTagIds"
						label="Tag"
						options={this.filterOptions.tagOptions || []}
						isClearable
						onAfterChange={(event, data) => {
							this.filterOptions.setPatientOptionsFilteredByTags(data.value as string[], this.props.patients);
						}}
					/>
					<MultiCombobox
						className='dashboard-goal-todo-table-filter__multicombobox'
						placeholder={"Select patients"}
						model={this.filterOptions}
						modelProperty="selectedPatientIds"
						label="Patients"
						options={this.filterOptions.patientOptions || []}
						isClearable
					/>
					<DatePicker
						className='dashboard-goal-todo-table-filter__datepicker'
						label="From Goal Date"
						model={this.filterOptions}
						modelProperty="selectedGoalsFromCreatedDate"
						flatpickrOptions={{
							dateFormat: 'd/m/Y',
						}}
					/>
					<DatePicker
						className='dashboard-goal-todo-table-filter__datepicker'
						label="To Goal Date"
						model={this.filterOptions}
						modelProperty="selectedGoalsToCreatedDate"
						flatpickrOptions={{
							dateFormat: 'd/m/Y',
						}}
					/>
					<Checkbox
						className='dashboard-no-goals-user__checkbox '
						label="Show patients without goals"
						model={this.filterOptions}
						modelProperty="selectedNoGoalsUsers"
					/>
				</div>
			</div>
		)
	}

	handleOnClickRunReport = () => {

		let parsedSelectedTagIds = this.filterOptions.getParsedSelectedTagIds();
		let parsedSelectedPatientIds = this.filterOptions.getParsedSelectedPatientIds();

		this.tableOptions.setFilterOptions(
			parsedSelectedTagIds,
			parsedSelectedPatientIds,
			this.filterOptions.selectedGoalsFromCreatedDate,
			this.filterOptions.selectedGoalsToCreatedDate,
			this.filterOptions.selectedNoGoalsUsers,

		);
	}

	handleOnClickExportReport = () => {
		axios.get(
			"/api/entity/PatientEntity/dashboard_export_goals",
			{
				params: this.getMoreParams(),
			}
		).then((result) => {
			const blob = new Blob([result.data], { type: "text/csv;charset=utf-8" });
			saveAs(blob, `export-dashoboard-goals.csv`);
		}).catch(() => {
			alert('Unsuccessfully exported dashboard goals', 'error');
		})
	}

	renderPatientGoalsTable = () => {

		return (
			<div className="dashboard-grid-item dashboard-table">
				<ModelCollection
					url="/api/entity/PatientEntity/patient_list"
					isApiQuery
					model={PatientEntity}
					orderBy={this.tableOptions.orderBy}
					perPage={this.tableOptions.paginationQueryOptions.perPage}
					headers={this.getHeaders()}
					actions={this.getActions()}
					getMoreParams={this.getMoreParams}
				/>
			</div>
		)
	}

	getMoreParams = (filters?: Array<IFilter<PatientEntity>>, filterApplied?: boolean): ApiQueryParams => {
		const filterParams = {};


		if (!!this.tableOptions.tagIds) {
			filterParams['tagIds'] = JSON.stringify(this.tableOptions.tagIds.map(tag => tag));
		}

		if (!!this.tableOptions.patientIds) {
			filterParams['patientIds'] = JSON.stringify(this.tableOptions.patientIds.map(tag => tag));
		}

		if (!!this.tableOptions.fromGoalsCreatedDate) {
			filterParams['fromGoalsCreatedDate'] = this.tableOptions.fromGoalsCreatedDate;
		}

		if (!!this.tableOptions.toGoalsCreatedDate) {
			filterParams['toGoalsCreatedDate'] = this.tableOptions.toGoalsCreatedDate;
		}
		if (!!this.tableOptions.noGoalsUsers) {
			filterParams['noGoalsUsers'] = this.tableOptions.noGoalsUsers;
		}
		return filterParams;
	};

	getHeaders = (): Array<ICollectionHeaderProps<PatientEntity>> => {
		return [
			{
				name: 'surname',
				displayName: 'Surname',
				sortable: true,
			},
			{
				name: 'forename',
				displayName: 'First Name',
				sortable: true,
			},
			{
				name: 'goalsCreated',
				displayName: 'No. Goals Created',
				sortable: true,
			},
			{
				name: 'goalsCompleted',
				displayName: 'No. Goals Completed',
				sortable: true,
			},
			{
				name: 'goalsNotComplete',
				displayName: 'No. Goals not completed',
				sortable: true,
			},
			{
				name: 'goalsCompletedPercentage',
				displayName: '% Completed',
				sortable: true,
				transformItem: (item) => {
					return item.goalsCompletedPercentage + "%";
				}
			},
		];
	};


	getActions = () => {
		const tableActions: Array<ICollectionItemActionProps<PatientEntity>> = [];

		tableActions.push({
			action: item => {
			},
			label: 'View Profile',
			customButton: (item) => (
				<Link to={`${SERVER_URL}/profile/patient/${item.id}`}>
					<Button colors={Colors.Primary} display={Display.Text} sizes={Sizes.Small}>View Profile</Button>
				</Link>
			)
		});
		return tableActions;
	};
}
