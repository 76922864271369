import { gql } from "apollo-boost";
import { store } from "Models/Store";
import moment from "moment";
import React, { useEffect, useState, version } from "react";
import Popup from "reactjs-popup";
import HeaderUpdate from "../Header/HeaderUpdate";
import GetModel from "../../../Views/Components/GetModel/GetModel";
import FormBanner from "./FormBanner";
import { RatingpersonalSubmissionEntity, RatingtemplateSubmissionEntity } from "Models/Entities";
import { EntityFormLayout } from "../EntityFormLayout/EntityFormLayout";
import { AttributeFormMode, EntityFormMode } from "../Helpers/Common";
import EntityAttributeList from "../CRUD/EntityAttributeList";
import { useHistory, useLocation, useRouteMatch } from "react-router";
import PaginationButtons from "./../PageinationButtons/PaginationButtons";
import { Line } from "react-chartjs-2";
import { Combobox } from "../Combobox/Combobox";
import { QuestionType } from '../../../Forms/Schema/Question';

interface ICheckInOverviewProps {
	formId: string;
	submissionId?: string;
	mode?: string;
}

export default function CheckInOverviewTile(props: ICheckInOverviewProps) {
	const [type, form] = useOverview(props.formId);

	return (
		<div className="checkin-overview-page">
			<HeaderUpdate title="Form Overview" displayBack={true} backClick={() => store.routerHistory.goBack()} />
			<FormBanner form={form} />
			<FormContent form={form}>{renderContent()}</FormContent>
		</div>
	);

	function renderContent() {
		if (props.submissionId && props.mode && type) {
			return <PreviousEntry submissionId={props.submissionId} mode={props.mode} type={type} />;
		} else {
			return (
				<>
					<HistoryGraph form={form} />
					<PreviousEntries form={form} />
				</>
			);
		}
	}
}

function PreviousEntry(props: { submissionId: string; mode: string; type: string }) {
	const history = useHistory();
	const location = useLocation();
	const match = useRouteMatch();
	const formMode = props.mode === "view" ? EntityFormMode.VIEW : EntityFormMode.EDIT;

	if (props.type == "RatingtemplateEntity") {
		if (props.mode === "view") {
			return (
				<GetModel modelType={RatingtemplateSubmissionEntity} id={props.submissionId}>
					{(model: RatingtemplateSubmissionEntity) => (
						<EntityFormLayout
							key={model.id}
							model={model}
							getErrorsForAttribute={model.getErrorsForAttribute}
							formMode={formMode}
							attributeBehaviours={[
								{ name: "clinicianss", behaviour: AttributeFormMode.HIDE },
								{ name: "submissionDate", behaviour: AttributeFormMode.HIDE },
								{ name: "created", behaviour: AttributeFormMode.HIDE },
								{ name: "modified", behaviour: AttributeFormMode.HIDE },
							]}
						/>
					)}
				</GetModel>
			);
		} else {
			return (
				<GetModel modelType={RatingtemplateSubmissionEntity} id={props.submissionId}>
					{(model: RatingtemplateSubmissionEntity) => (
						<EntityAttributeList
							key={model.id}
							model={model}
							modelType={RatingtemplateSubmissionEntity}
							sectionClassName={"edit"}
							title=""
							history={history}
							location={location}
							match={match}
							formMode={formMode}
							attributeBehaviours={[
								{ name: "modified", behaviour: AttributeFormMode.HIDE },
								{ name: "created", behaviour: AttributeFormMode.HIDE },
								{ name: "clinicianss", behaviour: AttributeFormMode.HIDE },
								{ name: "submissionDate", behaviour: AttributeFormMode.HIDE },
							]}
						/>
					)}
				</GetModel>
			);
		}
	} else if (props.type == "RatingpersonalEntity") {
		if (props.mode === "view") {
			return (
				<GetModel modelType={RatingpersonalSubmissionEntity} id={props.submissionId}>
					{(model: RatingpersonalSubmissionEntity) => (
						<EntityFormLayout
							key={model.id}
							model={model}
							getErrorsForAttribute={model.getErrorsForAttribute}
							formMode={formMode}
							attributeBehaviours={[
								{ name: "clinicianss", behaviour: AttributeFormMode.HIDE },
								{ name: "submissionDate", behaviour: AttributeFormMode.HIDE },
								{ name: "created", behaviour: AttributeFormMode.HIDE },
								{ name: "modified", behaviour: AttributeFormMode.HIDE },
							]}
						/>
					)}
				</GetModel>
			);
		} else {
			return (
				<GetModel modelType={RatingpersonalSubmissionEntity} id={props.submissionId}>
					{(model: RatingpersonalSubmissionEntity) => (
						<EntityAttributeList
							key={model.id}
							model={model}
							modelType={RatingpersonalSubmissionEntity}
							sectionClassName={"edit"}
							title=""
							history={history}
							location={location}
							match={match}
							formMode={formMode}
							attributeBehaviours={[
								{ name: "modified", behaviour: AttributeFormMode.HIDE },
								{ name: "created", behaviour: AttributeFormMode.HIDE },
								{ name: "clinicianss", behaviour: AttributeFormMode.HIDE },
								{ name: "submissionDate", behaviour: AttributeFormMode.HIDE },
							]}
						/>
					)}
				</GetModel>
			);
		}
	}

	return <></>;
}

interface IFormContent {
	children?: any;
	form: any;
}

function FormContent(props: IFormContent) {
	if (props.form) {
		return (
			<div className="container">
				<div className="content">
					<div className="information">
						<div className="title">{props.form.name}</div>
						<div className="description">{props.form.description}</div>
					</div>
					{props.children}
				</div>
			</div>
		);
	}
	return <></>;
}

interface IHistoryGraphState {
	activeButton: number;
	formData: any;
	formResponses: any;
	currentQuestion: number;
	availableQuestions: { question: any; slideIndex: number; questionIndex: number }[];
}
type GraphRangeDuration = 'day' | 'week' | 'month';
interface GraphRange {
	text: string;
	period: GraphRangeDuration;
	step: number;
	back: number;
}
function HistoryGraph(props: { form: any }) {
	// Question Types that can be rendered on the graph.
	const GRAPHABLE_QUESTIONS = ["slider", "smileyrating", "pictorial-slider", "number"] as QuestionType[];
	const graphedFrequencies = ["DAILY", "WEEKLY", "FORTNIGHTLY", "MONTHLY"];
	const graphedButtons = {
		DAILY: [
			{ text: "2 Weeks", period: "day", step: 1, back: 14 },
			{ text: "1 Month", period: "day", step: 1, back: 31 },
			{ text: "3 Months", period: "day", step: 1, back: 90 },
			{ text: "1 Year", period: "day", step: 1, back: 365 },
		] as GraphRange[],
		WEEKLY: [
			{ text: "2 Weeks", period: "week", step: 1, back: 2 },
			{ text: "1 Month", period: "week", step: 1, back: 4 },
			{ text: "3 Months", period: "week", step: 1, back: 12 },
			{ text: "1 Year", period: "week", step: 1, back: 52 },
		] as GraphRange[],
		FORTNIGHTLY: [
			{ text: "1 Month", period: "week", step: 2, back: 2 },
			{ text: "3 Months", period: "week", step: 2, back: 6 },
			{ text: "1 Year", period: "week", step: 2, back: 24 },
		] as GraphRange[],
		MONTHLY: [
			{ text: "3 Months", period: "month", step: 1, back: 3 },
			{ text: "6 Months", period: "month", step: 1, back: 6 },
			{ text: "1 Year", period: "month", step: 1, back: 12 },
		] as GraphRange[],
	};

	const [state, setState] = useState<IHistoryGraphState>({
		activeButton: 0,
		formData: undefined,
		formResponses: undefined,
		currentQuestion: 0,
		availableQuestions: [],
	});

	useEffect(() => {
		getQuestions();
	}, [props.form]);

	useEffect(() => {
		getData();
	}, [state.availableQuestions, state.currentQuestion]);

	if (props.form) {
		if (graphedFrequencies.find((frequency) => frequency === props.form.frequency) != null) {
			return (
				<div className="history-graph">
					<div className="section-title">History</div>
					<div className="graph-container">
						<div className="buttons">{renderButtons()}</div>
						<div className="graph">{renderGraph()}</div>
						<div className="available-questions">{renderAvailableQuestions()}</div>
					</div>
				</div>
			);
		}
	}
	return <></>;

	/**
	 * Dropdown that allows the user to select the different questions available so they can view their history
	 */
	function renderAvailableQuestions() {

		if (state.availableQuestions.length === 0) {
			return <></>;
		}

		const options = state.availableQuestions.map((question: any, index: number) => {
			return { display: question.question.title, value: index };
		});
		return (
			<Combobox
				model={state}
				modelProperty="currentQuestion"
				label="Question"
				options={options}
				inputProps={{ onChange: handleQuestionChange }}
			/>
		);

		function handleQuestionChange(e: React.SyntheticEvent<HTMLElement, Event>, data: any) {
			setState({ ...state, currentQuestion: data.value });
		}
	}

	function renderButtons() {
		return graphedButtons[props.form.frequency].map((button: any, index: number) => {
			return (
				<div
					key={index}
					className={index == state.activeButton ? "button active-button" : "button"}
					onClick={() => setState({ ...state, activeButton: index })}>
					{button.text}
				</div>
			);
		});
	}

	function getQuestions() {
		if (props?.form?.publishedVersion) {
			const formData = JSON.parse(props.form.publishedVersion.formData);
			const availableQuestions: { question: any; slideIndex: number; questionIndex: number }[] = [];

			// Find supported graph questions from the published form version
			formData.forEach((slide: any, sIndex: number) => {
				if (slide?.contents) {
					slide.contents.forEach((question: any, qIndex: number) => {
						if (GRAPHABLE_QUESTIONS.find((q: string) => q === question.questionType)) {
							availableQuestions.push({
								question: question, slideIndex: sIndex,
								questionIndex: qIndex
							});
						}
					});
				}
			});

			setState({ ...state, formData: formData, availableQuestions: availableQuestions });
		}
	}

	function getData() {
		if (state.availableQuestions.length > 0 && state.currentQuestion != null) {

			const allSubmissions = props.form.formVersions.flatMap((formVersion: any) => formVersion.formSubmissions);
			const values = allSubmissions.map((submission: any) => {
				const response = JSON.parse(submission.submissionData);
				const value = response[state.availableQuestions[state.currentQuestion].question.id];
				return { id: submission.id, date: submission.submissionDate, response: value };
			});

			setState({ ...state, formResponses: values });
		}
	}

	function renderGraph() {
		if (state.formResponses) {
			const options = graphedButtons[props.form.frequency][state.activeButton] as GraphRange;

			let dates: any[] = [];
			let data: any[] = [];
			let dataResponse: any[] = [];
			let responseIndex = 0;

			for (let i = 0; i <= options.back; i++) {

				const date = moment.utc().local()
					.subtract(options.step * i, options.period)
					.toDate()

				dates.push(date);

				const response = state.formResponses[responseIndex];

				if (response) {
					const startingDate = moment(dates[i]).subtract(options.step, options.period);
					const endingDate = moment(dates[i]);

					if (moment(response.date).isBetween(startingDate, endingDate, options.period, "(]")) {
						data.push(response.response);
						dataResponse.push(response);
						responseIndex++;
					} else {
						data.push(null);
						dataResponse.push(null);
					}
				}
			}

			let suggestedMax = 10;

			if (state.availableQuestions[state.currentQuestion]) {
				if (
					state.availableQuestions[state.currentQuestion].question.questionType == "pictorial-slider" &&
					state.availableQuestions[state.currentQuestion].question.max == 5
				) {
					suggestedMax = 5;
				}
			}

			let dateMin = moment(dates[dates.length - 1])
				.startOf("day")
				.toDate();

			let dateMax = moment(dates[0]).startOf("day").add(1, "day").toDate();

			return (
				<Line
					data={{
						labels: dates,
						datasets: [{
							data: data,
							borderColor: "rgba(55, 121, 149, 1)",
							backgroundColor: "rgba(55, 121, 149, 0)"
						}],
					}}
					options={{
						scales: {
							xAxes: [
								{
									type: "time",
									time: {
										unit: options.period,
										displayFormats: { day: "M/D", week: "M/D" },
										min: dateMin,
										max: dateMax
									},
								},
							],
							yAxes: [
								{
									ticks: {
										suggestedMin: 0,
										suggestedMax: suggestedMax,
									},
								},
							],
						},
						legend: {
							display: false,
						},
						onHover: (e: any, element: any) => handleHover(element),
						onClick: (e: any, element: any) => handleClick(element, dataResponse),
					}}
				/>
			);
		}
		return <div className="no-graph">No graphable questions</div>;
	}

	function handleClick(element: any, dataResponse: any[]) {
		if (element[0]) {
			const submission = dataResponse[element[0]._index];
			store.routerHistory.push(`/check-in/overview/${props.form.id}/view/${submission.id}`);
		}
	}

	function handleHover(element: any) {
		element[0] ? (document.body.style.cursor = "pointer") : (document.body.style.cursor = "default");
	}
}

function PreviousEntries(props: { form: any }) {
	const [page, setPage] = useState(1);

	const ENTRIES_PER_PAGE = 5;

	if (props.form) {
		const pages = getPages(props.form);

		return (
			<>
				<div className="previous-entries">
					<div className="section-title">Previous entries</div>
					{renderEntries()}
				</div>
				<PaginationButtons
					pages={pages}
					page={page}
					previousPage={() => setPage(page - 1)}
					nextPage={() => setPage(page + 1)}
					setActivePage={(page: number) => setPage(page)}
				/>
			</>
		);
	}
	return <></>;


	function renderEntries() {
		let submissions = props.form.formVersions
			.flatMap((x: any) => x.formSubmissions)
			.filter((x: any) => x != null)
			.filter((x: any, index: number) => index >= page * ENTRIES_PER_PAGE - ENTRIES_PER_PAGE && index <= page * ENTRIES_PER_PAGE - 1)
			.sort((a: any, b: any) => {
				let dateA = moment(a.submissionDate);
				let dateB = moment(b.submissionDate);
				return dateB.diff(dateA)
			});

		return submissions.map((submission: any) => {
			return (
				<div className="submission" key={submission.id}>
					<div className="date">
						<div className="checkin-submission-info">
							Entry date:
							<div className="submission-data">
							{moment(submission.submissionDate).format("DD/MM/YYYY")}
							</div>
						</div>
						<div className="checkin-submission-info">
							Completed at:
							<div className="submission-data">
								{console.log(submission.created)}
								{moment.utc(submission.created).local().format("DD/MM/YYYY LT")}
							</div>
						</div>

					</div>
					<Popup
						contentStyle={{ width: "100px" }}
						position="left center"
						trigger={<div className="expand icon-left icon-more-vertical" />}>
						<div className="options">
							<div
								className="option"
								onClick={() => store.routerHistory.push(`/check-in/overview/${props.form.id}/view/${submission.id}`)}>
								View
							</div>
							<div
								className="option"
								onClick={() => store.routerHistory.push(`/check-in/overview/${props.form.id}/edit/${submission.id}`)}>
								Edit
							</div>
						</div>
					</Popup>
				</div>
			);
		});
	}

	function getPages(form: any): number[] {
		let pageArray: number[] = [];
		let numberPages = 0;
		let totalLength = form.formVersions.flatMap((formVersion: any) => formVersion.formSubmissions).length;

		if (totalLength <= ENTRIES_PER_PAGE) {
			numberPages = 1;
		} else {
			numberPages = Math.floor(totalLength / ENTRIES_PER_PAGE);
		}
		for (let page = 1; page <= numberPages; page++) {
			pageArray.push(page);
		}

		return pageArray;
	}
}

function useOverview(formId: string) {
	const [form, setForm] = useState<any>(undefined);
	const [type, setType] = useState<"RatingtemplateEntity" | "RatingpersonalEntity" | undefined>(undefined);

	useEffect(() => {
		getForm();
	}, [formId]);

	async function getForm() {
		let response: any = await store.apolloClient.query({ query: queryOverview(formId) });
		if (response.data.ratingtemplateEntitys.length > 0) {
			setForm(response.data.ratingtemplateEntitys[0]);
			setType("RatingtemplateEntity");
		} else if (response.data.ratingpersonalEntitys.length > 0) {
			setForm(response.data.ratingpersonalEntitys[0]);
			setType("RatingpersonalEntity");
		}
	}

	return [type, form];
}

function queryOverview(formId: string) {
	return gql`
		query overview {
			ratingtemplateEntitys(id: "${formId}") {
				id
				name
				description
				bannerId
				frequency
				publishedVersion {
					id
					formData
					formSubmissions(
						where: { path: "owner", comparison: equal, value: "${store.userId!}" }
						orderBy: { path: "submissionDate", descending: true },
					) {
						id
						owner
						created
						submissionDate
						submissionData
					}
				}
				formVersions (orderBy: {path: "version", descending: true}) {
					version
					formData
					formSubmissions(
						where: { path: "owner", comparison: equal, value: "${store.userId}" },
						orderBy: {path: "submissionDate", descending: true}
					) {
						id
						owner
						created
						submissionDate
						submissionData
						clinicianss{
							cliniciansId
						}
					}
				}
			}
			ratingpersonalEntitys(id: "${formId}") {
				id
				name
				description
				bannerId
				frequency
				publishedVersion {
					id
					formData
					formSubmissions(
						where: { path: "owner", comparison: equal, value: "${store.userId}" }
						orderBy: { path: "submissionDate", descending: true },
					) {
						id		
						owner
						created
						submissionDate
						submissionData
					}
				}
				formVersions (orderBy: {path: "version", descending: true}) {
					version
					formData
					formSubmissions(
						where: { path: "owner", comparison: equal, value: "${store.userId}" },
						orderBy: {path: "submissionDate", descending: true}
					) {
						id
						owner
						created
						submissionDate
						submissionData
						clinicianss{
							cliniciansId
						}
					}
				}
			}
		}
	`;
}
