import React, { Component } from "react";
import { IModelType } from "Models/Model";
import { Button } from "../Button/Button";
import { observable, action } from "mobx";
import { observer } from "mobx-react";
import If from "../If/If";
import moment from "moment";

interface IPreviewPane {
	name: string;
	createdDate?: Date;
	submittedDate?: Date;
	created: string;
	submitted: string;
	countForms: number;
	goal: boolean;
	currentFormIndex: number;
	previous: () => void;
	next: () => void;
	close: () => void;
}

export default class PreviewPane extends Component<IPreviewPane> {
	render() {
		const { created, submitted, createdDate, submittedDate, name, countForms, goal, currentFormIndex } = this.props;

		var submissionString = 'n/a'
		if(submittedDate != undefined) {
			submissionString = moment.utc(submittedDate).format('Do MMMM YYYY');
		}
		
		return (
			<div className={goal == false ? "preview-pane" : "goal-create-preview-pane"}>
				<div className="header">
					<div className="form-counter">
						<If condition={goal == false}>
							{currentFormIndex + 1} of {countForms}
						</If>
					</div>
					<div className="header-content-container">
						<div>
							<If condition={goal == false}>
								<div className="navigate-button icon-top icon-chevron-left" onClick={() => this.props.previous()} />
							</If>
						</div>

						<div className="header-content">
							<div className="form-name">{name}</div>
							<div className="form-created ">
								<If condition={goal == false}>
									<div style={{ display: "flex", flexDirection: "column", height: "40px" }}>
										<div>
										{"Submitted for "} {submissionString} {"    "}
										</div>
										<div>
											{"Completed on"} {created}
											</div>
									</div>
								</If>
							</div>
						</div>

						<div>
							<If condition={goal == false}>
								<div className="navigate-button icon-top icon-chevron-right" onClick={() => this.props.next()} />
							</If>
						</div>
					</div>
				</div>
				<div className="children">{this.props.children}</div>
				<div className="footer">
					<div className="close-preview icon-right icon-chevron-right" onClick={() => this.props.close()} />
				</div>
			</div>
		);
	}
}
