import React, { Component } from 'react';
import { observer } from 'mobx-react';
import moment from 'moment';
import { Bar } from 'react-chartjs-2';
import { response } from './ClinicianCheckIn';
import { computed } from 'mobx';
import { QuestionType } from '../../../Forms/Schema/Question';
import { prettyLog } from '../../../Util/StringUtils';

interface ratingProps {
	responses: response[];
	frequency: 'DAILY' | 'WEEKLY' | 'FORTNIGHTLY';
	formField: string;
	tagName: string;
	question: any;
}

@observer
export default class CheckInRatings extends Component<ratingProps> {

	// Gets the headers for each of the columns
	private getDates(frequency: string) {
		let columns = [];
		if (frequency === 'DAILY') {
			for (let prevDays = 6; prevDays >= 0; prevDays--) {
				let nowDay = moment();
				columns.push(nowDay.subtract(prevDays, 'd').format('D/M'));
			}
		}
		if (frequency === 'WEEKLY') {
			for (let prevWeek = 6; prevWeek >= 0; prevWeek--) {
				let nowDay = moment().startOf('w');
				columns.push(nowDay.subtract(prevWeek, 'w').format('D/M'));
			}
		}
		if (frequency === 'FORTNIGHTLY') {
			for (let prevFortnight = 6; prevFortnight >= 0; prevFortnight--) {
				let nowDay = moment().startOf('w');
				columns.push(nowDay.subtract(prevFortnight * 2, 'w').format('D/M'));
			}
		}
		return columns;
	}

	// Gets the values to go into the columns for each user
	private getValues(columns: string[], frequency: string, responses: response[]) {
		let submissions = responses.map((response: response) => {
			if (response.hasRating) {
				let values = columns.map((day: string) => {
					let submission;

					if (frequency === 'DAILY') {
						response.values?.forEach((value: any) => {
							let submissionDate = moment(value.submissionDate).format('D/M');
							if (submissionDate === day) {
								let data = JSON.parse(value.submissionData);
								if (data != undefined) {
									submission = data[this.props.formField];
								}
							}
						});
						return submission;
					}
					if (frequency === 'WEEKLY') {
						response.values?.forEach((value: any) => {
							let submissionDate = moment(value.submissionDate).startOf('w').format('D/M');
							if (submissionDate === day) {
								let data = JSON.parse(value.submissionData);

								if (data != undefined) {
									submission = data[this.props.formField];
								}
							}
						});
						return submission;
					}
					return;
				});

				return { id: response.userId, user: response.userName, values: values };
			}
			return { id: response.userId, user: response.userName, values: [] };
		});

		return submissions;
	}


	// Renders a graph if the appropriate question is chosen
	private renderGraph(values: any) {
		const { questionType, title, shape, max } = this.props.question;

		if (questionType === 'smileyrating' || questionType === 'slider' || questionType === 'pictorial-slider') {
			// Define a counter for each value
			let counts: any;
			// Define object to store graph data
			let data: any;

			if (questionType === 'pictorial-slider') {
				if (max === 5) {
					counts = { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0 };
					// Add 1 to the counter for each submission
					values.forEach((user: any) => {
						user.values.forEach((submission: any) => {
							if (submission) {
								counts[submission]++;
							}
						});
					});

					// White labelled background colors for graphs
					const backgroundColor = shape === 'alco'
						? ['#9dcf50', '#d9e158', '#f1d343', '#de824b', '#be3a49']
						: ['#d36352', '#e29c56', '#efe545', '#bbe058', '#92c3a1'];

						data = {
						labels: [1, 2, 3, 4, 5],
						datasets: [
							{
								backgroundColor: backgroundColor,
								data: Object.values(counts),
							},
						],
					};
				} else {
					counts = { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0, 7: 0, 8: 0, 9: 0, 10: 0 };
					values.forEach((user: any) => {
						user.values.forEach((submission: any) => {
							if (submission) {
								counts[submission]++;
							}
						});
					});

					// White labelled background colors for graphs
					const backgroundColor = shape === 'alco'
						? ['#92c3a1', '#9dcf50', '#bbe058', '#d9e158', '#efe545', '#f1d343', '#e29c56', '#de824b', '#d36352', '#be3a49']
						: ['#be3a49', '#d36352', '#de824b', '#e29c56', '#f1d343', '#efe545', '#d9e158', '#bbe058', '#9dcf50', '#92c3a1']

					data = {
						labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
						datasets: [
							{
								backgroundColor: backgroundColor,
								data: Object.values(counts),
							},
						],
					};
				}
			} else {
				counts = { 0: 0, 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0, 7: 0, 8: 0, 9: 0, 10: 0 };
				values.forEach((user: any) => {
					user.values.forEach((submission: any) => {
						if (submission) {
							counts[submission]++;
						}
					});
				});

				// Define dataset for graph
				data = {
					labels: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
					datasets: [
						{
							backgroundColor: [
								'#a32f3c',
								'#be3a49',
								'#d36352',
								'#de824b',
								'#e29c56',
								'#f1d343',
								'#efe545',
								'#d9e158',
								'#bbe058',
								'#9dcf50',
								'#92c3a1',
							],
							data: Object.values(counts),
						},
					],
				};
			}

			// Define options for graph
			const options = {
				legend: {
					display: false,
				},
				scales: {
					xAxes: [
						{
							scaleLabel: {
								display: true,
								labelString: title + ' Value',
							},
						},
					],
					yAxes: [
						{
							ticks: {
								suggestedMin: 0,
								stepSize: 1,
							},
							scaleLabel: {
								display: true,
								labelString: this.props.tagName,
							},
						},
					],
				},
			};

			return (
				<div className="graph">
					<Bar data={data} options={options} />
				</div>
			);
		}
		return;
	}

	render() {
		const { frequency, responses, question } = this.props;

		let columns = this.getDates(frequency);
		let values = this.getValues(columns, frequency, responses);

		return (
			<div className="dashboard-grid-item dashboard-patient-graph">
				<h1>Check Ins</h1>
				<p> {question.title ? `Check ins from the past 7 days - ${question.title}` : 'Please select filters to display the graph'}</p>
				{this.renderGraph(values)}
			</div>
		);
	}
}
