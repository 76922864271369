import React, { Component } from "react";
import { gql } from "apollo-boost";
import { store } from "Models/Store";
import { observer } from "mobx-react";
import { observable, action, toJS } from "mobx";
import moment from "moment";
import FormButton from "./FormButton";
import PreviewPane from "./PreviewPane";
import {
	ActivitiesSubmissionEntity,
	CliniciansViewedactivitiesSubmissions,
	ClinicianEntity,
	PatientEntity,
} from "Models/Entities";
import { EntityFormLayout } from "../EntityFormLayout/EntityFormLayout";
import { EntityFormMode, AttributeFormMode } from "../Helpers/Common";
import { getModelDisplayName } from "Util/EntityUtils";
import _ from "lodash";

function queryActivities(userId: string) {
	return gql`
		query activities {
			activitiesEntitys(orderBy: { path: "name" }) {
				id
				name
				formVersions(orderBy: { path: "version", descending: true }) {
					version
					formSubmissions(
						where: { path: "owner", comparison: equal, value: "${userId}" }
						orderBy: { path: "created", descending: true }
					) {
						id
						owner
                        created
                        clinicianss{
                            cliniciansId
                        }
					}
                }
			}
		}
	`;
}

function querySubmissionDate(submissionId: string) {
	return gql`
		query submissionDate {
			activitiesSubmissionEntity(id: "${submissionId}") {
				id
				created
			}
		}
	`;
}

interface IProfileActivity {
	userId: string;
	renderProfileTile?: () => void;
}

interface IActivityButton {
	name: string;
	countSubmissions: number;
	submissions: string[];
	lastCreated?: Date;
	lastCreatedString: string;
	lastSubmission?: Date;
	lastSubmissionString: string;
	clinicians: string[];
}

@observer
export default class ProfileActivities extends Component<IProfileActivity> {
	@observable
	private activities: IActivityButton[] = [];

	@observable
	private viewPreview: boolean = false;

	@observable
	private currentForm: IActivityButton;

	@observable
	private currentFormIndex: number = 0;

	@observable
	private page: number = 1;

	@observable
	private containerStyle = {};

	@observable
	private clinician: ClinicianEntity;

	@observable
	private activity: ActivitiesSubmissionEntity;

	componentDidMount() {
		this.getActivities();
		this.getClinician();
	}

	private async getClinician() {
		if (store.userGroups[0].name === "Clinician") {
			ClinicianEntity.fetch<ClinicianEntity>(
				{ args: [[{ path: "id", comparison: "equal", value: store.userId }]] },
				new ClinicianEntity().defaultExpands
			).then((clinician: ClinicianEntity[]) => {
				this.setClinician(clinician[0]);
			});
		}
	}

	@action
	private setClinician(clinician: ClinicianEntity) {
		this.clinician = clinician;
	}

	private async getActivity() {
		if (this.currentForm.submissions[this.currentFormIndex]) {
			ActivitiesSubmissionEntity.fetch<ActivitiesSubmissionEntity>(
				{ args: [[{ path: "id", comparison: "equal", value: this.currentForm.submissions[this.currentFormIndex] }]] },
				new ActivitiesSubmissionEntity().defaultExpands
			).then((activity: ActivitiesSubmissionEntity[]) => {
				this.setActivity(activity[0]);
			});
		} else {
			this.setActivity(new ActivitiesSubmissionEntity());
		}
	}

	@action
	private setActivity(activity: ActivitiesSubmissionEntity) {
		this.activity = activity;
	}

	private async getActivities() {
		store.apolloClient.query({ query: queryActivities(this.props.userId), fetchPolicy: "network-only" }).then((d) => {
			this.setActivities(d.data.activitiesEntitys);
		});
	}

	@action
	private setActivities(data: any) {
		this.activities = [];

		data.map((activity: any) => {
			let submissions: any[] = [];
			let lastCreated;
			let lastCreatedString: string = "n/a";
			let lastSubmission;
			let lastSubmissionString: string = "n/a";
			let clinicians: string[] = [];

			activity.formVersions.map((version: any, versionIndex: number) => {
				version.formSubmissions.map((submission: any, submissionIndex: number) => {
					submissions.push(submission.id);
					if (submissionIndex == 0 && versionIndex == 0) {
						lastCreated = new Date(submission.created);
						lastCreatedString = moment.utc(submission.created).local().format("LT Do MMMM YYYY"); 
						lastSubmission = new Date(submission.submissionDate);
						lastSubmissionString = moment.utc(submission.submissionDate).local().format("LT Do MMMM YYYY");
					}

					submission.clinicianss.forEach((clinician: any) => {
						clinicians.push(clinician.cliniciansId);
					});
				});
			});

			this.activities.push({
				name: activity.name,
				countSubmissions: submissions.length,
				submissions: submissions,
				lastCreated: lastCreated,
				lastCreatedString: lastCreatedString,
				lastSubmission: lastSubmission,
				lastSubmissionString: lastSubmissionString,
				clinicians: clinicians,
			});
		});

		this.activities = _.orderBy(
			this.activities,
			(activity) => {
				return activity.lastSubmission ? activity.lastSubmission : "";
			},
			["desc"]
		);
	}

	private getPageLength(): number {
		if (this.activities.length <= 6) {
			return 1;
		}
		return Math.floor(this.activities.length / 6) + 1;
	}

	private renderActivities() {
		let pages = [];
		for (let page = 1; page <= this.getPageLength(); page++) {
			pages.push(page);
		}

		return (
			<div className="button-container" style={toJS(this.containerStyle)} onClickCapture={() => this.closePreview()}>
				<div className="checkin-buttons">
					{this.activities.map((activityButton: IActivityButton, index: number) => {
						let showBadge;
						if (store.userGroups[0].name === "Clinician") {
							let clinicianSeen = 0;

							// clinician id should show up the same number of times as submissions if they've seen all submissions in this activity
							activityButton.clinicians.forEach((clinician: string, i) => {
								if (clinician == store.userId) {
									clinicianSeen++;
								}
							});
							showBadge = clinicianSeen == activityButton.countSubmissions ? false : true;
						}

						if (index >= this.page * 6 - 6 && index <= this.page * 6 - 1) {
							return (
								<>
									<FormButton
										key={index}
										title={activityButton.name}
										submissions={activityButton.submissions}
										countSubmissions={activityButton.countSubmissions}
										lastCreated={activityButton.lastSubmissionString} //FIX
										lastSubmission={activityButton.lastSubmission}
										onClick={() => {
											this.setCurrentForm(activityButton);
											this.getActivity();
										}}
										showBadge={showBadge}
									/>
								</>
							);
						}
						return;
					})}
				</div>

				<div className="page-buttons">
					<div
						className={"navigate-page icon-left icon-chevron-left " + (this.page == 1 ? "navigate-page-disabled" : "")}
						onClick={() => this.previousPage()}
					/>
					<div
						className={this.page == 1 ? `active-page-button page-button` : `page-button`}
						onClick={() => {
							this.setActivePage(1);
						}}>
						{1}
					</div>

					{this.getMiddlePages(pages)}

					{pages.length !== 1 ? (
						<div
							className={this.page == pages.length ? `active-page-button page-button` : `page-button`}
							onClick={() => {
								this.setActivePage(pages.length);
							}}>
							{pages.length}
						</div>
					) : (
						<></>
					)}

					<div
						className={"navigate-page icon-right icon-chevron-right " + (this.page == pages.length ? "navigate-page-disabled" : "")}
						onClick={() => this.nextPage(pages.length)}
					/>
				</div>
			</div>
		);
	}

	private getMiddlePages(pages: number[]) {
		if (pages.length < 4) {
			return pages.map((page) => {
				if (page !== 1 && page !== pages.length) {
					return (
						<div
							className={this.page == page ? `active-page-button page-button` : `page-button`}
							onClick={() => {
								this.setActivePage(page);
							}}>
							{page}
						</div>
					);
				}
				return;
			});
		} else {
			if (this.page !== 1 && this.page !== pages.length) {
				return (
					<>
						{this.page !== 2 ? <div className="page-dots">...</div> : <></>}
						<div
							className="active-page-button page-button"
							onClick={() => {
								this.setActivePage(this.page);
							}}>
							{this.page}
						</div>
						{this.page !== pages.length - 1 ? <div className="page-dots">...</div> : <></>}
					</>
				);
			} else {
				return <div className="page-dots">...</div>;
			}
		}
	}

	@action
	private previousPage() {
		if (this.page > 1) {
			this.page--;
		}
	}

	@action
	private nextPage(pageLength: number) {
		if (this.page < pageLength) {
			this.page++;
		}
	}

	@action
	private setActivePage(page: number) {
		this.page = page;
	}

	@action
	private setCurrentForm(form: IActivityButton) {
		this.currentFormIndex = 0;
		this.currentForm = form;
		this.viewPreview = true;
		if (window.innerWidth < 800) {
			this.containerStyle = { display: "none" };
		}
	}

	private renderPreview() {
		if (this.viewPreview) {
			if (this.currentForm && this.activity) {
				const { name, countSubmissions, lastCreated, lastCreatedString, lastSubmission, lastSubmissionString } = this.currentForm;

				if (store.userGroups[0].name === "Clinician") {
					this.addClinicianToActivity();
				}

				const childContent =
					countSubmissions > 0 ? (
						<EntityFormLayout
							{...this.props}
							model={this.activity}
							key={this.activity.id}
							getErrorsForAttribute={this.activity.getErrorsForAttribute}
							formMode={EntityFormMode.VIEW}
							attributeBehaviours={[
								{ name: "modified", behaviour: AttributeFormMode.HIDE },
								{ name: "created", behaviour: AttributeFormMode.HIDE },
								{ name: "clinicianss", behaviour: AttributeFormMode.HIDE },
							]}
						/>
					) : (
						`This ${getModelDisplayName(PatientEntity)} has no submissions for this activity`
					);

				return (
					<PreviewPane
						name={name}
						createdDate={lastCreated}
						submittedDate={lastSubmission}
						created={lastCreatedString}
						submitted={lastSubmissionString}
						countForms={countSubmissions}
						currentFormIndex={this.currentFormIndex}
						goal={false}
						previous={() => {
							this.navigateForms("previous");
						}}
						next={() => {
							this.navigateForms("next");
						}}
						close={() => {
							this.closePreview();
						}}>
						{childContent}
					</PreviewPane>
				);
			}
		}
		return;
	}

	@action
	private navigateForms(action: "previous" | "next") {
		switch (action) {
			case "previous":
				if (this.currentFormIndex > 0) {
					this.currentFormIndex--;
				}
				break;
			case "next":
				if (this.currentFormIndex < this.currentForm.countSubmissions - 1) {
					this.currentFormIndex++;
				}
				break;
		}
		this.getActivity();
		this.getSubmissionDate();
	}

	private async addClinicianToActivity() {
		let clinicianAlreadySeen = false;

		this.activity.clinicianss.forEach((clinicianViewed) => {
			if (clinicianViewed.cliniciansId == this.clinician.id) {
				clinicianAlreadySeen = true;
			}
		});

		if (!clinicianAlreadySeen) {
			let clinicianViewedSubmission = new CliniciansViewedactivitiesSubmissions({ clinicians: this.clinician });
			this.activity.clinicianss.push(clinicianViewedSubmission);
			await this.activity.saveFromCrud(EntityFormMode.EDIT);

			this.getActivities();
			if (this.props.renderProfileTile) this.props.renderProfileTile();
		}
	}

	@action
	private closePreview() {
		this.viewPreview = false;
		this.containerStyle = {};
	}

	private async getSubmissionDate() {
		store.apolloClient.query({ query: querySubmissionDate(this.currentForm.submissions[this.currentFormIndex]) }).then((d) => {
			if (d.data.activitiesSubmissionEntity.created) {
				this.setSubmissionDate(d.data.activitiesSubmissionEntity.created);
			}
		});
	}

	@action
	private setSubmissionDate(submissionDate: any) {
		this.currentForm.lastSubmissionString = moment.utc(submissionDate).local().format("LT Do MMMM YYYY");
	}

	render() {
		return (
			<div className="profile-checkin-page">
				{this.renderActivities()}
				{this.renderPreview()}
			</div>
		);
	}
}
