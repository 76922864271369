import * as React from 'react';
import { observer } from 'mobx-react';
import { action, observable, runInAction } from 'mobx';
import { RouteComponentProps } from 'react-router';
import SecuredPage from 'Views/Components/Security/SecuredPage';
import Navigation, { Orientation } from 'Views/Components/Navigation/Navigation';
import { getFrontendNavLinks } from 'Views/FrontendNavLinks';
import GoalViewTile from 'Views/Tiles/GoalViewTile';
import { GoalsEntity, PatientEntity } from 'Models/Entities';
import { store } from 'Models/Store';
import PatientService from '../../Services/PatientService/PatientService';
import { PatientExpands } from '../../Services/PatientService/PatientEntityExpands';
import { prettyLog } from '../../Util/StringUtils';

interface IGoalsViewPageProps {
	id: string;
}

@observer
export default class GoalsViewPage extends React.Component<RouteComponentProps<IGoalsViewPageProps>> {

	@observable
	public goal: GoalsEntity = new GoalsEntity();

	@observable
	private user: PatientEntity = new PatientEntity();

	async componentDidMount() {
		await this.getUser();
		GoalsEntity.fetch<GoalsEntity>(
			{
				args: [
					[{ path: 'id', comparison: 'equal', value: this.props.match.params.id }],
				],
			},
			new GoalsEntity().defaultExpands
		).then((d) => {
			runInAction(() => {
				this.goal = d[0];
			})
		});
	}

	@action
	private async getUser() {
		if (store.userId != null) {
			try {
				const res = await PatientService.getPatient(store.userId, PatientExpands.patientProfileGoals)
				runInAction(() => {
					this.user = new PatientEntity(res[0]);
				})
			} catch (err) {
				console.log(err);
			}
		}
	}

	@action
	private onClickBackFromGoalsVeiew() {
		store.routerHistory.push('/goals')
	}

	public render() {
		const { history, location, match, staticContext } = this.props;

		const navigationLinks = getFrontendNavLinks(this.props);

		const contents = (
			<SecuredPage groups={["Patient"]}>
				<Navigation
					linkGroups={navigationLinks}
					orientation={Orientation.VERTICAL}
					match={match}
					location={location}
					history={history}
					staticContext={staticContext}
				/>

				<div className="body-content">
					<GoalViewTile goal={this.goal} user={this.user} onClickBack={this.onClickBackFromGoalsVeiew} onClickDelete={this.onClickBackFromGoalsVeiew} onPopUpSaveAndCelebrate={this.onClickBackFromGoalsVeiew} />
				</div>
			</SecuredPage>
		);

		return contents;
	}

}
