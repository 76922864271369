/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import moment from 'moment';
import { action, observable } from 'mobx';
import { Model, IModelAttributes, attribute, entity } from 'Models/Model';
import {IOrderByCondition} from 'Views/Components/ModelCollection/ModelQuery';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from "Util/AttributeUtils";
import { IAcl } from 'Models/Security/IAcl';
import {
	makeFetchManyToManyFunc,
	makeJoinEqualsFunc,
	makeFetchOneToManyFunc,
	makeEnumFetchFunction,
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import { VisitorsGoalsEntity } from 'Models/Security/Acl/VisitorsGoalsEntity';
import { PatientGoalsEntity } from 'Models/Security/Acl/PatientGoalsEntity';
import { ClinicianGoalsEntity } from 'Models/Security/Acl/ClinicianGoalsEntity';
import { CarerGoalsEntity } from 'Models/Security/Acl/CarerGoalsEntity';
import { AdminGoalsEntity } from 'Models/Security/Acl/AdminGoalsEntity';
import * as Enums from '../Enums';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import {SuperAdministratorScheme} from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] on begin
import { getModelDisplayName } from 'Util/EntityUtils';
import { computed } from 'mobx';
import { GoalStepEntity } from "../Entities";
import _ from 'lodash';
// % protected region % [Add any further imports here] end

export interface IGoalsEntityAttributes extends IModelAttributes {
	name: string;
	lifeArea: Enums.lifeArea;
	steps: string;
	levelOfImportance: Enums.levelOfImportance;
	specific: string;
	measurable: string;
	attainable: string;
	template: boolean;
	relevant: string;
	isComplete: boolean;
	completedDate: Date;
	achieveby: Date;
	archived: boolean;

	goalStepss: Array<Models.GoalStepEntity | Models.IGoalStepEntityAttributes>;
	patientId?: string;
	patient?: Models.PatientEntity | Models.IPatientEntityAttributes;
	libraryTagss: Array<Models.GoalsLibraryTags | Models.IGoalsLibraryTagsAttributes>;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('GoalsEntity', 'Goals')
// % protected region % [Customise your entity metadata here] end
export default class GoalsEntity extends Model implements IGoalsEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new VisitorsGoalsEntity(),
		new PatientGoalsEntity(),
		new ClinicianGoalsEntity(),
		new CarerGoalsEntity(),
		new AdminGoalsEntity(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	/**
	 * The default order by field when the collection is loaded .
	 */
	public get orderByField(): IOrderByCondition<Model> | undefined {
		// % protected region % [Modify the order by field here] off begin
		return {
			path: 'name',
			descending: false,
		};
		// % protected region % [Modify the order by field here] end
	}

	// % protected region % [Modify props to the crud options here for attribute 'Name'] on begin
	/* Name of the goal */
	@observable
	@attribute()
	@CRUD({
		name: 'Name',
		displayType: 'textfield',
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
		order: 1,
	})
	public name: string;
	// % protected region % [Modify props to the crud options here for attribute 'Name'] end

	// % protected region % [Modify props to the crud options here for attribute 'Life Area'] on begin
	/**
	 * Area of life the goal relates to
	 */
	@observable
	@attribute()
	@CRUD({
		name: 'Life Area',
		displayType: 'enum-combobox',
		order: 3,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: (attr: string) => {
			return AttrUtils.standardiseEnum(attr, Enums.lifeAreaOptions);
		},
		enumResolveFunction: makeEnumFetchFunction(Enums.lifeAreaOptions),
		displayFunction: (attribute: Enums.lifeArea) => Enums.lifeAreaOptions[attribute],
	})
	public lifeArea: Enums.lifeArea;
	// % protected region % [Modify props to the crud options here for attribute 'Life Area'] end

	// % protected region % [Modify props to the crud options here for attribute 'Steps'] on begin
	/**
	 * JSON string representing all of the steps
	 */
	@observable
	@attribute()
	@CRUD({
		name: 'Steps',
		displayType: 'textarea',
		order: 50,
		headerColumn: false,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public steps: string;
	// % protected region % [Modify props to the crud options here for attribute 'Steps'] end

	// % protected region % [Modify props to the crud options here for attribute 'Level of Importance'] on begin
	/**
	 * How important the goal is
	 */
	@observable
	@attribute()
	@CRUD({
		name: 'Level of Importance',
		displayType: 'enum-combobox',
		order: 10,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: (attr: string) => {
			return AttrUtils.standardiseEnum(attr, Enums.levelOfImportanceOptions);
		},
		enumResolveFunction: makeEnumFetchFunction(Enums.levelOfImportanceOptions),
		displayFunction: (attribute: Enums.levelOfImportance) => Enums.levelOfImportanceOptions[attribute],
	})
	public levelOfImportance: Enums.levelOfImportance;
	// % protected region % [Modify props to the crud options here for attribute 'Level of Importance'] end

	// % protected region % [Modify props to the crud options here for attribute 'Specific'] on begin
	/**
	 * Describe the goal
	 */
	@observable
	@attribute()
	@CRUD({
		name: 'Specific',
		displayType: 'textarea',
		order: 70,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public specific: string;
	// % protected region % [Modify props to the crud options here for attribute 'Specific'] end

	// % protected region % [Modify props to the crud options here for attribute 'Measurable'] on begin
	/**
	 * How you measure ther goal progress
	 */
	@observable
	@attribute()
	@CRUD({
		name: 'Measurable',
		displayType: 'textarea',
		order: 80,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public measurable: string;
	// % protected region % [Modify props to the crud options here for attribute 'Measurable'] end

	// % protected region % [Modify props to the crud options here for attribute 'Attainable'] on begin
	/**
	 * Can you achieve this goal
	 */
	@observable
	@attribute()
	@CRUD({
		name: 'Attainable',
		displayType: 'textarea',
		order: 90,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public attainable: string;
	// % protected region % [Modify props to the crud options here for attribute 'Attainable'] end

	// % protected region % [Modify props to the crud options here for attribute 'Template'] on begin
	/**
	 * A template goal is one that is created by an admin.
	 */
	@observable
	@attribute()
	@CRUD({
		name: 'Template',
		displayType: 'checkbox',
		order: 80,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public template: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'Template'] end

	// % protected region % [Modify props to the crud options here for attribute 'Relevant'] on begin
	/**
	 * Why is the goal worthwhile
	 */
	@observable
	@attribute()
	@CRUD({
		name: 'Relevant',
		displayType: 'textarea',
		order: 100,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public relevant: string;
	// % protected region % [Modify props to the crud options here for attribute 'Relevant'] end

	// % protected region % [Modify props to the crud options here for attribute 'Is Complete'] on begin
	/**
	 * Is the goal done
	 */
	@observable
	@attribute()
	@CRUD({
		name: 'Is Complete',
		displayType: 'checkbox',
		order: 6,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: (attr) => (attr ? 'True' : 'False'),
	})
	public isComplete: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'Is Complete'] end

	// % protected region % [Modify props to the crud options here for attribute 'Completed Date'] off begin
	/**
	 * The date that the goal was completed. 
	 */
	@observable
	@attribute()
	@CRUD({
		name: 'Completed Date',
		displayType: 'datepicker',
		order: 110,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseDate,
	})
	public completedDate: Date;
	// % protected region % [Modify props to the crud options here for attribute 'Completed Date'] end

	// % protected region % [Modify props to the crud options here for attribute 'AchieveBy'] on begin
	/* Date to achieve the goal by */
	@observable
	@attribute()
	@CRUD({
		name: 'Achieve By Date',
		displayType: 'datepicker',
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseDate,
		order: 8,
	})
	public achieveby: Date;
	// % protected region % [Modify props to the crud options here for attribute 'AchieveBy'] end

	// % protected region % [Modify props to the crud options here for attribute 'Archived'] off begin
	/**
	 * Specifies if the goal is archived.
	 */
	@observable
	@attribute()
	@CRUD({
		name: 'Archived',
		displayType: 'checkbox',
		order: 130,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public archived: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'Archived'] end

	@observable
	@attribute({isReference: true})
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Goal Steps'] off begin
		name: "Goal Stepss",
		displayType: 'reference-multicombobox',
		order: 140,
		referenceTypeFunc: () => Models.GoalStepEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'goalStepss',
			oppositeEntity: () => Models.GoalStepEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Goal Steps'] end
	})
	public goalStepss: Models.GoalStepEntity[] = [];

	/**
	 * Goals for the patients
	 */
	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Patient'] on begin
		name: 'Belongs To',
		displayType: 'reference-combobox',
		order: 2,
		referenceTypeFunc: () => Models.PatientEntity,
		// % protected region % [Modify props to the crud options here for reference 'Patient'] end
	})
	public patientId?: string;
	@observable
	@attribute({isReference: true})
	public patient: Models.PatientEntity;

	@observable
	@attribute({isReference: true})
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Library Tags'] off begin
		name: 'Library Tags',
		displayType: 'reference-multicombobox',
		order: 160,
		isJoinEntity: true,
		referenceTypeFunc: () => Models.GoalsLibraryTags,
		optionEqualFunc: makeJoinEqualsFunc('libraryTagsId'),
		referenceResolveFunction: makeFetchManyToManyFunc({
			entityName: 'goalsEntity',
			oppositeEntityName: 'libraryTagEntity',
			relationName: 'goals',
			relationOppositeName: 'libraryTags',
			entity: () => Models.GoalsEntity,
			joinEntity: () => Models.GoalsLibraryTags,
			oppositeEntity: () => Models.LibraryTagEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Library Tags'] end
	})
	public libraryTagss: Models.GoalsLibraryTags[] = [];

	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<IGoalsEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<IGoalsEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.name !== undefined) {
				this.name = attributes.name;
			}
			if (attributes.lifeArea !== undefined) {
				this.lifeArea = attributes.lifeArea;
			}
			if (attributes.steps !== undefined) {
				this.steps = attributes.steps;
			}
			if (attributes.levelOfImportance !== undefined) {
				this.levelOfImportance = attributes.levelOfImportance;
			}
			if (attributes.specific !== undefined) {
				this.specific = attributes.specific;
			}
			if (attributes.measurable !== undefined) {
				this.measurable = attributes.measurable;
			}
			if (attributes.attainable !== undefined) {
				this.attainable = attributes.attainable;
			}
			if (attributes.template !== undefined) {
				this.template = attributes.template;
			}
			if (attributes.relevant !== undefined) {
				this.relevant = attributes.relevant;
			}
			if (attributes.isComplete !== undefined) {
				this.isComplete = attributes.isComplete;
			}
			if (attributes.completedDate !== undefined) {
				if (attributes.completedDate === null) {
					this.completedDate = attributes.completedDate;
				} else {
					this.completedDate = moment(attributes.completedDate).toDate();
				}
			}
			if (attributes.achieveby !== undefined) {
				if (attributes.achieveby === null) {
					this.achieveby = attributes.achieveby;
				} else {
					this.achieveby = moment(attributes.achieveby).toDate();
				}
			}
			if (attributes.archived !== undefined) {
				this.archived = attributes.archived;
			}
			if (attributes.goalStepss !== undefined && Array.isArray(attributes.goalStepss)) {
				for (const model of attributes.goalStepss) {
					if (model instanceof Models.GoalStepEntity) {
						this.goalStepss.push(model);
					} else {
						this.goalStepss.push(new Models.GoalStepEntity(model));
					}
				}
			}
			if (attributes.patient !== undefined) {
				if (attributes.patient === null) {
					this.patient = attributes.patient;
				} else {
					if (attributes.patient instanceof Models.PatientEntity) {
						this.patient = attributes.patient;
						this.patientId = attributes.patient.id;
					} else {
						this.patient = new Models.PatientEntity(attributes.patient);
						this.patientId = this.patient.id;
					}
				}
			} else if (attributes.patientId !== undefined) {
				this.patientId = attributes.patientId;
			}
			if (attributes.libraryTagss !== undefined && Array.isArray(attributes.libraryTagss)) {
				for (const model of attributes.libraryTagss) {
					if (model instanceof Models.GoalsLibraryTags) {
						this.libraryTagss.push(model);
					} else {
						this.libraryTagss.push(new Models.GoalsLibraryTags(model));
					}
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] on begin
			if (attributes.isComplete != null) {
				this.isComplete = attributes.isComplete;
			}
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] off begin
	public defaultExpands = `
		libraryTagss {
			${Models.GoalsLibraryTags.getAttributes().join('\n')}
			libraryTags {
				${Models.LibraryTagEntity.getAttributes().join('\n')}
			}
		}
		goalStepss {
			${Models.GoalStepEntity.getAttributes().join('\n')}
		}
		patient {
			${Models.PatientEntity.getAttributes().join('\n')}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] off begin
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath = {
			libraryTagss: {},
			goalStepss: {},
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
							'goalStepss',
							'libraryTagss',
						]
					},
				],
			}
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] on begin
		return this.name;
		// % protected region % [Customise the display name for this entity] end
	}


	// % protected region % [Add any further custom model features here] on begin
	@computed
	get progressPercentage(): number {
		const numSteps = this.goalStepss.length;
		const numCompletedSteps = this.goalStepss.filter(step => step.completed).length;

		return numSteps === 0
			? 0
			: (numCompletedSteps / numSteps) * 100;
	}

	@computed
	get progressPercentageToString(): string {
		return `${this.progressPercentage.toFixed(0)}%`;
	}

	@computed
	public get lifeAreaToString() {
		return _.upperFirst(this.lifeArea?.toLowerCase());
	}

	@action
	public removeEmptyGoals() {
		this.goalStepss = this.goalStepss.filter((step: GoalStepEntity) => (step.name !== undefined && step.name !== ""));
	}

	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [ createdAttr, modifiedAttr ] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(GoalsEntity.prototype, 'created');
CRUD(modifiedAttr)(GoalsEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
