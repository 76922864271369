import { action, observable, runInAction } from 'mobx'
import { observer } from 'mobx-react'
import { PatientEntity, TagEntity } from 'Models/Entities'
import React, { Component } from 'react'
import { Button, Colors, Display, Sizes } from '../Button/Button'
import { ButtonGroup } from '../Button/ButtonGroup'
import { Checkbox } from '../Checkbox/Checkbox'
import { ICollectionItemActionProps } from '../Collection/Collection'
import { ICollectionHeaderProps } from '../Collection/CollectionHeaders'
import { MultiCombobox } from '../Combobox/MultiCombobox'
import { DatePicker } from '../DatePicker/DatePicker'
import { ModelCollection } from '../ModelCollection/ModelCollection'
import { DashboardTableFilterOptions, DashboardTableOptions, IDashboardProps } from './DashboardCommon';
import { Link } from 'react-router-dom';
import { SERVER_URL } from '../../../Constants';
import { IFilter } from '../Collection/CollectionFilterPanel'
import { ApiQueryParams, IOrderByCondition } from '../ModelCollection/ModelAPIQuery';
import axios from 'axios'
import alert from '../../../Util/ToastifyUtils';
import moment from 'moment';

class PatientTodosTableOptions extends DashboardTableOptions {
	orderBy: IOrderByCondition<PatientEntity> = {
		path: 'forename', descending: true
	}

	@observable
	public fromTodosCreatedDate: Date | null = null;

	@observable
	public toTodosCreatedDate: Date | null = null;

	@observable
	public noTodosUsers: boolean | false;

	@action
	setFilterOptions = (
		selectedTagIds: string[],
		selecetedPatientIds: string[],
		selectedTodosFromCreatedDate: Date | null,
		selectedTodosToCreatedDate: Date | null,
		selectedNoTodosUsers: boolean | false
	) => {

		let parsedTagIds: string[] = [];
		selectedTagIds?.forEach(id => parsedTagIds.push(id));
		this.tagIds = parsedTagIds;

		let parsedPatientIds: string[] = [];
		selecetedPatientIds?.forEach(id => parsedPatientIds.push(id));
		this.patientIds = parsedPatientIds;

		const MS_PER_MINUTE = 60000;

		if (selectedTodosFromCreatedDate != null) {
			// serverside stopped casting timezones after the bot upgrade so this is no longer needed - leaving this here cos timezones has been a cluster of a mess. .
			// selectedTodosFromCreatedDate = new Date(selectedTodosFromCreatedDate.getTime() + (selectedTodosFromCreatedDate.getTimezoneOffset() * MS_PER_MINUTE))
		}

		if (selectedTodosToCreatedDate != null) {
			// serverside stopped casting timezones after the bot upgrade so this is no longer needed - leaving this here cos timezones has been a cluster of a mess. .
			selectedTodosToCreatedDate.setHours(23);
			selectedTodosToCreatedDate.setMinutes(59);
			selectedTodosToCreatedDate.setSeconds(59);

			// selectedTodosToCreatedDate = new Date(selectedTodosToCreatedDate.getTime() + (selectedTodosToCreatedDate.getTimezoneOffset() * MS_PER_MINUTE))
			// selectedTodosToCreatedDate = moment(selectedTodosToCreatedDate).add(24, 'hours').toDate();
		}

		this.fromTodosCreatedDate = selectedTodosFromCreatedDate;
		this.toTodosCreatedDate = selectedTodosToCreatedDate;
		this.noTodosUsers = selectedNoTodosUsers;
	}
}
/**
 * Class for storing the selected data from the filter data table.
 * These are not passed into the table until we click `run report`.
 */
export class PatientTodosTableFilterOptions extends DashboardTableFilterOptions {
	@observable
	public selectedTodosFromCreatedDate: Date | null;

	@observable
	public selectedTodosToCreatedDate: Date | null;

	@observable
	public selectedNoTodosUsers: boolean | false;
}

@observer
export default class DashboardToDo extends Component<IDashboardProps> {

	@observable
	public filterOptions: PatientTodosTableFilterOptions = new PatientTodosTableFilterOptions();

	@observable
	public tableOptions: PatientTodosTableOptions = new PatientTodosTableOptions();

	@action
	setFilterOptionTagOptions() {
		this.filterOptions.setTagOptionsAndIncludeAll(this.props.tags);
		this.filterOptions.setPatientOptionsAndIncludeAll(this.props.patients);
	}

	componentDidMount() {
		this.setFilterOptionTagOptions();
	}

	render() {
		return (
			<div>
				<div className="dashboard-goal-todo-row">
					{this.renderPatientTodosTableFilterOptions()}
				</div>
				{this.renderPatientTodosTable()}
			</div>
		)
	}

	renderPatientTodosTableFilterOptions = () => {
		return (
			<div className="dashboard-grid-item dash-goal-todo-filter-grid">
				<h1 className="filter-title">Filter data</h1>
				<div className="dashboard-goal-todo-filter">
					<p>Filter, view, and export the full table of data below.</p>
					<div className='dashboard-report'>
						<ButtonGroup>
							<Button
								className="dashboard-button__run-report"
								display={Display.Solid}
								colors={Colors.Black}
								onClick={() => this.handleOnClickRunReport()}
							>
								Run Report
						</Button>
							<Button
								className="dashboard-button__run-report"
								display={Display.Solid}
								colors={Colors.Black}
								onClick={() => this.handleOnClickExportReport()}
							>
								Export
						</Button>
						</ButtonGroup>
					</div>
				</div>
				<div className='dashboard-goal-todo-table-filter__row'>
					<MultiCombobox
						className='dashboard-goal-todo-table-filter__multicombobox'
						placeholder="Select tags"
						model={this.filterOptions}
						modelProperty="selectedTagIds"
						label="Tag"
						options={this.filterOptions.tagOptions || []}
						isClearable
						onAfterChange={(event, data) => {
							this.filterOptions.setPatientOptionsFilteredByTags(data.value as string[], this.props.patients);
						}}
					/>
					<MultiCombobox
						className='dashboard-goal-todo-table-filter__multicombobox'
						placeholder={"Select patients"}
						model={this.filterOptions}
						modelProperty="selectedPatientIds"
						label="Patients"
						options={this.filterOptions.patientOptions || []}
						isClearable
					/>
					<DatePicker
						className='dashboard-goal-todo-table-filter__datepicker'
						label="From Todo Date"
						model={this.filterOptions}
						modelProperty="selectedTodosFromCreatedDate"
						flatpickrOptions={{
							dateFormat: 'd/m/Y',
						}}
					/>
					<DatePicker
						className='dashboard-goal-todo-table-filter__datepicker'
						label="To Todo Date"
						model={this.filterOptions}
						modelProperty="selectedTodosToCreatedDate"
						flatpickrOptions={{
							dateFormat: 'd/m/Y',
						}}
					/>
					<Checkbox
						className='dashboard-no-goals-user__checkbox '
						label="Show users without to-dos"
						model={this.filterOptions}
						modelProperty="selectedNoTodosUsers"
					/>
				</div>
			</div>
		)
	}

	handleOnClickRunReport = () => {

		let parsedSelectedTagIds = this.filterOptions.getParsedSelectedTagIds();
		let parsedSelectedPatientIds = this.filterOptions.getParsedSelectedPatientIds();

		this.tableOptions.setFilterOptions(
			parsedSelectedTagIds,
			parsedSelectedPatientIds,
			this.filterOptions.selectedTodosFromCreatedDate,
			this.filterOptions.selectedTodosToCreatedDate,
			this.filterOptions.selectedNoTodosUsers

		);
	}

	handleOnClickExportReport = () => {
		axios.get(
			"/api/entity/PatientEntity/dashboard_export_todos",
			{
				params: this.getMoreParams(),
			}
		).then((result) => {
			const blob = new Blob([result.data], { type: "text/csv;charset=utf-8" });
			saveAs(blob, `export-dashoboard-todo.csv`);
		}).catch(() => {
			alert('Unsuccessfully exported dashboard todos', 'error');
		})
	}

	renderPatientTodosTable = () => {

		return (
			<div className="dashboard-grid-item dashboard-table">
				<ModelCollection
					url="/api/entity/PatientEntity/patient_list"
					isApiQuery
					model={PatientEntity}
					orderBy={this.tableOptions.orderBy}
					perPage={this.tableOptions.paginationQueryOptions.perPage}
					headers={this.getHeaders()}
					actions={this.getActions()}
					getMoreParams={this.getMoreParams}
				/>
			</div>
		)
	}


	getMoreParams = (filters?: Array<IFilter<PatientEntity>>, filterApplied?: boolean): ApiQueryParams => {
		const filterParams = {};

		// Check what filters have been selected and is ready to be passed to the table.
		if (!!this.tableOptions.tagIds) {
			filterParams['tagIds'] = JSON.stringify(this.tableOptions.tagIds.map(tag => tag));
		}

		if (!!this.tableOptions.patientIds) {
			filterParams['patientIds'] = JSON.stringify(this.tableOptions.patientIds.map(tag => tag));
		}

		if (!!this.tableOptions.fromTodosCreatedDate) {
			filterParams['fromTodosCreatedDate'] = this.tableOptions.fromTodosCreatedDate;
		}

		if (!!this.tableOptions.toTodosCreatedDate) {
			filterParams['toTodosCreatedDate'] = this.tableOptions.toTodosCreatedDate;
		}
		if (!!this.tableOptions.noTodosUsers) {
			filterParams['noTodosUsers'] = this.tableOptions.noTodosUsers;
		}

		return filterParams;
	};
	getHeaders = (): Array<ICollectionHeaderProps<PatientEntity>> => {
		return [
			{
				name: 'surname',
				displayName: 'Surname',
				sortable: true,

			},
			{
				name: 'forename',
				displayName: 'First Name',
				sortable: true,

			},
			{
				name: 'todosCreatedByUser',
				displayName: 'To-Dos created by user',
				sortable: true,
			},
			{
				name: 'todosAssignedToUser',
				displayName: 'To-Dos assigned to user',
				sortable: true,
			},
			{
				name: 'totalTodos',
				displayName: 'Total To-Dos',
				sortable: true,
			},
			{
				name: 'todosNotCompleted',
				displayName: 'To-Dos not completed',
				sortable: true,
			},

			{
				name: 'todosCompletedPercentage',
				displayName: '% completed',
				sortable: true,
				transformItem: (item) => {
					return item.todosCompletedPercentage + "%";
				}
			},
		];
	};
	getActions = () => {
		const tableActions: Array<ICollectionItemActionProps<PatientEntity>> = [];

		tableActions.push({
			action: item => {
			},
			label: 'View Profile',
			customButton: (item) => (
				<Link to={`${SERVER_URL}/profile/patient/${item.id}`}>
					<Button colors={Colors.Primary} display={Display.Text} sizes={Sizes.Small}>View Profile</Button>
				</Link>
			)
		});
		return tableActions;
	};
}
