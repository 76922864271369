/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import { Model, IModelAttributes, attribute, entity } from 'Models/Model';
import * as Models from 'Models/Entities';
import { IAcl } from '../Security/IAcl';
import { observable } from 'mobx';
import { VisitorsRatingtemplatesReferenceManyToMany } from '../Security/Acl/VisitorsRatingtemplatesReferenceManyToMany';
import { PatientRatingtemplatesReferenceManyToMany } from '../Security/Acl/PatientRatingtemplatesReferenceManyToMany';
import { ClinicianRatingtemplatesReferenceManyToMany } from '../Security/Acl/ClinicianRatingtemplatesReferenceManyToMany';
import { CarerRatingtemplatesReferenceManyToMany } from '../Security/Acl/CarerRatingtemplatesReferenceManyToMany';
import { AdminRatingtemplatesReferenceManyToMany } from '../Security/Acl/AdminRatingtemplatesReferenceManyToMany';

// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface ITagsRatingtemplatesAttributes extends IModelAttributes {
	tagsId: string;
	ratingtemplatesId: string;

	tags: Models.TagEntity | Models.ITagEntityAttributes;
	ratingtemplates: Models.RatingtemplateEntity | Models.IRatingtemplateEntityAttributes;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

@entity('TagsRatingtemplates')
export default class TagsRatingtemplates extends Model implements ITagsRatingtemplatesAttributes {
	public static acls: IAcl[] = [
		new VisitorsRatingtemplatesReferenceManyToMany(),
		new PatientRatingtemplatesReferenceManyToMany(),
		new ClinicianRatingtemplatesReferenceManyToMany(),
		new CarerRatingtemplatesReferenceManyToMany(),
		new AdminRatingtemplatesReferenceManyToMany(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	@observable
	@attribute()
	public tagsId: string;

	@observable
	@attribute()
	public ratingtemplatesId: string;

	@observable
	@attribute({isReference: true})
	public tags: Models.TagEntity;

	@observable
	@attribute({isReference: true})
	public ratingtemplates: Models.RatingtemplateEntity;
	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	constructor(attributes?: Partial<ITagsRatingtemplatesAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		if (attributes) {
			if (attributes.tagsId) {
				this.tagsId = attributes.tagsId;
			}
			if (attributes.ratingtemplatesId) {
				this.ratingtemplatesId = attributes.ratingtemplatesId;
			}

			if (attributes.tags) {
				if (attributes.tags instanceof Models.TagEntity) {
					this.tags = attributes.tags;
					this.tagsId = attributes.tags.id;
				} else {
					this.tags = new Models.TagEntity(attributes.tags);
					this.tagsId = this.tags.id;
				}
			} else if (attributes.tagsId !== undefined) {
				this.tagsId = attributes.tagsId;
			}

			if (attributes.ratingtemplates) {
				if (attributes.ratingtemplates instanceof Models.RatingtemplateEntity) {
					this.ratingtemplates = attributes.ratingtemplates;
					this.ratingtemplatesId = attributes.ratingtemplates.id;
				} else {
					this.ratingtemplates = new Models.RatingtemplateEntity(attributes.ratingtemplates);
					this.ratingtemplatesId = this.ratingtemplates.id;
				}
			} else if (attributes.ratingtemplatesId !== undefined) {
				this.ratingtemplatesId = attributes.ratingtemplatesId;
			}
		}

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}