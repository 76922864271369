import React, { Component } from "react";
import { RouteComponentProps } from "react-router";
import { gql } from "apollo-boost";
import { store } from "Models/Store";
import { observable, action, toJS } from "mobx";
import { observer } from "mobx-react";
import Header from "Views/Components/Header/Header";
import ToDo from "Views/Components/ToDo/ToDo";
import moment from "moment";
import { TextField } from "Views/Components/TextBox/TextBox";
import Popup from "reactjs-popup";
import alert from "Util/ToastifyUtils";
import ToDoTask from "Views/Components/ToDo/ToDoTask";
import { TodoEntity } from "Models/Entities";
import { todotype } from "Models/Enums";

// Gets a list of todo items owned by the user
function queryToDos(owner: string, equality: string, complete: boolean, take?: number) {
	if (equality === 'notEqual') {
		equality = 'equal, negate: true';
	}
	return gql`
        query todo {
            todoEntitys(
                where: [
                    { path: "owner", comparison: equal, value: "${owner}" }
                    { path: "complete", comparison: ${equality}, value: "true" }		
                    { path: "complete", comparison: ${equality}, value: "true" }											
                ]
				orderBy: [{ path: "duedate", descending: false }, {path: "title"}]
				${complete ? `take: ${take} ` : ``}
            ) {
                id
                owner
                title
                description
                duedate
                complete
                types
				typeid
                created
                patientId
            }
        }
    `;
}

// Gets a list of todo items for a given patient
function queryPatientToDos(patient: string, equality: string) {
	if (equality === 'notEqual') {
		equality = 'equal, negate: true';
	}
	return gql`
	query todo {
		todoEntitys(
			where: [
				{ path: "patientId", comparison: equal, value: "${patient}" }
				{ path: "complete", comparison: ${equality}, value: "true" }
			]
			orderBy: [{ path: "duedate", descending: false }, {path: "title"}]
		) {
			id
			owner
			title
			description
			duedate
			complete
			types
			typeid
			created
            modified
            patientId
		}
	}
`;
}

// Interface to store information on a task (todo)
interface task {
	id: string;
	title: string;
	description: string;
	complete: boolean;
	duedate: Date;
	types: todotype;
	typeid: string;
	owner: string;
	created: Date;
	modified: Date;
	dueString?: string;
	patientId?: string;
	expired?: boolean;
}

interface IToDoProps extends RouteComponentProps {
	displayHeader: boolean;
	userId: string;
	userGroup: string;
	setNumToDos?: (numToDo: number) => number;
	renderProfileTile?: () => void;
}

@observer
export default class ToDoTile extends Component<IToDoProps> {
	componentDidMount() {
		this.getToDos(this.props.userId!);
	}

	@observable
	private newTask = {
		title: "",
	};

	// Stores information of the current active task
	@observable
	private activeTask: task | undefined;

	// Array of items in the todo list as JSX elements
	@observable
	private toDoItems: JSX.Element[] = [];

	// The active todo task, initially rendered as an empty closed div
	@observable
	private toDoTask: JSX.Element = (<div className="to-do-task to-do-task-closed"/>);

	@observable
	private toDoItemsCompleted: JSX.Element[] = [
		<div
			key="x2x2x2"
			className="completed-task-button icon-left icon-chevron-right"
			onClick={() => this.handleToDoItemsCompleted()}
		>
			Completed
		</div>,
	];

	// Function to handle the event of clicking the completed to-do button. Changes the chevron icon, sets the render completed status and gets the completed todos
	@action
	private handleToDoItemsCompleted() {
		this.completeDropDownIcon = this.completeDropDownIcon == "icon-chevron-right" ? "icon-chevron-down" : "icon-chevron-right";
		this.renderCompleted = !this.renderCompleted;
		this.getToDos(this.props.userId!);
	}

	// Global scope variable for keeping track of whether the completed todo items should be rendered
	private renderCompleted = false;

	// Icon for the completed todo drop down button
	@observable
	private completeDropDownIcon = "icon-chevron-right";

	// Gets the todo items for a given user
	public async getToDos(owner: string) {
		const patient = this.props.userGroup === "Patient";
		const take = patient ? 5 : 10;
		const completed = this.renderCompleted;

		try {
			// Declare 4 possible API calls that this function can make
			const owned = await store.apolloClient.query({
				query: queryToDos(owner, "notEqual", false),
				fetchPolicy: "network-only",
			});

			const ownedComplete = await store.apolloClient.query({
				query: queryToDos(owner, "equal", true, take),
				fetchPolicy: "network-only",
			});

			const assigned = await store.apolloClient.query({
				query: queryPatientToDos(owner, "notEqual"),
				fetchPolicy: "network-only",
			});

			const assignedComplete = await store.apolloClient.query({
				query: queryPatientToDos(owner, "equal"),
				fetchPolicy: "network-only",
			});
			

			if (patient) {
				if (completed) {
					Promise.all([owned, ownedComplete, assigned, assignedComplete]).then((d) => {
						this.renderToDos(d);
					});
				} else {
					Promise.all([owned, assigned]).then((d) => {
						this.renderToDos(d);
					});
				}
			} else {
				if (completed) {
					Promise.all([owned, ownedComplete]).then((d) => {
						this.renderToDos(d, false);
					});
				} else {
					Promise.all([owned]).then((d) => {
						this.renderToDos(d, false);
					});
				}
			}
		} catch (error) {
			console.log("Apolloclient not defined in testing environment");
		}
	}

	// Renders the returned list of todo items
	@action
	public renderToDos(data: any[], patient?: false) {
		this.toDoItems = [];
		const toDoIds = new Set;
		// have to make up a fake key as this item is not part of the actuall list
		this.toDoItemsCompleted = [
			<div
				key="x1x1x1"
				className={`completed-task-button icon-left ${this.completeDropDownIcon}`}
				onClick={() => this.handleToDoItemsCompleted()}
			>
				Completed
			</div>,
		];

		// Combines the arrays returned from the multiple API requests into one
		data
			.reduce((arr: any, row: any) => {
				return arr.concat(row.data.todoEntitys);
			}, [])
			.map((task: any) => {
				if (patient == false) {
					if (task.patientId) {
						return;
					}
				}
				const {
					id,
					owner,
					title,
					duedate,
					description,
					type,
					typeid,
					complete,
					created,
					modified,
					patientId,
				} = task;
				// ensures no duplicates
				if (toDoIds.has(id)) {
					return; 
				}
				toDoIds.add(id);
				const dueString = this.getDueString(duedate, complete, modified);
				let expired: boolean = false;
				if (dueString === "Expired") {
					expired = true;
				}

				let toDoType = "toDoItems";
				if (complete) {
					toDoType = "toDoItemsCompleted";
				}

				this[toDoType].push(
					<ToDo
						key={id}
						id={id}
						title={title}
						owner={owner}
						description={description}
						duedate={duedate}
						type={type}
						typeid={typeid}
						complete={complete}
						dueString={dueString}
						expired={expired}
						patientId={patientId}
						onClickOpen={() =>
							this.setActiveToDoTask(
								id,
								title,
								description,
								owner,
								type,
								typeid,
								complete,
								created,
								modified,
								duedate,
								dueString,
								patientId,
								expired
							)
						}
						onClickComplete={() => this.completeToDo()}
					/>
				);
			});

		// Renders a prompt if the user has no incomplete todo items
		if (this.toDoItems.length < 1) {
			this.toDoItems = [
				<div key="x0x0x0">
					<b>You do not have any active items in your to-do list. Add some below!</b>
				</div>,
			];
		}
	}

	// Completes a todo item (or unchecks it as being complete)
	@action
	completeToDo() {
		// If the active task is open, change the rendered icon to relfect the status of the todo item
		if (this.activeTask) {
			this.activeTask.complete = !this.activeTask.complete;
			this.renderToDoTask();
		}
		// Specify that we should now render the completed todo items and then fetch the items again
		this.renderCompleted = true;
		this.getToDos(this.props.userId!);
		if (this.props.renderProfileTile) this.props.renderProfileTile();
	}

	// Function to get the string representation of a todo items due date
	private getDueString(
		dueDate: Date | undefined,
		complete: boolean | undefined,
		modified: Date
	): string | undefined {
		if (complete) {
			let modifiedDate = moment.utc(modified);
			return `Completed on ${modifiedDate.local().format("ddd D MMM")}`;
		}

		if (dueDate) {
			const now = moment();
			const localDueDate = moment.utc(dueDate).local();

			if (localDueDate.isSame(now, 'day')) {
				return 'Today';
			} else if (localDueDate.isSame(now.add(1, 'days'), 'day')) {
				return 'Tomorrow';
			} else if (localDueDate.isSameOrBefore(now)) {
				return 'Expired';
			}

			return localDueDate.format('ddd D MMM');
		}
		return undefined;
	}

	// Set Active Todo Item ------------------------------------------------------------------------------------------------------------------- //

	// Sets all of the attributes for the active todo task
	@action
	setActiveToDoTask(
		id: string,
		title: string,
		description: string,
		owner: string,
		types: todotype,
		typeid: string,
		complete: boolean,
		created: Date,
		modified: Date,
		duedate: Date,
		dueString?: string,
		patientId?: string,
		expired?: boolean
	) {
		this.activeTask = {
			id: id,
			title: title,
			description: description,
			owner: owner,
			types: types,
			typeid: typeid,
			complete: complete,
			created: created,
			modified: modified,
			duedate: duedate,
			dueString: dueString,
			patientId: patientId,
			expired: expired,
		};
		this.renderToDoTask();
	}


	// Renders the active todo task panel
	@action
	renderToDoTask() {
		if (this.activeTask) {
			const {
				id,
				title,
				description,
				owner,
				types,
				typeid,
				complete,
				created,
				duedate,
				dueString,
				patientId,
				expired,
			} = this.activeTask;

			const createString = moment(created).format("ddd D MMM");
			const readonly =
				owner === this.props.userId ||
				store.userGroups[0].name === "Clinician" ||
				store.userGroups[0].name === "Admin"
					? false
					: true;

			this.width = { left: { width: "55%" }, right: { width: "45%" } };

			if (window.innerWidth < 1200) {
				this.width = { left: { width: "0%" }, right: { width: "100%" } };
			}

			this.toDoTask = (
				<ToDoTask
					key={id}
					id={id}
					title={title}
					owner={owner}
					description={description}
					duedate={duedate}
					types={types}
					typeid={typeid}
					complete={complete}
					dueString={dueString}
					createString={createString}
					patientId={patientId}
					onClickClose={() => this.closeToDoTask()}
					onClickDelete={() => {
						this.closeToDoTask();
						this.getToDos(this.props.userId!);
					}}
					onClickSave={() => this.getToDos(this.props.userId!)}
					readOnly={readonly}
					expired={expired}
				/>
			);
		}
	}

	// Closes the active todo task panel
	@action
	private closeToDoTask() {
		this.width = { left: {}, right: {} };
		this.activeTask = undefined;
	}

	// Creates a new todo item (also performs validation)
	@action
	private createToDo(event: any, type: todotype) {
		event.preventDefault();
		if (this.newTask.title !== "") {
			this.revertAddIcon();

			const newToDo = new TodoEntity({
				title: this.newTask.title,
				types: type,
				patientId: this.props.userId == store.userId ? this.props.userId : undefined,
			});

			newToDo.save().then(() => {
				this.renderCreatedTodo(newToDo, type);
				if (this.props.renderProfileTile) this.props.renderProfileTile();
			});
		} else {
			alert("Please enter a task name");
		}
	}

	// Renders the newly created todo task
	@action
	renderCreatedTodo = (newToDo: TodoEntity, type: todotype) => {
		this.getToDos(this.props.userId!);
		const createString = moment().format("ddd D MMM");

		this.toDoTask = (
			<ToDoTask
				key={newToDo.id}
				id={newToDo.id}
				title={this.newTask.title}
				owner={store.userId!}
				types={type}
				complete={false}
				createString={createString}
				onClickClose={() => this.closeToDoTask()}
				onClickDelete={() => {
					this.closeToDoTask();
					this.getToDos(this.props.userId!);
				}}
				onClickSave={() => this.getToDos(this.props.userId!)}
				patientId={this.props.userId !== store.userId ? this.props.userId : undefined}
			/>
		);
		this.newTask.title = "";

		this.width = { left: { width: "55%" }, right: { width: "45%" } };

		if (window.innerWidth < 1200) {
			this.width = { left: { width: "0%" }, right: { width: "100%" } };
		}
	};

	// Changes the icon next to the input field and adds popup options to choose a specific kind of task
	@action
	private changeAddIcon = () => {
		this.addIcon = "icon-circle";
		this.addType = (
			<Popup
				trigger={<div className="todo-add-type icon-left icon-link-1" />}
				position="top right"
				closeOnDocumentClick
			>
				{(close) => (
					<>
						<div
							className="popup-item icon-left icon-yhp-library"
							onClick={(e) => {
								close();
								this.createToDo(e, "ARTICLE");
							}}
						>
							Article
						</div>
						<div
							className="popup-item icon-left icon-quiz"
							onClick={(e) => {
								close();
								this.createToDo(e, "ACTIVITY");
							}}
						>
							Activity
						</div>
						<div
							className="popup-item icon-left icon-question"
							onClick={(e) => {
								close();
								this.createToDo(e, "CUSTOM");
							}}
						>
							Other
						</div>
					</>
				)}
			</Popup>
		);
	};

	// Changes the add icon back to a plus and removes the type selector
	@action
	revertAddIcon = () => {
		this.addIcon = "icon-plus";
		this.addType = <></>;
	};

	// Icon next to the todo item input
	@observable
	private addIcon = "icon-plus";

	// Type icon for the todo item input
	@observable
	private addType: JSX.Element;

	@observable
	private width = { left: {}, right: {} };

	private handleEnterPress(e: React.KeyboardEvent<Element>) {
		if (e.keyCode === 13) {
			e.preventDefault();
			this.createToDo(e, "CUSTOM");
		}
	}

	render() {
		return (
			<div className="todo-page">
				{this.props.displayHeader ? <Header title="To-Do" subtitle="Home /" /> : <></>}
				<div className="todo-content">
					<div className="todo-left" style={toJS(this.width.left)}>
						<div className="todo-list" onClickCapture={() => this.closeToDoTask()}>
							{this.toDoItems}
							{this.toDoItemsCompleted}
						</div>
						<div className="todo-add">
							<div className="todo-add-field" onClick={this.changeAddIcon}>
								<div className={`todo-add-icon icon-right ${this.addIcon}`} />
								<TextField
									className="todo-add-task"
									model={this.newTask}
									modelProperty="title"
									inputProps={{ onKeyUp: (e) => this.handleEnterPress(e) }}
								/>
								{this.addType}
							</div>
						</div>
					</div>
					<div className="todo-right" style={toJS(this.width.right)}>
						{this.toDoTask}
					</div>
				</div>
			</div>
		);
	}
}
