import { PatientEntity } from 'Models/Entities';

export default class PatientService {

	public static getAllPatient(expandString?: string) {
		return PatientEntity
			.fetch<PatientEntity>(
				{},
				expandString ? expandString : '',
				{},
				true
			)
	}

	public static getPatient(id: string, expandString?: string): Promise<PatientEntity[]> {
		return PatientEntity.fetch<PatientEntity>(
			{
				args: [
					[
						{ path: "id", comparison: "equal", value: id }
					]
				]
			}
			, expandString ? expandString : ''
			, {}, true
		)
	}
}

