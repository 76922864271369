/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import { action, observable } from 'mobx';
import { Model, IModelAttributes, attribute, entity } from 'Models/Model';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from "Util/AttributeUtils";
import { IAcl } from 'Models/Security/IAcl';
import {
	makeFetchOneToManyFunc,
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import { VisitorsAdminEntity } from 'Models/Security/Acl/VisitorsAdminEntity';
import { PatientAdminEntity } from 'Models/Security/Acl/PatientAdminEntity';
import { ClinicianAdminEntity } from 'Models/Security/Acl/ClinicianAdminEntity';
import { CarerAdminEntity } from 'Models/Security/Acl/CarerAdminEntity';
import { AdminAdminEntity } from 'Models/Security/Acl/AdminAdminEntity';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import {SuperAdministratorScheme} from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface IAdminEntityAttributes extends IModelAttributes {
	email: string;

	demoCliniciansId?: string;
	demoClinicians?: Models.ClinicianEntity | Models.IClinicianEntityAttributes;
	demoPatientId?: string;
	demoPatient?: Models.PatientEntity | Models.IPatientEntityAttributes;
	todoss: Array<Models.TodoEntity | Models.ITodoEntityAttributes>;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('AdminEntity', 'Admin')
// % protected region % [Customise your entity metadata here] end
export default class AdminEntity extends Model implements IAdminEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new VisitorsAdminEntity(),
		new PatientAdminEntity(),
		new ClinicianAdminEntity(),
		new CarerAdminEntity(),
		new AdminAdminEntity(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		'email',
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	// % protected region % [Modify props to the crud options here for attribute 'Email'] on begin
	@Validators.Email()
	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		name: 'Email',
		displayType: 'displayfield',
		createFieldType: 'textfield',
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
		order: 1,
	})
	public email: string;
	// % protected region % [Modify props to the crud options here for attribute 'Email'] end

	// % protected region % [Modify props to the crud options here for attribute 'Password'] on begin
	@Validators.Length(6)
	@observable
	@CRUD({
		name: 'Password',
		displayType: 'hidden',
		createFieldType: 'password',
		order: 2,
	})
	public password: string;
	// % protected region % [Modify props to the crud options here for attribute 'Password'] end

	// % protected region % [Modify props to the crud options here for attribute 'Confirm Password'] off begin
	@Validators.Custom('Password Match', (e: string, target: AdminEntity) => {
		return new Promise(resolve => resolve(target.password !== e ? "Password fields do not match" : null))
	})
	@observable
	@CRUD({
		name: 'Confirm Password',
		displayType: 'hidden',
		order: 30,
		createFieldType: 'password',
	})
	public _confirmPassword: string;
	// % protected region % [Modify props to the crud options here for attribute 'Confirm Password'] end

	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Demo Clinicians'] off begin
		name: 'Demo Clinicians',
		displayType: 'reference-combobox',
		order: 40,
		referenceTypeFunc: () => Models.ClinicianEntity,
		// % protected region % [Modify props to the crud options here for reference 'Demo Clinicians'] end
	})
	public demoCliniciansId?: string;
	@observable
	@attribute({isReference: true})
	public demoClinicians: Models.ClinicianEntity;

	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Demo Patient'] off begin
		name: 'Demo Patient',
		displayType: 'reference-combobox',
		order: 50,
		referenceTypeFunc: () => Models.PatientEntity,
		// % protected region % [Modify props to the crud options here for reference 'Demo Patient'] end
	})
	public demoPatientId?: string;
	@observable
	@attribute({isReference: true})
	public demoPatient: Models.PatientEntity;

	/**
	 * ToDo
	 */
	@observable
	@attribute({isReference: true})
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'ToDos'] off begin
		name: "ToDoss",
		displayType: 'reference-multicombobox',
		order: 60,
		referenceTypeFunc: () => Models.TodoEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'todoss',
			oppositeEntity: () => Models.TodoEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'ToDos'] end
	})
	public todoss: Models.TodoEntity[] = [];

	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<IAdminEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<IAdminEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.email !== undefined) {
				this.email = attributes.email;
			}
			if (attributes.demoClinicians !== undefined) {
				if (attributes.demoClinicians === null) {
					this.demoClinicians = attributes.demoClinicians;
				} else {
					if (attributes.demoClinicians instanceof Models.ClinicianEntity) {
						this.demoClinicians = attributes.demoClinicians;
						this.demoCliniciansId = attributes.demoClinicians.id;
					} else {
						this.demoClinicians = new Models.ClinicianEntity(attributes.demoClinicians);
						this.demoCliniciansId = this.demoClinicians.id;
					}
				}
			} else if (attributes.demoCliniciansId !== undefined) {
				this.demoCliniciansId = attributes.demoCliniciansId;
			}
			if (attributes.demoPatient !== undefined) {
				if (attributes.demoPatient === null) {
					this.demoPatient = attributes.demoPatient;
				} else {
					if (attributes.demoPatient instanceof Models.PatientEntity) {
						this.demoPatient = attributes.demoPatient;
						this.demoPatientId = attributes.demoPatient.id;
					} else {
						this.demoPatient = new Models.PatientEntity(attributes.demoPatient);
						this.demoPatientId = this.demoPatient.id;
					}
				}
			} else if (attributes.demoPatientId !== undefined) {
				this.demoPatientId = attributes.demoPatientId;
			}
			if (attributes.todoss !== undefined && Array.isArray(attributes.todoss)) {
				for (const model of attributes.todoss) {
					if (model instanceof Models.TodoEntity) {
						this.todoss.push(model);
					} else {
						this.todoss.push(new Models.TodoEntity(model));
					}
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] off begin
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] off begin
	public defaultExpands = `
		demoClinicians {
			${Models.ClinicianEntity.getAttributes().join('\n')}
		}
		demoPatient {
			${Models.PatientEntity.getAttributes().join('\n')}
		}
		todoss {
			${Models.TodoEntity.getAttributes().join('\n')}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] off begin
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath = {
			todoss: {},
		};

		if (formMode === 'create') {
			relationPath['password'] = {};

			if (this.password !== this._confirmPassword) {
				throw Error("Password fields do not match");
			}
		}
		return this.save(
			relationPath,
			{
				graphQlInputType: formMode === 'create'
					? `[${this.getModelName()}CreateInput]`
					: `[${this.getModelName()}Input]`,
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
							'todoss',
						]
					},
				],
			}
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] off begin
		return this.email;
		// % protected region % [Customise the display name for this entity] end
	}


	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [ createdAttr, modifiedAttr ] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(AdminEntity.prototype, 'created');
CRUD(modifiedAttr)(AdminEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
