import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { QuestionType, ValidatorType } from 'Forms/Schema/Question';
import { IQuestionTile, QuestionTileOptionsProps } from '../QuestionTile';
import { QuestionComponent, QuestionComponentProps } from 'Forms/Questions/QuestionComponent';
import {hasRequiredValidator} from 'Forms/Validators/ValidationUtils';
import { TextArea } from 'Views/Components/TextArea/TextArea';
import { ValidateText } from 'Forms/Validators/ValidateText';
import CompareText from 'Forms/Conditions/CompareText';
import TileOptions from '../TileOptions';

/**
 * This component is what is rendered in the options menu for the form builder.
 */
@observer
export class TextAreaQuestionTileOptions extends Component<QuestionTileOptionsProps> {
    public render() {
        const { question, schema } = this.props;
        // The tile options component is used to provide the default options, such as question name, validators ect.
        // You can add extra things to this render function to have more options, or remove the tile options component
        // entirely to provide your entirely custom configuration.
        return (
            <TileOptions question={question} schema={schema} hasShowConditions hasValidators hasTooltip />
        );
    }
}

/**
 * The props for this question tile are extended from the generic question component props. If you have custom data that
 * you configure in your options component you can type it here to access it's values through a typed interface. 
 */
export interface ITextAreaQuestionTileProps<T> extends QuestionComponentProps<T> {

}

/**
 * The question tile itself. It is important that the component extends the QuestionComponent class and implements
 * IQuestionTile. The generic argument that is provided is the type of the model prop, which is the object that is
 * edited when changing data in the form.
 */
@observer
export class TextAreaQuestionTile<T> extends QuestionComponent<T, ITextAreaQuestionTileProps<T>> implements IQuestionTile {
    /** The name of the question that is displayed on the user interface */
    static displayName = 'Text Area';

    /** This is the name of the question that is used internally by the system */
    static questionType: QuestionType = 'textarea';

    /** The options menu to display in the sidebar. This class is defined above */
    static optionsMenu = TextAreaQuestionTileOptions;

    /**
     * The types of validators that can be used for this field.
     * These options must be covered in the validateFunction that is defined above.
     */
    static validatorOptions: { display: string, value: ValidatorType }[] = [
        { display: 'Required', value: 'required' },
        { display: 'Email', value: 'email' },
        { display: 'Phone', value: 'phone' },
        { display: 'Custom', value: 'custom' },
    ];

    /** The function used to validate the options. This must handle all validator options. */
    static validateFunction = ValidateText;

    /**
     * The options that are used for the show conditions.
     * These options must be covered
     */
    static conditionOptions = [
        { display: 'Equal', value: 'equal' },
        { display: 'Not equal', value: 'notEqual' },
        { display: 'Contains', value: 'contains' },
    ];

    /**
     * The function used to compare the value for the show conditions. This function is the one that is used by the
     * text box since we are using the same same condition options. However any function can be used here.
     */
    static compareFunction = CompareText;

    /**
     * The content that is displayed to the user that is filling out the form.
     */
    public render() {
        const {title, model, id, isReadOnly, validators, toolTip, className, checkValidation} = this.props;
        return (
            <TextArea
                model={model}
                modelProperty={id}
                label={title}
                isReadOnly={isReadOnly}
                isRequired={hasRequiredValidator(validators)}
                tooltip={toolTip}
                onAfterChange={checkValidation}
                className={className}
            />
        );
    }
}