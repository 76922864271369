import React, { Component } from 'react';
import { AreaProgress } from '../GoalsProgess';
import { AreaOfLifeRatingsEntity, GoalsEntity, PatientEntity } from 'Models/Entities';
import { lifeArea } from 'Models/Enums';
import { store } from 'Models/Store';
import { action, observable, runInAction } from 'mobx';
import { observer } from 'mobx-react';
import { Button, Colors, Display, QuickTypes } from 'Views/Components/Button/Button';
import If from '../../If/If';


interface IGoalSlideSelectAreaofLife {
	goal: GoalsEntity
	lifeArea: lifeArea;
	user: PatientEntity;
	areaOfLifeRating: AreaOfLifeRatingsEntity;
	incrementStep: () => void;
	decrementStep: () => void;
	onClickBack: () => void;
	displayBack?: boolean;
}

@observer
export default class GoalSlideSelectAreaOfLife extends Component<IGoalSlideSelectAreaofLife> {

	constructor(props: IGoalSlideSelectAreaofLife) {
		super(props);
	}

	@action
	private setLifeArea(area: any) {
		this.props.goal.lifeArea = area;
	}

	render() {
		const { user, incrementStep, decrementStep, onClickBack, displayBack } = this.props;

		let contents = (
			<>
				<h4 className="goal-select-text">Select the first area of life you'd like to improve </h4>
				<div className="goal-area-of-life">
					<div className="goal-area-of-life__title">
						<h4>Areas of life</h4>
					</div>
					<AreaProgress
						user={this.props.user}
						areaOfLifeRating={this.props.areaOfLifeRating}
						displayFooter={true}
						onClick={(area) => {
							this.setLifeArea(area);
							incrementStep();
						}}
					/>
				</div>

				<div className="goal-wizard__footer">

					<If condition={this.props.displayBack === undefined}>
					<div>
						<Button
							display={Display.Text}
							colors={Colors.White}
							onClick={() => {
								onClickBack();
								decrementStep()
							}}>Back</Button>
					</div>
					</If>
				</div>
			</>
		);
		return contents;
	}
}

