import Axios from 'axios';
import moment from 'moment';
import React from 'react';
import { SeriesOptionsType } from 'highcharts';
import {
	BasePatientGraphData,
	DASHBOARD_GRAPH_COMMON_CHART_OPTIONS,
	DashboardGraphDateRange,
	IBasePatientGraphDataAttributes
} from 'Views/Components/PatientGraphs/PatientCommonGraph';
import { GRADIENT_COLORS } from '../Constants';

export default class ArticleReadService {
	protected static readonly URL = '/api/entity/ArticleReadEventEntity';

	public static saveArticleReadEvent(articleId: string) {
		const data = {
			readDate: moment.utc(Date.now()).toDate(),
			articlesId: articleId,
		}
		return Axios.post(this.URL, data);
	}

	public static getPatientArticlesReadEventGraphData(dateRange: DashboardGraphDateRange) {
		const URL = `${this.URL}/patient-articles-graph-data`;
		return Axios.post<PatientArticlesReadEventGraphData>(URL, dateRange);
	}
}

interface IPatientArticlesReadEventGraphData extends IBasePatientGraphDataAttributes<PatientArticleReadEventPerDay> {

}

export class PatientArticlesReadEventGraphData extends BasePatientGraphData<PatientArticleReadEventPerDay> {

	constructor(attributes?: Partial<IPatientArticlesReadEventGraphData>) {
		super(attributes);
		if (attributes) {
			if (attributes.data) {
				for (const model of attributes.data) {
					if (model instanceof PatientArticleReadEventPerDay) {
						this.data.push(model);
					} else {
						this.data.push(new PatientArticleReadEventPerDay(model));
					}
				}
			}
		}
	}

	// Return a list of date strings for the x axis of the graph
	getXCategories = () => {
		return this.data.map(day => moment(day.date).local().format('D MMM'));
	}

	getSubmissionsSeriesData = (): SeriesOptionsType => {
		return {
			type: DASHBOARD_GRAPH_COMMON_CHART_OPTIONS.seriesType,
			data: this.data.map(data => data.numberOfArticleReadEvent),
			name: "Articles read",
			color: GRADIENT_COLORS.PURPLE,
		} as SeriesOptionsType;
	}
}

interface IPatientArticleReadEventPerDayAttributes {
	date: Date;
	numberOfArticleReadEvent: number;
}

class PatientArticleReadEventPerDay {
	public date: Date;
	public numberOfArticleReadEvent: number = 0;

	constructor(attributes?: Partial<IPatientArticleReadEventPerDayAttributes>) {

		if (attributes) {
			if (attributes.date) {
				this.date = moment(attributes.date).local().toDate();
			}
			if (attributes.numberOfArticleReadEvent) {
				this.numberOfArticleReadEvent = attributes.numberOfArticleReadEvent;
			}
		}
	}
}
