import * as React from 'react';
import { observer } from 'mobx-react';
import { RouteComponentProps } from 'react-router';
import SecuredPage from 'Views/Components/Security/SecuredPage';
import Navigation, { Orientation } from 'Views/Components/Navigation/Navigation';
import { getFrontendNavLinks } from 'Views/FrontendNavLinks';
import Header from 'Views/Components/Header/Header';
import { displayName } from 'Symbols';
import * as Models from 'Models/Entities';
import Spinner from 'Views/Components/Spinner/Spinner';
import { store } from 'Models/Store';
import EntityCRUD from '../Components/CRUD/EntityCRUD';

@observer
class CustomCarerPage extends React.Component<RouteComponentProps> {

	public render() {

		if(store.displayNameStatus === 'loading'){
			return <Spinner/>
		}

		return (
			<SecuredPage groups={['Super Administrators', 'Admin']}>
				<Navigation
					linkGroups={getFrontendNavLinks(this.props)}
					orientation={Orientation.VERTICAL}
					match={this.props.match}
					location={this.props.location}
					history={this.props.history}
					staticContext={this.props.staticContext}
				/>

				<div className="body-content">
					<Header title={Models.CarerEntity[displayName]} subtitle="User Management" />
					<EntityCRUD {...this.props} modelType={Models.CarerEntity} URLExtension={'Carer'} />
				</div>
			</SecuredPage>
		);
	}
}

export default CustomCarerPage;
