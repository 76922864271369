import React, { Component } from 'react';
import { SERVER_URL } from 'Constants';
import { Link, RouteComponentProps } from 'react-router-dom';
import If from '../If/If';
import { toJS } from 'mobx';
import { store } from 'Models/Store';
import { idText } from 'typescript';

export interface ArticleButtonProps {
	article: any;
}

export default class ArticleButton extends Component<ArticleButtonProps> {
	render() {
		const { id, title, featureId } = this.props.article;
		return (
			<div className="article-button" onClick={() => store.routerHistory.push(`/article/${id}`)}>
				<img src={`${SERVER_URL}/api/files/${featureId}`} />
				<b>{title}</b>
			</div>
		);
	}
}
