/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import * as React from 'react';
import Navigation, { Orientation, ILink } from 'Views/Components/Navigation/Navigation';
import { RouteComponentProps } from 'react-router';
import * as Models from 'Models/Entities';
import { Model } from 'Models/Model';
import { IIconProps } from "Views/Components/Helpers/Common";
import { SecurityService } from "Services/SecurityService";
import { getModelDisplayName } from 'Util/EntityUtils';
import { store } from 'Models/Store';
// % protected region % [Add any further imports here] on begin
import { displayName } from "Symbols";
import _ from "lodash";
// % protected region % [Add any further imports here] end

interface AdminLink extends IIconProps {
	path: string;
	label: string;
	entity: {new (args: any): Model};
	isMember?: boolean;
	// % protected region % [Add extra AdminLink fields here] off begin
	// % protected region % [Add extra AdminLink fields here] end
}

const getPageLinks = (): AdminLink[] => [
	{
		// % protected region % [Override navigation link for ActivitiesSubmissionEntity here] off begin
		path: '/admin/activitiessubmissionentity',
		label: getModelDisplayName(Models.ActivitiesSubmissionEntity),
		entity: Models.ActivitiesSubmissionEntity,
		isMember: false
		// % protected region % [Override navigation link for ActivitiesSubmissionEntity here] end
	},
	{
		// % protected region % [Override navigation link for AdminEntity here] off begin
		path: '/admin/adminentity',
		label: getModelDisplayName(Models.AdminEntity),
		entity: Models.AdminEntity,
		isMember: true
		// % protected region % [Override navigation link for AdminEntity here] end
	},
	{
		// % protected region % [Override navigation link for AreaOfLifeRatingsEntity here] off begin
		path: '/admin/areaofliferatingsentity',
		label: getModelDisplayName(Models.AreaOfLifeRatingsEntity),
		entity: Models.AreaOfLifeRatingsEntity,
		isMember: false
		// % protected region % [Override navigation link for AreaOfLifeRatingsEntity here] end
	},
	{
		// % protected region % [Override navigation link for ArticlesEntity here] off begin
		path: '/admin/articlesentity',
		label: getModelDisplayName(Models.ArticlesEntity),
		entity: Models.ArticlesEntity,
		isMember: false
		// % protected region % [Override navigation link for ArticlesEntity here] end
	},
	{
		// % protected region % [Override navigation link for BooksEntity here] off begin
		path: '/admin/booksentity',
		label: getModelDisplayName(Models.BooksEntity),
		entity: Models.BooksEntity,
		isMember: false
		// % protected region % [Override navigation link for BooksEntity here] end
	},
	{
		// % protected region % [Override navigation link for CarerEntity here] on begin
		path: "/admin/carerentity",
		label: Models.CarerEntity[displayName],
		entity: Models.CarerEntity,
		isMember: true,
		// % protected region % [Override navigation link for CarerEntity here] end
	},
	{
		// % protected region % [Override navigation link for ClinicianEntity here] on begin
		path: "/admin/clinicianentity",
		label: Models.ClinicianEntity[displayName],
		entity: Models.ClinicianEntity,
		isMember: true,
		// % protected region % [Override navigation link for ClinicianEntity here] end
	},
	{
		// % protected region % [Override navigation link for FeaturedArticleEntity here] off begin
		path: '/admin/featuredarticleentity',
		label: getModelDisplayName(Models.FeaturedArticleEntity),
		entity: Models.FeaturedArticleEntity,
		isMember: false
		// % protected region % [Override navigation link for FeaturedArticleEntity here] end
	},
	{
		// % protected region % [Override navigation link for GoalsEntity here] off begin
		path: '/admin/goalsentity',
		label: getModelDisplayName(Models.GoalsEntity),
		entity: Models.GoalsEntity,
		isMember: false
		// % protected region % [Override navigation link for GoalsEntity here] end
	},
	{
		// % protected region % [Override navigation link for NotificationsSettingsEntity here] off begin
		path: '/admin/notificationssettingsentity',
		label: getModelDisplayName(Models.NotificationsSettingsEntity),
		entity: Models.NotificationsSettingsEntity,
		isMember: false
		// % protected region % [Override navigation link for NotificationsSettingsEntity here] end
	},
	{
		// % protected region % [Override navigation link for ActivitiesEntity here] off begin
		path: '/admin/activitiesentity',
		label: getModelDisplayName(Models.ActivitiesEntity),
		entity: Models.ActivitiesEntity,
		isMember: false
		// % protected region % [Override navigation link for ActivitiesEntity here] end
	},
	{
		// % protected region % [Override navigation link for ArticleReadEventEntity here] off begin
		path: '/admin/articlereadevententity',
		label: getModelDisplayName(Models.ArticleReadEventEntity),
		entity: Models.ArticleReadEventEntity,
		isMember: false
		// % protected region % [Override navigation link for ArticleReadEventEntity here] end
	},
	{
		// % protected region % [Override navigation link for CustomProfileFieldsEntity here] off begin
		path: '/admin/customprofilefieldsentity',
		label: getModelDisplayName(Models.CustomProfileFieldsEntity),
		entity: Models.CustomProfileFieldsEntity,
		isMember: false
		// % protected region % [Override navigation link for CustomProfileFieldsEntity here] end
	},
	{
		// % protected region % [Override navigation link for CustomlogoEntity here] off begin
		path: '/admin/customlogoentity',
		label: getModelDisplayName(Models.CustomlogoEntity),
		entity: Models.CustomlogoEntity,
		isMember: false
		// % protected region % [Override navigation link for CustomlogoEntity here] end
	},
	{
		// % protected region % [Override navigation link for GoalStepEntity here] off begin
		path: '/admin/goalstepentity',
		label: getModelDisplayName(Models.GoalStepEntity),
		entity: Models.GoalStepEntity,
		isMember: false
		// % protected region % [Override navigation link for GoalStepEntity here] end
	},
	{
		// % protected region % [Override navigation link for LabelsEntity here] off begin
		path: '/admin/labelsentity',
		label: getModelDisplayName(Models.LabelsEntity),
		entity: Models.LabelsEntity,
		isMember: false
		// % protected region % [Override navigation link for LabelsEntity here] end
	},
	{
		// % protected region % [Override navigation link for LibraryTagEntity here] off begin
		path: '/admin/librarytagentity',
		label: getModelDisplayName(Models.LibraryTagEntity),
		entity: Models.LibraryTagEntity,
		isMember: false
		// % protected region % [Override navigation link for LibraryTagEntity here] end
	},
	{
		// % protected region % [Override navigation link for LibraryimagesEntity here] off begin
		path: '/admin/libraryimagesentity',
		label: getModelDisplayName(Models.LibraryimagesEntity),
		entity: Models.LibraryimagesEntity,
		isMember: false
		// % protected region % [Override navigation link for LibraryimagesEntity here] end
	},
	{
		// % protected region % [Override navigation link for OnboardingSlideEntity here] off begin
		path: '/admin/onboardingslideentity',
		label: getModelDisplayName(Models.OnboardingSlideEntity),
		entity: Models.OnboardingSlideEntity,
		isMember: false
		// % protected region % [Override navigation link for OnboardingSlideEntity here] end
	},
	{
		// % protected region % [Override navigation link for PatientEntity here] on begin
		path: "/admin/patiententity",
		label: Models.PatientEntity[displayName],
		entity: Models.PatientEntity,
		isMember: true,
		// % protected region % [Override navigation link for PatientEntity here] end
	},
	{
		// % protected region % [Override navigation link for RatingpersonalEntity here] off begin
		path: '/admin/ratingpersonalentity',
		label: getModelDisplayName(Models.RatingpersonalEntity),
		entity: Models.RatingpersonalEntity,
		isMember: false
		// % protected region % [Override navigation link for RatingpersonalEntity here] end
	},
	{
		// % protected region % [Override navigation link for RatingpersonalSubmissionEntity here] off begin
		path: '/admin/ratingpersonalsubmissionentity',
		label: getModelDisplayName(Models.RatingpersonalSubmissionEntity),
		entity: Models.RatingpersonalSubmissionEntity,
		isMember: false
		// % protected region % [Override navigation link for RatingpersonalSubmissionEntity here] end
	},
	{
		// % protected region % [Override navigation link for RatingtemplateEntity here] off begin
		path: '/admin/ratingtemplateentity',
		label: getModelDisplayName(Models.RatingtemplateEntity),
		entity: Models.RatingtemplateEntity,
		isMember: false
		// % protected region % [Override navigation link for RatingtemplateEntity here] end
	},
	{
		// % protected region % [Override navigation link for RatingtemplateSubmissionEntity here] off begin
		path: '/admin/ratingtemplatesubmissionentity',
		label: getModelDisplayName(Models.RatingtemplateSubmissionEntity),
		entity: Models.RatingtemplateSubmissionEntity,
		isMember: false
		// % protected region % [Override navigation link for RatingtemplateSubmissionEntity here] end
	},
	{
		// % protected region % [Override navigation link for SocialMediaLinkEntity here] off begin
		path: '/admin/socialmedialinkentity',
		label: getModelDisplayName(Models.SocialMediaLinkEntity),
		entity: Models.SocialMediaLinkEntity,
		isMember: false
		// % protected region % [Override navigation link for SocialMediaLinkEntity here] end
	},
	{
		// % protected region % [Override navigation link for TagEntity here] off begin
		path: '/admin/tagentity',
		label: getModelDisplayName(Models.TagEntity),
		entity: Models.TagEntity,
		isMember: false
		// % protected region % [Override navigation link for TagEntity here] end
	},
	{
		// % protected region % [Override navigation link for TodoEntity here] off begin
		path: '/admin/todoentity',
		label: getModelDisplayName(Models.TodoEntity),
		entity: Models.TodoEntity,
		isMember: false
		// % protected region % [Override navigation link for TodoEntity here] end
	},
	{
		// % protected region % [Override navigation link for VideoChatLinkEntity here] off begin
		path: '/admin/videochatlinkentity',
		label: getModelDisplayName(Models.VideoChatLinkEntity),
		entity: Models.VideoChatLinkEntity,
		isMember: false
		// % protected region % [Override navigation link for VideoChatLinkEntity here] end
	},
	{
		// % protected region % [Override navigation link for PatientTimelineEventsEntity here] off begin
		path: '/admin/patienttimelineeventsentity',
		label: getModelDisplayName(Models.PatientTimelineEventsEntity),
		entity: Models.PatientTimelineEventsEntity,
		isMember: false
		// % protected region % [Override navigation link for PatientTimelineEventsEntity here] end
	},
	// % protected region % [Add any extra page links here] on begin
	// % protected region % [Add any extra page links here] end
];

export default class PageLinks extends React.Component<RouteComponentProps> {
	private filter = (link: AdminLink) => {
		return SecurityService.canRead(link.entity);
	}

	public render() {
		return <Navigation
			className='nav__admin'
			orientation={Orientation.VERTICAL}
			linkGroups={this.getAdminNavLinks()}
			{...this.props} />;
	}

	private getAdminNavLinks = () : ILink[][] => {
		// % protected region % [Add custom logic before all here] off begin
		// % protected region % [Add custom logic before all here] end

		const links = getPageLinks();
		let userLinks = links.filter(link => link.isMember).filter(this.filter);
		let entityLinks = links.filter(link => ! link.isMember).filter(this.filter);

		let linkGroups: ILink[][] = [];

		// % protected region % [Add any custom logic here before groups are made] on begin
		entityLinks = _.sortBy(entityLinks, "label");
		userLinks = _.sortBy(userLinks, "label");
		// % protected region % [Add any custom logic here before groups are made] end

		const homeLinkGroup: ILink[] = [
			{ path: '/admin', label: 'Home', icon: 'home', iconPos: 'icon-left' },
			// { path: '/admin/settings', label: 'Settings', icon: 'settings', iconPos: 'icon-left', isDisabled: true }

			// % protected region % [Updated your home link group here] off begin
			// % protected region % [Updated your home link group here] end
		];
		linkGroups.push(homeLinkGroup);

		const entityLinkGroup: ILink[] = [];
		if (userLinks.length > 0) {
			entityLinkGroup.push(
				{
					path: '/admin/users',
					label: 'Users',
					icon: 'person-group',
					iconPos: 'icon-left',
					subLinks: [
						{path: "/admin/user", label: "All Users"},
						...userLinks.map(link => ({path: link.path, label: link.label}))
					]
				}
			);
		}
		if (entityLinks.length > 0) {
			entityLinkGroup.push(
				{
					path: '/admin/entities',
					label: 'Entities',
					icon: 'list',
					iconPos: 'icon-left',
					subLinks: entityLinks.map(link => {
						return {
							path: link.path,
							label: link.label,
						}
					})
				}
			);
		}
		linkGroups.push(entityLinkGroup);

		// % protected region % [Add any new link groups here before other and bottom] on begin
		// % protected region % [Add any new link groups here before other and bottom] end

		const otherlinkGroup: ILink[] = [];
		const formsGroups: string[] = ['Patient', 'Super Administrators', 'Carer', 'Admin', 'Clinician'];
		if (store.userGroups.some(ug => formsGroups.some(fg => fg === ug.name))) {
			otherlinkGroup.push({ path: '/admin/forms', label: 'Forms', icon: 'forms', iconPos: 'icon-left', isDisabled: false });
		}
		otherlinkGroup.push({ path: '/admin/timelines', label: 'Timelines', icon: 'timeline', iconPos: 'icon-left', isDisabled: false });
		if (otherlinkGroup.length > 0) {
			linkGroups.push(otherlinkGroup);
		}

		const bottomlinkGroup: ILink[] = [];
		bottomlinkGroup.push(
			{
				path: '/admin/',
				label: 'Utility',
				icon: 'file',
				iconPos: 'icon-left',
				subLinks: [
					{
						path: '/admin/styleguide',
						label: 'Style Guide',
						useATag: false
					},
					{
						path: '/admin/graphiql',
						label: 'GraphiQL',
						useATag: true,
					},
					{
						path: '/api/hangfire',
						label: 'Hangfire',
						useATag: true,
					},
					{
						path: '/api/swagger',
						label: 'OpenAPI',
						useATag: true,
					},
					{
						path: 'https://gitlab.codebots.dev',
						label: 'Git',
						useATag: true,
					},
				],
			}
		);

		bottomlinkGroup.push({ path: '/logout', label: 'Logout', icon: 'room', iconPos: 'icon-left' });
		linkGroups.push(bottomlinkGroup);

		// % protected region % [Modify your link groups here before returning] on begin
		otherlinkGroup.push({
			path: "/admin/libraryadmin",
			label: "Library",
			icon: "book",
			iconPos: "icon-left",
			isDisabled: false,
		});
		// % protected region % [Modify your link groups here before returning] end

		return linkGroups;
	}

	// % protected region % [Add custom methods here] off begin
	// % protected region % [Add custom methods here] end
}