/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import { Model, IModelAttributes, attribute, entity } from 'Models/Model';
import * as Models from 'Models/Entities';
import { IAcl } from '../Security/IAcl';
import { observable } from 'mobx';
import { VisitorsLibraryTagsReferenceManyToMany } from '../Security/Acl/VisitorsLibraryTagsReferenceManyToMany';
import { PatientLibraryTagsReferenceManyToMany } from '../Security/Acl/PatientLibraryTagsReferenceManyToMany';
import { ClinicianLibraryTagsReferenceManyToMany } from '../Security/Acl/ClinicianLibraryTagsReferenceManyToMany';
import { CarerLibraryTagsReferenceManyToMany } from '../Security/Acl/CarerLibraryTagsReferenceManyToMany';
import { AdminLibraryTagsReferenceManyToMany } from '../Security/Acl/AdminLibraryTagsReferenceManyToMany';

// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface IArticlesLibraryTagsAttributes extends IModelAttributes {
	articlesId: string;
	libraryTagsId: string;

	articles: Models.ArticlesEntity | Models.IArticlesEntityAttributes;
	libraryTags: Models.LibraryTagEntity | Models.ILibraryTagEntityAttributes;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

@entity('ArticlesLibraryTags')
export default class ArticlesLibraryTags extends Model implements IArticlesLibraryTagsAttributes {
	public static acls: IAcl[] = [
		new VisitorsLibraryTagsReferenceManyToMany(),
		new PatientLibraryTagsReferenceManyToMany(),
		new ClinicianLibraryTagsReferenceManyToMany(),
		new CarerLibraryTagsReferenceManyToMany(),
		new AdminLibraryTagsReferenceManyToMany(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	@observable
	@attribute()
	public articlesId: string;

	@observable
	@attribute()
	public libraryTagsId: string;

	@observable
	@attribute({isReference: true})
	public articles: Models.ArticlesEntity;

	@observable
	@attribute({isReference: true})
	public libraryTags: Models.LibraryTagEntity;
	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	constructor(attributes?: Partial<IArticlesLibraryTagsAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		if (attributes) {
			if (attributes.articlesId) {
				this.articlesId = attributes.articlesId;
			}
			if (attributes.libraryTagsId) {
				this.libraryTagsId = attributes.libraryTagsId;
			}

			if (attributes.articles) {
				if (attributes.articles instanceof Models.ArticlesEntity) {
					this.articles = attributes.articles;
					this.articlesId = attributes.articles.id;
				} else {
					this.articles = new Models.ArticlesEntity(attributes.articles);
					this.articlesId = this.articles.id;
				}
			} else if (attributes.articlesId !== undefined) {
				this.articlesId = attributes.articlesId;
			}

			if (attributes.libraryTags) {
				if (attributes.libraryTags instanceof Models.LibraryTagEntity) {
					this.libraryTags = attributes.libraryTags;
					this.libraryTagsId = attributes.libraryTags.id;
				} else {
					this.libraryTags = new Models.LibraryTagEntity(attributes.libraryTags);
					this.libraryTagsId = this.libraryTags.id;
				}
			} else if (attributes.libraryTagsId !== undefined) {
				this.libraryTagsId = attributes.libraryTagsId;
			}
		}

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}