/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import * as React from 'react';
import { action } from 'mobx';
import * as AdminPages from './Pages/Admin/Entity';
import Auth from "./Components/Auth/Auth";
import AllUsersPage from './Pages/Admin/AllUsersPage';
import AdminPage from './Pages/Admin/AdminPage';
import Topbar from "./Components/Topbar/Topbar";
import PageLinks from './Pages/Admin/PageLinks';
import StyleguidePage from './Pages/Admin/StyleguidePage';
import Spinner from 'Views/Components/Spinner/Spinner';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router';
import { store } from "Models/Store";
import FormsPage from "./Pages/Admin/Forms/FormsPage";
import TimelinePage from "./Pages/Admin/Timelines/TimelinePage";
// % protected region % [Add any extra imports here] on begin
import ImpersonateTopBar from 'Views/Components/Topbar/ImpersonateTopBar';
// % protected region % [Add any extra imports here] end

// % protected region % [Customize lazy imports here] off begin
const GraphiQlLazy = React.lazy(() => import("./Pages/Admin/Graphiql"));
// % protected region % [Customize lazy imports here] end

export default class Admin extends React.Component<RouteComponentProps> {
	private path = this.props.match.path === '/' ? '' : this.props.match.path;
	@action
	private setAppLocation = () => {
		store.appLocation = 'admin';
	}

	public componentDidMount() {
		this.setAppLocation();
	}

	public render() {
		return (
			<>
				<div className="body-container">
					{
					// % protected region % [Modify Topbar] on begin
					}
					<ImpersonateTopBar currentLocation="admin"/>
					{
					// % protected region % [Modify Topbar] end
					}
					<Switch>
						<Route path={`${this.path}/styleguide`} >
							<Auth {...this.props}>
								<div className="admin">
									<PageLinks {...this.props} />
								</div>
								<div className="frontend">
									<div className="body-content">
										<StyleguidePage {...this.props}/>
									</div>
								</div>
							</Auth>
						</Route>
						<Route>
							<div className="admin">
								<Auth {...this.props}>
									<Switch>
										{
										// % protected region % [Modify top level admin routing here] off begin
										}
										<Route path={`${this.path}/graphiql`}>
											<React.Suspense fallback={<Spinner />}>
												<GraphiQlLazy />
											</React.Suspense>
										</Route>
										<Route component={this.adminSwitch} />
										{
										// % protected region % [Modify top level admin routing here] end
										}
									</Switch>
								</Auth>
							</div>
						</Route>
					</Switch>
				</div>
			</>
		);
	}

	private adminSwitch = () => {
		if (!store.userGroups.some(ug => ug.hasBackendAccess)) {
			return <Redirect to="/404" />;
		}

		return (
			<>
				{
				// % protected region % [Override contents here] off begin
				}
				<PageLinks {...this.props} />
				{
				// % protected region % [Override contents here] end
				}
				<div className="body-content">
					<Switch>
						{/* These routes require a login to view */}

						{/* Admin entity pages */}
						<Route exact={true} path={`${this.path}`} component={AdminPage} />
						<Route path={`${this.path}/User`} component={AllUsersPage} />
						<Route path={`${this.path}/forms`} component={FormsPage} />
						<Route path={`${this.path}/Timelines`} component={TimelinePage} />
						<Route path={`${this.path}/ActivitiesSubmissionEntity`} component={AdminPages.ActivitiesSubmissionEntityPage} />
						<Route path={`${this.path}/AdminEntity`} component={AdminPages.AdminEntityPage} />
						<Route path={`${this.path}/AreaOfLifeRatingsEntity`} component={AdminPages.AreaOfLifeRatingsEntityPage} />
						<Route path={`${this.path}/ArticlesEntity`} component={AdminPages.ArticlesEntityPage} />
						<Route path={`${this.path}/BooksEntity`} component={AdminPages.BooksEntityPage} />
						<Route path={`${this.path}/CarerEntity`} component={AdminPages.CarerEntityPage} />
						<Route path={`${this.path}/ClinicianEntity`} component={AdminPages.ClinicianEntityPage} />
						<Route path={`${this.path}/FeaturedArticleEntity`} component={AdminPages.FeaturedArticleEntityPage} />
						<Route path={`${this.path}/GoalsEntity`} component={AdminPages.GoalsEntityPage} />
						<Route path={`${this.path}/NotificationsSettingsEntity`} component={AdminPages.NotificationsSettingsEntityPage} />
						<Route path={`${this.path}/ActivitiesEntity`} component={AdminPages.ActivitiesEntityPage} />
						<Route path={`${this.path}/ArticleReadEventEntity`} component={AdminPages.ArticleReadEventEntityPage} />
						<Route path={`${this.path}/CustomProfileFieldsEntity`} component={AdminPages.CustomProfileFieldsEntityPage} />
						<Route path={`${this.path}/CustomlogoEntity`} component={AdminPages.CustomlogoEntityPage} />
						<Route path={`${this.path}/GoalStepEntity`} component={AdminPages.GoalStepEntityPage} />
						<Route path={`${this.path}/LabelsEntity`} component={AdminPages.LabelsEntityPage} />
						<Route path={`${this.path}/LibraryTagEntity`} component={AdminPages.LibraryTagEntityPage} />
						<Route path={`${this.path}/LibraryimagesEntity`} component={AdminPages.LibraryimagesEntityPage} />
						<Route path={`${this.path}/OnboardingSlideEntity`} component={AdminPages.OnboardingSlideEntityPage} />
						<Route path={`${this.path}/PatientEntity`} component={AdminPages.PatientEntityPage} />
						<Route path={`${this.path}/RatingpersonalEntity`} component={AdminPages.RatingpersonalEntityPage} />
						<Route path={`${this.path}/RatingpersonalSubmissionEntity`} component={AdminPages.RatingpersonalSubmissionEntityPage} />
						<Route path={`${this.path}/RatingtemplateEntity`} component={AdminPages.RatingtemplateEntityPage} />
						<Route path={`${this.path}/RatingtemplateSubmissionEntity`} component={AdminPages.RatingtemplateSubmissionEntityPage} />
						<Route path={`${this.path}/SocialMediaLinkEntity`} component={AdminPages.SocialMediaLinkEntityPage} />
						<Route path={`${this.path}/TagEntity`} component={AdminPages.TagEntityPage} />
						<Route path={`${this.path}/TodoEntity`} component={AdminPages.TodoEntityPage} />
						<Route path={`${this.path}/VideoChatLinkEntity`} component={AdminPages.VideoChatLinkEntityPage} />
						<Route path={`${this.path}/ActivitiesEntityFormTileEntity`} component={AdminPages.ActivitiesEntityFormTileEntityPage} />
						<Route path={`${this.path}/RatingpersonalEntityFormTileEntity`} component={AdminPages.RatingpersonalEntityFormTileEntityPage} />
						<Route path={`${this.path}/RatingtemplateEntityFormTileEntity`} component={AdminPages.RatingtemplateEntityFormTileEntityPage} />
						<Route path={`${this.path}/PatientTimelineEventsEntity`} component={AdminPages.PatientTimelineEventsEntityPage} />

						{
						// % protected region % [Add any extra page routes here] on begin
						}
						<Route path={`${this.path}/LibraryEditor`} component={AdminPages.LibraryEditorPage} />
						<Route path={`${this.path}/LibraryAdmin`} component={AdminPages.LibraryAdminPage} />
						{
						// % protected region % [Add any extra page routes here] end
						}
					</Switch>
				</div>
				{
				// % protected region % [Add any admin footer content here] off begin
				}
				{
				// % protected region % [Add any admin footer content here] end
				}
			</>
		);
	}
}