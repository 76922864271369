import * as React from 'react';
import { observer } from 'mobx-react';

export interface HeaderProps{
    subtitle:string;
    title:string;
}

@observer
export default class Header extends React.Component<HeaderProps> {
    public render() {
        return (
            <div className="page-header">
                <div>
                    <h6>{this.props.subtitle}</h6>
                    <h2>{this.props.title}</h2>
                </div>
            </div>
        )
    }
}