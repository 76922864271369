import React, { Component } from "react";
import { RouteComponentProps, Switch } from "react-router";
import Navigation, { Orientation } from "Views/Components/Navigation/Navigation";
import SecuredPage from "Views/Components/Security/SecuredPage";
import { getFrontendNavLinks } from "Views/FrontendNavLinks";
import CheckInTile from "Views/Components/PatientCheckIn/CheckInTile";

interface ICheckInPageParams {
	id?: string;
}

export default class CheckInPage extends Component<RouteComponentProps<ICheckInPageParams>> {
	render() {
		const { history, location, match, staticContext } = this.props;
		const navigationLinks = getFrontendNavLinks(this.props);

		return (
			<SecuredPage groups={["Admin", "Patient", "Clinician", "Carer"]}>
				<Navigation
					linkGroups={navigationLinks}
					orientation={Orientation.VERTICAL}
					match={match}
					location={location}
					history={history}
					staticContext={staticContext}
				/>

				<div className="body-content">
					<CheckInTile />
				</div>
			</SecuredPage>
		);
	}
}
