import React, { Component } from "react";
import YHPLogo from "Views/Components/Logo/YHPLogo";
import { LoginWrapper } from "Wrappers/LoginWrapper";
import styles from "./../../../scss/pages/loginPage.module.scss";

export interface CheckEmailProps {}

export default class CheckEmailPage extends Component<CheckEmailProps> {
	render() {
		return (
			<LoginWrapper>
				<div className={styles.formContainer}>
					<YHPLogo />
					<h2 className={styles.formHeader}>Check your inbox</h2>
					<p>
						If this email matches what's in our system, we have sent you a password
						reset link. Please be sure to check your spam folder too.
					</p>
				</div>
			</LoginWrapper>
		);
	}
}
