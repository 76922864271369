/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import { SERVER_URL } from 'Constants';
import * as React from 'react';
import { action, observable } from 'mobx';
import { Model, IModelAttributes, attribute, entity } from 'Models/Model';
import * as Validators from 'Validators';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from "Util/AttributeUtils";
import { IAcl } from 'Models/Security/IAcl';
import {
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import { VisitorsOnboardingSlideEntity } from 'Models/Security/Acl/VisitorsOnboardingSlideEntity';
import { PatientOnboardingSlideEntity } from 'Models/Security/Acl/PatientOnboardingSlideEntity';
import { ClinicianOnboardingSlideEntity } from 'Models/Security/Acl/ClinicianOnboardingSlideEntity';
import { CarerOnboardingSlideEntity } from 'Models/Security/Acl/CarerOnboardingSlideEntity';
import { AdminOnboardingSlideEntity } from 'Models/Security/Acl/AdminOnboardingSlideEntity';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import {SuperAdministratorScheme} from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] on begin
export const TITLE_MAX_LENGTH = 30;
export const DESCRIPTION_MAX_LENGTH = 220;
// % protected region % [Add any further imports here] end

export interface IOnboardingSlideEntityAttributes extends IModelAttributes {
	title: string;
	description: string;
	featureId: string;
	feature: Blob;
	order: number;

	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('OnboardingSlideEntity', 'Onboarding Slide')
// % protected region % [Customise your entity metadata here] end
export default class OnboardingSlideEntity extends Model implements IOnboardingSlideEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new VisitorsOnboardingSlideEntity(),
		new PatientOnboardingSlideEntity(),
		new ClinicianOnboardingSlideEntity(),
		new CarerOnboardingSlideEntity(),
		new AdminOnboardingSlideEntity(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	// % protected region % [Modify props to the crud options here for attribute 'Title'] on begin
	/**
	 * Title of the slide
	 */
	@observable
	@Validators.Required()
	@Validators.Length(0, TITLE_MAX_LENGTH)
	@attribute()
	@CRUD({
		name: "Title",
		displayType: "textfield",
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: "like",
		searchTransform: AttrUtils.standardiseString,
	})
	public title: string;
	// % protected region % [Modify props to the crud options here for attribute 'Title'] end

	// % protected region % [Modify props to the crud options here for attribute 'Description'] on begin
	/**
	 * Description of the slide
	 */

	@observable
	@Validators.Length(0, DESCRIPTION_MAX_LENGTH)
	@attribute()
	@CRUD({
		name: "Description",
		displayType: "textarea",
		order: 20,
		headerColumn: true,
		searchable: true,
		searchFunction: "like",
		searchTransform: AttrUtils.standardiseString,
	})
	public description: string;
	// % protected region % [Modify props to the crud options here for attribute 'Description'] end

	// % protected region % [Modify props to the crud options here for attribute 'Feature'] on begin
	/**
	 * Feature image to display
	 */
	@observable
	@attribute({ file: "feature" })
	@CRUD({
		name: "Upload files",
		displayType: "file",
		order: 1,
		headerColumn: true,
		searchable: true,
		searchFunction: "equal",
		searchTransform: AttrUtils.standardiseUuid,
		inputProps: {
			imageOnly: true,
		},
		fileAttribute: "feature",
		displayFunction: (attr) => (attr ? <img src={`${SERVER_URL}/api/files/${attr}`} style={{ maxWidth: "300px" }} /> : "No File Attached"),
	})
	public featureId: string;
	@observable
	public feature: Blob;
	// % protected region % [Modify props to the crud options here for attribute 'Feature'] end

	// % protected region % [Modify props to the crud options here for attribute 'Order'] off begin
	/**
	 * Order of the slide to display
	 */
	@Validators.Integer()
	@observable
	@attribute()
	@CRUD({
		name: 'Order',
		displayType: 'textfield',
		order: 40,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseInteger,
	})
	public order: number;
	// % protected region % [Modify props to the crud options here for attribute 'Order'] end

	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<IOnboardingSlideEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<IOnboardingSlideEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.title !== undefined) {
				this.title = attributes.title;
			}
			if (attributes.description !== undefined) {
				this.description = attributes.description;
			}
			if (attributes.feature !== undefined) {
				this.feature = attributes.feature;
			}
			if (attributes.featureId !== undefined) {
				this.featureId = attributes.featureId;
			}
			if (attributes.order !== undefined) {
				this.order = attributes.order;
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] off begin
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] off begin
	public defaultExpands = `
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] off begin
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath = {
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
						]
					},
				],
				contentType: 'multipart/form-data',
			}
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] off begin
		return this.id;
		// % protected region % [Customise the display name for this entity] end
	}


	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [ createdAttr, modifiedAttr ] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(OnboardingSlideEntity.prototype, 'created');
CRUD(modifiedAttr)(OnboardingSlideEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
