/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import moment from 'moment';
import { action, observable } from 'mobx';
import { Model, IModelAttributes, attribute, entity } from 'Models/Model';
import {IOrderByCondition} from 'Views/Components/ModelCollection/ModelQuery';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from "Util/AttributeUtils";
import { IAcl } from 'Models/Security/IAcl';
import {
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import { VisitorsArticleReadEventEntity } from 'Models/Security/Acl/VisitorsArticleReadEventEntity';
import { PatientArticleReadEventEntity } from 'Models/Security/Acl/PatientArticleReadEventEntity';
import { ClinicianArticleReadEventEntity } from 'Models/Security/Acl/ClinicianArticleReadEventEntity';
import { CarerArticleReadEventEntity } from 'Models/Security/Acl/CarerArticleReadEventEntity';
import { AdminArticleReadEventEntity } from 'Models/Security/Acl/AdminArticleReadEventEntity';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import {SuperAdministratorScheme} from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface IArticleReadEventEntityAttributes extends IModelAttributes {
	readDate: Date;

	articlesId?: string;
	articles?: Models.ArticlesEntity | Models.IArticlesEntityAttributes;
	// % protected region % [Add any custom attributes to the interface here] on begin
	ownerId: string;
	name: string;
	surname: string;
	forename: string;
	userRole: string;
	libraryTagNames: string[];
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('ArticleReadEventEntity', 'Article Read Event')
// % protected region % [Customise your entity metadata here] end
export default class ArticleReadEventEntity extends Model implements IArticleReadEventEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new VisitorsArticleReadEventEntity(),
		new PatientArticleReadEventEntity(),
		new ClinicianArticleReadEventEntity(),
		new CarerArticleReadEventEntity(),
		new AdminArticleReadEventEntity(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	/**
	 * The default order by field when the collection is loaded .
	 */
	public get orderByField(): IOrderByCondition<Model> | undefined {
		// % protected region % [Modify the order by field here] off begin
		return {
			path: 'readDate',
			descending: false,
		};
		// % protected region % [Modify the order by field here] end
	}

	// % protected region % [Modify props to the crud options here for attribute 'Read Date'] off begin
	/**
	 * User read date
	 */
	@observable
	@attribute()
	@CRUD({
		name: 'Read Date',
		displayType: 'datepicker',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseDate,
	})
	public readDate: Date;
	// % protected region % [Modify props to the crud options here for attribute 'Read Date'] end

	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Articles'] off begin
		name: 'Articles',
		displayType: 'reference-combobox',
		order: 20,
		referenceTypeFunc: () => Models.ArticlesEntity,
		// % protected region % [Modify props to the crud options here for reference 'Articles'] end
	})
	public articlesId?: string;
	@observable
	@attribute({isReference: true})
	public articles: Models.ArticlesEntity;

	// % protected region % [Add any custom attributes to the model here] on begin
	@observable
	public ownerId: string;

	@observable
	public name: string;

	@observable
	public surname: string;

	@observable
	public forename: string;

	@observable
	public userRole: string;

	@observable
	public libraryTagNames: string[];
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<IArticleReadEventEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<IArticleReadEventEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.readDate !== undefined) {
				if (attributes.readDate === null) {
					this.readDate = attributes.readDate;
				} else {
					this.readDate = moment(attributes.readDate).toDate();
				}
			}
			if (attributes.articles !== undefined) {
				if (attributes.articles === null) {
					this.articles = attributes.articles;
				} else {
					if (attributes.articles instanceof Models.ArticlesEntity) {
						this.articles = attributes.articles;
						this.articlesId = attributes.articles.id;
					} else {
						this.articles = new Models.ArticlesEntity(attributes.articles);
						this.articlesId = this.articles.id;
					}
				}
			} else if (attributes.articlesId !== undefined) {
				this.articlesId = attributes.articlesId;
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] on begin
			if (attributes.name) {
				this.name = attributes.name;
			}
			if (attributes.surname) {
				this.surname = attributes.surname;
			}
			if (attributes.forename) {
				this.forename = attributes.forename;
			}
			if (attributes.userRole) {
				this.userRole = attributes.userRole;
			}
			if (attributes.libraryTagNames) {
				this.libraryTagNames = attributes.libraryTagNames;
			}
			if (attributes.ownerId) {
				this.ownerId = attributes.ownerId;
			}
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] off begin
	public defaultExpands = `
		articles {
			${Models.ArticlesEntity.getAttributes().join('\n')}
			${Models.ArticlesEntity.getFiles().map(f => f.name).join('\n')}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] off begin
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath = {
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
						]
					},
				],
			}
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] off begin
		return this.id;
		// % protected region % [Customise the display name for this entity] end
	}


	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [ createdAttr, modifiedAttr ] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(ArticleReadEventEntity.prototype, 'created');
CRUD(modifiedAttr)(ArticleReadEventEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
