/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import { Model, IModelAttributes, attribute, entity } from 'Models/Model';
import * as Models from 'Models/Entities';
import { IAcl } from '../Security/IAcl';
import { observable } from 'mobx';
import { VisitorsRatingsReferenceManyToMany } from '../Security/Acl/VisitorsRatingsReferenceManyToMany';
import { PatientRatingsReferenceManyToMany } from '../Security/Acl/PatientRatingsReferenceManyToMany';
import { ClinicianRatingsReferenceManyToMany } from '../Security/Acl/ClinicianRatingsReferenceManyToMany';
import { CarerRatingsReferenceManyToMany } from '../Security/Acl/CarerRatingsReferenceManyToMany';
import { AdminRatingsReferenceManyToMany } from '../Security/Acl/AdminRatingsReferenceManyToMany';

// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface IUsersRatingsAttributes extends IModelAttributes {
	usersId: string;
	ratingsId: string;

	users: Models.PatientEntity | Models.IPatientEntityAttributes;
	ratings: Models.RatingpersonalEntity | Models.IRatingpersonalEntityAttributes;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

@entity('UsersRatings')
export default class UsersRatings extends Model implements IUsersRatingsAttributes {
	public static acls: IAcl[] = [
		new VisitorsRatingsReferenceManyToMany(),
		new PatientRatingsReferenceManyToMany(),
		new ClinicianRatingsReferenceManyToMany(),
		new CarerRatingsReferenceManyToMany(),
		new AdminRatingsReferenceManyToMany(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	@observable
	@attribute()
	public usersId: string;

	@observable
	@attribute()
	public ratingsId: string;

	@observable
	@attribute({isReference: true})
	public users: Models.PatientEntity;

	@observable
	@attribute({isReference: true})
	public ratings: Models.RatingpersonalEntity;
	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	constructor(attributes?: Partial<IUsersRatingsAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		if (attributes) {
			if (attributes.usersId) {
				this.usersId = attributes.usersId;
			}
			if (attributes.ratingsId) {
				this.ratingsId = attributes.ratingsId;
			}

			if (attributes.users) {
				if (attributes.users instanceof Models.PatientEntity) {
					this.users = attributes.users;
					this.usersId = attributes.users.id;
				} else {
					this.users = new Models.PatientEntity(attributes.users);
					this.usersId = this.users.id;
				}
			} else if (attributes.usersId !== undefined) {
				this.usersId = attributes.usersId;
			}

			if (attributes.ratings) {
				if (attributes.ratings instanceof Models.RatingpersonalEntity) {
					this.ratings = attributes.ratings;
					this.ratingsId = attributes.ratings.id;
				} else {
					this.ratings = new Models.RatingpersonalEntity(attributes.ratings);
					this.ratingsId = this.ratings.id;
				}
			} else if (attributes.ratingsId !== undefined) {
				this.ratingsId = attributes.ratingsId;
			}
		}

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}