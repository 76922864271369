/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import moment from 'moment';
import { action, observable } from 'mobx';
import { Model, IModelAttributes, attribute, entity } from 'Models/Model';
import {IOrderByCondition} from 'Views/Components/ModelCollection/ModelQuery';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from "Util/AttributeUtils";
import { IAcl } from 'Models/Security/IAcl';
import {
	makeFetchManyToManyFunc,
	makeJoinEqualsFunc,
	makeFetchOneToManyFunc,
	makeEnumFetchFunction,
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import { VisitorsClinicianEntity } from 'Models/Security/Acl/VisitorsClinicianEntity';
import { PatientClinicianEntity } from 'Models/Security/Acl/PatientClinicianEntity';
import { ClinicianClinicianEntity } from 'Models/Security/Acl/ClinicianClinicianEntity';
import { CarerClinicianEntity } from 'Models/Security/Acl/CarerClinicianEntity';
import { AdminClinicianEntity } from 'Models/Security/Acl/AdminClinicianEntity';
import * as Enums from '../Enums';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import {SuperAdministratorScheme} from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] on begin
import { computed } from 'mobx';
// % protected region % [Add any further imports here] end

export interface IClinicianEntityAttributes extends IModelAttributes {
	email: string;
	forename: string;
	middlename: string;
	surname: string;
	gender: Enums.gender;
	dateOfBirth: Date;
	phone: string;
	customFields: string;

	demoAdminss: Array<Models.AdminEntity | Models.IAdminEntityAttributes>;
	demoPatientId?: string;
	demoPatient?: Models.PatientEntity | Models.IPatientEntityAttributes;
	todoss: Array<Models.TodoEntity | Models.ITodoEntityAttributes>;
	userss: Array<Models.CliniciansUsers | Models.ICliniciansUsersAttributes>;
	viewedactivitiesSubmissionss: Array<Models.CliniciansViewedactivitiesSubmissions | Models.ICliniciansViewedactivitiesSubmissionsAttributes>;
	viewedratingpersonalsubmissionss: Array<Models.CliniciansViewedratingpersonalsubmissions | Models.ICliniciansViewedratingpersonalsubmissionsAttributes>;
	viewedratingtemplatesubmissionss: Array<Models.CliniciansViewedratingtemplatesubmissions | Models.ICliniciansViewedratingtemplatesubmissionsAttributes>;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('ClinicianEntity', 'Clinician')
// % protected region % [Customise your entity metadata here] end
export default class ClinicianEntity extends Model implements IClinicianEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new VisitorsClinicianEntity(),
		new PatientClinicianEntity(),
		new ClinicianClinicianEntity(),
		new CarerClinicianEntity(),
		new AdminClinicianEntity(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		'email',
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	/**
	 * The default order by field when the collection is loaded .
	 */
	public get orderByField(): IOrderByCondition<Model> | undefined {
		// % protected region % [Modify the order by field here] off begin
		return {
			path: 'forename',
			descending: false,
		};
		// % protected region % [Modify the order by field here] end
	}

	// % protected region % [Modify props to the crud options here for attribute 'Email'] on begin
	@Validators.Email()
	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		name: 'Email',
		displayType: 'displayfield',
		createFieldType: 'textfield',
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
		order: 1,
	})
	public email: string;
	// % protected region % [Modify props to the crud options here for attribute 'Email'] end

	// % protected region % [Modify props to the crud options here for attribute 'Password'] on begin
	@Validators.Length(6)
	@observable
	@CRUD({
		name: 'Password',
		displayType: 'hidden',
		createFieldType: 'password',
		order: 2,
	})
	public password: string;
	// % protected region % [Modify props to the crud options here for attribute 'Password'] end

	// % protected region % [Modify props to the crud options here for attribute 'Confirm Password'] on begin
	@Validators.Custom('Password Match', (e: string, target: ClinicianEntity) => {
		return new Promise((resolve) => resolve(target.password !== e ? 'Password fields do not match' : null));
	})
	@observable
	@CRUD({
		name: 'Confirm Password',
		displayType: 'hidden',
		createFieldType: 'password',
		order: 3,
	})
	public _confirmPassword: string;
	// % protected region % [Modify props to the crud options here for attribute 'Confirm Password'] end

	// % protected region % [Modify props to the crud options here for attribute 'Forename'] on begin
	/* Forename */
	@observable
	@attribute()
	@CRUD({
		name: 'First Name',
		displayType: 'textfield',
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
		order: 4,
	})
	public forename: string;
	// % protected region % [Modify props to the crud options here for attribute 'Forename'] end

	// % protected region % [Modify props to the crud options here for attribute 'Middlename'] off begin
	/**
	 * Middlename
	 */
	@observable
	@attribute()
	@CRUD({
		name: 'Middlename',
		displayType: 'textfield',
		order: 50,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public middlename: string;
	// % protected region % [Modify props to the crud options here for attribute 'Middlename'] end

	// % protected region % [Modify props to the crud options here for attribute 'Surname'] on begin
	/* Surname */
	@observable
	@attribute()
	@CRUD({
		name: 'Last Name',
		displayType: 'textfield',
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
		order: 5,
	})
	public surname: string;
	// % protected region % [Modify props to the crud options here for attribute 'Surname'] end

	// % protected region % [Modify props to the crud options here for attribute 'Gender'] on begin
	/* Gender */
	@observable
	@attribute()
	@CRUD({
		name: 'Gender',
		displayType: 'enum-combobox',
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: (attr: string) => {
			return AttrUtils.standardiseEnum(attr, Enums.genderOptions);
		},
		enumResolveFunction: makeEnumFetchFunction(Enums.genderOptions),
		displayFunction: (attribute: Enums.gender) => Enums.genderOptions[attribute],
		order: 6,
	})
	public gender: Enums.gender;
	// % protected region % [Modify props to the crud options here for attribute 'Gender'] end

	// % protected region % [Modify props to the crud options here for attribute 'Date of Birth'] on begin
	/* DoB */
	@observable
	@attribute()
	@CRUD({
		name: 'Date of Birth',
		displayType: 'datepicker',
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseDate,
		order: 7,
		inputProps: {
			maxDate: moment(new Date().toISOString()).format('YYYY-MM-DD'),
		},
	})
	public dateOfBirth: Date;
	// % protected region % [Modify props to the crud options here for attribute 'Date of Birth'] end

	// % protected region % [Modify props to the crud options here for attribute 'Phone'] off begin
	/**
	 * Phone Number
	 */
	@observable
	@attribute()
	@CRUD({
		name: 'Phone',
		displayType: 'textfield',
		order: 90,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public phone: string;
	// % protected region % [Modify props to the crud options here for attribute 'Phone'] end

	// % protected region % [Modify props to the crud options here for attribute 'Custom Fields'] off begin
	/**
	 * Custom fields for the clinician
	 */
	@observable
	@attribute()
	@CRUD({
		name: 'Custom Fields',
		displayType: 'textfield',
		order: 100,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public customFields: string;
	// % protected region % [Modify props to the crud options here for attribute 'Custom Fields'] end

	@observable
	@attribute({isReference: true})
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Demo Admins'] on begin
		// Hiding from admin crud because it is not expected to be explicitly editted.
		name: "Demo Adminss",
		displayType: 'hidden', //'reference-multicombobox',
		order: 120,
		referenceTypeFunc: () => Models.AdminEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'demoAdminss',
			oppositeEntity: () => Models.AdminEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Demo Admins'] end
	})
	public demoAdminss: Models.AdminEntity[] = [];

	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Demo Patient'] on begin
		// Hiding from admin crud because it is not expected to be explicitly editted.
		name: 'Demo Patient',
		displayType: 'hidden', //'reference-combobox',
		order: 110,
		referenceTypeFunc: () => Models.PatientEntity,
		// % protected region % [Modify props to the crud options here for reference 'Demo Patient'] end
	})
	public demoPatientId?: string;
	@observable
	@attribute({isReference: true})
	public demoPatient: Models.PatientEntity;

	/**
	 * ToDo
	 */
	@observable
	@attribute({isReference: true})
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'ToDos'] off begin
		name: "ToDoss",
		displayType: 'reference-multicombobox',
		order: 130,
		referenceTypeFunc: () => Models.TodoEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'todoss',
			oppositeEntity: () => Models.TodoEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'ToDos'] end
	})
	public todoss: Models.TodoEntity[] = [];

	@observable
	@attribute({isReference: true})
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Users'] on begin
		name: 'Users',
		displayType: 'reference-multicombobox',
		isJoinEntity: true,
		referenceTypeFunc: () => Models.CliniciansUsers,
		optionEqualFunc: makeJoinEqualsFunc('usersId'),
		referenceResolveFunction: makeFetchManyToManyFunc({
			entityName: 'clinicianEntity',
			oppositeEntityName: 'patientEntity',
			relationName: 'clinicians',
			relationOppositeName: 'users',
			entity: () => Models.ClinicianEntity,
			joinEntity: () => Models.CliniciansUsers,
			oppositeEntity: () => Models.PatientEntity,
		}),
		order: 8,
		// % protected region % [Modify props to the crud options here for reference 'Users'] end
	})
	public userss: Models.CliniciansUsers[] = [];

	@observable
	@attribute({isReference: true})
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'ViewedActivities Submissions'] on begin
		// @performance m2m fetches are chugging the page and causing request to time out.
		// - removing m2m joins from the admin crud that are not expected to be editted.
		// - also removed because it was modelled but never implemented
		name: 'ViewedActivities Submissions',
		displayType: 'hidden', //reference-multicombobox',
		order: 150,
		isJoinEntity: true,
		referenceTypeFunc: () => Models.CliniciansViewedactivitiesSubmissions,
		optionEqualFunc: makeJoinEqualsFunc('viewedactivitiesSubmissionsId'),
		referenceResolveFunction: makeFetchManyToManyFunc({
			entityName: 'clinicianEntity',
			oppositeEntityName: 'activitiesSubmissionEntity',
			relationName: 'clinicians',
			relationOppositeName: 'viewedactivitiesSubmissions',
			entity: () => Models.ClinicianEntity,
			joinEntity: () => Models.CliniciansViewedactivitiesSubmissions,
			oppositeEntity: () => Models.ActivitiesSubmissionEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'ViewedActivities Submissions'] end
	})
	public viewedactivitiesSubmissionss: Models.CliniciansViewedactivitiesSubmissions[] = [];

	@observable
	@attribute({isReference: true})
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'ViewedRatingPersonalSubmissions'] on begin
		// @performance m2m fetches are chugging the page and causing request to time out.
		// - removing m2m joins from the admin crud that are not expected to be editted.
		// - also removed because it was modelled but never implemented
		name: 'ViewedRatingPersonalSubmissions',
		displayType: 'hidden', //reference-multicombobox',
		order: 160,
		isJoinEntity: true,
		referenceTypeFunc: () => Models.CliniciansViewedratingpersonalsubmissions,
		optionEqualFunc: makeJoinEqualsFunc('viewedratingpersonalsubmissionsId'),
		referenceResolveFunction: makeFetchManyToManyFunc({
			entityName: 'clinicianEntity',
			oppositeEntityName: 'ratingpersonalSubmissionEntity',
			relationName: 'clinicians',
			relationOppositeName: 'viewedratingpersonalsubmissions',
			entity: () => Models.ClinicianEntity,
			joinEntity: () => Models.CliniciansViewedratingpersonalsubmissions,
			oppositeEntity: () => Models.RatingpersonalSubmissionEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'ViewedRatingPersonalSubmissions'] end
	})
	public viewedratingpersonalsubmissionss: Models.CliniciansViewedratingpersonalsubmissions[] = [];

	@observable
	@attribute({isReference: true})
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'ViewedRatingTemplateSubmissions'] on begin
		// @performance m2m fetches are chugging the page and causing request to time out.
		// - removing m2m joins from the admin crud that are not expected to be editted.
		// - also removed because it was modelled but never implemented
		name: 'ViewedRatingTemplateSubmissions',
		displayType: 'hidden', //reference-multicombobox',
		order: 170,
		isJoinEntity: true,
		referenceTypeFunc: () => Models.CliniciansViewedratingtemplatesubmissions,
		optionEqualFunc: makeJoinEqualsFunc('viewedratingtemplatesubmissionsId'),
		referenceResolveFunction: makeFetchManyToManyFunc({
			entityName: 'clinicianEntity',
			oppositeEntityName: 'ratingtemplateSubmissionEntity',
			relationName: 'clinicians',
			relationOppositeName: 'viewedratingtemplatesubmissions',
			entity: () => Models.ClinicianEntity,
			joinEntity: () => Models.CliniciansViewedratingtemplatesubmissions,
			oppositeEntity: () => Models.RatingtemplateSubmissionEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'ViewedRatingTemplateSubmissions'] end
	})
	public viewedratingtemplatesubmissionss: Models.CliniciansViewedratingtemplatesubmissions[] = [];

	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<IClinicianEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<IClinicianEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.email !== undefined) {
				this.email = attributes.email;
			}
			if (attributes.forename !== undefined) {
				this.forename = attributes.forename;
			}
			if (attributes.middlename !== undefined) {
				this.middlename = attributes.middlename;
			}
			if (attributes.surname !== undefined) {
				this.surname = attributes.surname;
			}
			if (attributes.gender !== undefined) {
				this.gender = attributes.gender;
			}
			if (attributes.dateOfBirth !== undefined) {
				if (attributes.dateOfBirth === null) {
					this.dateOfBirth = attributes.dateOfBirth;
				} else {
					this.dateOfBirth = moment(attributes.dateOfBirth).toDate();
				}
			}
			if (attributes.phone !== undefined) {
				this.phone = attributes.phone;
			}
			if (attributes.customFields !== undefined) {
				this.customFields = attributes.customFields;
			}
			if (attributes.demoAdminss !== undefined && Array.isArray(attributes.demoAdminss)) {
				for (const model of attributes.demoAdminss) {
					if (model instanceof Models.AdminEntity) {
						this.demoAdminss.push(model);
					} else {
						this.demoAdminss.push(new Models.AdminEntity(model));
					}
				}
			}
			if (attributes.demoPatient !== undefined) {
				if (attributes.demoPatient === null) {
					this.demoPatient = attributes.demoPatient;
				} else {
					if (attributes.demoPatient instanceof Models.PatientEntity) {
						this.demoPatient = attributes.demoPatient;
						this.demoPatientId = attributes.demoPatient.id;
					} else {
						this.demoPatient = new Models.PatientEntity(attributes.demoPatient);
						this.demoPatientId = this.demoPatient.id;
					}
				}
			} else if (attributes.demoPatientId !== undefined) {
				this.demoPatientId = attributes.demoPatientId;
			}
			if (attributes.todoss !== undefined && Array.isArray(attributes.todoss)) {
				for (const model of attributes.todoss) {
					if (model instanceof Models.TodoEntity) {
						this.todoss.push(model);
					} else {
						this.todoss.push(new Models.TodoEntity(model));
					}
				}
			}
			if (attributes.userss !== undefined && Array.isArray(attributes.userss)) {
				for (const model of attributes.userss) {
					if (model instanceof Models.CliniciansUsers) {
						this.userss.push(model);
					} else {
						this.userss.push(new Models.CliniciansUsers(model));
					}
				}
			}
			if (attributes.viewedactivitiesSubmissionss !== undefined && Array.isArray(attributes.viewedactivitiesSubmissionss)) {
				for (const model of attributes.viewedactivitiesSubmissionss) {
					if (model instanceof Models.CliniciansViewedactivitiesSubmissions) {
						this.viewedactivitiesSubmissionss.push(model);
					} else {
						this.viewedactivitiesSubmissionss.push(new Models.CliniciansViewedactivitiesSubmissions(model));
					}
				}
			}
			if (attributes.viewedratingpersonalsubmissionss !== undefined && Array.isArray(attributes.viewedratingpersonalsubmissionss)) {
				for (const model of attributes.viewedratingpersonalsubmissionss) {
					if (model instanceof Models.CliniciansViewedratingpersonalsubmissions) {
						this.viewedratingpersonalsubmissionss.push(model);
					} else {
						this.viewedratingpersonalsubmissionss.push(new Models.CliniciansViewedratingpersonalsubmissions(model));
					}
				}
			}
			if (attributes.viewedratingtemplatesubmissionss !== undefined && Array.isArray(attributes.viewedratingtemplatesubmissionss)) {
				for (const model of attributes.viewedratingtemplatesubmissionss) {
					if (model instanceof Models.CliniciansViewedratingtemplatesubmissions) {
						this.viewedratingtemplatesubmissionss.push(model);
					} else {
						this.viewedratingtemplatesubmissionss.push(new Models.CliniciansViewedratingtemplatesubmissions(model));
					}
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] off begin
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] off begin
	public defaultExpands = `
		userss {
			${Models.CliniciansUsers.getAttributes().join('\n')}
			users {
				${Models.PatientEntity.getAttributes().join('\n')}
			}
		}
		viewedactivitiesSubmissionss {
			${Models.CliniciansViewedactivitiesSubmissions.getAttributes().join('\n')}
			viewedactivitiesSubmissions {
				${Models.ActivitiesSubmissionEntity.getAttributes().join('\n')}
			}
		}
		viewedratingpersonalsubmissionss {
			${Models.CliniciansViewedratingpersonalsubmissions.getAttributes().join('\n')}
			viewedratingpersonalsubmissions {
				${Models.RatingpersonalSubmissionEntity.getAttributes().join('\n')}
			}
		}
		viewedratingtemplatesubmissionss {
			${Models.CliniciansViewedratingtemplatesubmissions.getAttributes().join('\n')}
			viewedratingtemplatesubmissions {
				${Models.RatingtemplateSubmissionEntity.getAttributes().join('\n')}
			}
		}
		demoAdminss {
			${Models.AdminEntity.getAttributes().join('\n')}
		}
		demoPatient {
			${Models.PatientEntity.getAttributes().join('\n')}
		}
		todoss {
			${Models.TodoEntity.getAttributes().join('\n')}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] off begin
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath = {
			userss: {},
			viewedactivitiesSubmissionss: {},
			viewedratingpersonalsubmissionss: {},
			viewedratingtemplatesubmissionss: {},
			demoAdminss: {},
			todoss: {},
		};

		if (formMode === 'create') {
			relationPath['password'] = {};

			if (this.password !== this._confirmPassword) {
				throw Error("Password fields do not match");
			}
		}
		return this.save(
			relationPath,
			{
				graphQlInputType: formMode === 'create'
					? `[${this.getModelName()}CreateInput]`
					: `[${this.getModelName()}Input]`,
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
							'demoAdminss',
							'todoss',
							'userss',
							'viewedactivitiesSubmissionss',
							'viewedratingpersonalsubmissionss',
							'viewedratingtemplatesubmissionss',
						]
					},
				],
			}
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] off begin
		return this.email;
		// % protected region % [Customise the display name for this entity] end
	}


	// % protected region % [Add any further custom model features here] on begin
	@computed
	get fullname() {
		return `${this.forename} ${this.surname}`;
	}
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [ createdAttr, modifiedAttr ] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(ClinicianEntity.prototype, 'created');
CRUD(modifiedAttr)(ClinicianEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
