import {
	BasePatientGraphData,
	DASHBOARD_GRAPH_COMMON_CHART_OPTIONS,
	DashboardGraphDateRange,
	IBasePatientGraphDataAttributes
} from '../Views/Components/PatientGraphs/PatientCommonGraph';
import Axios from 'axios';
import { SeriesOptionsType } from 'highcharts';
import moment from 'moment';
import { GRADIENT_COLORS } from '../Constants';

export default class ActivitiesSubmissionService {

	private static readonly BASE_URL = '/api/entity/ActivitiesSubmissionEntity';

	public static getPatientActivitiesGraphData(dateRange: DashboardGraphDateRange) {
		const URL = `${ActivitiesSubmissionService.BASE_URL}/patient-activities-graph-data`;
		return Axios.post<PatientActivitiesGraphData>(URL, dateRange);
	}
}


interface IPatientActivitiesGraphData extends IBasePatientGraphDataAttributes<PatientActivitiesDay> {

}

export class PatientActivitiesGraphData extends BasePatientGraphData<PatientActivitiesDay> {
	constructor(attributes?: Partial<IPatientActivitiesGraphData>) {
		super(attributes);
		if (attributes) {
			if (attributes.data) {
				for (const model of attributes.data) {
					if (model instanceof PatientActivitiesDay) {
						this.data.push(model);
					} else {
						this.data.push(new PatientActivitiesDay(model));
					}
				}
			}
		}
	}

	// Return a list of date strings for the x axis of the graph
	getXCategories = () => {
		return this.data.map(day => moment(day.date).format('D MMM'));
	}

	getSubmissionsSeriesData = (): SeriesOptionsType => {
		return {
			name: 'Activities Completed',
			type: DASHBOARD_GRAPH_COMMON_CHART_OPTIONS.seriesType,
			data: this.data.map(data => data.numberSubmissions),
			color: GRADIENT_COLORS.PURPLE,
		} as SeriesOptionsType;
	}
}

interface IPatientActivitiesDayAttributes {
	date: Date;
	numberSubmissions: number;
}

/**
 * Single graph data point.
 */
class PatientActivitiesDay {
	public date: Date;
	public numberSubmissions: number = 0;

	constructor(attributes?: Partial<IPatientActivitiesDayAttributes>) {
		if (attributes) {
			if (attributes.date) {
				this.date = moment(attributes.date).local().toDate();
			}
			if (attributes.numberSubmissions) {
				this.numberSubmissions = attributes.numberSubmissions;
			}
		}
	}
}
