import * as React from 'react';
import { observer } from 'mobx-react';
import { RouteComponentProps } from 'react-router';
import SecuredPage from 'Views/Components/Security/SecuredPage';
import Navigation, { Orientation } from 'Views/Components/Navigation/Navigation';
import { getFrontendNavLinks } from 'Views/FrontendNavLinks';
import GoalAreas from 'Views/Components/Goals/GoalAreas';
import { observable, runInAction } from 'mobx';
import { AreaOfLifeRatingsEntity, PatientEntity } from 'Models/Entities';
import { store } from 'Models/Store';
import { Button, Display, Widths } from '../Components/Button/Button';
import Spinner from '../Components/Spinner/Spinner';
import PatientService from '../../Services/PatientService/PatientService';
import { PatientExpands } from '../../Services/PatientService/PatientEntityExpands';

@observer
export default class RateAreasOfLife extends React.Component<RouteComponentProps> {

	@observable
	private areaOfLifeRating = new AreaOfLifeRatingsEntity();

	private initialLifeRating = new AreaOfLifeRatingsEntity();

	@observable
	private user: PatientEntity = new PatientEntity();

	@observable
	private isLoaded = false;

	async componentDidMount() {
		if (store.userId == null) {
			return;
		}
		try {
			const res = await PatientService.getPatient(store.userId, PatientExpands.patientRateAllAreaOfLife)
			runInAction(() => {
				this.user = new PatientEntity(res[0]);
				this.areaOfLifeRating = new AreaOfLifeRatingsEntity(this.user.getLatestAreaofLifeValues());
				// Store for later comparison;
				this.initialLifeRating = new AreaOfLifeRatingsEntity(this.user.getLatestAreaofLifeValues());
			})
		} catch (e) {
			console.log('Error fetching patient')
		} finally {
			runInAction(() => { this.isLoaded = true; })
		}
	}

	public render() {
		const { history, location, match, staticContext } = this.props;

		if (!this.isLoaded) {
			return <Spinner />
		}
		
		return (
			<SecuredPage groups={["Patient"]}>
				<Navigation
					linkGroups={getFrontendNavLinks(this.props)}
					orientation={Orientation.VERTICAL}
					match={match}
					location={location}
					history={history}
					staticContext={staticContext}
				/>

				<div className="body-content">
					<div className="rate-areas-of-life-page">
						<div className="goal-areas-container">
							<GoalAreas areasOfLifeRating={this.areaOfLifeRating} />
						</div>
						<Button
							widths={Widths.auto}
							display={Display.Round}
							onClick={() => this.onClickSave()}
						>
							Save
						</Button>
					</div>
				</div>
			</SecuredPage>
		);
	}

	onClickSave = () => {
		// Create empty area of life rating and only copy over values that the user actually changed.
		const areaOfLifeRating = new AreaOfLifeRatingsEntity({
			id: '00000000-0000-0000-0000-000000000000',
			patientId: this.user.id,
			fullRating: true
		});

		areaOfLifeRating.setValuesForChangedAreas(this.initialLifeRating, this.areaOfLifeRating);

		areaOfLifeRating.save().then(res => {
			store.routerHistory.push("/goals");
		})
	}
}
