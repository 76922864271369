import React, { Component } from "react";
import { AreaOfLifeRatingsEntity, PatientEntity } from "Models/Entities";
import { AreaSlider } from "./AreaSlider";
import { lifeArea, lifeAreaDescriptions, lifeAreaOptions } from '../../../Models/Enums';
import { observer } from 'mobx-react';

interface IGoalAreas {
	areasOfLifeRating: AreaOfLifeRatingsEntity;
}

@observer
export default class GoalAreas extends Component<IGoalAreas> {

	render() {
		return (
			<div className="goal-areas">
				<div className="goal-areas__introduction">
					<h4>How would you rate your areas of life?</h4>
					<h5>Indicate how happy you are between 0% and 100% in each of the following areas:</h5>
					<h6>
						<p>1% = Least happy</p>
						<p>100% = Most happy</p>
					</h6>
				</div>

				<div className="goals-card">
					{Object.keys(lifeAreaOptions).map((type) => {
						return (
							<div key={type}>
								<AreaSlider
									type={type as lifeArea}
									areaOfLifeRating={this.props.areasOfLifeRating}
									blurb={lifeAreaDescriptions[type]} />
							</div>
						);
					})}
				</div>
			</div>
		);
	}
}
