import * as React from 'react';
import { PluginComponent, PluginProps } from 'react-markdown-editor-lite';

export default class Collapsible extends PluginComponent {

	constructor(props: PluginProps) {
		super(props);
		this.handleClick = this.handleClick.bind(this);
	}

	handleClick() {
		this.editor.insertText('<details> \n <summary> \n\nAdd Collapsible Title Here\n\n </summary> \n\nAdd Collapsible Content Here\n\n</details> \n');
	}

	render() {
		return (
			<span
				className="button button-type-collapsible"
				title="Collapsible" onClick={this.handleClick}
			>
				<i className="icon-collaspe-left icon-top" style={{ fontStyle: 'normal' }} />
			</span>
		);
	}
}
