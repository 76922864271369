import { store } from 'Models/Store';
import moment from 'moment';
import React, { useState } from 'react';
import Popup from 'reactjs-popup';
import { IForm } from './CheckInTile';

import styles from './../../../scss/components/checkInCard.module.scss';
import classNames from 'classnames';
import { lifeAreaOptions } from 'Models/Enums';
import smartlookClient from "smartlook-client";

export default function FormCard(props: {
	id: string;
	title: string;
	description: string;
	featureImage: string;
	completed: boolean;
	missingDates?: string[];
	incompleteForms?: IForm[];
	incompleteFormIndex?: number;
}) {
	const {
		id,
		title,
		description,
		featureImage,
		completed,
		missingDates,
		incompleteForms,
		incompleteFormIndex,
	} = props;

	const [imageSRC, setImageSRC] = useState(renderImage(featureImage));
	const [popupOpen, setPopupOpen] = useState(false);

	const route = completed ? `/check-in/overview/${id}` : `/check-in/${missingDates![0]}/${id}`;

	const handleOnClickCard = () => {
		smartlookClient.track('Home_Checkin_Open', {});
		// Open popup if there are dates missing
		if (missingDates && missingDates.length >= 1) {
			setPopupOpen(true);
			return;
			// Otherwise, redirect if there are no missing dates
		} else {
			store.routerHistory.push(route, {
				forms: incompleteForms,
				incompleteFormIndex: incompleteFormIndex,
			});
		}
	};

	return (
		<>
			<div className={styles.card} onClick={handleOnClickCard}>
				<div className={styles.cardContent}>
					<div className={styles.cardTitle}>{title}</div>
					{missingDates && (
						<div className={styles.cardRemaining}>
							{getEntriesRemaining()}
						</div>
					)}
					<div
						className={
							missingDates
								? classNames([
									styles.cardDescription,
									styles.cardDescriptionRemaining,
								])
								: styles.cardDescription
						}
					>
						{description}
					</div>
				</div>

				<div className={styles.cardImage}>
					<img src={imageSRC} onError={() => setImageSRC(renderImage())} />
				</div>
			</div>
			<Popup open={popupOpen} onClose={() => setPopupOpen(false)} modal>
				<div className={styles.popup}>{renderMissingDates()}</div>
			</Popup>
		</>
	);

	function getEntriesRemaining() {
		if (missingDates) {
			const remaining = missingDates.length;
			return remaining == 1 ? `${remaining} entry remaining` : `${remaining} entries remaining`;
		}
		return;
	}

	function renderMissingDates() {
		if (missingDates) {
			return (
				<div className={styles.popupContent}>
					<div className={styles.popupTitle}>Select Entry</div>
					{missingDates.map((date: string) => {
						return (
							<div
								key={date}
								className={styles.popupDate}
								onClick={() =>
									store.routerHistory.push(
										`/check-in/${date}/${id}`
									)
								}
							>
								Complete rating for:{' '}
								{moment
									.utc(date)
									.local()
									.format('ddd Do MMM YYYY')}
							</div>
						);
					})}
				</div>
			);
		}
		return;
	}

	function renderImage(featureImage?: string) {
		if (!!featureImage) return `/api/files/${featureImage}`;
		const backGrounds = Object.values(lifeAreaOptions);
		return `${process.env.PUBLIC_URL}/Goals/${backGrounds[Math.floor(Math.random() * Math.floor(backGrounds.length))]}Background.svg`;
	}
}
