import * as React from "react";
import { observer } from "mobx-react";
import { RouteComponentProps } from "react-router";
import Header from "../Components/Header/Header";
import { Button, Widths, Sizes } from "../Components/Button/Button";
import { store } from "Models/Store";
import * as Enums from "../../Models/Enums";
import { action, observable } from "mobx";
import Modal from "../Components/Modal/Modal";
import { RadioButtonGroup } from "../Components/RadioButton/RadioButtonGroup";
import { TextField } from "../Components/TextBox/TextBox";
import { DatePicker } from "../Components/DatePicker/DatePicker";
import { ButtonGroup, Sizing } from "Views/Components/Button/ButtonGroup";
import * as Models from "../../Models/Entities";
import alert from "../../Util/ToastifyUtils";
import axios from "axios";
import If from "Views/Components/If/If";
import { OnboardingSlideEntity } from "../../Models/Entities";
import { EntityFormMode } from "Views/Components/Helpers/Common";
import { gql } from "apollo-boost";
import FileUpload from "Views/Components/FileUpload/FileUpload";
import { TextArea } from "Views/Components/TextArea/TextArea";
import { DESCRIPTION_MAX_LENGTH, TITLE_MAX_LENGTH } from "Models/Entities/OnboardingSlideEntity";

interface OnboardingSlideTileProps {
	userId?: string;
	viewOnly: boolean;
	className?: string;
	label?: string;
}

@observer
export default class OnboardingSlideTile extends React.Component<OnboardingSlideTileProps> {
	@observable
	private allSlides: OnboardingSlideEntity[] = [];

	@observable
	private previewSlide: OnboardingSlideEntity;

	@observable
	private previewIndex: number = 0;

	@observable
	private startSlideContent = (<></>);

	@observable
	private endSlideContent = (<></>);

	@observable
	private previewContent = (<></>);

	@observable
	private extraSlidesContent: any = [];

	@observable
	private hasExtraSlides = false;

	constructor(props: OnboardingSlideTileProps) {
		super(props);
	}

	public render() {
		let contents = <></>;

		contents = (
			<div className="onboarding-page">
				<section className="header-bar">
					<h2>Joyride slides</h2>
				</section>

				<section className="slides-form">
					<section className="create-section">
						<body>
							The joyride slides are seen when the user logins into the app and presses take me on a your. They have controls to skip these slides or progress through.
						</body>

						<h5>Start slide</h5>
						{this.startSlideContent}

						<If condition={this.hasExtraSlides}>
							<h5>Extra slides</h5>
							{this.extraSlidesContent}
						</If>

						<Button
							className="add-slide-button"
							icon={{ icon: "box-plus", iconPos: "icon-left" }}
							onClick={(event) => this.createNewSlides(1, false)}>
							Add Slide
						</Button>

						<h5>End slide</h5>
						{this.endSlideContent}
					</section>

					<section className="preview-section">
						<div className="preview">
							<h3> Preview </h3>
							<div className="app-container">
								<div>
									<h3 className={"title"}>Welcome to YourHealth+, Bradley.</h3>
								</div>
								{this.previewContent}
								<div className={"footer"}>Skip to app</div>
							</div>
						</div>
					</section>
				</section>

				<section className="action-bar">
					<ButtonGroup>
						<Button onClick={() => this.onCancel()} className="btn--outline">
							Cancel
						</Button>
						<Button onClick={() => this.onSave()} className="btn--solid">
							Save
						</Button>
					</ButtonGroup>
				</section>
			</div>
		);

		return contents;
	}

	private onCancel() {
		store.routerHistory.push("/admin");
	}

	@action
	private async onSave() {
		this.reorderSlides();

		// Save changes of each slide
		this.allSlides.forEach(async (slide: OnboardingSlideEntity, i) => {
			await slide.saveFromCrud(EntityFormMode.EDIT);
		});
		alert("Slides saved!");
	}

	public componentDidMount() {
		this.getSlides();
	}

	// Preview section
	@action
	private setPreview() {
		const numSlides = this.allSlides.length;
		let buttons = [];

		// Create buttons for each slide
		for (let i = 0; i < numSlides; i++) {
			buttons.push(
				<Button
					className={this.previewIndex == i ? "active-button preview-button" : "preview-button"}
					onClick={(event) => this.setPreviewSlide(i)}
				/>
			);
		}

		this.previewContent = (
			<div>
				<div className={"slide-container"}>
					{this.previewSlide.featureId ? (
						<img src={`/api/files/${this.previewSlide.featureId}`} />
					) : (
						<div className="image-placeholder">Upload your image</div>
					)}

					<div className={"preview-text"}>
						<h4 className={"preview-title"}>{this.previewSlide.title}</h4>
						<div className={"preview-description"}>{this.previewSlide.description}</div>
					</div>
				</div>
				<ButtonGroup className={"preview-buttons"}>{buttons}</ButtonGroup>
			</div>
		);
	}

	// Set preview slide from certain index of allSlides array
	private setPreviewSlide(index: number) {
		this.previewSlide = this.allSlides[index];
		this.previewIndex = index;
		this.setPreview();
	}

	// Get slides from database
	private async getSlides() {
		console.log("getting slides from database");
		let q = gql`
			query getAllSlides {
				onboardingSlideEntitys {
					id
					title
					description
					order
					featureId
				}
			}
		`;

		store.apolloClient.query({ query: q, fetchPolicy: "network-only" }).then((res) => {
			// Order slides from order attribute
			let orderedSlides = res.data.onboardingSlideEntitys.sort((a: any, b: any) => (a.order > b.order ? 1 : b.order > a.order ? -1 : 0));

			// If database is empty or has less than 1 slide, then create slides to populate first and last slide positions
			if (orderedSlides.length < 2) {
				this.createNewSlides(2 - orderedSlides.length, true);
			} else {
				// Push each slide result to allSlides variable
				orderedSlides.forEach((slide: OnboardingSlideEntity) => {
					this.allSlides.push(new OnboardingSlideEntity(slide));
				});

				// Set slide contents
				this.setSlides();
			}
		});
	}

	// Set page with slide content received from database
	@action
	private setSlides() {
		this.setStartSlide();
		this.setEndSlide();

		// Initialise preview slide to be the start slide if undefined
		this.previewSlide = this.previewSlide !== undefined ? this.previewSlide : this.allSlides[0];

		// If there are extra slides
		if (this.allSlides.length > 2) {
			this.hasExtraSlides = true;
			this.setExtraSlides();
		} else {
			this.hasExtraSlides = false;
		}

		// Assign slide order
		this.reorderSlides();

		// Update preview content
		this.setPreview();
	}

	@action
	private reorderSlides() {
		// Reorder slides according to their position in allSlides array
		for (let i = 1; i <= this.allSlides.length; i++) {
			this.allSlides[i - 1].order = i;
		}
	}

	private async createNewSlides(num: number, dbEmpty: boolean) {
		for (let i = 1; i <= num; i++) {
			let slide = new OnboardingSlideEntity();

			slide.assignAttributes({ title: `New Slide` });

			// If database is empty then assign order and immediately save to database
			if (dbEmpty) {
				slide.assignAttributes({ order: i });
				await slide.saveFromCrud(EntityFormMode.EDIT);
			}

			// Push new slide to second last position in allSlides array
			let lastSlide = this.allSlides.pop();
			this.allSlides.push(slide);
			if (lastSlide) this.allSlides.push(lastSlide);
		}

		// Re set slide content on page
		this.setSlides();
	}

	private deleteSlide(slideId: string) {
		let m = gql`
			mutation deleteSlide {
				deleteOnboardingSlideEntity (
				onboardingSlideEntityIds: ["${slideId}"]
				){id}
			}
			`;

		store.apolloClient
			.mutate({ mutation: m, fetchPolicy: "no-cache" })
			.then((res) => {})
			.catch(() => {})
			.finally(() => {
				// Deletes slide from allSlides array
				this.allSlides = this.allSlides.filter(function (obj) {
					return obj.id !== slideId;
				});

				// Re set slide content on page
				this.setSlides();
			});
	}

	private setStartSlide() {
		let startSlide: OnboardingSlideEntity = this.allSlides[0];

		this.startSlideContent = <OnboardingSlideEditor model={startSlide} onChange={() => this.setPreviewSlide(0)} />;
	}

	private setEndSlide() {
		let endSlide: OnboardingSlideEntity = this.allSlides[this.allSlides.length - 1];

		this.endSlideContent = <OnboardingSlideEditor model={endSlide} onChange={() => this.setPreviewSlide(this.allSlides.length - 1)} />;
	}

	private setExtraSlides() {
		this.extraSlidesContent = this.allSlides.map((slide: OnboardingSlideEntity, i) => {
			if (i != 0 && i != this.allSlides.length - 1) {
				return (
					<>
						<OnboardingSlideEditor model={slide} onChange={() => this.setPreviewSlide(i)} />

						<Button
							className="remove-slide-button"
							icon={{ icon: "bin-2", iconPos: "icon-left" }}
							onClick={(event) => this.deleteSlide(slide["id"])}>
							Remove
						</Button>
					</>
				);
			} else {
				return <></>;
			}
		});
	}
}

interface IOnboardingSlideEditor {
	model: OnboardingSlideEntity;
	onChange: () => void;
}

@observer
class OnboardingSlideEditor extends React.Component<IOnboardingSlideEditor> {
	@observable
	private descriptionLength: number = this.props.model.description ? this.props.model.description.length : 0;

	componentDidMount() {
		this.getBlob();
	}

	private async handleImageChange() {
		await this.props.model.saveFromCrud(EntityFormMode.EDIT);
		this.props.onChange();
	}

	private async getBlob() {
		axios.get(`/api/files/${this.props.model.featureId}`, { responseType: "blob" }).then(
			action((d) => {
				console.log(d);
				this.props.model.feature = d.data;
			})
		);
	}

	@action
	private handleDescriptionChange() {
		this.descriptionLength = this.props.model.description.length;
		this.props.onChange();
	}

	render() {
		return (
			<div className="slider-editor">
				<div className="upload-image">
					<FileUpload
						model={this.props.model}
						modelProperty="feature"
						imageUpload
						preview={true}
						onAfterChange={() => this.handleImageChange()}
					/>
					<div className="dimensions">
						<p>Recommended Dimensions:</p>
						<p>h: 240px by w: 280px</p>
					</div>
				</div>
				<div className="text">
					<TextField
						model={this.props.model}
						modelProperty="title"
						label="Title"
						isRequired={true}
						onAfterChange={() => this.props.onChange()}
						inputProps={{ maxLength: TITLE_MAX_LENGTH }}
					/>
					<TextArea
						model={this.props.model}
						modelProperty="description"
						label="Description"
						isRequired={true}
						textAreaProps={{ maxLength: DESCRIPTION_MAX_LENGTH }}
						onAfterChange={() => this.handleDescriptionChange()}
					/>
					<div className="description-length">{this.descriptionLength + "/ 220"}</div>
				</div>
			</div>
		);
	}
}
