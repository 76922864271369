import * as React from "react";
import { observer } from "mobx-react";
import { observable, action } from "mobx";
import { store } from "Models/Store";
import { CSVClinicianTemplate } from "../CSVUserImport/CSVClinicianImport";
import { toJS } from "mobx";
import axios from "axios";
// import { Date, Email } from 'Validators';
import moment from "moment";
import alert from "../../../Util/ToastifyUtils";
import { DateTimePicker } from "../DateTimePicker/DateTimePicker";
import If from "../If/If";
import { gql, DocumentNode } from "apollo-boost";
import { Combobox } from '../Combobox/Combobox';

export interface IFailedClinicianCSVTableProps {
    headers: string[];
    data: any[];
    entity: any;
}

@observer
export class FailedClinicianCSVTable extends React.Component<IFailedClinicianCSVTableProps> {
    @observable
    private tableData: any[];

    @observable
    private rows: any;

    @observable
    private icon: string[] = [];

    constructor(props: IFailedClinicianCSVTableProps) {
        super(props);
        this.tableData = props.data[0];
        this.updateTableData = this.updateTableData.bind(this);
        this.props.headers.pop();   // remove pos from headers

        for (let i = 0; i < this.tableData.length; i++) {
            this.icon.push("icon-cross icon-left");
        }
    }

    @action
    private updateRowState() {
        //assign row content
        this.rows = (
            <>
                {" "}
                {this.tableData.map((data: any, i) => {
                    return (
                        <TableRow
                            row={data}
                            keys={this.props.headers}
                            icon={this.icon}
                            onUpdate={this.updateTableData}
                            index={i}
                        />
                    );
                })}
            </>
        );
    }

    @action
    private updateTableData(row: number, rowData: Object) {
        this.tableData[row] = rowData;
        let valid = true;

        //frontend row validation
        this.props.headers.forEach((header: string) => {
            let entry = this.tableData[row][header];
            let emailExp = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

            if (header == "email") {
                valid = emailExp.test(entry) ? true : false;
            } else if (header == "gender") {
                let gender = entry.toUpperCase();

                if (gender !== "" && gender !== "FEMALE" && gender !== "MALE") {
                    valid = false;
                } else {
                    this.tableData[row][header] = this.tableData[row][
                        header
                    ].toUpperCase();
                }
            } else if (header == "dateofbirth" && entry !== "") {
                if (!moment(entry, "DD/MM/YYYY", true).isValid()) {
                    valid = false;
                }
            }
        });

        //update icon for current row
        this.icon[row] = valid
            ? "icon-check icon-left"
            : "icon-cross icon-left";
    }

    @action
    private setTableData(data: CSVClinicianTemplate[]) {
        this.tableData = data;
    }

    @action
    private onSubmit() {
        let finalTable = [...this.tableData];
        let failedRows: CSVClinicianTemplate[] = [];
        let counter = 0;

        //reset table data
        this.setTableData([]);
        this.updateRowState();

        //attempt to post each row of table
        toJS(finalTable).forEach((row: CSVClinicianTemplate, i) => {
            //for some reason blank emails will get posted, so manually push as failed when email is blank
            if (row.email != "") {
                axios
                    .post(
                        "/api/entity/ClinicianEntity/import-users",
                        row
                    )
                    .then(() => {
                        this.icon[i] = "icon-cross icon-left";
                        alert(`${row.email} imported!`, "success");
                    })
                    .catch(() => {
                        //push failed row to failedRow array
                        failedRows.push(row);
                    })
                    .finally(() => {
                        counter++;

                        //repopulate table with rows that still fail and check why they failed
                        if (counter == finalTable.length) {
                            failedRows = failedRows.sort((a, b) => (a.pos > b.pos) ? 1 : (b.pos > a.pos) ? -1 : 0); // sort table by line position in csv
                            
                            this.setTableData(failedRows);
                            this.updateRowState();

                            //redirect back to patients page if no failed rows left
                            if (failedRows.length == 0) {
                                alert("All imports succeeded!", "success");
                                window.open("/Clinician", "_self");
                            }
                        }
                    });
            } else {
                //push failed row to failedRow array
                failedRows.push(row);
                counter++;
            }
        });
    }

    @action
    private onCancel() {
        store.routerHistory.goBack();
    }

    public componentDidMount() {
        this.updateRowState();
    }

    public render() {
        return (
            <div className={"editable-table"}>
                <table cellSpacing="0" cellPadding="0">
                    <tbody>
                        <tr>
                            <th>
                                <div className={"icon-circle icon-left"}></div>
                            </th>

                            {this.props.headers.map((header: string) => {
                                let title = header;

                                if (title.match("dateofbirth")) {
                                    title = "Date of Birth";
                                } else if (title.match("firstname")) {
                                    title = "First Name";
                                }

                                return <th key={header}>{title}</th>;
                            })}
                        </tr>

                        {this.rows}
                    </tbody>
                </table>

                <div className={"buttons-div"}>
                    <button
                        className={"btn btn--solid"}
                        onClick={() => this.onSubmit()}
                    >
                        Submit
                    </button>
                    <button
                        className={"btn btn--outline"}
                        onClick={this.onCancel}
                    >
                        Cancel
                    </button>
                </div>
            </div>
        );
    }
}

export interface ITableRowProps {
    row: Object;
    keys: string[];
    onUpdate: (index: number, rowData: Object) => void;
    index: number;
    icon: string[];
}

@observer
export default class TableRow extends React.Component<ITableRowProps> {
    @observable
    private rowData: Object;

    @observable
    private clicked: boolean[] = [];

    @observable
    private emailError: string = "";

    @observable
    private dobError: string = "";

    @observable
    private genderError: string = "";

    private genderOptions = [
        {display: 'MALE', value: 'MALE'},
        {display: 'FEMALE', value: 'FEMALE'}
    ];

    constructor(props: ITableRowProps) {
        super(props);

        this.rowData = { ...props.row };

        this.checkEmail(this.rowData["email"]);
        this.checkBirth(this.rowData["dateofbirth"]);
        this.checkGender(this.rowData["gender"]);

        for (let i = 0; i < props.keys.length; i++) {
            this.clicked.push(true);
        }
    }

    @action
    private onChange(event: any, header:string) {
        this.rowData[header] = event.target.value;
        if(header=="email") this.checkEmail(this.rowData[header]);
        this.props.onUpdate(this.props.index, this.rowData);
    }

    @action
    private onGenderChange(event: any, header: string) {
        this.rowData[header] = event.target.textContent;
        this.checkGender(this.rowData[header]);
        this.props.onUpdate(this.props.index, this.rowData);
    }

    @action
    private onDateChange(date: Date[], header: string) {
        date.forEach((entry) => {
            this.checkBirth(this.rowData[header]);
            this.rowData[header] = moment(
                entry.toDateString(),
                "ddd MMM DD YYYY"
            ).format("DD/MM/YYYY");
            this.props.onUpdate(this.props.index, this.rowData);
        });
    }

    @action
    private checkGender(entry: string) {
        this.genderError = "";

        if (entry !== "")
        {
            let entryUpper = entry.toUpperCase();
            if (entryUpper !== "FEMALE" && entryUpper !== "MALE")
                this.genderError = "Invalid gender.";
        }
    }

    @action
    private checkBirth(entry: string) {
        this.dobError = "";

        if (entry !== "") {
            let entryDate = moment(entry, 'DD/MM/YYYY');

            if (!entryDate.isValid()) {
                this.dobError = "Invalid birth date.";
            } else if (entryDate.isAfter(new Date())) {
                this.rowData["dateofbirth"] = "";
                this.dobError = "Birth date cannot be in the future.";
                this.props.onUpdate(this.props.index, this.rowData);
            }
        }
    }

    @action
    private checkEmail(email: string) {
        let emailExp = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

        const q = gql`
        query checkValidEmail {
            patientEntitys(where: {path: "email", comparison: equal, value:"${email.toLowerCase()}"}){
                id
                email
            }
        }` as DocumentNode;

        //check if email already exists in databases
        store.apolloClient
            .query({ query: q, fetchPolicy: "network-only" })
            .then(
                action((res: any) => {
                    if (res.data["patientEntitys"].length > 0) {
                        this.emailError = "Email already exists.";
                        this.props.icon[this.props.index] =
                            "icon-cross icon-left";
                    }
                })
            );

        //check if email is valid
        if (!emailExp.test(email)) {
            this.emailError = "Invalid email.";
        } else {
            this.emailError = "";
        }
    }

    @action
    private onClick(i: number) {
        this.clicked[i] = false;
    }

    @action
    private afterClick(i: number) {
        this.clicked[i] = true;
    }

    public render() {
        return (
            <tr key={this.props.index}>
                <td>
                    <If condition={this.emailError != ""}>
                        <div className={"icon-cross icon-left"}></div>
                    </If>
                    <If condition={this.emailError == ""}>
                        <div className={this.props.icon[this.props.index]}></div>
                    </If>
                </td>

                {this.props.keys.map((header: any, i) => {
                    if (header == "dateofbirth") {
                        return (
                            <td>
                                <DateTimePicker
                                    className={"dob-picker"}
                                    name={"dobPicker"}
                                    model={this.rowData}
                                    modelProperty={header}
                                    altInput={true}
                                    altFormat={"d/m/y"}
                                    label={this.rowData[header]}
                                    labelVisible={false}
                                    maxDate={new Date().toUTCString()}
                                    enableTime={false}
                                    onAfterChange={(event) =>
                                        this.onDateChange(event, header)
                                    }
                                />
                                <If condition={this.dobError != ""}>
                                    <label>{this.dobError}</label>
                                </If>
                            </td>
                        );
                    } else if (header == "gender") {
                        return (
                            <td>
                                <Combobox
                                    model={this.rowData}
                                    label="Gender"
                                    labelVisible={false}
                                    modelProperty="gender"
                                    options={this.genderOptions}
                                    placeholder={this.rowData[header]}
                                    isRequired={false}
                                    isClearable
                                    onChange={(event) => this.onGenderChange(event, header)}
                                />
                                <If condition={this.genderError != ""}>
                                    <label>{this.genderError}</label>
                                </If>
                            </td>
                        );
                    } else {
                        return (
                            <td>
                                <input
                                    className={"input-data"}
                                    id={"inputBox"}
                                    type="text"
                                    onBlur={() => this.afterClick(i)}
                                    onClick={() => this.onClick(i)}
                                    readOnly={this.clicked[i]}
                                    onChange={(event) =>
                                        this.onChange(event, header)
                                    }
                                    value={this.rowData[header]}
                                />

                                <If condition={header == "email"}>
                                    <label>{this.emailError}</label>
                                </If>
                            </td>
                        );
                    }
                })}
            </tr>
        );
    }
}
