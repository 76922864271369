import React, { Component } from "react";
import { gql } from "apollo-boost";
import { store } from "Models/Store";
import { observer } from "mobx-react";
import { observable, action, toJS } from "mobx";
import moment from "moment";
import FormButton from "./FormButton";
import PreviewPane from "./PreviewPane";
import {
	RatingtemplateSubmissionEntity,
	ClinicianEntity,
	CliniciansViewedratingtemplatesubmissions,
	PatientEntity,
	RatingpersonalSubmissionEntity,
	CliniciansViewedratingpersonalsubmissions,
} from "Models/Entities";
import alert from "Util/ToastifyUtils";
import GetModel from "../../../Views/Components/GetModel/GetModel";
import { EntityFormLayout } from "../EntityFormLayout/EntityFormLayout";
import { EntityFormMode, AttributeFormMode } from "../Helpers/Common";
import Popup from "reactjs-popup";
import { Button } from "../Button/Button";
import { ButtonGroup } from "../Button/ButtonGroup";
import { getModelDisplayName } from "Util/EntityUtils";
import _ from "lodash";

function queryCheckIns(userId: string) {
	return gql`
		query checkIns {
			patientEntity(id: "${userId}") {
				id
				ratingtemplatess (orderBy: {path: "ratingtemplates.name"}) {
					ratingtemplates {
                        id
                        name
                        frequency
						formVersions (orderBy: {path: "version", descending: true}) {
							version
                            formSubmissions(
                                where: { path: "owner", comparison: equal, value: "${userId}" }, 
                                orderBy: {path: "submissionDate", descending: true}
                            ) {
                                id
                                owner
								created	
                                submissionDate	
                                clinicianss{
                                    cliniciansId
                                }
							}
						}
					}
                }
                ratingss (orderBy: {path: "ratings.name"}) {
					ratings {
                        id
                        name
                        frequency
						formVersions (orderBy: {path: "version", descending: true}) {
							version
                            formSubmissions(
                                where: { path: "owner", comparison: equal, value: "${userId}" }, 
                                orderBy: {path: "submissionDate", descending: true}
                            ) {
                                id
                                owner
								created	
                                submissionDate	
                                clinicianss{
                                    cliniciansId
                                }
							}
						}
					}
                }
			}
		}
	`;
}

function querySubmissionDate(submissionType: string, submissionId: string) {
	return gql` 
		query submissionDate {
			${submissionType}(id: "${submissionId}") {
				id
				created
				submissionDate
			}
		}
	`;
}

interface IProfileCheckIn {
	userId: string;
	renderProfileTile?: () => void;
}

interface ICheckInButton {
	name: string;
	countSubmissions: number;
	submissions: string[];
	frequency: string;
	lastCreated?: Date;
	lastCreatedString: string;
	lastSubmission?: Date;
	lastSubmissionString: string;
	currentSubmission?: Date;
	currentSubmissionString?: string;
	clinicians: string[];
	type: "template" | "personal";
}

@observer
export default class ProfileCheckIn extends Component<IProfileCheckIn> {
	@observable
	private checkIns: ICheckInButton[] = [];

	@observable
	private viewPreview: boolean = false;

	@observable
	private currentForm: ICheckInButton;

	@observable
	private currentFormIndex: number = 0;

	@observable
	private page: number = 1;

	@observable
	private containerStyle = {};

	@observable
	private showModal = false;

	@observable
	private model: PatientEntity = new PatientEntity();

	@observable
	private clinician: ClinicianEntity;

	@observable
	private rating: any;


	componentDidMount() {
		this.getCheckIns();
		this.getClinician();
	}

	private async getClinician() {
		if (store.userGroups[0].name === "Clinician") {
			ClinicianEntity.fetch<ClinicianEntity>(
				{ args: [[{ path: "id", comparison: "equal", value: store.userId }]] },
				new ClinicianEntity().defaultExpands
			).then((clinician: ClinicianEntity[]) => {
				this.setClinician(clinician[0]);
			});
		}
	}

	@action
	private setClinician(clinician: ClinicianEntity) {
		this.clinician = clinician;
	}

	private async getRating() {
		if (this.currentForm.submissions[this.currentFormIndex]) {
			if (this.currentForm.type === "template") {
				RatingtemplateSubmissionEntity.fetch<RatingtemplateSubmissionEntity>(
					{ args: [[{ path: "id", comparison: "equal", value: this.currentForm.submissions[this.currentFormIndex] }]] },
					new RatingtemplateSubmissionEntity().defaultExpands
				).then((rating: RatingtemplateSubmissionEntity[]) => {
					this.setRating(rating[0]);
				});
			} else {
				console.log(this.currentForm.submissions[this.currentFormIndex]);
				RatingpersonalSubmissionEntity.fetch<RatingpersonalSubmissionEntity>(
					{ args: [[{ path: "id", comparison: "equal", value: this.currentForm.submissions[this.currentFormIndex] }]] },
					new RatingpersonalSubmissionEntity().defaultExpands
				).then((rating: RatingpersonalSubmissionEntity[]) => {
					this.setRating(rating[0]);
				});
			}
		} else {
			this.setRating(new RatingpersonalSubmissionEntity());
		}
	}

	@action
	private setRating(rating: RatingtemplateSubmissionEntity | RatingpersonalSubmissionEntity) {
		this.rating = rating;
	}

	private async getCheckIns() {
		store.apolloClient.query({ query: queryCheckIns(this.props.userId), fetchPolicy: "network-only" }).then((d) => {
			this.setCheckIns(d.data.patientEntity);
		});
	}

	@action
	private setCheckIns(data: any) {
		// Reset checkin array
		this.checkIns = [];

		data.ratingtemplatess.map((rating: any) => {
			let submissions: any[] = [];
			let lastCreated: Date | undefined;
			let lastCreatedString: string = "n/a";
			let lastSubmission: Date | undefined;
			let lastSubmissionString: string = "n/a";
			let clinicians: string[] = [];

			rating.ratingtemplates.formVersions.map((version: any, versionIndex: number) => {

				version.formSubmissions.map((submission: any, submissionIndex: number) => {
					submissions.push(submission.id);
					if (!lastSubmission) {
						lastCreated = new Date(submission.created);
						lastCreatedString = moment.utc(submission.created).local().format("LT Do MMMM YYYY");
						lastSubmission = new Date(submission.submissionDate);
						lastSubmissionString = moment.utc(submission.submissionDate).local().format("LT Do MMMM YYYY");
					}
					submission.clinicianss.forEach((clinician: any) => {
						clinicians.push(clinician.cliniciansId);
					});
				});
			});
			const frequency = rating.ratingtemplates.frequency.charAt(0) + rating.ratingtemplates.frequency.slice(1).toLowerCase();

			this.checkIns.push({
				name: rating.ratingtemplates.name,
				countSubmissions: submissions.length,
				submissions: submissions,
				frequency: frequency,
				lastCreated: lastCreated,
				lastCreatedString: lastCreatedString,
				lastSubmission: lastSubmission,
				lastSubmissionString: lastSubmissionString,
				currentSubmission: lastSubmission,
				currentSubmissionString: lastSubmissionString,
				clinicians: clinicians,
				type: "template",
			});
		});

		data.ratingss.map((rating: any) => {
			let submissions: any[] = [];
			let lastCreated: Date | undefined;
			let lastCreatedString: string = "n/a";
			let lastSubmission: Date | undefined;
			let lastSubmissionString: string = "n/a";
			let clinicians: string[] = [];

			rating.ratings.formVersions.map((version: any, versionIndex: number) => {
				version.formSubmissions.map((submission: any, submissionIndex: number) => {
					submissions.push(submission.id);
					if (!lastSubmission) {
						lastCreated = new Date(submission.created);
						lastCreatedString = moment.utc(submission.created).local().format("LT Do MMMM YYYY");
						lastSubmission = new Date(submission.submissionDate);
						lastSubmissionString = moment.utc(submission.submissionDate).local().format("LT Do MMMM YYYY");
					}
					submission.clinicianss.forEach((clinician: any) => {
						clinicians.push(clinician.cliniciansId);
					});
				});
			});
			const frequency = rating.ratings.frequency.charAt(0) + rating.ratings.frequency.slice(1).toLowerCase();

			this.checkIns.push({
				name: rating.ratings.name,
				countSubmissions: submissions.length,
				submissions: submissions,
				frequency: frequency,
				lastCreated: lastCreated,
				lastCreatedString: lastCreatedString,
				lastSubmission: lastSubmission,
				lastSubmissionString: lastSubmissionString,
				currentSubmission: lastSubmission,
				currentSubmissionString: lastSubmissionString,
				clinicians: clinicians,
				type: "personal",
			});
		});

		this.checkIns = _.orderBy(
			this.checkIns,
			(checkIn) => {
				console.log(checkIn.lastSubmission);
				return checkIn.lastSubmission ? checkIn.lastSubmission : "";
			},
			["desc"]
		);

	}

	private getPageLength(): number {
		if (this.checkIns.length <= 6) {
			return 1;
		}
		return Math.floor(this.checkIns.length / 6) + 1;
	}

	private renderCheckIns() {
		let pages = [];
		for (let page = 1; page <= this.getPageLength(); page++) {
			pages.push(page);
		}

		return (
			<div className="button-container" style={toJS(this.containerStyle)} onClickCapture={() => this.closePreview()}>
				<div className="add-button" onClick={() => this.assignTracking()}>
					Assign tracking
				</div>
				<div className="checkin-buttons">
					{this.checkIns.map((checkInButton: ICheckInButton, index: number) => {
						let showBadge;
						if (store.userGroups[0].name === "Clinician") {
							let clinicianSeen = 0;

							// clinician id should show up the same number of times as submissions if they've seen all submissions in this activity
							checkInButton.clinicians.forEach((clinician: string, i) => {
								if (clinician == store.userId) {
									clinicianSeen++;
								}
							});
							showBadge = clinicianSeen != checkInButton.countSubmissions;
						}

						if (index >= this.page * 6 - 6 && index <= this.page * 6 - 1) {
							return (
								<FormButton
									key={index}
									title={checkInButton.name}
									submissions={checkInButton.submissions}
									countSubmissions={checkInButton.countSubmissions}
									frequency={checkInButton.frequency}
									lastCreated={checkInButton.lastCreatedString}
									lastSubmission={checkInButton.lastSubmission}
									onClick={() => {
										this.setCurrentForm(checkInButton);
										this.getRating();
									}}
									showBadge={showBadge}
								/>
							);
						}
						return;
					})}
				</div>

				<div className="page-buttons">
					<div
						className={"navigate-page icon-left icon-chevron-left " + (this.page == 1 ? "navigate-page-disabled" : "")}
						onClick={() => this.previousPage()}
					/>
					<div
						className={this.page == 1 ? `active-page-button page-button` : `page-button`}
						onClick={() => {
							this.setActivePage(1);
						}}>
						{1}
					</div>

					{this.getMiddlePages(pages)}


					{pages.length !== 1 && (
						<div
							className={this.page == pages.length ? `active-page-button page-button` : `page-button`}
							onClick={() => {
								this.setActivePage(pages.length);
							}}>
							{pages.length}
						</div>
					)}


					<div
						className={"navigate-page icon-right icon-chevron-right " + (this.page == pages.length ? "navigate-page-disabled" : "")}
						onClick={() => this.nextPage(pages.length)}
					/>
				</div>
			</div>
		);
	}

	private getMiddlePages(pages: number[]) {
		if (pages.length < 4) {
			return pages.map((page) => {
				if (page !== 1 && page !== pages.length) {
					return (
						<div
							className={this.page == page ? `active-page-button page-button` : `page-button`}
							onClick={() => {
								this.setActivePage(page);
							}}>
							{page}
						</div>
					);
				}
				return;
			});
		} else {
			if (this.page !== 1 && this.page !== pages.length) {
				return (
					<>
						{this.page !== 2 ? <div className="page-dots">...</div> : <></>}
						<div
							className="active-page-button page-button"
							onClick={() => {
								this.setActivePage(this.page);
							}}>
							{this.page}
						</div>
						{this.page !== pages.length - 1 ? <div className="page-dots">...</div> : <></>}
					</>
				);
			} else {
				return <div className="page-dots">...</div>;
			}
		}
	}

	@action
	private previousPage() {
		if (this.page > 1) {
			this.page--;
		}
	}

	@action
	private nextPage(pageLength: number) {
		if (this.page < pageLength) {
			this.page++;
		}
	}

	@action
	private setActivePage(page: number) {
		this.page = page;
	}

	@action
	private setCurrentForm(form: ICheckInButton) {
		this.currentFormIndex = 0;
		this.currentForm = form;
		this.viewPreview = true;
		if (window.innerWidth < 800) {
			this.containerStyle = { display: "none" };
		}
	}

	private renderPreview() {
		if (this.viewPreview) {
			if (this.currentForm && this.rating) {
				this.getSubmissionAndCreationDate();
				const { name, countSubmissions, lastCreated, lastCreatedString, lastSubmission, lastSubmissionString, currentSubmission } = this.currentForm;

				if (store.userGroups[0].name === "Clinician") {
					if (countSubmissions > 0) {
						this.addClinicianToCheckin();
					}
				}

				const childContent =
					countSubmissions > 0 ? (
						<EntityFormLayout
							{...this.props}
							model={this.rating}
							key={this.rating.id}
							getErrorsForAttribute={this.rating.getErrorsForAttribute}
							formMode={EntityFormMode.VIEW}
							attributeBehaviours={[
								{ name: "modified", behaviour: AttributeFormMode.HIDE },
								{ name: "created", behaviour: AttributeFormMode.HIDE },
								{ name: "clinicianss", behaviour: AttributeFormMode.HIDE },
								{ name: "submissionDate", behaviour: AttributeFormMode.HIDE },
							]}
						/>
					) : (
						`This ${getModelDisplayName(PatientEntity)} has no submissions for this checkin`
					);

				return (
					<PreviewPane
						name={name}
						createdDate={lastCreated}
						submittedDate={currentSubmission}
						created={lastCreatedString}
						submitted={lastSubmissionString}
						countForms={countSubmissions}
						currentFormIndex={this.currentFormIndex}
						goal={false}
						previous={() => {
							this.navigateForms("previous");
						}}
						next={() => {
							this.navigateForms("next");
						}}
						close={() => {
							this.closePreview();
						}}>
						{childContent}
					</PreviewPane>
				);
			}
		}
		return;
	}

	@action
	private navigateForms(action: "previous" | "next") {
		switch (action) {
			case "previous":
				if (this.currentFormIndex > 0) {
					this.currentFormIndex--;
				}

				break;
			case "next":
				if (this.currentFormIndex < this.currentForm.countSubmissions - 1) {
					this.currentFormIndex++;
				}
				break;
		}

		this.getRating();
		this.getSubmissionAndCreationDate();
	}

	private async addClinicianToCheckin() {
		let clinicianAlreadySeen = false;

		this.rating.clinicianss.forEach((clinicianViewed: { cliniciansId: string }) => {
			if (clinicianViewed.cliniciansId == this.clinician.id) {
				clinicianAlreadySeen = true;
			}
		});

		if (!clinicianAlreadySeen) {
			let clinicianViewedSubmission;
			if (this.currentForm.type === "template") {
				clinicianViewedSubmission = new CliniciansViewedratingtemplatesubmissions({ clinicians: this.clinician });
			} else {
				clinicianViewedSubmission = new CliniciansViewedratingpersonalsubmissions({ clinicians: this.clinician });
			}
			this.rating.clinicianss.push(clinicianViewedSubmission);
			await this.rating.saveFromCrud(EntityFormMode.EDIT);

			this.getCheckIns();
			if (this.props.renderProfileTile) this.props.renderProfileTile();
		}
	}

	@action
	private closePreview() {
		this.viewPreview = false;
		this.containerStyle = {};
	}

	private async getSubmissionAndCreationDate() {
		if (this.currentForm.type === "template") {
			store.apolloClient
				.query({ query: querySubmissionDate("ratingtemplateSubmissionEntity", this.currentForm.submissions[this.currentFormIndex]) })
				.then((d) => {
					if (d.data.ratingtemplateSubmissionEntity.submissionDate) {
						this.setSubmissionDate(d.data.ratingtemplateSubmissionEntity.submissionDate);
						this.setCreationDate(d.data.ratingtemplateSubmissionEntity.created);
					}
				});
		} else {
			store.apolloClient
				.query({ query: querySubmissionDate("ratingpersonalSubmissionEntity", this.currentForm.submissions[this.currentFormIndex]) })
				.then((d) => {
					if (d.data.ratingpersonalSubmissionEntity.submissionDate) {
						this.setSubmissionDate(d.data.ratingpersonalSubmissionEntity.submissionDate);
						this.setCreationDate(d.data.ratingpersonalSubmissionEntity.created);
					}
				});
		}
	}

	
	@action
	private setSubmissionDate(submissionDate: any) {
		this.currentForm.currentSubmission = submissionDate;
		this.currentForm.lastSubmissionString = moment.utc(submissionDate).format("LT Do MMMM YYYY");
	}

	@action
	private setCreationDate(creationDate: any) {
		this.currentForm.lastCreated = creationDate;
		this.currentForm.lastCreatedString = moment.utc(creationDate).local().format("LT Do MMMM YYYY");
	}

	@action
	private assignTracking() {
		this.showModal = true;
	}

	@action
	private closePopup() {
		this.showModal = false;
	}

	private renderAdd() {
		return (
			<Popup className="patient-ratings" modal open={this.showModal} closeOnDocumentClick={false} closeOnEscape={false}>
				<div className="rating-modal">
					<GetModel modelType={PatientEntity} id={this.props.userId}>
						{(model: PatientEntity, modelName: string) => (
							(this.model = model),
							(
								<EntityFormLayout
									{...this.props}
									model={model}
									getErrorsForAttribute={model.getErrorsForAttribute}
									formMode={EntityFormMode.EDIT}
									attributeBehaviours={[
										{ name: 'onboarded', behaviour: AttributeFormMode.HIDE },
										{ name: 'activatedDate', behaviour: AttributeFormMode.HIDE },
										{ name: "created", behaviour: AttributeFormMode.HIDE },
										{ name: "email", behaviour: AttributeFormMode.HIDE },
										{ name: "acceptedPrivacyPolicy", behaviour: AttributeFormMode.HIDE },
										{ name: "modified", behaviour: AttributeFormMode.HIDE },
										{ name: "sessionss", behaviour: AttributeFormMode.HIDE },
										{ name: "forename", behaviour: AttributeFormMode.HIDE },
										{ name: "surname", behaviour: AttributeFormMode.HIDE },
										{ name: "dateOfBirth", behaviour: AttributeFormMode.HIDE },
										{ name: "goalss", behaviour: AttributeFormMode.HIDE },
										{ name: "clinicianss", behaviour: AttributeFormMode.HIDE },
										{ name: "carerss", behaviour: AttributeFormMode.HIDE },
										{ name: "tagss", behaviour: AttributeFormMode.HIDE },
										{ name: "gender", behaviour: AttributeFormMode.HIDE },
										{ name: "middlename", behaviour: AttributeFormMode.HIDE },
										{ name: "phone", behaviour: AttributeFormMode.HIDE },
										{ name: "work", behaviour: AttributeFormMode.HIDE },
										{ name: "financial", behaviour: AttributeFormMode.HIDE },
										{ name: "social", behaviour: AttributeFormMode.HIDE },
										{ name: "family", behaviour: AttributeFormMode.HIDE },
										{ name: "psychological", behaviour: AttributeFormMode.HIDE },
										{ name: "relationships", behaviour: AttributeFormMode.HIDE },
										{ name: "spiritual", behaviour: AttributeFormMode.HIDE },
										{ name: "physical", behaviour: AttributeFormMode.HIDE },
										{ name: "todoss", behaviour: AttributeFormMode.HIDE },
										{ name: "favouriteactivitiess", behaviour: AttributeFormMode.HIDE },
										{ name: "favouritearticless", behaviour: AttributeFormMode.HIDE },
										{ name: "status", behaviour: AttributeFormMode.HIDE },
										{ name: "notificationsSettingsId", behaviour: AttributeFormMode.HIDE },
										{ name: "patientSocialMediaLinkss", behaviour: AttributeFormMode.HIDE },
										{ name: "customFields", behaviour: AttributeFormMode.HIDE },
										{ name: "lastLogin", behaviour: AttributeFormMode.HIDE },
									]}
								/>
							)
						)}
					</GetModel>
					<ButtonGroup className="rating-buttons">
						<Button
							className="btn--outline"
							onClick={() => {
								this.closePopup();
							}}>
							Cancel
						</Button>
						<Button
							className="btn--solid"
							onClick={() => {
								this.onSave();
							}}>
							Save
						</Button>
					</ButtonGroup>
				</div>
			</Popup>
		);
	}

	private onSave() {
		this.model
			.validate()
			.then(() => {
				this.model
					.saveFromCrud(EntityFormMode.EDIT)
					.then(() => {
						alert(`Successfully updated Patient`, "success");
						this.closePopup();
						this.getCheckIns();
					})
					.catch(() => {
						alert(`An error has occured updating Patient`, "error");
					});
			})
			.catch(() => {
				alert(`Patient invalid`, "error");
			});
	}

	render() {
		return (
			<div className="profile-checkin-page">
				{this.renderCheckIns()}
				{this.renderPreview()}
				{this.renderAdd()}
			</div>
		);
	}
}
