import { FetchPolicy, gql } from "apollo-boost";
import { frequency } from "Models/Enums";
import { store } from "Models/Store";
import moment from "moment";
import { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { IForm } from "../Views/Components/PatientCheckIn/CheckInTile";
import { prettyLog } from '../Util/StringUtils';

export interface IFormsAll {
	incomplete: IForm[];
	complete: IForm[];
}

interface IQueryOption {
	frequency: frequency;
	type: ratingType;
	startDate?: string;
	period?: any;
	stepSize?: any;
	expectedSize?: number;
}

type ratingType = "ratingtemplates" | "ratings";

function queryCheckIn(
	patientId: string,
	frequency: frequency,
	type: ratingType,
	startDate?: string
) {
	return gql`
		query patient {
			patientEntity(id: "${patientId}") {
				id
				${type}s(where: { path: "${type}.frequency", comparison: equal, value: "${frequency}" }) {
					created
					${type} {
						id
						name
						frequency
						description
        				featureImageId
						publishedVersion {
							id
							formSubmissions(
								where: [
	                                    ${startDate ? `{ path: "submissionDate", comparison: greaterThanOrEqual, value: "${startDate}" }` : ""}
										{ path: "owner", comparison: equal, value: "${patientId}" }
								]
								orderBy: { path: "submissionDate", descending: true }
							) {
								id
								owner
								submissionDate
								created
							}
						}
						formVersions {
				            id
				            formSubmissions(
								where: [
                                    ${startDate ? `{ path: "submissionDate", comparison: greaterThanOrEqual, value: "${startDate}" }` : ""}
                                    { path: "owner", comparison: equal, value: "${patientId}" }
                                ]
                                orderBy: { path: "submissionDate", descending: true }
                            ) {
				                id
				                owner
				                submissionDate
								created
				            }
                        }
					}
				}
			}
		}
	`;
}

// YHPSD-94
// Get the date the user was activated/first login.
function getActivatedDate(userId : string) {
	return gql`
		query getActivatedDate {
			patientEntitys(where: [{path: "id", comparison: equal, value: "${userId}"}]) {
				activatedDate
			}
		}
	`;
}

export function useForms(): { loading: boolean, forms: IFormsAll | undefined } {
	const [forms, setForms] = useState<IFormsAll | undefined>(undefined);
	const [loading, setLoading] = useState(true);

	const location: any = useLocation();

	let fetchPolicy: FetchPolicy = "cache-first";
	if (location.state) {
		if (location.state.reFetch) fetchPolicy = "network-only";
	}

	useEffect(() => {
		getForms();
	}, []);

	async function getForms() {
		let forms: IFormsAll = { incomplete: [], complete: [] };

		await Promise.all(
			queryOptions.map(async (option: IQueryOption) => {
				let query = await store.apolloClient.query({
					query: queryCheckIn(
						store.userId!,
						option.frequency,
						option.type,
						option.startDate
					),
					fetchPolicy: fetchPolicy,
				});

				let activatedDateQuery = await store.apolloClient.query({
					query: getActivatedDate(
						store.userId!
					),
					fetchPolicy: fetchPolicy,
				});
				const activatedDate = new Date(activatedDateQuery.data.patientEntitys[0]["activatedDate"]);

				const typeConnection = option.type + "s";
				const ratingForms = query.data.patientEntity[typeConnection];
				if (ratingForms.length > 0) {

					ratingForms.map((rating: any) => {
						const dateRatingIsAssignedToPatient = new Date(rating.created);
						const formPublishedVersion = rating[option.type].publishedVersion;
						const formAllVersion = rating[option.type].formVersions;
						if (formPublishedVersion) {
							const { id, frequency, name, description, featureImageId } = rating[option.type];

							// At least one submissions exists. See if a submission exists for all dates.
							const formSubmissions = formAllVersion.flatMap((formVersion: any) => formVersion.formSubmissions);
							if (formSubmissions.length > 0) {

								// If the form is a one-off form with a submission, then it will be completed
								if (frequency === "ONEOFF") {
									forms.complete.push({
										id: id,
										frequency: frequency,
										name: name,
										description: description,
										featureImageId: featureImageId,
									});
								} else {
									// Get the missing submission dates for the form
									const missingDates = getMissingDates(
										rating,
										option.type,
										option.expectedSize!,
										option.stepSize!,
										option.period!,
										activatedDate,
										dateRatingIsAssignedToPatient
									);

									// If there is at least one missing date, add the form and it's dates to the incomplete array
									if (missingDates.length > 0) {
										forms.incomplete.push({
											id: id,
											frequency: frequency,
											name: name,
											description: description,
											featureImageId: featureImageId,
											missingDates: missingDates,
										});
									// If there is no missing submissions, add the form to the complete array
									} else {
										forms.complete.push({
											id: id,
											frequency: frequency,
											name: name,
											description: description,
											featureImageId: featureImageId,
										});
									}
								}
							// No submissions exist, add all of the necessary dates and submissions to the incomplete array
							} else {
								// If the form is a one-off form with no submissions, then it will be incomplete
								if (frequency === "ONEOFF") {
									forms.incomplete.push({
										id: id,
										frequency: frequency,
										name: name,
										description: description,
										featureImageId: featureImageId,
										missingDates: [moment().utc().format("YYYY-MM-DD LT")],
									});
								// Get all of the missing dates and add them to the incomplete form
								} else {
									const missingDates = getMissingDates(
										rating,
										option.type,
										option.expectedSize!,
										option.stepSize!,
										option.period!,
										activatedDate,
										dateRatingIsAssignedToPatient
									);

									forms.incomplete.push({
										id: id,
										frequency: frequency,
										name: name,
										description: description,
										featureImageId: featureImageId,
										missingDates: missingDates,
									});
								}
							}
						}
					});
				}
			})
		);

		setForms(forms);
		setLoading(false);
	}

	return { loading, forms };
}

// Gets the missing dates for a given rating
// Rating: the rating we would like to retrieve missing dates for
// Expected size: how many submissions we expect to see
// Step size: the gap in between periods, e.g. fortnight = 2 weeks
// Period: the period in between form submissions (day, week, month, etc)
export function getMissingDates(
	rating: any,
	type: ratingType,
	expectedSize: number,
	stepSize: number,
	period: any,
	activatedDate: Date,
	assignedDate?: Date
) {
	// Declare array to store the list of missing dates
	let missingDates = [];

	const formAllVersion = rating[type].formVersions;
	const formSubmissions = formAllVersion.flatMap((formVersion: any) => formVersion.formSubmissions);

	// Iterate over each expected day and check if there exists a submission between the start and end date for the given period
	for (let subtractPeriod = 0; subtractPeriod < expectedSize; subtractPeriod++) {
		const submissionExists = formSubmissions.find((submission: any) => {

			if (Date.parse(submission.submissionDate) >= Date.parse(moment.utc(activatedDate).format('YYYY-MM-DD'))) {
					let startDate = moment.utc()
						.subtract((subtractPeriod + 1) * stepSize, period).local()
						.format("YYYY-MM-DD");

					let endDate = moment.utc()
						.subtract(subtractPeriod, period).local()
						.format("YYYY-MM-DD");

					let submissionDate = moment.utc(submission.submissionDate).format("YYYY-MM-DD");
					let dateBetween = moment(submissionDate).isBetween(startDate, endDate, "day", "(]");
					return dateBetween;
				}
				return null;
			}
		);

		// If a submission does not exist for this period, add the missing date to the incomplete form
		if (!submissionExists) {
			const missingDate = moment.utc().subtract(subtractPeriod, period);
			if (assignedDate == null || (moment(missingDate).isAfter(assignedDate))) {
				missingDates.push(missingDate.format("YYYY-MM-DD"));
			}
		}
	}

	return missingDates;
}

const queryOptions: IQueryOption[] = [
	{ frequency: "ONEOFF", type: "ratingtemplates" },
	{ frequency: "ONEOFF", type: "ratings" },
	{
		frequency: "DAILY",
		type: "ratingtemplates",
		startDate: moment.utc().subtract(4, "day").format("YYYY-MM-DD"),
		period: "day",
		stepSize: 1,
		expectedSize: 4,
	},
	{
		frequency: "DAILY",
		type: "ratings",
		startDate: moment.utc().subtract(4, "day").format("YYYY-MM-DD"),
		period: "day",
		stepSize: 1,
		expectedSize: 4,
	},
	{
		frequency: "WEEKLY",
		type: "ratingtemplates",
		startDate: moment.utc().subtract(2, "week").format("YYYY-MM-DD"),
		period: "week",
		stepSize: 1,
		expectedSize: 2,
	},
	{
		frequency: "WEEKLY",
		type: "ratings",
		startDate: moment.utc().subtract(2, "week").format("YYYY-MM-DD"),
		period: "week",
		stepSize: 1,
		expectedSize: 2,
	},
	{
		frequency: "FORTNIGHTLY",
		type: "ratingtemplates",
		startDate: moment.utc().subtract(4, "week").format("YYYY-MM-DD"),
		period: "week",
		stepSize: 2,
		expectedSize: 2,
	},
	{
		frequency: "FORTNIGHTLY",
		type: "ratings",
		startDate: moment.utc().subtract(4, "week").format("YYYY-MM-DD"),
		period: "week",
		stepSize: 2,
		expectedSize: 2,
	},
	{
		frequency: "MONTHLY",
		type: "ratingtemplates",
		startDate: moment.utc().subtract(2, "month").format("YYYY-MM-DD"),
		period: "month",
		stepSize: 1,
		expectedSize: 2,
	},
	{
		frequency: "MONTHLY",
		type: "ratings",
		startDate: moment.utc().subtract(2, "month").format("YYYY-MM-DD"),
		period: "month",
		stepSize: 1,
		expectedSize: 2,
	},
	{
		frequency: "QUARTERLY",
		type: "ratingtemplates",
		startDate: moment.utc().subtract(6, "month").format("YYYY-MM-DD"),
		period: "month",
		stepSize: 3,
		expectedSize: 2,
	},
	{
		frequency: "QUARTERLY",
		type: "ratings",
		startDate: moment.utc().subtract(6, "month").format("YYYY-MM-DD"),
		period: "month",
		stepSize: 3,
		expectedSize: 2,
	},
	{
		frequency: "BIANNUALLY",
		type: "ratingtemplates",
		startDate: moment.utc().subtract(12, "month").format("YYYY-MM-DD"),
		period: "month",
		stepSize: 6,
		expectedSize: 2,
	},
	{
		frequency: "BIANNUALLY",
		type: "ratings",
		startDate: moment.utc().subtract(12, "month").format("YYYY-MM-DD"),
		period: "month",
		stepSize: 6,
		expectedSize: 2,
	},
];
