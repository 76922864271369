import React, { Component } from 'react';
import If from '../If/If';
import { observable, action, toJS } from 'mobx';
import { observer } from 'mobx-react';
import { TodoEntity } from 'Models/Entities';
import { todotype } from 'Models/Enums';
import moment from 'moment';
import { store } from 'Models/Store';
import { ReactComponent as TickComplete } from "./TickComplete.svg";
import { ReactComponent as TickIncomplete } from "./TickIncomplete.svg";

interface toDoProps {
	id: string;
	title: string;
	description?: string;
	complete: boolean;
	duedate: Date;
	type: todotype;
	typeid: string;
	owner: string;
	expired: boolean;
	dueString?: string;
	featureId?: string;
	patientId?: string;
	onClickOpen?: () => void;
	onClickComplete: () => void;
}

@observer
export default class ToDo extends Component<toDoProps> {
	@observable
	private completeTick: any = this.props.complete ? <TickComplete /> : <TickIncomplete />;

	@observable
	private toDoTask = {
		id: this.props.id,
		title: this.props.title,
		description: this.props.description,
		complete: this.props.complete ? this.props.complete : false,
		duedate: this.props.duedate ? moment(this.props.duedate).toDate() : undefined,
		type: this.props.type,
		typeid: this.props.typeid,
		patientId: this.props.patientId,
	};

	@observable
	private animateComplete = {
		opacity: 1,
		transition: "opacity 1s",
	};

	@action
	private completeToDo = (e: any) => {
		e.preventDefault();
		e.stopPropagation();

		this.toDoTask.complete = !this.toDoTask.complete;
		this.completeTick = this.toDoTask.complete ? <TickComplete /> : <TickIncomplete />;

		this.animateComplete = {
			opacity: 0,
			transition: "opacity 1s",
		};

		const saveToDo = new TodoEntity(this.toDoTask);
		saveToDo.save().then(() => {
			setTimeout(() => this.props.onClickComplete(), 1000);
		});
	};

	render() {
		const { title, dueString, expired, type } = this.props;

		let path: string | undefined;
		switch (this.toDoTask.type) {
			case "ACTIVITY":
				path = `activity?id=${this.toDoTask.typeid}`;
				break;
			case "ARTICLE":
				path = `article?id=${this.toDoTask.typeid}`;
				break;
			case "CUSTOM":
				path = undefined;
				break;
		}

		return (
			<div
				className={expired ? "todo-item todo-item-expired " : "todo-item"}
				onClick={this.props.onClickOpen}
				style={toJS(this.animateComplete)}>

				<div className="todo-complete" onClick={(e) => this.completeToDo(e)}>{this.completeTick}</div>
				<div className="todo-info">
					<div className="todo-text">{title}</div>
					<If condition={dueString !== undefined}>
						<div className="todo-due icon-left icon-calender">
							<span>{dueString}</span>
						</div>
					</If>
				</div>
				<If condition={type !== "CUSTOM"}>
					<div
						className="todo-link icon-right icon-link-2"
						onClick={() => store.routerHistory.push(path!, store.routerHistory.location.pathname)}
					/>
				</If>
			</div>
		);
	}
}
