import React, { Component } from 'react';
import { SERVER_URL } from 'Constants';
import { Link } from 'react-router-dom';
import If from '../If/If';
import { toJS } from 'mobx';

export interface ArticleButtonProps {
	article: any;
	book: string;
	editing: boolean;
}

export default class AdminArticleButton extends Component<ArticleButtonProps> {
	render() {
		return (
			<>
				<If condition={this.props.editing}>
					<Link
						className="tile"
						style={linkStyle}
						to={{ pathname: '/admin/libraryeditor', state: { article: toJS(this.props.article), book: this.props.book } }}>
						<img style={imageStyle} src={`${SERVER_URL}/api/files/${this.props.article.featureId}`} />
						<div className="details">
							<span className="title">{this.props.article.title}</span>
						</div>
					</Link>
				</If>

				<If condition={!this.props.editing}>
					<Link className="tile" style={linkStyle} to={`/article/${this.props.article.id}`}>
						<img style={imageStyle} src={`${SERVER_URL}/api/files/${this.props.article.featureId}`} />
						<div className="details">
							<span className="title">{this.props.article.title}</span>
						</div>
					</Link>
				</If>
			</>
		);
	}
}

const linkStyle = {
	textDecoration: 'none',
	borderBottom: 'none',
};

const imageStyle = {
	maxHeight: '180px',
};
