import React, { Component } from 'react';
import ActivityButton from './ActivityButton';
import { store } from 'Models/Store';

export interface BooksProps {
	id: string;
	title: string;
	activities: any[];
	max?: boolean;
	filter?: string;
}

export default class ActivityBookContainer extends Component<BooksProps> {
	render() {
		let activities: any = [];
		if (this.props.activities) {
			this.props.activities.map((activity: any) => {
				// Only render filtered books
				if (!this.props.filter) {
					activities.push(<ActivityButton key={activity.id} id={activity.id} name={activity.name} featureId={activity.featureId} />);
				} else {
					if (activity.name.toLowerCase().includes(this.props.filter.toLowerCase())) {
						activities.push(<ActivityButton key={activity.id} id={activity.id} name={activity.name} featureId={activity.featureId} />);
					}
				}
			});
			if (this.props.max) {
				activities.push(
					<div className="view-all-button" onClick={() => store.routerHistory.push('/activities/book?id=' + this.props.id)}>
						<div className="view-all-text">View All</div>
					</div>
				);
			}
		}

		let title = activities.length > 0 ? <h4>{this.props.title}</h4> : <></>;

		return (
			<>
				<h4>{title}</h4>
				<div className="all-articles-container">{activities}</div>
			</>
		);
	}
}
