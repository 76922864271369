import React from "react";
import { GoalStepEntity } from "Models/Entities";
import { Button, Sizes } from "../Button/Button";
import { Checkbox } from '../Checkbox/Checkbox';
import { DisplayType } from "../Models/Enums";
import { DatePicker } from "../DatePicker/DatePicker";
import moment from "moment";
import { observer } from "mobx-react";
import { observable } from "mobx";
import { TextField } from '../TextBox/TextBox';
import smartlookClient from "smartlook-client";
import classNames from "classnames";

interface IGoalStepRowProps {
	displayCheckbox?: boolean;
	step: GoalStepEntity;
	onChangeStep: (step: GoalStepEntity) => void;
	onRemoveStep: (step: GoalStepEntity) => void;
}

@observer
export default class GoalStepRow extends React.Component<IGoalStepRowProps> {

	static defaultProps = {
		displayCheckbox: true
	}

	@observable
	private step: GoalStepEntity;

	constructor(props: IGoalStepRowProps) {
		super(props);
		this.step = this.props.step;
	}

	render() {
		var strikeThrough = this.step.completed ? 'strikethrough' : '';
		return (
			<div className={classNames("goals-step-row", strikeThrough)}>
				{this.props.displayCheckbox && (
					<div className={"goals-step-row__checkbox"}>
						<Checkbox
							onAfterChecked={this.handleAfterCheck}
							className="override-checkbox-step-completed"
							labelVisible={false}
							modelProperty="completed"
							model={this.step}

						/>
					</div>
				)}
				<div className="goals-step-row__content">
					<div className="step-content-container">
						<TextField
							model={this.step}
							modelProperty="name"
							placeholder="Step Name"
							className="goal-step-name"
							onAfterChange={() => {
								this.props.onChangeStep(this.step);
							}}
						/>
						<DatePicker
							className="override-date-duedate"
							model={this.step}
							modelProperty="dueDate"
							placeholder="Enter a due date (optional)"
							displayType={DisplayType.INLINE}
							flatpickrOptions={{
								minDate: moment().startOf("d").toDate(),
								dateFormat: "d/m/y",
							}}
							onAfterChange={() => {
								this.props.onChangeStep(this.step);
							}}
						/>
					</div>
				</div>
				<div>
					<Button
						sizes={Sizes.Medium}
						className="goals-step-row__date icon-right icon-bin-delete"
						onClick={() => this.props.onRemoveStep(this.step)}
					/>
				</div>
			</div>
		)
	}

	handleAfterCheck = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
		smartlookClient.track('Goals_Complete_Step', {});
		this.step.completed = checked;
		this.props.onChangeStep(this.step);
	};

}
