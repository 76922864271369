import React, { useEffect, useState } from "react";
import HeaderUpdate from "../Header/HeaderUpdate";
import { CarerEntity, ClinicianEntity, PatientEntity } from "Models/Entities";
import Summary from "./Summary";
import Associations from "./Associations";
import CustomFields from "./CustomFields";
import { store } from "Models/Store";
import SocialMediaLinks from "./SocialMediaLinks";
import Spinner from "../Spinner/Spinner";
import { PatientExpands } from '../../../Services/PatientService/PatientEntityExpands';

interface IMyProfileTile {
	displayHeader: boolean;
	userId: string;
	userGroup: string;
	onSave?: () => void;
}

interface IMyProfileState {
	user: PatientEntity | ClinicianEntity | CarerEntity | undefined;
}

export default function MyProfileTile(props: IMyProfileTile) {
	const [state, setState] = useState<IMyProfileState>({ user: undefined });

	useEffect(() => {
		getUserInformation();
	}, []);

	function getUserInformation() {
		switch (props.userGroup) {
			case "Patient":
				PatientEntity.fetch<PatientEntity>(
					{ args: [[{ path: "id", comparison: "equal", value: props.userId }]] },
					PatientExpands.patientProfile,
					{},
					true
				).then((d) => setState({ ...state, user: d[0] }));
				break;
			case "Clinician":
				ClinicianEntity.fetch<ClinicianEntity>(
					{ args: [[{ path: "id", comparison: "equal", value: props.userId }]] },
				).then((d) => setState({ ...state, user: d[0] }));
				break;
			case "Carer":
				CarerEntity.fetch<CarerEntity>(
					{ args: [[{ path: "id", comparison: "equal", value: props.userId }]] },
				).then((d) => setState({ ...state, user: d[0] }));
				break;
		}
	}

	if (!state.user) {
		return <Spinner />;
	}

	return (
		<div className="my-profile-page">
			{props.displayHeader ? <HeaderUpdate title="My Profile" displayBack={false} /> : <></>}
			<div className="profile-cards">
				<Summary user={state.user} userGroup={props.userGroup} refetch={() => getUserInformation()} />
				<div className="container-sub-content">
					<Associations user={state.user} userGroup={props.userGroup} />
					{store.userGroups[0].name !== "Patient" && (
						<CustomFields user={state.user} userGroup={props.userGroup.toLowerCase()} />
					)}
					{props.userGroup === "Patient" && (
						<SocialMediaLinks user={state.user} refetch={() => getUserInformation()} />
					)}
				</div>
			</div>
		</div>
	)
}
