import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { TextField } from 'Views/Components/TextBox/TextBox';
import { QuestionType, ValidatorType } from 'Forms/Schema/Question';
import { IQuestionTile, QuestionTileOptionsProps } from '../QuestionTile';
import { hasRequiredValidator } from '../../Validators/ValidationUtils';
import TileOptions from '../TileOptions';
import CompareText from 'Forms/Conditions/CompareText';
import { ValidateText } from 'Forms/Validators/ValidateText';
import { QuestionComponent, QuestionComponentProps } from 'Forms/Questions/QuestionComponent';
// % protected region % [Add any further imports here] on begin
import * as Models from 'Models/Entities';
import FileUpload from 'Views/Components/FileUpload/FileUpload';
import { action, observable } from 'mobx';
import { FileUploadPreview, UploadPreview } from 'Views/Components/FileUpload/UploadPreview';
import { SERVER_URL } from 'Constants';
import { Button } from 'Views/Components/Button/Button';
// % protected region % [Add any further imports here] end

@observer
export class ImageQuestionTileOptions extends Component<QuestionTileOptionsProps> {

	@observable
	private imageEntity = new Models.LibraryimagesEntity();

	private onSave = () => {
        this.imageEntity.save(undefined, {contentType: 'multipart/form-data'})
            .then(this.onAfterSave);
	}
	
	@action
    private onAfterSave = () => {
        const { question } = this.props;
        
        if (!question.options) {
            question.options = {};
        }

        question.options.image = this.imageEntity.libraryimagesId;
        this.imageEntity = new Models.LibraryimagesEntity();
    }


	// % protected region % [Add extra options class properties here] end

	public render() {
		// % protected region % [Customize options render here] on begin
		const { question, schema } = this.props;

		return (
            <>
			    <TileOptions question={question} schema={schema} hasTooltip />
                <FileUpload model={this.imageEntity} modelProperty="libraryimages" imageUpload preview/>
				<Button onClick={this.onSave}>Save</Button>
            </>
		);
		// % protected region % [Customize options render here] end
    }
}

export interface IImageQuestionTileProps<T> extends QuestionComponentProps<T> {
    // % protected region % [Add extra props here] off begin
	// % protected region % [Add extra props here] end
}

@observer
export class ImageQuestionTile<T> extends QuestionComponent<T, IImageQuestionTileProps<T>> implements IQuestionTile {
    // % protected region % [Customize static form vars here] off beginS

	static displayName = 'Image';

	static questionType: QuestionType = 'image';

	static optionsMenu = ImageQuestionTileOptions;

	static compareFunction = CompareText;

	static validateFunction = ValidateText;

	static validatorOptions: { display: string, value: ValidatorType }[] = [
	];

	static stylingOptions = undefined;
	// % protected region % [Customize static form vars here] end

	// % protected region % [Add extra class properties here] off begin
	// % protected region % [Add extra class properties here] end

	public render() {

		// % protected region % [Customize render here] on begin
		const { title, model, id, isReadOnly, validators, toolTip, className, checkValidation, maxWidth} = this.props;

		return (
            <>
            <p>{this.props.title}</p>
            <div style={{display: 'flex', justifyContent: 'center'}}>
               
			{this.props.options?.image 
								? <img src={`${SERVER_URL}/api/files/${this.props.options.image }`} />
								: 'No Image Selected'}
            </div>
            </>
        
		);
		// % protected region % [Customize render here] end
	}
}

// % protected region % [Add extra methods here] off begin
// % protected region % [Add extra methods here] end