/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */

export type frequency =
	// % protected region % [Override frequency keys here] off begin
	'ONEOFF' |
		'DAILY' |
		'WEEKLY' |
		'FORTNIGHTLY' |
		'MONTHLY' |
		'QUARTERLY' |
		'BIANNUALLY';
	// % protected region % [Override frequency keys here] end

export const frequencyOptions: { [key in frequency]: string } = {
	// % protected region % [Override frequency display fields here] off begin
	ONEOFF: 'OneOff',
	DAILY: 'Daily',
	WEEKLY: 'Weekly',
	FORTNIGHTLY: 'Fortnightly',
	MONTHLY: 'Monthly',
	QUARTERLY: 'Quarterly',
	BIANNUALLY: 'Biannually',
	// % protected region % [Override frequency display fields here] end
};

export type gender =
	// % protected region % [Override gender keys here] off begin
	'MALE' |
		'FEMALE' |
		'OTHER';
	// % protected region % [Override gender keys here] end

export const genderOptions: { [key in gender]: string } = {
	// % protected region % [Override gender display fields here] off begin
	MALE: 'Male',
	FEMALE: 'Female',
	OTHER: 'Other',
	// % protected region % [Override gender display fields here] end
};

export type goalType =
	// % protected region % [Override goalType keys here] off begin
	'BASEGOAL' |
		'SHORTTERM' |
		'LONGTERM' |
		'BUSINESS';
	// % protected region % [Override goalType keys here] end

export const goalTypeOptions: { [key in goalType]: string } = {
	// % protected region % [Override goalType display fields here] off begin
	BASEGOAL: 'BaseGoal',
	SHORTTERM: 'ShortTerm',
	LONGTERM: 'LongTerm',
	BUSINESS: 'Business',
	// % protected region % [Override goalType display fields here] end
};

export type levelOfImportance =
	// % protected region % [Override levelOfImportance keys here] off begin
	'CRITICAL' |
		'MAJOR' |
		'MODERATE' |
		'LOW';
	// % protected region % [Override levelOfImportance keys here] end

export const levelOfImportanceOptions: { [key in levelOfImportance]: string } = {
	// % protected region % [Override levelOfImportance display fields here] off begin
	CRITICAL: 'Critical',
	MAJOR: 'Major',
	MODERATE: 'Moderate',
	LOW: 'Low',
	// % protected region % [Override levelOfImportance display fields here] end
};

export type lifeArea =
	// % protected region % [Override lifeArea keys here] off begin
	'WORK' |
		'FINANCIAL' |
		'SOCIAL' |
		'RELATIONSHIPS' |
		'SPIRITUAL' |
		'FAMILY' |
		'PSYCHOLOGICAL' |
		'PHYSICAL';
	// % protected region % [Override lifeArea keys here] end

export const lifeAreaOptions: { [key in lifeArea]: string } = {
	// % protected region % [Override lifeArea display fields here] off begin
	WORK: 'Work',
	FINANCIAL: 'Financial',
	SOCIAL: 'Social',
	RELATIONSHIPS: 'Relationships',
	SPIRITUAL: 'Spiritual',
	FAMILY: 'Family',
	PSYCHOLOGICAL: 'Psychological',
	PHYSICAL: 'Physical',
	// % protected region % [Override lifeArea display fields here] end
};

export type profileFieldType =
	// % protected region % [Override profileFieldType keys here] off begin
	'STRING_TYPE' |
		'NUMBER_TYPE' |
		'DATE_TYPE';
	// % protected region % [Override profileFieldType keys here] end

export const profileFieldTypeOptions: { [key in profileFieldType]: string } = {
	// % protected region % [Override profileFieldType display fields here] off begin
	STRING_TYPE: 'String Type',
	NUMBER_TYPE: 'Number Type',
	DATE_TYPE: 'Date Type',
	// % protected region % [Override profileFieldType display fields here] end
};

export type status =
	// % protected region % [Override status keys here] off begin
	'ACTIVE' |
		'TRACKING' |
		'CLOSED';
	// % protected region % [Override status keys here] end

export const statusOptions: { [key in status]: string } = {
	// % protected region % [Override status display fields here] off begin
	ACTIVE: 'Active',
	TRACKING: 'Tracking',
	CLOSED: 'Closed',
	// % protected region % [Override status display fields here] end
};

export type todotype =
	// % protected region % [Override todotype keys here] off begin
	'ACTIVITY' |
		'ARTICLE' |
		'TOOL' |
		'RATING' |
		'CUSTOM';
	// % protected region % [Override todotype keys here] end

export const todotypeOptions: { [key in todotype]: string } = {
	// % protected region % [Override todotype display fields here] off begin
	ACTIVITY: 'Activity',
	ARTICLE: 'Article',
	TOOL: 'Tool',
	RATING: 'Rating',
	CUSTOM: 'Custom',
	// % protected region % [Override todotype display fields here] end
};

// % protected region % [Add any extra enums here] on begin
/**
 * Tooltip descriptions used for life areas
 */
export const lifeAreaDescriptions: { [key in lifeArea]: string } = {
	WORK: 'Think big! Do you want to be an entrepreneur? CEO? Whatever you want to be or do, this year, next year, in ten years’ time, if you have a plan of action and milestones to reach it, you can get there.',
	FINANCIAL: 'Financial goals can support your work goals. Do you want to be free of living paycheck to paycheck? Set some savings away for a holiday?',
	SOCIAL: 'Social goals could be helping those in need or volunteering at a local food bank.',
	RELATIONSHIPS: 'Goal setting and planning also helps with your relationships with friends and family. Keeping in touch? Spend more time with them? There will benefits.',
	SPIRITUAL: 'A goal in the values area of life could be keeping a gratitude journal, engaging in a daily meditation practice, or something that aligns more with your values.',
	FAMILY: 'Family goals could be as simple as spending a dinner together, eating more veggies, splitting chores etc.',
	PSYCHOLOGICAL: 'Psychological goals are just like physical goals. It’s training your brain to help keep it in a positive mental health state.',
	PHYSICAL: 'Setting a physical goal could be as easy as walking 5km everyday, or making it to the gym three times a week.'
};
// % protected region % [Add any extra enums here] end
