import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router';
import { getFrontendNavLinks } from 'Views/FrontendNavLinks';
import { store } from 'Models/Store';
import SecuredPage from 'Views/Components/Security/SecuredPage';
import Navigation, { Orientation } from 'Views/Components/Navigation/Navigation';
import ToDoTile from 'Views/Tiles/ToDoTile';

export default class ToDoPage extends Component<RouteComponentProps> {
	render() {
		const { history, location, match, staticContext } = this.props;

		const navigationLinks = getFrontendNavLinks(this.props);

		const contents = (
			<SecuredPage groups={['Admin', 'Patient', 'Clinician', 'Carer']}>
				<Navigation
					linkGroups={navigationLinks}
					orientation={Orientation.VERTICAL}
					match={match}
					location={location}
					history={history}
					staticContext={staticContext}
				/>

				<div className="body-content">
					<ToDoTile
						match={match}
						location={location}
						history={history}
						staticContext={staticContext}
						displayHeader={true}
						userId={store.userId!}
						userGroup={store.userGroups[0].name}
					/>
				</div>
			</SecuredPage>
		);
		// % protected region % [Customise the page here] end

		return contents;
	}
}
