/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import moment from 'moment';
import { action, observable } from 'mobx';
import { Model, IModelAttributes, attribute, entity } from 'Models/Model';
import {IOrderByCondition} from 'Views/Components/ModelCollection/ModelQuery';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from "Util/AttributeUtils";
import { IAcl } from 'Models/Security/IAcl';
import {
	makeFetchManyToManyFunc,
	makeJoinEqualsFunc,
	makeFetchOneToManyFunc,
	makeEnumFetchFunction,
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import { VisitorsCarerEntity } from 'Models/Security/Acl/VisitorsCarerEntity';
import { PatientCarerEntity } from 'Models/Security/Acl/PatientCarerEntity';
import { ClinicianCarerEntity } from 'Models/Security/Acl/ClinicianCarerEntity';
import { CarerCarerEntity } from 'Models/Security/Acl/CarerCarerEntity';
import { AdminCarerEntity } from 'Models/Security/Acl/AdminCarerEntity';
import * as Enums from '../Enums';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import {SuperAdministratorScheme} from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] on begin
import { computed } from 'mobx';
// % protected region % [Add any further imports here] end

export interface ICarerEntityAttributes extends IModelAttributes {
	email: string;
	forename: string;
	middlename: string;
	surname: string;
	gender: Enums.gender;
	dateOfBirth: Date;
	phone: string;
	customFields: string;

	todoss: Array<Models.TodoEntity | Models.ITodoEntityAttributes>;
	userss: Array<Models.CarersUsers | Models.ICarersUsersAttributes>;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('CarerEntity', 'Carer')
// % protected region % [Customise your entity metadata here] end
export default class CarerEntity extends Model implements ICarerEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new VisitorsCarerEntity(),
		new PatientCarerEntity(),
		new ClinicianCarerEntity(),
		new CarerCarerEntity(),
		new AdminCarerEntity(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		'email',
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	/**
	 * The default order by field when the collection is loaded .
	 */
	public get orderByField(): IOrderByCondition<Model> | undefined {
		// % protected region % [Modify the order by field here] off begin
		return {
			path: 'forename',
			descending: false,
		};
		// % protected region % [Modify the order by field here] end
	}

	// % protected region % [Modify props to the crud options here for attribute 'Email'] on begin
	@Validators.Email()
	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		name: 'Email',
		displayType: 'displayfield',
		createFieldType: 'textfield',
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
		order: 1,
	})
	public email: string;
	// % protected region % [Modify props to the crud options here for attribute 'Email'] end

	// % protected region % [Modify props to the crud options here for attribute 'Password'] on begin
	@Validators.Length(6)
	@observable
	@CRUD({
		name: 'Password',
		displayType: 'hidden',
		createFieldType: 'password',
		order: 2,
	})
	public password: string;
	// % protected region % [Modify props to the crud options here for attribute 'Password'] end

	// % protected region % [Modify props to the crud options here for attribute 'Confirm Password'] on begin
	@Validators.Custom('Password Match', (e: string, target: CarerEntity) => {
		return new Promise((resolve) => resolve(target.password !== e ? 'Password fields do not match' : null));
	})
	@observable
	@CRUD({
		name: 'Confirm Password',
		displayType: 'hidden',
		createFieldType: 'password',
		order: 3,
	})
	public _confirmPassword: string;
	// % protected region % [Modify props to the crud options here for attribute 'Confirm Password'] end

	// % protected region % [Modify props to the crud options here for attribute 'Forename'] on begin
	/* Forename */
	@observable
	@attribute()
	@CRUD({
		name: 'First Name',
		displayType: 'textfield',
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
		order: 4,
	})
	public forename: string;
	// % protected region % [Modify props to the crud options here for attribute 'Forename'] end

	// % protected region % [Modify props to the crud options here for attribute 'Middlename'] off begin
	/**
	 * Middlename
	 */
	@observable
	@attribute()
	@CRUD({
		name: 'Middlename',
		displayType: 'textfield',
		order: 50,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public middlename: string;
	// % protected region % [Modify props to the crud options here for attribute 'Middlename'] end

	// % protected region % [Modify props to the crud options here for attribute 'Surname'] on begin
	@observable
	@attribute()
	@CRUD({
		name: 'Last Name',
		displayType: 'textfield',
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
		order: 5,
	})
	public surname: string;
	// % protected region % [Modify props to the crud options here for attribute 'Surname'] end

	// % protected region % [Modify props to the crud options here for attribute 'Gender'] on begin
	/* Gender */
	@observable
	@attribute()
	@CRUD({
		name: 'Gender',
		displayType: 'enum-combobox',
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: (attr: string) => {
			return AttrUtils.standardiseEnum(attr, Enums.genderOptions);
		},
		enumResolveFunction: makeEnumFetchFunction(Enums.genderOptions),
		displayFunction: (attribute: Enums.gender) => Enums.genderOptions[attribute],
		order: 6,
	})
	public gender: Enums.gender;
	// % protected region % [Modify props to the crud options here for attribute 'Gender'] end

	// % protected region % [Modify props to the crud options here for attribute 'Date of Birth'] on begin
	/* DoB */
	@observable
	@attribute()
	@CRUD({
		name: 'Date of Birth',
		displayType: 'datepicker',
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseDate,
		order: 7,
		inputProps: {
			maxDate: moment(new Date().toISOString()).format('YYYY-MM-DD'),
		},
	})
	public dateOfBirth: Date;
	// % protected region % [Modify props to the crud options here for attribute 'Date of Birth'] end

	// % protected region % [Modify props to the crud options here for attribute 'Phone'] off begin
	/**
	 * Phone Number
	 */
	@observable
	@attribute()
	@CRUD({
		name: 'Phone',
		displayType: 'textfield',
		order: 90,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public phone: string;
	// % protected region % [Modify props to the crud options here for attribute 'Phone'] end

	// % protected region % [Modify props to the crud options here for attribute 'Custom Fields'] off begin
	/**
	 * Customn fields for the carer
	 */
	@observable
	@attribute()
	@CRUD({
		name: 'Custom Fields',
		displayType: 'textfield',
		order: 100,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public customFields: string;
	// % protected region % [Modify props to the crud options here for attribute 'Custom Fields'] end

	/**
	 * ToDo
	 */
	@observable
	@attribute({isReference: true})
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'ToDos'] off begin
		name: "ToDoss",
		displayType: 'reference-multicombobox',
		order: 110,
		referenceTypeFunc: () => Models.TodoEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'todoss',
			oppositeEntity: () => Models.TodoEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'ToDos'] end
	})
	public todoss: Models.TodoEntity[] = [];

	/**
	 * Users the carer takes care of
	 */
	@observable
	@attribute({isReference: true})
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Users'] on begin
		name: 'Associated Users',
		displayType: 'reference-multicombobox',
		isJoinEntity: true,
		referenceTypeFunc: () => Models.CarersUsers,
		optionEqualFunc: makeJoinEqualsFunc('usersId'),
		referenceResolveFunction: makeFetchManyToManyFunc({
			entityName: 'carerEntity',
			oppositeEntityName: 'patientEntity',
			relationName: 'carers',
			relationOppositeName: 'users',
			entity: () => Models.CarerEntity,
			joinEntity: () => Models.CarersUsers,
			oppositeEntity: () => Models.PatientEntity,
		}),
		order: 8,
		// % protected region % [Modify props to the crud options here for reference 'Users'] end
	})
	public userss: Models.CarersUsers[] = [];

	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<ICarerEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<ICarerEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.email !== undefined) {
				this.email = attributes.email;
			}
			if (attributes.forename !== undefined) {
				this.forename = attributes.forename;
			}
			if (attributes.middlename !== undefined) {
				this.middlename = attributes.middlename;
			}
			if (attributes.surname !== undefined) {
				this.surname = attributes.surname;
			}
			if (attributes.gender !== undefined) {
				this.gender = attributes.gender;
			}
			if (attributes.dateOfBirth !== undefined) {
				if (attributes.dateOfBirth === null) {
					this.dateOfBirth = attributes.dateOfBirth;
				} else {
					this.dateOfBirth = moment(attributes.dateOfBirth).toDate();
				}
			}
			if (attributes.phone !== undefined) {
				this.phone = attributes.phone;
			}
			if (attributes.customFields !== undefined) {
				this.customFields = attributes.customFields;
			}
			if (attributes.todoss !== undefined && Array.isArray(attributes.todoss)) {
				for (const model of attributes.todoss) {
					if (model instanceof Models.TodoEntity) {
						this.todoss.push(model);
					} else {
						this.todoss.push(new Models.TodoEntity(model));
					}
				}
			}
			if (attributes.userss !== undefined && Array.isArray(attributes.userss)) {
				for (const model of attributes.userss) {
					if (model instanceof Models.CarersUsers) {
						this.userss.push(model);
					} else {
						this.userss.push(new Models.CarersUsers(model));
					}
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] off begin
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] off begin
	public defaultExpands = `
		userss {
			${Models.CarersUsers.getAttributes().join('\n')}
			users {
				${Models.PatientEntity.getAttributes().join('\n')}
			}
		}
		todoss {
			${Models.TodoEntity.getAttributes().join('\n')}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] off begin
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath = {
			userss: {},
			todoss: {},
		};

		if (formMode === 'create') {
			relationPath['password'] = {};

			if (this.password !== this._confirmPassword) {
				throw Error("Password fields do not match");
			}
		}
		return this.save(
			relationPath,
			{
				graphQlInputType: formMode === 'create'
					? `[${this.getModelName()}CreateInput]`
					: `[${this.getModelName()}Input]`,
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
							'todoss',
							'userss',
						]
					},
				],
			}
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] off begin
		return this.email;
		// % protected region % [Customise the display name for this entity] end
	}


	// % protected region % [Add any further custom model features here] on begin
	@computed
	get fullname() {
		return `${this.forename} ${this.surname}`;
	}
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [ createdAttr, modifiedAttr ] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(CarerEntity.prototype, 'created');
CRUD(modifiedAttr)(CarerEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
