import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import { SERVER_URL } from 'Constants';
import { Button, Display } from '../Button/Button';
import { observable, action } from 'mobx';
import { observer } from 'mobx-react';
import { store } from 'Models/Store';

interface ActivityProps {
	id: string;
	name: string;
	featureId: string;
}

@observer
export default class ActivityButton extends Component<ActivityProps> {
	// Notifications not currently in use however will leave this here until they are
	// @observable
	// private notification: 'bell' | 'bell-off' = 'bell-off';

	// @action
	// private handleNotification = () => {
	// 	this.notification = this.notification == 'bell' ? 'bell-off' : 'bell';
	// };

	render() {
		const { pathname, search } = store.routerHistory.location;
		return (
			<div className="activity-button" onClick={() => store.routerHistory.push('/activity?id=' + this.props.id, pathname + search)}>
				<img src={`${SERVER_URL}/api/files/${this.props.featureId}`} />
				<b>{this.props.name}</b>
			</div>
		);
	}
}
