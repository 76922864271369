import React, { Component } from "react";
import { RouteComponentProps } from "react-router";
import Navigation, { Orientation } from "Views/Components/Navigation/Navigation";
import SecuredPage from "Views/Components/Security/SecuredPage";
import { getFrontendNavLinks } from "Views/FrontendNavLinks";
import CheckInOverviewTile from "Views/Components/PatientCheckIn/CheckInOverviewTile";

interface ICheckInOverviewPageParams {
	id: string;
	submission: string;
	mode?: string;
}

export default class CheckInOverviewPage extends Component<RouteComponentProps<ICheckInOverviewPageParams>> {
	render() {
		const { history, location, match, staticContext } = this.props;
		const navigationLinks = getFrontendNavLinks(this.props);

		return (
			<SecuredPage groups={["Admin", "Patient", "Clinician", "Carer"]}>
				<Navigation
					linkGroups={navigationLinks}
					orientation={Orientation.VERTICAL}
					match={match}
					location={location}
					history={history}
					staticContext={staticContext}
				/>

				<div className="body-content">
					<CheckInOverviewTile formId={match.params.id} submissionId={match.params.submission} mode={match.params.mode} />
				</div>
			</SecuredPage>
		);
	}
}
