/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import moment from 'moment';
import { action, observable } from 'mobx';
import { Model, IModelAttributes, attribute, entity } from 'Models/Model';
import {IOrderByCondition} from 'Views/Components/ModelCollection/ModelQuery';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from "Util/AttributeUtils";
import { IAcl } from 'Models/Security/IAcl';
import {
	makeEnumFetchFunction,
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import { VisitorsTodoEntity } from 'Models/Security/Acl/VisitorsTodoEntity';
import { PatientTodoEntity } from 'Models/Security/Acl/PatientTodoEntity';
import { ClinicianTodoEntity } from 'Models/Security/Acl/ClinicianTodoEntity';
import { CarerTodoEntity } from 'Models/Security/Acl/CarerTodoEntity';
import { AdminTodoEntity } from 'Models/Security/Acl/AdminTodoEntity';
import * as Enums from '../Enums';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import {SuperAdministratorScheme} from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface ITodoEntityAttributes extends IModelAttributes {
	title: string;
	description: string;
	complete: boolean;
	duedate: Date;
	types: Enums.todotype;
	typeid: string;
	displayStartDate: Date;
	completedDate: Date;

	adminId?: string;
	admin?: Models.AdminEntity | Models.IAdminEntityAttributes;
	carerId?: string;
	carer?: Models.CarerEntity | Models.ICarerEntityAttributes;
	clinicianId?: string;
	clinician?: Models.ClinicianEntity | Models.IClinicianEntityAttributes;
	patientId?: string;
	patient?: Models.PatientEntity | Models.IPatientEntityAttributes;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('TodoEntity', 'ToDo')
// % protected region % [Customise your entity metadata here] end
export default class TodoEntity extends Model implements ITodoEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new VisitorsTodoEntity(),
		new PatientTodoEntity(),
		new ClinicianTodoEntity(),
		new CarerTodoEntity(),
		new AdminTodoEntity(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	/**
	 * The default order by field when the collection is loaded .
	 */
	public get orderByField(): IOrderByCondition<Model> | undefined {
		// % protected region % [Modify the order by field here] off begin
		return {
			path: 'duedate',
			descending: false,
		};
		// % protected region % [Modify the order by field here] end
	}

	// % protected region % [Modify props to the crud options here for attribute 'Title'] off begin
	/**
	 * Title of the to-do item
	 */
	@observable
	@attribute()
	@CRUD({
		name: 'Title',
		displayType: 'textfield',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public title: string;
	// % protected region % [Modify props to the crud options here for attribute 'Title'] end

	// % protected region % [Modify props to the crud options here for attribute 'Description'] on begin
	@observable
	@attribute()
	@CRUD({
		name: 'Description',
		displayType: 'textfield',
		order: 20,
		headerColumn: false,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public description: string;
	// % protected region % [Modify props to the crud options here for attribute 'Description'] end

	// % protected region % [Modify props to the crud options here for attribute 'Complete'] off begin
	/**
	 * If the item is complete or not
	 */
	@observable
	@attribute()
	@CRUD({
		name: 'Complete',
		displayType: 'checkbox',
		order: 30,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public complete: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'Complete'] end

	// % protected region % [Modify props to the crud options here for attribute 'DueDate'] off begin
	/**
	 * Date the task is due
	 */
	@observable
	@attribute()
	@CRUD({
		name: 'DueDate',
		displayType: 'datetimepicker',
		order: 40,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseDate,
	})
	public duedate: Date;
	// % protected region % [Modify props to the crud options here for attribute 'DueDate'] end

	// % protected region % [Modify props to the crud options here for attribute 'Types'] off begin
	@observable
	@attribute()
	@CRUD({
		name: 'Types',
		displayType: 'enum-combobox',
		order: 50,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: (attr: string) => {
			return AttrUtils.standardiseEnum(attr, Enums.todotypeOptions);
		},
		enumResolveFunction: makeEnumFetchFunction(Enums.todotypeOptions),
		displayFunction: (attribute: Enums.todotype) => Enums.todotypeOptions[attribute],
	})
	public types: Enums.todotype;
	// % protected region % [Modify props to the crud options here for attribute 'Types'] end

	// % protected region % [Modify props to the crud options here for attribute 'TypeID'] off begin
	@observable
	@attribute()
	@CRUD({
		name: 'TypeID',
		displayType: 'textfield',
		order: 60,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public typeid: string;
	// % protected region % [Modify props to the crud options here for attribute 'TypeID'] end

	// % protected region % [Modify props to the crud options here for attribute 'Display Start Date'] off begin
	/**
	 * The date this to do item should be visible to the user.
	 */
	@observable
	@attribute()
	@CRUD({
		name: 'Display Start Date',
		displayType: 'datepicker',
		order: 70,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseDate,
	})
	public displayStartDate: Date;
	// % protected region % [Modify props to the crud options here for attribute 'Display Start Date'] end

	// % protected region % [Modify props to the crud options here for attribute 'Completed Date'] off begin
	/**
	 * The date that this to do item is completed.
	 */
	@observable
	@attribute()
	@CRUD({
		name: 'Completed Date',
		displayType: 'datepicker',
		order: 80,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseDate,
	})
	public completedDate: Date;
	// % protected region % [Modify props to the crud options here for attribute 'Completed Date'] end

	/**
	 * ToDo
	 */
	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Admin'] off begin
		name: 'Admin',
		displayType: 'reference-combobox',
		order: 90,
		referenceTypeFunc: () => Models.AdminEntity,
		// % protected region % [Modify props to the crud options here for reference 'Admin'] end
	})
	public adminId?: string;
	@observable
	@attribute({isReference: true})
	public admin: Models.AdminEntity;

	/**
	 * ToDo
	 */
	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Carer'] off begin
		name: 'Carer',
		displayType: 'reference-combobox',
		order: 100,
		referenceTypeFunc: () => Models.CarerEntity,
		// % protected region % [Modify props to the crud options here for reference 'Carer'] end
	})
	public carerId?: string;
	@observable
	@attribute({isReference: true})
	public carer: Models.CarerEntity;

	/**
	 * ToDo
	 */
	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Clinician'] off begin
		name: 'Clinician',
		displayType: 'reference-combobox',
		order: 110,
		referenceTypeFunc: () => Models.ClinicianEntity,
		// % protected region % [Modify props to the crud options here for reference 'Clinician'] end
	})
	public clinicianId?: string;
	@observable
	@attribute({isReference: true})
	public clinician: Models.ClinicianEntity;

	/**
	 * ToDo
	 */
	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Patient'] off begin
		name: 'Patient',
		displayType: 'reference-combobox',
		order: 120,
		referenceTypeFunc: () => Models.PatientEntity,
		// % protected region % [Modify props to the crud options here for reference 'Patient'] end
	})
	public patientId?: string;
	@observable
	@attribute({isReference: true})
	public patient: Models.PatientEntity;

	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<ITodoEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<ITodoEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.title !== undefined) {
				this.title = attributes.title;
			}
			if (attributes.description !== undefined) {
				this.description = attributes.description;
			}
			if (attributes.complete !== undefined) {
				this.complete = attributes.complete;
			}
			if (attributes.duedate !== undefined) {
				if (attributes.duedate === null) {
					this.duedate = attributes.duedate;
				} else {
					this.duedate = moment(attributes.duedate).toDate();
				}
			}
			if (attributes.types !== undefined) {
				this.types = attributes.types;
			}
			if (attributes.typeid !== undefined) {
				this.typeid = attributes.typeid;
			}
			if (attributes.displayStartDate !== undefined) {
				if (attributes.displayStartDate === null) {
					this.displayStartDate = attributes.displayStartDate;
				} else {
					this.displayStartDate = moment(attributes.displayStartDate).toDate();
				}
			}
			if (attributes.completedDate !== undefined) {
				if (attributes.completedDate === null) {
					this.completedDate = attributes.completedDate;
				} else {
					this.completedDate = moment(attributes.completedDate).toDate();
				}
			}
			if (attributes.admin !== undefined) {
				if (attributes.admin === null) {
					this.admin = attributes.admin;
				} else {
					if (attributes.admin instanceof Models.AdminEntity) {
						this.admin = attributes.admin;
						this.adminId = attributes.admin.id;
					} else {
						this.admin = new Models.AdminEntity(attributes.admin);
						this.adminId = this.admin.id;
					}
				}
			} else if (attributes.adminId !== undefined) {
				this.adminId = attributes.adminId;
			}
			if (attributes.carer !== undefined) {
				if (attributes.carer === null) {
					this.carer = attributes.carer;
				} else {
					if (attributes.carer instanceof Models.CarerEntity) {
						this.carer = attributes.carer;
						this.carerId = attributes.carer.id;
					} else {
						this.carer = new Models.CarerEntity(attributes.carer);
						this.carerId = this.carer.id;
					}
				}
			} else if (attributes.carerId !== undefined) {
				this.carerId = attributes.carerId;
			}
			if (attributes.clinician !== undefined) {
				if (attributes.clinician === null) {
					this.clinician = attributes.clinician;
				} else {
					if (attributes.clinician instanceof Models.ClinicianEntity) {
						this.clinician = attributes.clinician;
						this.clinicianId = attributes.clinician.id;
					} else {
						this.clinician = new Models.ClinicianEntity(attributes.clinician);
						this.clinicianId = this.clinician.id;
					}
				}
			} else if (attributes.clinicianId !== undefined) {
				this.clinicianId = attributes.clinicianId;
			}
			if (attributes.patient !== undefined) {
				if (attributes.patient === null) {
					this.patient = attributes.patient;
				} else {
					if (attributes.patient instanceof Models.PatientEntity) {
						this.patient = attributes.patient;
						this.patientId = attributes.patient.id;
					} else {
						this.patient = new Models.PatientEntity(attributes.patient);
						this.patientId = this.patient.id;
					}
				}
			} else if (attributes.patientId !== undefined) {
				this.patientId = attributes.patientId;
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] off begin
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] off begin
	public defaultExpands = `
		admin {
			${Models.AdminEntity.getAttributes().join('\n')}
		}
		carer {
			${Models.CarerEntity.getAttributes().join('\n')}
		}
		clinician {
			${Models.ClinicianEntity.getAttributes().join('\n')}
		}
		patient {
			${Models.PatientEntity.getAttributes().join('\n')}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] off begin
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath = {
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
						]
					},
				],
			}
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] on begin
		return this.title;
		// % protected region % [Customise the display name for this entity] end
	}


	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [ createdAttr, modifiedAttr ] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(TodoEntity.prototype, 'created');
CRUD(modifiedAttr)(TodoEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
