import { AreaOfLifeRatingsEntity, PatientEntity } from 'Models/Entities';
import React, { Component } from 'react'
import { Button, Colors, Display } from '../Button/Button'
import GoalAreas from '../Goals/GoalAreas'
import { action, observable } from 'mobx';
import { observer } from 'mobx-react';

interface IOnboardingAreaofLifeProps {
	user: PatientEntity;
	areaOfLifeRating: AreaOfLifeRatingsEntity;
	setAreaOfLifeRating: (areaOfLifeRating: AreaOfLifeRatingsEntity) => void;
	incrementStep: () => void;
	decrementStep: () => void;
}

@observer
export default class OnboardingAreaofLife extends Component<IOnboardingAreaofLifeProps> {

	@observable
	private areaOfLifeRating = this.props.areaOfLifeRating;

	render() {

		const { decrementStep } = this.props;

		return (
			<>
				<GoalAreas areasOfLifeRating={this.areaOfLifeRating} />
				<div className="onboarding-wizard-footer">
					<Button
						display={Display.Text}
						colors={Colors.White}
						onClick={() => decrementStep()}>
						Back
					</Button>
					<Button
						display={Display.Round}
						colors={Colors.Black}
						className="icon-arrow-right icon-right"
						onClick={this.handleOnClickNext}
					>
						Next
					</Button>
				</div>
			</>
		)
	}

	@action
	handleOnClickNext = () => {
		this.props.setAreaOfLifeRating(this.areaOfLifeRating);
		this.props.incrementStep();
	}
}
