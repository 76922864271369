/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import { action, observable } from 'mobx';
import { Model, IModelAttributes, attribute, entity } from 'Models/Model';
import {IOrderByCondition} from 'Views/Components/ModelCollection/ModelQuery';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from "Util/AttributeUtils";
import { IAcl } from 'Models/Security/IAcl';
import {
	makeFetchOneToManyFunc,
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import { VisitorsBooksEntity } from 'Models/Security/Acl/VisitorsBooksEntity';
import { PatientBooksEntity } from 'Models/Security/Acl/PatientBooksEntity';
import { ClinicianBooksEntity } from 'Models/Security/Acl/ClinicianBooksEntity';
import { CarerBooksEntity } from 'Models/Security/Acl/CarerBooksEntity';
import { AdminBooksEntity } from 'Models/Security/Acl/AdminBooksEntity';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import {SuperAdministratorScheme} from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface IBooksEntityAttributes extends IModelAttributes {
	name: string;
	patient: boolean;
	clinician: boolean;
	carer: boolean;

	activitiess: Array<Models.ActivitiesEntity | Models.IActivitiesEntityAttributes>;
	learningss: Array<Models.ArticlesEntity | Models.IArticlesEntityAttributes>;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('BooksEntity', 'Books')
// % protected region % [Customise your entity metadata here] end
export default class BooksEntity extends Model implements IBooksEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new VisitorsBooksEntity(),
		new PatientBooksEntity(),
		new ClinicianBooksEntity(),
		new CarerBooksEntity(),
		new AdminBooksEntity(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	/**
	 * The default order by field when the collection is loaded .
	 */
	public get orderByField(): IOrderByCondition<Model> | undefined {
		// % protected region % [Modify the order by field here] off begin
		return {
			path: 'name',
			descending: false,
		};
		// % protected region % [Modify the order by field here] end
	}

	// % protected region % [Modify props to the crud options here for attribute 'Name'] on begin
	/* Name of the book */
	@observable
	@attribute()
	@CRUD({
		name: 'Name',
		displayType: 'textfield',
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
		order: 1,
	})
	public name: string;
	// % protected region % [Modify props to the crud options here for attribute 'Name'] end

	// % protected region % [Modify props to the crud options here for attribute 'Patient'] off begin
	/**
	 * Available to patients
	 */
	@observable
	@attribute()
	@CRUD({
		name: 'Patient',
		displayType: 'checkbox',
		order: 20,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public patient: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'Patient'] end

	// % protected region % [Modify props to the crud options here for attribute 'Clinician'] off begin
	/**
	 * Available to clinicians
	 */
	@observable
	@attribute()
	@CRUD({
		name: 'Clinician',
		displayType: 'checkbox',
		order: 30,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public clinician: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'Clinician'] end

	// % protected region % [Modify props to the crud options here for attribute 'Carer'] off begin
	/**
	 * Available to Carer
	 */
	@observable
	@attribute()
	@CRUD({
		name: 'Carer',
		displayType: 'checkbox',
		order: 40,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public carer: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'Carer'] end

	/**
	 * Activities belong to books
	 */
	@observable
	@attribute({isReference: true})
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Activities'] on begin
		name: 'Activities',
		displayType: 'reference-multicombobox',
		referenceTypeFunc: () => Models.ActivitiesEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'activitiess',
			oppositeEntity: () => Models.ActivitiesEntity,
		}),
		order: 3,
		// % protected region % [Modify props to the crud options here for reference 'Activities'] end
	})
	public activitiess: Models.ActivitiesEntity[] = [];

	/**
	 * Books contain many articles
	 */
	@observable
	@attribute({isReference: true})
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Learnings'] on begin
		name: 'Articles',
		displayType: 'reference-multicombobox',
		referenceTypeFunc: () => Models.ArticlesEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'learningss',
			oppositeEntity: () => Models.ArticlesEntity,
		}),
		order: 2,
		// % protected region % [Modify props to the crud options here for reference 'Learnings'] end
	})
	public learningss: Models.ArticlesEntity[] = [];

	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<IBooksEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<IBooksEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.name !== undefined) {
				this.name = attributes.name;
			}
			if (attributes.patient !== undefined) {
				this.patient = attributes.patient;
			}
			if (attributes.clinician !== undefined) {
				this.clinician = attributes.clinician;
			}
			if (attributes.carer !== undefined) {
				this.carer = attributes.carer;
			}
			if (attributes.activitiess !== undefined && Array.isArray(attributes.activitiess)) {
				for (const model of attributes.activitiess) {
					if (model instanceof Models.ActivitiesEntity) {
						this.activitiess.push(model);
					} else {
						this.activitiess.push(new Models.ActivitiesEntity(model));
					}
				}
			}
			if (attributes.learningss !== undefined && Array.isArray(attributes.learningss)) {
				for (const model of attributes.learningss) {
					if (model instanceof Models.ArticlesEntity) {
						this.learningss.push(model);
					} else {
						this.learningss.push(new Models.ArticlesEntity(model));
					}
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] off begin
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] on begin
	public defaultExpands = `
		learningss {
			${Models.ArticlesEntity.getAttributes().join('\n')}
			${Models.ArticlesEntity.getFiles()
				.map((f: any) => f.name)
				.join('\n')}
		}
		activitiess {
			${Models.ActivitiesEntity.getAttributes().join('\n')}
			${Models.ActivitiesEntity.getFiles()
				.map((f: any) => f.name)
				.join('\n')}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] off begin
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath = {
			activitiess: {},
			learningss: {},
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
							'activitiess',
							'learningss',
						]
					},
				],
			}
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] on begin
		return this.name;
		// % protected region % [Customise the display name for this entity] end
	}


	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [ createdAttr, modifiedAttr ] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(BooksEntity.prototype, 'created');
CRUD(modifiedAttr)(BooksEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
