import React, { Component } from 'react';
import { GoalsEntity, PatientEntity } from 'Models/Entities';
import { observer } from 'mobx-react';
import { lifeArea } from 'Models/Enums';
import { store } from '../../../Models/Store';

interface IGoalCard {
	goal: GoalsEntity;
	onGoalClick: () => void;
}

export function GetAreaOfLifeBackgroundPath(type: lifeArea) {
	switch (type) {
		case 'WORK':
			return `${process.env.PUBLIC_URL}/Goals//WorkBackground.svg`;
		case 'PSYCHOLOGICAL':
			return `${process.env.PUBLIC_URL}/Goals/PsychologicalBackground.svg`;
		case 'PHYSICAL':
			return `${process.env.PUBLIC_URL}/Goals/PhysicalBackground.svg`;
		case 'FINANCIAL':
			return `${process.env.PUBLIC_URL}/Goals/FinancialBackground.svg`;
		case 'SOCIAL':
			return `${process.env.PUBLIC_URL}/Goals/SocialBackground.svg`;
		case 'RELATIONSHIPS':
			return `${process.env.PUBLIC_URL}/Goals/RelationshipBackground.svg`;
		case 'SPIRITUAL':
			return `${process.env.PUBLIC_URL}/Goals/ValuesBackground.svg`;
		case 'FAMILY':
			return `${process.env.PUBLIC_URL}/Goals/FamilyBackground.svg`;
		default:
			return `${process.env.PUBLIC_URL}/Goals/WorkBackground.svg`;
	}
}

@observer
export class GoalButton extends Component<IGoalCard> {

	render() {
		const { goal, onGoalClick } = this.props;

		// this is adding more bad ontop of the bad. however with the impending production release a refactor of the entire codebase that is using the enums inappropriately
		var areaOfLifeDisplay = "";
		switch (goal.lifeAreaToString){
			case "Spiritual":
				areaOfLifeDisplay = "Values";
				break;
			case "Psychological":
				areaOfLifeDisplay = "Mindset"
				break;
			default:
				areaOfLifeDisplay = goal.lifeAreaToString;
		}

		return (
			<div
				className={"goals-card"}
				onClick={() => onGoalClick()}
			>
				<div className="goal-info">
					<div className="goal-info-container">
						<span className="goal-text">{areaOfLifeDisplay}</span>
						<span className="goal-text goal-name">{goal.name}</span>
					</div>
					<div className="goal-progress-container">
						<div className="goal-progress-container__text-container">
							<span className="goal-text goal-progress">{goal.progressPercentageToString}</span>
						</div>
					</div>
				</div>
			</div>

		);
	}
}
