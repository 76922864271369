import React, { Component } from "react";
import { AreaOfLifeRatingsEntity, PatientEntity } from "Models/Entities";
import { observable, action } from "mobx";
import { observer } from "mobx-react";
import { store } from "Models/Store";
import { Button, Display } from '../Button/Button';
import { AreaProgress } from './GoalsProgess';
import GoalList from "../GoalList/GoalList";
import { prettyLog } from '../../../Util/StringUtils';

interface IGoalSettingActivity {
	user: PatientEntity;
}

@observer
export default class GoalSettingActivity extends Component<IGoalSettingActivity> {
	@observable patient: PatientEntity = this.props.user;

	@action
	private handleOnClickArea(area: string) {
		store.routerHistory.push('/goals/create');
	}

	renderAreaOfLifeProgress = () => {
		let areaOfLifeRating: AreaOfLifeRatingsEntity = this.patient.getLatestAreaofLifeValues();

		return (
			<AreaProgress
				user={this.props.user}
				areaOfLifeRating={areaOfLifeRating}
				displayFooter={true}
				onClick={(area) => this.handleOnClickArea(area) }
			/>
		)
	}
	render() {
		return (
			<>
				<div className="goals-area-progress goals-page-section">
					<div className="goals-page-section-header">
						<div className="goals-page-section-title">AREAS OF LIFE</div>
						<Button display={Display.Text}
						        onClick={() => {
							        store.routerHistory.push("/rateareasoflife")
						        }}>
							Re-rate Areas of Life
						</Button>
					</div>
					{this.renderAreaOfLifeProgress()}
				</div>

				<div className="goals-progress goals-page-section">
					<GoalList />
				</div>
			</>
		);
	}

}

