import React, { Component } from 'react'
import { YHPButton } from "Views/Components/Button/YHPButton";
import YHPLogo from "Views/Components/Logo/YHPLogo";
import { ButtonGroup, Alignment } from 'Views/Components/Button/ButtonGroup';
import { TextField } from '../TextBox/TextBox';
import { action, observable, runInAction } from 'mobx';
import { Checkbox } from '../Checkbox/Checkbox';
import { PatientEntity } from 'Models/Entities';
import { store } from 'Models/Store';
import { observer } from 'mobx-react';
import alert from '../../../Util/ToastifyUtils';
import PhoneInput, { formatPhoneNumberIntl, isValidPhoneNumber } from "react-phone-number-input";
import { prettyLog } from '../../../Util/StringUtils';
import classNames from 'classnames';

interface IProfileDetailsProps {
	user: PatientEntity;
}

interface ICompleteProfileForm {
	forename: string;
	lastname: string;
	middlename: string;
	phone: string;
	acceptedPrivacyPolicy: boolean;
	errors: { [attr: string]: string };
}

const defaultCompleteProfileFormValues: ICompleteProfileForm = {
	forename: '',
	lastname: '',
	middlename: '',
	phone: '',
	acceptedPrivacyPolicy: false,
	errors: {},

}

@observer
export default class ProfileDetails extends Component<IProfileDetailsProps> {

	@observable
	private completeProfileForm: ICompleteProfileForm = defaultCompleteProfileFormValues;

	private saveProfileDetails = () => {
		this.props.user
			.save()
			.then(() => {
				console.log("Profile details saved")
				if (this.props.user.onboarded == null || this.props.user.onboarded == false) {
					store.routerHistory.push('/onboarding');
				} else {
					store.routerHistory.push('/');
				}
			})
			.catch(() => alert("Could not save information", 'error'));


	}
	@action
	private handlePhoneChange(e: string) {
		this.props.user.phone = e;
	}

	@action
	private saveProfileCreationForm = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		this.completeProfileForm.errors = {};

		if (!this.props.user.forename) {
			this.completeProfileForm.errors['forename'] = "First name is required";
		}
		if (!this.props.user.surname) {
			this.completeProfileForm.errors['lastname'] = "Last name is required";
		}
		if (!isValidPhoneNumber(this.props.user.phone)) {
			this.completeProfileForm.errors['phone'] = "Valid phone number is required";
		}
		if (!this.props.user.phone) {
			this.completeProfileForm.errors['phone'] = "Phone number is required";
		}
		if (this.props.user.acceptedPrivacyPolicy == false || this.props.user.acceptedPrivacyPolicy == null) {
			this.completeProfileForm.errors['acceptedPrivacyPolicy'] = "Agree to terms and conditions";
		}
		if (Object.keys(this.completeProfileForm.errors).length > 0) {
			return;
		} else {
			this.saveProfileDetails();
		}
	};

	render() {
		return (
			<form className="profile-creation-form" onSubmit={(e) => this.saveProfileCreationForm(e)}>
				<YHPLogo />
				<h2 className="profile-creation-header">Finish setting up your profile</h2>
				<TextField
					className="profile-input"
					model={this.props.user}
					modelProperty="forename"
					placeholder="First name"
					label="First name"
					errors={this.completeProfileForm.errors["forename"]}
				/>
				<TextField
					className="profile-input"
					model={this.props.user}
					modelProperty="middlename"
					placeholder="Middle name(optional)"
					label="Middle name (Optional)"
				/>
				<TextField
					className="profile-input"
					model={this.props.user}
					modelProperty="surname"
					placeholder="Last name"
					label="Last name"
					errors={this.completeProfileForm.errors["lastname"]}
				/>
				<div className="profile-label" style={{ color: this.completeProfileForm.errors["phone"] ? 'red' : '' }}>Phone</div>
				<PhoneInput
					error={this.completeProfileForm.errors['phone']}
					className={this.completeProfileForm.errors['phone']?.length > 0 ? classNames(["profile-input-phone-error", "profile-input-phone"]) : 'profile-input-phone'}
					value={this.props.user.phone ? this.props.user.phone : ""}
					onChange={(e) => this.handlePhoneChange(e)}
					international
				/>

				<div className="profile-input-group__error-text">
					<p className="input-error">{this.completeProfileForm.errors["phone"]}</p>
				</div>

				<div className="profile-field-agree-term">
					<Checkbox
						className='profile-agree-terms__checkbox input '
						model={this.props.user}
						modelProperty="acceptedPrivacyPolicy"
						onAfterChecked={() => {
							this.completeProfileForm.errors["acceptedPrivacyPolicy"] = '';
						}}
						label="Agree to " />
					<a
						className="profile-terms"
						target="_blank"
						rel="noopener noreferrer"
						href="https://yourhealthplus.com.au/privacy-statement/"
					>
						Terms and Conditions
					</a>
				</div>

				{/* Note - showing error message for checkbox here because the inbuilt checkbox one cannot be used */}
				{this.completeProfileForm.errors && this.completeProfileForm.errors["acceptedPrivacyPolicy"]?.length > 0 && (
					<div className="input-group__error-text profile-terms__accepted-error">
						<p>{this.completeProfileForm.errors["acceptedPrivacyPolicy"]}</p>
					</div>
				)}

				<ButtonGroup className="profile-continue-button">
					<YHPButton
						type='submit'
						icon={{ icon: "arrow-right", iconPos: "icon-right" }}>
						Continue
					</YHPButton>
				</ButtonGroup>
			</form>

		)
	}
}
