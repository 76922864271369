/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { RouteComponentProps } from 'react-router';
import { Redirect } from 'react-router';
import { Button, Display, Sizes } from '../Components/Button/Button';
import { action, observable } from 'mobx';
import { store } from 'Models/Store';
import { SERVER_URL } from 'Constants';
import axios from 'axios';
import { ButtonGroup, Alignment } from 'Views/Components/Button/ButtonGroup';
import alert from '../../Util/ToastifyUtils';
import { Password } from 'Views/Components/Password/Password';
import * as queryString from 'querystring';
// % protected region % [Add any extra imports here] on begin
import styles from "./../../scss/pages/loginPage.module.scss";
import { LoginWrapper } from "Wrappers/LoginWrapper";
import YHPLogo from "Views/Components/Logo/YHPLogo";
import { YHPButton } from "Views/Components/Button/YHPButton";
import * as MobileUtils from "../../Util/MobileUtils";
import If from 'Views/Components/If/If';
// % protected region % [Add any extra imports here] end

interface IResetPasswordState {
	token: string;
	username: string;
	password: string;
	confirmPassword: string,
	errors: { [attr: string]: string };
	// % protected region % [Add extra reset password state properties here] off begin
	// % protected region % [Add extra reset password state properties here] end
}

const defaultResetPasswordState: IResetPasswordState = {
	token:'',
	username: '',
	password: '',
	confirmPassword:'',
	errors: {},
	// % protected region % [Instantiate extra reset password state properties here] off begin
	// % protected region % [Instantiate extra reset password state properties here] end
};

@observer
export default class ResetPasswordPage extends React.Component<RouteComponentProps> {
	@observable
	private resetPasswordState: IResetPasswordState = defaultResetPasswordState;

	constructor(props:RouteComponentProps, context: any){
		super(props, context);
		let queryParams = this.props.location.search.substring(1);
		const { token, username } = queryString.parse(queryParams);
		this.resetPasswordState.token = token as string;
		this.resetPasswordState.username = username as string;
	}

	public render() {
		let contents = null;

		if (store.loggedIn) {
			// % protected region % [Override redirect here] off begin
			return <Redirect to="/" />;
			// % protected region % [Override redirect here] end
		}

		// % protected region % [Override contents here] on begin
		if (MobileUtils.isMobile())
		{
			contents = this.renderResetPasswordForm();
		} else {
			contents = <LoginWrapper>{this.renderResetPasswordForm()}</LoginWrapper>;
		}
		// % protected region % [Override contents here] end
		return contents;
	}

	@action
	private onResetPasswordClicked = (event: React.FormEvent<HTMLFormElement>) => {
		// % protected region % [Override onLoginClicked here] off begin
		event.preventDefault();

		this.resetPasswordState.errors = {};

		if (!this.resetPasswordState.password) {
			this.resetPasswordState.errors['password'] = "Password is required";
		} else if (this.resetPasswordState['password'].length < 6) {
			this.resetPasswordState.errors['password'] = "The minimum length of password is 6";
		} else if (!this.resetPasswordState['confirmPassword']){
			this.resetPasswordState.errors['confirmPassword'] = "Confirm password is required";
		} else if (this.resetPasswordState['confirmPassword'] !== this.resetPasswordState.password) {
			this.resetPasswordState.errors['confirmPassword'] = "Password and confirm password does not match";
		}

		if (Object.keys(this.resetPasswordState.errors).length > 0) {
			return;
		} else {
			axios.post(
				`${SERVER_URL}/api/account/reset-password`,
				{
					token: this.resetPasswordState.token,
					username: this.resetPasswordState.username,
					password: this.resetPasswordState.password,
				})
				.then(({ data }) => {
					this.onConfirmPasswordSent();
				})
				.catch(error => {
					const errorMsgs = error.response.data.errors.map((error: any) => (<p>{error.message}</p>));
					alert(
						<div>
							<h6>Password could not be changed: </h6>
							{errorMsgs}
						</div>,
						'error'
					);
				});
		}
		// % protected region % [Override onLoginClicked here] end
	};

	@action
	private onConfirmPasswordSent = () => {
		// % protected region % [Override login success logic here] on begin
		alert(`Your password has been reset`, 'success');

		if (MobileUtils.isMobile()) {
			setTimeout(() => MobileUtils.navigateIfMobile("home", true), 2000);
		} else {
			store.routerHistory.push(`/login`);
		}		
		// % protected region % [Override login success logic here] end
	};

	// % protected region % [Add class methods here] on begin
	public renderResetPasswordForm() {
		return (
			<form className={styles.formContainer} onSubmit={this.onResetPasswordClicked}>
				<If condition={MobileUtils.isMobile()}>
					<div style={{display: 'flex', width: '100%', justifyContent: 'space-between', marginBottom: '2rem'}}>
						<img style={{ maxHeight: '4.25rem' }} src={`${process.env.PUBLIC_URL}/Logo/bhg_logo_vert.png`} alt='YHP Logo'/>

						{store.customLogo && (
							<img style={{ maxHeight: '4.25rem' }} src={`${SERVER_URL}/api/files/${store.customLogo}`} alt='Custom Logo' />
						)}
					</div>
				</If>
				<If condition={!MobileUtils.isMobile()}>
					<YHPLogo />
				</If>

				<h2 className={styles.formHeader}>Set New Password</h2>
				<Password
					id='new_password'
					className='new-password'
					model={this.resetPasswordState}
					modelProperty='password'
					label='New Password'
					isRequired={true}
					errors={this.resetPasswordState.errors["password"]}
				/>
				<Password
					id='confirm_password'
					className='confirm-password'
					model={this.resetPasswordState}
					modelProperty='confirmPassword'
					label='Confirm Password'
					isRequired={true}
					errors={this.resetPasswordState.errors["confirmPassword"]}
				/>

				<ButtonGroup className={styles.formFooter}>
					<YHPButton
						className={styles.YHPButton}
						type='submit'
						icon={{ icon: "arrow-right", iconPos: "icon-right" }}>
						Confirm & log in
					</YHPButton>
				</ButtonGroup>
			</form>
		);
	}
	// % protected region % [Add class methods here] end
}