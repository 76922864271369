import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router';
import { gql } from 'apollo-boost';
import { observer } from 'mobx-react';
import { store } from 'Models/Store';
import { observable, action } from 'mobx';
import { Button, Display } from 'Views/Components/Button/Button';
import { Link } from 'react-router-dom';
import { TextField } from 'Views/Components/TextBox/TextBox';
import ActivityBookContainer from 'Views/Components/Activities/ActivityBookContainer';

function queryBooksWithActivities() {
	return gql`
		query books {
			booksEntitys {
				id
				name
				patient
				carer
				clinician
				activitiess {
					id
					name
					featureId
				}
			}
		}
	`;
}

interface ISearch {
	searchTerm: string;
}

@observer
export default class ActivitiesTileAll extends Component<RouteComponentProps> {
	@observable
	private books: JSX.Element;

	@observable
	private booksObject: any;

	@observable
	private search: ISearch = { searchTerm: '' };

	public componentDidMount(): void {
		this.getActivities();
		this.checkSearch();
	}

	private async getActivities() {
		await store.apolloClient
			.query({
				query: queryBooksWithActivities(),
			})
			.then((d) => {
				this.renderBooks(d.data.booksEntitys);
				this.booksObject = d.data.booksEntitys;
			})
			.catch(() => alert('Could not retrieve content'));
	}

	@action
	renderBooks(books: any) {
		let bookContainers;
		if (books) {
			bookContainers = books.map((book: any) => {
				// Super admins can see all books
				if (store.userGroups.find((group) => group.name === 'Admin')) {
					return this.filterSearch(book);
				}
				// Check if the user have access to the book
				if (store.userGroups.find((group) => group.name === 'Patient') && book.patient == true) {
					return this.filterSearch(book);
				} else if (store.userGroups.find((group) => group.name === 'Clinician') && book.clinician == true) {
					return this.filterSearch(book);
				} else if (store.userGroups.find((group) => group.name === 'Carer') && book.carer == true) {
					return this.filterSearch(book);
				} else {
					return <></>;
				}
			});
		}

		this.books = bookContainers;
	}

	@action
	filterSearch = (book: any) => {
		// Check book match
		if (book.name.toLowerCase().includes(this.search.searchTerm.toLowerCase())) {
			return <ActivityBookContainer key={book.id} id={book.id} title={book.name} activities={book.activitiess} />;
		}
		// return;
		// Check article match
		return <ActivityBookContainer key={book.id} id={book.id} title={book.name} activities={book.activitiess} filter={this.search.searchTerm} />;
	};

	@action
	handleSearch = () => {
		// Some trickery to take the user back to the main page if they
		// got here by searching from the main page and their search is now blank
		let state: any = this.props.location.state;
		if (state) {
			const { path } = state;
			if (path) {
				if (this.search.searchTerm === '') {
					store.routerHistory.push(path, true);
				}
			}
		}
		this.renderBooks(this.booksObject);
	};

	@action
	checkSearch = () => {
		let state: any = this.props.location.state;
		if (state) {
			const { search } = state;
			this.search.searchTerm = search ? search.toString() : '';
		}
	};

	render() {
		let autoFocus;
		let state: any = this.props.location.state;
		if (state) {
			const { focus } = state;
			autoFocus = focus == true ? true : false;
		}

		let contents = (
			<>
				<div className="library-header">
					<div>
						<h6>Home / Activities</h6>
						<h2>All Activities</h2>
					</div>
					<div className="library-all-back">
						{/* Search Bar */}
						<div className="search-box">
							<Button icon={{ icon: 'search', iconPos: 'icon-left' }} />
						</div>
						<TextField
							model={this.search}
							modelProperty="searchTerm"
							className="article-search"
							placeholder="Search..."
							onAfterChange={this.handleSearch}
							autoFocus={autoFocus}
						/>
					</div>
				</div>

				<div className="back-fav">
					<Link to="/activities" className="icon-arrow-left icon-left">
						Back
					</Link>
				</div>

				<div className="activity-books-container">{this.books}</div>
			</>
		);
		return contents;
	}
}
