import React, { Component } from 'react';
import ArticleButton from './ArticleButton';
import Editor from 'react-markdown-editor-lite';
import AdminArticleButton from './AdminArticleButton';

export interface BooksProps {
	title: string;
	articles: any[];
	book: string;
	editing: boolean;
}

export default class AdminBookContainer extends Component<BooksProps> {
	render() {
		let articles = this.props.articles.map((article: any) => (
			<AdminArticleButton key={article.id} article={article} book={this.props.book} editing={this.props.editing} />
		));

		return (
			<>
				<div className="tiles" style={{ padding: '30px' }}>
					<h4>{this.props.title}</h4>
					<div style={{ display: 'flex', flexWrap: 'wrap' }}>{articles}</div>
				</div>
			</>
		);
	}
}
