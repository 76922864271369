/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import { SERVER_URL } from 'Constants';
import * as React from 'react';
import { action, observable, runInAction } from 'mobx';
import { Model, IModelAttributes, attribute, entity } from 'Models/Model';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from "Util/AttributeUtils";
import { IAcl } from 'Models/Security/IAcl';
import {
	makeFetchManyToManyFunc,
	makeJoinEqualsFunc,
	makeFetchOneToManyFunc,
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import { VisitorsActivitiesEntity } from 'Models/Security/Acl/VisitorsActivitiesEntity';
import { PatientActivitiesEntity } from 'Models/Security/Acl/PatientActivitiesEntity';
import { ClinicianActivitiesEntity } from 'Models/Security/Acl/ClinicianActivitiesEntity';
import { CarerActivitiesEntity } from 'Models/Security/Acl/CarerActivitiesEntity';
import { AdminActivitiesEntity } from 'Models/Security/Acl/AdminActivitiesEntity';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import { FormEntityData, FormEntityDataAttributes, getAllVersionsFn, getPublishedVersionFn } from 'Forms/FormEntityData';
import { FormVersion } from 'Forms/FormVersion';
import { fetchFormVersions, fetchPublishedVersion } from 'Forms/Forms';
import {SuperAdministratorScheme} from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] on begin
import { v4 as uuidv4 } from "uuid";
import { store } from "Models/Store";
// % protected region % [Add any further imports here] end

export interface IActivitiesEntityAttributes extends IModelAttributes, FormEntityDataAttributes {
	name: string;
	featureId: string;
	feature: Blob;
	description: string;
	patient: boolean;
	clinician: boolean;
	carer: boolean;

	booksId?: string;
	books?: Models.BooksEntity | Models.IBooksEntityAttributes;
	formPages: Array<Models.ActivitiesEntityFormTileEntity | Models.IActivitiesEntityFormTileEntityAttributes>;
	articles?: Models.ArticlesEntity | Models.IArticlesEntityAttributes;
	tagss: Array<Models.TagsActivities | Models.ITagsActivitiesAttributes>;
	favouritedbypatientss: Array<Models.FavouritedbypatientsFavouriteactivities | Models.IFavouritedbypatientsFavouriteactivitiesAttributes>;
	libraryTagss: Array<Models.ActivitiesLibraryTags | Models.IActivitiesLibraryTagsAttributes>;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('ActivitiesEntity', 'Activities')
// % protected region % [Customise your entity metadata here] end
export default class ActivitiesEntity extends Model implements IActivitiesEntityAttributes, FormEntityData  {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new VisitorsActivitiesEntity(),
		new PatientActivitiesEntity(),
		new ClinicianActivitiesEntity(),
		new CarerActivitiesEntity(),
		new AdminActivitiesEntity(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	// % protected region % [Modify props to the crud options here for attribute 'Name'] on begin
	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		name: "Activity Name",
		displayType: "textfield",
		headerColumn: true,
		searchable: true,
		searchFunction: "like",
		searchTransform: AttrUtils.standardiseString,
		order: 2,
	})
	public name: string;
	// % protected region % [Modify props to the crud options here for attribute 'Name'] end

	// % protected region % [Modify props to the crud options here for attribute 'Feature'] on begin
	/**
	 * Feature image for the activity
	 */
	@observable
	@attribute({ file: "feature" })
	@CRUD({
		name: "Activity Image",
		displayType: "file",
		order: 1,
		headerColumn: true,
		searchable: true,
		searchFunction: "equal",
		searchTransform: AttrUtils.standardiseUuid,
		inputProps: {
			imageOnly: true,
		},
		fileAttribute: "feature",
		displayFunction: (attr) =>
			attr ? <img src={`${SERVER_URL}/api/files/${attr}`} style={{ maxWidth: "300px" }} alt={"Image Preview"} /> : "No File Attached",
	})
	public featureId: string;
	@observable
	public feature: Blob;
	// % protected region % [Modify props to the crud options here for attribute 'Feature'] end

	// % protected region % [Modify props to the crud options here for attribute 'Description'] on begin
	/**
	 * Description of what the activity does
	 */
	@observable
	@attribute()
	@CRUD({
		name: "Description",
		displayType: "textarea",
		order: 30,
		headerColumn: false,
		searchable: true,
		searchFunction: "like",
		searchTransform: AttrUtils.standardiseString,
	})
	public description: string;
	// % protected region % [Modify props to the crud options here for attribute 'Description'] end

	// % protected region % [Modify props to the crud options here for attribute 'Patient'] on begin
	/**
	 * Available to patients
	 */
	@observable
	@attribute()
	@CRUD({
		name: "Patient",
		displayType: "checkbox",
		order: 100,
		headerColumn: false,
		searchable: true,
		searchFunction: "equal",
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: (attr) => (attr ? "True" : "False"),
	})
	public patient: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'Patient'] end

	// % protected region % [Modify props to the crud options here for attribute 'Clinician'] on begin
	/**
	 * Available to clinicians
	 */
	@observable
	@attribute()
	@CRUD({
		name: "Clinician",
		displayType: "checkbox",
		order: 110,
		headerColumn: false,
		searchable: true,
		searchFunction: "equal",
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: (attr) => (attr ? "True" : "False"),
	})
	public clinician: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'Clinician'] end

	// % protected region % [Modify props to the crud options here for attribute 'Carer'] on begin
	/**
	 * Available to carers
	 */
	@observable
	@attribute()
	@CRUD({
		name: "Carer",
		displayType: "checkbox",
		order: 120,
		headerColumn: false,
		searchable: true,
		searchFunction: "equal",
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: (attr) => (attr ? "True" : "False"),
	})
	public carer: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'Carer'] end

	@observable
	@attribute({isReference: true})
	public formVersions: FormVersion[] = [];

	@observable
	@attribute()
	public publishedVersionId?: string;

	@observable
	@attribute({isReference: true})
	public publishedVersion?: FormVersion;

	/**
	 * Activities belong to books
	 */
	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Books'] on begin
		name: "Books",
		displayType: "reference-combobox",
		order: 50,
		referenceTypeFunc: () => Models.BooksEntity,
		searchable: false,
		searchFunction: "like",
		searchTransform: AttrUtils.standardiseString,
		optionEqualFunc: (model, option) => model.id === option,

		// % protected region % [Modify props to the crud options here for reference 'Books'] end
	})
	public booksId?: string;
	@observable
	@attribute({isReference: true})
	public books: Models.BooksEntity;

	@observable
	@attribute({isReference: true})
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Form Page'] on begin
		name: "Form Pages",
		displayType: "hidden",
		referenceTypeFunc: () => Models.ActivitiesEntityFormTileEntity,
		disableDefaultOptionRemoval: true,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: "formPages",
			oppositeEntity: () => Models.ActivitiesEntityFormTileEntity,
		}),

		// % protected region % [Modify props to the crud options here for reference 'Form Page'] end
	})
	public formPages: Models.ActivitiesEntityFormTileEntity[] = [];

	/**
	 * Articles for an activity
	 */
	@observable
	@attribute({isReference: true})
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Articles'] on begin
		name: "Article",
		displayType: "reference-combobox",
		order: 60,
		referenceTypeFunc: () => Models.ArticlesEntity,
		searchable: false,
		searchFunction: "like",
		searchTransform: AttrUtils.standardiseString,
		optionEqualFunc: (model, option) => model.id === option,
		inputProps: {
			fetchReferenceEntity: true,
		},
		// % protected region % [Modify props to the crud options here for reference 'Articles'] end
	})
	public articles?: Models.ArticlesEntity;

	/**
	 * Tags for the Activities
	 */
	@observable
	@attribute({isReference: true})
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Tags'] on begin
		name: "Tags",
		displayType: "reference-multicombobox",
		isJoinEntity: true,
		referenceTypeFunc: () => Models.TagsActivities,
		optionEqualFunc: makeJoinEqualsFunc("tagsId"),
		referenceResolveFunction: makeFetchManyToManyFunc({
			entityName: "activitiesEntity",
			oppositeEntityName: "tagEntity",
			relationName: "activities",
			relationOppositeName: "tags",
			entity: () => Models.ActivitiesEntity,
			joinEntity: () => Models.TagsActivities,
			oppositeEntity: () => Models.TagEntity,
		}),
		order: 60,

		// % protected region % [Modify props to the crud options here for reference 'Tags'] end
	})
	public tagss: Models.TagsActivities[] = [];

	/**
	 * Activities favourited by patients
	 */
	@observable
	@attribute({isReference: true})
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'FavouritedByPatients'] on begin
		name: 'FavouritedByPatients',
		displayType: 'hidden', //reference-multicombobox',
		order: 110,
		isJoinEntity: true,
		referenceTypeFunc: () => Models.FavouritedbypatientsFavouriteactivities,
		optionEqualFunc: makeJoinEqualsFunc('favouritedbypatientsId'),
		referenceResolveFunction: makeFetchManyToManyFunc({
			entityName: 'activitiesEntity',
			oppositeEntityName: 'patientEntity',
			relationName: 'favouriteactivities',
			relationOppositeName: 'favouritedbypatients',
			entity: () => Models.ActivitiesEntity,
			joinEntity: () => Models.FavouritedbypatientsFavouriteactivities,
			oppositeEntity: () => Models.PatientEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'FavouritedByPatients'] end
	})
	public favouritedbypatientss: Models.FavouritedbypatientsFavouriteactivities[] = [];

	/**
	 * List of library tags that is associated with the activity. These are used to personalise content.
	 */
	@observable
	@attribute({isReference: true})
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Library Tags'] off begin
		name: 'Library Tags',
		displayType: 'reference-multicombobox',
		order: 120,
		isJoinEntity: true,
		referenceTypeFunc: () => Models.ActivitiesLibraryTags,
		optionEqualFunc: makeJoinEqualsFunc('libraryTagsId'),
		referenceResolveFunction: makeFetchManyToManyFunc({
			entityName: 'activitiesEntity',
			oppositeEntityName: 'libraryTagEntity',
			relationName: 'activities',
			relationOppositeName: 'libraryTags',
			entity: () => Models.ActivitiesEntity,
			joinEntity: () => Models.ActivitiesLibraryTags,
			oppositeEntity: () => Models.LibraryTagEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Library Tags'] end
	})
	public libraryTagss: Models.ActivitiesLibraryTags[] = [];

	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<IActivitiesEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<IActivitiesEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.feature !== undefined) {
				this.feature = attributes.feature;
			}
			if (attributes.featureId !== undefined) {
				this.featureId = attributes.featureId;
			}
			if (attributes.description !== undefined) {
				this.description = attributes.description;
			}
			if (attributes.patient !== undefined) {
				this.patient = attributes.patient;
			}
			if (attributes.clinician !== undefined) {
				this.clinician = attributes.clinician;
			}
			if (attributes.carer !== undefined) {
				this.carer = attributes.carer;
			}
			if (attributes.publishedVersion !== undefined) {
				if (attributes.publishedVersion === null) {
					this.publishedVersion = attributes.publishedVersion;
				} else {
					if (typeof attributes.publishedVersion.formData === 'string') {
						attributes.publishedVersion.formData = JSON.parse(attributes.publishedVersion.formData);
					}
					this.publishedVersion = attributes.publishedVersion;
					this.publishedVersionId = attributes.publishedVersion.id;
				}
			} else if (attributes.publishedVersionId !== undefined) {
				this.publishedVersionId = attributes.publishedVersionId;
			}
			if (attributes.formVersions !== undefined) {
				this.formVersions.push(...attributes.formVersions);
			}
			if (attributes.name !== undefined) {
				this.name = attributes.name;
			}
			if (attributes.books !== undefined) {
				if (attributes.books === null) {
					this.books = attributes.books;
				} else {
					if (attributes.books instanceof Models.BooksEntity) {
						this.books = attributes.books;
						this.booksId = attributes.books.id;
					} else {
						this.books = new Models.BooksEntity(attributes.books);
						this.booksId = this.books.id;
					}
				}
			} else if (attributes.booksId !== undefined) {
				this.booksId = attributes.booksId;
			}
			if (attributes.formPages !== undefined && Array.isArray(attributes.formPages)) {
				for (const model of attributes.formPages) {
					if (model instanceof Models.ActivitiesEntityFormTileEntity) {
						this.formPages.push(model);
					} else {
						this.formPages.push(new Models.ActivitiesEntityFormTileEntity(model));
					}
				}
			}
			if (attributes.articles !== undefined) {
				if (attributes.articles === null) {
					this.articles = attributes.articles;
				} else {
					if (attributes.articles instanceof Models.ArticlesEntity) {
						this.articles = attributes.articles;
					} else {
						this.articles = new Models.ArticlesEntity(attributes.articles);
					}
				}
			}
			if (attributes.tagss !== undefined && Array.isArray(attributes.tagss)) {
				for (const model of attributes.tagss) {
					if (model instanceof Models.TagsActivities) {
						this.tagss.push(model);
					} else {
						this.tagss.push(new Models.TagsActivities(model));
					}
				}
			}
			if (attributes.favouritedbypatientss !== undefined && Array.isArray(attributes.favouritedbypatientss)) {
				for (const model of attributes.favouritedbypatientss) {
					if (model instanceof Models.FavouritedbypatientsFavouriteactivities) {
						this.favouritedbypatientss.push(model);
					} else {
						this.favouritedbypatientss.push(new Models.FavouritedbypatientsFavouriteactivities(model));
					}
				}
			}
			if (attributes.libraryTagss !== undefined && Array.isArray(attributes.libraryTagss)) {
				for (const model of attributes.libraryTagss) {
					if (model instanceof Models.ActivitiesLibraryTags) {
						this.libraryTagss.push(model);
					} else {
						this.libraryTagss.push(new Models.ActivitiesLibraryTags(model));
					}
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] on begin
			if (attributes.id) {
				this.id = attributes.id;
			}
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] off begin
	public defaultExpands = `
		publishedVersion {
			id
			created
			modified
			formData
		}
		libraryTagss {
			${Models.ActivitiesLibraryTags.getAttributes().join('\n')}
			libraryTags {
				${Models.LibraryTagEntity.getAttributes().join('\n')}
			}
		}
		tagss {
			${Models.TagsActivities.getAttributes().join('\n')}
			tags {
				${Models.TagEntity.getAttributes().join('\n')}
			}
		}
		favouritedbypatientss {
			${Models.FavouritedbypatientsFavouriteactivities.getAttributes().join('\n')}
			favouritedbypatients {
				${Models.PatientEntity.getAttributes().join('\n')}
			}
		}
		books {
			${Models.BooksEntity.getAttributes().join('\n')}
		}
		formPages {
			${Models.ActivitiesEntityFormTileEntity.getAttributes().join('\n')}
		}
		articles {
			${Models.ArticlesEntity.getAttributes().join('\n')}
			${Models.ArticlesEntity.getFiles().map(f => f.name).join('\n')}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] off begin
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath = {
			libraryTagss: {},
			tagss: {},
			favouritedbypatientss: {},
			formPages: {},
			articles: {},
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
							'articles',
							'tagss',
							'favouritedbypatientss',
							'libraryTagss',
						]
					},
				],
				contentType: 'multipart/form-data',
			}
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] off begin
		return this.name;
		// % protected region % [Customise the display name for this entity] end
	}

	/**
	 * Gets all the versions for this form.
	 */
	public getAllVersions: getAllVersionsFn = (includeSubmissions?, conditions?) => {
		// % protected region % [Modify the getAllVersionsFn here] off begin
		return fetchFormVersions(this, includeSubmissions, conditions)
			.then(d => {
				runInAction(() => this.formVersions = d);
				return d.map(x => x.formData)
			});
		// % protected region % [Modify the getAllVersionsFn here] end
	};

	/**
	 * Gets the published version for this form.
	 */
	public getPublishedVersion: getPublishedVersionFn = includeSubmissions => {
		// % protected region % [Modify the getPublishedVersionFn here] off begin
		return fetchPublishedVersion(this, includeSubmissions)
			.then(d => {
				runInAction(() => this.publishedVersion = d);
				return d ? d.formData : undefined;
			});
		// % protected region % [Modify the getPublishedVersionFn here] end
	};

	/**
	 * Gets the submission entity type for this form.
	 */
	public getSubmissionEntity = () => {
		// % protected region % [Modify the getSubmissionEntity here] off begin
		return Models.ActivitiesSubmissionEntity;
		// % protected region % [Modify the getSubmissionEntity here] end
	}


	// % protected region % [Add any further custom model features here] on begin
	public setModelName(name: string) {
		this.name = name;
	}
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [ createdAttr, modifiedAttr ] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(ActivitiesEntity.prototype, 'created');
CRUD(modifiedAttr)(ActivitiesEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
