/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import { Model, IModelAttributes, attribute, entity } from 'Models/Model';
import * as Models from 'Models/Entities';
import { IAcl } from '../Security/IAcl';
import { observable } from 'mobx';
import { VisitorsFavouritearticlesReferenceManyToMany } from '../Security/Acl/VisitorsFavouritearticlesReferenceManyToMany';
import { PatientFavouritearticlesReferenceManyToMany } from '../Security/Acl/PatientFavouritearticlesReferenceManyToMany';
import { ClinicianFavouritearticlesReferenceManyToMany } from '../Security/Acl/ClinicianFavouritearticlesReferenceManyToMany';
import { CarerFavouritearticlesReferenceManyToMany } from '../Security/Acl/CarerFavouritearticlesReferenceManyToMany';
import { AdminFavouritearticlesReferenceManyToMany } from '../Security/Acl/AdminFavouritearticlesReferenceManyToMany';

// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface IFavouritedbypatientsFavouritearticlesAttributes extends IModelAttributes {
	favouritedbypatientsId: string;
	favouritearticlesId: string;

	favouritedbypatients: Models.PatientEntity | Models.IPatientEntityAttributes;
	favouritearticles: Models.ArticlesEntity | Models.IArticlesEntityAttributes;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

@entity('FavouritedbypatientsFavouritearticles')
export default class FavouritedbypatientsFavouritearticles extends Model implements IFavouritedbypatientsFavouritearticlesAttributes {
	public static acls: IAcl[] = [
		new VisitorsFavouritearticlesReferenceManyToMany(),
		new PatientFavouritearticlesReferenceManyToMany(),
		new ClinicianFavouritearticlesReferenceManyToMany(),
		new CarerFavouritearticlesReferenceManyToMany(),
		new AdminFavouritearticlesReferenceManyToMany(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	@observable
	@attribute()
	public favouritedbypatientsId: string;

	@observable
	@attribute()
	public favouritearticlesId: string;

	@observable
	@attribute({isReference: true})
	public favouritedbypatients: Models.PatientEntity;

	@observable
	@attribute({isReference: true})
	public favouritearticles: Models.ArticlesEntity;
	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	constructor(attributes?: Partial<IFavouritedbypatientsFavouritearticlesAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		if (attributes) {
			if (attributes.favouritedbypatientsId) {
				this.favouritedbypatientsId = attributes.favouritedbypatientsId;
			}
			if (attributes.favouritearticlesId) {
				this.favouritearticlesId = attributes.favouritearticlesId;
			}

			if (attributes.favouritedbypatients) {
				if (attributes.favouritedbypatients instanceof Models.PatientEntity) {
					this.favouritedbypatients = attributes.favouritedbypatients;
					this.favouritedbypatientsId = attributes.favouritedbypatients.id;
				} else {
					this.favouritedbypatients = new Models.PatientEntity(attributes.favouritedbypatients);
					this.favouritedbypatientsId = this.favouritedbypatients.id;
				}
			} else if (attributes.favouritedbypatientsId !== undefined) {
				this.favouritedbypatientsId = attributes.favouritedbypatientsId;
			}

			if (attributes.favouritearticles) {
				if (attributes.favouritearticles instanceof Models.ArticlesEntity) {
					this.favouritearticles = attributes.favouritearticles;
					this.favouritearticlesId = attributes.favouritearticles.id;
				} else {
					this.favouritearticles = new Models.ArticlesEntity(attributes.favouritearticles);
					this.favouritearticlesId = this.favouritearticles.id;
				}
			} else if (attributes.favouritearticlesId !== undefined) {
				this.favouritearticlesId = attributes.favouritearticlesId;
			}
		}

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}