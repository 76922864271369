import { observable } from 'mobx';
import { PaginationQueryOptions } from '../../../Models/PaginationData';
import { ComboboxOption } from '../Combobox/Combobox';
import { PatientEntity, TagEntity } from '../../../Models/Entities';

/**
 * Props for dashboard that is common across all the pages
 */
export interface IDashboardProps {
	tags: TagEntity[];
	patients: PatientEntity[];
}
export const DASHBOARD_COMMON_TEXT = {
	filterTable: {
		title: "Filter data",
		description: "Filter, view, and export the full table of data below.",
	}
}


/**
 * Abstract filter options for the dashboard tables.
 */
export abstract class DashboardTableOptions {

	@observable
	paginationQueryOptions: PaginationQueryOptions = new PaginationQueryOptions();

	@observable
	public tagIds: string[] = [];

	@observable
	public patientIds: string[] = [];
}


export abstract class DashboardTableFilterOptions {
	@observable
	public tagOptions: ComboboxOption<string>[] = [];

	@observable
	public patientOptions: ComboboxOption<string>[] = [];

	@observable
	public selectedTagIds: string[] = [];

	@observable
	public selectedPatientIds: string[] = [];

	/**
	 * Given a list of tags, create the list of options that are to be used in the dropdown
	 * @param tags
	 */
	setTagOptionsAndIncludeAll = (tags: TagEntity[]) => {
		this.tagOptions = tags.map(attr => {
			return { display: attr.name, value: attr.id };
		})
		this.tagOptions.unshift({ display: "All", value: "All" });
	}

	/**
	 * Given a list of patients, create the list of options that are to be used in the dropdown
	 * @param patients
	 */
	setPatientOptionsAndIncludeAll = (patients: PatientEntity[]) => {
		this.patientOptions = patients.map(attr => {
			return { display: attr.fullname, value: attr.id };
		})
		this.patientOptions.unshift({ display: "All", value: "All" });
	}

	setPatientOptionsFilteredByTags = (selectedTagIds: string[], patients: PatientEntity[]) => {

		// If there are no tags or user has selected all, options should include all patients
		if (selectedTagIds.length === 0 || selectedTagIds.includes('All')) {
			this.setPatientOptionsAndIncludeAll(patients);
			return;
		}

		// Otherwise, filter patients to show only the ones that have the tags
		const patientsThatHaveSelectedTags = patients
			.filter(p => {
				const patientsTagsIds = p.tagss.map(tag => tag.tagsId);
				const intersectedIds = patientsTagsIds.filter(x => selectedTagIds.includes(x));
				return intersectedIds.length > 0
			})

		this.setPatientOptionsAndIncludeAll(patientsThatHaveSelectedTags);
	}

	/**
	 * Return serverside readable selected tag ids.
	 */
	getParsedSelectedTagIds = () => {
		return this.selectedTagIds.includes('All')
			? []
			: this.selectedTagIds;
	}

	/**
	 * Parse the selected patient options to a serverside readable format.
	 *
	 * If 'All' is selected, we only send up 'All' to the server instead of the full list of id's, because request
	 * params have a character limit and with all patients selected, server throws a 414.
	 *
	 * @returns serverside readable patient filter options
	 */
	getParsedSelectedPatientIds = () => {
		return this.selectedPatientIds.includes('All')
			? ['All'] as string[]
			: this.selectedPatientIds;
	}
}
