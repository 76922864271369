import React, { Component } from "react";
import { AreaOfLifeRatingsEntity, GoalsEntity, PatientEntity } from "Models/Entities";
import { observer } from "mobx-react";
import { observable, action, computed, runInAction } from "mobx";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { Slider } from "@material-ui/core";
import { store } from '../../../Models/Store';
import { SLIDER_THEME } from './AreaSlider';
import { prettyLog } from '../../../Util/StringUtils';

interface IGoalPopupProps {
	patient: PatientEntity;
	goal: GoalsEntity;
	closePopup: () => void;
	saveAndCelebrate: () => void;
}

/**
 * Configuration values for the slider.
 * - value represents starting and ending value.
 * - label represents the label shown for each end of the slider
 */
const SLIDER_MARKS = [
	{
		value: 0,
		label: "0%",
	},
	{
		value: 10,
		label: "100%",
	},
];

/**
 * Default rating value is 5 when they have not previously rating that area of life.
 */
const DEFAULT_RATING_VALUE = 5;

@observer
export default class GoalPopup extends Component<IGoalPopupProps> {

	@observable
	areaOfLifeRating: AreaOfLifeRatingsEntity;

	@observable
	public currentValue: number = DEFAULT_RATING_VALUE;

	@observable
	private initialValue: number = DEFAULT_RATING_VALUE;

	componentDidMount() {
		this.setupState();
	}
	@action
	setupState = () => {
		prettyLog(this.props.patient, 'patient')
		runInAction(() => {
			// Get latest values so we know which one to show on the popup.
			this.areaOfLifeRating = this.props.patient.getLatestAreaofLifeValues();
			const patientsLastRatingOnArea = this.areaOfLifeRating.getLifeAreaRating(this.props.goal.lifeArea);
			this.initialValue = patientsLastRatingOnArea;
			this.currentValue = patientsLastRatingOnArea ?? DEFAULT_RATING_VALUE;
		})
	}

	@action
	componentDidUpdate(prevProps: any) {
		if (prevProps.patient !== this.props.patient) {
			this.setupState();
		}
	}

	@action
	private close(next?: boolean) {
		const { closePopup, saveAndCelebrate } = this.props;
		if (next) {
			this.props.goal.isComplete = true;
		}
		// Create a new area of life rating and set the appropriate values for a single area of life rating
		const areaOfLifeRating = new AreaOfLifeRatingsEntity({
			patientId: this.props.patient.id,
			fullRating: false,
		})
		areaOfLifeRating.setLifeAreaRating(this.props.goal.lifeArea, this.currentValue);

		this.props.goal.save()
			.then(() =>

				areaOfLifeRating.save().then(() => {
					closePopup();
					if (next) {
						saveAndCelebrate();
					}
				})
			);
	}

	@action
	private valueChanged(val: any) {
		this.currentValue = val;
	}

	/**
	 * @author
	 *
	 * Rating values should be on a scale of 1 to 100.
	 * However, existing data has been restricted to from 1 to 10 and hence the conversion.
	 */
	@computed
	get currentRatingToString() {
		return this.ratingToString(this.currentValue);
	}

	public ratingToString(value: number) {
		return value === undefined
			? `0%` : `${(value / 10) * 100}%`
	}

	render() {

		if (this.areaOfLifeRating == null) {
			return <></>
		}
		// this is adding more bad ontop of the bad. however with the impending production release a refactor of the entire codebase that is using the enums inappropriately
		var areaOfLifeDisplay = "";
		switch (this.props.goal.lifeArea) {
			case "SPIRITUAL":
				areaOfLifeDisplay = "values";
				break;
			case "PSYCHOLOGICAL":
				areaOfLifeDisplay = "mindset"
				break;
			default:
				areaOfLifeDisplay = this.props.goal.lifeArea.toLocaleLowerCase();
		}

		return (
			<div className="complete-goal-popup-child">
				<div
					className={
						this.currentValue < 10
							? `complete-icon complete-less-10 icon-top icon-check-circle-2`
							: `complete-icon complete-10 icon-top icon-emoji`
					}
				/>
				<div className="complete-message">
					{this.currentValue < 10 ? "Goal completed" : "Great job! Keep it up"}
				</div>
				<div className="new-prompt">
					How would you rate your <b>{areaOfLifeDisplay}</b> life area now?
				</div>
				<div className="lifearea-slider">
					<div className="text-rating">{this.currentRatingToString}</div>
					<ThemeProvider theme={SLIDER_THEME}>
						<Slider
							value={this.currentValue}
							aria-labelledby="discrete-slider"
							valueLabelDisplay={"off"}
							step={1}
							min={0}
							max={10}
							marks={SLIDER_MARKS}
							onChange={(e, val) => this.valueChanged(val)}
						/>
					</ThemeProvider>
				</div>
				<div className="text-rating icon-left">
					{`Previous was ${this.ratingToString(this.initialValue)}`}
				</div>
				<div className="complete-footer">
					<div className="complete-button-container">
						<div className="close-plain" onClick={() => this.close()}>
							Close
						</div>
					</div>
					<div className="complete-button-container">
						<div className="orange-button" onClick={() => { this.close(true); }}>
							{`Save & Celebrate`}
						</div>
					</div>
				</div>
			</div>
		);
	}
}
