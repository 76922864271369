import React, { Component } from 'react';
import SecuredPage from 'Views/Components/Security/SecuredPage';
import Navigation, { Orientation } from 'Views/Components/Navigation/Navigation';
import { getFrontendNavLinks } from 'Views/FrontendNavLinks';
import { observer } from 'mobx-react';
import { store } from 'Models/Store';
import Spinner from 'Views/Components/Spinner/Spinner';
import { RouteComponentProps } from 'react-router';
import LibraryTileAll from 'Views/Tiles/LibraryTileAll';

@observer
export default class LibraryPageAll extends Component<RouteComponentProps> {
	render() {
		const { history, location, match, staticContext } = this.props;

		const navigationLinks = getFrontendNavLinks(this.props);

		if (store.displayNameStatus === 'loading') {
			return <Spinner />;
		}

		const contents = (
			<SecuredPage groups={['Admin', 'Patient', 'Clinician', 'Carer']}>
				<Navigation
					linkGroups={navigationLinks}
					orientation={Orientation.VERTICAL}
					match={match}
					location={location}
					history={history}
					staticContext={staticContext}
				/>

				<div className="body-content">
					<LibraryTileAll history={history} location={location} match={match} staticContext={staticContext} />
				</div>
			</SecuredPage>
		);
		// % protected region % [Customise the page here] end

		return contents;
	}
}
