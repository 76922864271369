import React from 'react';
import { GoalsEntity, GoalStepEntity, PatientEntity } from "Models/Entities";
import { Button, Colors, Display } from 'Views/Components/Button/Button';
import GoalStepRow from "Views/Components/Goals/GoalStepRow";
import { store } from "Models/Store";
import Popup from "reactjs-popup";
import { GetAreaOfLifeBackgroundPath, GoalButton } from "Views/Components/Goals/GoalsButtons";
import { observer } from "mobx-react";
import { action, observable, runInAction } from "mobx";
import alert from '../../Util/ToastifyUtils';
import Modal from '../Components/Modal/Modal';
import GoalPopup from 'Views/Components/Goals/GoalPopup';
import _ from "lodash";
import { TransferWithinAStationSharp } from '@material-ui/icons';

interface IGoalViewTileProps {
	goal: GoalsEntity;
	user: PatientEntity;
	onClickBack: () => void;
	onClickDelete: () => void;
	onPopUpSaveAndCelebrate: () => void;
	onAfterSave?: () => void;
}

@observer
export default class GoalViewTile extends React.Component<IGoalViewTileProps> {

	@observable
	public goal: GoalsEntity = this.props.goal;

	@observable
	public displayDeleteGoalModal = false;

	@observable
	public displayCompleteModal = false;

	@observable
	public displayGoalOptions = false;


	@observable
	private popupOpen: boolean = false

	@action
	public onPopupOpen = () => {
		this.popupOpen = true;
	};

	@action
	public onPopupClose = () => {
		this.popupOpen = false;
	};

	@action
	private closeCompletePopup() {
		this.displayCompleteModal = false;
	}

	componentDidUpdate(prevProps: Readonly<IGoalViewTileProps>, prevState: Readonly<{}>, snapshot?: any) {
		runInAction(() => {
			this.goal = this.props.goal;
		})
	}

	saveDebounce = _.debounce(() => {
		const relationPath = {
			goalStepss: {}
		};
		this.goal.removeEmptyGoals();
		this.goal.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
							'goalStepss',
						]
					},
				],
			}
		).then(res => {
			this.checkIfGoalComplete()
			if (this.props.onAfterSave) {
				this.props.onAfterSave();
			}
		});

	},
		250
	);

	onChangeStep = (step: GoalStepEntity) => {
		const foundIndedx = this.goal.goalStepss.findIndex(x => x.id === step.id);
		this.goal.goalStepss[foundIndedx] = step;
		if (step.name === '') {
			return;
		}
		this.checkIfGoalComplete();
		this.saveDebounce();
	}

	@action
	checkIfGoalComplete() {
		if (this.goal.progressPercentage === 100) {
			this.displayCompleteModal = true;
		}
	}

	/**
	 * The header for the goal.
	 */
	getGoalsBannerNode = () => {
		return (
			<div
				className="goals-banner"
				style={{ background: `url(${GetAreaOfLifeBackgroundPath(this.goal.lifeArea)})` }}
			>
				<div className="goals-banner__navigation">
					<Button
						colors={Colors.Black}
						display={Display.Text}
						className="icon-left icon-chevron-left"
						onClick={() => this.props.onClickBack()}
					>
						Back
					</Button>

					<Popup
						trigger={
							<div className="goal-options expand icon-more-vertical icon-right">Options</div>
						}
						position="bottom right"
						open={this.popupOpen}
						onClose={this.onPopupClose}

					>
						{(close) => (
							<div>
								<div className="goal-options__option"
									onClick={() => runInAction(() => {
										close();
										this.displayDeleteGoalModal = true
									})}
								>
									Delete
								</div>
							</div>
						)}

					</Popup>
				</div>
				<div className="goals-banner__card">
					<GoalButton goal={this.goal} onGoalClick={() => { }} />
				</div>
			</div>
		)
	}

	getGoalPlannerNode = () => {
		return (
			<div className="goals-planner">
				<div className="goals-planner__header">
					<div className="goals-planner__title">GOAL PLANNER</div>
					<Button
						display={Display.Text}
						colors={Colors.Black}
						className="goals-planner__add icon-plus-circle icon-left"
						onClick={this.handleOnClickAddStep}
					>
						Add Step
					</Button>

				</div>
				<div className="goals-step-list">
					{this.goal.goalStepss.map((step) => (
						<div key={step.id}>
							<GoalStepRow
								step={step}
								onChangeStep={this.onChangeStep}
								onRemoveStep={this.handleOnClickRemoveStep}
							/>
							<hr className="goals-step__step-divider" />
						</div>
					))}
				</div>

				<Popup
					className="complete-goal-popup"
					contentStyle={{ width: "auto" }}
					open={this.displayCompleteModal}
					closeOnDocumentClick={false}
					modal
				>
					<GoalPopup
						goal={this.goal}
						patient={this.props.user}
						closePopup={() => { this.closeCompletePopup() }}
						saveAndCelebrate={() => {
							this.checkIfGoalComplete();
							this.props.onPopUpSaveAndCelebrate()
						}}

					/>
				</Popup>

			</div>

		);
	}

	@action
	handleOnClickAddStep = () => {
		this.goal.goalStepss.push(new GoalStepEntity({
			goalsId: this.goal.id,
		}));
	}

	@action
	handleOnClickRemoveStep = (removedStep: GoalStepEntity) => {
		// Find the appropriate step and remove it from the goal
		const index = this.goal.goalStepss.findIndex((step) => step.id === removedStep.id);
		this.goal.goalStepss.splice(index, 1);

		// Save the goal and check if the goal is complete
		const relationPath = {
			goalStepss: {}
		};
		this.goal.removeEmptyGoals();
		this.goal.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
							'goalStepss',
						]
					},
				],

			}
		).then(res => {
			this.checkIfGoalComplete()
			console.log("success")
		})

		// this.setState(this.goal);
		console.log("goal saved here");
	}

	@action
	handleClickDeleteModalCancel = () => {
		this.displayDeleteGoalModal = false;
		this.onPopupClose();
	}

	@action
	handleClickDeleteModalConfirm = () => {
		this.goal.archived = true;
		this.goal.save()
			.then(res => {
				alert(`Successfully deleted ${this.goal.name}`, 'success');
				this.props.onClickDelete();
			})
	}
	getConfirmDeleteGoalNode = () => {
		return (
		<Modal
				isOpen={this.displayDeleteGoalModal}
				className="delete-profile-goals"
				overlayClassName="delete-profile-goals-overlay"
				label="User Selection Modal"
				onRequestClose={() => runInAction(() => this.displayDeleteGoalModal = false)}>
				<h4>Please confirm</h4>
				<p>{`Are you sure you want to delete ${this.goal.name}`}</p>
				<div key="actions" className="modal__actions">
					<Button key="confirm" onClick={this.handleClickDeleteModalConfirm}
						display={Display.Solid}>Confirm</Button>
					<Button key="cancel" onClick={this.handleClickDeleteModalCancel}
						display={Display.Outline}>Cancel</Button>
				</div>
			</Modal>
		)
	}

	public render() {
		return (
			<>
				{this.getGoalsBannerNode()}

				{this.getGoalPlannerNode()}

				{/* This is only shown if display delete state is true*/}
				{this.getConfirmDeleteGoalNode()}
			</>
		);
	}
}
