import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import If from "../If/If";
import { IUserResult, store } from "../../../Models/Store";
import { Link } from 'react-router-dom';
import { ITopBarProps } from './Topbar';
import { Combobox } from '../Combobox/Combobox';
import { observable, runInAction } from 'mobx';
import { DropdownProps } from 'semantic-ui-react';
import { SERVER_URL } from '../../../Constants';
import Axios, { AxiosResponse } from 'axios';
import { PatientEngagementGraphData } from '../../../Services/PatientTimelineEventsService';
import alert from '../../../Util/ToastifyUtils';
import PatientService from '../../../Services/PatientService/PatientService';
import { PatientEntity } from '../../../Models/Entities';
import { prettyLog } from '../../../Util/StringUtils';

function impersonateOptions(userTypes: UserType[]): { display: string, value: string }[] {
	return userTypes.map(o => {
		return {
			display: o.replace('Entity', ''), value: o
		}
	})
}

const AdminImpersonateOptions: UserType[] = ['AdminEntity', 'ClinicianEntity', 'PatientEntity'];
const ClinicianImpersonateOptions: UserType[] = ['ClinicianEntity', 'PatientEntity'];

type UserType = 'AdminEntity' | 'ClinicianEntity' | 'PatientEntity';

class UserState {
	@observable
	selectedUserType: UserType = 'AdminEntity';
}

export default function ImpersonateTopBar(props: ITopBarProps) {
	const [cookies] = useCookies(['Impersonation', 'loggedIn']);
	const [userState, setUserState] = useState<UserState>(new UserState());

	useEffect(() => {
		// HOTFIX / BAND-AID - minh
		// Cant display the current logged in user because store is instantiated before it can read
		// the current user from the cookie.
		setInterval(() => {
			runInAction(() => {
				if (store.getUser()) {
					userState.selectedUserType = store.getUser()?.discriminator as UserType;
				}
			})
		}, 1000)
	}, [])

	/**
	 * Switch back to the original user account.
	 * Re-direct them to the home page on success (just incase the switched user does not have access to the current page)
	 */
	const handleOnChangeLoggedInUser = async (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
		const value = data.value as string;

		if (value == null || value === userState.selectedUserType) {
			return;
		}
		userState.selectedUserType = value as UserType;

		try {
			let qry = '';
			if (userState.selectedUserType === 'ClinicianEntity') {
				qry = `?type=CLINICIAN`;
			} else if (userState.selectedUserType === 'PatientEntity') {
				qry = `?type=PATIENT`;
			}
			const res = await Axios.get<IUserResult>(`/api/authorization/impersonate/${qry}`);

			await onSuccessfulImpersonate(res);
		} catch (e) {
			console.error(e, 'Error on handleOnClickImpersonate');
			alert('Unsuccessfully switched to demo account', 'error');
		}
	}
	// Re-direct the user to the home page as they may not have access to the page the current user is on
	const onSuccessfulImpersonate = async (res: AxiosResponse<IUserResult>) => {

		// If not impersonating a patient, redirect to home.
		if (res.data.discriminator !== 'PatientEntity') {
			window.location.href = `${SERVER_URL}/`;
		}

		// If impersonating a patient, redirect to the appropriate page that patients follow upon login.
		try {
			// NOTE - Have to fetch from rest endpoint. GraphQL request rejects the XSRF Token.
			const resPatient = await Axios.get(`${SERVER_URL}/api/entity/PatientEntity/${res.data.id}`);
			const patient = new PatientEntity(resPatient.data);
			if (!patient.acceptedPrivacyPolicy) {
				window.location.href = `${SERVER_URL}/profile-creation-page`;
			} else if (!patient.onboarded) {
				window.location.href = `${SERVER_URL}/onboarding`;
			} else {
				window.location.href = `${SERVER_URL}/`;
			}
		} catch (e) {
			// Refresh on home page if there is an error
			window.location.href = `${SERVER_URL}/`;
		}
	}

	/**
	 * Send request to unimpersonate the currently logged in user.
	 * Re-direct the user to the home page as they may not have access to the current page they are on.
	 */
	const handleOnClickUnimpersonate = async () => {
		try {
			await Axios.get('/api/authorization/unimpersonate');
			window.location.href = `${SERVER_URL}/`;
		} catch (e) {
			console.error(e, 'Error on handleOnClickUnimpersonate');
			alert('Unsuccessfully switched back to original account', 'error');
		}
	}


	const getImpersonationOptions = () => {
		const discriminator = store.getUser()?.discriminator;
		if (discriminator == null) return [];
		if (discriminator === 'AdminEntity') return impersonateOptions(AdminImpersonateOptions);
		if (discriminator === 'ClinicianEntity') return impersonateOptions(ClinicianImpersonateOptions);
		return [];
	}

	const isImpersonating = () => cookies.Impersonation !== undefined;
	// Use discriminator instead of user groups. Masquerading does not support users that have 'User' as the discriminator
	// I.e., SuperUser etc.
	const isUserGroupThatCanImpersonate = () => store.getUser()?.discriminator === 'AdminEntity' || store.getUser()?.discriminator === 'ClinicianEntity';

	if (!store.loggedIn) {
		return (<></>);
	}

	return (
		<div className="app-top-bar nav--horizontal">
			{!isImpersonating() && isUserGroupThatCanImpersonate() && (
				<Combobox
					model={userState}
					className={"app-top-bar__combobox"}
					label={"Logged in as: "}
					options={getImpersonationOptions()}
					modelProperty={'selectedUserType'}
					onChange={handleOnChangeLoggedInUser}
				/>
			)}

			{isImpersonating() && (
				<a className="unimpersonate" onClick={handleOnClickUnimpersonate}>Unimpersonate</a>
			)}

			<If condition={store.hasBackendAccess}>
				{props.currentLocation === 'admin'
					? <Link to="/" className="icon-right icon-arrow-right-up admin-backend">Access to Frontend</Link>
					: <Link to="/admin" className="icon-right icon-arrow-right-up admin-backend">Access to backend</Link>
				}
			</If>
		</div>
	);
}
