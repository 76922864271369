/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import { Model, IModelAttributes, attribute, entity } from 'Models/Model';
import * as Models from 'Models/Entities';
import { IAcl } from '../Security/IAcl';
import { observable } from 'mobx';
import { VisitorsFavouriteactivitiesReferenceManyToMany } from '../Security/Acl/VisitorsFavouriteactivitiesReferenceManyToMany';
import { PatientFavouriteactivitiesReferenceManyToMany } from '../Security/Acl/PatientFavouriteactivitiesReferenceManyToMany';
import { ClinicianFavouriteactivitiesReferenceManyToMany } from '../Security/Acl/ClinicianFavouriteactivitiesReferenceManyToMany';
import { CarerFavouriteactivitiesReferenceManyToMany } from '../Security/Acl/CarerFavouriteactivitiesReferenceManyToMany';
import { AdminFavouriteactivitiesReferenceManyToMany } from '../Security/Acl/AdminFavouriteactivitiesReferenceManyToMany';

// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface IFavouritedbypatientsFavouriteactivitiesAttributes extends IModelAttributes {
	favouritedbypatientsId: string;
	favouriteactivitiesId: string;

	favouritedbypatients: Models.PatientEntity | Models.IPatientEntityAttributes;
	favouriteactivities: Models.ActivitiesEntity | Models.IActivitiesEntityAttributes;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

@entity('FavouritedbypatientsFavouriteactivities')
export default class FavouritedbypatientsFavouriteactivities extends Model implements IFavouritedbypatientsFavouriteactivitiesAttributes {
	public static acls: IAcl[] = [
		new VisitorsFavouriteactivitiesReferenceManyToMany(),
		new PatientFavouriteactivitiesReferenceManyToMany(),
		new ClinicianFavouriteactivitiesReferenceManyToMany(),
		new CarerFavouriteactivitiesReferenceManyToMany(),
		new AdminFavouriteactivitiesReferenceManyToMany(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	@observable
	@attribute()
	public favouritedbypatientsId: string;

	@observable
	@attribute()
	public favouriteactivitiesId: string;

	@observable
	@attribute({isReference: true})
	public favouritedbypatients: Models.PatientEntity;

	@observable
	@attribute({isReference: true})
	public favouriteactivities: Models.ActivitiesEntity;
	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	constructor(attributes?: Partial<IFavouritedbypatientsFavouriteactivitiesAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		if (attributes) {
			if (attributes.favouritedbypatientsId) {
				this.favouritedbypatientsId = attributes.favouritedbypatientsId;
			}
			if (attributes.favouriteactivitiesId) {
				this.favouriteactivitiesId = attributes.favouriteactivitiesId;
			}

			if (attributes.favouritedbypatients) {
				if (attributes.favouritedbypatients instanceof Models.PatientEntity) {
					this.favouritedbypatients = attributes.favouritedbypatients;
					this.favouritedbypatientsId = attributes.favouritedbypatients.id;
				} else {
					this.favouritedbypatients = new Models.PatientEntity(attributes.favouritedbypatients);
					this.favouritedbypatientsId = this.favouritedbypatients.id;
				}
			} else if (attributes.favouritedbypatientsId !== undefined) {
				this.favouritedbypatientsId = attributes.favouritedbypatientsId;
			}

			if (attributes.favouriteactivities) {
				if (attributes.favouriteactivities instanceof Models.ActivitiesEntity) {
					this.favouriteactivities = attributes.favouriteactivities;
					this.favouriteactivitiesId = attributes.favouriteactivities.id;
				} else {
					this.favouriteactivities = new Models.ActivitiesEntity(attributes.favouriteactivities);
					this.favouriteactivitiesId = this.favouriteactivities.id;
				}
			} else if (attributes.favouriteactivitiesId !== undefined) {
				this.favouriteactivitiesId = attributes.favouriteactivitiesId;
			}
		}

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}