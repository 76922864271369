import Axios from 'axios';
import { action, observable, runInAction } from 'mobx';
import { observer } from 'mobx-react';
import { GoalsEntity } from 'Models/Entities';
import { lifeArea } from 'Models/Enums';
import { store } from 'Models/Store';
import React, { Component } from 'react';
import { Button, Colors, Display } from 'Views/Components/Button/Button';
import smartlookClient from "smartlook-client";
import _ from 'lodash';
import If from 'Views/Components/If/If';

interface IGoalSlideSelectGoalProps {
	lifeArea: lifeArea;
	goal: GoalsEntity;
	onChangeGoal: (goal: GoalsEntity) => void;
	incrementStep: () => void;
	decrementStep: () => void;
	onClickRedirect: () => void;
	displayRedirect?: boolean;
}

@observer
export default class GoalSlideSelectGoal extends Component<IGoalSlideSelectGoalProps> {
	@observable
	private predefinedGoals: GoalsEntity[] = [];

	@observable
	private aol: lifeArea;

	@observable
	private requestState: 'pending' | 'error' | 'success' = 'pending';

	constructor(props: IGoalSlideSelectGoalProps) {
		super(props);
		this.aol = this.props.lifeArea;
	}

	componentDidMount() {
		this.getPredefinedGoals(this.aol);
	}

	@action
	private async getPredefinedGoals(lifeArea: lifeArea) {

		await Axios.get(`/api/entity/GoalsEntity/template/${lifeArea}`)
			.then((d) =>
				runInAction(() => {
					this.setPredefinedGoals(d.data);
					this.requestState = 'success';
				}))
			.catch((err) => {
				runInAction(() => {
					this.requestState = 'error';
				})
			})

	}
	@action
	private setPredefinedGoals = (results: GoalsEntity[]) => {
		this.predefinedGoals = results.map(attr => new GoalsEntity(attr));
	};

	render() {
		const { incrementStep, decrementStep, onClickRedirect } = this.props;
		let areaOfLifeTitle = "";
		switch (this.props.lifeArea.toLowerCase()) {
			case "spiritual":
				areaOfLifeTitle = "values";
				break;
			case "psychological":
				areaOfLifeTitle = "mindset"
				break;
			default:
				areaOfLifeTitle = this.props.lifeArea.toLowerCase();
		}

		let contents = (
			<>
				<h4 className="goal-select-text">
					Set a goal to help you improve your <span className="goals-life-area-text">{areaOfLifeTitle}</span> for area of life
                </h4>
				<div className="predefined-goals-list">
					{
						this.predefinedGoals.slice(0, 4).map((goal: GoalsEntity, index) => {
							return (
								<Button
									key={index}
									display={Display.Solid}
									colors={Colors.White}
									className="btn-goals"
									onClick={() => {
										this.handleOnClickNext(goal)
									}}
								>{goal.name}</Button>
							);
						})
					}
					<Button
						display={Display.Dotted}
						colors={Colors.White}
						className="btn-goals"
						onClick={
							() => {
								smartlookClient.track('Goals_Own_Goal', {});
								const refreshedGoal = this.props.goal;
								incrementStep();
								refreshedGoal.name = '';
								this.props.onChangeGoal(refreshedGoal);
							}
						}
					>Create my own goal</Button>

				</div>

				<div className="goal-wizard__footer">
					<div >
						<Button
							display={Display.Text}
							colors={Colors.White}
							onClick={() => { decrementStep() }}>Back</Button>
					</div>

					<If condition={this.props.displayRedirect === undefined}>
					<Button
						display={Display.Text}
						colors={Colors.White}
						onClick={() => onClickRedirect()}
						>I'll set goal later</Button>
					</If>

				</div>

			</>


		);

		if (this.requestState === 'success') {
			return contents;
		}

		return <></>
	}

	handleOnClickNext = (goal: GoalsEntity) => {
			this.props.onChangeGoal(goal);
			this.props.incrementStep();
	}
}
