/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import { Model, IModelAttributes, attribute, entity } from 'Models/Model';
import * as Models from 'Models/Entities';
import { IAcl } from '../Security/IAcl';
import { observable } from 'mobx';
import { VisitorsUsertagsReferenceManyToMany } from '../Security/Acl/VisitorsUsertagsReferenceManyToMany';
import { PatientUsertagsReferenceManyToMany } from '../Security/Acl/PatientUsertagsReferenceManyToMany';
import { ClinicianUsertagsReferenceManyToMany } from '../Security/Acl/ClinicianUsertagsReferenceManyToMany';
import { CarerUsertagsReferenceManyToMany } from '../Security/Acl/CarerUsertagsReferenceManyToMany';
import { AdminUsertagsReferenceManyToMany } from '../Security/Acl/AdminUsertagsReferenceManyToMany';

// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface ITagsUsertagsAttributes extends IModelAttributes {
	tagsId: string;
	usertagsId: string;

	tags: Models.TagEntity | Models.ITagEntityAttributes;
	usertags: Models.PatientEntity | Models.IPatientEntityAttributes;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

@entity('TagsUsertags')
export default class TagsUsertags extends Model implements ITagsUsertagsAttributes {
	public static acls: IAcl[] = [
		new VisitorsUsertagsReferenceManyToMany(),
		new PatientUsertagsReferenceManyToMany(),
		new ClinicianUsertagsReferenceManyToMany(),
		new CarerUsertagsReferenceManyToMany(),
		new AdminUsertagsReferenceManyToMany(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	@observable
	@attribute()
	public tagsId: string;

	@observable
	@attribute()
	public usertagsId: string;

	@observable
	@attribute({isReference: true})
	public tags: Models.TagEntity;

	@observable
	@attribute({isReference: true})
	public usertags: Models.PatientEntity;
	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	constructor(attributes?: Partial<ITagsUsertagsAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		if (attributes) {
			if (attributes.tagsId) {
				this.tagsId = attributes.tagsId;
			}
			if (attributes.usertagsId) {
				this.usertagsId = attributes.usertagsId;
			}

			if (attributes.tags) {
				if (attributes.tags instanceof Models.TagEntity) {
					this.tags = attributes.tags;
					this.tagsId = attributes.tags.id;
				} else {
					this.tags = new Models.TagEntity(attributes.tags);
					this.tagsId = this.tags.id;
				}
			} else if (attributes.tagsId !== undefined) {
				this.tagsId = attributes.tagsId;
			}

			if (attributes.usertags) {
				if (attributes.usertags instanceof Models.PatientEntity) {
					this.usertags = attributes.usertags;
					this.usertagsId = attributes.usertags.id;
				} else {
					this.usertags = new Models.PatientEntity(attributes.usertags);
					this.usertagsId = this.usertags.id;
				}
			} else if (attributes.usertagsId !== undefined) {
				this.usertagsId = attributes.usertagsId;
			}
		}

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}