import React, { Component } from 'react'
import { Animated } from 'react-animated-css'
import { observer } from 'mobx-react';

interface IOnboardingAreaofLifeSavedProps {
	incrementStep: () => void;
}

@observer
export default class OnboardingAreadOfLifeSaved extends Component<IOnboardingAreaofLifeSavedProps> {

	componentDidMount() {
		const { incrementStep } = this.props;

		setTimeout(() => {
			incrementStep()
		}, 3000);
	}

	render() {
		return (
			<Animated animationIn="fadeInUp" isVisible={true} animationOut="fadeInDown">
				<div className="onboarding-framework-set">
					<img src={process.env.PUBLIC_URL + "/Goals/thumb.png"}  alt="Thumbs up"/>
					<div className="title">
						<h4>Areas of life saved</h4>
					</div>
				</div>
			</Animated>
		)
	}
}
