import React, { Component } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { ArticleEditor } from 'Views/Components/Articles/ArticleEditor';

export interface LibraryEditorProps extends RouteComponentProps {
	article: any;
	book: any;
	location: any;
}

export default class LibraryEditorPage extends Component<LibraryEditorProps> {
	state = {
		article: this.props.location.state.article,
		book: this.props.location.state.book,
	};

	render() {
		return (
			<>
				<ArticleEditor article={this.state.article} book={this.state.book} />
			</>
		);
	}
}
