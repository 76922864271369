import React, {Component} from 'react';
import {RouteComponentProps} from 'react-router';
import {gql} from 'apollo-boost';
import {store} from 'Models/Store';
import {observable, action, toJS} from 'mobx';
import {observer} from 'mobx-react';
import {Button, Display} from 'Views/Components/Button/Button';
import {SERVER_URL} from 'Constants';
import ArticleBanner from 'Views/Components/Articles/ArticleBanner';
import {PatientEntity} from 'Models/Entities';
import {TextField} from 'Views/Components/TextBox/TextBox';
import {Link} from 'react-router-dom';

function queryFavouriteArticleByPatient(owner: string) {
	return gql`
        query favourites {
            patientEntity(id: "${owner}") {
                id
                favouritearticless {
                    favouritearticlesId
                    favouritearticles {
                        id
                        title
                        content
                        description
                        featureId
                        booksId
                    }
                }
            }
        }
    `;
}

interface ISearch {
	searchTerm: string;
}

@observer
export default class LibraryFavouriteArticlesTile extends Component<RouteComponentProps> {
	@observable
	private search: ISearch = {searchTerm: ''};

	@observable
	private favouriteArticle: JSX.Element;

	@observable
	private patientEntity: PatientEntity = new PatientEntity();

	componentDidMount() {
		this.getFavouriteArticles();
	}

	async getFavouriteArticles() {
		const owner: any = store.userId;
		await store.apolloClient
			.query({query: queryFavouriteArticleByPatient(owner), fetchPolicy: 'network-only'})
			.then((d) => this.renderFavouriteArticles(d.data.patientEntity))
			.catch(() => alert('Could not load favourite articles'));
	}

	@action
	private renderFavouriteArticles = (favourites: any) => {
		const {history, location, match, staticContext} = this.props;
		let articleButtons = favourites.favouritearticless.map((article: any) => (
			<div>
				<ArticleBanner
					key={article.favouritearticles.id}
					article={article.favouritearticles}
					history={history}
					location={location}
					match={match}
					staticContext={staticContext}
				/>
			</div>
		));

		let state = {focus: false};
		this.favouriteArticle = <div className="favourite-article-container">{articleButtons}</div>;
	};

	@action
	private handleSearch = () => {
		let state = {
			search: this.search.searchTerm,
			path: '/library',
			focus: true,
		};
		store.routerHistory.push('/library/all', state);
	};

	render() {
		// % protected region % [Customise the page here] on begin

		let focus = this.props.location.state == true ? true : false;

		let contents = (
			<>
				{/* <Header subtitle="Dashboard" title="Library" /> */}

				<div className="library-header">
					<div>
						<h6>Home /Library / </h6>
						<h2>Favourites</h2>
					</div>
					<div className="library-all-back">
						<Button className="library-index-button"
								onClick={() => store.routerHistory.push('/library/index')}>
							View Index
						</Button>
						<div className="search-box">
							<Button icon={{icon: 'search', iconPos: 'icon-left'}}/>
						</div>
						<TextField
							model={this.search}
							modelProperty="searchTerm"
							className="article-search"
							placeholder="Search..."
							onAfterChange={this.handleSearch}
							autoFocus={focus}
						/>
					</div>
				</div>
				<div className="back-fav">
					<Link to="/library" className="icon-arrow-left icon-left">
						Back
					</Link>
				</div>
				{/* Rendering favourite articles*/}

				<div className="articles-container">
					<div className="latest-recommended-container">{this.favouriteArticle}</div>
				</div>
			</>
		);
		return contents;
		// % protected region % [Customise the page here] end
	}
}
