import React, { Component, useState } from "react";
import { store } from "Models/Store";
import If from "../If/If";
import { observer } from "mobx-react";
import { observable, action } from "mobx";
import { TextArea } from "../TextArea/TextArea";
import Axios from "axios";
import { todotype } from "Models/Enums";
import { TodoEntity, PatientEntity } from "Models/Entities";
import alert from "Util/ToastifyUtils";
import { Combobox, ComboboxOption } from "../Combobox/Combobox";
import { gql } from "apollo-boost";
import { DatePicker } from "../DatePicker/DatePicker";
import moment from "moment";
import Popup from "reactjs-popup";
import { EventAttributes } from "ics";
import FileSaver from "file-saver";
import { getModelDisplayName } from "Util/EntityUtils";
import { SERVER_URL } from "Constants";
import { ReactComponent as TickComplete } from "./TickComplete.svg";
import { ReactComponent as TickIncomplete } from "./TickIncomplete.svg";
import { prettyLog } from '../../../Util/StringUtils';

// Gets the names and ids for a specified task (activity/article/tool)
function queryTaskTypes(type: string, nameString: string) {
    return gql`
		query task {
			${type} {
				id
                ${nameString}
			}
		}
	`;
}

// Gets content for a selected task (feature image/description)
function queryTaskContent(id: string, type: string) {
    return gql`
		query task {
			${type}(id: "${id}"){
				id
                featureId
                description
			}
		}
	`;
}

// Gets the username for a given clinician/carer
function queryUsername(id: string) {
    return gql`
		query users {
			clinicianEntitys(id: "${id}") {
				forename
				surname
			}
			carerEntitys(id: "${id}") {
				forename
				surname
			}
		}
	`;
}

// Gets the name of the linked article or activity
function queryLinkName(typeid: string) {
	return gql`
		query todo2 {
			articlesEntitys(where: {path: "id", comparison: equal, value: "${typeid}"}) {
				id
				title
			}

			activitiesEntitys(where: {path: "id", comparison: equal, value: "${typeid}"}) {
				id
				name
			}
		}
	`;
}

/**
 * Interface to store information on a task (todo)
 * @deprecated
 *
 * - Deprecated as developer create a custom interface that is identical to ToDoEntity.tsx
 */
interface taskProps {
    id: string;
    title: string;
    description?: string;
    complete: boolean;
    duedate?: Date;
    types: todotype;
    typeid?: string;
    owner: string;
    dueString?: string;
    createString?: string;
    readOnly?: boolean;
    patientId?: string;
    expired?: boolean;
    onClickClose: (event: React.MouseEvent) => void;
    onClickDelete: () => void;
    onClickSave: () => void;
}

// Interface for rendering the number of rows in a task
interface rows {
    minRows: number;
    maxRows: number;
    rows: number;
}

@observer
export default class ToDoTask extends Component<taskProps> {
    // Gets information to populate the todo task pane. If the task is not a custom task,
    // then we also get the content for the task (feature image/description)
    async componentDidMount() {
		// Get todo link name if there is one
		if (this.toDoTask.typeid !== "NULL" && this.toDoTask.typeid != undefined) {
			await store.apolloClient.query({ query: queryLinkName(this.toDoTask.typeid!) }).then((d) => {
				if (d.data.articlesEntitys.length != 0) { // This is an articles todo
					this.comboPlaceHolder = d.data.articlesEntitys[0].title;
				} else if (d.data.activitiesEntitys.length != 0) { // This is an activities todo
					this.comboPlaceHolder = d.data.activitiesEntitys[0].name;
				} else { // No todo link assigned yet
					this.comboPlaceHolder = "Add a link";
				}
			})
		}

        this.getAssignedString();
        if (this.toDoTask.types !== "CUSTOM" && this.toDoTask.types != null) {
            this.getTypes();
            if (this.toDoTask.typeid) {
                this.getTypeContent(false);
            }
        }
    }

	// Title for todo link
	private comboPlaceHolder = "Add a link";

    // Model for creating and editing a todo task
    @observable
    private toDoTask = {
        id: this.props.id,
        title: this.props.title,
        description: this.props.description,
        complete: this.props.complete ? this.props.complete : false,
        duedate: this.props.duedate ? moment.utc(this.props.duedate).toDate() : undefined,
        types: this.props.types,
        typeid: this.props.typeid,
        patientId: this.props.patientId,
    };

    // Options for the type combobox
    @observable
    private typeOptions: ComboboxOption<string>[] = [{ display: "", value: "" }];

    // Feature image URL for a task
    @observable
    private typeContentFeature: string | undefined;

    // Description for a task
    @observable
    private typeContentDescription: string | undefined;

    // Button to check if a task is complete
    @observable
    private completeTick: any = this.props.complete ? <TickComplete /> : <TickIncomplete />;

    // String representation of the assigner
    @observable
    private assignedString: string;

    // Function to get the string representation of the assinger
    private async getAssignedString() {
        if (store.userId !== this.props.owner) {
            await store.apolloClient.query({ query: queryUsername(this.props.owner) }).then((d) => {
                this.setAssignedString(true, d.data);
            });
        } else {
            this.setAssignedString(false);
        }
    }

    // Function to set the string representation of the assigner
    @action
    private setAssignedString(assigned: boolean, data?: any) {
        if (assigned) {
            if (data.carerEntitys.length > 0) {
                const { forename, surname } = data.carerEntitys[0];
                this.assignedString = `Assigned by ${this.getUserName(forename, surname, "Carer")} on ${this.props.createString}`;
            } else if (data.clinicianEntitys.length > 0) {
                const { forename, surname } = data.clinicianEntitys[0];
                this.assignedString = `Assigned by ${this.getUserName(forename, surname, "Clinician")} on ${this.props.createString}`;
            } else {
                this.assignedString = `Created on ${this.props.createString}`;
            }
        } else {
            this.assignedString = `Created by me on ${this.props.createString}`;
        }
    }

    // Function to produce a readable username for the asigner
    private getUserName(fistName?: string, lastName?: string, type?: string) {
        let firstname = fistName ? fistName : type;
        let lastname = lastName ? lastName : "";
        return `${firstname} ${lastname}`;
    }

    // Updates the model content if new props are passed into the component
    @action
    componentDidUpdate(prevProps: any) {
        if (prevProps.title !== this.props.title) {
            this.toDoTask.title = this.props.title;
        }
        if (prevProps.complete !== this.props.complete) {
            this.toDoTask.complete = this.props.complete;
            this.completeTick = this.toDoTask.complete ? "icon-check-circle-2" : "icon-circle";
        }
    }

    // Deletes a task (re-renders the todo items in the parent component - ToDoTile.tsx)
    private deleteTask(id: string) {
        if (!this.props.readOnly) {
            Axios.delete(`/api/entity/TodoEntity/${id}`).then((d) => {
                this.props.onClickDelete();
            });
        }
    }

    // Saves a task  (re-renders the todo items in the parent component - ToDoTile.tsx)
    private saveTask() {
        if (this.validateTask()) {
	        Axios.put('/api/entity/TodoEntity', this.toDoTask).then(() => {
	            this.props.onClickSave();
	            this.toggleTitle();
	        });
        } else {
            alert("Please enter a title");
        }
    }

    // Validates a saved task (return true if valid else return false)
    private validateTask(): boolean {
        if (this.toDoTask.title !== "") {
            return true;
        }
        return false;
    }

    // Completes a task  (re-renders the todo items in the parent component - ToDoTile.tsx)
    @action
    private completeTask(e: any) {
        e.preventDefault();
        if (this.validateTask()) {
            this.toDoTask.complete = !this.toDoTask.complete;
            this.completeTick = this.toDoTask.complete ? <TickComplete /> : <TickIncomplete />;
            const saveToDo = new TodoEntity(this.toDoTask);
            saveToDo.save().then(() => {
                this.props.onClickSave();
            });
        }
    }

    // Helper function to define what items to was into the task query
    private getTypeInfo() {
        let taskType: string;
        let nameString: string;
        switch (this.toDoTask.types) {
            case "ACTIVITY":
                taskType = "activitiesEntity";
                nameString = "name";
                return { taskType, nameString };
            case "ARTICLE":
                taskType = "articlesEntity";
                nameString = "title";
                return { taskType, nameString };
        }

        return undefined;
    }

    // Gets of of the types (activities/articles/tools)
    private async getTypes() {
        if (this.toDoTask.types !== "CUSTOM" && this.toDoTask.types != null) {
            const typeName: any = this.getTypeInfo();
            let { taskType, nameString } = typeName;
            taskType = taskType + "s";

            store.apolloClient.query({ query: queryTaskTypes(taskType!, nameString!) }).then((d) => {
                if (d.data[taskType].length > 0) {
                    this.renderTypes(d.data[taskType], nameString);
                }
            });
        }
    }

    // Handles the changing of a type
    @action
    private handleTypeChange() {
        this.toDoTask.typeid = undefined;
        this.toDoTask.description = undefined;
        this.typeContentFeature = undefined;
        this.typeContentDescription = undefined;
        this.getTypes();
    }

    // Renders the potential type choices in a combobox
    @action
    private renderTypes(typeData: any, nameString: string) {
        this.typeOptions = typeData.map((task: any) => {
            return { display: task[nameString], value: task.id };
        });
    }

    // Gets the content for a selected type
    private async getTypeContent(save: boolean) {
        const typeName: any = this.getTypeInfo();
        const { taskType } = typeName;

        store.apolloClient
            .query({ query: queryTaskContent(this.toDoTask.typeid!, taskType) })
            .then((d) => {
                if (d.data[taskType]) {
                    this.renderTypeContent(d.data[taskType]);
                }
            })
            .then(() => {
                if (save) {
                    this.saveTask();
                }
            });
    }

    // Renders the content for a selected type
    @action
    private renderTypeContent(typeData: any) {
        this.typeContentFeature = typeData.featureId;
        this.typeContentDescription = typeData.description;
    }

    // Takes the user to the task so they can complete it (if the task has one)
    private handleTaskPress(e: React.MouseEvent<HTMLDivElement, MouseEvent>, path: string | undefined) {
        e.stopPropagation();
        if (path) {
            if (this.toDoTask.typeid) {
                store.routerHistory.push(path, store.routerHistory.location.pathname);
            }
        }
    }

    // Sets the active type
    @action
    private setType(type: todotype) {
        this.toDoTask.types = type;
        this.handleTypeChange();
        this.saveTask();
    }

    @observable
    private editTitle = false;

    @action
    private toggleTitle() {
        this.editTitle = !this.editTitle;
    }

    private createICS() {
        const { title, duedate } = this.toDoTask;

        // let date = this.toDoTask.duedate;
        if (duedate) {
            const ics = require("ics");

            const event: EventAttributes = {
                start: [duedate.getFullYear(), duedate.getMonth() + 1, duedate.getDate(), duedate.getHours(), duedate.getMinutes()],
                duration: { hours: 24, minutes: 0 },
                title: `Complete To-Do Task: ${title}`,
            };

            ics.createEvent(event, (error: any, value: string) => {
                if (error) {
                    alert("Could not create calendar event");
                } else {
                    this.saveICS(value);
                }
            });
        }
    }

    private saveICS(event: string) {
        const blob = new Blob([event], { type: "text/plain;charset=utf-8" });
        FileSaver.saveAs(blob, `${this.toDoTask.title}.ics`);
    }

    private path = "";
    private async setPath() {
        await store.apolloClient.query({ query: queryLinkName(this.toDoTask.typeid!) }).then((d) => {
            if (d.data.articlesEntitys.length != 0) { // This is an articles todo
                this.path = `${SERVER_URL}/article/${this.toDoTask.typeid}`;
                return;
            } else if (d.data.activitiesEntitys.length != 0) { // This is an activities todo
                this.path = `${SERVER_URL}/activity?id=${this.toDoTask.typeid}`;
                return;
            }
        })
    }

    // Render function
    render() {
        const { id, readOnly, onClickClose } = this.props;

        // let path: string | undefined;
        switch (this.toDoTask.types) {
            case "ACTIVITY":
                this.path = `${SERVER_URL}/activity?id=${this.toDoTask.typeid}`;
                break;
            case "ARTICLE":
                this.path = `${SERVER_URL}/article/${this.toDoTask.typeid}`;
                break;
            case "CUSTOM":
                break;
            default:
                if (this.toDoTask.typeid === null) break;
                this.setPath();
        }

        return (
			<>
				{/* Task pane */}
				<div className="to-do-task">
					<div className="task-name-container">
						{/* Check Mark */}
						<div className="to-do-task-complete" onClick={(e) => this.completeTask(e)}>
							{this.completeTick}
						</div>
						{/* Title */}
						<div className={readOnly ? "task-name task-name-readonly" : "task-name"}>
							<If condition={this.editTitle}>
								<TextArea
									className="editable-title"
									model={this.toDoTask}
									modelProperty="title"
									textAreaProps={{
										rows: this.titleRows.rows,
										// Updated line height so new rows get created properly
										onChange: (e) => this.resizeTextArea(e, "title", "titleRows", 28),
										onBlur: () => this.saveTask(),
										autoFocus: true,
									}}
									isReadOnly={readOnly}
								/>
							</If>
							<If condition={!this.editTitle}>
								<div className="standard-title" onClick={() => this.toggleTitle()}>
									{this.toDoTask.title}
								</div>
							</If>
						</div>
						{/* Edit Button */}
						<If condition={!readOnly}>
							<Popup trigger={<div className="type-edit icon-right icon-link-2" />} position="bottom right" closeOnDocumentClick>
								{(close) => (
									<>
										<div
											className="popup-item icon-left icon-yhp-library"
											onClick={() => {
												close();
												this.setType("ARTICLE");
											}}>
											Article
										</div>
										<div
											className="popup-item icon-left icon-quiz"
											onClick={() => {
												close();
												this.setType("ACTIVITY");
											}}>
											Activity
										</div>
										<div
											className="popup-item icon-left icon-question"
											onClick={() => {
												close();
												this.setType("CUSTOM");
											}}>
											Other
										</div>
									</>
								)}
							</Popup>
						</If>
					</div>

					<div className="task-assigned icon-left icon-person-add">
						{this.props.owner === store.userId!
							? `Created by me`
							: store.userGroups[0].name === "Patient"
							? `Assigned to me`
							: `Assigned to ${getModelDisplayName(PatientEntity)}`}
					</div>

					<If condition={this.toDoTask.types !== "CUSTOM"}>
						<div
							className={this.toDoTask.types ? "task-link icon-left icon-link-2" : "task-link task-no-link icon-left icon-link-2"}
							onClick={(e) => this.handleTaskPress(e, this.path)}>
							<Combobox
								className={readOnly ? "select-type-readonly select-type" : "select-type"}
								model={this.toDoTask}
								modelProperty="typeid"
								label="Task"
								labelVisible={false}
								options={this.typeOptions}
								onAfterChange={() => this.getTypeContent(true)}
								isDisabled={readOnly}
								placeholder={this.comboPlaceHolder}
							/>
						</div>
					</If>

					<div className={readOnly ? "task-date-container-readonly task-date-container" : "task-date-container"}>
						<div className="task-calender icon-left icon-calender" />
						<DatePicker
							className="task-date-picker"
							model={this.toDoTask}
							modelProperty="duedate"
							onAfterChange={() => this.saveTask()}
							flatpickrOptions={{
								minDate: moment().startOf("d").toDate(),
								dateFormat: "D j M, Y",
								altFormat: "Y-m-d",
							}}
							isReadOnly={readOnly}
							placeholder={this.props.expired ? "Expired" : readOnly ? "No due date" : "Pick a due date"}
						/>
					</div>

					<If condition={this.toDoTask.duedate !== undefined}>
						<div className="ics" onClick={() => this.createICS()}>
							Add to calendar
						</div>
					</If>
				</div>

				{/* Footer */}
				<div className="to-do-task-footer">
					<div className="close-task icon-left icon-arrow-right" onClick={onClickClose} />

					<div className="assigned">{this.assignedString}</div>
					<div
						className={readOnly ? "lock-task icon-right icon-lock" : "delete-task icon-right icon-bin-delete"}
						onClick={() => this.deleteTask(id)}
					/>
				</div>
			</>
		);
    }

    // State for keeping track of the number of rows in a the title
    @observable
    private titleRows: rows = {
        minRows: 1,
        maxRows: 6,
        rows: 1,
    };

    // State for keeping track of the number of rows in the description
    @observable
    private descriptionRows: rows = {
        minRows: 3,
        maxRows: 15,
        rows: 5,
    };

    // A helper function to re-size the number of rows within a text area
    @action
    resizeTextArea = (
        event: {
            target: {
                rows: number;
                scrollHeight: number;
                scrollTop: any;
                value: string;
            };
        },
        valueType: string,
        inputType: string,
        lineHeight: number
    ) => {
        this.toDoTask[valueType] = event.target.value;
        const textareaLineHeight = lineHeight;
        const { minRows, maxRows } = this[inputType];

        const previousRows = event.target.rows;
        event.target.rows = minRows; // reset number of rows in textarea

        const currentRows = ~~(event.target.scrollHeight / textareaLineHeight);

        if (currentRows === previousRows) {
            event.target.rows = currentRows;
        }

        if (currentRows >= maxRows) {
            event.target.rows = maxRows;
            event.target.scrollTop = event.target.scrollHeight;
        }

        this[inputType].rows = currentRows < maxRows ? currentRows : maxRows;
    };
}
