import * as React from 'react';
import { observer } from 'mobx-react';
import { RouteComponentProps } from 'react-router';
import SecuredPage from 'Views/Components/Security/SecuredPage';
import Navigation, { Orientation } from 'Views/Components/Navigation/Navigation';
import { getFrontendNavLinks } from 'Views/FrontendNavLinks';
import { store } from "Models/Store";
import Spinner from "Views/Components/Spinner/Spinner";
import PatientCreateTile from "Views/Tiles/PatientCreateTile";
import { observable } from 'mobx';

@observer
class PatientCreatePage extends React.Component<RouteComponentProps> {

	@observable
	private popupOpen: boolean = false;

	public render() {
		const { history, location, match, staticContext } = this.props;

		const navigationLinks = getFrontendNavLinks(this.props);

		if (store.displayNameStatus === "loading") {
			return <Spinner />;
		}

		const contents = (
			<SecuredPage groups={['Super Administrators', 'Clinician', 'Admin']}>
				<Navigation
					linkGroups={navigationLinks}
					orientation={Orientation.VERTICAL}
					match={match}
					location={location}
					history={history}
					staticContext={staticContext}
				/>

				<div className="body-content">
					<PatientCreateTile />
				</div>
			</SecuredPage>
		);
		return contents;
	}
}

export default PatientCreatePage;
