import React from 'react';
import { observer } from 'mobx-react';
import { action, observable, runInAction } from 'mobx';
import {
	DASHBOARD_GRAPH_COMMON_CHART_OPTIONS,
	DashboardGraphDateRange,
} from './PatientCommonGraph';
import Highcharts  from 'highcharts';
import moment from 'moment';
import HighchartsReact from 'highcharts-react-official';
import { ButtonGroup } from '../Button/ButtonGroup';
import { Button } from '../Button/Button';
import alert from '../../../Util/ToastifyUtils';
import ActivitiesSubmissionService, { PatientActivitiesGraphData } from '../../../Services/ActivitiesSubmissionService';

@observer
export default class PatientActivitiesGraph extends React.Component {

	@observable
	private highchartOptions: Highcharts.Options;

	@observable
	private dateRange: DashboardGraphDateRange;

	@observable
	private data: PatientActivitiesGraphData;

	@action
	updateChartData = (graphData: PatientActivitiesGraphData) => {
		this.highchartOptions = {
			title: DASHBOARD_GRAPH_COMMON_CHART_OPTIONS.title,
			legend: DASHBOARD_GRAPH_COMMON_CHART_OPTIONS.legend,
			yAxis: DASHBOARD_GRAPH_COMMON_CHART_OPTIONS.yAxis,
			xAxis: {
				categories: graphData.getXCategories()
			},
			series: [
				graphData.getSubmissionsSeriesData()
			]
		}
	}

	constructor(props: any) {
		super(props);
		const startingDate = moment().subtract(DASHBOARD_GRAPH_COMMON_CHART_OPTIONS.xAxisRange, 'days').toDate();
		startingDate.setHours(0);
		startingDate.setMinutes(0);
		startingDate.setSeconds(0);
		const endingDate = moment().toDate();
		endingDate.setHours(23);
		endingDate.setMinutes(59);
		endingDate.setSeconds(59);
		this.dateRange = new DashboardGraphDateRange(startingDate, endingDate);
	}

	componentDidMount() {
		this.fetchChartDataAndUpdateChart();
	}

	@action
	fetchChartDataAndUpdateChart = () => {
		ActivitiesSubmissionService.getPatientActivitiesGraphData(this.dateRange)
			.then(res => {
				runInAction(() => {
					this.data = new PatientActivitiesGraphData(res.data);
					this.updateChartData(this.data);
				});
			})
			.catch(err => {
				console.error(err);
				alert('Unsuccessfully fetched activities graph data', 'error')
			});
	}

	@action
	onPanLeft = () => {
		this.dateRange.updateRangeBackward(DASHBOARD_GRAPH_COMMON_CHART_OPTIONS.xAxisRange);
		this.fetchChartDataAndUpdateChart();
	}

	@action
	onPanRight = () => {
		this.dateRange.updateRangeForward(DASHBOARD_GRAPH_COMMON_CHART_OPTIONS.xAxisRange);
		this.fetchChartDataAndUpdateChart();
	}

	render() {
		return (
			<div>
				<ButtonGroup>
					<Button className="push-right" onClick={this.onPanLeft}>
						View left
					</Button>
					<Button
						disabled={moment(this.dateRange.endDate).isSameOrAfter(moment())}
						onClick={this.onPanRight}
					>
						View right
					</Button>
				</ButtonGroup>
				<HighchartsReact
					highcharts={Highcharts}
					options={this.highchartOptions}
				/>
			</div>
		);
	}
}
