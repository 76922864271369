import React, { Component } from "react";
import { AreaOfLifeRatingsEntity, GoalsEntity, PatientEntity } from "Models/Entities";
import { observable, action, toJS, runInAction, computed } from "mobx";
import { observer } from "mobx-react";
import Popup from "reactjs-popup";
import { lifeArea } from "Models/Enums";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { Slider } from "@material-ui/core";
import { Checkbox } from "../Checkbox/Checkbox";
import _ from 'lodash';
import { GRADIENT_COLORS } from '../../../Constants';

interface IAreaSlider {
	type: lifeArea;
	areaOfLifeRating: AreaOfLifeRatingsEntity;
	blurb: string;
}

/**
 * Configuration values for the slider.
 * - value represents starting and ending value.
 * - label represents the label shown for each end of the slider
 */
const SLIDER_MARKS = [
	{
		value: 0,
		label: "0%",
	},
	{
		value: 10,
		label: "100%",
	},
];

export const SLIDER_THEME = createMuiTheme({
	typography: {
		fontFamily: ["Lato"].join(","),
	},
	palette: {
		primary: { main: GRADIENT_COLORS.BLUE },
	},
});

/**
 * Default rating value is 5 when they have not previously rating that area of life.
 */
const DEFAULT_RATING_VALUE = 5;

@observer
export class AreaSlider extends React.Component<IAreaSlider> {

	@observable
	private currentValue: number = DEFAULT_RATING_VALUE;

	@observable
	private initialValue: number = DEFAULT_RATING_VALUE;

	@observable
	private valueBeforeNotApplicable: number;

	@action
	private valueChanged(value: number | number[], type: string) {
		if (typeof value === 'number') {
			this.currentValue = value;
		} else {
			this.currentValue = value[0];
		}

		this.props.areaOfLifeRating[this.props.type.toLowerCase()] = this.currentValue;
	}

	@action
	private handleOnAfterCheckedNotApplicable(checked: boolean) {
		if (checked) {
			this.valueBeforeNotApplicable = this.props.areaOfLifeRating.getLifeAreaRating(this.props.type);
			this.props.areaOfLifeRating.setLifeAreaRating(this.props.type, -1);
		} else if (!checked) {
			this.props.areaOfLifeRating.setLifeAreaRating(this.props.type, this.valueBeforeNotApplicable);
		}
	}

	constructor(props: IAreaSlider) {
		super(props);
		this.initialValue = this.props.areaOfLifeRating.getLifeAreaRating(this.props.type) || DEFAULT_RATING_VALUE;
		this.currentValue = this.props.areaOfLifeRating.getLifeAreaRating(this.props.type) || DEFAULT_RATING_VALUE;
	}

	render() {
		var areaDisplayName = _.upperFirst(this.props.type.toLowerCase());
		// this is adding more bad ontop of the bad. however with the impending production release a refactor of the entire codebase that is using the enums inappropriately

		switch (areaDisplayName){
			case "Spiritual":
				areaDisplayName = "Values";
				break;
			case "Psychological":
				areaDisplayName = "Mindset"
				break;
		}

		return (
			<div className="area-slider">
				<div className="card-header">
					<h5>{areaDisplayName}</h5>
					{this.renderTooltip()}
				</div>
				<div>
					<ThemeProvider theme={SLIDER_THEME}>
						<Slider
							defaultValue={this.initialValue}
							aria-labelledby="discrete-slider"
							valueLabelDisplay={"off"}
							step={1}
							min={0}
							max={10}
							marks={SLIDER_MARKS}
							onChange={(e, val) => this.valueChanged(val, this.props.type)}
							disabled={this.props.areaOfLifeRating.isAreaDisabled(this.props.type)}
						/>
					</ThemeProvider>
				</div>
				{this.renderNotApplicableCheckbox()}
			</div>
		);
	}

	renderTooltip = () => {
		return (
			<Popup
				position="left center"
				trigger={<div className="tooltip icon-information icon-right" />}
				on={["hover", "click"]}
			>
				<div className="tooltip__content">
					{this.props.blurb}
				</div>
			</Popup>
		)
	}

	renderNotApplicableCheckbox = () => {
		const disabledArea = {
			work: this.props.areaOfLifeRating.isAreaDisabled(this.props.type),
			financial: this.props.areaOfLifeRating.isAreaDisabled(this.props.type),
			social: this.props.areaOfLifeRating.isAreaDisabled(this.props.type),
			relationships: this.props.areaOfLifeRating.isAreaDisabled(this.props.type),
			spiritual: this.props.areaOfLifeRating.isAreaDisabled(this.props.type),
			family: this.props.areaOfLifeRating.isAreaDisabled(this.props.type),
			psychological: this.props.areaOfLifeRating.isAreaDisabled(this.props.type),
			physical: this.props.areaOfLifeRating.isAreaDisabled(this.props.type),
		};

		return (
			<div className="disable-area">
				<Checkbox
					model={disabledArea}
					modelProperty={this.props.type.toLowerCase()}
					onAfterChecked={(e, checked) => this.handleOnAfterCheckedNotApplicable(checked)}
					label="Not applicable to me"
				/>
			</div>
		)
	}
}
