/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import { action, observable } from 'mobx';
import { Model, IModelAttributes, attribute, entity } from 'Models/Model';
import {IOrderByCondition} from 'Views/Components/ModelCollection/ModelQuery';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from "Util/AttributeUtils";
import { IAcl } from 'Models/Security/IAcl';
import {
	makeFetchManyToManyFunc,
	makeJoinEqualsFunc,
	makeEnumFetchFunction,
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import { VisitorsLibraryTagEntity } from 'Models/Security/Acl/VisitorsLibraryTagEntity';
import { PatientLibraryTagEntity } from 'Models/Security/Acl/PatientLibraryTagEntity';
import { ClinicianLibraryTagEntity } from 'Models/Security/Acl/ClinicianLibraryTagEntity';
import { CarerLibraryTagEntity } from 'Models/Security/Acl/CarerLibraryTagEntity';
import { AdminLibraryTagEntity } from 'Models/Security/Acl/AdminLibraryTagEntity';
import * as Enums from '../Enums';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import {SuperAdministratorScheme} from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface ILibraryTagEntityAttributes extends IModelAttributes {
	name: string;
	areaOfLife: Enums.lifeArea;

	activitiess: Array<Models.ActivitiesLibraryTags | Models.IActivitiesLibraryTagsAttributes>;
	articless: Array<Models.ArticlesLibraryTags | Models.IArticlesLibraryTagsAttributes>;
	goalss: Array<Models.GoalsLibraryTags | Models.IGoalsLibraryTagsAttributes>;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('LibraryTagEntity', 'Library Tag')
// % protected region % [Customise your entity metadata here] end
export default class LibraryTagEntity extends Model implements ILibraryTagEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new VisitorsLibraryTagEntity(),
		new PatientLibraryTagEntity(),
		new ClinicianLibraryTagEntity(),
		new CarerLibraryTagEntity(),
		new AdminLibraryTagEntity(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	/**
	 * The default order by field when the collection is loaded .
	 */
	public get orderByField(): IOrderByCondition<Model> | undefined {
		// % protected region % [Modify the order by field here] off begin
		return {
			path: 'name',
			descending: false,
		};
		// % protected region % [Modify the order by field here] end
	}

	// % protected region % [Modify props to the crud options here for attribute 'Name'] off begin
	/**
	 * The name of the tag.
	 */
	@Validators.Required()
	@Validators.Unique()
	@observable
	@attribute()
	@CRUD({
		name: 'Name',
		displayType: 'textfield',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public name: string;
	// % protected region % [Modify props to the crud options here for attribute 'Name'] end

	// % protected region % [Modify props to the crud options here for attribute 'Area Of Life'] off begin
	/**
	 * This determines whether the library tag sits underneath a core area of life. This is used to aid in personalising content for the users.
	 */
	@observable
	@attribute()
	@CRUD({
		name: 'Area Of Life',
		displayType: 'enum-combobox',
		order: 20,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: (attr: string) => {
			return AttrUtils.standardiseEnum(attr, Enums.lifeAreaOptions);
		},
		enumResolveFunction: makeEnumFetchFunction(Enums.lifeAreaOptions),
		displayFunction: (attribute: Enums.lifeArea) => Enums.lifeAreaOptions[attribute],
	})
	public areaOfLife: Enums.lifeArea;
	// % protected region % [Modify props to the crud options here for attribute 'Area Of Life'] end

	/**
	 * List of library tags that is associated with the activity. These are used to personalise content.
	 */
	@observable
	@attribute({isReference: true})
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Activities'] off begin
		name: 'Activities',
		displayType: 'reference-multicombobox',
		order: 30,
		isJoinEntity: true,
		referenceTypeFunc: () => Models.ActivitiesLibraryTags,
		optionEqualFunc: makeJoinEqualsFunc('activitiesId'),
		referenceResolveFunction: makeFetchManyToManyFunc({
			entityName: 'libraryTagEntity',
			oppositeEntityName: 'activitiesEntity',
			relationName: 'libraryTags',
			relationOppositeName: 'activities',
			entity: () => Models.LibraryTagEntity,
			joinEntity: () => Models.ActivitiesLibraryTags,
			oppositeEntity: () => Models.ActivitiesEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Activities'] end
	})
	public activitiess: Models.ActivitiesLibraryTags[] = [];

	/**
	 * List of library tags that is associated with the article. These are used to personalise content.
	 */
	@observable
	@attribute({isReference: true})
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Articles'] off begin
		name: 'Articles',
		displayType: 'reference-multicombobox',
		order: 40,
		isJoinEntity: true,
		referenceTypeFunc: () => Models.ArticlesLibraryTags,
		optionEqualFunc: makeJoinEqualsFunc('articlesId'),
		referenceResolveFunction: makeFetchManyToManyFunc({
			entityName: 'libraryTagEntity',
			oppositeEntityName: 'articlesEntity',
			relationName: 'libraryTags',
			relationOppositeName: 'articles',
			entity: () => Models.LibraryTagEntity,
			joinEntity: () => Models.ArticlesLibraryTags,
			oppositeEntity: () => Models.ArticlesEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Articles'] end
	})
	public articless: Models.ArticlesLibraryTags[] = [];

	@observable
	@attribute({isReference: true})
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Goals'] off begin
		name: 'Goals',
		displayType: 'reference-multicombobox',
		order: 50,
		isJoinEntity: true,
		referenceTypeFunc: () => Models.GoalsLibraryTags,
		optionEqualFunc: makeJoinEqualsFunc('goalsId'),
		referenceResolveFunction: makeFetchManyToManyFunc({
			entityName: 'libraryTagEntity',
			oppositeEntityName: 'goalsEntity',
			relationName: 'libraryTags',
			relationOppositeName: 'goals',
			entity: () => Models.LibraryTagEntity,
			joinEntity: () => Models.GoalsLibraryTags,
			oppositeEntity: () => Models.GoalsEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Goals'] end
	})
	public goalss: Models.GoalsLibraryTags[] = [];

	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<ILibraryTagEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<ILibraryTagEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.name !== undefined) {
				this.name = attributes.name;
			}
			if (attributes.areaOfLife !== undefined) {
				this.areaOfLife = attributes.areaOfLife;
			}
			if (attributes.activitiess !== undefined && Array.isArray(attributes.activitiess)) {
				for (const model of attributes.activitiess) {
					if (model instanceof Models.ActivitiesLibraryTags) {
						this.activitiess.push(model);
					} else {
						this.activitiess.push(new Models.ActivitiesLibraryTags(model));
					}
				}
			}
			if (attributes.articless !== undefined && Array.isArray(attributes.articless)) {
				for (const model of attributes.articless) {
					if (model instanceof Models.ArticlesLibraryTags) {
						this.articless.push(model);
					} else {
						this.articless.push(new Models.ArticlesLibraryTags(model));
					}
				}
			}
			if (attributes.goalss !== undefined && Array.isArray(attributes.goalss)) {
				for (const model of attributes.goalss) {
					if (model instanceof Models.GoalsLibraryTags) {
						this.goalss.push(model);
					} else {
						this.goalss.push(new Models.GoalsLibraryTags(model));
					}
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] off begin
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] off begin
	public defaultExpands = `
		activitiess {
			${Models.ActivitiesLibraryTags.getAttributes().join('\n')}
			activities {
				${Models.ActivitiesEntity.getAttributes().join('\n')}
				${Models.ActivitiesEntity.getFiles().map(f => f.name).join('\n')}
			}
		}
		articless {
			${Models.ArticlesLibraryTags.getAttributes().join('\n')}
			articles {
				${Models.ArticlesEntity.getAttributes().join('\n')}
				${Models.ArticlesEntity.getFiles().map(f => f.name).join('\n')}
			}
		}
		goalss {
			${Models.GoalsLibraryTags.getAttributes().join('\n')}
			goals {
				${Models.GoalsEntity.getAttributes().join('\n')}
			}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] off begin
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath = {
			activitiess: {},
			articless: {},
			goalss: {},
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
							'activitiess',
							'articless',
							'goalss',
						]
					},
				],
			}
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] on begin
		switch (this.name.toLowerCase()){
			case "spiritual":
				return "values";
			case "psychological":
				return "mindset"
		}

		return this.name;
		// % protected region % [Customise the display name for this entity] end
	}


	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [ createdAttr, modifiedAttr ] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(LibraryTagEntity.prototype, 'created');
CRUD(modifiedAttr)(LibraryTagEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
