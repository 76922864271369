import React from 'react';
import { Button, Colors, Display } from '../Button/Button';
import { GoalsEntity } from '../../../Models/Entities';
import { action, observable, runInAction } from 'mobx';
import { observer } from 'mobx-react';
import { store } from 'Models/Store';
import { GetAreaOfLifeBackgroundPath, GoalButton } from '../Goals/GoalsButtons';
import smartlookClient from "smartlook-client";
import classNames from "classnames";

/**
 * List of pending and completed goals
 */
@observer
export default class GoalList extends React.Component {
	@observable
	private completedGoalsExpanded: boolean = false;

	@observable
	private goals: GoalsEntity[] = [];

	@action
	public onClick(goal: GoalsEntity): void {
		store.routerHistory.push(`/goals/${goal.id}`)
	}

	private async getGoals() {
		GoalsEntity.fetch<GoalsEntity>(
			{
				args: [
					[
						{
							path: 'patientid',
							comparison: 'equal',
							value: store.userId,
						},
					],
				],
			},
			new GoalsEntity().defaultExpands
		).then((res) => {
			runInAction(() => {
				this.goals = res;
			});
		});
	}
	componentDidMount() {
		this.getGoals();
	}

	render() {
		const pendingGoals = this.goals.filter((goal) => !goal.isComplete);
		const completedGoals = this.goals.filter((goal) => goal.isComplete);

		return (
			<div>
				<div className='goals-page-section-header'>
					<div className='goals-page-section-title'>GOAL SETTING</div>
					<div className='text-container'>
						<Button
							display={Display.Round}
							colors={Colors.Black}
							className='btn--icon btn--round icon-plus-circle icon-left'
							onClick={() => {
								smartlookClient.track('Goals_Set_Goal', {});
								store.routerHistory.push(`/goals/create`);
							}}
						>
							Set new goal
						</Button>
					</div>
				</div>
				<div>
					{pendingGoals.length > 0 ? (
						pendingGoals.map((goal) => {
							return (
								<div
									key={goal.id}
									className={classNames('goals-card')}
									style={{
										backgroundImage: `url(${GetAreaOfLifeBackgroundPath(
											goal.lifeArea
										)})`,
									}}
								>
									<GoalButton key={goal.id} goal={goal} onGoalClick={() => this.onClick(goal)} />
								</div>
							);
						})
					) : (
							<div className='no-goal-text'>
								You do not have any goals. Add a goal by pressing
								Set new goal
							</div>
						)}

					{completedGoals.length > 0 && (
						<Button
							className={this.completedGoalsExpanded ? " completed-task-button icon-left icon-chevron-down" : " completed-task-button icon-left icon-chevron-right"}
							onClick={() => {
								runInAction(() => {
									this.completedGoalsExpanded = !this
										.completedGoalsExpanded;
								});
							}}
						>
							Completed
						</Button>
					)}

					{this.completedGoalsExpanded &&
						completedGoals.length > 0 &&
						completedGoals.map((goal) => {
							return (
								<div
									className='goals-card'
									style={{
										backgroundImage: `url(${GetAreaOfLifeBackgroundPath(
											goal.lifeArea
										)})`,
									}}
								>
									<GoalButton key={goal.id} goal={goal} onGoalClick={() => this.onClick(goal)} />
								</div>
							);
						})}
				</div>
			</div>
		);
	}
}
