import axios from 'axios';
import { ArticlesEntity } from 'Models/Entities';
import { store } from 'Models/Store';
import React, { Component } from 'react'

export default class ArticleService {
	public static getArticles() {
		return axios.get('/articles-user/' + store.userId);
	}

	public static getArticle(id: string) {
		return ArticlesEntity
			.fetch<ArticlesEntity>(
				{ args: [[{ path: "id", comparison: "equal", value: id }]] },
				new ArticlesEntity().defaultExpands
			)
	}
}
