import React, { Component } from "react";
import { RouteComponentProps } from "react-router";
import { getFrontendNavLinks } from "Views/FrontendNavLinks";
import { store } from "Models/Store";
import SecuredPage from "Views/Components/Security/SecuredPage";
import Navigation, { Orientation } from "Views/Components/Navigation/Navigation";
import MyProfileTile from "Views/Components/MyProfile/MyProfileTile";
import { observer } from 'mobx-react';

@observer
export default class MyProfilePage extends Component<RouteComponentProps> {
	render() {

		return (
			<SecuredPage groups={["Admin", "Patient", "Clinician", "Carer"]}>
				<Navigation
					linkGroups={getFrontendNavLinks(this.props)}
					orientation={Orientation.VERTICAL}
					match={this.props.match}
					location={this.props.location}
					history={this.props.history}
					staticContext={this.props.staticContext}
				/>

				<div className="body-content">
					<MyProfileTile
						displayHeader={true}
						userId={store.userId!}
						userGroup={store.userGroups[0].name} />
				</div>
			</SecuredPage>
		);
	}
}
