import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router';
import { SERVER_URL } from 'Constants';
import { observer } from 'mobx-react';
import { store } from 'Models/Store';

interface Article {
	id: string;
	title?: string;
	content?: string;
	htmlcontent?: string;
	featureId?: string;
	description?: string;
	booksId?: string;
}

interface ArticleProps extends RouteComponentProps {
	article: Article;
}

@observer
export default class ArticleBanner extends Component<ArticleProps> {
	render() {
		const { id, title, featureId } = this.props.article;

		return (
			<div
				className="article-banner"
				onClick={() => {
					store.routerHistory.push(`/article/${id}`);
				}}>
				<div className="article-image">
					<img src={`${SERVER_URL}/api/files/${featureId}`} />
				</div>
				<h5>{title}</h5>
			</div>
		);
	}
}
