import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router';
import { gql } from 'apollo-boost';
import { store } from 'Models/Store';
import queryString from 'query-string';
import { action, observable } from 'mobx';
import { observer } from 'mobx-react';
import { LoadingIndicator } from 'react-select/lib/components/indicators';
import { Link } from 'react-router-dom';
import ActivityButton from 'Views/Components/Activities/ActivityButton';

function queryActivities(booksId: string) {
	return gql`query activities{
        activitiesEntitys(where: {path: "booksId", comparison: equal, value: "${booksId}"}) {
          id
          name
          featureId 
          booksId
          books{
            name
          }   
        }
    }`;
}

@observer
export default class ActivityTileBook extends Component<RouteComponentProps> {
	@observable
	private bookTitle: string = 'Loading...';

	@observable
	private activities: JSX.Element;

	componentDidMount() {
		this.getActivities();
	}

	async getActivities() {
		let query = queryString.parse(this.props.location.search);
		const id: any = query.id;

		await store.apolloClient.query({ query: queryActivities(id) }).then((d) => this.renderActivities(d.data));
	}

	@action
	private renderActivities = (activities: any) => {
		this.bookTitle = activities.activitiesEntitys[0].books.name;
		this.activities = activities.activitiesEntitys.map((activity: any, index: any) => {
			return <ActivityButton key={activity.id} id={activity.id} name={activity.name} featureId={activity.featureId} />;
		});
	};

	render() {
		return (
			<>
				<div className="library-header">
					<div>
						<h6>Home / Activities / Book</h6>
						<h2>{this.bookTitle}</h2>
					</div>
				</div>
				<div className="back-fav">
					<Link to="/activities" className="icon-arrow-left icon-left">
						Back
					</Link>
				</div>

				<div className="all-activities-container">{this.activities}</div>
			</>
		);
	}
}
