import * as React from "react";
import { IModelType } from "Models/Model";
import { observer } from "mobx-react";
import { Query, QueryResult } from "react-apollo";
import { getFetchSingleQuery, getModelDisplayName, getModelName } from "Util/EntityUtils";
import { lowerCaseFirst } from "Util/StringUtils";
import If from "../If/If";

interface IModelViewProps {
    modelType: IModelType;
    id?: string;
    children: (...args: any[]) => any;
    displayLoading?: boolean;
}

@observer
class ModelView extends React.Component<IModelViewProps, any> {
    public render() {
        const { modelType, id, displayLoading } = this.props;
        const query = getFetchSingleQuery(modelType);
        const modelName = getModelDisplayName(modelType);
        const dataReturnName = lowerCaseFirst(getModelName(modelType));

        if (this.props.id === null) {
            throw new Error("Expected id of model to fetch for edit");
        }

        /* Refetch the model */
        return (
            <If condition={!!id && !!modelType}>
                <Query
                    query={query}
                    fetchPolicy="network-only"
                    variables={{ args: [{ path: "id", comparison: "equal", value: id }] }}
                >
                    {({ loading, error, data }: QueryResult) => {
                        if (loading) {
                            if (displayLoading != false) {
                                return <div>Loading {modelName}...</div>;
                            }
                        }
                        if (error) {
                            return <div>Error Loading {modelName}</div>;
                        }
                        return this.props.children(new modelType(data[dataReturnName]), modelName);
                    }}
                </Query>
            </If>
        );
    }
}

export default ModelView;
